import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import type { DialogRef } from '../../dialog-ref';
import { DialogParams, spDialogData } from '../../dialog/dialog.service';
import { AbstractDialogContentComponent } from '../../dialog/abstract-dialog-content.component';
import { isDefined } from '../../utils';

@Component({
    selector: 'sp-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent
    extends AbstractDialogContentComponent
    implements OnInit
{
    title: string;
    topMessage: string;
    contentMessage: string[] = [];

    bottomNotes: string;
    justifyText: boolean;
    withSmallContentMessageSize: boolean;

    dontShowAgainLabel: string;
    dontShowAgain: boolean;

    dialogRef: DialogRef<ConfirmationModalComponent, any>;

    constructor(
        @Inject(spDialogData)
        public data: DialogParams<ConfirmationModalComponent>,
    ) {
        super();

        this.dialogRef = data.dialogRef;
        this.topMessage = data.contentComponentData.topMessage;
        this.contentMessage = Array.isArray(
            data.contentComponentData.contentMessage,
        )
            ? data.contentComponentData.contentMessage
            : [data.contentComponentData.contentMessage];
        this.bottomNotes = data.contentComponentData.bottomNotes;
        this.justifyText = !!data.contentComponentData.justifyText;
        this.withSmallContentMessageSize =
            data.contentComponentData.withSmallContentMessageSize;
        this.dontShowAgainLabel = data.dontShowAgainLabel;
    }

    ngOnInit() {
        if (this.data.primaryButton) {
            this.subscribeToPrimaryButtonClick();
        }

        if (this.data.secondaryButton) {
            this.subscribeToSecondaryButtonClick();
        }
    }

    private subscribeToPrimaryButtonClick() {
        let observable: Observable<any> = this.dialogRef.primaryButtonClick$;
        if (this.data.primaryButton.isStateful) {
            observable = this.dialogRef.primaryButtonClick$.pipe(
                switchMap(() => {
                    this.dialogRef.disableButtons(true);

                    return this.data.contentComponentData.executeConfirmedActionCallback();
                }),
            );
        }

        const subscription = observable.subscribe(
            (value) => {
                this.dialogRef.disableButtons(false);
                if (isDefined(this.dontShowAgainLabel)) {
                    this.dialogRef.close({
                        result: true,
                        dontShowAgain: this.dontShowAgain,
                        value,
                    });
                } else {
                    this.dialogRef.close(true);
                }
            },
            (error) => {
                this.dialogRef.disableButtons(false);
                this.data.contentComponentData.unsuccessfulActionCallback(
                    error,
                );
                this.subscribeToPrimaryButtonClick();
            },
        );
        this.subscription.add(subscription);
    }

    private subscribeToSecondaryButtonClick() {
        let observable: Observable<any> = this.dialogRef.secondaryButtonClick$;
        if (this.data.secondaryButton.isStateful) {
            observable = this.dialogRef.secondaryButtonClick$.pipe(
                switchMap(() => {
                    this.dialogRef.disableButtons(true);

                    return this.data.contentComponentData.executeConfirmedActionCallback();
                }),
            );
        }

        const subscription = observable.subscribe(
            () => {
                this.dialogRef.disableButtons(false);
                this.dialogRef.close(false);
            },
            () => {
                this.dialogRef.disableButtons(false);
                this.data.contentComponentData.unsuccessfulActionCallback();
                this.subscribeToSecondaryButtonClick();
            },
        );
        this.subscription.add(subscription);
    }
}
