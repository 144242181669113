import { ActivatedRoute, Router } from '@angular/router';
import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'sp-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if ('startSubscription' in params) {
                this.router.navigate(['/account/subscription']);
            }
        });
    }
}
