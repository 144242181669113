import type { OnDestroy, OnInit } from '@angular/core';
import { Directive, EventEmitter, NgZone, Output } from '@angular/core';

@Directive({
    selector: '[spWindowScroll]',
})
export class WindowScrollDirective implements OnInit, OnDestroy {
    @Output() scrollEvent = new EventEmitter();

    private eventOptions: boolean | { capture?: boolean; passive?: boolean };

    constructor(private ngZone: NgZone) {}

    ngOnInit(): void {
        this.eventOptions = this.isPassiveSupported()
            ? { capture: true, passive: true }
            : true;
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener('scroll', this.scroll, this.eventOptions);
        });
    }

    ngOnDestroy(): void {
        window.removeEventListener(
            'scroll',
            this.scroll,
            <any>this.eventOptions,
        );
    }

    scroll = (): void => {
        this.scrollEvent.emit();
    };

    private isPassiveSupported() {
        // see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
        // for details on feature support check
        let passiveSupported = false;

        try {
            const options: AddEventListenerOptions = {
                get passive() {
                    // This function will be called when the browser
                    // attempts to access the passive property.
                    return (passiveSupported = true);
                },
            };
            const fakeHandler = function () {};

            window.addEventListener('test', fakeHandler, options);
            window.removeEventListener('test', fakeHandler, options);
        } catch (err) {
            passiveSupported = false;
        }

        return passiveSupported;
    }
}
