import { EventEmitter } from '@angular/core';
import type { Options } from '@angular-slider/ngx-slider';
import {
    SpComponentInput,
    SpFormControlType,
} from '../../sp-form-controls.model';
import type { CompensationUnit } from '../../unit-selector/compensation-unit-selector/compensation-unit.model';
import { SpSliderBulletSize } from './slider-bullet-size.model';
import type { SpMultiValueSliderControlParams } from './multi-value-slider-form-input-control-params.model';

export class SpMultiValueSliderControl extends SpComponentInput {
    sliderUnits: CompensationUnit[];
    // Used for recalculation of units
    sliderFloorAbsolute: number;
    sliderCeilAbsolute: number;
    inputFloorAbsolute: number;
    inputCeilAbsolute: number;
    sliderOptions: Options;
    bulletSize?: SpSliderBulletSize;
    manualRefresh?: EventEmitter<void>;
    styles?: { [key: string]: any };
    colorScheme: 'default' | 'light' | 'gradient' = 'default';
    gradientStepColors?: { [value: string]: string };
    editableValuesFormatter?: (value: number, unit: CompensationUnit) => string;

    label?: string;

    // outputs
    valueChange = new EventEmitter<any>();

    constructor(params: SpMultiValueSliderControlParams) {
        super(params);
        this.controlType = SpFormControlType.component;
        this.bulletSize =
            params.bulletSize === undefined
                ? SpSliderBulletSize.big
                : params.bulletSize;
        // Hack to make slider refresh. Read the official docs for the details
        this.manualRefresh = new EventEmitter<void>();
        this.component = 'SpMultiValueSliderComponent';
        this.sliderOptions = params.options
            ? { animate: false, ...params.options }
            : { animate: false };
        this.styles = params.styles;
        this.colorScheme = params.colorScheme || 'default';
        this.gradientStepColors = params.gradientStepColors;
        this.editableValuesFormatter = params.editableValuesFormatter;
        this.sliderUnits = params.sliderUnits;
        this.sliderFloorAbsolute = params.sliderFloorAbsolute;
        this.sliderCeilAbsolute = params.sliderCeilAbsolute;
        this.inputFloorAbsolute = params.inputFloorAbsolute;
        this.inputCeilAbsolute = params.inputCeilAbsolute;
        this.label = params.label;
    }

    onValueChange(value: number) {
        this.formControl.patchValue({ value, unit: this.value.unit });
    }

    onUnitChange(unit: CompensationUnit) {
        this.formControl.patchValue({ value: this.value.value, unit });
    }
}
