import type { ComponentRef, OnInit } from '@angular/core';
import {
    Component,
    ComponentFactoryResolver,
    HostBinding,
    Inject,
    InjectionToken,
    Injector,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import type { ComponentType } from '@angular/cdk/overlay';
import { DialogContentComponentInjector } from '../dialog-container/dialog-content-component-injector';
import { spDialogData } from '../dialog.service';
import type { DialogRef } from '../../dialog-ref';
import { PlainComponentDialogParams } from './model/plain-component-dialog-params.model';

export const spPlainComponentDialogContentComponentData =
    new InjectionToken<any>('SpPlainComponentDialogContentComponentData');

@Component({
    selector: 'sp-plain-component-dialog-container',
    templateUrl: './plain-component-dialog-container.component.html',
    styleUrls: ['./plain-component-dialog-container.component.scss'],
})
export class PlainComponentDialogContainerComponent<T> implements OnInit {
    dialogRef: DialogRef<T>;

    @ViewChild('dialogContent', { read: ViewContainerRef, static: true })
    dialogContentContainerRef: ViewContainerRef;
    dialogContentComponentRef: ComponentRef<T>;

    private readonly component: ComponentType<T>;

    @HostBinding('class.contain-position-fixed')
    containFixedPosition;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        @Inject(spDialogData) public data: PlainComponentDialogParams<T>,
    ) {
        this.component = this.data.component;
        this.dialogRef = this.data.dialogRef;
        this.containFixedPosition = this.data.containFixedPosition ?? true;
    }

    ngOnInit() {
        this.renderDialogContent();
    }

    onCloseClick() {
        this.dialogRef.close();
    }

    private renderDialogContent() {
        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
                this.component,
            );
        this.dialogContentContainerRef.clear();
        this.dialogContentComponentRef =
            this.dialogContentContainerRef.createComponent(
                componentFactory,
                undefined,
                this.createInjector(),
            );

        // set input bindings
        if (this.data.contentComponentData.inputBindings) {
            Object.keys(this.data.contentComponentData.inputBindings).forEach(
                (bindingName) => {
                    this.dialogContentComponentRef.instance[bindingName] =
                        this.data.contentComponentData.inputBindings[
                            bindingName
                        ];
                },
            );
        }

        this.dialogContentComponentRef.changeDetectorRef.detectChanges();
    }

    private createInjector(): DialogContentComponentInjector {
        const tokens = new WeakMap();
        tokens.set(spPlainComponentDialogContentComponentData, {
            ...this.data,
        });

        return new DialogContentComponentInjector(this.injector, tokens);
    }
}
