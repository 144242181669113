import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserType } from '../../shared/models/user.model';

export enum AuthProvider {
    linkedin = 'linkedin',
    google = 'google',
}

@Component({
    selector: 'sp-social-auth',
    templateUrl: './social-auth.component.html',
    styleUrls: ['./social-auth.component.scss'],
})
export class SocialAuthComponent {
    readonly AuthProvider = AuthProvider;

    @Input()
    isSignUp: boolean;

    @Input()
    userType: UserType;

    @Input()
    isAuthenticating = false;

    @Input()
    invitationToken: string;

    @Output()
    authenticate: EventEmitter<{ [key: string]: string }> = new EventEmitter();

    authenticateWithProvider(provider: AuthProvider) {
        this.authenticate.emit({
            provider,
            invitationToken: this.invitationToken,
            isSignUp: `${this.isSignUp}`,
        });
    }
}
