<div class="header">
  <ng-container *ngIf="!control.withCustomHeader; else customHeader">
    <div
      class="header-row"
      [class.iconless]="!(control.icon || control.iconUrl)"
      [class.with-average-marker]="!!control.average"
    >
      <span *ngIf="control.icon" class="icon" [ngClass]="iconCssClass"></span>
      <span *ngIf="control.iconUrl && !control.icon" class="mask-icon" [ngStyle]="maskIconCss">
        </span>
      <span class="label" [class.label-small]="control.styles?.labelSmall">
            <span *ngIf="control.isOptional">{{ control.label }}</span>
            <span *ngIf="!control.isOptional" spTooltip tooltipText="Required"
            >{{ control.label }}&nbsp;*</span
            >
            <a
              *ngIf="control.infoTooltip"
              class="info-tooltip"
              spTooltip
              [widthType]="TooltipWidthType.normal"
              [textAlign]="'left'"
              [tooltipText]="control.infoTooltip"
            ></a>
        </span>
      <span class="value" [class.label-small]="control.styles?.labelSmall">
            <span *ngIf="isInvalid && !control.hideRequiredText" class="error-message"
            >Required</span
            >
            <span *ngIf="control.labelBeforeValue" class="value-label"
            >{{ control.labelBeforeValue }}
            </span>
            <span *ngIf="!control.gradientStepColors" [ngClass]="valueColor">{{ value }}</span>
            <span *ngIf="control.gradientStepColors" [style.color]="gradientStepColor">{{
                    value
              }}</span>
            <span *ngIf="control.labelAfterValue" class="value-label">{{
                    control.labelAfterValue
              }}</span>
            <a *ngIf="control.isClosable" class="close" (click)="onClose()"></a>
            <a
              *ngIf="showResetButton"
              class="reset-value"
              (click)="onResetValueClicked()"
              spTooltip
              [tooltipText]="control.resetTooltip"
            >Reset</a
            >
        </span>
    </div>
  </ng-container>
</div>

<span *ngIf="control.average" class="average" [style.left.%]="control.average"></span>

<ngx-slider
    class="slider"
    [class.bullet-size-small]="isSmallBullet"
    [ngClass]="percentClass"
    [class.has-bottom-ticks]="control.sliderOptions.showTicks"
    [(value)]="control.lowValue"
    [(highValue)]="control.highValue"
    (valueChange)="onValueChange($event)"
    (highValueChange)="onHighValueChange($event)"
    [options]="control.sliderOptions"
    [manualRefresh]="control.manualRefresh"
>
</ngx-slider>

<div *ngIf="control.bottomLabels" class="bottom-labels">
    <span class="bottom-label-left">{{ control.bottomLabels.left }}</span>
    <span class="bottom-label-right">{{ control.bottomLabels.right }}</span>
</div>

<ng-template #customHeader>
  <ng-content></ng-content>
</ng-template>
