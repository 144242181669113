import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../core/user/auth.guard';
import { JobRequirementsApiService } from '../core/job-requirements-api.service';
import { DashboardComponent } from './dashboard.component';
import { DashboardGuard } from './dashboard.guard';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard, DashboardGuard],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ],
    exports: [RouterModule],
    providers: [DashboardGuard, JobRequirementsApiService],
})
export class DashboardRoutingModule {}

export const routedComponents = [DashboardComponent];
