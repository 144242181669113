<div class="wrapper" [style.height.px]="size" [style.width.px]="size">
    <div *ngIf="!isIe" class="box-wrap">
        <div class="box one"></div>
        <div class="box two"></div>
        <div class="box three"></div>
        <div class="box four"></div>
        <div class="box five"></div>
        <div class="box six"></div>
    </div>

    <img *ngIf="isIe" src="/images/spinner.svg" class="sp-dots-spinner spinner" />
</div>
