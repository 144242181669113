export enum IntegrationVendor {
    icims = 'icims',
    lever = 'lever',
    greenhouse = 'greenhouse',
    jazz = 'jazz',
    ashby = 'ashby',
    slack = 'slack',
    linkedIn = 'linkedIn',
    other = 'other',
}

export interface Integration {
    vendor: IntegrationVendor;
    vendorTitle: string;
    connected: boolean;
    requiresAuthorizationForOfflineAccess: boolean;
    type: IntegrationType;
    connectedDate?: Date;
    connectedBy?: string;
    // The two following properties are not related to ATS and are currently slack integration specific as it is the
    // only non-ats integration. With more integrations added might need refactoring.
    team?: string;
    channel?: string;
}

export interface IntegrationData {
    logo: string;
    instructionalText: string;
    connectionSuccessMessage: string;
    connectionErrorMessage: string;
    disconnectSuccessMessage?: string;
    disconnectErrorMessage?: string;
    modalIntroInfo?: string;
    modalDisconnectionInfo?: string;
    modalInfoParams?: (integration: Integration) => {};
}

export enum IntegrationType {
    ats = 'ats',
    other = 'other',
}

export const integrationData: { [key: string]: IntegrationData } = {
    [IntegrationVendor.slack]: {
        logo: '/images/integrations/slack.png',
        instructionalText:
            'Get notified in a Slack channel when you receive new applicants.',
        connectionSuccessMessage:
            'You’ve successfully connected your Slack workspace.',
        connectionErrorMessage:
            'We were unable to connect to your Slack workspace.',
        disconnectSuccessMessage:
            'You’ve successfully disconnected your Slack workspace.',
        disconnectErrorMessage:
            'We were unable to disconnect to your Slack workspace.',
        modalIntroInfo: 'See all information directly in the dedicated channel',
        modalDisconnectionInfo: 'You’ll still see the channel Slack history.',
        modalInfoParams: (integration: Integration) => {
            const infoParams = {};
            infoParams['Slack workspace name'] = integration.team;
            infoParams['Slack channel'] = integration.channel;

            return infoParams;
        },
    },
    [IntegrationVendor.linkedIn]: {
        logo: '/images/icons/linkedin_icon.svg',
        instructionalText:
            'Post your SquarePeg jobs directly to your company’s LinkedIn page.',
        connectionSuccessMessage: 'LinkedIn has been connected successfully.',
        connectionErrorMessage:
            'We were unable to connect to your LinkedIn account. Please try again or contact your account manager.',
        disconnectSuccessMessage:
            'Your company’s LinkedIn page was disconnected successfully.',
        disconnectErrorMessage:
            'We were unable to disconnect your company’s LinkedIn page, please try again. If the ' +
            'issue persists please contact your account manager.',
    },
};
