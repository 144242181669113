import type { OnInit } from '@angular/core';
import {
    Component,
    ElementRef,
    Inject,
    NgZone,
    ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import type { CdkScrollable } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { hasScrolledToBottom } from '../utils';
import type { AppNotification } from '../models';
import { NotificationsListenerService } from '../../core/background-listeners/notifications-listener.service';
import type { PopupRef } from '../popup/model';
import { PopupContentComponentData, spPopupData } from '../popup/model';
import { NotificationsDropdownService } from './notifications-dropdown.service';

@Component({
    selector: 'sp-notifications-dropdown',
    templateUrl: './notifications-dropdown.component.html',
    styleUrls: ['./notifications-dropdown.component.scss'],
    providers: [NotificationsDropdownService],
})
export class NotificationsDropdownComponent implements OnInit {
    notifications: AppNotification[];
    isLoading: boolean;
    isLoadingMore: boolean;

    @ViewChild('notificationsScrollableContainer', { read: ElementRef })
    private scrollableContainer: ElementRef<HTMLElement>;

    readonly scrollableContainerCssClass = 'scrollable-notifications-container';

    private prevScrollOffset: number;
    private subscription: Subscription;

    private popupRef: PopupRef;

    constructor(
        private notificationListenersService: NotificationsListenerService,
        private notificationsDropdownService: NotificationsDropdownService,
        @Inject(spPopupData) popupData: PopupContentComponentData,
        private scrollDispatcher: ScrollDispatcher,
        private ngZone: NgZone,
        private router: Router,
    ) {
        this.popupRef = popupData.popupRef;
        this.subscription = new Subscription();
    }

    ngOnInit() {
        this.watchNotificationsContainerScroll();
        this.fetchNotifications(true);
    }

    setLoadingSpinnersState(isShown: boolean, isInitialStateSpinner: boolean) {
        if (isInitialStateSpinner) {
            this.isLoading = isShown;
        } else {
            // run in angular zone explicitly since CDK emits scroll events outside of angular zone
            this.ngZone.run(() => (this.isLoadingMore = isShown));
        }
    }

    closePopup() {
        this.popupRef.close();
    }

    async onNotificationClick(event, notification: AppNotification) {
        this.closePopup();
        event.stopPropagation();
        await this.router.navigate(
            [notification.route],
            notification.routeState,
        );
    }

    private fetchNotifications(fetchLatest?: boolean) {
        this.setLoadingSpinnersState(true, !!fetchLatest);
        const subscription = this.notificationListenersService
            .getNotifications(fetchLatest)
            .pipe(
                finalize(() =>
                    this.setLoadingSpinnersState(false, !!fetchLatest),
                ),
            )
            .subscribe((notifications) => {
                this.notifications = this.notificationsDropdownService.merge(
                    this.notifications,
                    notifications,
                );

                // mark as seen
                const activityGroupIds = this.notifications.map(
                    (notification) => notification.activityGroupId,
                );
                const markAsSeenSubscription = this.notificationListenersService
                    .markAsSeen(activityGroupIds)
                    .subscribe((r) => {
                        this.ngZone.run(() => {
                            this.notifications =
                                this.notificationsDropdownService.setIsSeen(
                                    this.notifications,
                                    activityGroupIds,
                                );
                        });
                    });
                this.subscription.add(markAsSeenSubscription);
            });
        this.subscription.add(subscription);
    }

    private watchNotificationsContainerScroll() {
        const subscription = this.scrollDispatcher
            .scrolled(300)
            .pipe(
                filter(
                    (scrollable: CdkScrollable) =>
                        !this.isLoadingMore &&
                        this.notificationListenersService.hasNextPage() &&
                        scrollable
                            .getElementRef()
                            .nativeElement.className.indexOf(
                                this.scrollableContainerCssClass,
                            ) > -1,
                ),
            )
            .subscribe(() => {
                const { scrollTop, hasReachedBottom } = hasScrolledToBottom(
                    this.scrollableContainer,
                    this.prevScrollOffset,
                );
                if (hasReachedBottom) {
                    this.fetchNotifications();
                }
                this.prevScrollOffset = scrollTop;
            });
        this.subscription.add(subscription);
    }
}
