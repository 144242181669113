<div class="progress-animation-wrapper" [style.background-color]="backgroundColor">
    <svg height="100%" width="100%">
        <defs>
            <linearGradient id="gradient1">
                <stop stop-color="#A6BAFE" offset="0%" />
                <stop stop-color="#BCCBFD" offset="69.9%" />
                <stop stop-color="#DFE7FF" offset="100%" />
            </linearGradient>
        </defs>
        <polygon [attr.points]="points" fill="url(#gradient1)" />
    </svg>
</div>
