import type { Lane } from '../../lanes-container/model';
import { CandidateMatchSubStatus } from '../../../matches/candidate/model/candidate-match-sub-status.model';
import { MatchBannerColor } from '../../../matches/candidate/model';

export enum CandidateMatchesLaneType {
    applied = 'applied',
    inProgress = 'inProgress',
    offers = 'offers',
}

const candidateAppliedMatchStatus = {
    [CandidateMatchSubStatus.ca_applied]: 'Applied',

    [CandidateMatchSubStatus.cb_pendingInProgress]: 'Pending',
    [CandidateMatchSubStatus.cc_interviewing]: 'Interviewing',
    [CandidateMatchSubStatus.cd_phoneScreen]: 'Phone Screen',

    [CandidateMatchSubStatus.ce_pendingOffers]: 'Pending',
    [CandidateMatchSubStatus.cf_accepted]: 'Accepted',
    [CandidateMatchSubStatus.cg_declined]: 'Declined',
};

export const appliedMatchesLanes: { [key in CandidateMatchesLaneType]: Lane } =
    {
        [CandidateMatchesLaneType.applied]: {
            title: 'Applied',
            icon: '/images/icons/mask/check.svg',
            groups: [
                {
                    title: 'Incomplete',
                    type: CandidateMatchSubStatus.ch_incomplete,
                    highlightColor: MatchBannerColor.incomplete,
                    moveToTitle: `${
                        candidateAppliedMatchStatus[
                            CandidateMatchSubStatus.ch_incomplete
                        ]
                    }`,
                    popupOverlayX: 'start',
                },
                {
                    title: 'Applied',
                    type: CandidateMatchSubStatus.ca_applied,
                    highlightColor: MatchBannerColor.applied,
                    moveToTitle: `${
                        candidateAppliedMatchStatus[
                            CandidateMatchSubStatus.ca_applied
                        ]
                    }`,
                    popupOverlayX: 'start',
                },
            ],
        },

        [CandidateMatchesLaneType.inProgress]: {
            title: 'In Progress',
            icon: '/images/icons/mask/interview.svg',
            groups: [
                {
                    title: 'Pending',
                    type: CandidateMatchSubStatus.cb_pendingInProgress,
                    highlightColor: MatchBannerColor.pendingInProgress,
                    moveToTitle: `${
                        candidateAppliedMatchStatus[
                            CandidateMatchSubStatus.cb_pendingInProgress
                        ]
                    }`,
                    popupOverlayX: 'start',
                },
                {
                    title: 'Interviewing',
                    type: CandidateMatchSubStatus.cc_interviewing,
                    highlightColor: MatchBannerColor.interviewing,
                    moveToTitle: `${
                        candidateAppliedMatchStatus[
                            CandidateMatchSubStatus.cc_interviewing
                        ]
                    }`,
                    popupOverlayX: 'start',
                },
                {
                    title: 'Phone Screen',
                    type: CandidateMatchSubStatus.cd_phoneScreen,
                    highlightColor: MatchBannerColor.phoneScreen,
                    moveToTitle: `${
                        candidateAppliedMatchStatus[
                            CandidateMatchSubStatus.cd_phoneScreen
                        ]
                    }`,
                    popupOverlayX: 'start',
                },
            ],
        },

        [CandidateMatchesLaneType.offers]: {
            title: 'Offers',
            icon: '/images/icons/mask/confetti.svg',
            groups: [
                {
                    title: 'Pending',
                    type: CandidateMatchSubStatus.ce_pendingOffers,
                    highlightColor: MatchBannerColor.pendingOffers,
                    moveToTitle: `${
                        candidateAppliedMatchStatus[
                            CandidateMatchSubStatus.ce_pendingOffers
                        ]
                    }`,
                    popupOverlayX: 'end',
                },
                {
                    title: 'Accepted',
                    type: CandidateMatchSubStatus.cf_accepted,
                    highlightColor: MatchBannerColor.accepted,
                    moveToTitle: `${
                        candidateAppliedMatchStatus[
                            CandidateMatchSubStatus.cf_accepted
                        ]
                    }`,
                    popupOverlayX: 'end',
                },
                {
                    title: 'Declined',
                    type: CandidateMatchSubStatus.cg_declined,
                    highlightColor: MatchBannerColor.declined,
                    moveToTitle: `${
                        candidateAppliedMatchStatus[
                            CandidateMatchSubStatus.cg_declined
                        ]
                    }`,
                    popupOverlayX: 'end',
                },
            ],
        },
    };

export const candidateConnectedMatchStatusColor = {
    [CandidateMatchSubStatus.ca_applied]: MatchBannerColor.applied,

    [CandidateMatchSubStatus.cb_pendingInProgress]:
        MatchBannerColor.pendingInProgress,
    [CandidateMatchSubStatus.cc_interviewing]: MatchBannerColor.interviewing,
    [CandidateMatchSubStatus.cd_phoneScreen]: MatchBannerColor.phoneScreen,

    [CandidateMatchSubStatus.ce_pendingOffers]: MatchBannerColor.pendingOffers,
    [CandidateMatchSubStatus.cf_accepted]: MatchBannerColor.accepted,
    [CandidateMatchSubStatus.cg_declined]: MatchBannerColor.declined,
};
