import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { spDialogData } from '../../dialog/dialog.service';
import type { DialogRef } from '../../dialog-ref';
import { unsubscribeIfActive } from '../../utils';

@Component({
    selector: 'sp-pdf-viewer-modal',
    templateUrl: './pdf-viewer-modal.component.html',
    styleUrls: ['./pdf-viewer-modal.component.scss'],
})
export class PdfViewerModalComponent implements OnInit, OnDestroy {
    dialogRef: DialogRef<PdfViewerModalComponent>;
    documentUrl: string;

    readonly subscription: Subscription;

    constructor(@Inject(spDialogData) dialogData) {
        this.subscription = new Subscription();
        this.dialogRef = dialogData.dialogRef;
        this.documentUrl = dialogData.contentComponentData.documentUrl;
    }

    ngOnInit() {
        this.watchBackdropClick();
    }

    ngOnDestroy() {
        unsubscribeIfActive(this.subscription);
    }

    downloadResume() {
        window.open(this.documentUrl, '_blank');
    }

    close(event: any) {
        event.stopPropagation();
        this.dialogRef.close();
    }

    private watchBackdropClick(): void {
        this.subscription.add(
            this.dialogRef
                .backdropClick()
                .subscribe((event) => this.close(event)),
        );
    }
}
