import { FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import {
    SpEmailInput,
    SpTextInput,
    SpValidatedPasswordInput,
} from '../../../shared/forms/custom-controls/sp-form-controls.model';
import { UserType } from '../../../shared/models/user.model';
import type { ISignupForm } from '../../model/signup-form.model';
import {
    COMMON_FORM_VALIDATORS,
    forbiddenEmployerEmailDomains,
} from '../../../core/config.service';
import type { SpFormControlsGroup } from '../../../shared/forms/custom-controls/sp-form-controls-group.interface';

@Injectable()
export class SignUpFormService {
    form: FormGroup;

    public formData: ISignupForm = {};
    public controls: SpFormControlsGroup = {};

    createControls(
        userType: UserType,
        disableEmailField = false,
    ): SpFormControlsGroup {
        this.form = new FormGroup({});

        this.controls = {
            firstName: new SpTextInput({
                form: this.form,
                name: 'firstName',
                label: 'First name',
                validators: [
                    Validators.maxLength(
                        COMMON_FORM_VALIDATORS.firstNameMaxLength,
                    ),
                ],
                errorMessages: {
                    maxlength: `Max ${COMMON_FORM_VALIDATORS.firstNameMaxLength} characters`,
                },
                placeholder: 'Enter your first name',
                icon: 'user',
            }),

            lastName: new SpTextInput({
                form: this.form,
                name: 'lastName',
                label: 'Last name',
                validators: [
                    Validators.maxLength(
                        COMMON_FORM_VALIDATORS.lastNameMaxLength,
                    ),
                ],
                errorMessages: {
                    maxlength: `Max ${COMMON_FORM_VALIDATORS.lastNameMaxLength} characters`,
                },
                placeholder: 'Enter your last name',
                icon: 'user',
            }),

            email: new SpEmailInput({
                form: this.form,
                name: 'email',
                label:
                    userType === UserType.candidate
                        ? 'Email Address'
                        : 'Work Email Address',
                errorMessages: {
                    alreadyRegistered: 'already registered',
                    domainRegistered: ' ',
                },
                placeholder:
                    userType === UserType.candidate
                        ? 'Enter your e-mail'
                        : 'Enter your work e-mail',
                disabled: userType === UserType.employer && disableEmailField,
                icon: 'email',
            }),

            password: new SpValidatedPasswordInput({
                form: this.form,
                name: 'password',
                label: 'Password',
                icon: 'lock',
                placeholder: 'Enter your password',
                browserAutocomplete: 'new-password',
            }),
        };

        return this.controls;
    }

    updateControls(
        controls: SpFormControlsGroup,
        userType: UserType,
        options: Record<string, boolean>,
        data?: ISignupForm,
    ) {
        if (userType === UserType.employer) {
            controls['email'].label = 'Work Email Address';
            controls['email'].placeholder = 'Enter your work e-mail';

            if (options.showFullEmployerSignupForm) {
                controls['email'].value = data ? data.email : '';

                controls['firstName'].value = data ? data.firstName : '';
                controls['firstName'].updateValidators([
                    ...SpTextInput.defaultValidators,
                    Validators.required,
                    Validators.maxLength(
                        COMMON_FORM_VALIDATORS.firstNameMaxLength,
                    ),
                ]);

                controls['lastName'].value = data ? data.lastName : '';
                controls['lastName'].updateValidators([
                    ...SpTextInput.defaultValidators,
                    Validators.required,
                    Validators.maxLength(
                        COMMON_FORM_VALIDATORS.lastNameMaxLength,
                    ),
                ]);

                controls['password'].updateValidators(
                    SpValidatedPasswordInput.defaultValidators,
                );
            } else {
                // we do not display the sign-up controls until 'pre-fill employer profile' request completes
                // reset all validators except the one for email field
                Object.keys(controls).forEach((control) => {
                    if (
                        control !== 'email' &&
                        control !== 'firstName' &&
                        control !== 'lastName'
                    ) {
                        controls[control].updateValidators([]);
                    }
                });
            }
        } else if (userType === UserType.candidate) {
            controls['email'].label = 'Email Address';
            controls['email'].placeholder = 'Enter your e-mail';

            const textInputValidators = [
                Validators.required,
                ...SpTextInput.defaultValidators,
            ];
            controls['firstName'].updateValidators([
                ...textInputValidators,
                Validators.maxLength(COMMON_FORM_VALIDATORS.firstNameMaxLength),
            ]);
            controls['lastName'].updateValidators([
                ...textInputValidators,
                Validators.maxLength(COMMON_FORM_VALIDATORS.lastNameMaxLength),
            ]);
            controls['password'].updateValidators(
                SpValidatedPasswordInput.defaultValidators,
            );
        }
    }

    saveFormState() {
        Object.keys(this.controls).forEach((name) => {
            if (name !== 'password') {
                this.formData[name] = this.controls[name].value;
            }
        });
    }

    isEmailDomainForbidden(email: string, userType: UserType): boolean {
        let isForbidden = false;
        if (userType === UserType.employer) {
            isForbidden = !!forbiddenEmployerEmailDomains.find((domain) =>
                new RegExp(`.+@${domain}`).test(email),
            );
        }

        return isForbidden;
    }
}
