export interface CachedResponseData<T> {
    lastFetchedAt: Date;
    data: T;
}

export class CachedItemsMap<T> {
    private items: Map<number, CachedResponseData<T>> = new Map<
        number,
        CachedResponseData<T>
    >();
    private cacheRefreshIntervalMs: number;

    constructor(cacheRefreshInterval: number) {
        this.cacheRefreshIntervalMs = cacheRefreshInterval;
    }

    updateItem(key: number, item: T) {
        this.items.set(key, { lastFetchedAt: new Date(), data: item });
    }

    getItemIfValid(key: number): T {
        const cachedData = this.items.get(key);

        return cachedData &&
            isCacheValid(cachedData, this.cacheRefreshIntervalMs)
            ? cachedData.data
            : null;
    }

    clear() {
        this.items = new Map<number, CachedResponseData<T>>();
    }
}

export function isCacheValid<T>(
    cachedData: CachedResponseData<T>,
    cacheRefreshIntervalMs: number,
): boolean {
    return (
        cachedData &&
        new Date().getTime() - cachedData.lastFetchedAt.getTime() <
            cacheRefreshIntervalMs
    );
}
