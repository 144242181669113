<div
    class="logo-container"
    [style.width.px]="width"
    [style.height.px]="height"
    [class.fallback-logo]="isFallbackLogo"
    [class.is-circle]="isCircle"
    [class.with-rounded-borders]="roundedBorders"
    [class.bg-white]="whiteBackground"
>
    <img
        *ngIf="logoUrl"
        src="{{ logoUrl }}"
        #logo
        class="logo icon-size-{{ iconSize }}"
        [class.is-icon]="isFallbackLogoIcon"
        [class.is-circle]="isCircle"
        [class.with-rounded-borders]="roundedBorders"
        (error)="onLogoLoadingError(logo)"
    />
    <span
        *ngIf="showMaskedIcon"
        class="masked-icon logo icon-size-{{ iconSize }} is-icon"
        [ngStyle]="maskInlineCss"
    >
    </span>
</div>
