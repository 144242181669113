import type { OnInit } from '@angular/core';
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { templatesAdditionalMatchStatusColorsMap } from 'app/account/profile/employer-profile/profile-edit/section-messaging/templates-manager/templates-manager.constants';
import { chatMessagingThreadCandidateStatusColor } from '../messaging-chat/messaging-inbox/model/chat-messaging-thread-candidate-status.model';
import type { ChatMessagingThreadCandidateStatus } from '../messaging-chat/messaging-inbox/model/intex';
import type { EmployerConnectedMatchStatus } from '../models/enumeration/employer-connected-match-status.model';
import { connectedMatchStatusColor } from '../models/enumeration/employer-connected-match-status.model';
import { candidateConnectedMatchStatusColor } from '../models/enumeration/candidate-applied-matches.constants';

type Status =
    | ChatMessagingThreadCandidateStatus
    | EmployerConnectedMatchStatus
    | string;

@Component({
    selector: 'sp-status-label',
    templateUrl: './status-label.component.html',
    styleUrls: ['./status-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusLabelComponent implements OnInit {
    private static allStatusColorsMap: Record<Status, string | string[]> = null;

    @Input() status: Status;
    @Input() statusLabel: string;

    @HostBinding('class.uppercase')
    @Input()
    uppercase = true;

    get statusColor(): string {
        const color = StatusLabelComponent.allStatusColorsMap[this.status];
        if (Array.isArray(color)) {
            return color[0];
        }

        return color;
    }

    get borderLeftColor(): string {
        const color = StatusLabelComponent.allStatusColorsMap[this.status];
        if (Array.isArray(color)) {
            return color[1];
        }

        return color;
    }

    ngOnInit(): void {
        if (!StatusLabelComponent.allStatusColorsMap) {
            StatusLabelComponent.allStatusColorsMap = {
                ...templatesAdditionalMatchStatusColorsMap,
                ...chatMessagingThreadCandidateStatusColor,
                ...connectedMatchStatusColor,
                ...candidateConnectedMatchStatusColor,
            };
        }
    }
}
