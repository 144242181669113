import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    HostListener,
    Inject,
} from '@angular/core';
import type { DropdownItem } from '../../../models';
import {
    keyCodeArrowDown,
    keyCodeArrowUp,
    keyCodeBackspace,
    keyCodeEnter,
    keyCodeEsc,
} from '../../../../core/config.service';
import { isAlphanumericKey } from '../../../utils';
import { spPopupContentComponentData } from '../../../popup/popup.component';
import type { PopupRef } from '../../../popup/model';

@Component({
    selector: 'sp-mention-dropdown-list.component',
    templateUrl: './mention-dropdown-list.component.html',
    styleUrls: ['./mention-dropdown-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionDropdownListComponent {
    dropdownData: DropdownItem[];
    filteredData: DropdownItem[];
    filterString = '';
    private popupRef: PopupRef;

    @HostBinding('style.left.px') offsetLeft: number;
    @HostBinding('style.top.px') offsetTop: number;

    selectedIndex = 0;

    constructor(@Inject(spPopupContentComponentData) private data: any) {
        this.dropdownData = data.contentComponentData.dropdownData;
        this.filteredData = [...this.dropdownData];
        this.offsetLeft = data.contentComponentData.offsetLeft;
        this.offsetTop = data.contentComponentData.offsetTop;
        this.popupRef = data.popupHandle;
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.keyCode === keyCodeArrowUp) {
            this.selectedIndex =
                this.selectedIndex !== 0 ? this.selectedIndex - 1 : 0;
        } else if (event.keyCode === keyCodeArrowDown) {
            this.selectedIndex =
                this.selectedIndex < this.filteredData.length - 1
                    ? this.selectedIndex + 1
                    : this.selectedIndex;
        } else if (event.keyCode === keyCodeEnter) {
            this.popupRef.close(this.filteredData[this.selectedIndex].key);
        } else if (event.keyCode === keyCodeEsc) {
            this.popupRef.close();
        } else if (event.keyCode === keyCodeBackspace) {
            if (this.filterString.length > 0) {
                this.filterString = this.filterString.slice(0, -1);
                this.filterItems();
                this.selectedIndex = 0;
            } else {
                this.popupRef.close();
            }
        } else if (isAlphanumericKey(event.key)) {
            this.filterString = this.filterString.concat(event.key);
            this.filterItems();
            this.selectedIndex = 0;
        }
    }

    onItemClick($event: MouseEvent, key: string) {
        $event.stopPropagation();
        this.popupRef.close(key);
    }

    onMouseOver(index: number) {
        this.selectedIndex = index;
    }

    trackByFn(index, item) {
        return item.key;
    }

    private filterItems() {
        this.filteredData = this.dropdownData.filter((item) =>
            item.label
                .toLowerCase()
                .startsWith(this.filterString.toLowerCase()),
        );
    }
}
