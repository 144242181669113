<img class="image" src="{{ image }}" alt="" />
<div class="title">{{ title }}</div>
<div class="description" [innerHTML]="description"></div>
<div class="button-wrapper" *ngIf="buttonLabel">
    <sp-stateful-button
        [heightPx]="48"
        [isLoading]="isLoading"
        [label]="buttonLabel"
        [routerLink]="buttonNavigationLink"
        [queryParams]="queryParams"
        [context]="buttonContext"
        [inverted]="buttonInverted"
        (buttonClick)="onButtonClick()"
    >
    </sp-stateful-button>
</div>
<div class="button-status-msg" *ngIf="buttonStatusMessage">{{ buttonStatusMessage }}</div>
