import type { ComponentRef } from '@angular/core';
import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import type {
    ConnectedPosition,
    FlexibleConnectedPositionStrategyOrigin,
    OverlayRef,
    PositionStrategy,
} from '@angular/cdk/overlay';
import type { ComponentType } from '@angular/cdk/portal';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { delay, filter, take, tap } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { EMPTY, Subject, Subscription } from 'rxjs';
import {
    addCdkOverlayClass,
    isDefined,
    removeCdkOverlayClass,
    unsubscribeIfActive,
} from '../utils';
import { PopupRef, spPopupData } from './model';
import type { PopupBackgroundColor, PopupConfig, PopupData } from './model';
import { PopupComponent } from './popup.component';

const defaultPopupConfig: PopupConfig = {
    borderRadius: 3,
    hidePointer: false,
    stopPropagationForClickInside: false,
    theme: 'light',
};

const defaultConnectedPosition: ConnectedPosition = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetX: 0,
    offsetY: 0,
};

const popupOverlayCssClass = 'popup-above-navbar';

@Injectable({ providedIn: 'root' })
export class PopupService {
    private origin: FlexibleConnectedPositionStrategyOrigin;
    private popupRef: ComponentRef<PopupComponent>;
    private overlayRef: OverlayRef;

    private afterClosedSubject: Subject<any>;
    private subscription: Subscription;

    constructor(
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private injector: Injector,
    ) {}

    show<T>(
        origin: FlexibleConnectedPositionStrategyOrigin,
        contentComponent: ComponentType<T>,
        data?: PopupData,
        aboveNavbar = false,
    ): Observable<any> {
        if (this.isOpen()) {
            this.hide();

            return EMPTY;
        }
        this.subscription = new Subscription();

        const config: PopupConfig = this.computeConfig(data);
        const position: ConnectedPosition = this.computePosition(data);

        this.origin = origin;
        if (this.overlayRef) {
            if (this.overlayRef.hasAttached()) {
                this.overlayRef.detach();
            }

            const positionStrategy = this.computePositionStrategy(position);
            this.overlayRef.updatePositionStrategy(positionStrategy);
        } else {
            this.overlayRef = this.createOverlay(
                position,
                !!data?.config?.dismissOnScroll,
                !!data?.config?.hasBackdrop,
            );
        }
        this.watchOverlayDetachments();
        if (aboveNavbar) {
            addCdkOverlayClass(popupOverlayCssClass);
        }

        const portal = new ComponentPortal(
            PopupComponent,
            null,
            this.createInjector({
                popupRef: new PopupRef(this),
                popupData: { config },
            }),
        );
        this.popupRef = this.overlayRef.attach(portal);
        this.subscription.add(
            this.renderPopupContentAsync(
                contentComponent,
                data.popupData,
            ).subscribe(() => {}),
        );

        this.afterClosedSubject = new Subject<any>();
        this.watchOutsidePopupClick();

        return this.afterClosedSubject.asObservable();
    }

    hide(result?: any) {
        if (this.popupRef) {
            this.afterClosedSubject.next(result);
            this.afterClosedSubject.complete();

            this.hidePopup();
        }
    }

    emitData(data: any) {
        this.afterClosedSubject.next(data);
    }

    isOpen() {
        return !!this.popupRef;
    }

    private hidePopup() {
        if (isDefined(this.overlayRef)) {
            this.overlayRef.detach();
            removeCdkOverlayClass(popupOverlayCssClass);
        }
    }

    private createOverlay(
        position: ConnectedPosition,
        dismissOnScroll: boolean,
        hasBackdrop: boolean,
    ): OverlayRef {
        const positionStrategy = this.computePositionStrategy(position);
        const scrollStrategy = dismissOnScroll
            ? this.overlay.scrollStrategies.close()
            : this.overlay.scrollStrategies.reposition();

        return this.overlay.create({
            positionStrategy,
            hasBackdrop,
            scrollStrategy,
        });
    }

    private renderPopupContentAsync<T>(
        componentType: ComponentType<T>,
        popupComponentData: any,
    ): Observable<any> {
        const observable$ = this.popupRef.instance.rendered.pipe(
            take(1),
            // using delay(n) to capture more accurate dimensions of the rendered popup
            delay(10),
            tap(() => {
                if (this.popupRef) {
                    this.popupRef.instance.renderContent(
                        componentType,
                        popupComponentData,
                    );
                }
            }),
        );

        return observable$;
    }

    private createInjector(data: any): Injector {
        data = data || {};

        const tokens = new WeakMap();
        tokens.set(spPopupData, data);

        return new PortalInjector(this.injector, tokens);
    }

    private watchOutsidePopupClick() {
        // 'document:click' host listener can only be configured on a component. Thus,
        // PopupContainer component sets it by itself and notifies us when outside click happens
        this.popupRef.instance.outsideClick
            .pipe(
                // if event originates from the same anchor used to toggle dropdown opened initially
                // we just skip it. This allows us to NOT use event.stopPropagation() in unrelated
                // places
                filter(
                    (clickEventTarget) =>
                        clickEventTarget !== this.origin &&
                        !(this.origin as Element).contains(clickEventTarget),
                ),
                take(1),
            )
            .subscribe(() => this.hide());
    }

    private computeConfig(data: PopupData): PopupConfig {
        const providedConfig: PopupConfig =
            data && data.config ? data.config : {};

        return { ...defaultPopupConfig, ...providedConfig };
    }

    private computePosition(data: PopupData): ConnectedPosition {
        const position: ConnectedPosition =
            data && data.config
                ? {
                      ...defaultConnectedPosition,
                      overlayX: data.config.overlayX
                          ? data.config.overlayX
                          : defaultConnectedPosition.overlayX,
                      overlayY: data.config.overlayY
                          ? data.config.overlayY
                          : defaultConnectedPosition.overlayY,
                      originX: data.config.originX
                          ? data.config.originX
                          : defaultConnectedPosition.originX,
                      originY: data.config.originY
                          ? data.config.originY
                          : defaultConnectedPosition.originY,
                      offsetX: isDefined(data.config.offsetX)
                          ? data.config.offsetX
                          : null,
                      offsetY: isDefined(data.config.offsetY)
                          ? data.config.offsetY
                          : null,
                  }
                : defaultConnectedPosition;
        const color: PopupBackgroundColor =
            data?.config?.backgroundColor ?? 'white';
        position.panelClass = [
            `${position.overlayX}-${position.overlayY}`,
            `${position.originX}-${position.originY}`,
            color,
        ];

        return position;
    }

    private computePositionStrategy(position: ConnectedPosition) {
        const positionStrategy: PositionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.origin)
            .setOrigin(this.origin)
            .withPush(false)
            .withPositions([position]);

        return positionStrategy;
    }

    private watchOverlayDetachments() {
        const subscription = this.overlayRef.detachments().subscribe(() => {
            unsubscribeIfActive(this.subscription);
            this.overlayRef.dispose();
            this.popupRef = null;
            this.overlayRef = null;
        });
        this.subscription.add(subscription);
    }
}
