import { Component, Input } from '@angular/core';
import { TemplateStage } from '../models/enumeration/template-stage.model';

@Component({
    selector: 'sp-template-item',
    templateUrl: './template-item.component.html',
    styleUrls: ['./template-item.component.scss'],
})
export class TemplateItemComponent {
    @Input() title: string;
    @Input() body: string;
    @Input() stage: TemplateStage;
}
