import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { deleteObjectFromSet, setHasObject } from '../utils';

export interface IconButton<T = number> {
    id: T;
    icon?: string;
    invalidStateIcon?: string;
    name: string;
    selected?: boolean;
}

export interface IconButtonStyles {
    top?: string;
    height?: string;
    flexGrow?: string;
    minWidth?: string;
    maxWidth?: string;
    marginBottom?: string;
    marginRight?: string;
    marginLeft?: string;
    marginTop?: string;
    padding?: string;
    flexDirection?: string;
    justifyContent?: string;
    whiteSpace?: string;
    transform?: string;
    fontWeight?: number;
    display?: string;
    background?: string;
    border?: string;
}

const defaultSelectedButtonStyle = {
    background: 'rgba(222, 241, 237, 0.17)',
    border: '1px solid #00dcbf',
};

// TODO: evaluate usages and migrate to sp-form-input SpIconButtonsGroupControls
@Component({
    selector: 'sp-icon-buttons-group[items][control]',
    templateUrl: './icon-buttons-group.component.html',
    styleUrls: ['./icon-buttons-group.component.scss'],
})
export class IconButtonsGroupComponent<T> implements OnInit, OnChanges {
    @Input() items!: IconButton<T>[];
    @Input() control!: FormControl;
    @Input() allowToUnSelect = false;
    @Input() allowToExpand = false;
    @Input() allowToExpandSm = false;
    @Input() labelAlignStart = false;
    @Input() showVertical = false;
    @Input() invalid = false;
    @Input() isMultiselect = false;
    @Input() styles?: IconButtonStyles;
    @Input() iconStyles?: IconButtonStyles;
    @Input() checkIconStyles?: IconButtonStyles;
    @Input() listStyles?: IconButtonStyles;
    @Input() selectedButtonStyle: IconButtonStyles = defaultSelectedButtonStyle;
    @Input() maxSelectedItemsAllowed?: number;

    @Output() selectionChanged: EventEmitter<IconButton<T> | IconButton<T>[]> =
        new EventEmitter<IconButton<T> | IconButton<T>[]>();

    selectedItemsIds: Set<T> = new Set<T>();

    inlineStyles?: IconButtonStyles;

    get maximumButtonsSelected() {
        return (
            !!this.maxSelectedItemsAllowed &&
            this.selectedItemsIds.size >= this.maxSelectedItemsAllowed
        );
    }

    get combinedSelectedButtonStyle(): IconButtonStyles {
        return { ...this.inlineStyles, ...this.selectedButtonStyle };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.styles) {
            this.computeInlineStyles();
        }
    }

    ngOnInit() {
        if (!this.control) {
            throw new Error("'control' binding is required");
        }

        if (this.control.value) {
            this.selectedItemsIds = this.getSelectedFromFormControl();
        } else {
            this.selectedItemsIds = this.getSelectedFromRadiosArray();
        }
    }

    onItemClick(id: T) {
        if (this.allowToUnSelect && this.isSelected(id)) {
            this.selectedItemsIds = deleteObjectFromSet(
                this.selectedItemsIds,
                id,
            );
        } else {
            if (!this.isMultiselect) {
                this.selectedItemsIds.clear();
            }
            this.selectedItemsIds.add(id);
        }

        this.setValue();
    }

    resetSelection() {
        this.selectedItemsIds.clear();
        this.setValue();
    }

    disabledItem(id: T) {
        return (
            this.control.disabled ||
            (this.maximumButtonsSelected && !this.isSelected(id))
        );
    }

    isSelected(item: T) {
        return setHasObject(this.selectedItemsIds, item);
    }

    private getSelectedFromRadiosArray(): Set<T> {
        const selectedIds = this.items
            .filter((item) => item.selected)
            .map((item) => item.id);

        return new Set(selectedIds);
    }

    private getSelectedFromFormControl(): Set<T> {
        const selectedIds = this.isMultiselect
            ? (this.control.value as IconButton<T>[]).map((item) => item.id)
            : [this.control.value?.id as T];

        return new Set(selectedIds);
    }

    private computeInlineStyles() {
        if (this.styles) {
            this.inlineStyles = { ...this.styles };
        }
    }

    private setValue() {
        let selection: IconButton<T> | IconButton<T>[] = this.items.filter(
            (item) => this.isSelected(item.id),
        );
        if (!this.isMultiselect) {
            selection = selection.length > 0 ? selection[0] : [];
        }

        this.control.setValue(selection);
        this.selectionChanged.emit(selection);
    }
}
