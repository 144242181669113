import type { OnChanges, SimpleChanges } from '@angular/core';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
} from '@angular/core';

@Component({
    selector: 'sp-step-progress-animation',
    templateUrl: './step-progress-animation.component.html',
    styleUrls: ['./step-progress-animation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepProgressAnimationComponent implements OnChanges {
    @Input() width: number;
    @Input() backgroundColor: string;
    points = '0,0 0,0 0,0 0,0';

    // to achieve oblique polygon for progress bar we need right side of the polygon to have different x-axis dots
    // difference of those dots in px is described by progressBarTopBottomDiff
    progressBarTopBottomDiff = 70;

    constructor(private elRef: ElementRef<HTMLElement>) {}

    ngOnChanges(changes: SimpleChanges): void {
        const elHeight = this.elRef.nativeElement.clientHeight;
        const elWidth =
            (this.width * this.elRef.nativeElement.clientWidth) / 100;
        if (changes.width) {
            this.points = `0,0 ${
                elWidth + this.progressBarTopBottomDiff
            },0 ${elWidth},${elHeight} 0,${elHeight}`;
        }
    }
}
