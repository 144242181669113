<quill-editor
    class="wysiwyg-editor"
    [spAutosize]="true"
    [placeholder]="placeholder"
    [formControl]="formControl"
    [readOnly]="!enabled"
    theme="bubble"
    (keydown)="onKeyDown($event)"
    (onEditorCreated)="onEditorCreated($event)"
>
</quill-editor>
