import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { ProfileCompany } from '../shared/models/profile-company.interface';

const clearbitMaxAllowedCompanyNameLength = 99;

@Injectable()
export class ClearbitApiService {
    constructor(private http: HttpClient) {}

    searchCompany(search: string): Observable<ProfileCompany[]> {
        if (search.length > clearbitMaxAllowedCompanyNameLength) {
            search = search.substr(0, clearbitMaxAllowedCompanyNameLength);
        }

        return this.http.get<any[]>(
            'https://autocomplete.clearbit.com/v1/companies/suggest',
            {
                params: {
                    query: search,
                },
            },
        );
    }
}
