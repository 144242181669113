<div
    class="chips-container"
    [ngClass]="chipsMaxItemsWrapperClass"
    [class.with-counter]="control.showCounter"
>
    <span *ngIf="control.icon && !isTagContainerEmpty" [ngClass]="iconCssClass" class="field-icon">
    </span>
    <tag-input
        class="tag-input"
        [(ngModel)]="selectedValues"
        [class.sp-chips-first-tag-horizontal-offset]="control.icon"
        [theme]="control.theme ? control.theme : 'squarepeg-theme'"
        [placeholder]="control.placeholder"
        [secondaryPlaceholder]="control.secondaryPlaceholder"
        [dragZone]="control.dragZone"
        [hideForm]="true"
        [disable]="control.disabled"
        (onAdd)="onChange()"
        (onRemove)="onRemove($event)"
        #input
    >
        <ng-template let-item="item" let-index="index">
          <ng-container *ngIf="!useChipsWithConstraints; else chipWithConstraint">
            <span
              class="ng2-tag__text sp__draggable {{ item.rawValue?.cssClasses }}"
              (click)="onChipsControlClick($event)"
            >
                {{ item.value }}
            </span>
            <span
              *ngIf="!control.disabled"
              class="close-icon {{ item.rawValue?.cssClasses }}"
              (click)="input.removeItem(item, index)"
            >
            </span>
            <span *ngIf="control.disabled" class="close-icon"></span>
          </ng-container>
          <ng-template #chipWithConstraint>
            <sp-chip-with-constraint [model]="item"
                                     [disabled]="control.disabled"
                                     [dropdownItems]="control.autocomplete.constraintOptions"
                                     (constraintChange)="onChange()"
                                     (removeItem)="removeItem($event)">
            </sp-chip-with-constraint>
          </ng-template>
        </ng-template>
    </tag-input>
</div>

<ng-container *ngIf="showBottomCounter">
  <span class="bottom-counter" [class.invalid-count]="isMaxChipsExceeded">{{ bottomCounterValue }}</span>
</ng-container>

<span
    *ngIf="control.icon && isAutocompleteInputVisible && isTagContainerEmpty"
    [ngClass]="iconCssClass"
    class="field-icon"
>
</span>

<span
    *ngIf="control.showCounter && control.chipsMaxItems"
    [class.overflow]="control.chipsMaxItems < selectedValues.length"
    class="counter"
>
    {{ selectedValues.length }}/{{ control.chipsMaxItems }}
</span>

<!--note: v2 css class HAS to be set on the input UNTIL corresponding changes added previously to
sp-web-styles project to allow v1/v2 styles to coexist are removed-->
<input
    #autocompleteInputField
    [name]="control.name"
    [placeholder]="control.placeholder"
    [ngClass]="{ focused: control.focused }"
    [attr.disabled]="control.disabled ? 'disabled' : null"
    [class.iconless]="compositeControl"
    [class.autocomplete-input-hidden]="!isAutocompleteInputVisible"
    [class.with-counter]="control.showCounter"
    (blur)="onInputBlur(autocompleteInputField.value)"
    (keyup)="onInputKeyPressed($event, autocompleteInputField.value)"
    (focus)="onInputFocus()"
    class="input-field v2"
/>

<sp-autocomplete
    *ngIf="control.autocomplete && autocompleteInput?.nativeElement"
    [autocomplete]="autocompleteParams"
    class="autocomplete"
>
</sp-autocomplete>

<span *ngIf="control.showCaret" class="caret" [class.reverse]="isInputFocused" (click)="focusInput()"></span>
