<div class="content">
    <sp-base-header (closeClick)="onCloseClick()">
        <div class="header-sm">
            <sp-resolvable-logo
                class="company-logo"
                [logoUrl]="jobData.employerLogoUrl"
                [fallbackLogo]="'/images/icons/forms/city.svg'"
                [height]="28"
                [width]="28"
                [iconSize]="16"
                [roundedBorders]="false"
                [isFallbackLogoMasked]="true"
                [isMasked]="false"
            >
            </sp-resolvable-logo>
            <div class="job-title">{{ jobData.jobTitle }}</div>
        </div>
    </sp-base-header>
    <div class="body">
        <div class="title">Apply for this job</div>
        <sp-job-item *ngIf="jobData" class="job-item" [job]="jobData"></sp-job-item>

        <ng-container *ngIf="!showSpinner && !errorMsg">
            <div class="file-upload-wrapper">
                <div class="email-wrapper">
                    <div class="email-wrapper-title">Upload your resume</div>
                    <div class="email-box">{{ userData.email }}</div>
                </div>

                <sp-file-upload
                    class="file-upload"
                    [dropAreaMessage]="uploadDropAreaMessage"
                    [processingMessage]="uploadProcessingMessage"
                    [dropAreaFileTypeMessage]="'PDF or Word files supported'"
                    [uploadUrl]="uploadUrl"
                    [uploadType]="'resume'"
                    [errorMessage]="
                        'Something went wrong while analyzing your resume. Try uploading another one.'
                    "
                    [isDisabled]="false"
                    (uploadOutput)="onUploadOutput($event)"
                >
                </sp-file-upload>

                <div class="build-resume-separator">
                    <div class="separator-line"></div>
                    <span class="separator-text">or</span>
                    <div class="separator-line"></div>
                </div>

                <div class="build-resume">
                    <sp-stateful-button
                        [context]="ButtonContext.info"
                        [inverted]="true"
                        [leftIconUrl]="'/images/icons/wrench.svg'"
                        [label]="'Build a SquarePeg Resume'"
                        [routerLink]="'/account/profile/create/personal'"
                    >
                    </sp-stateful-button>
                </div>
            </div>
        </ng-container>

        <div *ngIf="showSpinner" class="spinner-wrapper">
            <img src="/images/spinner.svg" class="sp-dots-spinner spinner" />
            <div class="message-loading">Please wait while get things ready for you</div>
        </div>

        <div *ngIf="errorMsg" class="error-message" [innerHTML]="errorMsg"></div>
    </div>
</div>
