import { APP_INITIALIZER, NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';

const SCRIPT_CONFIG = {
    id: 'google-maps-script',
    type: 'text/javascript',
    async: true,
    defer: true,
};

export function loadGoogleMapsApi() {
    return () =>
        new Promise<void>((resolve) => {
            if (document.getElementById(SCRIPT_CONFIG.id)) {
                resolve();
            }

            const script = document.createElement('script');
            script.id = SCRIPT_CONFIG.id;
            script.type = SCRIPT_CONFIG.type;
            script.src =
                `https://maps.googleapis.com/maps/api/js?key=${environment.config.googleMapsApi.API_KEY}` +
                `&libraries=places&language=${environment.config.googleMapsApi.language}`;
            script.async = SCRIPT_CONFIG.async;
            script.defer = SCRIPT_CONFIG.defer;
            script.onload = () => resolve();
            document.body.appendChild(script);
        });
}

@NgModule({
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: loadGoogleMapsApi,
            multi: true,
        },
    ],
})
export class GoogleMapsModule {}
