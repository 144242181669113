import type { CandidateMatchSubStatus } from '../../matches/candidate/model/candidate-match-sub-status.model';
import type { EmployerDashboardMatchType } from '../../matches/employer/model/employer-dashboard-match-type';
import type { IdentifiableVersionedModel } from './identifiable-versioned-model.interface';
import type { DecisionType } from './enumeration/match-decision-type.model';
import type { Employer } from './employer.model';
import type { JobInfo } from './job-info.model';
import type { EmployerConnectedMatchStatus } from './enumeration/employer-connected-match-status.model';
import type { ScreeningQuestionAnswer } from './screening-question-answer.model';

export class Match implements IdentifiableVersionedModel {
    id: number;
    jobId: number;
    job: JobInfo;
    employer: Employer;
    totalMatchScore: number;

    createdDate: Date;
    modifiedDate: Date;

    candidateId?: number;
    candidateFirstName?: string;
    candidateName: string;
    candidateEmail?: string;
    isCandidateProfileComplete?: boolean;
    hasCandidateUploadedResume?: boolean;
    candidateWithIncompleteAssessments?: boolean;
    candidateScreeningQuestionAnswers: ScreeningQuestionAnswer[];

    candidateAction: DecisionType;
    candidateActionDate: Date;
    coverLetter?: string;

    employerAction: string;
    employerActionDate: Date;

    approvalDate?: Date;
    approvalStatus?: string;

    isSalaryMatch?: boolean;

    version: number;
    previousMatchId?: number;

    messagingChatChannelId?: string;
    screeningQuestionsCompleted?: boolean;

    candidateMatchSubStatus: CandidateMatchSubStatus;
    connectedMatchStatus: EmployerConnectedMatchStatus;

    employerDashboardMatchType?: EmployerDashboardMatchType;
    isViewedByEmployer?: boolean;
    isInvitedByEmployer?: boolean;
    lastModifiedDate?: Date;
    isScoreOverriden?: boolean;
}
