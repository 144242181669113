import { safeTrimConcatStrings } from '../../utils';
import { spLogoPath } from '../../../core/config.service';
import type { User } from '../../models';

const chatUserIdentityRegex = /\d+/gi;
const memberIdentityPrefix = 'SP-USER-';

export class ChatChannelMember {
    userId: number;
    identity: string;
    firstName: string;
    lastName: string;
    candidate: boolean;
    email?: string;
    profilePictureUrl?: string;

    isAccountManager: boolean;

    // Returns numeric id of the SP user parsed from a Chat Service user identity.
    static getSpUserIdFromIdentity(identity: string): number {
        chatUserIdentityRegex.lastIndex = 0;
        const regExpExecArray = chatUserIdentityRegex.exec(identity);

        return parseInt(regExpExecArray[0], 10);
    }

    static memberEqualIdAndIdentity(identity: string, id: number): boolean {
        return this.getSpUserIdFromIdentity(identity) === id;
    }

    static generateIdentityFromSpUserId(userId: number): string {
        return memberIdentityPrefix + userId;
    }

    static fromUser(user: User): ChatChannelMember {
        return Object.assign(new ChatChannelMember(user), {
            identity: ChatChannelMember.generateIdentityFromSpUserId(user.id),
            userId: user.id,
            candidate: false,
            isAccountManager: false,
        });
    }

    get fullName(): string {
        return safeTrimConcatStrings(this.firstName, this.lastName);
    }

    constructor(attributes: any) {
        this.userId = attributes.userId;
        this.identity = attributes.identity;
        this.firstName = attributes.firstName;
        this.lastName = attributes.lastName;
        this.candidate = attributes.candidate;
        this.email = attributes.email;
        this.isAccountManager = attributes.accountManager;
        this.profilePictureUrl = this.isAccountManager
            ? spLogoPath
            : attributes.profilePictureUrl;
    }
}
