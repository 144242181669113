import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { UserService } from '../user.service';
import { UserType } from '../../shared/models/user.model';

@Injectable()
export class EmployerGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> {
        return this.userService.getUserObservable().pipe(
            map((user) => {
                const canActivate = user.type === UserType.employer;

                if (!canActivate) {
                    const extras =
                        state.url.trim() !== '/'
                            ? { queryParams: { redirectTo: state.url } }
                            : {};
                    this.router.navigate(['/login/employer'], extras);
                }

                return canActivate;
            }),
        );
    }
}
