export enum ChatType {
    // Comments Service
    commentsMatch = 'commentsMatch',
    commentsJob = 'commentsJob',

    // Messaging Service
    messaging = 'messaging',
}

export type CommentChatType = ChatType.commentsMatch | ChatType.commentsJob;
