import { Component, Input } from '@angular/core';
import { SpFormControl } from '../sp-form-controls.model';
import { validationMessages as passwordValidationMessages } from '../../validators/password.validator';

@Component({
    selector: 'sp-password-validation-details[control]',
    templateUrl: './password-validation-details.component.html',
    styleUrls: ['./password-validation-details.component.scss'],
})
export class PasswordValidationDetailsComponent {
    @Input() control!: SpFormControl;

    get validationMessages() {
        return passwordValidationMessages;
    }

    get validationMessageKeys() {
        return Object.keys(this.validationMessages);
    }
}
