import type { OnDestroy, OnInit } from '@angular/core';
import { Component, HostBinding, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { WindowService } from '../../../core/window.service';
import { getMaskInlineCss, unsubscribeIfActive } from '../../utils';
import { StepperService } from './stepper.service';

@Component({
    selector: 'sp-stepper-header',
    templateUrl: './stepper-header.component.html',
    styleUrls: ['./stepper-header.component.scss'],
})
export class StepperHeaderComponent<T> implements OnInit, OnDestroy {
    @Input() direction: 'row' | 'column' = 'column';

    isSmallScreen = false;

    private subscription: Subscription;

    @HostBinding('class.row-orientation')
    get isWithColumnOrientation() {
        return this.direction === 'row';
    }

    get currentStepIndex() {
        return this.stepperService.currentHeaderStepIndex;
    }

    get highlightedStepIndex() {
        return this.stepperService.highlightedStepIndex;
    }

    get steps() {
        return this.stepperService.headerSteps;
    }

    get currentStep() {
        return this.steps[this.currentStepIndex];
    }

    get hideSteps(): boolean {
        return this.stepperService.hideSteps;
    }

    constructor(
        private stepperService: StepperService<T>,
        private windowService: WindowService,
    ) {
        this.subscription = new Subscription();
    }

    ngOnInit() {
        this.subscription = this.windowService.width$.subscribe(
            () => (this.isSmallScreen = this.windowService.isLtLarge()),
        );
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    getStepIconMask(stepIndex: number) {
        const iconPath =
            stepIndex < this.highlightedStepIndex
                ? '/images/icons/forms/check.svg'
                : this.stepperService.getHeaderStep(stepIndex).icon;

        return getMaskInlineCss('#32C6BA', iconPath, 'auto 20px');
    }

    onLeftButtonClick() {
        this.stepperService.backButton$.next(true);
    }
}
