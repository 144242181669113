import type { OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getFirstLetters } from '../utils';
import type { AvatarItem } from '../models';

@Component({
    selector: 'sp-avatars-stack',
    templateUrl: './avatars-stack.component.html',
    styleUrls: ['./avatars-stack.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarsStackComponent implements OnChanges {
    @Input() items: AvatarItem[] = [];
    @Input() maxStackItems = 3;
    @Input() hidePlusMore = false;
    @Input() size: number;
    @Input() label: string;

    plusMoreItems = 0;

    private fallbackAvatars = new Set<number>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items) {
            this.computePlusMoreItems();
            this.computeAvatarItems();
        }
    }

    showFallbackAvatar(avatarIndex: number) {
        return this.fallbackAvatars.has(avatarIndex);
    }

    onImageLoadError(index: number) {
        this.fallbackAvatars.add(index);
    }

    private computePlusMoreItems() {
        this.plusMoreItems = (this.items || []).length - this.maxStackItems;
        if (this.plusMoreItems < 0) {
            this.plusMoreItems = 0;
        }
    }

    private computeAvatarItems() {
        const items = [...(this.items || [])].slice(0, this.maxStackItems);
        items.forEach((item, index) => {
            if (!item.image) {
                this.fallbackAvatars.add(index);
            }
            item.initials = getFirstLetters(item.fullName, 2);
        });
        this.items = items;
    }
}
