<div
    ngui-auto-complete
    [ngClass]="{ 'input-autocomplete': true }"
    [outer-input-element]="externalInput || autocomplete.inputElement"
    [source]="filteredSource"
    [extFormControl]="autocomplete.formControl"
    [display-property-name]="autocomplete.displayPropertyName"
    [no-match-found-text]="autocomplete.noResultText"
    [re-focus-after-select]="autocomplete.reFocusAfterSelect"
    [accept-user-input]="autocomplete.acceptUserInput"
    [auto-select-first-item]="autocomplete.autoSelectFirstItem"
    [select-on-blur]="autocomplete.selectOnBlur"
    [select-value-of]="autocomplete.selectValueOf"
    [max-num-list]="autocomplete.maxItemsInList"
    [open-on-focus]="autocomplete.openOnFocus"
    [list-formatter]="autocomplete.listFormatter"
    [filters]="autocomplete.filters"
    [z-index]="autocomplete.zIndex"
    [match-formatted]="autocomplete.matchFormatted"
    [hide-on-no-match-found]="autocomplete.hideOnNoMatchFound"
    [close-on-focusout]="true"
    [in-use-item-label]="'IN USE'"
    (valueChanged)="onAutocompleteValueChanged($event)"
    (customSelected)="onAutocompleteCustomValueSelected($event)"
    (noMatchFoundAction)="onNoMatchFoundAction($event)"
    [max-height-top-gap]="130"
></div>
