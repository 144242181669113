import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { AssessmentInfo } from 'app/assessments/shared/models/assessment-info.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserType } from '../shared/models/user.model';
import { byOrderAscComparatorFn } from '../shared/utils';
import { UserService } from './user.service';

@Injectable()
export class AssessmentListService {
    constructor(
        private userService: UserService,
        private http: HttpClient,
    ) {}

    getAssessments(jobId?: number): Observable<AssessmentInfo[]> {
        let params = {};

        if (this.userService.user.type === UserType.employer) {
            params = { jobId };
        }

        return this.http
            .get<AssessmentInfo[]>('/api/assessments', { params })
            .pipe(
                map((response: AssessmentInfo[]) =>
                    response.sort(byOrderAscComparatorFn),
                ),
            );
    }
}
