import {
    Component,
    EventEmitter,
    HostBinding,
    Inject,
    Output,
} from '@angular/core';
import { SP_POPOVER_DATA } from '../popover/popover.service';
import { TemplateActionType } from '../models/enumeration/template-action-type.enum';

const defaultWidth = '350';

@Component({
    selector: 'sp-template-legend',
    templateUrl: './template-legend.component.html',
    styleUrls: ['./template-legend.component.scss'],
})
export class TemplateLegendComponent {
    readonly actionType = TemplateActionType;
    body: string;
    bottomButtons: boolean;

    @HostBinding('style.transform') transform = 'translate(0, 0)';
    @HostBinding('style.height.px') height;
    @HostBinding('style.width.px') width;
    @HostBinding('class.bottom-orientation') bottomOrientation = false;
    @HostBinding('class.left-orientation') leftOrientation = false;

    @Output() componentAction = new EventEmitter<TemplateActionType>();

    constructor(@Inject(SP_POPOVER_DATA) private data: any) {
        this.body = data.body.replace(/\n/g, '<br/>');
        this.bottomButtons = data.bottomButtons;
        this.transform = data.transform;
        this.height = data.height;
        this.width = data.width || defaultWidth;
        this.bottomOrientation = data.orientationVertical === 'bottom';
        this.leftOrientation = data.orientationHorizontal === 'left';
    }

    execTemplateAction(action: TemplateActionType) {
        this.componentAction.emit(action);
    }
}
