<div class="input-wrapper {{ spControlInputWrapperMarker }}" [formGroup]="control.form">
    <input
        [name]="control.name"
        type="text"
        [placeholder]="control.placeholder"
        [formControlName]="control.name"
        class="sp-input-field v2"
        [class.with-domain-logo]="!!control.fallbackLogo"
        [attr.disabled]="control.disabled ? 'disabled' : null"
        #inputField
    />
    <span
        *ngIf="!control.fallbackLogo"
        [ngClass]="inputFieldIconCssClasses"
        class="field-icon"
    ></span>
    <sp-resolvable-logo
        *ngIf="!!control.fallbackLogo"
        class="field-domain-icon size-{{ domainLogoSize }}"
        [domain]="control.domain"
        [roundedBorders]="false"
        [isMasked]="true"
        [fallbackLogo]="control.fallbackLogo"
        [width]="domainLogoSize"
        [height]="domainLogoSize"
    >
    </sp-resolvable-logo>
</div>

<sp-autocomplete
    [autocomplete]="v2AutocompleteParams"
    [externalInput]="inputField"
    class="autocomplete"
>
</sp-autocomplete>
