import type { OnInit } from '@angular/core';
import { Component, HostBinding, Input } from '@angular/core';
import {
    getFirstLetters,
    nameAndInitial,
    safeTrimConcatStrings,
} from '../utils';

@Component({
    selector: 'sp-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
    private _avatar;
    get avatar() {
        return this._avatar;
    }

    @Input()
    set avatar(value) {
        this._avatar = value;
        this.showInitials = !value;
    }

    // either full user name ('userName' binding) or first / last names of the user
    // should be provided
    @Input() userName;
    @Input() firstName;
    @Input() lastName;

    @Input() className = 'size-24';
    @Input() showTooltip: boolean;
    @Input() tooltipNameAndInitialOnly = false;
    @Input() borderless = false;

    @Input()
    @HostBinding('class.is-square')
    isSquare: boolean;

    initials: string;
    tooltipText: string;
    showInitials = false;

    @HostBinding('class') get class() {
        return this.className;
    }

    ngOnInit() {
        if (!this.userName) {
            this.userName = safeTrimConcatStrings(
                this.firstName,
                this.lastName,
            );
        }

        this.initials = this.nameInitials;
        this.tooltipText = this.computeTooltipText();
    }

    imageNotLoaded() {
        this.showInitials = true;
        this.avatar = null;
    }

    computeTooltipText(): string {
        let tooltipText = this.userName;
        if (this.tooltipNameAndInitialOnly) {
            const [firstName, lastName] = this.userName.split(' ');
            tooltipText = nameAndInitial(firstName, lastName);
        }

        return tooltipText;
    }

    get nameInitials() {
        return getFirstLetters(this.userName, 2);
    }
}
