<ul class="dropdown-items">
    <li
        *ngFor="let item of filteredData; let idx = index; trackBy: trackByFn"
        class="dropdown-item"
        [class.selected]="idx === selectedIndex"
        (mouseover)="onMouseOver(idx)"
        (click)="onItemClick($event, item.key)"
    >
        <img class="dropdown-item-icon" *ngIf="!!item.icon" src="{{ item.icon }}" />
        <sp-avatar
            *ngIf="!!item.avatar"
            [avatar]="item.avatar.imageUrl"
            [userName]="item.avatar.fullName"
        >
        </sp-avatar>
        <span class="dropdown-item-text">{{ item.label }}</span>
    </li>
</ul>
