import type { IDemographics } from '../../../../shared/models';
import {
    isBeforeNow,
    isDateAfter,
} from '../../../../shared/forms/validators/date.validator';
import { minYearDateAllowed } from '../../../../shared/constants';

export const preferNotToRespondKey = 'preferNotToRespond';

export const genderOptionLabel = {
    male: 'Male',
    female: 'Female',
    other: 'Other',
};

export const racialGroupOptionLabel = {
    hispanicOrLatino: 'Hispanic / Latino',
    nativeAmericanOrAmericanIndian: 'Native American',
    white: 'White',
    blackOrAfricanAmerican: 'Black / African American',
    asianPacificIslander: 'Asian / Pacific Islander',
    other: 'Other',
};

export class CandidateDemographics {
    racialGroups?: string[];
    gender?: string;

    birthday?: Date;

    constructor(demographics: IDemographics) {
        this.birthday =
            demographics.birthday && typeof demographics.birthday === 'string'
                ? new Date(demographics.birthday)
                : demographics.birthday;
        this.gender = demographics.gender;
        this.racialGroups = demographics.racialGroups
            ? [...demographics.racialGroups]
            : demographics.racialGroups;
    }

    isValid(): boolean {
        return (
            !this.birthday ||
            (isDateAfter(this.birthday, minYearDateAllowed) === null &&
                isBeforeNow(this.birthday))
        );
    }

    getGender(): string {
        return this.gender;
    }
}
