<form [formGroup]="form" class="request-access__form" (submit)="onSubmit()" novalidate>
    <sp-form-input
        [control]="controls['companyName']"
        class="request-access-form__{{ controls['companyName'].name }}"
    ></sp-form-input>
    <sp-form-input
        [control]="controls['email']"
        class="request-access-form__{{ controls['email'].name }}"
    ></sp-form-input>
    <sp-form-input
        [control]="controls['firstName']"
        class="request-access-form__{{ controls['firstName'].name }}"
    ></sp-form-input>
    <sp-form-input
        [control]="controls['lastName']"
        class="request-access-form__{{ controls['lastName'].name }}"
    ></sp-form-input>

    <span *ngIf="responseStatus === ResponseStatus.success" class="response-message">
        Your access request has been received. Once approved we’ll send you an email invite.
    </span>

    <span *ngIf="responseStatus === ResponseStatus.error" class="response-message error">
        We were unable to successfully complete your access request. Please try again later or
        contact <a href="mailto:{{ spInfoEmailAddress }}">info@squarepeghires.com</a> for help.
    </span>

    <sp-stateful-button
        [label]="'Request Access'"
        [context]="'primary'"
        [disabledFromOutside]="isDisabled"
        (buttonClick)="onSubmit()"
        [isLoading]="isSubmitting"
        class="submit-btn"
    >
    </sp-stateful-button>
</form>
