import { isDefined } from '../../../../shared/utils';
import { CANDIDATE_SALARY_RANGE } from '../../../../core/config.service';
import type { LocationType } from '../../../../shared/models/location-type.model';

export class CandidatePreferences {
    minSalary: number;
    maxSalary: number;
    preferredLocation?: LocationType;
    willingToRelocate?: boolean;
    communication?: boolean;
    distanceToCommute?: number;

    constructor(
        minSalary: number,
        maxSalary: number,
        preferredLocation: LocationType,
        willingToRelocate?: boolean,
        communication?: boolean,
        distanceToCommute?: number,
    ) {
        this.minSalary = minSalary;
        this.maxSalary = maxSalary;
        this.preferredLocation = preferredLocation;
        this.willingToRelocate = willingToRelocate;
        this.distanceToCommute = distanceToCommute || 50;
        this.communication = isDefined(communication) ? communication : true;
    }

    isValid(): boolean {
        return (
            isDefined(this.minSalary, this.maxSalary) &&
            this.minSalary <= this.maxSalary &&
            this.minSalary >= CANDIDATE_SALARY_RANGE.validMin &&
            this.minSalary <= CANDIDATE_SALARY_RANGE.max &&
            this.maxSalary >= CANDIDATE_SALARY_RANGE.validMin &&
            this.maxSalary <= CANDIDATE_SALARY_RANGE.max
        );
    }
}
