import type { Message } from '@twilio/conversations';
import { findMemberByEmail } from '../chat-utils';
import { ChatChannelMember } from './chat-channel-member.model';
import { ChatMessageType } from './chat-message-type.model';
import { ChatMessageAttributes } from './chat-message-attributes.model';

type MessageType = 'candidate' | 'employer' | 'employer-approval';

const spAccountManagerChatMessagePrefix = 'sp-admin-';
const spAccountManagerDefaultFullName = 'Account Manager';

export class ChatMessage {
    sid: string;
    index: number;

    // identity of a member in a Chat Service (e.g. 'SP-xxx' in Twilio)
    authorIdentity: string;
    message: string;
    attributes?: ChatMessageAttributes;
    date: Date; // local date

    isOwnedByCurrentUser: boolean;
    author?: ChatChannelMember;

    type?: ChatMessageType;
    contentUrl?: Promise<string>;
    fileName?: string;
    fileSize?: number;

    // custom attrs set on a message
    sentOnCandidateApproval?: boolean;

    static fromNativeMessage(
        message: Message,
        userIdentity: string,
        channelMembers?: Map<number, ChatChannelMember>,
    ): ChatMessage {
        const chatMessage = new ChatMessage(message);
        chatMessage.isOwnedByCurrentUser =
            chatMessage.authorIdentity === userIdentity;

        // if we already have resolved channel members - set author info on a message
        if (channelMembers) {
            if (chatMessage.isLegacyManagerMessage) {
                chatMessage.author =
                    ChatMessage.findByLegacyManagerMemberIdentity(
                        channelMembers,
                        chatMessage,
                    );
            } else {
                const spUserIdFromIdentity =
                    ChatChannelMember.getSpUserIdFromIdentity(
                        chatMessage.authorIdentity,
                    );
                chatMessage.author = channelMembers.get(spUserIdFromIdentity);
            }
        }

        return chatMessage;
    }

    static findByLegacyManagerMemberIdentity(
        channelMembers: Map<number, ChatChannelMember>,
        message?: ChatMessage,
    ): ChatChannelMember {
        // Previous messages sent by Account Manager have the author information stored on the Message itself
        // Using the email as identity we can try find the manager
        // information in participants data. ie: sp-admin-admin@localhost
        const email = message.authorIdentity.substr(
            spAccountManagerChatMessagePrefix.length,
        );
        let author = findMemberByEmail(channelMembers, email);

        if (!author) {
            // old format manager msg identity, and no participant id found
            const [firstName, lastName] = (
                message.attributes.fullName || spAccountManagerDefaultFullName
            ).split(' ');
            author = new ChatChannelMember({
                identity: message.author,
                firstName,
                lastName,
                candidate: false,
                accountManager: true,
            });
        }

        return author;
    }

    static isLegacyManagerMessage(authorIdentity: string): boolean {
        return authorIdentity.startsWith(spAccountManagerChatMessagePrefix);
    }

    get isMediaType() {
        return this.type === ChatMessageType.media;
    }

    get spUserId(): number {
        return ChatChannelMember.getSpUserIdFromIdentity(this.authorIdentity);
    }

    get messageType(): MessageType {
        return this.author.candidate
            ? 'candidate'
            : this.sentOnCandidateApproval
              ? 'employer-approval'
              : 'employer';
    }

    get isLegacyManagerMessage(): boolean {
        return ChatMessage.isLegacyManagerMessage(this.authorIdentity);
    }

    constructor(message: any) {
        this.sid = message.sid;
        this.index = message.index;
        this.authorIdentity = message.author;
        this.message = message.body;
        this.date = new Date(message.dateUpdated);
        this.type = message.type;
        this.sentOnCandidateApproval = !!(
            message.attributes && message.attributes.sentOnCandidateApproval
        );
        this.attributes = ChatMessageAttributes.of(message.attributes);
        if (message.type === 'media' && message.media) {
            this.contentUrl = message.media.getContentTemporaryUrl();
            this.fileName = message.media.filename;
            this.fileSize = message.media.size;
            this.message = message.attributes.attachmentMessage;
        }
    }
}
