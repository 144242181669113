import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { ContentChange } from 'ngx-quill';
import { FormControl } from '@angular/forms';
// https://github.com/KillerCodeMonkey/ngx-quill/issues/215
import * as Delta from 'quill-delta/lib/delta';
import type { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Quill from 'quill';
import type { RangeStatic } from 'quill';
import { PopupService } from '../../../popup/popup.service';
import { PopupDropdownComponent } from '../../../popup/popup-dropdown/popup-dropdown.component';
import type { MessageTemplate } from '../../../messaging-chat/model/message-template';
import {
    getNumberOfItemsOrderedByDate,
    unsubscribeIfActive,
} from '../../../utils';
import { TemplateMenuPopupComponent } from '../../../template-menu-popup/template-menu-popup.component';
import {
    linkButtonTooltip,
    numberOfRecentlyUsedTemplates,
} from '../../../constants';
import {
    createChunksFromString,
    customizeLinkBlot,
} from '../../../quill-utils';
import type { DropdownItem } from '../../../models';
import { SpTemplateEditorInput } from '../sp-form-controls.model';
import { TemplateMarker } from './template-fields';
import { InsertLinkTemplateComponent } from './insert-link-template/insert-link-template.component';

const Link = Quill.import('formats/link');

@Component({
    selector: 'sp-template-editor-wysiwyg',
    templateUrl: './template-editor-wysiwyg.component.html',
    styleUrls: ['./template-editor-wysiwyg.component.scss'],
})
export class TemplateEditorWysiwygComponent implements OnInit, OnDestroy {
    @Input() control: SpTemplateEditorInput;

    readonly linkButtonTooltip = linkButtonTooltip;

    private editor;
    private dropdownItems: DropdownItem[];
    formControl: FormControl;

    private readonly openTag = '{{';
    private readonly closeTag = '}}';
    private readonly openTagWithEscaping = '{{{';
    private readonly closeTagWithEscaping = '}}}';

    private placeholderRegexps: RegExp[];
    private delta: Delta;

    private templates: MessageTemplate[];
    isLoadingTemplates: boolean;
    templatesLoadingError: string | undefined;
    private subscription: Subscription;

    constructor(private popupService: PopupService) {}

    ngOnInit() {
        this.control.componentRef = this;

        this.initPlaceholderRegexps();
        if (this.control.formControl.value) {
            // if template is open for editing we have to convert it to a format
            // understandable by Quill i.e. to Delta
            this.delta = this.fromStringToDelta(this.control.formControl.value);
        }

        this.formControl = new FormControl();
        this.dropdownItems = this.control.availablePlaceholders.map((p) => ({
            key: p.value,
            label: p.label,
            needsEscaping: p.needsEscaping,
        }));

        // template-editor may be pre-configured to allow selection of the existing template
        if (this.control.templates$) {
            this.isLoadingTemplates = true;
            this.subscription = this.control.templates$
                .pipe(finalize(() => (this.isLoadingTemplates = false)))
                .subscribe(
                    (templates) => (this.templates = templates),
                    () => {
                        this.templatesLoadingError =
                            this.control.templatesLoadingError;
                    },
                );
        }
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    setValue(value: string) {
        this.delta = this.fromStringToDelta(value);
        this.editor.setContents(this.delta);
    }

    onEditorCreated(editor: Quill) {
        this.editor = editor;

        if (this.delta) {
            this.editor.setContents(this.delta);
        }

        Quill.register(customizeLinkBlot(Link));

        // configure custom matchers to be able to analyze and content being
        // pasted and extract parts recognized as placeholders
        this.editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) =>
            this.fromStringToDelta(node.textContent),
        );

        this.editor.clipboard.addMatcher(Node.TEXT_NODE, (node) =>
            this.fromStringToDelta(node.data),
        );
    }

    onContentChanged(contentChange: ContentChange) {
        const content = this.fromDeltaToString(contentChange.content);
        this.control.onContentChanged(content);
    }

    onInsertFieldButtonClick(event: MouseEvent) {
        event.stopPropagation();

        this.popupService
            .show(event.target as HTMLElement, PopupDropdownComponent, {
                config: {
                    overlayY: 'bottom',
                    borderRadius: 4,
                    offsetY: -25,
                    padding: '16px',
                },
                popupData: {
                    items: this.dropdownItems,
                    styles: {
                        padding: '16px 0',
                        itemHorizontalPadding: 0,
                    },
                },
            })
            .subscribe((placeholder) => {
                if (placeholder) {
                    this.insertPlaceholder(placeholder);
                }
            });
    }

    onSelectTemplateButtonClick(event: Event) {
        event.stopPropagation();
        if (this.templatesLoadingError) {
            return;
        }

        this.showTemplateMenuPopup(event, this.templates);
    }

    onInsertLinkButtonClick(event: MouseEvent) {
        const selection: RangeStatic = this.editor.getSelection();
        if (selection?.length) {
            this.popupService
                .show(
                    event.target as HTMLElement,
                    InsertLinkTemplateComponent,
                    {
                        config: {
                            overlayX: 'start',
                            overlayY: 'bottom',
                            offsetX: -25,
                            offsetY: -15,
                            borderRadius: 8,
                            hidePointer: true,
                        },
                        popupData: {},
                    },
                )
                .subscribe((url?: string) => {
                    if (url?.length) {
                        const text: string = this.editor.getText(
                            selection.index,
                            selection.length,
                        );
                        this.editor.deleteText(
                            selection.index,
                            selection.length,
                        );
                        this.editor.insertText(
                            selection.index,
                            text,
                            'link',
                            url,
                        );
                    }
                });
        }
    }

    // convert Quill's content Delta to a text w/o html markup
    private fromDeltaToString(content): string {
        if (!content.ops) {
            return '';
        }

        return content.ops
            .map((operation) => {
                if (operation['attributes'] && operation['attributes'].link) {
                    return `<a href="${operation['attributes'].link}">${operation.insert}</a>`;
                }

                const value =
                    operation.insert &&
                    operation.insert[TemplateMarker.blotName]
                        ? operation.insert[TemplateMarker.blotName].value
                        : operation.insert;

                return typeof value === 'string' ? value : '';
            })
            .join('');
    }

    // convert raw template text to Delta, data format used internally by Quill
    private fromStringToDelta(templateBody: string): Delta {
        const chunks = createChunksFromString(
            templateBody,
            this.placeholderRegexps,
        );

        // map chunks to Delta's operations via Delta API
        const delta = new Delta();
        chunks.forEach((chunk) => {
            // search for the zero-width-no-space unicode character which gets inserted
            // in case template was created via copy-and-pasting existing template
            // (http://www.fileformat.info/info/unicode/char/feff/index.htm)
            const chunkValue = chunk.value.replace(/\uFEFF/g, '');
            if (chunk.type === 'text') {
                delta.insert(chunkValue);
            } else if (chunk.type === 'link') {
                delta.insert(chunk.value, { link: chunkValue });
            } else {
                delta.insert({ TemplateMarker: { value: chunkValue } });
            }
        });

        return delta;
    }

    private insertPlaceholder(placeholder: DropdownItem) {
        const range = this.editor.getSelection(true);
        const value = this.control.labelAsPlaceholder
            ? placeholder.label
            : placeholder.key;
        this.editor.insertEmbed(range.index, TemplateMarker.blotName, {
            value: placeholder.needsEscaping
                ? `${this.openTagWithEscaping}${value}${this.closeTagWithEscaping}`
                : `${this.openTag}${value}${this.closeTag}`,
        });
        // move a caret after the inserted embed
        this.editor.setSelection(range.index + 1);
    }

    private initPlaceholderRegexps() {
        this.placeholderRegexps = this.control.availablePlaceholders.map(
            (placeholder) => {
                const placeholderString = this.control.labelAsPlaceholder
                    ? placeholder.label
                    : placeholder.value;

                return placeholder.needsEscaping
                    ? new RegExp(`\{\{\{(${placeholderString})\}\}\}`, 'g')
                    : new RegExp(`\{\{(${placeholderString})\}\}`, 'g');
            },
        );
    }

    private showTemplateMenuPopup(event, templates) {
        this.popupService.show(event.target, TemplateMenuPopupComponent, {
            config: {
                overlayX: 'start',
                overlayY: 'bottom',
                offsetX: -25,
                offsetY: -15,
                borderRadius: 8,
            },
            popupData: {
                selectTemplate: (template) => this.onSelectTemplate(template),
                allTemplatesSectionData: templates,
                recentlyUsedTemplatesSectionData: getNumberOfItemsOrderedByDate(
                    templates,
                    'recentlyUsedDate',
                    numberOfRecentlyUsedTemplates,
                ),
            },
        });
    }

    private onSelectTemplate(template: MessageTemplate) {
        this.control.selectTemplate.emit(template);
        this.popupService.hide();
        this.setValue(template.body);
    }
}
