export enum Constraint {
    mustHave = 'mustHave',
    niceToHave = 'niceToHave',
    dealBreaker = 'dealBreaker',
}

export const constraintLabels = {
    [Constraint.mustHave]: 'Must Have',
    [Constraint.niceToHave]: 'Nice to Have',
    [Constraint.dealBreaker]: 'Dealbreaker',
};

export const constraintOptionClasses = {
    [Constraint.mustHave]: 'selector must-have',
    [Constraint.niceToHave]: 'selector nice-to-have',
    [Constraint.dealBreaker]: 'selector deal-breaker',
};

export const constraintOrder: { [key in Constraint]: number } = {
    [Constraint.mustHave]: 1,
    [Constraint.niceToHave]: 2,
    [Constraint.dealBreaker]: 3,
};

export function byConstraintComparator<T extends { constraint: Constraint }>(
    a: T,
    b: T,
): number {
    return constraintOrder[a.constraint] - constraintOrder[b.constraint];
}
