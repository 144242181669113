import { RequiredActionInfoType } from '../models/required-action-info-type.model';
import type { RequiredActionInfo } from '../models/required-action-info.model';
import type { RequiredActionInfoItem } from './required-action-info.component';

export enum RequiredActionCTA {
    click = 'Click',
    dismiss = 'Dismiss',
}

export function mapToRequiredActionItem(
    requiredActionAlarm: RequiredActionInfo,
): RequiredActionInfoItem {
    let requiredActionItem: RequiredActionInfoItem = null;

    switch (requiredActionAlarm.type) {
        case RequiredActionInfoType.pendingReviewPerJob:
            requiredActionItem =
                getPendingReviewPerJobItem(requiredActionAlarm);

            break;
        case RequiredActionInfoType.pendingReviewCandidate:
            requiredActionItem =
                getPendingReviewCandidateItem(requiredActionAlarm);

            break;
        case RequiredActionInfoType.notContactedCandidatesPerJob:
            requiredActionItem =
                getNotContactedCandidatesPerJobItem(requiredActionAlarm);

            break;
        case RequiredActionInfoType.requireResponseCandidateTotal:
            requiredActionItem =
                getRequireResponseCandidateTotalItem(requiredActionAlarm);

            break;
        case RequiredActionInfoType.requireResponseCandidatePerJob:
            requiredActionItem =
                getRequireResponseCandidatePerJobItem(requiredActionAlarm);

            break;
    }

    return requiredActionItem;
}

function getPendingReviewPerJobItem(
    item: RequiredActionInfo,
): RequiredActionInfoItem {
    return {
        id: `pendingReviewPerJob_${item.employerJobId}`,
        jobId: item.employerJobId,
        type: item.type,
        icon: 'synchronize',
        description:
            `<b>${item.count} candidate(s)</b> for: <b>${item.jobTitle}</b> have been` +
            ` pending review for at least <b>${item.numberOfDays} days</b>`,
        navigationUrl: `/jobs/${item.employerJobId}/matches/applications`,
    };
}

function getPendingReviewCandidateItem(
    item: RequiredActionInfo,
): RequiredActionInfoItem {
    return {
        id: `pendingReviewCandidate_${item.matchId}`,
        jobId: item.employerJobId,
        type: item.type,
        icon: 'synchronize',
        description: `<b>${item.candidateName}</b> has been pending review for: <b>${item.jobTitle}</b> for more than <b>5 days</b>`,
        navigationUrl: `/jobs/${item.employerJobId}/matches/applications/${item.matchId}`,
    };
}

function getNotContactedCandidatesPerJobItem(
    item: RequiredActionInfo,
): RequiredActionInfoItem {
    return {
        id: `notContactedCandidatesPerJob_${item.employerJobId}`,
        jobId: item.employerJobId,
        type: item.type,
        icon: 'email',
        description: `<b>${item.count} approved candidate(s)</b> for: <b>${item.jobTitle}</b> have not been contacted yet`,
        navigationUrl: `/jobs/${item.employerJobId}/matches/in-progress`,
    };
}

function getRequireResponseCandidateTotalItem(
    item: RequiredActionInfo,
): RequiredActionInfoItem {
    return {
        id: `requireResponseCandidateTotal`,
        type: item.type,
        icon: 'warning_solid',
        description: `You have <b>${item.count} candidate messages</b> that <b>require a response</b>`,
        navigationUrl: `/messages`,
    };
}

function getRequireResponseCandidatePerJobItem(
    item: RequiredActionInfo,
): RequiredActionInfoItem {
    return {
        id: `requireResponseCandidatePerJob_${item.employerJobId}`,
        jobId: item.employerJobId,
        type: item.type,
        icon: 'warning_solid',
        description: `You have <b>${item.count} candidate messages</b> that <b>require a response</b>`,
        navigationUrl: `/messages`,
    };
}
