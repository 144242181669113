import type {
    CandidateTraitResult,
    IMatchSectionResult,
} from '../../matches/candidate/model';
import { DashboardMatchType } from '../../matches/candidate/model';
import { CandidateMatchSubStatus } from '../../matches/candidate/model/candidate-match-sub-status.model';
import { toDate } from '../date-utils';
import { Match } from './match.model';
import { JobInfo } from './job-info.model';
import { DashboardJobType } from './enumeration/dashboard-job-type.enum';

export class MatchCandidate extends Match {
    candidateFavorite?: boolean;

    skillScore?: number;
    traitScore?: number;
    workExperienceScore?: number;
    matchResults?: {
        skillResults?: IMatchSectionResult[];
        traitResults?: CandidateTraitResult[];
        industryResults?: IMatchSectionResult[];
    };
    isSampleMatch?: boolean; // set on web-app only for style purpose
    isEmpty?: boolean;

    static of(match): MatchCandidate {
        const m: MatchCandidate = Object.assign(new MatchCandidate(), match);
        m.createdDate = toDate(match.createdDate);
        m.modifiedDate = toDate(match.modifiedDate);
        m.candidateActionDate = toDate(match.candidateActionDate);
        m.employerActionDate = toDate(match.employerActionDate);
        m.job = JobInfo.of(match.job);

        return m;
    }

    get isViewedByCandidate() {
        return (
            this.candidateMatchSubStatus !==
            CandidateMatchSubStatus.bb_nonViewed
        );
    }

    get isDisqualified() {
        return (
            this.candidateMatchSubStatus ===
            CandidateMatchSubStatus.aa_disqualified
        );
    }

    get isClosed() {
        return this.job.status === DashboardJobType.archived;
    }

    get hasUnansweredRequiredScreeningQuestions() {
        return (
            this.job.screeningQuestionsRequired &&
            !this.screeningQuestionsCompleted
        );
    }

    get hasNotProvidedRequiredCoverLetter() {
        return this.job.requireCoverLetter && !this.coverLetter;
    }

    get hasNotProvidedRequiredTraitsAssessments() {
        return (
            !this.job.getOptionalTraitsAssessments() &&
            this.candidateWithIncompleteAssessments
        );
    }

    get withCompletedAllRequiredActions() {
        return (
            !this.hasUnansweredRequiredScreeningQuestions &&
            !this.hasNotProvidedRequiredCoverLetter &&
            !this.hasNotProvidedRequiredTraitsAssessments
        );
    }

    get withRequiredFields() {
        return (
            this.job.screeningQuestionsRequired ||
            this.job.requireCoverLetter ||
            !this.job.getOptionalTraitsAssessments()
        );
    }

    get matchPathSuffix(): string {
        return this.id
            ? `${this.candidateDashboardMatchType}/${this.id}`
            : `${DashboardMatchType.review}/job/${
                  this.job ? this.job.id : this.jobId
              }`;
    }

    get candidateDashboardMatchType(): DashboardMatchType {
        if (this.job.status === DashboardJobType.archived) {
            return DashboardMatchType.archived;
        }
        switch (this.candidateMatchSubStatus) {
            case CandidateMatchSubStatus.ba_invited:
            case CandidateMatchSubStatus.bb_nonViewed:
            case CandidateMatchSubStatus.bc_inReview:
                return DashboardMatchType.review;
            case CandidateMatchSubStatus.aa_disqualified:
            case CandidateMatchSubStatus.da_withdrew:
            case CandidateMatchSubStatus.db_archived:
                return DashboardMatchType.archived;
            case CandidateMatchSubStatus.ca_applied:
            case CandidateMatchSubStatus.cb_pendingInProgress:
            case CandidateMatchSubStatus.cc_interviewing:
            case CandidateMatchSubStatus.cd_phoneScreen:
            case CandidateMatchSubStatus.ce_pendingOffers:
            case CandidateMatchSubStatus.cf_accepted:
            case CandidateMatchSubStatus.cg_declined:
                return DashboardMatchType.applied;
            default:
                return null;
        }
    }

    get isExisting(): boolean {
        return !!this.id;
    }

    get employerLogo(): string | undefined {
        return this.job.isStealth
            ? '/images/stealth/stealth_icon.svg'
            : this.employer.thumbnailLogoUrl;
    }

    get employerName(): string | undefined {
        return this.job.isStealth
            ? 'Company name is hidden'
            : this.employer.employerName;
    }
}
