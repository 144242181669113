import { toDate } from '../date-utils';
import { getFirstLetters, safeTrimConcatStrings } from '../utils';
import { Employer } from './employer.model';
import type { Subscription } from './subscription.model';
import type { Team } from './team.model';
import type { AvatarItem } from './avatar-item.model';

export enum UserType {
    candidate = 'candidate',
    employer = 'employer',
}

export enum UserInteractionEventType {
    useHelpMenu = 'useHelpMenu',
}

export interface UserBasicInfo {
    id: number;
    firstName: string;
    lastName: string;
    email?: string;
    profilePictureUrl?: string;
}

export enum EmployerUserRole {
    recruiter = 'recruiter',
    hiringManager = 'hiringManager',
    headOfHR = 'headOfHR',
    ceo = 'ceo',
}

export function userToAvatarItemMapperFn(userData: User): AvatarItem {
    const user = User.from(userData);

    return {
        image: user.profilePictureUrl,
        firstName: user.firstName,
        lastName: user.lastName,
        initials: getFirstLetters(user.fullName),
        fullName: user.fullName,
    };
}

export class User implements UserBasicInfo {
    id: number;
    firstName: string;
    lastName: string;
    displayName: string;
    type: UserType;
    email: string;
    birthday?: Date;
    phoneNumber?: string;
    website?: string;
    summary?: string;
    minSalary?: number;
    maxSalary?: number;
    willingToRelocate?: boolean;
    token?: string;
    assessments?: any[];
    teams?: Team[];
    teamName?: string;
    linkedinId?: string;
    profilePictureUrl?: string;
    publicProfileUrl?: string;
    newUser?: boolean;
    subscription?: Subscription;
    employer?: Employer;
    isProfileComplete?: boolean;
    hasParsedResume?: boolean;
    hasUploadedResume?: boolean;
    lastLoginDate?: Date;
    createdDate?: Date;
    suspendedDate?: Date;
    suspendedUntil?: Date;
    position?: string;
    feedUUID?: string;
    completedAllAssessments?: boolean;
    generatedMatchesCount?: number;
    appliedMatchesCount?: number;
    optionalTraitsAssessments?: boolean;
    hasPassword?: boolean;
    displaySectionScores?: boolean;
    interactionEvents?: UserInteractionEventType[];
    inviteToApplyOptIn?: boolean;

    userImpersonating: UserBasicInfo;

    static from(user: User | null): User {
        if (!user) {
            return null;
        }

        return Object.assign(new User(), user, {
            lastLoginDate: toDate(user.lastLoginDate),
            createdDate: toDate(user.createdDate),
            employer: Employer.from(user.employer),
        });
    }

    get fullName() {
        return safeTrimConcatStrings(this.firstName, this.lastName);
    }

    get allowedToAccessMatches() {
        return this.isProfileComplete || this.hasUploadedResume;
    }

    get isFreeTrialActive() {
        return !!this.employer?.daysRemainingInTrial;
    }

    get candidateRequiresCompletion(): boolean {
        return (
            this.type === UserType.candidate &&
            (!this.isProfileComplete ||
                !this.hasUploadedResume ||
                !this.completedAllAssessments)
        );
    }

    get isEnabledEmployer(): boolean {
        return !!this.employer?.accountEnabled;
    }
}
