import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input, ViewChild } from '@angular/core';
import type { Subscription } from 'rxjs';
import type { FormControl, ValidationErrors } from '@angular/forms';
import type { IDateValue } from '../sp-form-controls.model';
import {
    SpMaskedDateInput,
    SpMaskedDateRangeInput,
} from '../sp-form-controls.model';
import { isDefined, unsubscribeIfActive } from '../../../utils';
import { isDateAfterToday } from '../../validators/date.validator';

export const maskedDateRangeDateToMarker = 'masked-to-date-field-marker';

@Component({
    selector: 'sp-masked-date-range-input',
    templateUrl: './masked-date-range-input.component.html',
    styleUrls: ['./masked-date-range-input.component.scss'],
})
export class MaskedDateRangeInputComponent implements OnInit, OnDestroy {
    readonly maskedDateRangeDateToMarker = maskedDateRangeDateToMarker;

    @Input() control: SpMaskedDateRangeInput;

    // referencing sp-form-control for a single purpose of being able to clear input on current date checkbox toggle
    @ViewChild('toDateFormInput') toDateFormInput;

    fromDateControl: SpMaskedDateInput;
    toDateControl: SpMaskedDateInput;
    toDateIsCurrentDate: boolean;

    private fromDate: IDateValue;
    private toDate: IDateValue;

    private subscription: Subscription;
    private defaultEndDateFieldLabel: string;

    prefixIn = (key: string, prefix: string) => key.startsWith(prefix);
    prefixNotIn = (key: string, prefix: string) => !key.startsWith(prefix);

    ngOnInit() {
        this.defaultEndDateFieldLabel = this.control.labelTo;

        this.fromDate = this.control._value.from || { value: null };
        this.toDate = this.control._value.to || { value: null };
        this.toDateIsCurrentDate = this.control._value
            ? !!this.control._value.to.isCurrent
            : false;

        this.fromDateControl = this.getDateControlConfig(
            this.fromDate,
            this.control.labelFrom,
        );
        this.toDateControl = this.getDateControlConfig(
            this.toDate,
            this.control.labelTo,
        );
        if (this.control.allowFutureDates) {
            this.toDateControl.disabled = false;
            if (
                this.control.labelForIsCurrentToggled &&
                (this.toDateIsCurrentDate ||
                    (this.toDate.value &&
                        this.toDate.value.getTime() > new Date().getTime()))
            ) {
                this.toDateControl.label =
                    this.control.labelForIsCurrentToggled;
            } else {
                this.updateLabelForToDate();
            }
        } else {
            this.toDateControl.disabled = this.toDateIsCurrentDate;
        }

        this.subscription = this.fromDateControl.valueChange.subscribe(
            (value) => this.onFromValueChange(value),
        );
        this.subscription.add(
            this.toDateControl.valueChange.subscribe((value) =>
                this.onToValueChange(value),
            ),
        );
        this.subscription.add(
            this.control.formControl.statusChanges.subscribe((state) => {
                this.updateChildrenValidationState(state);
            }),
        );
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    onCurrentDateToggle(checked) {
        this.toDateIsCurrentDate = checked;
        this.toDateControl.disabled =
            this.toDateIsCurrentDate && !this.control.allowFutureDates;
        this.toDateControl.formControl.reset();

        if (this.toDateIsCurrentDate) {
            if (!this.control.allowFutureDates) {
                this.toDateControl.value = null;
                this.toDateFormInput.spFormInputChildComponent.model = ''; // reset currently selected 'to' date
                this.toDate = { value: null, isCurrent: true };
            } else {
                this.toDate.isCurrent = true;
            }

            if (this.control.labelForIsCurrentToggled) {
                this.toDateControl.label =
                    this.control.labelForIsCurrentToggled;
            }
        } else {
            this.toDate.isCurrent = false;

            this.toDateControl.label = this.defaultEndDateFieldLabel;
        }

        this.updatePlaceholder();
        this.emit();
    }

    private onFromValueChange(value: IDateValue) {
        this.fromDate = { ...value, isOptional: this.fromDate.isOptional };
        this.emit();
    }

    private onToValueChange(value: IDateValue) {
        this.toDate = {
            ...value,
            isCurrent: this.toDate.isCurrent,
            isOptional: this.toDate.isOptional,
        };
        this.emit();
        this.updateLabelForToDate();
        this.updatePlaceholder();
    }

    private getDateControlConfig(value: IDateValue, label: string) {
        return new SpMaskedDateInput(
            Object.assign({} as SpMaskedDateInput, this.control, {
                form: null,
                label,
                value,
                // explicitly disable isCurrent mode on underlying solo masked-date-input's;
                // isCurrent mode is implemented on a masked-date-range-input due to issues
                // with checkbox positioning in different layouts
                hasIsCurrentOption: false,
                labelIsCurrentOption: null,
                hint: '',
                validators: [],
            }),
        );
    }

    private emit() {
        this.control.onValueChange({
            from: this.fromDate,
            to: this.toDate,
        });
    }

    // Helper to allow setting validation states on child sp-form-input controls separately
    // rather then validating entire masked-date-range-input control as a single form control
    // Please note that to be able to set validation state per child we analyze error keys
    // returned by validator function. Thus, keys starting with 'from' prefix will result
    // in error state set on Start Date child component, 'to' in error state set on End Date
    // child component and any other key will set error state on both children (e.g. Start > End)
    private updateChildrenValidationState(state) {
        this.filterValidationErrorsByPrefix(
            this.control.formControl.errors,
            'to',
            this.prefixIn,
            this.toDateControl.formControl,
        );
        this.filterValidationErrorsByPrefix(
            this.control.formControl.errors,
            'to',
            this.prefixNotIn,
            this.fromDateControl.formControl,
        );
    }

    private updateLabelForToDate() {
        if (isDefined(this.control.labelForFutureDateSelected)) {
            if (
                isDefined(this.toDate.value) &&
                isDateAfterToday(this.toDate.value)
            ) {
                this.toDateControl.label =
                    this.control.labelForFutureDateSelected;
            } else {
                this.toDateControl.label = this.defaultEndDateFieldLabel;
            }
        }
    }

    private updatePlaceholder() {
        const value = this.toDateIsCurrentDate
            ? this.control.placeholderForIsCurrentTo
            : null;
        this.toDateFormInput.spFormInputChildComponent.setPlaceholder(value);
    }

    private filterValidationErrorsByPrefix(
        errors: ValidationErrors | null,
        prefix: string,
        predicate,
        control: FormControl,
    ) {
        const filteredErrors: ValidationErrors = {};
        if (isDefined(errors)) {
            for (const [key, value] of Object.entries(errors)) {
                if (predicate(key, prefix)) {
                    filteredErrors[key] = value;
                }
            }
        }

        control.setErrors(
            Object.keys(filteredErrors).length > 0 ? filteredErrors : null,
        );
        control.markAsTouched();
        control.markAsDirty();
    }
}
