import type { StepperStep } from '../../../../shared/models/stepper/stepper-step';

export enum CandidateProfileStepType {
    resume = 'resume',
    resumeReview = 'resumeReview',
    workExperience = 'workExperience',
    education = 'education',
    certificatesAndLicenses = 'certificatesAndLicenses',
    accomplishments = 'accomplishments',
    skills = 'skills',
    preferences = 'preferences',
    workplacePriorities = 'workplacePriorities',
    demographics = 'demographics',
    review = 'review',
}

export const candidateProfileSteps: StepperStep<CandidateProfileStepType>[] = [
    {
        title: 'Resume Upload',
        url: 'resume',
        icon: '/images/icons/upload_primary.svg',
        type: CandidateProfileStepType.resume,
        hideNextButton: true,
        outOfStepper: false,
    },
    {
        title: 'Resume',
        url: 'personal',
        icon: '/images/icons/note_default.svg',
        type: CandidateProfileStepType.resumeReview,
        outOfStepper: false,
    },
    {
        title: 'Work Experience',
        url: 'work-experience',
        icon: '/images/icons/note_default.svg',
        type: CandidateProfileStepType.workExperience,
        parentType: CandidateProfileStepType.resumeReview,
        outOfStepper: false,
    },
    {
        title: 'Skills',
        url: 'skills',
        icon: '/images/icons/skills_primary.svg',
        type: CandidateProfileStepType.skills,
        outOfStepper: false,
    },
    {
        title: 'Preferences',
        url: 'preferences',
        icon: '/images/icons/review_primary.svg',
        type: CandidateProfileStepType.preferences,
        outOfStepper: false,
    },
    {
        title: 'Demographics',
        url: 'demographics',
        icon: '/images/icons/review_primary.svg',
        type: CandidateProfileStepType.demographics,
        outOfStepper: false,
    },
    {
        title: 'Review',
        url: 'review',
        icon: '/images/icons/forms/eyeball.svg',
        type: CandidateProfileStepType.review,
        outOfStepper: false,
    },
];
