import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import type { OverlayRef } from '@angular/cdk/overlay';
import {
    cdkOverlayContainerBackdropShowingCssClass,
    dialogOverlayCssClass,
} from './constants';
import { removeCdkOverlayClass } from './utils';
import type { DialogTitle } from './dialog/model/dialog-title.model';

export class DialogRef<T, R = any> {
    primaryButtonClick$: Subject<void> = new Subject<void>();
    secondaryButtonClick$: Subject<void> = new Subject<void>();
    alternativeButtonClick$: Subject<void> = new Subject<void>();

    title$: Subject<DialogTitle | string> = new Subject<DialogTitle | string>();

    primaryButtonDisabled: boolean;
    secondaryButtonDisabled: boolean;
    alternativeButtonDisabled: boolean;

    componentInstance: T;

    /**
     * Disable closing modal from within a child dialog content component
     *
     * By default, dialog can be closed by clicking on a backdrop or on a close icon in the
     * dialog's header (if configured with a header and close button). To be able to disable
     * closing of the dialog from within a child content component (e.g. disable closing for
     * the duration of the API request) this property should be set to 'true' on the dialog-ref
     * instance injected into a child content component. Should be set back to a 'false' later
     * to release a modal for a normal flow
     *
     * @type {boolean}
     */
    disableClosing = false;

    private afterClosedSubject = new Subject<R | undefined>();
    private onHeaderButtonClickSubject = new Subject<R | undefined>();

    constructor(private overlayRef: OverlayRef) {}

    afterClosed(): Observable<R | undefined> {
        return this.afterClosedSubject.asObservable();
    }

    onHeaderButtonClick(): Subject<R | undefined> {
        return this.onHeaderButtonClickSubject;
    }

    backdropClick(): Observable<MouseEvent> {
        return this.overlayRef.backdropClick();
    }

    close(dialogResult?: R): void {
        if (!this.disableClosing && this.overlayRef.hasAttached()) {
            this.overlayRef.dispose();

            this.onHeaderButtonClickSubject.complete();
            this.removeCdkOverlayClassForModals();
            this.afterClosedSubject.next(dialogResult);
            this.afterClosedSubject.complete();
        }
    }

    sendData(dialogResult?: R): void {
        if (!this.disableClosing && this.overlayRef.hasAttached()) {
            this.disableClosing = true;
            this.onHeaderButtonClickSubject.next(dialogResult);
        }
    }

    disableButtons(disable: boolean) {
        this.primaryButtonDisabled = disable;
        this.secondaryButtonDisabled = disable;
        this.alternativeButtonDisabled = disable;
        this.disableClosing = disable;
    }

    private removeCdkOverlayClassForModals() {
        // In order to revert default z-index to 'cdk-overlay-container' we need to remove additional class
        // that we append on opening modal ('dialog-overlay').
        // However, there is a case when dialog is opened from within dialog. To handle that case properly
        // we need to check whether document contains element with
        // cdkOverlayContainerBackdropShowingCssClass ('cdk-overlay-backdrop-showing') class - which hints that
        // other modal is already opened. In that case, we do not remove 'dialog-overlay' class.
        if (
            !document.getElementsByClassName(
                cdkOverlayContainerBackdropShowingCssClass,
            ) ||
            document.getElementsByClassName(
                cdkOverlayContainerBackdropShowingCssClass,
            ).length === 0
        ) {
            removeCdkOverlayClass(dialogOverlayCssClass);
        }
    }
}
