<div
    *ngIf="data.usePlain; else deprecatedV2"
    class="dialog-panel"
    [class.content-view]="data.contentView"
    [class.visible-overflow]="data.visibleContentOutsideModal"
>
    <h1
        *ngIf="!data.contentView"
        class="dialog-header-title title-align-{{ data.titleAlign }} {{ data.headerClass }}"
        [class.hide-header-border]="data.hideHeaderBottomBorder"
    >
        <img *ngIf="data.headerLogo" src="{{ data.headerLogo }}" class="header-logo" />
        <span *ngIf="data.headerImage" class="header-icon {{ data.headerImage }}"> </span>
        <ng-container *ngIf="!!$any(title)?.chunks">
            <span *ngFor="let chunk of $any(title).chunks" [style.color]="chunk.color"
                >{{ chunk.text }}
            </span>
        </ng-container>
        <ng-container *ngIf="!$any(title)?.chunks">{{ data.title }}</ng-container>
        <span *ngIf="infoMessage" class="info-icon" spTooltip [tooltipText]="infoMessage"></span>
        <a href="javascript:" class="close-button" (click)="onCloseDialog($event)"></a>
    </h1>

    <div *ngIf="!!data.headerButtons" class="content-view-header">
        <a
            *ngFor="let button of data.headerButtons"
            class="header-button"
            href="javascript:"
            (click)="onContentViewHeaderButtonClick(button.identifier)"
        >
            <img
                *ngIf="button.tooltip; else imgWithoutTooltip"
                class="header-button-match-view-{{ button.float || 'right' }}"
                src="/images/icons/{{ button.icon }}.svg"
                alt="{{ button.alt }}"
                spTooltip
                tooltipText="{{ button.tooltip }}"
            />

            <ng-template #imgWithoutTooltip>
                <img
                    class="header-button-match-view-{{ button.float || 'right' }}"
                    src="/images/icons/{{ button.icon }}.svg"
                    alt="{{ button.alt }}"
                />
            </ng-template>
        </a>
    </div>

    <ng-container #dialogContent></ng-container>

    <footer
        *ngIf="hasFooter"
        class="dialog-footer"
        [class.has-primary-button]="!!data.primaryButton"
        [class.has-secondary-button]="!!data.secondaryButton"
        [class.has-alternative-button]="!!data.alternativeButton"
        [class.hide-footer-border]="data.hideFooterBottomBorder"
    >
        <sp-stateful-button
            *ngIf="data.secondaryButton"
            class="secondary-button"
            [context]="'info'"
            [inverted]="true"
            [isLoading]="data.secondaryButton.isStateful && dialogRef.disableClosing"
            [disabledFromOutside]="dialogRef.secondaryButtonDisabled"
            [label]="data.secondaryButton.label"
            (click)="onSecondaryButtonClick($event)"
        >
        </sp-stateful-button>

        <div class="footer-right">
            <a
                *ngIf="data.alternativeButton"
                (click)="onAlternativeButtonClick()"
                href="javascript:"
                class="alternative-button"
            >
                {{ data.alternativeButton.label }}
            </a>

            <sp-stateful-button
                *ngIf="data.primaryButton"
                [context]="data.primaryButton.colorContext || 'primary'"
                [inverted]="!!data.primaryButton.isInverted"
                [isLoading]="data.primaryButton.isStateful && dialogRef.disableClosing"
                [disabledFromOutside]="dialogRef.primaryButtonDisabled"
                [label]="data.primaryButton.label"
                [iconUrl]="data.primaryButton.icon"
                [withAiSuffix]="data.primaryButton.withAiSuffix"
                (click)="onPrimaryButtonClick($event)"
                class="primary-button"
            >
            </sp-stateful-button>
        </div>
    </footer>
</div>

<!-- deprecated v2 styled panel-based modal (there is AT LEAST one usage which is suggestions-modal) -->
<ng-template #deprecatedV2>
    <sp-panel
        [title]="title"
        [titleAlign]="data.titleAlign"
        [colorContext]="v2PanelContext"
        class="dialog-panel"
    >
        <ng-container #dialogContent></ng-container>
    </sp-panel>
</ng-template>
