import type { TemplateRef } from '@angular/core';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import type { DropdownItem } from '../../models';
import type { PopupDropdownStyles, PopupRef } from '../model';
import { spPopupContentComponentData } from '../popup.component';

@Component({
    selector: 'sp-popup-dropdown',
    templateUrl: './popup-dropdown.component.html',
    styleUrls: ['./popup-dropdown.component.scss'],
})
export class PopupDropdownComponent {
    @Output() outsideClick = new EventEmitter();

    items: DropdownItem[];
    styles: PopupDropdownStyles;
    listItemTemplateRef: TemplateRef<any>;
    // any extra data that might be needed when rendering dropdown list items
    data?: any;
    private popupHandle: PopupRef;

    constructor(@Inject(spPopupContentComponentData) data: any) {
        this.items = data.contentComponentData.items;
        this.styles = data.contentComponentData.styles;
        this.listItemTemplateRef =
            data.contentComponentData.listItemTemplateRef;
        this.data = data.contentComponentData.data;
        this.popupHandle = data.popupHandle;
    }

    onDropdownItemClick(item: DropdownItem) {
        this.popupHandle.close(item);
    }
}
