import { isDefined } from '../../utils';

export class ChatMessageAttributes {
    // legacy admin manager fullname
    fullName?: string;
    mentionedUserIds?: string[];
    mentionedKeywords?: string[];
    // on media messages real message body (text) is stored in the attributes
    attachmentMessage?: string;

    static of(attributes): ChatMessageAttributes {
        const chatMsgAttr = new ChatMessageAttributes();
        if (isDefined(attributes)) {
            chatMsgAttr.mentionedUserIds = attributes['mentionedUserIds'];
            chatMsgAttr.mentionedKeywords = attributes['mentionedKeywords'];
            chatMsgAttr.fullName = attributes['fullName'];
            chatMsgAttr.attachmentMessage = attributes['attachmentMessage'];
        }

        return chatMsgAttr;
    }
}
