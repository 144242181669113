import type { ElementRef } from '@angular/core';
import type { Subscription } from 'rxjs';
import {
    EMAIL_REGEXP,
    httpPreconditionFailedStatus,
} from '../core/config.service';
import type { GenericIdName, IdentifiableModel } from './models';
import { cdkOverlayContainerCssClass } from './constants';
import type { ChatChannelMember } from './messaging-chat/model';
import { toGenericUidName } from './models/generic-id-name.interface';

export function isNumeric(value: any): boolean {
    // https://stackoverflow.com/a/1830844/1524551
    return !isNaN(parseFloat(value)) && isFinite(value);
}

export function buildClearbitLogoUrl(domain: string) {
    return domain ? 'https://logo.clearbit.com/' + domain : null;
}

export function mapStringToObject(value: any, key = 'name') {
    if (!value) {
        return null;
    }

    return typeof value === 'string' ? { [key]: value } : value;
}

/**
 * Utility function to prefix URL missing protocol with provided or default
 * protocol
 */
export function prefixUrl(url?: string, prefix = 'http://'): string {
    return !url || url.startsWith('http://') || url.startsWith('https://')
        ? url
        : `${prefix}${url}`;
}

export function arraysEqual<T>(a?: T[], b?: T[]) {
    if (a === b) return true;
    if (!a || !b) return false;
    if (a.length !== b.length) return false;

    a.sort();
    b.sort();

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}

export function deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;

    if (
        typeof obj1 !== 'object' ||
        obj1 === null ||
        typeof obj2 !== 'object' ||
        obj2 === null
    ) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

export function setHasObject<T>(set: Set<T>, obj: T): boolean {
    for (const item of set) {
        if (deepEqual(item, obj)) {
            return true;
        }
    }

    return false;
}

export function deleteObjectFromSet<T>(set: Set<T>, obj: T): Set<T> {
    return new Set([...set].filter((item) => !deepEqual(item, obj)));
}

export function containsObjectWithEqualPropertyComparator(propertyName) {
    return (array: Object[], v: Object) => {
        if (!v || !v.hasOwnProperty(propertyName)) {
            return false;
        }

        for (let i = 0; i < array.length; i++) {
            if (v[propertyName] === array[i][propertyName]) {
                return true;
            }
        }

        return false;
    };
}

export function containsAllFromArray(
    array,
    subarray,
    eachFnComparator?,
): boolean {
    if (array === subarray) {
        return true;
    }
    if (array == null || subarray == null) {
        return false;
    }

    if (eachFnComparator) {
        return subarray.every((r) => eachFnComparator(array, r));
    }

    return subarray.every((r) => array.includes(r));
}

export function containsAnyFromArray(array, subarray, eachFnComparator?) {
    if (array === subarray) {
        return true;
    }
    if (array == null || subarray == null) {
        return false;
    }

    if (eachFnComparator) {
        return subarray.some((r) => eachFnComparator(array, r));
    }

    return subarray.some((r) => array.includes(r));
}

export function deleteObjectProperties(obj: any, ...keysToRemove) {
    const copy = { ...obj };
    keysToRemove.forEach((key) => delete copy[key]);

    return copy;
}

/**
 * Returns string representation of the size in Mb/Kb/Bytes
 * @param bytes - the size in bytes to convert to string with size units
 */
export function fileSizeWithUnits(bytes: number, startFromKb = false) {
    let size: string;
    if (bytes > 999999) {
        // megabytes
        size = `${Math.round((bytes / 1000000) * 100) / 100}Mb`;
    } else if (bytes > 999) {
        // kilobytes
        size = `${Math.round(bytes / 1000)}Kb`;
    } else {
        // bytes
        size = startFromKb ? `0.${bytes}Kb` : `${bytes}B`;
    }

    return size;
}

export function getLastUrlSegment(url: string) {
    let segment = '';
    if (url && url.lastIndexOf('/') < url.length - 1) {
        segment = url.substr(url.lastIndexOf('/') + 1);
    }

    const queryStringCharIdx = segment.indexOf('?');
    if (queryStringCharIdx > 0) {
        segment = segment.substr(0, queryStringCharIdx);
    }

    return segment;
}

export function mergeCollectionsByField(
    dstCollection: any[],
    srcCollection: any[],
    mergeFiled = 'id',
) {
    const itemsToAppend = [];
    srcCollection.forEach((item) => {
        const duplicateElementIndex = dstCollection.findIndex(
            (localItem) => localItem[mergeFiled] === item[mergeFiled],
        );
        if (duplicateElementIndex > -1) {
            dstCollection[duplicateElementIndex] = item;
        } else {
            itemsToAppend.push(item);
        }
    });

    dstCollection.splice(dstCollection.length, 0, ...itemsToAppend);
}

export function removePrimitiveItemFromCollection<T>(item: T, collection: T[]) {
    const index = collection.indexOf(item);
    if (index > -1) {
        return collection.splice(index, 1);
    }
}

export function removeFromCollection(id: number, collection: any[]) {
    const index = collection.findIndex((item) => item.id === id);
    if (index > -1) {
        return collection.splice(index, 1);
    }
}

export function removeFromCollectionByFieldName<T>(
    field: string,
    value: T,
    collection: T[],
) {
    const index = collection.findIndex((item) => item[field] === value[field]);
    if (index > -1) {
        return collection.splice(index, 1);
    }
}

export function replaceElementById<T>(
    values: { id: T }[],
    newElement: { id: T },
) {
    const index = values.findIndex((e) => e.id === newElement.id);
    if (index !== -1) {
        values[index] = newElement;
    }
}

export function decrementIfGreaterThanZero(value: number) {
    if (!value && value < 1) {
        return value;
    }

    return value - 1;
}

export function getNextElementAfter(
    allItems: IdentifiableModel[],
    afterItemId: number,
): IdentifiableModel {
    const index = allItems.findIndex((item) => item.id === afterItemId);

    return index > -1 && index < allItems.length - 1
        ? allItems[index + 1]
        : null;
}

// compare two sets for equality
export function equalSets(a: Set<number>, b: Set<number>) {
    return a.size === b.size && Array.from(a).every((value) => b.has(value));
}

// Perform check if original set contains all the elements of the subset set
export function containsAllFromSet(original: Set<number>, subset: Set<number>) {
    let result = true;

    if (subset.size > 0 && subset.size <= original.size) {
        const subsetArray = Array.from(subset);
        for (const item of subsetArray) {
            if (!original.has(item)) {
                result = false;

                break;
            }
        }
    } else {
        result = false;
    }

    return result;
}

/**
 * Concatenate uppercased first characters of `count` words
 * @param src
 * @param count
 */
export function getFirstLetters(src: string, count = 2): string {
    if (!src || !src.trim() || !count) {
        return '';
    }

    let result = '';
    const chunks = src.split(' ');
    for (let i = 0; i < chunks.length && i < count; i++) {
        result += chunks[i].charAt(0).toUpperCase();
    }

    return result;
}

export function equalGenericArraysWithId<T extends { id: number | string }>(
    val1?: T[],
    val2?: T[],
): boolean {
    val1 = !val1?.length ? undefined : val1;
    val2 = !val2?.length ? undefined : val2;

    if (!val1 || !val2) {
        return !val1 && !val2;
    }

    return (
        val1 &&
        val2 &&
        arraysEqual(
            val1.map((a) => a.id),
            val2.map((a) => a.id),
        )
    );
}

export function equalStrings(val1, val2) {
    val1 = !val1 || val1 === '' ? null : val1;
    val2 = !val2 || val2 === '' ? null : val2;

    return val1 === val2;
}

/**
 * Returns true if all values are defined
 * @param values
 */
export function isDefined(...values: any[]): boolean {
    return values.reduce(
        (previous, current) =>
            previous && current !== undefined && current !== null,
        true,
    );
}

export function isValidMinLength(value: string, minValue: number): boolean {
    return isDefined(value) && value.length >= minValue;
}

export function byTitleComparator(
    a1: { title: string },
    a2: { title: string },
) {
    return a1.title < a2.title ? -1 : a1.title === a2.title ? 0 : 1;
}

export function byIdComparator(a1: { id: number }, a2: { id: number }) {
    return a1.id < a2.id ? -1 : a1.id === a2.id ? 0 : 1;
}

// Maps values to a percentages on 100 percent scale. Uses Largest Reminder Method as in the example here:
// https://stackoverflow.com/a/13483710/1524551
export function mapTo100percentScale(src: { [key: string]: number }): {
    [key: string]: number;
} {
    const keys = Object.keys(src);
    const total = keys.reduce((prev, next) => prev + src[next], 0);
    const ratio = total === 0 ? 0 : 100 / total;

    const sortedByDecimalPartDesc = keys
        .map((key) => ({ key, value: src[key] * ratio }))
        .sort((a, b) => {
            const aDecimal = a.value % 1;
            const bDecimal = b.value % 1;

            return bDecimal - aDecimal;
        });
    sortedByDecimalPartDesc.forEach(
        (keyValue) => (keyValue.value = Math.floor(keyValue.value)),
    );
    const totalPercentage = sortedByDecimalPartDesc.reduce(
        (prev, next) => prev + next.value,
        0,
    );

    if (totalPercentage > 0) {
        const distribute = 100 - totalPercentage;
        if (distribute > 0) {
            for (let i = 0; i < distribute; i++) {
                sortedByDecimalPartDesc[i].value += 1;
            }
        }
    }

    const result = {};
    sortedByDecimalPartDesc.forEach((pair) => (result[pair.key] = pair.value));

    return result;
}

export function safeTrimConcatStrings(s1, s2): string {
    return `${s1 || ''} ${s2 || ''}`.trim();
}

export function nameAndInitialFromFullName(fullName: string) {
    const [firstName, lastName] = fullName.split(' ');

    return nameAndInitial(firstName, lastName);
}

export function nameAndInitial(
    firstName: string,
    lastName: string,
    appendDotToInitial = true,
) {
    let result = firstName;
    if (lastName && lastName.trim()) {
        result += ` ${lastName.charAt(0).toUpperCase()}`;
        if (appendDotToInitial) {
            result += '.';
        }
    }

    return result;
}

export function nameAndInitialFromChatChannelMember(
    chatMember: ChatChannelMember,
) {
    return nameAndInitial(chatMember.firstName, chatMember.lastName);
}

export function findKeyByValue(sourceObject, value) {
    for (const member in sourceObject) {
        if (sourceObject[member] === value) {
            return member;
        }
    }

    return null;
}

export function hasScrolledToTop(
    scrollableContainer: ElementRef,
    prevYScrollOffset?: number,
): { scrollTop: number; hasReachedTop: boolean } {
    const scrollTop = scrollableContainer.nativeElement.scrollTop;

    return {
        scrollTop,
        hasReachedTop:
            prevYScrollOffset !== undefined &&
            scrollTop < prevYScrollOffset &&
            scrollTop === 0,
    };
}

export function hasScrolledToBottom(
    scrollableContainer: ElementRef,
    prevYScrollOffset: number,
): { scrollTop: number; hasReachedBottom: boolean } {
    const scrollTop = scrollableContainer.nativeElement.scrollTop;
    const containerHeight = scrollableContainer.nativeElement.offsetHeight;
    const containerScrollHeight =
        scrollableContainer.nativeElement.scrollHeight;
    const maxYScrollOffset = containerScrollHeight - containerHeight;

    return {
        scrollTop,
        hasReachedBottom:
            prevYScrollOffset !== undefined &&
            scrollTop > prevYScrollOffset &&
            scrollTop >= maxYScrollOffset,
    };
}

export function unsubscribeIfActive(...subscriptions: Subscription[]) {
    if (!subscriptions) {
        return;
    }

    subscriptions.forEach((subscription) => {
        if (subscription && !subscription.closed) {
            subscription.unsubscribe();
        }
    });
}

export function byOrderAscComparatorFn(
    i1: { order: number },
    i2: { order: number },
): number {
    return i1.order - i2.order;
}

export function byDateDescComparator(
    i1: { date: Date },
    i2: { date: Date },
): number {
    return i2.date.getTime() - i1.date.getTime();
}

export function byOptionalEndDateDescComparator(
    a: { endDate?: Date },
    b: { endDate?: Date },
): number {
    const aEndDate = !isDefined(a.endDate)
        ? new Date().getTime()
        : new Date(a.endDate).getTime();
    const bEndDate = !isDefined(b.endDate)
        ? new Date().getTime()
        : new Date(b.endDate).getTime();

    return bEndDate - aEndDate;
}

export function byOptionalCreatedDateDescComparator(
    a: { createdDate?: Date },
    b: { createdDate?: Date },
): number {
    const aCreatedDate = !isDefined(a.createdDate)
        ? new Date().getTime()
        : new Date(a.createdDate).getTime();
    const bCreatedDate = !isDefined(b.createdDate)
        ? new Date().getTime()
        : new Date(b.createdDate).getTime();

    return bCreatedDate - aCreatedDate;
}

export function byNumericPropertyDescWithNullsLastComparator<
    T extends Record<string, unknown>,
>(a: T, b: T, property: keyof T) {
    if (a[property] === null && b[property] === null) {
        return 0;
    }
    if (a[property] === null) {
        return 1; // Null values go after non-null values
    }
    if (b[property] === null) {
        return -1; // Null values go after non-null values
    }

    return (b[property] as number) - (b[property] as number);
}

export function isEntityVersionCheckError(error): boolean {
    return (
        error && error.status && error.status === httpPreconditionFailedStatus
    );
}

export function getNumberOfItemsOrderedByDate(
    items: any[],
    propertyName: string,
    topItemsCount: number,
) {
    return [...items]
        .filter((item) => !!item[propertyName])
        .sort((a, b) => {
            const time1 = a[propertyName] ? a[propertyName].getTime() : 0;
            const time2 = b[propertyName] ? b[propertyName].getTime() : 0;

            return time2 - time1;
        })
        .slice(0, topItemsCount);
}

export function escapeRegExp(text: string): string {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function replaceAll(text: string, term: string, replacement: string) {
    return text.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
}

// key is actual KeyboardEvent.key, which may have values as follow:
// ‘5', ‘3’, ‘g’, ‘s’, ‘Enter’, ‘Backspace’... etc.
// Because of that, we check if length is one and value is char or number
export function isAlphanumericKey(key: string): boolean {
    return /^[a-z0-9]$/i.test(key);
}

export function isIE() {
    return /msie\s|trident\//i.test(window.navigator.userAgent);
}

export function getImageUrlMaskInlineCss(imageUrl: string) {
    return {
        '-webkit-mask-image': `url(${imageUrl})`,
        'mask-image': `url(${imageUrl})`,
    };
}

export function getMaskInlineCss(
    color: string,
    logoUrl: string,
    size = '16px',
    extras: { [key: string]: string } = {},
) {
    return Object.assign(
        getImageUrlMaskInlineCss(logoUrl),
        {
            'background-color': `${color}`,
            'mask-size': `${size}`,
            '-webkit-mask-size': `${size}`,
        },
        extras,
    );
}

export function isElementInViewport(element): boolean {
    const position = element.getBoundingClientRect();

    return position.top < window.innerHeight && position.bottom >= 0;
}

/**
 * Prepends N '0' characters at the beginning of a numeric string if string representation
 * of the passed numeric value is of length lower than expectedLength
 * @param value
 * @param expectedLength
 */
export function padWithZeros(value: number, expectedLength: number): string {
    const chars = `${value}`.split('');
    if (chars.length >= expectedLength) {
        return `${value}`;
    }

    while (chars.unshift('0') < expectedLength) {}

    return chars.join('');
}

export function isValidEmail(email: string): boolean {
    return EMAIL_REGEXP.test(email);
}

export function isValidEmailForDomain(email: string, domain: string): boolean {
    return isValidEmail(email) && email.endsWith(domain);
}

export function getDomainFromEmail(email: string): string {
    return email.trim().split('@')[1];
}

export function plusNMore(
    items: any[],
    valueKey: string,
    separator = ' | ',
): { full: string; short?: string } {
    let short: string, full: string;
    if (items.length <= 1) {
        short = full = items.length ? items[0][valueKey] : '';
    } else {
        short = `${items[0][valueKey]}+${items.length - 1}`;
        full = items.map((item) => item[valueKey]).join(separator);
    }

    return { short, full };
}

export function findUniqueByProperty(collection: any[], propBy: string): any[] {
    return collection.filter(
        (v, i, a) => a.findIndex((v2) => v2[propBy] === v[propBy]) === i,
    );
}

export function addCdkOverlayClass(className: string) {
    const containerElement: Element = document.getElementsByClassName(
        cdkOverlayContainerCssClass,
    )[0];
    containerElement?.classList.add(className);
}

export function removeCdkOverlayClass(className: string) {
    const containerElement: Element = document.getElementsByClassName(
        cdkOverlayContainerCssClass,
    )[0];
    containerElement?.classList.remove(className);
}

export function thousandsToKFormatter(num: number): string {
    return num > 999 ? (num / 1000).toFixed() + 'k' : num.toString();
}

export function removeHtmlTags(value: string, replaceValue = ''): string {
    if (!value) {
        return '';
    }

    return value.replace(/<\/?[^>]+(>|$)/g, replaceValue);
}

// Returns value if it has any data except for empty html tags
export function getValueFromOptionalWysiwygControl(
    value: string,
): string | null {
    return !!value && !!removeHtmlTags(value).trim().length ? value : null;
}

export function markUsedItemsInAutocompleteSourceByName<
    T extends { name: string },
>(items: T[], presentIn: (T[] | null | undefined)[] = [[]]): T[] {
    const presentItems = flattenCollectionOfNullableCollections(presentIn);

    return items.map((item) =>
        Object.assign(item, {
            inUse: presentItems.some(
                (presentItem) =>
                    presentItem.name.toLowerCase() === item.name.toLowerCase(),
            ),
        }),
    );
}

export function markUsedItemsInAutocompleteSourceById<T extends { id: string }>(
    items: T[],
    presentIn: (T[] | null | undefined)[] = [[]],
): T[] {
    const presentItems = flattenCollectionOfNullableCollections(presentIn);

    return items.map((item) =>
        Object.assign(item, {
            inUse: presentItems.some(
                (presentItem) => presentItem.id === item.id,
            ),
        }),
    );
}

export function excludeAlreadyPresentById<T extends { id: number }>(
    incomingItems: T[],
    presentItems: (T[] | null | undefined)[],
): T[] {
    const presentItemsFlat =
        flattenCollectionOfNullableCollections(presentItems);

    return incomingItems.filter(
        (item) =>
            !presentItemsFlat.some((presentItem) => presentItem.id === item.id),
    );
}

export function excludeAlreadyPresentByName<T extends { name: string }>(
    incomingItems: T[],
    presentItems: (T[] | null | undefined)[],
): T[] {
    const presentItemsFlat =
        flattenCollectionOfNullableCollections(presentItems);

    return incomingItems.filter(
        (item) =>
            !presentItemsFlat.some(
                (presentItem) =>
                    presentItem.name.toLowerCase() === item.name.toLowerCase(),
            ),
    );
}

export function flattenCollectionOfNullableCollections<T>(
    collectionsToMerge: (T[] | null | undefined)[],
): T[] {
    return collectionsToMerge
        .filter((collection): collection is T[] => !!collection)
        .reduce((result, collection) => result.concat(collection), []);
}

export function stringsToGenericUidNames(
    names?: string[],
): GenericIdName<string>[] {
    return !names?.length ? [] : names.map((name) => toGenericUidName(name));
}

export function capitalizeFirstLetter(inputString: string): string {
    return isDefined(inputString)
        ? inputString.charAt(0).toUpperCase() +
              inputString.substr(1).toLowerCase()
        : null;
}

export function convertArrayToHtmlList(input: string[]): string {
    return input?.length > 0
        ? `<ul><li>${input.join('</li><li>')}</li></ul>`
        : '';
}

export function companyLinkedinUrlById(id: string): string {
    return `https://www.linkedin.com/company/${id}`;
}
