import type { GeoLocation } from '../../core/google-api.service';
import type { IconButton } from '../icon-buttons-group/icon-buttons-group.component';
import type {
    EmployerAdditionalInfo,
    EmployerBasicInfo,
    EmployerCategoryInfo,
} from '../../account/profile/employer-profile/models/employer-profile.model';
import type { Industry } from './industry.model';
import type { GenericIdName } from './generic-id-name.interface';
import type { Integration } from './integrations/integration.model';
import { IntegrationType } from './integrations/integration.model';

export enum CandidateInfoFileType {
    mergedResumeWithMatchDetailsReport = 'mergedResumeWithMatchDetailsReport',
    resumePdf = 'resumePdf',
}

export class Employer {
    id: number;
    displayName: string;
    email: string;
    phoneNumber?: string;
    employerName: string;
    industries: Industry[];
    benefits: IconButton[];
    location?: GeoLocation;
    employerSize?: number;
    companySizeRange?: string;
    employerFoundationYear?: number;
    companyType?: GenericIdName;
    description?: string;
    logoUrl?: string;
    thumbnailLogoUrl?: string;
    website?: string;
    linkedin?: string;
    twitter?: string;
    facebook?: string;
    instagram?: string;
    isProfileComplete?: boolean;
    integrations?: Integration[];
    hasEverPublishedAJob?: boolean;
    allowAutoJoin?: boolean;
    isCompanyPageEnabled?: boolean;
    companyPublicPageUrl?: string;
    matchDetailsFileType: CandidateInfoFileType;
    atsFileUploadType?: CandidateInfoFileType;
    hideCandidateLastName: boolean;
    hasActionedCandidates: boolean;
    daysRemainingInTrial: number;
    linkedInCompanyId?: string;
    accountEnabled?: boolean;

    static from(employer?: Employer) {
        return employer ? Object.assign(new Employer(), employer) : null;
    }

    get isConnectedToAts(): boolean {
        return !!this.connectedAtsIntegration;
    }

    get connectedAtsIntegration(): Integration | undefined {
        return this.integrations
            ? this.integrations.find(
                  (item) => item.type === IntegrationType.ats && item.connected,
              )
            : undefined;
    }

    get basicInfo(): EmployerBasicInfo {
        return {
            employerName: this.employerName,
            location: this.location,
            website: this.website,
            employerFoundationYear: this.employerFoundationYear,
            allowAutoJoin: this.allowAutoJoin,
        };
    }

    get categoryInfo(): EmployerCategoryInfo {
        return {
            employerSize: this.employerSize,
            industries: this.industries,
            companyType: this.companyType,
        };
    }

    get additionalInfo(): EmployerAdditionalInfo {
        return {
            description: this.description,
            linkedin: this.linkedin,
            twitter: this.twitter,
            facebook: this.facebook,
            instagram: this.instagram,
            benefits: this.benefits,
        };
    }
}
