import { Component, Input } from '@angular/core';

@Component({
    selector: 'sp-status-light',
    templateUrl: './status-light.component.html',
    styleUrls: ['./status-light.component.scss'],
})
export class StatusLightComponent {
    @Input() color: string;
    @Input() tooltip: string;
}
