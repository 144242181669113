import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { UserService } from '../user.service';
import { UserType } from '../../shared/models/user.model';

@Injectable()
export class CandidateGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> {
        return this.userService.getUserObservable().pipe(
            map((user) => {
                if (!user) {
                    const extras =
                        state.url.trim() !== '/'
                            ? { queryParams: { redirectTo: state.url } }
                            : {};
                    // guarding against accessing the protected pages by the unauthenticated user
                    this.router.navigate([`/login/candidate`], extras);

                    return false;
                }

                if (user.type !== UserType.candidate) {
                    // guarding against accessing the protected pages by employer user
                    this.router.navigate(['/']);

                    return false;
                }

                return true;
            }),
        );
    }
}
