<ng-select
    *ngIf="!!control.items"
    [items]="control.items"
    [bindLabel]="control.bindLabel"
    [bindValue]="control.bindValue"
    [(ngModel)]="model"
    [placeholder]="control.placeholder"
    [dropdownPosition]="control.dropdownPosition"
    [class.small]="control.size === 'small'"
    [class.invalid]="isInvalid"
    (change)="onChange($event)"
    (open)="onOpen()"
    (close)="onClose()"
    class="sp-ng-select"
>
</ng-select>

<ng-select
    *ngIf="!!control.asyncItems"
    [items]="asyncItems$ | async"
    [bindLabel]="control.bindLabel"
    [bindValue]="control.bindValue"
    [(ngModel)]="model"
    [placeholder]="control.placeholder"
    [dropdownPosition]="control.dropdownPosition"
    [class.small]="control.size === 'small'"
    [class.invalid]="isInvalid"
    (change)="onChange($event)"
    (open)="onOpen()"
    (close)="onClose()"
    class="sp-ng-select"
>
</ng-select>

<div *ngIf="loadingAsyncItemsInProgress" class="spinner-wrapper">
    <img src="/images/spinner.svg" class="sp-dots-spinner spinner" />
</div>

<span
    *ngIf="control.icon && !loadingAsyncItemsInProgress"
    [ngClass]="computedCssClasses"
    class="sp-field-icon"
></span>

<div class="disabled-overlay" *ngIf="control.disabled"></div>
