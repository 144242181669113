import {
    SALARY_RANGE,
    YEARS_OF_EXPERIENCE_RANGE,
} from '../../core/config.service';
import type { GeoLocation } from '../../core/google-api.service';
import { toDate } from '../date-utils';
import {
    arraysEqual,
    convertArrayToHtmlList,
    equalGenericArraysWithId,
    equalStrings,
    isDefined,
} from '../utils';
import type { TraitInfo } from '../../jobs/job-stepper/model/trait-info';
import type { AppendableItemsListItem } from '../appendable-items-list/model';
import { ShineDotType } from '../shine-dot/shine-dot.component';
import type { StatusInfo } from '../sourcing-info-modal/sourcing-info-modal.component';
import type { AiGeneratedTargetingCriteria } from '../ai/ai.model';
import type { JobLocation } from '../../jobs/shared/composite-location/model/job-location.model';
import type { GenericIdName } from './generic-id-name.interface';
import { DashboardJobType } from './enumeration/dashboard-job-type.enum';
import { Team } from './team.model';
import { User } from './user.model';
import type { JobCandidateCounters } from './job-candidate-counters.interface';
import type { Compensation } from './user/compensation.model';
import type { Currency } from './enumeration/currency.enum';
import type { CompanySizeRange } from './company-size-range.model';

export enum JobLocationType {
    onSite = 'onSite',
    hybrid = 'hybrid',
    remote = 'remote',
}

export enum SourcingStatus {
    enabled = 'enabled',
    started = 'started',
    stopped = 'stopped',
    archived = 'archived',
}

export enum JobCampaignStatus {
    drafted = 'drafted',
    started = 'started',
    stopped = 'stopped',
    paused = 'paused',
}

export enum LinkedInSponsorshipType {
    spPage = 'spPage',
    companyPage = 'companyPage',
    disabled = 'disabled',
}

export const jobLocationOptionNames = {
    [JobLocationType.onSite]: 'On-Site',
    [JobLocationType.hybrid]: 'Hybrid',
    [JobLocationType.remote]: 'Remote',
};

export enum JobTraitsAssessmentType {
    required = 'required',
    optional = 'optional',
    disabled = 'disabled',
}

export const jobTraitsAssessmentTypeNames: {
    [key in JobTraitsAssessmentType]: string;
} = {
    [JobTraitsAssessmentType.required]: 'Required',
    [JobTraitsAssessmentType.optional]: 'Optional',
    [JobTraitsAssessmentType.disabled]: 'Disabled',
};

export const maxPrimaryIndustries = 3;
export const maxSecondaryIndustries = 3;
export const maxPrimarySkills = 5;
export const maxSecondarySkills = 15;

export const applicantsFromOtherCities =
    'Accept applicants who need to relocate';
export const visaSponsorship =
    'Accept applicants who require sponsorship (US only)';
export const requiresTravel = 'This job requires applicants to travel';
const visaSponsorshipSelected = 'Visa Sponsorship Offered';
export const displaySalaryOnPublicJobPage = 'Display salary to candidates';
export const bonusOffered = 'Bonus offered';
export const commissionOffered = 'Commission offered';
export const uncappedCommissionOffered = 'Commission is uncapped';
export const stockOrEquityOffered = 'Stock or equity offered';

export class JobReviewers {
    teams: Team[] = [];
    users: User[] = [];

    static from(reviewers?: JobReviewers): JobReviewers {
        return {
            users: reviewers?.users?.map((user) => User.from(user)) || [],
            teams: reviewers?.teams?.map((team) => Team.from(team)) || [],
        };
    }

    static toUsers(reviewers: JobReviewers): User[] {
        const users: User[] = [];

        if (reviewers.users) {
            const individualReviewers: User[] = reviewers.users.map(
                (reviewer) => User.from(reviewer),
            );
            users.push(...individualReviewers);
        }

        if (reviewers.teams?.length) {
            const teamMembers: User[] = [];
            reviewers.teams.forEach((team) =>
                teamMembers.push(
                    ...(team.members
                        ? team.members.map((user) => User.from(user))
                        : []),
                ),
            );

            teamMembers.forEach((teamMember) => {
                if (!users.some((user) => user.id === teamMember.id)) {
                    users.push(teamMember);
                }
            });
        }

        return users;
    }
}

const equalTraits = (val1?: TraitInfo[], val2?: TraitInfo[]) => {
    if (!val1 || !val2) {
        return !val1 && !val2;
    }

    for (const trait of val1) {
        if (val2.find((tr) => tr?.name === trait.name)?.value !== trait.value) {
            return false;
        }
    }

    return true;
};

const equalReviewers = (val1?: JobReviewers, val2?: JobReviewers) => {
    if (!val1 || !val2) {
        return !val1 && !val2;
    }

    return (
        equalGenericArraysWithId(val1.teams, val2.teams) &&
        equalGenericArraysWithId(val1.users, val2.users)
    );
};

const equalCompensation = (
    compensation?: Compensation,
    compensationRef?: Compensation,
): boolean => {
    if (!compensation || !compensationRef) {
        return !compensation && !compensationRef;
    }

    return (
        compensation.value === compensationRef.value &&
        compensation.unit === compensationRef.unit &&
        compensation.isIncluded === compensationRef.isIncluded
    );
};

export class BaseJobInfo {
    id: number;
    version: number;
    createdDate: Date;
    title: string;
    status: DashboardJobType;
    sourcingStatus: SourcingStatus;
    isStealth: boolean;
    campaignStatus?: JobCampaignStatus;
    allowDirectApply?: boolean;
    publicJobLandingPageUrl?: string;
    matchesTags?: string[];
    chatChannelId?: string;

    static of(jobInfo: BaseJobInfo): BaseJobInfo {
        return Object.assign(new BaseJobInfo(), jobInfo);
    }

    get isJobSourcingActive(): boolean {
        return (
            this.sourcingStatus === SourcingStatus.enabled ||
            this.sourcingStatus === SourcingStatus.started
        );
    }

    get isPublished(): boolean {
        return this.status === DashboardJobType.published;
    }
}

export class JobInfo extends BaseJobInfo {
    description: string;
    officeLocations: GeoLocation[];
    candidateLocations: GeoLocation[];
    allowedRelocationAreas?: GeoLocation[];
    minYearsOfExperience: number;
    maxYearsOfExperience: number;
    minTotalYearsOfExperience: number;
    maxTotalYearsOfExperience: number;
    minSalary: number;
    maxSalary: number;
    displaySalaryOnPublicPage?: boolean;
    active?: boolean;
    nextAssessmentId?: number;
    requiredIndustries?: GenericIdName[];
    relevantIndustries?: GenericIdName[];
    requiredSkills?: string[];
    relevantSkills?: string[];
    candidatesFromOtherCities: boolean;
    jobLocationType?: JobLocationType;
    requiresTravel: boolean;
    visaSponsorship: boolean;
    requireCoverLetter: boolean;
    department?: GenericIdName;
    screeningQuestionsRequired?: boolean;
    screeningQuestions?: AppendableItemsListItem[];
    modifiedDate?: Date;
    lastUpdatedDate?: Date;
    publishedDate?: Date;
    daysActive?: number;
    userCreatorAvatar?: string;
    userModifierAvatar?: string;
    createdBy?: string;
    updatedBy?: string;
    reviewers?: JobReviewers;
    nextAssessmentFirst: boolean;
    traitsResults?: TraitInfo[];
    candidateCounters?: JobCandidateCounters;
    feedUUID?: string;
    lastTimeFeedView?: Date;
    hashId?: string;
    inviteToApplyAnalyticsParams?: string;
    atsJobId?: string;
    spellRunIds?: string[];
    traitsAssessmentType?: JobTraitsAssessmentType;
    bonus?: Compensation;
    commission?: Compensation;
    enabledUncappedCommission: boolean;
    stockOrEquityOffered?: boolean;
    desiredDegrees?: string[];
    desiredSeniorityLevels?: string[];
    desiredCompanyFundings?: GenericIdName[];
    desiredCompanySizes?: CompanySizeRange[];
    desiredCandidateCompanyNames?: string[];
    desiredJobTitles?: string[];
    desiredSchools?: string[];
    desiredMajors?: string[];
    customTargetingCriteria?: string;
    linkedInSponsorshipType?: LinkedInSponsorshipType;
    currency: Currency;

    static of(job): JobInfo {
        return Object.assign(new JobInfo(), job, {
            createdDate: toDate(job.createdDate),
            modifiedDate: toDate(job.modifiedDate),
            reviewers: JobReviewers.from(job.reviewers),
            lastTimeFeedView: job.lastTimeFeedView
                ? new Date(job.lastTimeFeedView)
                : undefined,
        });
    }

    static initBy(createdBy: string): JobInfo {
        return Object.assign(new JobInfo(), {
            createdDate: toDate(new Date()),
            createdBy,
        });
    }

    static duplicate(source: JobInfo, createdBy: string): JobInfo {
        const screeningQuestions = source.screeningQuestions?.map(
            (question) => {
                question.id = null;

                return question;
            },
        );

        return JobInfo.of(
            Object.assign(new JobInfo(), source, {
                id: null,
                title:
                    source.status === DashboardJobType.published
                        ? 'COPY - ' + source.title
                        : source.title,
                status: DashboardJobType.drafts,
                createdDate: new Date(),
                createdBy,
                lastUpdatedDate: null,
                updatedBy: null,
                publishedDate: null,
                sourcingStatus: SourcingStatus.enabled,
                atsJobId: null,
                screeningQuestions,
            }),
        );
    }

    equalTo(job: JobInfo) {
        return (
            equalStrings(this.title, job.title) &&
            ((!this.department && !job.department) ||
                (this.department &&
                    job.department &&
                    this.department.name === job.department.name)) &&
            equalStrings(this.description, job.description) &&
            equalGenericArraysWithId(
                this.officeLocations,
                job.officeLocations,
            ) &&
            equalGenericArraysWithId(
                this.candidateLocations,
                job.candidateLocations,
            ) &&
            this.jobLocationType === job.jobLocationType &&
            this.minSalary === job.minSalary &&
            this.maxSalary === job.maxSalary &&
            equalCompensation(this.bonus, job.bonus) &&
            equalCompensation(this.commission, job.commission) &&
            this.enabledUncappedCommission === job.enabledUncappedCommission &&
            this.stockOrEquityOffered === job.stockOrEquityOffered &&
            this.displaySalaryOnPublicPage === job.displaySalaryOnPublicPage &&
            this.candidatesFromOtherCities === job.candidatesFromOtherCities &&
            this.visaSponsorship === job.visaSponsorship &&
            equalReviewers(this.reviewers, job.reviewers) &&
            this.linkedInSponsorshipType === job.linkedInSponsorshipType &&
            this.traitsAssessmentType === job.traitsAssessmentType &&
            this.requireCoverLetter === job.requireCoverLetter &&
            equalGenericArraysWithId(
                this.screeningQuestions,
                job.screeningQuestions,
            ) &&
            equalTraits(this.traitsResults, job.traitsResults) &&
            this.minYearsOfExperience === job.minYearsOfExperience &&
            this.maxYearsOfExperience === job.maxYearsOfExperience &&
            this.minTotalYearsOfExperience === job.minTotalYearsOfExperience &&
            this.maxTotalYearsOfExperience === job.maxTotalYearsOfExperience &&
            equalGenericArraysWithId(
                this.requiredIndustries,
                job.requiredIndustries,
            ) &&
            equalGenericArraysWithId(
                this.relevantIndustries,
                job.relevantIndustries,
            ) &&
            arraysEqual(this.requiredSkills, job.requiredSkills) &&
            arraysEqual(this.relevantSkills, job.relevantSkills) &&
            arraysEqual(this.desiredDegrees, job.desiredDegrees) &&
            arraysEqual(
                this.desiredSeniorityLevels,
                job.desiredSeniorityLevels,
            ) &&
            equalGenericArraysWithId(
                this.desiredCompanyFundings,
                job.desiredCompanyFundings,
            ) &&
            arraysEqual(
                this.desiredCompanySizes || [],
                job.desiredCompanySizes || [],
            ) &&
            arraysEqual(
                this.desiredCandidateCompanyNames,
                job.desiredCandidateCompanyNames,
            ) &&
            arraysEqual(this.desiredJobTitles, job.desiredJobTitles) &&
            arraysEqual(this.desiredSchools, job.desiredSchools) &&
            arraysEqual(this.desiredMajors, job.desiredMajors) &&
            equalStrings(
                this.customTargetingCriteria,
                job.customTargetingCriteria,
            )
        );
    }

    constructor() {
        super();
        this.minYearsOfExperience = YEARS_OF_EXPERIENCE_RANGE.defaultMin;
        this.maxYearsOfExperience = YEARS_OF_EXPERIENCE_RANGE.defaultMax;
        this.minTotalYearsOfExperience = YEARS_OF_EXPERIENCE_RANGE.defaultMin;
        this.maxTotalYearsOfExperience = YEARS_OF_EXPERIENCE_RANGE.defaultMax;

        this.minSalary = SALARY_RANGE.defaultMin;
        this.maxSalary = SALARY_RANGE.defaultMax;

        this.candidatesFromOtherCities = false;
        this.visaSponsorship = false;
        this.requireCoverLetter = false;

        this.status = DashboardJobType.drafts;

        this.reviewers = new JobReviewers();
    }

    getOptionalTraitsAssessments(): boolean {
        return this.traitsAssessmentType !== JobTraitsAssessmentType.required;
    }

    isRemote(): boolean {
        return this.jobLocationType === JobLocationType.remote;
    }

    hasReviewers(): boolean {
        return (
            !!this.reviewers?.teams?.length || !!this.reviewers?.users?.length
        );
    }

    getRelevantLocations(): GeoLocation[] {
        return this.isRemote() ? this.candidateLocations : this.officeLocations;
    }

    hasUpdatedTargetingCriteria(): boolean {
        return !!(
            this.requiredIndustries?.length ||
            this.relevantIndustries?.length ||
            this.desiredJobTitles?.length ||
            this.requiredSkills?.length ||
            this.relevantSkills?.length ||
            this.desiredCompanyFundings?.length ||
            this.desiredCompanySizes?.length ||
            this.desiredSeniorityLevels?.length ||
            this.desiredDegrees?.length ||
            this.desiredSchools?.length ||
            this.desiredMajors?.length ||
            this.minYearsOfExperience !==
                YEARS_OF_EXPERIENCE_RANGE.defaultMin ||
            this.maxYearsOfExperience !==
                YEARS_OF_EXPERIENCE_RANGE.defaultMax ||
            isDefined(this.customTargetingCriteria)
        );
    }

    overrideTargetingCriteria(
        targetingCriteria?: AiGeneratedTargetingCriteria,
    ): void {
        this.requiredIndustries =
            targetingCriteria?.primaryIndustries.slice(
                0,
                maxPrimaryIndustries,
            ) ?? [];
        this.desiredJobTitles = targetingCriteria?.jobTitles ?? [];
        this.minYearsOfExperience =
            targetingCriteria?.minYearsOfRelevantExperience ??
            YEARS_OF_EXPERIENCE_RANGE.defaultMin;
        this.maxYearsOfExperience =
            targetingCriteria?.maxYearsOfRelevantExperience ??
            YEARS_OF_EXPERIENCE_RANGE.defaultMax;
        this.requiredSkills =
            targetingCriteria?.primarySkills.slice(0, maxPrimarySkills) ?? [];
        this.desiredCompanySizes = targetingCriteria?.companySizes ?? [];
    }

    updateLocationFromComposite(location: JobLocation) {
        this.jobLocationType = location.jobLocationType;
        this.candidatesFromOtherCities = location.candidatesFromOtherCities;
        this.candidateLocations = location.getValidCandidateLocations();
        this.officeLocations = location.getValidOfficeLocations();
        this.allowedRelocationAreas = location.getValidRelocationAreas();
    }
}

export const jobInfoMock: JobInfo = JobInfo.of({
    id: 123,
    title: 'Lorem',
    description: 'Ipsum Dolor Sit',
    requirements: 'Test Job Requirements',
    responsibilities: 'Test Job Responsibilities',
    officeLocations: [{ id: 1, value: 'Atlantis' }],
    minYearsOfExperience: 1,
    maxYearsOfExperience: 10,
    desiredSchools: [],
    desiredMajors: [],
    desiredCustomMajorMinor: [],
    minSalary: 0,
    maxSalary: 500,
    status: DashboardJobType.published,
});

export const sourcingStatusInfo: StatusInfo[] = [
    {
        type: ShineDotType.active,
        label:
            'SquarePeg is sourcing candidates through LinkedIn and email campaigns based on your targeting criteria. ' +
            'When you modify targeting criteria, SquarePeg will adjust candidate targeting and applicant screening.',
    },
    {
        type: ShineDotType.inactive,
        label: 'Sourcing is not active. If you want SquarePeg to source candidates for this job, please turn on sourcing.',
    },
];

export const stealthJobCallout = {
    header: 'Stealth mode is enabled for this job.',
    description: 'If necessary, omit company-identifiable information.',
};

export const jobDescriptionFromParsedInfo = (
    overview: string,
    responsibilities: string[],
    qualifications: string[],
    otherDetails?: string,
): string =>
    `<h3>Job Overview</h3><p>${overview}</p>
    <h3>Responsibilities</h3>${convertArrayToHtmlList(responsibilities)}
    <h3>Qualifications</h3>${convertArrayToHtmlList(qualifications)}
    ${
        otherDetails
            ? `<h3>Additional Information</h3><p>${otherDetails}</p>`
            : ''
    }`;
