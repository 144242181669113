export enum AsyncTaskType {
    importFromAts = 'importFromAts',
    parseJobDescription = 'parseJobDescription',
    generatingJobDescription = 'generatingJobDescription',
    generatingTargeting = 'generatingTargeting',
}

export const rotatingTextsByAsyncTaskType: {
    [key in AsyncTaskType]: string[];
} = {
    [AsyncTaskType.importFromAts]: [
        'Connecting to your ATS…',
        'Importing your job description…',
        'Extracting job title…',
        'Extracting overview…',
        'Extracting responsibilities…',
        'Extracting qualifications…',
        'Finishing up…',
    ],
    [AsyncTaskType.parseJobDescription]: [
        'Parsing your job description…',
        'Detecting title…',
        'Detecting overview…',
        'Detecting responsibilities…',
        'Detecting qualifications…',
        'Detecting additional information…',
    ],
    [AsyncTaskType.generatingJobDescription]: [
        'Writing your job description…',
        'Determining title…',
        'Determining overview…',
        'Determining responsibilities…',
        'Determining qualifications…',
        'Determining additional information…',
    ],
    [AsyncTaskType.generatingTargeting]: [
        'Analyzing your job description…',
        'Determining targeting criteria…',
    ],
};
