import type { OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { Subscription, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ButtonContext } from 'app/shared/stateful-button/stateful-button.component';
import type { OAuthRequest } from '../core/user.service';
import { UserService } from '../core/user.service';
import type { User } from '../shared/models/user.model';
import { UserType } from '../shared/models/user.model';
import type { ITabSwitch } from '../shared/tab-switcher/tab-switcher.component';
import { environment } from '../../environments/environment';
import { isDefined, unsubscribeIfActive } from '../shared/utils';
import { LocalStorageService } from '../core/local-storage.service';
import { getEasySignupJobHashIdKeyPrefix } from '../core/config.service';
import { CandidateMatchesApiService } from '../core/candidate-matches-api.service';
import { TokenService } from '../core/token.service';
import { TokenType } from '../shared/models/token-type.enum';
import type { UserInvitation } from '../shared/models/user/user-invitation.interface';
import { UserInvitationStatus } from '../shared/models/user/user-invitation.interface';
import { AuthFormType } from './model/auth-form-type.model';
import type { ISignupForm } from './model/signup-form.model';
import type { IAccessRequest } from './model/request-access-form.model';
import { SignupErrorType } from './forms/signup-form/signup-form.component';

export enum AuthScreenMode {
    auth = 'auth',
    passwordReset = 'passwordReset',
    requestAccess = 'requestAccess',
    verificationEmailSent = 'verificationEmailSent',
    joinEmployer = 'joinEmployer',
    requestToJoinEmailSent = 'requestToJoinEmailSent',
    spInviteToApplyResponse = 'spInviteToApplyResponse',
}

export enum InviteToApplyResponseType {
    accept = 'accept',
    decline = 'decline',
    expired = 'expired',
}

@Component({
    selector: 'sp-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnDestroy {
    public redirectTo: string;
    public userType: UserType;
    public requestAccessData?: IAccessRequest;
    public authFormType: AuthFormType;
    public mode = AuthScreenMode.auth;
    public prefillData: ISignupForm;
    public emailVerificationToken: string;
    public employerInvitationToken: string;
    public inviteToApplyRedirectTo = '/login/candidate';

    // used as a redirection in OAuth flow
    public showSuccessMessage = false;
    public tabs: ITabSwitch[];
    public currentYear = new Date().getFullYear();
    public siteUrl: string;
    public isAuthenticating = false;
    public authenticationError = '';
    public error?: SignupErrorType;
    public inviteToApplyResponse: InviteToApplyResponseType;
    public jobTitle: string;
    public companyName: string;
    public prefilledEmail: string;

    readonly subscription: Subscription;
    readonly ButtonContext = ButtonContext;
    readonly UserType = UserType;

    showAuthScreen = false;
    emailSentToDescription?: string;
    resendEmailVerification?: () => Observable<any>;
    sendRequestToJoinEmail?: (prefillData: ISignupForm) => Observable<any>;
    requestToJoinEmailSentDescription?: string;

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private storageService: LocalStorageService,
        private candidateMatchesApiService: CandidateMatchesApiService,
        private tokenService: TokenService,
        private router: Router,
    ) {
        this.siteUrl = environment.config.SITE_URL;
        this.subscription = new Subscription();
        this.prefillData =
            this.router.getCurrentNavigation()?.extras?.state?.prefillData;
        this.prefilledEmail =
            this.router.getCurrentNavigation()?.extras?.state?.prefilledEmail;

        this.redirectTo = this.route.snapshot.queryParams['redirectTo'] || '/';
        const authToken = this.route.snapshot.queryParams['token'];
        const authLinkToken = this.route.snapshot.queryParams['authLinkToken'];
        this.employerInvitationToken =
            this.route.snapshot.queryParams['invitationToken'];
        this.jobTitle = this.route.snapshot.queryParams['jobTitle'];
        this.companyName = this.route.snapshot.queryParams['companyName'];
        this.emailVerificationToken =
            this.route.snapshot.params.emailVerificationToken;
        this.inviteToApplyResponse = this.route.snapshot.params
            .inviteToApplyResponse as InviteToApplyResponseType;

        if (this.inviteToApplyResponse === InviteToApplyResponseType.accept) {
            const matchId = this.route.snapshot.params.matchId;
            this.inviteToApplyRedirectTo = `/matches/applied/${matchId}`;
        }

        this.setRequestToJoinObservable();
        this.route.url.subscribe((urlSegments) => {
            const [authFormType, userType] = urlSegments.map(
                (segment) => segment.path,
            );

            this.authFormType = authFormType as AuthFormType;
            this.userType = userType as UserType;

            this.tabs = [
                {
                    title: 'Employers',
                    route: `/${this.authFormType}/${UserType.employer}`,
                    active: userType === UserType.employer,
                    queryParams: { redirectTo: this.redirectTo },
                },
                {
                    title: 'Candidates',
                    route: `/${this.authFormType}/${UserType.candidate}`,
                    active: userType === UserType.candidate,
                    queryParams: { redirectTo: this.redirectTo },
                },
            ];
        });

        this.route.data.subscribe((data) => {
            if (
                data?.mode === AuthScreenMode.joinEmployer &&
                !this.prefillData
            ) {
                this.router.navigateByUrl('/signup/employer');
            } else {
                this.showSuccessMessage = data && !!data.showSuccessMessage;
                this.setMode(data?.mode || AuthScreenMode.auth);
            }
        });

        if (
            !this.showForgotPasswordForm &&
            authToken &&
            authToken.trim().length
        ) {
            this.authenticateWithToken(authToken);
        }

        if (
            !this.showForgotPasswordForm &&
            authLinkToken &&
            authLinkToken.trim().length
        ) {
            this.authenticateThroughAuthLink(authLinkToken);
        }
    }

    get isSignIn(): boolean {
        return this.authFormType === AuthFormType.login;
    }

    get isSignUp(): boolean {
        return this.authFormType === AuthFormType.signup;
    }

    get showForgotPasswordForm(): boolean {
        return this.mode === AuthScreenMode.passwordReset;
    }

    get showVerificationEmailSentForm() {
        return this.mode === AuthScreenMode.verificationEmailSent;
    }

    get showTabSwitcher(): boolean {
        return (
            this.showAuthScreen ||
            this.showVerificationEmailSentForm ||
            this.showJoinEmployerForm ||
            this.showRequestAccessForm
        );
    }

    get showRequestEmailSentForm() {
        return this.mode === AuthScreenMode.requestToJoinEmailSent;
    }

    get showJoinEmployerForm() {
        return this.mode === AuthScreenMode.joinEmployer && !!this.prefillData;
    }

    get showRequestAccessForm() {
        return this.mode === AuthScreenMode.requestAccess;
    }

    get showInviteToApplyResponseResult(): boolean {
        return this.mode === AuthScreenMode.spInviteToApplyResponse;
    }

    get isAuthMode() {
        return this.mode === AuthScreenMode.auth;
    }

    get isInitialAuthScreen(): boolean {
        return (
            this.isAuthMode &&
            !this.employerInvitationToken &&
            !this.emailVerificationToken
        );
    }

    get authFormLabels() {
        if (!this.isAuthMode) {
            return undefined;
        }

        switch (this.authFormType) {
            case AuthFormType.login:
                return {
                    header: 'Sign in to your account',
                    switcherInfo: 'Need an account?',
                    switcherButton: 'Register',
                };
            case AuthFormType.signup:
                return {
                    header: this.signupHeader,
                    switcherInfo: 'Have an account?',
                    switcherButton: 'Log In',
                };
            default:
                return undefined;
        }
    }

    private get signupHeader(): string {
        return this.employerInvitationToken || this.emailVerificationToken
            ? 'Create your password'
            : 'Create an account';
    }

    switchFormType(): void {
        const newFormType =
            this.authFormType === AuthFormType.login
                ? AuthFormType.signup
                : AuthFormType.login;
        this.router.navigate([`/${newFormType}/${this.userType}`], {
            queryParams: { redirectTo: this.redirectTo },
        });
    }

    setMode(mode: AuthScreenMode) {
        this.mode = mode;
        this.showAuthScreen =
            this.isAuthMode && this.showAuthFormOnEmailVerificationForUser();
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    authenticate(request: OAuthRequest) {
        if (!this.isAuthenticating) {
            this.isAuthenticating = true;

            this.userService
                .authenticate(request, this.userType)
                .pipe(finalize(() => (this.isAuthenticating = false)))
                .subscribe({
                    next: (user) => {
                        if (!isDefined(user.id)) {
                            this.error =
                                SignupErrorType.newUserForExistingEmployerError;

                            this.requestAccessData = {
                                email: user.email,
                                firstName: user.firstName,
                                lastName: user.lastName,
                                employerName: user.employer?.employerName,
                                employerId: user.employer?.id,
                            };
                            this.setMode(AuthScreenMode.requestAccess);
                        }
                    },
                    complete: () => {
                        if (
                            this.error !==
                            SignupErrorType.newUserForExistingEmployerError
                        ) {
                            this.redirectToFirstActivity();
                        }
                    },
                    error: (error: string) => {
                        error === 'Please register with your work email.'
                            ? (this.error =
                                  SignupErrorType.forbiddenEmailDomainError)
                            : (this.authenticationError = error);
                    },
                });
        }
    }

    authenticateWithToken(token: string) {
        this.isAuthenticating = true;
        this.userService
            .loginWithToken(token)
            .pipe(finalize(() => (this.isAuthenticating = false)))
            .subscribe(
                () => this.redirectToFirstActivity(),
                (error) => (this.authenticationError = error),
            );
    }

    authenticateThroughAuthLink(token: string) {
        this.isAuthenticating = true;
        this.userService.loginWithAuthToken(token).subscribe(
            () => this.redirectToFirstActivity(),
            (error) => {
                this.isAuthenticating = false;
                this.authenticationError = error;
            },
        );
    }

    redirectToFirstActivity() {
        const user = this.userService.user;
        let jobHashId = this.storageService.getSessionEntry(
            getEasySignupJobHashIdKeyPrefix(user.email),
        );

        // as a fallback use queryParam jobHashId, which should be set on backend during creation of authLink
        if (
            !isDefined(jobHashId) &&
            isDefined(this.route.snapshot.queryParams.jobHashId)
        ) {
            jobHashId = this.route.snapshot.queryParams.jobHashId;
        }

        if (user.type === UserType.employer) {
            void this.router.navigateByUrl(this.redirectTo);

            return;
        }

        if (!user.isProfileComplete) {
            this.router.navigateByUrl(
                user.newUser
                    ? '/account/profile/create/resume'
                    : '/account/profile/create/review',
            );
        } else if (isDefined(jobHashId)) {
            // if in session we have destinationJobHashId, we need to generate (or not if exists) and navigate to it
            this.subscription.add(
                this.candidateMatchesApiService
                    .applyForMatchOrGenerateNewIfDoesNotExistAndNavigateTo(
                        jobHashId,
                        user,
                    )
                    .subscribe(() =>
                        this.storageService.removeSessionEntry(
                            getEasySignupJobHashIdKeyPrefix(user.email),
                        ),
                    ),
            );
        } else {
            this.router.navigateByUrl(this.redirectTo);
        }
    }

    onForgotPassword(userType: UserType) {
        this.router.navigate([`/password/${userType}/reset`]);
    }

    onPasswordChange() {
        this.redirectToFirstActivity();
    }

    onEmailVerificationComplete(user: User) {
        if (user.token) {
            this.userService.setUserAndToken(user);
            this.redirectToFirstActivity();
        } else {
            this.router.navigateByUrl('/login/candidate');
        }
    }

    onVerificationEmailSent(email: string) {
        this.emailSentToDescription =
            `<p>An email has been sent to <b>${email}</b>. Please open this email and click the verification link to finish creating your account.</p>` +
            '<br>' +
            `<div>Didn’t receive an email?</div>`;
        const tokenType: TokenType =
            this.userType === UserType.employer
                ? TokenType.employerEmailVerification
                : TokenType.candidateEmailVerification;

        const tokenRequest = {
            email,
            contextData:
                this.userService.getStringifiedPassThroughSignupParamsObject(),
        };
        this.resendEmailVerification = () =>
            this.tokenService.sendTokenToEmail(tokenType, tokenRequest);

        this.setMode(AuthScreenMode.verificationEmailSent);
    }

    onPrefillDataFetched(prefillData: ISignupForm) {
        this.router.navigateByUrl('/signup/employer/join', {
            state: { prefillData },
        });
    }

    private setRequestToJoinObservable() {
        if (this.prefillData?.allowAutoJoin && !!this.prefillData.companyId) {
            this.sendRequestToJoinEmail = () =>
                this.userService
                    .inviteUser(
                        this.prefillData.companyId,
                        this.getInvitationPayload(),
                        UserInvitationStatus.requestToJoin,
                    )
                    .pipe(
                        tap(() => {
                            this.requestToJoinEmailSentDescription =
                                `We just sent you a verification email with a link to ` +
                                `join ${this.prefillData.companyName}'s workspace. The link will expire shortly, so please make ` +
                                `sure to complete the process as soon as you can.`;
                            this.setMode(AuthScreenMode.requestToJoinEmailSent);
                        }),
                    );
        } else {
            this.sendRequestToJoinEmail = () =>
                of([]).pipe(
                    tap(() => {
                        this.requestAccessData = {
                            email: this.prefillData.email,
                            firstName: this.prefillData.firstName,
                            lastName: this.prefillData.lastName,
                            employerName: this.prefillData.companyName,
                            employerId: this.prefillData.companyId,
                        };
                        this.setMode(AuthScreenMode.requestAccess);
                    }),
                );
        }
    }

    private showAuthFormOnEmailVerificationForUser(): boolean {
        return this.userType === UserType.candidate
            ? !this.emailVerificationToken
            : true;
    }

    private getInvitationPayload(): UserInvitation {
        return {
            alreadyUsed: false,
            email: this.prefillData.email || '',
            firstName: this.prefillData.firstName || '',
            lastName: this.prefillData.lastName || '',
            status: UserInvitationStatus.requestToJoin,
            role: 'admin',
        };
    }

    getInviteToApplyFormData(): { title: string; description: string } {
        switch (this.inviteToApplyResponse) {
            case InviteToApplyResponseType.accept:
                return {
                    title: `Your application for the ${this.jobTitle} position is complete!`,
                    description:
                        `Your application for the <strong>${this.jobTitle}</strong> position has been sent to the hiring ` +
                        `manager at <strong>${this.companyName}</strong> for review. You will hear from them directly if ` +
                        `they want to move forward with the interview process. <br/><br/>` +
                        `While you are here take a moment to verify that your SquarePeg profile and resume are up to date.`,
                };
            case InviteToApplyResponseType.decline:
                return {
                    title: 'Thank you for the feedback.',
                    description:
                        'Keeping your SquarePeg profile updated will ensure you continue receiving job matches ' +
                        'based on your unique skills, experience, and personality traits.”<br/><br/>' +
                        'Take a moment to make sure your profile is up to date.',
                };
            case InviteToApplyResponseType.expired:
                return {
                    title: 'Oops! This link has expired.',
                    description:
                        'This invitation has expired - but you can still apply by logging into your SquarePeg account ' +
                        'and applying directly.',
                };
        }
    }
}
