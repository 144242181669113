import type { AppendableItemsListItem } from '../appendable-items-list/model';

export class ScreeningQuestionAnswer {
    questionId: number;
    question: string;
    answer: string;

    static fromScreeningQuestion(screeningQuestion: AppendableItemsListItem) {
        return Object.assign(new ScreeningQuestionAnswer(), {
            questionId: screeningQuestion.id,
            question: screeningQuestion.text,
        });
    }
}
