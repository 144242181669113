import { Injectable } from '@angular/core';
import type { AppNotification } from '../models';
import { byDateDescComparator } from '../utils';

@Injectable()
export class NotificationsDropdownService {
    merge(
        target: AppNotification[],
        source: AppNotification[],
    ): AppNotification[] {
        if (!target || target.length < 1) {
            return (source || []).sort(byDateDescComparator);
        }

        const existingIds = new Set<string>(
            target.map((notification) => notification.id),
        );
        const targetCopy = [...target];
        for (const notification of source) {
            if (!existingIds.has(notification.id)) {
                targetCopy.push(notification);
            }
        }

        return targetCopy.sort(byDateDescComparator);
    }

    setIsSeen(
        notifications: AppNotification[],
        feedIds: string[],
    ): AppNotification[] {
        const notificationsCopy = [...notifications];
        notificationsCopy.forEach((notification) => {
            if (feedIds.includes(notification.activityGroupId)) {
                notification.isSeen = true;
            }
        });

        return notificationsCopy;
    }
}
