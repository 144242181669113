import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SpTagChipsInputControl } from '../sp-form-controls.model';

@Component({
    selector: 'sp-tag-chips',
    templateUrl: './tag-chips.component.html',
    styleUrls: ['./tag-chips.component.scss'],
})
export class TagChipsComponent {
    @Input()
    public control: SpTagChipsInputControl;

    @Output()
    public chipsDelete = new EventEmitter();

    removeFromSelected(item: string) {
        this.chipsDelete.emit(item);
        this.control.chipClick.emit();
    }

    onChipClicked() {
        this.control.chipClick.emit();
    }
}
