import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorContext } from '../models';

@Component({
    selector: 'sp-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
    @Input() message = '';
    @Input() colorContext: ColorContext;

    @Output() dismissNotification = new EventEmitter();

    get isDangerContext() {
        return this.colorContext === ColorContext.WARNING;
    }

    dismiss(event: Event) {
        event.stopPropagation();
        this.dismissNotification.emit();
    }
}
