<ul class="dropdown-items" [style.width]="styles?.width" [style.padding]="styles?.padding">
    <ng-container *ngIf="!listItemTemplateRef">
        <li
            *ngFor="let item of items"
            [style.padding-top.px]="styles?.itemVerticalPadding"
            [style.padding-bottom.px]="styles?.itemVerticalPadding"
            [style.padding-left.px]="styles?.itemHorizontalPadding"
            [style.padding-right.px]="styles?.itemHorizontalPadding"
            class="dropdown-list-item"
        >
            <a
                href="javascript:"
                [style.line-height.px]="styles?.lineHeight"
                [style.font-size.px]="styles?.fontSize"
                [style.color]="styles?.fontColor"
                [style.background-image]="item.icon ? 'url(\'' + item.icon + '\')' : null"
                [class.with-icon]="!!item.icon"
                (click)="onDropdownItemClick(item)"
            >
                {{ item.label }}
            </a>
        </li>
    </ng-container>

    <!-- custom li item template provided from a parent component -->
    <ng-container *ngIf="listItemTemplateRef">
        <ng-container *ngFor="let item of items">
            <ng-container
                *ngTemplateOutlet="
                    listItemTemplateRef;
                    context: {
                        templateData: { item: item, data: data },
                        clickHandler: onDropdownItemClick.bind(this)
                    }
                "
            >
            </ng-container>
        </ng-container>
    </ng-container>
</ul>
