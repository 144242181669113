import { isDefined } from './utils';

export const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export const msInDay = 24 * 3600 * 1000;
export const msInMinute = 60 * 1000;
export const msInHour = 3600 * 1000;
export const msIn180days = msInDay * 180;
export const hoursInDay = 24;
export const daysInMonth = 30;
export const daysInMonthBoundary = 7 * 5;

export function toDate(date: Date | null) {
    if (!date) {
        return null;
    }

    const localDate = new Date(date);

    return new Date(
        Date.UTC(
            localDate.getFullYear(),
            localDate.getMonth(),
            localDate.getDate(),
            localDate.getHours(),
            localDate.getMinutes(),
            localDate.getSeconds(),
            localDate.getMilliseconds(),
        ),
    );
}

export function isValidDateObject(date): boolean {
    return date && date instanceof Date && date.toString() !== 'Invalid Date';
}

export function datesAreOnSameDay(start: Date, end: Date): boolean {
    return (
        start.getFullYear() === end.getFullYear() &&
        start.getMonth() === end.getMonth() &&
        start.getDate() === end.getDate()
    );
}

export function isWithinDaysBeforeNow(date: Date, days: number): boolean {
    const daysInMs = days * msInDay;
    const startTimestamp = new Date().getTime() - daysInMs;
    const startDate = new Date(startTimestamp);

    const startDateZeroSecondTimestamp = Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        0,
        0,
        0,
        0,
    );

    return startDateZeroSecondTimestamp <= date.getTime();
}

export function isWithinHoursBeforeNow(date: Date, hours: number): boolean {
    const hoursInMs = hours * msInHour;

    return new Date().getTime() - hoursInMs <= date.getTime();
}

export function daysBetweenDates(start: Date, end: Date): number {
    if (!start || !end) {
        return 0;
    }
    const startMs = Date.UTC(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        0,
        0,
        0,
        0,
    );
    const endMs = Date.UTC(
        end.getFullYear(),
        end.getMonth(),
        end.getDate(),
        0,
        0,
        0,
        0,
    );

    return Math.floor((endMs - startMs) / msInDay);
}

// Based on the input Date object provided constructs new Date object storing
// the same Date value at '00:00:00.000' i.e. the beginning of the day
export function toStartOfDayTimestamp(date: Date): Date {
    if (!date) {
        return null;
    }

    const copy = new Date(date.getTime());
    copy.setHours(0, 0, 0, 0);

    return copy;
}

// Calculation of total months without calculation of overlapping period
export function getTotalMonthsInDateRangeEntries(
    data: any[],
    startKeyName: string,
    endKeyName: string,
): number {
    let totalMonths = 0;

    // Map data to array of objects with start date and end date
    const mappedDates = data.map((entry) => ({
        startDate: entry[startKeyName]
            ? new Date(entry[startKeyName])
            : new Date(),
        endDate: entry[endKeyName] ? new Date(entry[endKeyName]) : new Date(),
    }));

    // Sort the date ranges by start date
    const sortedRanges = mappedDates.sort(
        (a, b) => a.startDate.getTime() - b.startDate.getTime(),
    );

    // Iterate through each date range
    sortedRanges.forEach((range, index) => {
        const startDate = range.startDate;
        const endDate = range.endDate;

        // Calculate the number of months for the current date range
        const rangeMonths = getTotalMonthsInDateRange(startDate, endDate);

        if (index > 0) {
            const previousRange = sortedRanges[index - 1];
            const previousEndDate = previousRange.endDate;

            // Check for overlap with the previous date range
            if (startDate <= previousEndDate) {
                const overlapMonths = getTotalMonthsInDateRange(
                    startDate,
                    previousEndDate,
                );
                totalMonths -= overlapMonths;
            }
        }

        // Add the months for the current date range to the total
        totalMonths += rangeMonths;
    });

    return totalMonths;
}

export function calculateTotalExperienceInfo(
    data: any,
    startKeyName: string,
    endKeyName: string,
): string {
    const totalMonths = getTotalMonthsInDateRangeEntries(
        [data],
        startKeyName,
        endKeyName,
    );
    const totalYears = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    const totalYearsStr =
        totalYears > 0 ? `${totalYears} year${totalYears > 1 ? 's' : ''}` : '';
    const monthsStr =
        months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '';

    return `${totalYearsStr} ${monthsStr}`;
}

export function calculateDateRange(
    startDate?: Date,
    endDate?: Date,
    isCurrent?: boolean,
): string {
    let dateRange = '';
    if (isDefined(startDate)) {
        dateRange += `${
            monthNames[startDate.getMonth()]
        } ${startDate.getFullYear()}`;
    }
    if (isCurrent) {
        dateRange += ` - Current`;
    } else if (isDefined(endDate)) {
        dateRange += ` - ${
            monthNames[endDate.getMonth()]
        } ${endDate.getFullYear()}`;
    }

    return dateRange;
}

export function getNumberOfYearsFromNumberOfMonths(months: number): number {
    return Math.ceil(months / 12);
}

export function getMostRecentDateRangeEntry(
    data: any[],
    isCurrentKeyName: string,
    endDateKeyName: string,
): any {
    if (!isDefined(data) || data.length === 0) {
        return [];
    }

    const now = new Date().getTime();

    const sortedActivities: any = [...data].sort((a, b) => {
        const aEndDate = a[isCurrentKeyName]
            ? now
            : new Date(a[endDateKeyName]).getTime();
        const bEndDate = b[isCurrentKeyName]
            ? now
            : new Date(b[endDateKeyName]).getTime();

        return bEndDate - aEndDate;
    });

    return sortedActivities[0];
}

export function getStringValueFromHours(hours: number): string {
    if (hours > 0 && hours < 3 * hoursInDay) {
        // if less than 3 days in hours, show 'hours'
        return `${hours} hour${hours === 1 ? '' : 's'}`;
    } else if (hours < daysInMonth * hoursInDay) {
        // if more than 3 days in hours and less than 30 days in hours, show 'days'
        return `${Math.ceil(hours / hoursInDay)} days`;
    }

    // if more than 3 days in hours and less than 30 days in hours, show 'days'
    return `${Math.ceil(hours / (daysInMonth * hoursInDay))} months`;
}

export function addHoursToDate(date: Date, hours: number): Date {
    const result = new Date();
    result.setTime(date.getTime() + msInHour * hours);

    return result;
}

function getTotalMonthsInDateRange(startDate: Date, endDate: Date): number {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();

    // Adding 1 to ensure the last month is included.
    return (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
}

export function getTimeAgo(date: Date | null, useToday?: boolean): string {
    if (!date) {
        return '';
    }

    const now = new Date();
    const refDate = new Date(date);

    let value;
    let unit;

    const days = Math.floor((now.getTime() - refDate.getTime()) / msInDay);
    if (days === 0 && useToday) {
        return 'today';
    } else if (days < 7) {
        value = days;
        unit = 'day';
    } else if (days < daysInMonthBoundary) {
        value = Math.floor(days / 7);
        unit = 'week';
    } else if (days < 365) {
        value = Math.floor(days / 31);
        unit = 'month';
    } else {
        let years = now.getFullYear() - refDate.getFullYear();
        if (years < 1) {
            years = 1;
        }
        value = years;
        unit = 'year';
    }

    if (value !== 1 || !(value % 10 === 1 && value !== 11)) {
        unit += 's';
    }

    return `${value} ${unit} ago`;
}
