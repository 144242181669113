<ul class="top-menu-items" *ngIf="topListItems">
    <li
        class="top-items-wrapper"
        *ngFor="let item of topListItems"
        (click)="onTopListItemClicked(item.actionType)"
    >
        <a>
            <img src="{{ item.icon }}" />
            {{ item.text }}
        </a>
    </li>
</ul>

<div class="separator"></div>

<div class="section-wrapper">
    <ng-container *ngIf="recentlyUsedTemplatesSectionData?.length > 0">
        <h3 class="section-title">Recently used</h3>
        <ul class="list-all-templates">
            <li
                *ngFor="let template of recentlyUsedTemplatesSectionData"
                (mouseenter)="onRowHovered($event, template.body)"
                (mouseleave)="onRowLeave()"
            >
                <sp-template-item
                    (click)="onTemplateClick($event, template)"
                    [stage]="template.stage"
                    [title]="template.title"
                    [body]="template.body"
                >
                </sp-template-item>
            </li>
        </ul>
        <div class="separator"></div>
    </ng-container>

    <ng-container *ngIf="allTemplatesSectionData?.length === 0">
        <ng-container
            *ngTemplateOutlet="emptyStateContent; context: { emptyData: noActiveTemplatesFound }"
        >
        </ng-container>
    </ng-container>

    <ng-container *ngIf="allTemplatesSectionData?.length > 0">
        <h3 class="section-title">All</h3>
        <span class="controls-wrapper">
            <span class="search-icon"></span>
            <input
                type="text"
                class="panel-search-input"
                placeholder="Search by template name"
                (click)="onSearchInputClick($event)"
                (input)="onSearchValueChange($event)"
            />
        </span>
        <ng-container
            *ngIf="allTemplatesSectionData && filteredAllTemplatesSectionData?.length === 0"
        >
            <ng-container
                *ngTemplateOutlet="
                    emptyStateContent;
                    context: { emptyData: noFilteredTemplatesFound }
                "
            >
            </ng-container>
        </ng-container>
        <ul
            *ngIf="allTemplatesSectionData && filteredAllTemplatesSectionData?.length > 0"
            class="list-all-templates"
        >
            <li
                *ngFor="let template of filteredAllTemplatesSectionData"
                (mouseenter)="onRowHovered($event, template.body)"
                (mouseleave)="onRowLeave()"
            >
                <sp-template-item
                    (click)="onTemplateClick($event, template)"
                    [stage]="template.stage"
                    [title]="template.title"
                    [body]="template.body"
                >
                </sp-template-item>
            </li>
        </ul>
    </ng-container>
</div>

<ng-template #emptyStateContent let-emptyData="emptyData">
    <div class="empty-content">
        <div>
            <img src="/images/icons/empty-state.svg" />
        </div>
        <h3>{{ emptyData.title }}</h3>
        <p>{{ emptyData.body }}</p>
    </div>
</ng-template>
