import { EventEmitter } from '@angular/core';
import type { Options } from '@angular-slider/ngx-slider';
import { Subject } from 'rxjs';
import {
    SpComponentInput,
    SpFormControlType,
} from '../../sp-form-controls.model';
import { SpSliderBulletSize } from './slider-bullet-size.model';
import type { SpSliderControlParams } from './slider-form-input-control-params.model';

export class SpSliderControl extends SpComponentInput {
    lowValue?: number | string | object;
    highValue?: number | string | object;
    bulletSize?: SpSliderBulletSize;
    manualRefresh?: EventEmitter<void>;
    sliderOptions?: Options;
    labelBeforeValue?: string;
    labelAfterValue?: string;
    average?: number;
    defaultValue?: number;
    resetTooltip?: string;
    styles?: { [key: string]: any };
    colorScheme: 'default' | 'light' | 'gradient' = 'default';
    iconUrl?: string;
    iconColor?: string;
    gradientStepColors?: { [value: string]: string };
    bottomLabels?: { left: string; right: string };
    hideRequiredText?: boolean;
    withCustomHeader?: boolean;

    // outputs
    valueChange = new EventEmitter<any>();
    setValue$: Subject<{ lowValue: number; highValue: number }>;

    constructor(params: SpSliderControlParams) {
        super(params);
        this.controlType = SpFormControlType.component;
        this.lowValue = params.lowValue;
        this.highValue = params.highValue;
        this.bulletSize =
            params.bulletSize === undefined
                ? SpSliderBulletSize.big
                : params.bulletSize;
        // Hack to make slider refresh. Read the official docs for the details
        this.manualRefresh = new EventEmitter<void>();
        this.setValue$ = new Subject();
        this.component = 'SpSliderComponent';
        this.sliderOptions = params.options
            ? { animate: false, ...params.options }
            : { animate: false };
        this.styles = params.styles;
        this.colorScheme = params.colorScheme || 'default';
        this.iconUrl = params.iconUrl;
        this.iconColor = params.iconColor;
        this.gradientStepColors = params.gradientStepColors;
        this.labelBeforeValue = params.labelBeforeValue;
        this.labelAfterValue = params.labelAfterValue;
        this.average = params.average;
        this.defaultValue = params.defaultValue;
        this.resetTooltip = params.resetTooltip;
        this.bottomLabels = params.bottomLabels;
        this.hideRequiredText = params.hideRequiredText;
        this.validators = params.validators;
        this.withCustomHeader = params.withCustomHeader;
    }

    onValueChange(value: any) {
        this.lowValue = value;
        this.updateModels();
    }

    onHighValueChange(value: any) {
        this.highValue = value;
        this.updateModels();
    }

    private updateModels() {
        this.formControl.patchValue({
            value: this.lowValue,
            highValue: this.highValue,
        });
        this.valueChange.emit({
            value: this.lowValue,
            highValue: this.highValue,
        });
    }
}
