import { Injectable } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';
import type { DialogRef } from '../dialog-ref';
import { FullScreenLoadingIndicatorComponent } from './full-screen-loading-indicator.component';
import type { AsyncTaskType } from './full-screen-loading-indicator.utils';
import { rotatingTextsByAsyncTaskType } from './full-screen-loading-indicator.utils';

@Injectable()
export class FullScreenLoadingIndicatorService {
    private dialogRef: DialogRef<FullScreenLoadingIndicatorComponent>;
    constructor(private dialogService: DialogService) {}

    open(currentTask: AsyncTaskType, delayInMs = 6000) {
        this.dialogRef = this.dialogService.open(
            FullScreenLoadingIndicatorComponent,
            {
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                maxWidth: '100%',
                hasBackdrop: false,
                contentComponentData: {
                    rotatingMessages: rotatingTextsByAsyncTaskType[currentTask],
                    delayInMs,
                },
            },
        );
    }

    close() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }
}
