import { APP_INITIALIZER, NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HammerModule } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CoreModule } from './core/core.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { UserService } from './core/user.service';
import { UserflowService } from './core/userflow.service';
import { AnalyticsService } from './core/analytics.service';

function initUser(userService: UserService) {
    return () => userService.initUser();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        SharedModule.forRoot(),
        AuthModule,
        DashboardModule,
        QuillModule.forRoot(),
        ScrollingModule,
        HammerModule,
        PdfViewerModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initUser,
            deps: [UserService, UserflowService, AnalyticsService],
            multi: true,
        },
    ],
})
export class AppModule {}
