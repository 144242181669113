<div
    class="wrapper"
    spTooltip
    [tooltipText]="tooltip"
    [widthType]="TooltipWidthType.normal"
    [textAlign]="'left'"
    [cssOverrides]="{ fontSize: '1.4rem', lineHeight: '2.1rem' }"
>
    <div class="inner-wrapper">
        <div class="dot"></div>
    </div>
</div>
