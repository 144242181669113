import type { CountryCode } from 'libphonenumber-js';
import { findNumbers, format } from 'libphonenumber-js';
import { defaultCountryCode } from './constants';
import type { SelectorImageItem } from './forms/custom-controls/sp-form-controls.model';

const countries: ISOCountry[] = [
    {
        name: 'Afghanistan (‫افغانستان‬‎)',
        dialCode: '93',
        iso2Code: 'af',
    },
    { name: 'Albania (Shqipëri)', dialCode: '355', iso2Code: 'al' },
    { name: 'Algeria (‫الجزائر‬‎)', dialCode: '213', iso2Code: 'dz' },
    {
        name: 'American Samoa',
        dialCode: '1',
        iso2Code: 'as',
        order: 5,
        areaCodes: ['684'],
    },
    { name: 'Andorra', dialCode: '376', iso2Code: 'ad' },
    { name: 'Angola', dialCode: '244', iso2Code: 'ao' },
    {
        name: 'Anguilla',
        dialCode: '1',
        iso2Code: 'ai',
        order: 6,
        areaCodes: ['264'],
    },
    {
        name: 'Antigua and Barbuda',
        dialCode: '1',
        iso2Code: 'ag',
        order: 7,
        areaCodes: ['268'],
    },
    { name: 'Argentina', dialCode: '54', iso2Code: 'ar' },
    { name: 'Armenia (Հայաստան)', dialCode: '374', iso2Code: 'am' },
    { name: 'Aruba', dialCode: '297', iso2Code: 'aw' },
    { name: 'Australia', dialCode: '61', iso2Code: 'au', order: 0 },
    { name: 'Austria (Österreich)', dialCode: '43', iso2Code: 'at' },
    { name: 'Azerbaijan (Azərbaycan)', dialCode: '994', iso2Code: 'az' },
    {
        name: 'Bahamas',
        dialCode: '1',
        iso2Code: 'bs',
        order: 8,
        areaCodes: ['242'],
    },
    { name: 'Bahrain (‫البحرين‬‎)', dialCode: '973', iso2Code: 'bh' },
    { name: 'Bangladesh (বাংলাদেশ)', dialCode: '880', iso2Code: 'bd' },
    {
        name: 'Barbados',
        dialCode: '1',
        iso2Code: 'bb',
        order: 9,
        areaCodes: ['246'],
    },
    { name: 'Belarus (Беларусь)', dialCode: '375', iso2Code: 'by' },
    { name: 'Belgium (België)', dialCode: '32', iso2Code: 'be' },
    { name: 'Belize', dialCode: '501', iso2Code: 'bz' },
    { name: 'Benin (Bénin)', dialCode: '229', iso2Code: 'bj' },
    {
        name: 'Bermuda',
        dialCode: '1',
        iso2Code: 'bm',
        order: 10,
        areaCodes: ['441'],
    },
    { name: 'Bhutan (འབྲུག)', dialCode: '975', iso2Code: 'bt' },
    { name: 'Bolivia', dialCode: '591', iso2Code: 'bo' },
    {
        name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
        dialCode: '387',
        iso2Code: 'ba',
    },
    { name: 'Botswana', dialCode: '267', iso2Code: 'bw' },
    { name: 'Brazil (Brasil)', dialCode: '55', iso2Code: 'br' },
    { name: 'British Indian Ocean Territory', dialCode: '246', iso2Code: 'io' },
    {
        name: 'British Virgin Islands',
        dialCode: '1',
        iso2Code: 'vg',
        order: 11,
        areaCodes: ['284'],
    },
    { name: 'Brunei', dialCode: '673', iso2Code: 'bn' },
    { name: 'Bulgaria (България)', dialCode: '359', iso2Code: 'bg' },
    { name: 'Burkina Faso', dialCode: '226', iso2Code: 'bf' },
    { name: 'Burundi (Uburundi)', dialCode: '257', iso2Code: 'bi' },
    { name: 'Cambodia (កម្ពុជា)', dialCode: '855', iso2Code: 'kh' },
    { name: 'Cameroon (Cameroun)', dialCode: '237', iso2Code: 'cm' },
    {
        name: 'Canada',
        dialCode: '1',
        iso2Code: 'ca',
        order: 1,
        areaCodes: [
            '204',
            '226',
            '236',
            '249',
            '250',
            '289',
            '306',
            '343',
            '365',
            '387',
            '403',
            '416',
            '418',
            '431',
            '437',
            '438',
            '450',
            '506',
            '514',
            '519',
            '548',
            '579',
            '581',
            '587',
            '604',
            '613',
            '639',
            '647',
            '672',
            '705',
            '709',
            '742',
            '778',
            '780',
            '782',
            '807',
            '819',
            '825',
            '867',
            '873',
            '902',
            '905',
        ],
    },
    { name: 'Cape Verde (Kabu Verdi)', dialCode: '238', iso2Code: 'cv' },
    {
        name: 'Caribbean Netherlands',
        dialCode: '599',
        iso2Code: 'bq',
        order: 1,
        areaCodes: ['3', '4', '7'],
    },
    {
        name: 'Cayman Islands',
        dialCode: '1',
        iso2Code: 'ky',
        order: 12,
        areaCodes: ['345'],
    },
    {
        name: 'Central African Republic (République centrafricaine)',
        dialCode: '236',
        iso2Code: 'cf',
    },
    { name: 'Chad (Tchad)', dialCode: '235', iso2Code: 'td' },
    { name: 'Chile', dialCode: '56', iso2Code: 'cl' },
    { name: 'China (中国)', dialCode: '86', iso2Code: 'cn' },
    {
        name: 'Christmas Island',
        dialCode: '61',
        iso2Code: 'cx',
        order: 2,
        areaCodes: ['89164'],
    },
    {
        name: 'Cocos (Keeling) Islands',
        dialCode: '61',
        iso2Code: 'cc',
        order: 1,
        areaCodes: ['89162'],
    },
    { name: 'Colombia', dialCode: '57', iso2Code: 'co' },
    { name: 'Comoros (‫جزر القمر‬‎)', dialCode: '269', iso2Code: 'km' },
    {
        name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
        dialCode: '243',
        iso2Code: 'cd',
    },
    {
        name: 'Congo (Republic) (Congo-Brazzaville)',
        dialCode: '242',
        iso2Code: 'cg',
    },
    { name: 'Cook Islands', dialCode: '682', iso2Code: 'ck' },
    { name: 'Costa Rica', dialCode: '506', iso2Code: 'cr' },
    { name: 'Côte d’Ivoire', dialCode: '225', iso2Code: 'ci' },
    { name: 'Croatia (Hrvatska)', dialCode: '385', iso2Code: 'hr' },
    { name: 'Cuba', dialCode: '53', iso2Code: 'cu' },
    { name: 'Curaçao', dialCode: '599', iso2Code: 'cw', order: 0 },
    { name: 'Cyprus (Κύπρος)', dialCode: '357', iso2Code: 'cy' },
    {
        name: 'Czech Republic (Česká republika)',
        dialCode: '420',
        iso2Code: 'cz',
    },
    { name: 'Denmark (Danmark)', dialCode: '45', iso2Code: 'dk' },
    { name: 'Djibouti', dialCode: '253', iso2Code: 'dj' },
    {
        name: 'Dominica',
        dialCode: '1',
        iso2Code: 'dm',
        order: 13,
        areaCodes: ['767'],
    },
    {
        name: 'Dominican Republic (República Dominicana)',
        dialCode: '1',
        iso2Code: 'do',
        order: 2,
        areaCodes: ['809', '829', '849'],
    },
    { name: 'Ecuador', dialCode: '593', iso2Code: 'ec' },
    { name: 'Egypt (‫مصر‬‎)', dialCode: '20', iso2Code: 'eg' },
    { name: 'El Salvador', dialCode: '503', iso2Code: 'sv' },
    {
        name: 'Equatorial Guinea (Guinea Ecuatorial)',
        dialCode: '240',
        iso2Code: 'gq',
    },
    { name: 'Eritrea', dialCode: '291', iso2Code: 'er' },
    { name: 'Estonia (Eesti)', dialCode: '372', iso2Code: 'ee' },
    { name: 'Eswatini', dialCode: '268', iso2Code: 'sz' },
    { name: 'Ethiopia', dialCode: '251', iso2Code: 'et' },
    {
        name: 'Falkland Islands (Islas Malvinas)',
        dialCode: '500',
        iso2Code: 'fk',
    },
    { name: 'Faroe Islands (Føroyar)', dialCode: '298', iso2Code: 'fo' },
    { name: 'Fiji', dialCode: '679', iso2Code: 'fj' },
    { name: 'Finland (Suomi)', dialCode: '358', iso2Code: 'fi', order: 0 },
    { name: 'France', dialCode: '33', iso2Code: 'fr' },
    {
        name: 'French Guiana (Guyane française)',
        dialCode: '594',
        iso2Code: 'gf',
    },
    {
        name: 'French Polynesia (Polynésie française)',
        dialCode: '689',
        iso2Code: 'pf',
    },
    { name: 'Gabon', dialCode: '241', iso2Code: 'ga' },
    { name: 'Gambia', dialCode: '220', iso2Code: 'gm' },
    { name: 'Georgia (საქართველო)', dialCode: '995', iso2Code: 'ge' },
    { name: 'Germany (Deutschland)', dialCode: '49', iso2Code: 'de' },
    { name: 'Ghana (Gaana)', dialCode: '233', iso2Code: 'gh' },
    { name: 'Gibraltar', dialCode: '350', iso2Code: 'gi' },
    { name: 'Greece (Ελλάδα)', dialCode: '30', iso2Code: 'gr' },
    { name: 'Greenland (Kalaallit Nunaat)', dialCode: '299', iso2Code: 'gl' },
    {
        name: 'Grenada',
        dialCode: '1',
        iso2Code: 'gd',
        order: 14,
        areaCodes: ['473'],
    },
    { name: 'Guadeloupe', dialCode: '590', iso2Code: 'gp', order: 0 },
    {
        name: 'Guam',
        dialCode: '1',
        iso2Code: 'gu',
        order: 15,
        areaCodes: ['671'],
    },
    { name: 'Guatemala', dialCode: '502', iso2Code: 'gt' },
    {
        name: 'Guernsey',
        dialCode: '44',
        iso2Code: 'gg',
        order: 1,
        areaCodes: ['1481', '7781', '7839', '7911'],
    },
    { name: 'Guinea (Guinée)', dialCode: '224', iso2Code: 'gn' },
    { name: 'Guinea-Bissau (Guiné Bissau)', dialCode: '245', iso2Code: 'gw' },
    { name: 'Guyana', dialCode: '592', iso2Code: 'gy' },
    { name: 'Haiti', dialCode: '509', iso2Code: 'ht' },
    { name: 'Honduras', dialCode: '504', iso2Code: 'hn' },
    { name: 'Hong Kong (香港)', dialCode: '852', iso2Code: 'hk' },
    { name: 'Hungary (Magyarország)', dialCode: '36', iso2Code: 'hu' },
    { name: 'Iceland (Ísland)', dialCode: '354', iso2Code: 'is' },
    { name: 'India (भारत)', dialCode: '91', iso2Code: 'in' },
    { name: 'Indonesia', dialCode: '62', iso2Code: 'id' },
    { name: 'Iran (‫ایران‬‎)', dialCode: '98', iso2Code: 'ir' },
    { name: 'Iraq (‫العراق‬‎)', dialCode: '964', iso2Code: 'iq' },
    { name: 'Ireland', dialCode: '353', iso2Code: 'ie' },
    {
        name: 'Isle of Man',
        dialCode: '44',
        iso2Code: 'im',
        order: 2,
        areaCodes: ['1624', '74576', '7524', '7924', '7624'],
    },
    { name: 'Israel (‫ישראל‬‎)', dialCode: '972', iso2Code: 'il' },
    { name: 'Italy (Italia)', dialCode: '39', iso2Code: 'it', order: 0 },
    {
        name: 'Jamaica',
        dialCode: '1',
        iso2Code: 'jm',
        order: 4,
        areaCodes: ['876', '658'],
    },
    { name: 'Japan (日本)', dialCode: '81', iso2Code: 'jp' },
    {
        name: 'Jersey',
        dialCode: '44',
        iso2Code: 'je',
        order: 3,
        areaCodes: ['1534', '7509', '7700', '7797', '7829', '7937'],
    },
    { name: 'Jordan (‫الأردن‬‎)', dialCode: '962', iso2Code: 'jo' },
    {
        name: 'Kazakhstan (Казахстан)',
        dialCode: '7',
        iso2Code: 'kz',
        order: 1,
        areaCodes: ['33', '7'],
    },
    { name: 'Kenya', dialCode: '254', iso2Code: 'ke' },
    { name: 'Kiribati', dialCode: '686', iso2Code: 'ki' },
    { name: 'Kosovo', dialCode: '383', iso2Code: 'xk' },
    { name: 'Kuwait (‫الكويت‬‎)', dialCode: '965', iso2Code: 'kw' },
    { name: 'Kyrgyzstan (Кыргызстан)', dialCode: '996', iso2Code: 'kg' },
    { name: 'Laos (ລາວ)', dialCode: '856', iso2Code: 'la' },
    { name: 'Latvia (Latvija)', dialCode: '371', iso2Code: 'lv' },
    { name: 'Lebanon (‫لبنان‬‎)', dialCode: '961', iso2Code: 'lb' },
    { name: 'Lesotho', dialCode: '266', iso2Code: 'ls' },
    { name: 'Liberia', dialCode: '231', iso2Code: 'lr' },
    { name: 'Libya (‫ليبيا‬‎)', dialCode: '218', iso2Code: 'ly' },
    { name: 'Liechtenstein', dialCode: '423', iso2Code: 'li' },
    { name: 'Lithuania (Lietuva)', dialCode: '370', iso2Code: 'lt' },
    { name: 'Luxembourg', dialCode: '352', iso2Code: 'lu' },
    { name: 'Macau (澳門)', dialCode: '853', iso2Code: 'mo' },
    { name: 'Madagascar (Madagasikara)', dialCode: '261', iso2Code: 'mg' },
    { name: 'Malawi', dialCode: '265', iso2Code: 'mw' },
    { name: 'Malaysia', dialCode: '60', iso2Code: 'my' },
    { name: 'Maldives', dialCode: '960', iso2Code: 'mv' },
    { name: 'Mali', dialCode: '223', iso2Code: 'ml' },
    { name: 'Malta', dialCode: '356', iso2Code: 'mt' },
    { name: 'Marshall Islands', dialCode: '692', iso2Code: 'mh' },
    { name: 'Martinique', dialCode: '596', iso2Code: 'mq' },
    { name: 'Mauritania (‫موريتانيا‬‎)', dialCode: '222', iso2Code: 'mr' },
    { name: 'Mauritius (Moris)', dialCode: '230', iso2Code: 'mu' },
    {
        name: 'Mayotte',
        dialCode: '262',
        iso2Code: 'yt',
        order: 1,
        areaCodes: ['269', '639'],
    },
    { name: 'Mexico (México)', dialCode: '52', iso2Code: 'mx' },
    { name: 'Micronesia', dialCode: '691', iso2Code: 'fm' },
    { name: 'Moldova (Republica Moldova)', dialCode: '373', iso2Code: 'md' },
    { name: 'Monaco', dialCode: '377', iso2Code: 'mc' },
    { name: 'Mongolia (Монгол)', dialCode: '976', iso2Code: 'mn' },
    { name: 'Montenegro (Crna Gora)', dialCode: '382', iso2Code: 'me' },
    {
        name: 'Montserrat',
        dialCode: '1',
        iso2Code: 'ms',
        order: 16,
        areaCodes: ['664'],
    },
    { name: 'Morocco (‫المغرب‬‎)', dialCode: '212', iso2Code: 'ma', order: 0 },
    { name: 'Mozambique (Moçambique)', dialCode: '258', iso2Code: 'mz' },
    { name: 'Myanmar (Burma) (မြန်မာ)', dialCode: '95', iso2Code: 'mm' },
    { name: 'Namibia (Namibië)', dialCode: '264', iso2Code: 'na' },
    { name: 'Nauru', dialCode: '674', iso2Code: 'nr' },
    { name: 'Nepal (नेपाल)', dialCode: '977', iso2Code: 'np' },
    { name: 'Netherlands (Nederland)', dialCode: '31', iso2Code: 'nl' },
    {
        name: 'New Caledonia (Nouvelle-Calédonie)',
        dialCode: '687',
        iso2Code: 'nc',
    },
    { name: 'New Zealand', dialCode: '64', iso2Code: 'nz' },
    { name: 'Nicaragua', dialCode: '505', iso2Code: 'ni' },
    { name: 'Niger (Nijar)', dialCode: '227', iso2Code: 'ne' },
    { name: 'Nigeria', dialCode: '234', iso2Code: 'ng' },
    { name: 'Niue', dialCode: '683', iso2Code: 'nu' },
    { name: 'Norfolk Island', dialCode: '672', iso2Code: 'nf' },
    {
        name: 'North Korea (조선 민주주의 인민 공화국)',
        dialCode: '850',
        iso2Code: 'kp',
    },
    {
        name: 'North Macedonia (Северна Македонија)',
        dialCode: '389',
        iso2Code: 'mk',
    },
    {
        name: 'Northern Mariana Islands',
        dialCode: '1',
        iso2Code: 'mp',
        order: 17,
        areaCodes: ['670'],
    },
    { name: 'Norway (Norge)', dialCode: '47', iso2Code: 'no', order: 0 },
    { name: 'Oman (‫عُمان‬‎)', dialCode: '968', iso2Code: 'om' },
    { name: 'Pakistan (‫پاکستان‬‎)', dialCode: '92', iso2Code: 'pk' },
    { name: 'Palau', dialCode: '680', iso2Code: 'pw' },
    { name: 'Palestine (‫فلسطين‬‎)', dialCode: '970', iso2Code: 'ps' },
    { name: 'Panama (Panamá)', dialCode: '507', iso2Code: 'pa' },
    { name: 'Papua New Guinea', dialCode: '675', iso2Code: 'pg' },
    { name: 'Paraguay', dialCode: '595', iso2Code: 'py' },
    { name: 'Peru (Perú)', dialCode: '51', iso2Code: 'pe' },
    { name: 'Philippines', dialCode: '63', iso2Code: 'ph' },
    { name: 'Poland (Polska)', dialCode: '48', iso2Code: 'pl' },
    { name: 'Portugal', dialCode: '351', iso2Code: 'pt' },
    {
        name: 'Puerto Rico',
        dialCode: '1',
        iso2Code: 'pr',
        order: 3,
        areaCodes: ['787', '939'],
    },
    { name: 'Qatar (‫قطر‬‎)', dialCode: '974', iso2Code: 'qa' },
    { name: 'Réunion (La Réunion)', dialCode: '262', iso2Code: 're', order: 0 },
    { name: 'Romania (România)', dialCode: '40', iso2Code: 'ro' },
    { name: 'Russia (Россия)', dialCode: '7', iso2Code: 'ru', order: 0 },
    { name: 'Rwanda', dialCode: '250', iso2Code: 'rw' },
    { name: 'Saint Barthélemy', dialCode: '590', iso2Code: 'bl', order: 1 },
    { name: 'Saint Helena', dialCode: '290', iso2Code: 'sh' },
    {
        name: 'Saint Kitts and Nevis',
        dialCode: '1',
        iso2Code: 'kn',
        order: 18,
        areaCodes: ['869'],
    },
    {
        name: 'Saint Lucia',
        dialCode: '1',
        iso2Code: 'lc',
        order: 19,
        areaCodes: ['758'],
    },
    {
        name: 'Saint Martin (Saint-Martin (partie française))',
        dialCode: '590',
        iso2Code: 'mf',
        order: 2,
    },
    {
        name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
        dialCode: '508',
        iso2Code: 'pm',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        dialCode: '1',
        iso2Code: 'vc',
        order: 20,
        areaCodes: ['784'],
    },
    { name: 'Samoa', dialCode: '685', iso2Code: 'ws' },
    { name: 'San Marino', dialCode: '378', iso2Code: 'sm' },
    {
        name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
        dialCode: '239',
        iso2Code: 'st',
    },
    {
        name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
        dialCode: '966',
        iso2Code: 'sa',
    },
    { name: 'Senegal (Sénégal)', dialCode: '221', iso2Code: 'sn' },
    { name: 'Serbia (Србија)', dialCode: '381', iso2Code: 'rs' },
    { name: 'Seychelles', dialCode: '248', iso2Code: 'sc' },
    { name: 'Sierra Leone', dialCode: '232', iso2Code: 'sl' },
    { name: 'Singapore', dialCode: '65', iso2Code: 'sg' },
    {
        name: 'Sint Maarten',
        dialCode: '1',
        iso2Code: 'sx',
        order: 21,
        areaCodes: ['721'],
    },
    { name: 'Slovakia (Slovensko)', dialCode: '421', iso2Code: 'sk' },
    { name: 'Slovenia (Slovenija)', dialCode: '386', iso2Code: 'si' },
    { name: 'Solomon Islands', dialCode: '677', iso2Code: 'sb' },
    { name: 'Somalia (Soomaaliya)', dialCode: '252', iso2Code: 'so' },
    { name: 'South Africa', dialCode: '27', iso2Code: 'za' },
    { name: 'South Korea (대한민국)', dialCode: '82', iso2Code: 'kr' },
    { name: 'South Sudan (‫جنوب السودان‬‎)', dialCode: '211', iso2Code: 'ss' },
    { name: 'Spain (España)', dialCode: '34', iso2Code: 'es' },
    { name: 'Sri Lanka (ශ්‍රී ලංකාව)', dialCode: '94', iso2Code: 'lk' },
    { name: 'Sudan (‫السودان‬‎)', dialCode: '249', iso2Code: 'sd' },
    { name: 'Suriname', dialCode: '597', iso2Code: 'sr' },
    {
        name: 'Svalbard and Jan Mayen',
        dialCode: '47',
        iso2Code: 'sj',
        order: 1,
        areaCodes: ['79'],
    },
    { name: 'Sweden (Sverige)', dialCode: '46', iso2Code: 'se' },
    { name: 'Switzerland (Schweiz)', dialCode: '41', iso2Code: 'ch' },
    { name: 'Syria (‫سوريا‬‎)', dialCode: '963', iso2Code: 'sy' },
    { name: 'Taiwan (台灣)', dialCode: '886', iso2Code: 'tw' },
    { name: 'Tajikistan', dialCode: '992', iso2Code: 'tj' },
    { name: 'Tanzania', dialCode: '255', iso2Code: 'tz' },
    { name: 'Thailand (ไทย)', dialCode: '66', iso2Code: 'th' },
    { name: 'Timor-Leste', dialCode: '670', iso2Code: 'tl' },
    { name: 'Togo', dialCode: '228', iso2Code: 'tg' },
    { name: 'Tokelau', dialCode: '690', iso2Code: 'tk' },
    { name: 'Tonga', dialCode: '676', iso2Code: 'to' },
    {
        name: 'Trinidad and Tobago',
        dialCode: '1',
        iso2Code: 'tt',
        order: 22,
        areaCodes: ['868'],
    },
    { name: 'Tunisia (‫تونس‬‎)', dialCode: '216', iso2Code: 'tn' },
    { name: 'Turkey (Türkiye)', dialCode: '90', iso2Code: 'tr' },
    { name: 'Turkmenistan', dialCode: '993', iso2Code: 'tm' },
    {
        name: 'Turks and Caicos Islands',
        dialCode: '1',
        iso2Code: 'tc',
        order: 23,
        areaCodes: ['649'],
    },
    { name: 'Tuvalu', dialCode: '688', iso2Code: 'tv' },
    {
        name: 'U.S. Virgin Islands',
        dialCode: '1',
        iso2Code: 'vi',
        order: 24,
        areaCodes: ['340'],
    },
    { name: 'Uganda', dialCode: '256', iso2Code: 'ug' },
    { name: 'Ukraine (Україна)', dialCode: '380', iso2Code: 'ua' },
    {
        name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
        dialCode: '971',
        iso2Code: 'ae',
    },
    { name: 'United Kingdom', dialCode: '44', iso2Code: 'gb', order: 0 },
    { name: 'United States', dialCode: '1', iso2Code: 'us', order: 0 },
    { name: 'Uruguay', dialCode: '598', iso2Code: 'uy' },
    { name: 'Uzbekistan (Oʻzbekiston)', dialCode: '998', iso2Code: 'uz' },
    { name: 'Vanuatu', dialCode: '678', iso2Code: 'vu' },
    {
        name: 'Vatican City (Città del Vaticano)',
        dialCode: '39',
        iso2Code: 'va',
        order: 1,
        areaCodes: ['06698'],
    },
    { name: 'Venezuela', dialCode: '58', iso2Code: 've' },
    { name: 'Vietnam (Việt Nam)', dialCode: '84', iso2Code: 'vn' },
    {
        name: 'Wallis and Futuna (Wallis-et-Futuna)',
        dialCode: '681',
        iso2Code: 'wf',
    },
    {
        name: 'Western Sahara (‫الصحراء الغربية‬‎)',
        dialCode: '212',
        iso2Code: 'eh',
        order: 1,
        areaCodes: ['5288', '5289'],
    },
    { name: 'Yemen (‫اليمن‬‎)', dialCode: '967', iso2Code: 'ye' },
    { name: 'Zambia', dialCode: '260', iso2Code: 'zm' },
    { name: 'Zimbabwe', dialCode: '263', iso2Code: 'zw' },
    {
        name: 'Åland Islands',
        dialCode: '358',
        iso2Code: 'ax',
        order: 1,
        areaCodes: ['18'],
    },
];

const numberFormat = 'INTERNATIONAL';

export interface PhoneWithCountryCode {
    value: string;
    code: string;
}

export interface ISOCountry {
    name: string;
    dialCode: string;
    iso2Code: string; // country code: ex. US
    order?: number; // if order>1 country with same dial code
    areaCodes?: string[];
}

export function mapperForSelectorWithImage(
    country: ISOCountry,
): SelectorImageItem {
    return {
        name: country.name,
        value: `(+${country.dialCode})`,
        code: country.iso2Code,
    };
}

export function mapCountries(
    mapper: (value: ISOCountry) => SelectorImageItem,
): SelectorImageItem[] {
    return countries.map(mapper);
}

export function getCountryCodeFromPhoneNumber(phoneNumber?: string) {
    let countryCode = defaultCountryCode;
    if (phoneNumber) {
        const phoneNumbers = findNumbers(phoneNumber);
        if (phoneNumbers?.length) {
            countryCode = phoneNumbers[0].country;
        }
    }

    return countryCode.toLowerCase();
}

// return formatted string of the phone number in international format
// ex. for US flag selected:
// inserted: 301-506-0895 -> +1 301-506-0895
// inserted: +1 301-506-0895 -> +1 301-506-0895
export function getPhoneNumberWithDialCodeIncluded(
    phoneNumber?: PhoneWithCountryCode,
) {
    return phoneNumber
        ? format(
              phoneNumber.value,
              phoneNumber.code.toUpperCase() as CountryCode,
              numberFormat,
          )
        : undefined;
}
