import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import type { UserType } from '../../shared/models/user.model';
import type { SpFormControl } from '../../shared/forms/custom-controls/sp-form-controls.model';
import {
    SpEmailInput,
    SpValidatedPasswordInput,
} from '../../shared/forms/custom-controls/sp-form-controls.model';
import { UserService } from '../../core/user.service';

export enum ResetPasswordState {
    requestLink = 'requestLink',
    linkRequested = 'linkRequested',
    editPassword = 'editPassword',
}

@Component({
    selector: 'sp-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
    @Output() passwordChange = new EventEmitter<any>();

    // making enums available in template as enum
    ResetPasswordState = ResetPasswordState;
    fromEmail = 'info@squarepeghires.com';

    state: ResetPasswordState;
    isPending: boolean;
    error;

    userType: UserType;
    token: string;

    form: FormGroup;
    control: SpFormControl;

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.form = new FormGroup({});
        this.userType = this.route.snapshot.params.userType;
    }

    ngOnInit() {
        this.token = this.route.snapshot.queryParams.token;
        this.state = this.route.snapshot.data.state;

        if (this.state === ResetPasswordState.requestLink) {
            this.state = ResetPasswordState.requestLink;

            this.control = new SpEmailInput({
                form: this.form,
                name: 'email',
                label: 'Email Address',
                placeholder: 'enter email address',
                icon: 'email',
                errorMessages: {
                    email: 'Invalid Email Address',
                },
            });
        } else if (this.state === ResetPasswordState.editPassword) {
            if (!this.token) {
                this.router.navigate(['/password/reset']);

                return;
            }

            this.state = ResetPasswordState.editPassword;

            this.control = new SpValidatedPasswordInput({
                form: this.form,
                name: 'password',
                label: 'New Password',
                icon: 'lock',
            });
        }
    }

    submit() {
        this.isPending = true;
        this.error = null;
        this.state === ResetPasswordState.requestLink
            ? this.requestPasswordResetLink()
            : this.resetPassword();
    }

    private requestPasswordResetLink() {
        return this.userService
            .requestPasswordReset(this.control.value)
            .pipe(finalize(() => (this.isPending = false)))
            .subscribe(
                () => {
                    this.state = ResetPasswordState.linkRequested;
                },
                () => (this.error = { genericError: true }),
            );
    }

    private resetPassword() {
        return this.userService
            .resetPassword(this.control.value, this.token)
            .pipe(finalize(() => (this.isPending = false)))
            .subscribe(
                (user) => this.passwordChange.emit(),
                (error) => (this.error = { resetTokenValidationError: true }),
            );
    }
}
