import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { ResetPasswordState } from './password-reset/password-reset.component';
import { AuthComponent, AuthScreenMode } from './auth.component';
import { EasySignupComponent } from './easy-signup/easy-signup.component';
import { EasySignupGuard } from './easy-signup/guards/easy-signup.guard';
import { InviteToApplyGuard } from './invite-to-apply.guard';
import { AuthRouteReuseStrategy } from './auth-route-reuse-strategy';

const routes: Routes = [
    {
        path: 'login/:userType',
        component: AuthComponent,
        canActivate: [AuthGuard],
    },
    { path: 'login', redirectTo: 'login/employer', pathMatch: 'full' },
    { path: 'signin', redirectTo: 'login/employer', pathMatch: 'full' },
    { path: 'sign-in', redirectTo: 'login/employer', pathMatch: 'full' },
    {
        path: 'login/candidate/success',
        pathMatch: 'full',
        component: AuthComponent,
        canActivate: [AuthGuard],
        data: { showSuccessMessage: true },
    },
    {
        path: 'signup/:userType',
        pathMatch: 'full',
        component: AuthComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'signup/verify/:emailVerificationToken',
        redirectTo: 'signup/candidate/verify/:emailVerificationToken',
    },
    {
        path: 'signup/:userType/verify/:emailVerificationToken',
        component: AuthComponent,
        canActivate: [AuthGuard],
    },
    { path: 'signup', redirectTo: 'signup/candidate', pathMatch: 'full' },
    {
        path: 'signup/employer/join',
        component: AuthComponent,
        data: {
            mode: AuthScreenMode.joinEmployer,
        },
    },
    { path: 'simple-signup/employer', redirectTo: 'signup/employer' },
    {
        path: 'password/:userType/reset',
        component: AuthComponent,
        canActivate: [AuthGuard],
        data: {
            mode: AuthScreenMode.passwordReset,
            state: ResetPasswordState.requestLink,
        },
    },
    {
        path: 'password/:userType/edit',
        component: AuthComponent,
        canActivate: [AuthGuard],
        data: {
            mode: AuthScreenMode.passwordReset,
            state: ResetPasswordState.editPassword,
        },
    },
    {
        path: 'apply/:jobHashId/resume',
        canActivate: [EasySignupGuard],
        component: EasySignupComponent,
    },
    {
        path: 'apply/candidate/:matchId/:inviteToApplyResponse',
        canActivate: [InviteToApplyGuard],
        component: AuthComponent,
        data: {
            mode: AuthScreenMode.spInviteToApplyResponse,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        AuthGuard,
        EasySignupGuard,
        InviteToApplyGuard,
        { provide: RouteReuseStrategy, useClass: AuthRouteReuseStrategy },
    ],
})
export class AuthRoutingModule {}

export const routedComponents = [AuthComponent];
