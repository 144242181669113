<img
    alt=""
    class="arrow-left"
    src="/images/icons/arrow_left.svg"
    [routerLink]="'/signup/employer'"
/>

<div class="title">We found your team!</div>
<div class="description" [innerHTML]="description"></div>

<div class="company">
    <sp-avatar
        class="avatar"
        [className]="'size-32'"
        [avatar]="prefillData.companyLogoUrl"
        [userName]="prefillData.companyName"
    >
    </sp-avatar>
    <div class="company-info">
        <div>{{ prefillData.companyName }}</div>
        <div class="members">{{ prefillData.numberOfMembers }} members</div>
    </div>
    <sp-stateful-button
        class="join"
        [label]="buttonLabel"
        [isLoading]="isSubmitting"
        (buttonClick)="submitRequestToJoin()"
    >
    </sp-stateful-button>
</div>

<div class="instructions">
    <div>Not seeing your workspace?</div>
    <a [routerLink]="'/signup/employer'">Create an account</a>
</div>

<div *ngIf="error" class="error">{{ error }}</div>
