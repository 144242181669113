import { Injectable } from '@angular/core';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Subscription } from 'rxjs';
import type { User } from '../shared/models';
import { UserType } from '../shared/models/user.model';
import type { SegmentEventModel } from '../shared/models/analytics/segment/segment-event.model';
import { SegmentUserTraits } from '../shared/models/analytics/segment/segment-user-traits.model';
import { SegmentEventProperties } from '../shared/models/analytics/segment/segment-event-properties.model';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    readonly subscription: Subscription = new Subscription();
    constructor(
        private userService: UserService,
        private angulartics2Segment: Angulartics2Segment,
    ) {
        this.watchUserUpdated();
    }

    trackEvent(eventModel: SegmentEventModel): void {
        this.angulartics2Segment.eventTrack(
            eventModel.eventType,
            SegmentEventProperties.mapDataSourcesToEventProperties(
                eventModel.eventDataSources,
            ),
        );
    }

    private watchUserUpdated() {
        this.subscription.add(
            this.userService.user$.subscribe((user) => {
                if (user) {
                    this.identifyUser(user);
                } else {
                    this.unsetSegmentUserTraitsFromLocalStorage();
                }
            }),
        );
    }
    private identifyUser(user: User): void {
        const userProperties =
            UserType.candidate === user.type
                ? SegmentUserTraits.forIdentifyCandidate(user)
                : SegmentUserTraits.forIdentifyEmployer(user);

        this.angulartics2Segment.setUserProperties(userProperties);
    }

    private unsetSegmentUserTraitsFromLocalStorage(): void {
        this.angulartics2Segment.unsetUserProperties();
    }
}
