import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type {
    ActivatedRouteSnapshot,
    CanActivate,
    NavigationExtras,
    RouterStateSnapshot,
} from '@angular/router';
import { UserService } from '../user.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        const canActivate = this.userService.isAuthenticated();

        if (!canActivate) {
            const extras: NavigationExtras =
                state.url.trim() !== '/'
                    ? { queryParams: { redirectTo: state.url } }
                    : {};
            // guarding against accessing the protected pages by the unauthenticated user
            void this.router.navigate([`/login/employer`], extras);
        }

        return canActivate;
    }
}
