import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'sp-base-header',
    templateUrl: './base-header.component.html',
    styleUrls: ['./base-header.component.scss'],
})
export class BaseHeaderComponent {
    @Output() closeClick = new EventEmitter<void>();

    onClose() {
        this.closeClick.emit();
    }
}
