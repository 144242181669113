<form [formGroup]="form" class="signup__form" novalidate>
    <ng-container>
        <sp-form-input
          [control]="controls['email']"
          class="signup-form__{{ controls['email'].name }}"
        ></sp-form-input>
        <sp-form-input
            [control]="controls['firstName']"
            class="signup-form__{{ controls['firstName'].name }}"
        ></sp-form-input>
        <sp-form-input
            [control]="controls['lastName']"
            class="signup-form__{{ controls['lastName'].name }}"
        ></sp-form-input>
        <ng-container *ngIf="showPasswordField">
          <sp-form-input
            [control]="controls['password']"
            class="signup-form__{{ controls['password'].name }}"
          >
          </sp-form-input>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="userType === UserType.employer">
        <label class="form-error-message message-top" *ngIf="error && isInvitationError">
            <span *ngIf="error === SignupErrorType.invitationExpiredError">
                The registration link you followed has expired. Please ask the account owner to send
                you a new one.
            </span>

            <span *ngIf="error === SignupErrorType.invitationUsedError">
                The registration link you followed has already been used. Please login with your
                credentials.
            </span>

            <span *ngIf="error === SignupErrorType.invitationNotFoundError">
                The provided link is invalid.
            </span>
        </label>
    </ng-container>

    <label class="form-error-message" *ngIf="error && !isInvitationError">
        <span *ngIf="error === SignupErrorType.genericError">
            There was an error while trying to sign you up.<br />
            Please try again later.
        </span>

        <span *ngIf="error === SignupErrorType.forbiddenEmailDomainError">
            If you are an employer, please register with your work email. If you are a candidate,
            please
            <a href="javascript:" [routerLink]="['/signup', 'candidate']" class="error-message-link"
                >sign up here</a
            >.
        </span>

        <span *ngIf="error === SignupErrorType.incorrectVerificationLink">
            The verification link you’ve accessed is either invalid or expired. Please enter your
            email address to request a new one.
        </span>

        <span *ngIf="error === SignupErrorType.emailAlreadyUsedError">
            There is already an account associated with this email address.
            <a href="javascript:" (click)="redirectToSignIn()" class="error-message-link"
                >Click here</a
            >
            to sign in.
        </span>
    </label>

    <sp-stateful-button
        class = "submit-button"
        [disabledFromOutside]="form.invalid || form.pristine || isSubmitting"
        [label]="'Next'"
        [isLoading]="isSubmitting"
        (buttonClick)="submitForm()">
    </sp-stateful-button>
</form>
