import { Component, Input, ViewChild } from '@angular/core';
import * as QuillNamespace from 'quill';
import Quill from 'quill';
import { QuillEditorComponent } from 'ngx-quill';
import { SpWysiwygInput } from '../sp-form-controls.model';
import {
    keyCodeBackspace,
    keyCodeCtrl,
    keyCodeEnter,
} from '../../../../core/config.service';
import { customizeLinkBlot } from '../../../quill-utils';
import { isDefined } from '../../../utils';
import { linkButtonTooltip } from '../../../constants';

const QuillEditorNamespace: any = QuillNamespace;
const icons = QuillEditorNamespace.import('ui/icons');
const Link = Quill.import('formats/link');

icons['bold'] =
    '<img src="/images/icons/forms/editor/bold.svg" class="wys-icon">';
icons['italic'] =
    '<img src="/images/icons/forms/editor/italic.svg" class="wys-icon">';
icons['underline'] =
    '<img src="/images/icons/forms/editor/underline.svg" class="wys-icon">';
icons['list']['bullet'] =
    '<img src="/images/icons/forms/editor/ulist.svg" class="wys-icon">';
icons['list']['ordered'] =
    '<img src="/images/icons/forms/editor/olist.svg" class="wys-icon">';
icons['link'] =
    '<img src="/images/icons/forms/editor/link.svg" class="wys-icon">';
icons['header'] =
    '<img src="/images/icons/forms/editor/header.svg" class="wys-icon">';

@Component({
    selector: 'sp-wysiwyg-editor',
    templateUrl: './sp-wysiwyg-editor.component.html',
    styleUrls: ['./sp-wysiwyg-editor.component.scss'],
})
export class SpWysiwygEditorComponent {
    isFocused = false;
    readonly supportedFormats = [
        'bold',
        'italic',
        'underline',
        'list',
        'link',
        'header',
    ];

    @Input() public control: SpWysiwygInput;

    @ViewChild(QuillEditorComponent) editorComponent: QuillEditorComponent;

    private editor: Quill;

    get computedCssClasses() {
        const control = this.control;
        const css = {
            focused: this.isFocused,
            'validation-error':
                (control.formControl.dirty || control.formControl.touched) &&
                control.formControl.invalid,
        };

        if (control.height) {
            // note: 'sp-wysiwyg-h-' prefix is mandatory - a set of sp-wysiwyg-h-DDD classes are generated in global
            // styles file - that's the only way to affect the placeholder height of the quill-wysiwyg editor component
            css['sp-wysiwyg-h-' + control.height] = true;
        }

        if (control.lineHeight) {
            css[`with-line-height-${control.lineHeight}`] = true;
        }

        return css;
    }

    onContentChanged(event: any) {
        this.calculateTextAreaHeight();
        this.control.onContentChanged(event);

        if (this.control.allowOnlyListItems) {
            const pastedContent = !isDefined(this.editor.getSelection());
            if (pastedContent || this.editor.getSelection().index > 0) {
                this.editor.formatLine(
                    0,
                    this.control.value.length,
                    'list',
                    'unordered',
                );
            }
        }
    }

    onFocus() {
        this.isFocused = true;
    }

    onBlur() {
        this.isFocused = false;
    }

    onEditorCreated(editor: Quill) {
        this.editor = editor;

        const singleOrMultipleLineBreaksRegexp = /^\n+$/g;
        const multipleLineBreaksRegexp = /^\n{2,}$/g;
        const toolbarModule = this.editor.getModule('toolbar');

        if (toolbarModule) {
            const editorToolbar = this.editor.getModule('toolbar').container;

            Quill.register(customizeLinkBlot(Link));
            editorToolbar.querySelector('button.ql-link').dataset.tooltip =
                linkButtonTooltip;

            // prevent clicks/touches on toolbar trigger 'focusout' event for editor
            editorToolbar.addEventListener('mousedown', (e) => {
                e.preventDefault();
            });

            // Override link handler so we can set empty value when opening popup
            toolbarModule.addHandler('link', function newLink(value: boolean) {
                if (value) {
                    const tooltip = this.quill.theme.tooltip;
                    tooltip.root.querySelector(
                        'input[data-link]',
                    ).dataset.link = '';
                    tooltip.edit('link', '');
                } else {
                    this.quill.format('link', false);
                }
            });
        }

        // add a matcher that will remove an empty line from content being pasted
        // if this is not the first empty in the sequence of empty lines
        let prevEmptyLine = false;
        this.editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            const ops = [];
            for (const op of delta.ops) {
                singleOrMultipleLineBreaksRegexp.lastIndex = 0;
                if (
                    op.insert &&
                    typeof op.insert === 'string' &&
                    singleOrMultipleLineBreaksRegexp.test(op.insert)
                ) {
                    if (prevEmptyLine) {
                        continue;
                    }

                    multipleLineBreaksRegexp.lastIndex = 0;
                    if (multipleLineBreaksRegexp.test(op.insert)) {
                        // if there are multiple new line characters in a single line (e.g. when pasting google docs content)
                        // we will replace those with a single one
                        op.insert = '\n';
                    }

                    prevEmptyLine = true;
                } else {
                    prevEmptyLine = false;
                }

                if (this.control.allowOnlyListItems) {
                    // if editor allows only list items, be sure we convert to list items all pasted data
                    op.attributes = { list: 'unordered' };
                }

                ops.push(op);
            }
            delta.ops = ops;

            return delta;
        });

        this.calculateTextAreaHeight();
    }

    onKeyDown(event: KeyboardEvent) {
        if (this.control.allowOnlyPaste) {
            if (event.keyCode !== keyCodeCtrl && event.keyCode !== 86) {
                event.preventDefault();

                return false;
            }
        }
        // to avoid "jumping" view to the top when pressing enter or backspace,
        // we set selection to the very end
        if (
            event.keyCode === keyCodeEnter ||
            event.keyCode === keyCodeBackspace
        ) {
            this.editor.setSelection(this.editor.getSelection(true));
            this.formatListOnlyOnKeyDown(event);
        }
    }

    private calculateTextAreaHeight() {
        const element = this.editorComponent.editorElem;
        let height = this.control.height;
        if (this.control.maxHeight) {
            const minHeight = Math.max(height, element.offsetHeight);
            height = Math.min(this.control.maxHeight, minHeight);
        }
        element.style.height = `${height}px`;
        element.style.margin = 'auto 0px';
        this.editorComponent.editorElem.style.maxHeight = `${this.control.maxHeight}px`;
    }

    private formatListOnlyOnKeyDown(event: KeyboardEvent) {
        if (!this.control.allowOnlyListItems) {
            return;
        }

        const range = this.editor.getSelection(); // Get the current cursor position

        if (
            event.keyCode === keyCodeBackspace &&
            this.editor.getLine(range.index)[1] === 0
        ) {
            // If backspace on empty list item, we need to remove whole line to prevent removing list item
            // and allowing insert non list items
            this.editor.deleteText(range.index, 1);

            return;
        }
    }
}
