import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import type { Institution } from '../shared/models';

@Injectable()
export class InstitutionService {
    constructor(private http: HttpClient) {}

    searchSchools(keyword): Observable<Institution[]> {
        return keyword && keyword.trim().length
            ? this.http.get<Institution[][]>(
                  `/api/organizations/schools?name=${keyword}`,
              )
            : of([]);
    }

    searchAuthorities(keyword): Observable<Institution[]> {
        return keyword && keyword.trim().length
            ? this.http.get<Institution[]>(
                  `/api/organizations/authorities?name=${keyword}`,
              )
            : of([]);
    }
}
