import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Observable, Subscription } from 'rxjs';
import { concat, of } from 'rxjs';
import { unsubscribeIfActive } from 'app/shared/utils';
import { catchError, tap } from 'rxjs/operators';
import { getInputFieldIconCssClassForContext } from '../sp-form-control-utils';
import { SpSelectInput } from '../sp-form-controls.model';

@Component({
    selector: 'sp-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnDestroy {
    @Input()
    control: SpSelectInput;

    model: any;

    asyncItems$: Observable<any[]>;
    loadingAsyncItemsInProgress = false;

    private isOpen: boolean;
    private _value: { [key: string]: string };
    private subscription: Subscription;

    get isInvalid() {
        const formControl = this.control.formControl;

        return (
            (formControl.dirty || formControl.touched) && formControl.invalid
        );
    }

    get computedCssClasses() {
        const context = this.isInvalid ? 'danger' : '';
        let inputFieldIconCssClass = {};
        if (this.control.showIconOnlyOnEmpty) {
            if (!this._value) {
                inputFieldIconCssClass = getInputFieldIconCssClassForContext(
                    this.control.icon,
                    context,
                );
            }
        } else {
            inputFieldIconCssClass = getInputFieldIconCssClassForContext(
                this.control.icon,
                context,
            );
        }

        return {
            'dropdown-expanded': this.isOpen,
            ...inputFieldIconCssClass,
        };
    }

    ngOnInit() {
        if (this.control.formControl) {
            this.subscription = this.control.formControl.valueChanges.subscribe(
                (newValue) => {
                    if (newValue !== this._value) {
                        this._value = newValue;

                        if (
                            newValue &&
                            newValue[this.control.bindValue] !== undefined
                        ) {
                            this.model = newValue[this.control.bindValue];
                        } else {
                            this.model = null;
                        }
                    }
                },
            );
        }

        if (this.control._value) {
            this.model = this.control._value[this.control.bindValue];
        }

        if (this.control.asyncItems) {
            this.loadAsyncData();
        }
    }

    loadAsyncData() {
        this.loadingAsyncItemsInProgress = true;
        this.asyncItems$ = concat(
            of([]), // default items
            this.control.asyncItems.pipe(
                catchError(() => of([])), // empty list on error
                tap(() => (this.loadingAsyncItemsInProgress = false)),
            ),
        );
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    onChange(value) {
        this._value = value;
        this.control.formControl.setValue(value);
    }

    onOpen() {
        this.isOpen = true;
    }

    onClose() {
        this.isOpen = false;
    }
}
