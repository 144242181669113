<div class="content">
    <div class="wrapper-input">
        <input class="input-tag-field" [(ngModel)]="newTag" (ngModelChange)="filterChips()" />
        <span class="add-tag-icon" [class.can-add-border]="canAdd">
            <span [class.can-add-color]="canAdd">+</span>
            <span class="add-tag-text" (click)="createTag()" [class.show-add-text]="canAdd">
                Create tag
            </span>
        </span>
    </div>

    <div class="tags-box">
        <sp-form-input
            [control]="spTagChipsControl"
            (chipsRemoved)="removeFromSelected($event)"
            class="chips-content"
        >
        </sp-form-input>
        <div class="tags-box-bottom">
            <div class="chip-row" *ngFor="let chip of filteredChips" (click)="addToSelected(chip)">
                <span class="chip-tag">
                    <span class="chip-tag-text">{{ chip }}</span>
                    <img src="/images/icons/forms/plus.svg" class="chip-tag-icon" />
                </span>
            </div>
        </div>
    </div>
</div>
