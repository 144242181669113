import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize, map, take, tap } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { BehaviorSubject, EMPTY, Subject, Subscription, of } from 'rxjs';
import { EmployerMatchesApiService } from 'app/core/employer-matches-api.service';
import {
    arraysEqual,
    byNumericPropertyDescWithNullsLastComparator,
    decrementIfGreaterThanZero,
    isDefined,
    mergeCollectionsByField,
    removeFromCollection,
    unsubscribeIfActive,
} from '../../shared/utils';
import { computePageOffset } from '../../shared/page-utils';
import { DecisionType, MatchEmployer } from '../../shared/models';
import type {
    Employer,
    GenericIdValue,
    IdentifiableVersionedModel,
    Match,
    Page,
} from '../../shared/models';
import {
    RequiredActionEventType,
    RequiredActionsService,
    minDaysPendingReview,
} from '../../core/required-actions.service';
import { daysBetweenDates, toDate } from '../../shared/date-utils';
import { AnalyticsService } from '../../core/analytics.service';
import type { SegmentEventDataSources } from '../../shared/models/analytics/segment/segment-event.model';
import {
    EventLocation,
    SegmentEventActorType,
    SegmentEventModel,
    SegmentEventType,
    employerActionOnMatchToAnalyticsEventMap,
} from '../../shared/models/analytics/segment/segment-event.model';
import { JobsService } from '../../core/jobs.service';
import { UserService } from '../../core/user.service';
import type { EmployerConnectedMatchStatus } from '../../shared/models/enumeration/employer-connected-match-status.model';
import { byEmployerMatchConnectedStatusComparator } from '../../shared/models/enumeration/employer-connected-match-status.model';
import type { LaneCardEntity } from '../../shared/lanes-container/model';
import type { TotalAndViewedMatchesCount } from './model/total-and-viewed-matches-count';
import type { MatchFiltersCriteria } from './match-filters-form/match-filter.model';
import type { MatchSelectedState } from './model/match-selected-state.model';
import { EmployerDashboardMatchType } from './model/employer-dashboard-match-type';

const defaultPageSize = 50;
const baseMatchesUrl = `/api/matches`;
const defaultOrder = 'overrideMatchScore,nlpMatchScore,desc';

@Injectable()
export class EmployerMatchesService implements OnDestroy {
    private activeTab: EmployerDashboardMatchType;

    tabLoading$ = new BehaviorSubject<EmployerDashboardMatchType | null>(null);
    orderBy$ = new Subject<string>();

    distinctTags$ = new BehaviorSubject<string[]>([]);
    private distinctTags: string[] = [];

    activeTags$ = new Subject<string[]>();
    private selectedJobTags: string[] = [];

    favoriteFilter$ = new Subject<boolean>();
    private _filterByFavorite = false;

    private _includeAllCandidates = false;

    activeFilters$ = new Subject<MatchFiltersCriteria>();
    private _selectedMatchFilters: MatchFiltersCriteria;

    // total and new Matches count
    matchesCount$ = new Subject<TotalAndViewedMatchesCount>();

    executeMatchesBulkAction$ = new Subject<DecisionType>();
    selectAllMatches$ = new Subject<MatchSelectedState[]>();
    setSelectAllCheckboxState$ = new Subject<boolean>();

    matchEmployerFavorite$ = new Subject<GenericIdValue<boolean>>();

    readonly orderByOptions = [
        {
            key: defaultOrder,
            label: 'Match Score',
        },
        {
            // newestForSort is @Formula prop using coalesce with approvalDate and createdDate as fallback declared in match
            key: 'newestForSort,desc',
            label: 'Newest',
        },
    ];

    readonly tabOrder = {
        [EmployerDashboardMatchType.invited]: defaultOrder,
        [EmployerDashboardMatchType.applications]: defaultOrder,
        [EmployerDashboardMatchType.inProgress]: defaultOrder,
        [EmployerDashboardMatchType.archived]: defaultOrder,
        [EmployerDashboardMatchType.allApproved]: defaultOrder,
        [EmployerDashboardMatchType.invite]: defaultOrder,
    };

    readonly subscription: Subscription;

    invitedMatches: MatchEmployer[];
    reviewMatches: MatchEmployer[];
    inProgressMatches: MatchEmployer[];
    archivedMatches: MatchEmployer[];
    matchesToInvite: MatchEmployer[];

    selectedMatchesIds: number[] = [];
    employer: Employer;

    counters: TotalAndViewedMatchesCount;
    mappedCounters: Map<string, number> = new Map<string, number>();

    get filteringByFavorite(): boolean {
        return this._filterByFavorite;
    }

    constructor(
        private http: HttpClient,
        private employerMatchesService: EmployerMatchesApiService,
        private requiredActionsService: RequiredActionsService,
        private jobsService: JobsService,
        private userService: UserService,
        private analyticsService: AnalyticsService,
    ) {
        this.employer = this.userService.user.employer;
        this.subscription = new Subscription();
        this.watchSelectedJobIdChange();
    }

    ngOnDestroy() {
        unsubscribeIfActive(this.subscription);
    }

    setActiveTab(tab: EmployerDashboardMatchType) {
        this.counters = {};
        this.activeTab = tab;
    }

    fetchAndSetMatchCounts(type, jobId): Subscription {
        return this.getCountsForAllAndViewedMatches(jobId, type)
            .pipe(take(1))
            .subscribe((counts) => {
                this.counters = counts;
                this.calculateMappedCounters();
                this.matchesCount$.next(this.counters);
            });
    }

    setLoadingTab(tab: EmployerDashboardMatchType | null) {
        this.tabLoading$.next(tab);
    }

    setTabOrderBy(orderByOption: string) {
        this.tabOrder[this.activeTab] = orderByOption;
        this.orderBy$.next(orderByOption);
    }

    getTabOrderBy(tabType: EmployerDashboardMatchType) {
        return this.tabOrder[tabType];
    }

    favoriteMatch(
        match: MatchEmployer | LaneCardEntity,
        eventLocation: EventLocation,
    ): Observable<MatchEmployer> {
        return this.employerMatchesService.favoriteMatch(match).pipe(
            finalize(() => {
                // If favorite action occurs on in-progress tab, we get a LaneCardEntity type that does not have all the match
                // data required for event tracking, so we need to retrieve a match this card represents.
                if (EventLocation.jobInProgressTab === eventLocation) {
                    match = this.inProgressMatches?.find(
                        (m) => m.id === match.id,
                    );
                }

                this.trackAnalyticsEvent(
                    SegmentEventType.employer_favorite_candidate,
                    match as MatchEmployer,
                    eventLocation,
                );
            }),
            tap((updatedMatch) => {
                match.version = updatedMatch.version;
                this.matchEmployerFavorite$.next({
                    id: updatedMatch.id,
                    value: updatedMatch.employerFavorite,
                });
            }),
        );
    }

    updateStatusOfInProgressMatches(
        jobId: number,
        requestBody: any,
    ): Observable<Match[]> {
        return this.employerMatchesService.updateStatusOfInProgressMatches(
            jobId,
            requestBody,
        );
    }

    saveConnectedRelinkedMatches(
        jobId: number,
        request: any,
    ): Observable<IdentifiableVersionedModel[]> {
        return this.employerMatchesService.saveConnectedRelinkedMatches(
            jobId,
            request,
        );
    }

    updateMatchConnectedStatus(
        jobId: number,
        match: MatchEmployer,
        prevStatus: EmployerConnectedMatchStatus,
        nextStatus: EmployerConnectedMatchStatus,
        eventLocation: EventLocation,
    ): Observable<IdentifiableVersionedModel[]> {
        return this.employerMatchesService.updateMatchConnectedStatus(
            jobId,
            match,
            prevStatus,
            nextStatus,
            eventLocation,
        );
    }

    set activeTags(newTags: string[] | undefined) {
        if (!newTags || arraysEqual(this.activeTags, newTags)) {
            return;
        }
        this.selectedJobTags = [...newTags];
        this.activeTags$.next(newTags);
    }

    get activeTags() {
        return this.selectedJobTags;
    }

    set selectedMatchIds(matchIds: number[]) {
        this.selectedMatchesIds = matchIds;
    }

    get selectedMatchIds() {
        return this.selectedMatchesIds;
    }

    set matchFilters(filtersCriteria: MatchFiltersCriteria) {
        this._selectedMatchFilters = filtersCriteria;
    }

    filterMatches(filtersCriteria: MatchFiltersCriteria) {
        this.matchFilters = filtersCriteria;
        this.activeFilters$.next(filtersCriteria);
    }

    get matchFilters() {
        return this._selectedMatchFilters;
    }

    get includeAllCandidates() {
        return this._includeAllCandidates;
    }

    set includeAllCandidates(includeAllCandidates: boolean) {
        this._includeAllCandidates = includeAllCandidates;
        this.activeFilters$.next();
    }

    public clearMatchesSelection() {
        if (this.selectedMatchesIds.length > 0) {
            this.selectedMatchesIds = [];
            this.setSelectAllCheckboxState$.next(false);
            this.selectAllMatches(this.activeTab, false);
        }
    }

    public set distinctJobTags(newDistinctJobTags: string[] | undefined) {
        this.distinctTags = newDistinctJobTags?.length
            ? newDistinctJobTags.sort((a, b) => (a < b ? -1 : a === b ? 0 : 1))
            : [];
        this.distinctTags$.next(this.distinctTags);
    }

    public get distinctJobTags(): string[] {
        return this.distinctTags;
    }

    getMatches(
        tabType: EmployerDashboardMatchType,
        jobId: number,
        includeCandidateIndustries = false,
    ): Observable<MatchEmployer[]> {
        let getMatchesObservable: Observable<MatchEmployer[]>;
        switch (tabType) {
            case EmployerDashboardMatchType.invited:
                getMatchesObservable = this.getInvitedMatches(jobId);

                break;
            case EmployerDashboardMatchType.applications:
                getMatchesObservable = this.getReviewMatches(jobId);

                break;
            case EmployerDashboardMatchType.inProgress:
                getMatchesObservable = this.getInProgressMatches(jobId);

                break;
            case EmployerDashboardMatchType.archived:
                getMatchesObservable = this.getArchivedMatches(jobId);

                break;
            case EmployerDashboardMatchType.invite:
                getMatchesObservable = this.getMatchesToInvite(jobId);

                break;
            case EmployerDashboardMatchType.allApproved:
                getMatchesObservable = this.getAllMatchesApproved(
                    jobId,
                    includeCandidateIndustries,
                );

                break;
            default:
                throw new Error(
                    'Skip Employer Matches retrieval - unknown tab type',
                );
        }

        return getMatchesObservable;
    }

    selectAllMatches(
        activeTab: EmployerDashboardMatchType,
        isSelected: boolean,
    ) {
        const payload = this.getMatchesCollectionByType(activeTab).map(
            (match) => ({
                id: match.id,
                isSelected,
            }),
        );
        this.selectAllMatches$.next(payload);
    }

    setSelectAllCheckboxState(tabType: EmployerDashboardMatchType) {
        const matches = this.getMatchesCollectionByType(tabType);
        if (matches) {
            this.setSelectAllCheckboxState$.next(
                matches.length > 0 &&
                    matches.length === this.selectedMatchIds.length,
            );
        }
    }

    isFirstMatchListItem(type: EmployerDashboardMatchType, matchId: number) {
        const matches = this.getSortedMatchesCollectionByType(type);

        return matches && matches.length > 0 && matches[0].id === matchId;
    }

    getPrevMatchId(
        type: EmployerDashboardMatchType,
        jobId: number,
        beforeMatchId?: number,
    ): Observable<number> {
        const matches = this.getMatchesCollectionByType(type);
        if (!beforeMatchId) {
            // coming back from DONE page
            return of(matches?.length ? matches[matches.length - 1].id : null);
        }

        if (matches) {
            const targetIndex = matches.findIndex(
                (match) => match.id === beforeMatchId,
            );

            return of(
                matches[(targetIndex > 0 ? targetIndex : matches.length) - 1]
                    .id,
            );
        }

        // match details page reload
        return this.fetchNextMatchesChunk(type, jobId).pipe(
            map((matchesSlice) =>
                matchesSlice?.length
                    ? matchesSlice[matchesSlice.length - 1].id
                    : null,
            ),
        );
    }

    /**
     * Get the id of the match following the match with id afterMatchId.
     * Start from the beginning of the collection if afterMatchId is last one.
     * @param type
     * @param afterMatchId
     */
    getNextMatchId(
        type: EmployerDashboardMatchType,
        afterMatchId: number,
    ): number {
        const matches = this.getSortedMatchesCollectionByType(type);
        if (!matches || matches.length === 0) {
            return null;
        }

        const matchIndex = matches.findIndex(
            (match) => match.id === afterMatchId,
        );
        if (matchIndex === matches.length - 1) {
            // nextMatchId can't be the same id as afterMatchId
            return matches.length === 1 ? null : matches[0].id;
        } else if (matchIndex > -1) {
            return matches[matchIndex + 1].id;
        }

        return null;
    }

    getLastPageItemsIds(type: EmployerDashboardMatchType): Set<number> {
        const matches = this.getMatchesCollectionByType(type) || [];

        let ids;
        if (matches.length > defaultPageSize) {
            ids = [];
            for (
                let i = matches.length - defaultPageSize;
                i < matches.length;
                i++
            ) {
                ids.push(matches[i].id);
            }
        } else {
            ids = matches.map((match) => match.id);
        }

        return new Set<number>(ids);
    }

    /**
     * Fetches next matches chunk based on the page size configured. Returns Observable
     * of the matches ONLY of this slice
     * @param tabType
     * @param jobId
     */
    fetchNextMatchesChunk(
        tabType: EmployerDashboardMatchType,
        jobId: number,
    ): Observable<MatchEmployer[]> {
        let collection = this.getMatchesCollectionByType(tabType);
        if (!collection) {
            collection = this.setMatchesCollectionByType(tabType);
        }

        return this.loadMatchesNextChunk(tabType, jobId, collection);
    }

    setViewedByEmployerState(match: MatchEmployer): Observable<MatchEmployer> {
        return match && !match.isViewedByEmployer
            ? this.http
                  .patch<MatchEmployer>(
                      `${baseMatchesUrl}/${match.id}/mark-viewed`,
                      null,
                  )
                  .pipe(tap(() => this.markLocalMatchAsViewedByEmployer(match)))
            : EMPTY;
    }

    executeAction(
        matchesIds: number[],
        action: DecisionType,
        eventLocation: EventLocation,
    ): Observable<MatchEmployer[]> {
        return this.employerMatchesService
            .executeMatchAction(matchesIds, action)
            .pipe(
                tap((matches) => {
                    matches.forEach((m) => {
                        const match = this.removeLocalMatch(m.id, action);

                        if (match.isViewedByEmployer) {
                            // re-calculate viewedReviewMatchesCount only for matches being removed from Applications tab
                            // (i.e. non acted on matches)
                            this.counters.viewed = decrementIfGreaterThanZero(
                                this.counters.viewed,
                            );
                        }

                        this.trackAnalyticsEvent(
                            employerActionOnMatchToAnalyticsEventMap[action],
                            match,
                            eventLocation,
                        );
                        this.updateLocalCachedCounterValue(
                            this.activeTab,
                            action,
                        );
                        this.calculateMappedCounters();
                        this.invalidateRequiredActions(match, action);
                    });

                    this.matchesCount$.next(this.counters);
                }),
            );
    }

    private trackAnalyticsEvent(
        eventType: SegmentEventType,
        match: MatchEmployer,
        eventLocation: EventLocation,
    ): void {
        // In case if match is being passed from Messaging page it would have job data included and list of jobs
        // is not available to be able to retrieve job data from service.
        const job = match.job
            ? match.job
            : this.jobsService.getBaseJobInfo(match.jobId);
        if (isDefined(eventType)) {
            const eventDataSources: SegmentEventDataSources = {
                job,
                employer: this.userService.user.employer,
                match,
                eventLocation,
                eventActor: SegmentEventActorType.user,
            };

            this.analyticsService.trackEvent(
                new SegmentEventModel(eventType, eventDataSources),
            );
        }
    }

    updatePassReasons(
        matchId: number,
        passReasons: string[] = null,
    ): Observable<void> {
        return passReasons && passReasons.length > 0
            ? this.http.patch<void>(
                  `${baseMatchesUrl}/${matchId}/pass-reasons`,
                  passReasons,
              )
            : EMPTY;
    }

    updateDisqualifiedReasons(
        matches: MatchEmployer[],
        passReasons: string[] = null,
    ): Observable<void> {
        const requestBody = {
            matches: matches.map((match) => match.id),
            reasons: passReasons,
        };

        return passReasons && passReasons.length > 0
            ? this.http.patch<void>(
                  `${baseMatchesUrl}/pass-reasons`,
                  requestBody,
              )
            : EMPTY;
    }

    public getCurrentTotalCounterValue(includeOthers = false): number {
        if (!this.counters) {
            return 0;
        }

        switch (this.activeTab) {
            case EmployerDashboardMatchType.invited:
                return this.counters.totalInvitedCount || 0;

            case EmployerDashboardMatchType.applications:
                return (
                    (this.counters.totalApplicationsCount || 0) +
                    (includeOthers
                        ? this.counters.otherApplicationsCount || 0
                        : 0)
                );

            case EmployerDashboardMatchType.archived:
                return this.counters.totalArchivedCount || 0;

            case EmployerDashboardMatchType.inProgress:
                return this.counters.totalInProgressCount || 0;

            case EmployerDashboardMatchType.invite:
                return this.counters.totalInvitesCount || 0;
        }
    }

    updateLocalCachedCounterValue(
        currentTab: EmployerDashboardMatchType,
        executedAction: DecisionType,
    ) {
        switch (currentTab) {
            case EmployerDashboardMatchType.invited:
                this.counters.totalApplicationsCount =
                    decrementIfGreaterThanZero(this.counters.totalInvitedCount);

                break;
            case EmployerDashboardMatchType.applications:
                this.counters.totalApplicationsCount =
                    decrementIfGreaterThanZero(
                        this.counters.totalApplicationsCount,
                    );

                break;

            case EmployerDashboardMatchType.archived:
                this.counters.totalArchivedCount = decrementIfGreaterThanZero(
                    this.counters.totalArchivedCount,
                );

                break;

            case EmployerDashboardMatchType.inProgress:
                this.counters.totalInProgressCount = decrementIfGreaterThanZero(
                    this.counters.totalInProgressCount,
                );

                break;

            case EmployerDashboardMatchType.invite:
                this.counters.totalInvitesCount = decrementIfGreaterThanZero(
                    this.counters.totalInvitesCount,
                );

                break;
        }

        switch (executedAction) {
            case DecisionType.connect:
                if (this.activeTab === EmployerDashboardMatchType.invite) {
                    this.counters.totalInvitedCount += 1;
                } else {
                    this.counters.totalInProgressCount += 1;
                }

                break;
            case DecisionType.pass:
            case DecisionType.withdraw:
                this.counters.totalArchivedCount += 1;

                break;
            case DecisionType.undo:
                this.counters.totalApplicationsCount += 1;

                break;
        }
    }

    isMatchViewedByEmployer(matchId: number, type: EmployerDashboardMatchType) {
        const matches = this.getMatchesCollectionByType(type);
        const match = matches.find((m) => m.id === matchId);

        return match && match.isViewedByEmployer;
    }

    getMatch(matchId: number): Observable<MatchEmployer> {
        return this.employerMatchesService.getMatch(matchId);
    }

    pushFeedForComment(matchId): Observable<MatchEmployer> {
        return this.http.patch<MatchEmployer>(
            `/api/matches/${matchId}/feeds/comment`,
            {},
        );
    }

    getMatchesCollectionByType(tabType: EmployerDashboardMatchType) {
        switch (tabType) {
            case EmployerDashboardMatchType.invited:
                return this.invitedMatches;
            case EmployerDashboardMatchType.applications:
                return this.reviewMatches;
            case EmployerDashboardMatchType.inProgress:
                return this.inProgressMatches;
            case EmployerDashboardMatchType.archived:
                return this.archivedMatches;
            case EmployerDashboardMatchType.invite:
                return this.matchesToInvite;
        }
    }

    getSortedMatchesCollectionByType(tabType: EmployerDashboardMatchType) {
        let matches = this.getMatchesCollectionByType(tabType);
        if (tabType === EmployerDashboardMatchType.inProgress) {
            matches = matches.sort(byEmployerMatchConnectedStatusComparator);
        }

        return matches;
    }

    getMatchesByTypeAndIdIn(
        tabType: EmployerDashboardMatchType,
        ids: number[],
    ): MatchEmployer[] {
        const collection = this.getMatchesCollectionByType(tabType);

        return collection.filter((match) => ids.includes(match.id));
    }

    setMatchesCollectionByType(
        tabType: EmployerDashboardMatchType,
        value: MatchEmployer[] = [],
    ) {
        switch (tabType) {
            case EmployerDashboardMatchType.invited:
                this.invitedMatches = value;

                return this.invitedMatches;
            case EmployerDashboardMatchType.applications:
                this.reviewMatches = value;

                return this.reviewMatches;
            case EmployerDashboardMatchType.inProgress:
                this.inProgressMatches = value;

                return this.inProgressMatches;
            case EmployerDashboardMatchType.archived:
                this.archivedMatches = value;

                return this.archivedMatches;
            case EmployerDashboardMatchType.invite:
                this.matchesToInvite = value;

                return this.matchesToInvite;
        }
    }

    getCountsForAllAndViewedMatches(
        jobId,
        type,
    ): Observable<TotalAndViewedMatchesCount> {
        return this.http.get<TotalAndViewedMatchesCount>(
            `${baseMatchesUrl}/employer/counts`,
            { params: { type, jobId } },
        );
    }

    getAllResumesLink(jobId: number): Observable<string> {
        return this.http.get(`${baseMatchesUrl}/jobs/${jobId}/resumes`, {
            responseType: 'text',
        });
    }

    moveMatchToAnotherJob(
        jobId: number,
        matchId: number,
    ): Observable<MatchEmployer> {
        return this.http
            .post<MatchEmployer>(
                `/api/jobs/${jobId}/matches/${matchId}/move-to-another-job`,
                null,
            )
            .pipe(
                map((match) =>
                    match ? Object.assign(new MatchEmployer(), match) : null,
                ),
            );
    }

    private getInvitedMatches(jobId: number): Observable<MatchEmployer[]> {
        const params = this.getLoadMatchesRequestParams(
            EmployerDashboardMatchType.invited,
            0,
            jobId,
        );

        return this.fetchMatches(params).pipe(
            tap((matches) => (this.invitedMatches = matches)),
        );
    }

    private getReviewMatches(jobId: number): Observable<MatchEmployer[]> {
        const params = this.getLoadMatchesRequestParams(
            EmployerDashboardMatchType.applications,
            0,
            jobId,
        );

        return this.fetchMatches(params).pipe(
            tap((matches) => (this.reviewMatches = matches)),
        );
    }

    private getInProgressMatches(jobId: number): Observable<MatchEmployer[]> {
        const params = this.getLoadMatchesRequestParams(
            EmployerDashboardMatchType.inProgress,
            0,
            jobId,
            null,
        );

        return this.fetchMatches(Object.assign(params, { tags: [] })).pipe(
            tap((matches) => (this.inProgressMatches = matches)),
        );
    }

    private getArchivedMatches(jobId: number): Observable<MatchEmployer[]> {
        const params = this.getLoadMatchesRequestParams(
            EmployerDashboardMatchType.archived,
            0,
            jobId,
        );

        return this.fetchMatches(params).pipe(
            tap((matches) => (this.archivedMatches = matches)),
        );
    }

    private getMatchesToInvite(jobId: number): Observable<MatchEmployer[]> {
        const params = this.getLoadMatchesRequestParams(
            EmployerDashboardMatchType.invite,
            0,
            jobId,
        );

        return this.fetchMatches(params, this.matchFilters).pipe(
            tap((matches) => (this.matchesToInvite = matches)),
        );
    }

    private getAllMatchesApproved(
        jobId: number,
        includeCandidateIndustries: boolean,
    ): Observable<MatchEmployer[]> {
        // 2147483647 max Integer value for backend
        const params = this.getLoadMatchesRequestParams(
            EmployerDashboardMatchType.allApproved,
            0,
            jobId,
            2147483647,
            includeCandidateIndustries,
        );

        return this.fetchMatches(params);
    }

    private loadMatchesNextChunk(
        tabType: EmployerDashboardMatchType,
        jobId: number,
        targetCollection: MatchEmployer[],
    ): Observable<MatchEmployer[]> {
        const pageInfo = computePageOffset(
            targetCollection.length,
            defaultPageSize,
        );
        const params = this.getLoadMatchesRequestParams(
            tabType,
            pageInfo.nextPage,
            jobId,
        );

        return this.fetchMatches(params, this.matchFilters).pipe(
            tap((matches) =>
                this.processLoadMoreResponseData(
                    matches,
                    targetCollection,
                    pageInfo,
                ),
            ),
        );
    }

    private fetchMatches(params: any, body?: {}): Observable<MatchEmployer[]> {
        const additionalQueryParam = this.getAdditionalSortParam();

        return this.http
            .post<Page<MatchEmployer>>(
                `${baseMatchesUrl}/employer${additionalQueryParam}`,
                body,
                { params },
            )
            .pipe(
                map((page) => {
                    const matchesList = page.content.map((match) =>
                        MatchEmployer.of(match),
                    );

                    // This a temporary workaround for proper sorting due to how match scores are stored/prefilled on BE.
                    return this.getTabOrderBy(this.activeTab) ===
                        defaultOrder &&
                        this.activeTab !== EmployerDashboardMatchType.inProgress
                        ? matchesList.sort((a, b) =>
                              byNumericPropertyDescWithNullsLastComparator(
                                  a,
                                  b,
                                  'totalMatchScore',
                              ),
                          )
                        : matchesList;
                }),
            );
    }

    private processLoadMoreResponseData(
        matches: MatchEmployer[],
        dstCollection: MatchEmployer[],
        pageInfo: any,
    ) {
        if (pageInfo.nextPage === pageInfo.currentPage) {
            // we're re-loading current page since it is incomplete (e.g. contains 19 items of 20)
            // thus we have to merge result set into local collection
            mergeCollectionsByField(dstCollection, matches);
        } else {
            // completely new page was loaded
            dstCollection.splice(dstCollection.length, 0, ...matches);
        }

        return dstCollection;
    }

    private getLoadMatchesRequestParams(
        type: EmployerDashboardMatchType,
        page,
        jobId,
        size?: number,
        includeCandidateIndustries?: boolean,
    ) {
        const includeAll = this.shouldIncludeAllMatches(type);

        return {
            type,
            size: size === undefined ? defaultPageSize : size,
            page,
            jobId,
            sort: this.getTabOrderBy(type),
            tags:
                this.activeTags.length > 0
                    ? JSON.stringify(this.activeTags)
                    : '',
            favorite:
                type !== EmployerDashboardMatchType.inProgress
                    ? this._filterByFavorite
                    : false,
            candidateIndustries: includeCandidateIndustries || false,
            includeUnscreened: includeAll,
            includeDisqualified: includeAll,
        };
    }

    private removeLocalMatch(
        matchId: number,
        collectionDecisionType: DecisionType,
    ): MatchEmployer {
        let removedMatch;
        switch (collectionDecisionType) {
            case DecisionType.connect:
            case DecisionType.pass:
                removedMatch = removeFromCollection(
                    matchId,
                    this.reviewMatches,
                );

                break;
            case DecisionType.withdraw:
                removedMatch = removeFromCollection(
                    matchId,
                    this.inProgressMatches,
                );

                break;
            case DecisionType.undo:
                removedMatch = removeFromCollection(
                    matchId,
                    this.archivedMatches,
                );

                break;
            case DecisionType.invite:
            case DecisionType.remove:
                removedMatch = removeFromCollection(
                    matchId,
                    this.matchesToInvite,
                );

                break;
        }

        return removedMatch ? removedMatch.pop() : null;
    }

    private shouldIncludeAllMatches(
        dashboardType: EmployerDashboardMatchType,
    ): boolean {
        let includeAllMatches = false;
        switch (dashboardType) {
            case EmployerDashboardMatchType.applications:
            case EmployerDashboardMatchType.archived:
                includeAllMatches = this._includeAllCandidates;

                break;
            case EmployerDashboardMatchType.inProgress:
                includeAllMatches = true;

                break;
            case EmployerDashboardMatchType.invite:
                includeAllMatches = false;
        }

        return includeAllMatches;
    }

    // when update match tags, we return distinct job tags
    updateMatchTags(
        matchId: number,
        matchTags: string[] = [],
        eventModel: SegmentEventModel,
    ): Observable<string[]> {
        return this.employerMatchesService
            .updateMatchTags(matchId, matchTags)
            .pipe(tap(() => this.analyticsService.trackEvent(eventModel)));
    }

    applyFilterByFavorite(newFilterState, shouldFilter = true): boolean {
        if (this._filterByFavorite !== newFilterState) {
            this._filterByFavorite = newFilterState;
            if (shouldFilter) {
                this.favoriteFilter$.next(newFilterState);
            }

            return true;
        }

        return false;
    }

    private getAdditionalSortParam(): string {
        /*
      To be able to put unscreened candidates in the bottom of the page we need to sort by approvalStatus DESC.
      In order to sort by multiple columns in different direction (as we already have few columns with ASC direction)
      we need to add multiple sort queryParams.
     */
        const putUnscreenedToBottom =
            (this.activeTab === EmployerDashboardMatchType.applications ||
                this.activeTab === EmployerDashboardMatchType.archived) &&
            this.getTabOrderBy(this.activeTab) === defaultOrder;

        return putUnscreenedToBottom ? '?sort=approvalStatus,asc' : '';
    }

    calculateMappedCounters() {
        this.mappedCounters = new Map([
            [
                EmployerDashboardMatchType.invited,
                this.counters.totalInvitedCount || 0,
            ],
            [
                EmployerDashboardMatchType.applications,
                this.counters.totalApplicationsCount || 0,
            ],
            [
                EmployerDashboardMatchType.archived,
                this.counters.totalArchivedCount || 0,
            ],
            [
                EmployerDashboardMatchType.inProgress,
                this.counters.totalInProgressCount || 0,
            ],
            [
                EmployerDashboardMatchType.invite,
                this.counters.totalInvitesCount || 0,
            ],
        ]);
    }

    removeMatchFromSelectedList(matchId: number) {
        this.selectedMatchesIds = this.selectedMatchesIds.filter(
            (id) => id !== matchId,
        );
    }

    private invalidateRequiredActions(
        match: MatchEmployer,
        action: DecisionType,
    ) {
        if (action === DecisionType.connect || action === DecisionType.pass) {
            const daysSinceApproved = daysBetweenDates(
                match.approvalDate,
                toDate(new Date()),
            );
            if (daysSinceApproved > minDaysPendingReview) {
                this.requiredActionsService.invalidateRequiredActionsAsync(
                    match.id,
                    match.jobId,
                    RequiredActionEventType.connectPass,
                );
            }
        }
    }

    private markLocalMatchAsViewedByEmployer(match: MatchEmployer) {
        const localMatches = this.getMatchesCollectionByType(this.activeTab);
        const index = localMatches.findIndex((m) => m.id === match.id);
        if (index < 0) {
            return;
        }
        this.getMatchesCollectionByType(this.activeTab)[
            index
        ].isViewedByEmployer = true;
    }

    private watchSelectedJobIdChange() {
        this.subscription.add(
            this.jobsService.selectedJobId$.subscribe((jobId) => {
                this.distinctJobTags =
                    this.jobsService.getSelectedJobBaseInfo()?.matchesTags;
                if (
                    this.jobsService.getSelectedJobBaseInfo()?.allowDirectApply
                ) {
                    this.includeAllCandidates = true;
                }
            }),
        );
    }
}
