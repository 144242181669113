import type { OnDestroy, OnInit } from '@angular/core';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Optional,
    Output,
    ViewChild,
} from '@angular/core';
import * as uuid from 'uuid';
import type { TagModelClass } from 'ngx-chips/core/accessor';
import { Subscription, asyncScheduler } from 'rxjs';
import { getInputFieldIconCssClass } from '../sp-form-control-utils';
import { CompositeChipsService } from '../composite-chips/composite-chips.service';
import {
    keyCodeComma,
    keyCodeEnter,
    keyCodeSemicolon,
} from '../../../../core/config.service';
import { isDefined, unsubscribeIfActive } from '../../../utils';
import { AutoCompleteService } from '../autocomplete/sp-autocomplete.service';
import { chipsMaxItemsCssClassPrefix } from '../../../constants';
import { CHIPS_WITH_CONSTRAINT_THEME_NAME } from '../sp-form-controls.model';
import { CompositeChipsContainer, SpChipsInputControl } from './model';

const keycodesToAddTag = [keyCodeEnter, keyCodeSemicolon, keyCodeComma];
const chipCloseIconCssClass = 'close-icon';

@Component({
    selector: 'sp-chips[control]',
    templateUrl: './chips.component.html',
    styleUrls: ['./chips.component.scss'],
    providers: [AutoCompleteService],
})
export class ChipsComponent implements OnInit, OnDestroy {
    @Input() control!: SpChipsInputControl;

    @Output() chipsInputFocus = new EventEmitter();

    @ViewChild('autocompleteInputField', { static: true })
    autocompleteInput!: ElementRef<HTMLInputElement>;

    selectedValues: TagModelClass[] = [];
    isInputFocused = false;
    private subscription: Subscription = new Subscription();

    @HostBinding('class.drag-over') // css class defined in styles.scss
    get isDragOver() {
        return (
            this.chipsService?.isDragInProgress &&
            this.chipsService?.enterContainer ===
                this.control.compositeModePosition
        );
    }

    @HostBinding('class.tag-container-empty')
    get isTagContainerEmpty() {
        return !this.selectedValues.length;
    }

    @HostBinding('class.input-with-icon')
    get hasIcon() {
        return this.control.icon;
    }

    @HostBinding('class.invalid')
    get isInvalid() {
        const formControl = this.control.formControl;

        return (
            (formControl.dirty || formControl.touched) && formControl.invalid
        );
    }

    @HostBinding('style.border')
    get getBorderStyle() {
        return this.control.style?.border;
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): void {
        this.onChipsControlClick(event);
    }

    get isCompositeBottom(): boolean {
        return (
            this.control.compositeModePosition ===
            CompositeChipsContainer.bottom
        );
    }

    get useChipsWithConstraints(): boolean {
        return this.control.theme === CHIPS_WITH_CONSTRAINT_THEME_NAME;
    }

    get compositeControl(): boolean {
        return isDefined(this.control.compositeModePosition);
    }

    get autocompleteParams() {
        return {
            ...this.control.autocomplete,
            inputElement: this.autocompleteInput.nativeElement,
            valueChangedHandler: (value: TagModelClass) =>
                this.onAutocompleteValueChange(value),
        };
    }

    get iconCssClass() {
        return getInputFieldIconCssClass(
            this.control.formControl,
            !this.control.autocomplete.preventMarkAsValid,
            this.control.icon,
        );
    }

    // sp-chips-max-N class has to allow applying global 'nth-chip'
    // css rules on this particular component instance
    // note: the 'sp-chips-max-' should be in sync with corresponding
    // prefix used when generating sp-chips-max-N rules in styles.scss
    get chipsMaxItemsWrapperClass() {
        return this.control.chipsMaxItems
            ? {
                  [`${chipsMaxItemsCssClassPrefix}${
                      this.control.chipsMaxItems
                  } ${
                      this.disableChipsStyleOnError
                          ? 'disable-error-styling'
                          : ''
                  }`]: true,
              }
            : {};
    }

    @HostBinding('class.autocomplete-input-visible')
    get isAutocompleteInputVisible(): boolean {
        return this.selectedValues.length === 0 || this.isInputFocused;
    }

    @HostBinding('class.with-bottom-counter')
    get showBottomCounter(): boolean {
        const hasValuesOrInvalid =
            !!this.selectedValues.length || this.isInvalid;

        return !!(
            this.control.withBottomCounter &&
            this.control.chipsMaxItems &&
            hasValuesOrInvalid
        );
    }

    get bottomCounterValue(): string {
        return `${this.selectedValues.length}/${this.control.chipsMaxItems}`;
    }

    get isMaxChipsExceeded(): boolean {
        return (
            !!this.control.chipsMaxItems &&
            this.selectedValues.length > this.control.chipsMaxItems
        );
    }

    // Certain usages of component do not use styling of chips that exceed maximum.
    // Currently only chips with constraints.
    get disableChipsStyleOnError(): boolean {
        return this.useChipsWithConstraints;
    }

    constructor(
        private eRef: ElementRef,
        @Optional() public chipsService: CompositeChipsService,
        private autoCompleteService: AutoCompleteService,
    ) {}

    ngOnInit() {
        this.control.componentRef = this;
        this.autoFocusInput();

        if (this.control._value) {
            (this.control._value as TagModelClass[]).forEach((value) =>
                this.addValue(value, false),
            );
        }

        this.watchValueChangeFromOutside();
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
        this.control?.onDestroyFn();
    }

    @HostListener('document:click', ['$event'])
    onFocusOut(event: MouseEvent) {
        if (this.eRef.nativeElement?.contains(event.target)) {
            return false;
        }
        this.isInputFocused = false;
    }

    onInputBlur(value: any) {
        if (this.control.disabled) {
            return;
        }

        if (
            !this.control.autocomplete ||
            (this.control.autocomplete.acceptUserInput &&
                this.control.autocomplete.selectOnBlur)
        ) {
            this.addValue(value);
        }
        this.clearInput();
    }

    onInputKeyPressed(event: any, value: any) {
        if (this.control.disabled) {
            return;
        }

        if (keycodesToAddTag.includes(event.keyCode)) {
            if (
                !this.control.autocomplete ||
                this.control.autocomplete.acceptUserInput
            ) {
                value = (value as string).trim().replace(/[;,]/g, '');
                this.addValue(value);
                this.clearInput();
            }
        }
    }

    autoFocusInput() {
        if (this.control.autofocus) {
            setTimeout(() => this.focusInput());
        }
    }

    onInputFocus() {
        if (this.control.disabled) {
            return;
        }

        this.isInputFocused = true;
        this.chipsInputFocus.emit();

        if (this.control.emitFocusEvent) {
            this.control.emitFocusEvent();
        }
    }

    onAutocompleteValueChange(value: TagModelClass) {
        this.addValue(value);
    }

    onChange() {
        this.setFormValue();
    }

    removeItem(itemToRemove: TagModelClass) {
        const propToRemoveBy = itemToRemove.id
            ? 'id'
            : this.control.chipsDisplay;

        this.selectedValues = [
            ...this.selectedValues.filter(
                (item) => item[propToRemoveBy] !== itemToRemove[propToRemoveBy],
            ),
        ];
        this.onRemove(itemToRemove);
    }

    onRemove(event: TagModelClass) {
        if (this.compositeControl) {
            const dstType = this.isCompositeBottom
                ? CompositeChipsContainer.bottom
                : CompositeChipsContainer.top;
            this.chipsService.removeValue(dstType, event);
        }

        if (this.autocompleteParams.excludeSelected) {
            this.autoCompleteService.removeFromExclusion(event.rawValue);
        }

        this.setFormValue();
    }

    // focus autocomplete input field upon ngx-chips' tag container click or placeholder click
    onChipsControlClick(event: MouseEvent) {
        if (this.control.disabled) {
            return;
        }

        const target = event.target as HTMLElement;
        if (!target || !target.classList.contains(chipCloseIconCssClass)) {
            this.focusInput();

            return false;
        }
    }

    addValue(value: TagModelClass, setFormValue = true) {
        if (!value) {
            return;
        }

        if (this.control.onBeforeAddFunc) {
            const result = this.control.onBeforeAddFunc({ ...value });
            value['dynamicDisplay'] = result.dynamicDisplay;
            value['dynamicData'] = result.dynamicData;
            if (result.dynamicValue) {
                value['dynamicValue'] = result.dynamicValue;
            }
        }
        this.clearInput();

        const control = this.control;
        if (
            control.autocomplete &&
            !control.autocomplete.acceptUserInput &&
            this.isCustomValue(value)
        ) {
            return;
        }

        const tagModel = this.mapValueToTagModel(value);

        if (
            !control.allowDuplicates &&
            (this.alreadySelected(tagModel) ||
                this.alreadySelectedInCompositeSibling(tagModel))
        ) {
            if (!this.control.autocomplete.reFocusAfterSelect) {
                this.blurInput();
            }

            return;
        }

        if (!this.isAllowedToAdd()) {
            return;
        }

        if (this.control.isMultiSelect) {
            this.selectedValues.push(tagModel);
        } else {
            this.selectedValues = [tagModel];
        }

        if (this.control.orderingFunc) {
            this.selectedValues.sort(this.control.orderingFunc);
        }

        if (this.chipsService) {
            const dstType = this.isCompositeBottom
                ? CompositeChipsContainer.bottom
                : CompositeChipsContainer.top;
            this.chipsService.addValue(dstType, tagModel);
        }

        if (setFormValue) {
            this.setFormValue();
        }

        if (!this.control?.autocomplete?.reFocusAfterSelect) {
            this.isInputFocused = false;
        }
    }

    focusInput() {
        this.isInputFocused = true;
        asyncScheduler.schedule(() => {
            this.autocompleteInput.nativeElement.focus();
        });
    }

    blurInput() {
        this.isInputFocused = false;
        asyncScheduler.schedule(() => {
            this.autocompleteInput.nativeElement.blur();
        });
    }

    private mapValueToTagModel(model: TagModelClass): TagModelClass {
        if (this.useChipsWithConstraints) {
            return this.mapChipWithConstraintToTagModel(model);
        }

        model = model['rawValue'] || model;

        const id = model['id'] ?? uuid();
        const displayStr =
            (this.control.chipsDisplay && model[this.control.chipsDisplay]) ||
            model;
        const tagValue: TagModelClass = {
            value: displayStr,
            display: displayStr,
            id,
            rawValue: model,
        };

        if (model['dynamicDisplay'] && model['dynamicData']) {
            if (model['dynamicValue']) {
                tagValue['dynamicValue'] = model['dynamicValue'];
            } else {
                tagValue['dynamicValue'] = model;
            }
            tagValue['dynamicDisplay'] = model.dynamicDisplay;
            tagValue['dynamicData'] = model.dynamicData;
        }

        return tagValue;
    }

    private mapChipWithConstraintToTagModel(model: TagModelClass) {
        if (!model.value) {
            return {
                value: model,
                constraint: this.control.autocomplete.defaultConstraint,
            };
        }

        if (!model.constraint && this.control.autocomplete.defaultConstraint) {
            model.constraint = this.control.autocomplete.defaultConstraint;
        }

        return model;
    }

    private isCustomValue(value: any): boolean {
        const autocomplete = this.control.autocomplete;
        let isCustomValue = true;

        if (autocomplete) {
            if (
                typeof value === 'string' &&
                autocomplete.source instanceof Array
            ) {
                isCustomValue = !autocomplete.source.find(
                    (item) =>
                        autocomplete.displayPropertyName &&
                        item[autocomplete.displayPropertyName] === value,
                );
            } else if (typeof value !== 'string') {
                isCustomValue = false;
            }
        }

        return isCustomValue;
    }

    private alreadySelected(tagModel: Record<string, any>): boolean {
        if (
            this.control.autocomplete &&
            !this.control.autocomplete.acceptUserInput
        ) {
            return !!this.selectedValues.find(
                (chip) =>
                    ((chip.id !== null || tagModel.id !== null) &&
                        chip.id === tagModel.id) ||
                    chip.value === tagModel.value,
            );
        }

        return !!this.selectedValues.find(
            (chip) => chip.value === tagModel.value,
        );
    }

    private alreadySelectedInCompositeSibling(
        tagModel: TagModelClass,
    ): boolean {
        const onlyIdCheck =
            this.control.autocomplete &&
            !this.control.autocomplete.acceptUserInput;

        return (
            this.compositeControl &&
            this.chipsService.isAlreadySelected(tagModel, onlyIdCheck)
        );
    }

    private isAllowedToAdd(): boolean {
        return (
            this.control.chipsMaxItems === undefined ||
            this.control.chipsMaxItems > this.selectedValues.length ||
            this.control.allowExcessItems
        );
    }

    private clearInput() {
        this.autocompleteInput.nativeElement.value = '';
    }

    private setFormValue() {
        const displayKey = this.control.chipsDisplay || 'displayValue';
        const mappedValue = this.selectedValues.map((chip: TagModelClass) => {
            if (this.useChipsWithConstraints) {
                return this.mapChipWithConstraintToTagModel(chip);
            }

            const value: TagModelClass = {
                value: chip['value'],
                [displayKey]: chip['display'],
                id: chip['id'],
                rawValue: chip['rawValue'],
            };

            if (chip['dynamicDisplay']) {
                value['dynamicDisplay'] = chip['dynamicDisplay'];
                value['dynamicData'] = chip['dynamicData'];
                value['value'] = chip['dynamicValue'];
            }

            return value;
        });

        this.control.formControl.setValue(mappedValue);
        this.control.formControl.markAsDirty();
        this.control.valueChange.emit(mappedValue);
    }

    private watchValueChangeFromOutside() {
        if (!this.control.setValue$) {
            return;
        }

        this.subscription.add(
            this.control.setValue$.subscribe((value) =>
                this.setValueFromOutside(value),
            ),
        );
    }

    private setValueFromOutside(value: TagModelClass[]) {
        this.selectedValues = value;
        if (
            this.autocompleteParams.excludeSelected &&
            this.autoCompleteService
        ) {
            this.autoCompleteService.reset();
        }
        this.setFormValue();
    }

    // Chips Composite Mode drag-and-drop

    // We have to use the 'dragenter' and 'dragend' host listeners to be able to determine
    // that tag is being dragged. That is needed to show / hide the ngx-chips's tags container
    // which is hidden (to implement the required design) when it contains no chips. And having
    // a hidden container prevents us from being able to use it's built-in DnD features
    @HostListener('dragenter', ['$event'])
    onDragEnter() {
        this.chipsService.enterContainer = this.control.compositeModePosition;
        this.chipsService.isDragInProgress = true;
    }

    @HostListener('dragend', ['$event'])
    onDragEnd() {
        this.chipsService.enterContainer = undefined;
        this.chipsService.isDragInProgress = false;
    }
}
