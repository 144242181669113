<div class="input-controls">
    <span *ngIf="!!this.control.label" class="inline-label">{{ control.label }}</span>
    <ngx-slider
        class="slider"
        [class.bullet-size-small]="isSmallBullet"
        [ngClass]="percentClass"
        [class.has-bottom-ticks]="control.sliderOptions.showTicks"
        [value]="sliderValue"
        (valueChange)="onValueChange($event)"
        [options]="sliderOptions"
        [manualRefresh]="control.manualRefresh"
    >
    </ngx-slider>
    <ng-container>
        <div *ngIf="!inputEnabled" (click)="toggleInput(true)" class="current-value">
            {{ editableLabelValue(inputValue) }}
        </div>
        <input
            *ngIf="inputEnabled"
            [value]="inputValue"
            [min]="0"
            [max]="inputMax"
            (change)="onValueChange($any($event.target).value)"
            (blur)="toggleInput(false)"
            (keyup.enter)="onPressEnter($event)"
            type="number"
            class="numeric-input"
            #inputElement
        />
    </ng-container>
    <sp-compensation-unit-selector
        *ngIf="control.sliderUnits?.length > 0"
        [units]="control.sliderUnits"
        [defaultUnit]="sliderUnit"
        (unitSelected)="onUnitChange($event)"
        class="unit-selector"
    >
    </sp-compensation-unit-selector>
</div>
