import type { Constraint } from './enumeration/constraint.enum';
import type { CompanySizeRange } from './company-size-range.model';
import type { NumRange } from './num-range.model';

export class JobRequirements {
    companies: JobRequirement<null, string>[];
    companySizes: JobRequirement<null, CompanySizeRange>[];
    degrees: JobRequirement<null, string>[];
    industries: JobRequirement<number, string>[];
    jobTitles: JobRequirement<string | null, string>[];
    majors: JobRequirement<string, string>[];
    schools: JobRequirement<null, string>[];
    seniorities: JobRequirement<null, string>[];
    skills: JobRequirement<string | null, string>[];
    relevantYears?: JobRequirement<null, NumRange>;
    totalYears?: JobRequirement<null, NumRange>;

    constructor() {
        this.companies = [];
        this.companySizes = [];
        this.degrees = [];
        this.industries = [];
        this.jobTitles = [];
        this.majors = [];
        this.schools = [];
        this.seniorities = [];
        this.skills = [];
        this.relevantYears = undefined;
        this.totalYears = undefined;
    }

    update(partialReqs: Partial<JobRequirements>) {
        this.companies = partialReqs.companies ?? this.companies;
        this.companySizes = partialReqs.companySizes ?? this.companySizes;
        this.degrees = partialReqs.degrees ?? this.degrees;
        this.industries = partialReqs.industries ?? this.industries;
        this.jobTitles = partialReqs.jobTitles ?? this.jobTitles;
        this.majors = partialReqs.majors ?? this.majors;
        this.schools = partialReqs.schools ?? this.schools;
        this.seniorities = partialReqs.seniorities ?? this.seniorities;
        this.skills = partialReqs.skills ?? this.skills;
        this.relevantYears = partialReqs.relevantYears ?? this.relevantYears;
        this.totalYears = partialReqs.totalYears;
    }

    isValid(): boolean {
        return !!(this.skills.length && this.jobTitles.length);
    }

    static of(reqs: JobRequirements): JobRequirements {
        return Object.assign(new JobRequirements(), reqs);
    }
}

export type SupportedJobReqs = Pick<
    JobRequirements,
    | 'companies'
    | 'companySizes'
    | 'degrees'
    | 'industries'
    | 'jobTitles'
    | 'majors'
    | 'schools'
    | 'skills'
    | 'totalYears'
>;

export type JobRequirement<TId, TValue> = {
    id: TId;
    value: TValue;
    constraint: Constraint;
};

export const jobRequirementsLabels: Record<keyof SupportedJobReqs, string> = {
    jobTitles: 'Job Titles',
    skills: 'Skills',
    companySizes: 'Company Sizes',
    companies: 'Companies',
    industries: 'Industries',
    totalYears: 'Total Experience (Years)',
    schools: 'Schools',
    degrees: 'Degree Types',
    majors: 'Majors',
};
