<div class="job-item-content">
    <sp-resolvable-logo
        class="company-logo"
        [logoUrl]="job.employerLogoUrl"
        [fallbackLogo]="'/images/icons/forms/city.svg'"
        [height]="28"
        [width]="28"
        [iconSize]="16"
        [roundedBorders]="false"
        [isFallbackLogoMasked]="true"
        [isMasked]="false"
    >
    </sp-resolvable-logo>
    <div class="job-title">{{ job.jobTitle }}</div>
    <div class="details-wrapper">
        <div class="detail">
            <span class="detail-icon employer-name"></span>
            <span class="detail-text">{{ job.employerName }}</span>
        </div>
        <div class="detail">
            <span class="detail-icon job-location"></span>
            <sp-composite-location-view
                *ngIf="jobInfo"
                class="composite-location-view"
                [job]="jobInfo"
                [viewType]="'inline'"
                [groupedOfficeLocations]="true"
            >
            </sp-composite-location-view>
        </div>
    </div>
</div>
