import type { ValidatorFn } from '@angular/forms';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { CustomValidators } from '@narik/custom-validators';
import type { Observable, Subscription } from 'rxjs';
import { Subject, forkJoin, of } from 'rxjs';
import type { TagModel } from 'ngx-chips/core/accessor';
import { map, tap } from 'rxjs/operators';
import type { DropdownPosition } from '@ng-select/ng-select';
import type { AutoCompleteFilter } from '@ngui/auto-complete';
import type { MessageTemplate } from '../../messaging-chat/model/message-template';
import type { GenericLabelValue } from '../../models';
import { OverridenValidators } from '../validators/custom-min-length.validator';
import { objectWithPropertiesValidator } from '../validators/object-with-properties.validator';
import { GoogleApiLocationType } from '../../../core/google-api.service';
import type {
    GeoLocation,
    GoogleApiService,
} from '../../../core/google-api.service';
import { url } from '../validators/url.validator';
import { passwordValidator } from '../validators/password.validator';
import { isDefined, markUsedItemsInAutocompleteSourceById } from '../../utils';
import type { TemplatePlaceholder } from '../../../account/profile/employer-profile/profile-edit/section-messaging/templates-manager/templates-manager.constants';
import type {
    ToggleLabelPosition,
    ToggleSize,
} from '../../toggle-switch/toggle-switch.component';
import type { SpDropDownData } from '../../sp-dropdown/sp-dropdown.component';
import type {
    IconButton,
    IconButtonStyles,
} from '../../icon-buttons-group/icon-buttons-group.component';
import type { Constraint } from '../../models/enumeration/constraint.enum';
import type { SpChipsControlParams } from './chips/model';
import type { TemplateEditorWysiwygComponent } from './template-editor/template-editor-wysiwyg.component';
import type { ChipsComponent } from './chips/chips.component';
import ComponentRestrictions = google.maps.places.ComponentRestrictions;
import { usAndCanada, usCountry } from '../../constants';
import type { JobRequirement } from '../../models/job-requirements.model';

export const CHIPS_WITH_CONSTRAINT_THEME_NAME = 'chips-with-constraint';

export interface SpListItemIcon {
    // mapping for autocomplete dropdown img tag's 'src' attribute
    srcMapping: string | Function;

    // mapping for autocomplete dropdown list item text
    labelMapping: string;

    // default icon to use when dynamic icon was not resolved successfully
    defaultSrc: string;
}

export interface SpAutoCompleteParams {
    source?: any;
    initialSource?: any;
    formControl?: FormControl;
    inputElement?: HTMLInputElement;
    displayPropertyName?: string;
    noResultText?: string;
    hideOnNoMatchFound?: boolean;
    selectValueOf?: string;
    reFocusAfterSelect?: boolean;
    acceptUserInput?: boolean;
    autoSelectFirstItem?: boolean;
    selectOnBlur?: boolean;
    maxItemsInList?: number;
    openOnFocus?: boolean;
    listFormatter?: string | Function;
    zIndex?: number;
    matchFormatted?: boolean;
    valueChangedHandler?: Function;
    customValueSelectedHandler?: Function;
    filters?: AutoCompleteFilter[];
    clearOnSelect?: boolean;
    // (optional) autocomplete dropdown list item icon configuration
    // Note that if icon settings are specified 'listFormatter' setting will be ignored
    icon?: SpListItemIcon;
    // Ignores from dropdown list previous selected values
    // Used in combination with chips component to ignore selected tags
    // !! Initial implementation verified for array autocomplete source
    excludeSelected?: boolean;
    // Callback function when the source of the autocomplete is changed
    sourceChangedHandler?: (previousSource: any, newSource: any) => void;
    limitDropdownHeight?: boolean;
    setValueFromOutside$?: Subject<any>;
    // Used for chips with constraint
    defaultConstraint?: Constraint;
    // Dropdown data for chips with constraint
    constraintOptions?: SpDropDownData;
    // Set to true to prevent icon change to checkmark if control is valid
    preventMarkAsValid?: boolean;
}

export interface SpControlParams {
    form: FormGroup;
    name: string;
    label?: string;
    value?: any;
    hint?: string;
    placeholder?: string;
    isOptional?: boolean;
    hideHeader?: boolean;
    isAutoresizable?: boolean;
    textareaAsInput?: boolean;
    isTitleCase?: boolean;
    validators?: ValidatorFn[];
    errorMessages?: { [key: string]: string | Function };

    // Generic error message to render on an invalid field / control.
    // Used for scenarios when multiple 'unmet criteria' error messages
    // are also rendered
    genericErrorMessage?: string;
    autocomplete?: SpAutoCompleteParams;
    isMultiSelect?: boolean;
    allowDuplicates?: boolean;
    secondaryPlaceholder?: string;
    dragZone?: string;
    chipsDisplay?: string;
    chipsMaxItems?: number;
    controlType?: SpFormControlType;
    inputType?: SpFormInputType;
    dirty?: boolean;
    disabled?: boolean;
    autofocus?: boolean;

    // name of the icon to render in the left hand side of the input field
    icon?: string;

    // name of the domain for image to render in the left hand side of the input field
    domain?: string;

    // name of the fallback logo to render in the left hand side of the input field
    fallbackLogo?: string;

    // name of the icon to render to the left of the field's label
    labelIcon?: string;

    // for certain controls (e.g. date-range) we need to show validation errors below the field
    showValidationErrorBelowField?: boolean;

    // stop propagation of the focusin event on sp-form-input host
    // main usage is disallowing event propagation from the host element of the composite
    // (i.e. having other sp-form-input components as children) control
    stopFocusinPropagation?: boolean;

    // by default 'Required' tooltip (i.e. asterisk '*' with on-hover message) is shown
    // for fields with label and {isOptional: false}. Setting this to true allows to
    // override default behavior
    hideRequiredTooltip?: boolean;

    // optional function to be called prior corresponding component is destroyed
    onDestroyFn?: Function;

    // theme to apply,initially only ChipsComponent
    theme?: string;

    style?: { [key: string]: any };
    labelStyle?: { [key: string]: any };

    browserAutocomplete?: string;
    size?: 'small' | 'regular';

    infoTooltip?: string;

    isClosable?: boolean;
}

export interface SpUrlInputControlParams extends SpControlParams {
    // in lenient url validation mode protocol part (e.g. http(s):// / ftp:// etc.) is optional
    lenientUrlValidationMode?: boolean;
}

export enum SpFormControlType {
    input = 'input',
    validatedPasswordInput = 'validatedPasswordInput',
    textarea = 'textarea',
    switcher = 'switcher',
    component = 'component',
}

export enum SpFormInputType {
    text = 'text',
    number = 'number',
    email = 'email',
    url = 'url',
    password = 'password',
}

export class SpFormControl {
    form: FormGroup;
    name: string;
    controlType: SpFormControlType;
    label?: string;

    hint?: string;
    _value?: any;
    placeholder?: string;

    isOptional?: boolean;
    isAutoresizable?: boolean;
    isTitleCase?: boolean;
    textareaAsInput?: any;
    hideHeader?: boolean;
    dragZone?: string;
    focused?: boolean;
    dirty?: boolean;
    disabled?: boolean;
    icon?: string;
    domain?: string;
    fallbackLogo?: string;
    labelIcon?: string;
    showValidationErrorBelowField?: boolean;
    showChildErrorMessages?: boolean;
    stopFocusinPropagation?: boolean;
    hideRequiredTooltip?: boolean;
    autofocus?: boolean;
    style?: { [key: string]: any };
    labelStyle?: { [key: string]: any };

    validators?: ValidatorFn[];
    errorMessages: { [key: string]: string | Function } = {
        required: 'required',
        pattern: 'invalid',
    };
    genericErrorMessage?: string;

    formControl: FormControl;
    autocomplete: SpAutoCompleteParams;
    autocompleteInited: boolean;

    valueChange = new EventEmitter<any>();

    // for multi selectable
    isMultiSelect: boolean;
    allowDuplicates: boolean;
    secondaryPlaceholder?: string;
    selectedValues: TagModel[] = [];
    extComponent: string;
    isDragOver: boolean;
    prepareDrop: boolean;
    chipsDisplay: string;
    chipsMaxItems?: number;
    inited: boolean;

    // reference to an underlying child component
    componentRef?: any;

    // Theme to apply to the component,
    // initially only chips -> tag-input
    theme?: string;

    browserAutocomplete?: string;
    containsSubControls?: boolean;
    size?: 'small' | 'regular';

    // Tooltip which will be shown when hover over info icon
    infoTooltip?: string;

    // if true, close button is visible and emit close event
    isClosable?: boolean;

    // button which can generate additional values for the control using spell
    spellGenerateButton?: {
        label: string;
        func: (values) => Observable<JobRequirement<string, string>[]>;
        isVisible: (values) => boolean;
    };

    constructor(params: SpControlParams) {
        this.form = params.form;
        this.name = params.name;
        this.label = params.label;
        this.controlType = params.controlType;
        this.hint = params.hint;
        this._value = params.value != null ? params.value : '';
        this.validators = params.validators || [];
        this.isOptional = params.isOptional;
        this.isTitleCase = params.isTitleCase;
        this.isAutoresizable = params.isAutoresizable;
        this.textareaAsInput = params.textareaAsInput;
        this.hideHeader = params.hideHeader;
        this.placeholder = params.placeholder || '';
        this.errorMessages = { ...this.errorMessages, ...params.errorMessages };
        this.genericErrorMessage = params.genericErrorMessage;
        this.autocomplete = params.autocomplete;
        this.dragZone = params.dragZone || '';
        this.autofocus = params.autofocus;
        this.chipsDisplay = params.chipsDisplay;
        this.chipsMaxItems = params.chipsMaxItems;
        this.dirty = params.dirty;
        this.disabled = params.disabled;
        this.icon = params.icon;
        this.domain = params.domain;
        this.fallbackLogo = params.fallbackLogo;
        this.labelIcon = params.labelIcon;
        this.infoTooltip = params.infoTooltip;
        this.showValidationErrorBelowField =
            !!params.showValidationErrorBelowField;
        this.stopFocusinPropagation = params.stopFocusinPropagation;
        this.hideRequiredTooltip = params.hideRequiredTooltip;
        this.isClosable = params.isClosable;

        this.secondaryPlaceholder =
            params.secondaryPlaceholder || this.placeholder;
        this.isMultiSelect = params.isMultiSelect;
        this.allowDuplicates = params.allowDuplicates;
        this.theme = params.theme;
        this.style = params.style;
        this.labelStyle = params.labelStyle;
        this.browserAutocomplete = params.browserAutocomplete;
        this.size = params.size;

        this.init();
    }

    init() {
        if (this.inited) {
            return;
        }
        this.setIsOptional(this.isOptional);
        if (this.isMultiSelect) {
            this.extComponent = 'ChipsComponent';
        }
        this.formControl = new FormControl(this._value, this.validators);
        this.formControl.registerOnChange((v) => (this._value = v));
        if (!this.form) {
            this.form = new FormGroup({ [this.name]: this.formControl });
        } else {
            this.form.removeControl(this.name);
            this.form.addControl(this.name, this.formControl);
        }
        if (this.isAutoresizable && !this.textareaAsInput) {
            this.textareaAsInput = this.controlType === SpFormControlType.input;
            this.controlType = SpFormControlType.textarea;
        }

        if (this.dirty) {
            this.formControl.markAsDirty();
        }
        this.inited = true;
    }

    set source(source: any) {
        if (this.autocomplete) {
            const prevSource = this.autocomplete.source;
            this.autocomplete.source = this.autocomplete.initialSource = source;
            if (this.autocomplete.sourceChangedHandler) {
                this.autocomplete.sourceChangedHandler(prevSource, source);
            }
        }
    }

    get isDirtyOrTouched() {
        return this.formControl.dirty || this.formControl.touched;
    }

    get error() {
        if (this.formControl.invalid && this.isDirtyOrTouched) {
            if (this.genericErrorMessage) {
                return this.genericErrorMessage;
            }

            const keys = Object.keys(this.formControl.errors);
            if (keys && keys.length > 0) {
                if (this.errorMessages[keys[0]] instanceof Function) {
                    return (this.errorMessages[keys[0]] as Function)();
                }

                return (
                    this.errorMessages[keys[0]] ||
                    this.formControl.errors[keys[0]].message ||
                    keys[0]
                );
            }
        }

        return null;
    }

    set value(value: any) {
        this.formControl.patchValue((this._value = value));
    }

    get value(): any {
        return this.formControl.value;
    }

    setIsOptional(isOptional: boolean) {
        if (isOptional) {
            this.validators = this.validators.filter(
                (v) => v !== Validators.required,
            );
        } else {
            this.validators.push(Validators.required);
        }
    }

    updateValidators(newValidators?: ValidatorFn[]) {
        this.validators = newValidators ? newValidators : this.validators;
        if (this.inited) {
            this.formControl.clearValidators();
            this.formControl.setValidators(this.validators);
            this.formControl.updateValueAndValidity();
        }
    }

    onDestroyFn() {
        /* no-op */
    }
}

export class SpToggleControl extends SpFormControl {
    toggleSize: ToggleSize;
    stateLabelPosition: ToggleLabelPosition;

    constructor(params: SpToggleControlParams) {
        super(params);
        this.toggleSize = params.toggleSize;
        this.stateLabelPosition = params.stateLabelPosition;
        this.hideHeader = true;
    }
}

export interface SpToggleControlParams extends SpControlParams {
    toggleSize: ToggleSize;
    stateLabelPosition: ToggleLabelPosition;
}

export class SpDropdownControl extends SpFormControl {
    dropdownData: SpDropDownData;
    valueDisplayFormatter?: (value: SpDropDownData) => string;

    constructor(params: SpDropdownControlParams) {
        super(params);
        this.dropdownData = params.dropdownData;
        this.valueDisplayFormatter = params.valueDisplayFormatter;
        this.hideHeader = true;
    }
}

export interface SpDropdownControlParams extends SpControlParams {
    dropdownData: SpDropDownData;
    valueDisplayFormatter?: (value: SpDropDownData) => string;
}

export class SpInputControl extends SpFormControl {
    inputType: SpFormInputType;

    private subscription: Subscription;

    constructor(params: SpControlParams) {
        params.controlType = params.controlType
            ? params.controlType
            : SpFormControlType.input;
        super(params);
        this.inputType = params.inputType;

        if (params.autocomplete && !params.isMultiSelect) {
            this.controlType = SpFormControlType.component;
            this.autocomplete.setValueFromOutside$ = new Subject<any>();
            this.extComponent = 'SpSingleValueAutocomplete';
        }

        this.subscription = this.formControl.valueChanges.subscribe((value) => {
            if (
                value &&
                typeof value === 'string' &&
                value.trim().length === 0
            ) {
                this.formControl.patchValue('');
            }
        });
    }

    onDestroyFn() {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }
}

export class SpTextInput extends SpInputControl {
    static readonly defaultValidators = [OverridenValidators.minLength(2)];

    constructor(params: SpControlParams) {
        params.validators = [
            ...(params.validators || []),
            ...SpTextInput.defaultValidators,
        ];
        params.errorMessages = {
            minlength: 'Min 2 characters',
            ...params.errorMessages,
        };
        params.inputType = SpFormInputType.text;
        super(params);
    }
}

export interface SpNumberInputControlParams extends SpControlParams {
    digitsOnly?: boolean;
}

export class SpNumberInput extends SpInputControl {
    constructor(params: SpNumberInputControlParams) {
        params.inputType = SpFormInputType.number;
        if (params.digitsOnly) {
            params.validators = [
                ...(params.validators || []),
                CustomValidators.digits,
            ];
            params.errorMessages = {
                digits: 'Only digits 0-9 are allowed',
                ...params.errorMessages,
            };
        }
        super(params);
    }
}

export class SpEmailInput extends SpInputControl {
    constructor(params: SpControlParams) {
        params.validators = [
            ...(params.validators || []),
            CustomValidators.email,
        ];
        params.errorMessages = { email: 'invalid', ...params.errorMessages };
        params.inputType = SpFormInputType.email;
        super(params);
    }
}

export class SpUrlInput extends SpInputControl {
    constructor(params: SpUrlInputControlParams) {
        params.validators = [
            ...(params.validators || []),
            url(params.lenientUrlValidationMode),
        ];
        params.errorMessages = { url: 'invalid', ...params.errorMessages };
        params.inputType = SpFormInputType.url;
        super(params);
    }
}

export class SpPasswordInput extends SpFormControl {
    allowPasswordUnmask = true;
    isShowingPassword = false;

    constructor(params: SpControlParams) {
        super(params);
        this.controlType = SpFormControlType.input;
    }

    get inputType(): SpFormInputType {
        return this.isShowingPassword
            ? SpFormInputType.text
            : SpFormInputType.password;
    }

    showHidePassword() {
        this.isShowingPassword = !this.isShowingPassword;
    }
}

export class SpValidatedPasswordInput extends SpPasswordInput {
    static readonly defaultValidators = [passwordValidator()];

    constructor(params: SpControlParams) {
        params.validators = [
            ...(params.validators || []),
            ...SpValidatedPasswordInput.defaultValidators,
        ];
        params.errorMessages = {
            minlength: 'minimum of 10 characters',
            ...params.errorMessages,
        };
        params.genericErrorMessage = params.genericErrorMessage
            ? params.genericErrorMessage
            : 'Invalid entry';
        super(params);
        this.controlType = SpFormControlType.validatedPasswordInput;
    }
}

export interface SpTagChipsInputControlParams extends SpControlParams {
    // list of chips
    selectedChips: string[];

    // theme which will be used
    theme?: string;
}

export class SpTagChipsInputControl extends SpFormControl {
    component: string;
    selectedChips: string[];
    theme: string;
    chipClick = new EventEmitter();
    constructor(params: SpTagChipsInputControlParams) {
        super(params);

        this.component = 'SpTagChipsInputComponent';
        this.selectedChips = params.selectedChips;
        this.theme = params.theme || 'squarepeg-chips-theme-light';
    }
}

export interface SpCompositeFilterChipsControlParams
    extends SpChipsControlParams {
    // list of selected chips
    selectedChips: string[];

    // list of chips proposed for selection
    allChips: string[];

    // theme which will be used
    theme?: string;
}

export class SpCompositeFilterChipsControl extends SpFormControl {
    component: string;
    selectedChips: string[];
    allChips: string[];
    theme: string;

    constructor(params: SpCompositeFilterChipsControlParams) {
        super(params);

        this.component = 'CompositeFilteringChipsComponent';
        this.selectedChips = params.selectedChips;
        this.allChips = params.allChips;
        this.theme = params.theme || 'squarepeg-chips-theme-light';
    }
}

export interface SpTextAreaControlParams extends SpControlParams {
    rows?: number;
    maxChars?: number;
    // optional bottom padding to e.g. account for a toolbar
    bottomPadding?: number;
}

export class SpTextArea extends SpFormControl {
    rows: number;
    maxChars?: number;
    bottomPadding?: number;

    constructor(params: SpTextAreaControlParams) {
        params.validators = [
            ...(params.validators || []),
            OverridenValidators.minLength(2),
        ];
        params.errorMessages = {
            minlength: 'Min 2 characters',
            ...params.errorMessages,
        };
        params.controlType = SpFormControlType.textarea;
        super(params);
        this.rows = params.rows || 3;
        this.bottomPadding = params.bottomPadding;

        if (params.maxChars) {
            this.maxChars = params.maxChars;
        }
    }
}

export interface SpComponentInputParams extends SpControlParams {
    component?: string;
}

export class SpComponentInput extends SpFormControl {
    component: string;

    constructor(params: SpComponentInputParams) {
        super(params);
        params.controlType = SpFormControlType.component;
        this.component = params.component;
    }
}

export interface IDateValue {
    value: Date;
    isCurrent?: boolean;
    isOptional?: boolean;
}

export interface IDateRangeValue {
    from: IDateValue;
    to: IDateValue;
}

// Date Mask Input

export enum MaskedDateInputFormat {
    fullDate,
    monthYear,
    year,
}

export interface SpMaskedDateInputParams extends SpComponentInputParams {
    minYear?: number;
    maxYear?: number;
    allowFutureDates?: boolean;
    labelForFutureDateSelected?: string;

    // expected date format - mm/yyyy or mm/dd/yyyy
    format?: MaskedDateInputFormat;

    // Whether to emit 'raw' incomplete masked date value, e.g. '07/10/199_'
    // By default either valid Date object or null (on incomplete input) will
    // be emitted. If set to true either complete Date object or raw string value
    // will be emitted
    emitIncompleteMaskedValue?: boolean;

    icon?: string;

    hasIsCurrentOption?: boolean;
    labelIsCurrentOption?: string;
    // optional secondary label for (End) Date field if current-date checkbox option checkbox is checked
    labelForIsCurrentToggled?: string;
    placeholderForIsCurrentTo?: string;
}

export interface SpMaskedDateRangeInputParams extends SpMaskedDateInputParams {
    labelFrom?: string;
    labelTo?: string;
    placeholderForIsCurrentTo?: string;
}

export class SpMaskedDateInput extends SpComponentInput {
    minYear?: number;
    maxYear?: number;
    format: MaskedDateInputFormat;
    allowFutureDates: boolean;
    labelForFutureDateSelected?: string;

    hasIsCurrentOption?: boolean;
    labelIsCurrentOption?: string;
    labelForIsCurrentToggled?: string;
    placeholderForIsCurrentTo?: string;

    emitIncompleteMaskedValue?: boolean;

    // outputs
    valueChange: EventEmitter<IDateValue>;

    constructor(params: SpMaskedDateInputParams) {
        super(params);

        this.controlType = SpFormControlType.component;
        this.component = 'MaskedDateInputComponent';
        this.minYear = params.minYear;
        this.maxYear = params.maxYear;
        this.format = params.format
            ? params.format
            : MaskedDateInputFormat.fullDate;
        this.icon = params.icon;
        this.hasIsCurrentOption = params.hasIsCurrentOption;
        this.labelIsCurrentOption = params.labelIsCurrentOption;
        this.placeholderForIsCurrentTo = params.placeholderForIsCurrentTo;
        this.emitIncompleteMaskedValue =
            params.emitIncompleteMaskedValue || false;
        this.allowFutureDates = !!params.allowFutureDates;
        if (params.labelForIsCurrentToggled) {
            this.labelForIsCurrentToggled = params.labelForIsCurrentToggled;
        }
        this.placeholderForIsCurrentTo = params.placeholderForIsCurrentTo;
        this.labelForFutureDateSelected = params.labelForFutureDateSelected;

        this.valueChange = new EventEmitter<IDateValue>();
    }

    onValueChange(value: any) {
        this.value = value;

        this.formControl.markAsDirty();
        this.valueChange.emit(this.value);
    }
}

export class SpMaskedDateRangeInput extends SpMaskedDateInput {
    labelFrom?: string;
    labelTo?: string;
    placeholderForIsCurrentTo?: string;

    constructor(params: SpMaskedDateRangeInputParams) {
        super(params);

        this.component = 'MaskedDateRangeInputComponent';
        this.labelFrom = params.labelFrom;
        this.labelTo = params.labelTo;
        this.icon = params.icon || 'calendar';
        this.containsSubControls = true;
        this.showChildErrorMessages = true;
    }

    onValueChange(value: IDateRangeValue) {
        this.value = value.from || value.to ? value : null;

        this.formControl.markAsDirty();
        this.valueChange.emit(this.value);
    }
}

// sp-select
export interface ISpSelectParams extends SpComponentInputParams {
    // check ng-select docs for description of the properties below
    items?: { [key: string]: any }[];
    asyncItems?: Observable<{ [key: string]: any }[]>;
    bindLabel: string;
    bindValue: string;
    dropdownPosition?: DropdownPosition;
    showIconOnlyOnEmpty?: boolean;
}

export class SpSelectInput extends SpComponentInput {
    // check ng-select docs for description of the properties below
    items: { [key: string]: any }[];
    asyncItems: Observable<{ [key: string]: any }[]>;
    bindLabel: string;
    bindValue: string;
    dropdownPosition: DropdownPosition;
    showIconOnlyOnEmpty: boolean;

    constructor(params: ISpSelectParams) {
        super(params);

        this.controlType = SpFormControlType.component;
        this.component = 'SpSelect';

        this.items = params.items;
        this.asyncItems = params.asyncItems;
        this.bindLabel = params.bindLabel;
        this.bindValue = params.bindValue;
        this.dropdownPosition = params.dropdownPosition
            ? params.dropdownPosition
            : 'auto';
        this.showIconOnlyOnEmpty = params.showIconOnlyOnEmpty
            ? params.showIconOnlyOnEmpty
            : false;
    }
}
// end sp-select

// sp-input-with-image-selector
export interface SelectorImageItem {
    name: string;
    value: string;
    code: string;
}

export interface SpInputWithImageSelectorParams extends SpComponentInputParams {
    items: SelectorImageItem[];
    defaultItemCode: string;
    genericImageClassName: string;
    specificImageClassPrefix: string;
}

export class SpInputWithImageSelector extends SpFormControl {
    component: string;
    items: SelectorImageItem[];
    defaultItemCode: string;
    inputType: SpFormInputType;
    genericImageClassName: string;
    specificImageClassPrefix: string;

    constructor(params: SpInputWithImageSelectorParams) {
        super(params);

        this.controlType = SpFormControlType.component;
        this.component = 'InputWithImageSelector';
        this.inputType = SpFormInputType.text;
        this.icon = params.icon;

        this.items = params.items;
        this.defaultItemCode = params.defaultItemCode;
        this.genericImageClassName = params.genericImageClassName;
        this.specificImageClassPrefix = params.specificImageClassPrefix;
    }
}
// end sp-input-with-image-selector

export interface InputValueWithSelectedValue {
    inputValue: string;
    selectedValue: string;
}

export interface SpInputWithSelectorParams extends SpComponentInputParams {
    items: GenericLabelValue<string>[];
}

export class SpInputWithSelector extends SpFormControl {
    component: string;
    items: GenericLabelValue<string>[];
    inputType: SpFormInputType;

    constructor(params: SpInputWithSelectorParams) {
        super(params);

        this.controlType = SpFormControlType.component;
        this.component = 'InputWithSelector';
        this.inputType = SpFormInputType.text;
        this.icon = params.icon;

        this.items = params.items;
    }
}

export interface LocationParams {
    googleApiService: GoogleApiService;
    locationType?: GoogleApiLocationType;
    locationFilter?: (locations: GeoLocation[]) => GeoLocation[];
}

export interface SpLocationInputParams extends LocationParams, SpControlParams {
    reFocusAfterSelect?: boolean;
    includeCountries?: boolean;
    includeStates?: boolean;
    usAndCanadaOnly?: boolean;
    withBottomCounter?: boolean;
    allowExcessItems?: boolean;
    locationsInUse?: GeoLocation[];
}

export class SpLocationInput extends SpInputControl {
    locationType: GoogleApiLocationType;
    previousKeyword: string;
    previousFilterValue: boolean;
    locations: GeoLocation[];
    googleApiService: GoogleApiService;
    locationFilter?: (locations: GeoLocation[]) => GeoLocation[];
    reFocusAfterSelect?: boolean;
    includeCountries?: boolean;
    includeStates?: boolean;
    usAndCanadaOnly?: boolean;
    withBottomCounter?: boolean;
    allowExcessItems?: boolean;

    get usOnlyFilterSelected(): boolean {
        return (this.previousFilterValue =
            !!this.autocomplete.filters?.[0]?.enabled);
    }

    constructor(params: SpLocationInputParams) {
        params.inputType = SpFormInputType.text;
        params.autocomplete = {
            ...params.autocomplete,
            source: (keyword) =>
                this.locationFilter
                    ? this.getLocations(keyword, params.locationsInUse).pipe(
                          map(this.locationFilter),
                      )
                    : this.getLocations(keyword, params.locationsInUse),
            noResultText: 'No locations found',
            maxItemsInList: 12,
            listFormatter: 'value',
            displayPropertyName: 'value',
            reFocusAfterSelect: isDefined(params.reFocusAfterSelect)
                ? params.reFocusAfterSelect
                : true,
            filters: <AutoCompleteFilter[]>[
                {
                    label: 'U.S. only',
                },
            ],
        };
        params.controlType = SpFormControlType.component;

        super(params);
        if (!this.isMultiSelect) {
            this.extComponent = 'SpSingleValueAutocomplete';
            this.updateValidators([
                ...this.validators,
                objectWithPropertiesValidator('id', 'value'),
            ]);
        }

        this.googleApiService = params.googleApiService;
        this.locationType = params.locationType || GoogleApiLocationType.cities;
        this.locationFilter = params.locationFilter;
        this.includeCountries = params.includeCountries;
        this.includeStates = params.includeStates;
        this.usAndCanadaOnly = params.usAndCanadaOnly;
        this.withBottomCounter = params.withBottomCounter;
        this.allowExcessItems = !!params.allowExcessItems;
    }

    // this method caches locations from previously entered keyword. No need to call api again if keyword isn't changed.
    // saves api calls and makes location appearing a bit faster
    // TODO refactor cache to be global and more controllable
    getLocations(
        keyword: string,
        locationsInUse?: GeoLocation[],
    ): Observable<GeoLocation[]> {
        if (!keyword) {
            return of([]);
        }
        // if neither filter value nor keyword has changed we do not update locations
        if (
            keyword === this.previousKeyword &&
            this.previousFilterValue === this.autocomplete.filters?.[0]?.enabled
        ) {
            return of(this.locations);
        }

        const geoLocationsObservable$ =
            this.includeCountries || this.includeStates
                ? this.getMergedMultipleLocationTypes(keyword)
                : this.getLocationsOfSingleType(
                      keyword,
                      this.locationType,
                      false,
                  );

        // if either filter value or keyword changes we need to update locations
        return geoLocationsObservable$.pipe(
            tap((locations) => (this.locations = locations)),
            map((locations) =>
                locationsInUse?.length
                    ? markUsedItemsInAutocompleteSourceById(locations, [
                          locationsInUse,
                      ])
                    : locations,
            ),
        );
    }

    private getMergedMultipleLocationTypes(
        keyword: string,
    ): Observable<GeoLocation[]> {
        const observables$: Observable<GeoLocation[]>[] = [];

        if (this.autocomplete.filters?.[0]?.enabled) {
            // if U.S. Only checkbox is checked we need to add United States as first option
            observables$.push(
                this.getLocationsOfSingleType(
                    'united states',
                    GoogleApiLocationType.regions,
                    true,
                ),
            );
        }

        observables$.push(
            this.getLocationsOfSingleType(
                keyword,
                GoogleApiLocationType.cities,
                false,
            ),
        );

        if (this.includeCountries) {
            observables$.push(
                this.getLocationsOfSingleType(
                    keyword,
                    GoogleApiLocationType.regions,
                    true,
                ),
            );
        }

        if (this.includeStates) {
            observables$.push(
                this.getLocationsOfSingleType(
                    keyword,
                    GoogleApiLocationType.regions,
                    false,
                ),
            );
        }

        return forkJoin(observables$).pipe(
            map((geoResults) => this.mergeGeoResults(geoResults)),
            tap((locations) => (this.locations = locations)),
        );
    }

    private getLocationsOfSingleType(
        keyword: string,
        locationType: GoogleApiLocationType,
        displayCountriesOnly: boolean,
    ): Observable<GeoLocation[]> {
        return this.googleApiService.getPlaces(
            (this.previousKeyword = keyword),
            [locationType],
            displayCountriesOnly,
            displayCountriesOnly ? null : this.getRestrictions(),
        );
    }

    private getRestrictions(): ComponentRestrictions {
        return this.usAndCanadaOnly || this.usOnlyFilterSelected
            ? {
                  country: this.usAndCanadaOnly ? usAndCanada : usCountry,
              }
            : null;
    }

    private mergeGeoResults(results: GeoLocation[][]): GeoLocation[] {
        return results.reduce((accumulator, current) => {
            current.forEach((item) => {
                if (
                    !accumulator.some(
                        (currentItem) => currentItem.id === item.id,
                    )
                ) {
                    accumulator.push(item);
                }
            });

            return accumulator;
        }, []);
    }

    addLocation(geoLocation: GeoLocation) {
        if (this.isMultiSelect && this.componentRef) {
            (this.componentRef as ChipsComponent).addValue(geoLocation);
        }
    }
}

export interface SpWysiwygInputParams extends SpControlParams {
    height?: number;
    maxHeight?: number;
    lineHeight?: number;
    editorOptions?: any;
    allowOnlyListItems?: boolean;
    allowOnlyPaste?: boolean;
}

export class SpWysiwygInput extends SpComponentInput {
    height: number;
    maxHeight?: number;
    lineHeight?: number;
    editorOptions: any;
    allowOnlyListItems?: boolean;
    allowOnlyPaste?: boolean;

    constructor(params: SpWysiwygInputParams) {
        super({
            ...(params as SpControlParams),
            component: 'SpWysiwygEditorComponent',
        });

        this.controlType = SpFormControlType.component;
        this.height = params.height || 60;
        this.maxHeight = params.maxHeight;
        this.lineHeight = params.lineHeight;
        this.allowOnlyListItems = params.allowOnlyListItems;
        this.allowOnlyPaste = params.allowOnlyPaste;
        this.editorOptions = params.editorOptions || {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link'],
            ],
            clipboard: {
                matchVisual: false,
            },
        };
    }

    onContentChanged(value: { [key: string]: string }) {
        if (!this.isOptional && value.text.trim() === '') {
            this.formControl.patchValue('');
        } else {
            this._value = value.html;
        }
    }
}

export interface FormControls {
    [key: string]: SpFormControl;
}

export interface SpTemplateEditorInputParams extends SpControlParams {
    height?: number;
    availablePlaceholders: GenericLabelValue<string>[];
    labelAsPlaceholder?: boolean;

    templates$?: Observable<MessageTemplate[]>;
    templatesLoadingError?: string;
    allowAddingPlaceholders?: boolean;
}

export class SpTemplateEditorInput extends SpComponentInput {
    height: number;
    availablePlaceholders: TemplatePlaceholder[];
    labelAsPlaceholder: boolean;

    selectTemplate: EventEmitter<MessageTemplate>;
    templates$?: Observable<MessageTemplate[]>;
    templatesLoadingError?: string;
    allowAddingPlaceholders?: boolean;

    constructor(params: SpTemplateEditorInputParams) {
        super(params);

        this.component = 'TemplateEditorWysiwygComponent';
        this.controlType = SpFormControlType.component;

        this.height = params.height || 60;
        this.availablePlaceholders = params.availablePlaceholders;
        this.selectTemplate = new EventEmitter<MessageTemplate>();
        this.labelAsPlaceholder = !!params.labelAsPlaceholder;
        this.templates$ = params.templates$;
        this.templatesLoadingError = params.templatesLoadingError;
        this.allowAddingPlaceholders = params.allowAddingPlaceholders;
    }

    setValue(value: string) {
        (this.componentRef as TemplateEditorWysiwygComponent).setValue(value);
    }

    onContentChanged(value: string) {
        this.value = value === '\n' ? '' : value;
    }
}

export interface SpIconButtonsGroupParams<T = number> extends SpControlParams {
    items: IconButton<T>[];
    allowToUnSelect?: boolean;
    allowToExpand?: boolean;
    allowToExpandSm?: boolean;
    labelAlignStart?: boolean;
    showVertical?: boolean;
    invalid?: boolean;
    isMultiselect?: boolean;
    styles?: IconButtonStyles;
    iconStyles?: IconButtonStyles;
    checkIconStyles?: IconButtonStyles;
    listStyles?: IconButtonStyles;
    maxSelectedItemsAllowed?: number;
}

export class SpIconButtonsGroupControls<T = number> extends SpFormControl {
    items: IconButton<T>[];
    component: string;
    allowToUnSelect?: boolean;
    allowToExpand?: boolean;
    allowToExpandSm?: boolean;
    labelAlignStart?: boolean;
    showVertical?: boolean;
    isMultiselect?: boolean;
    styles?: IconButtonStyles;
    iconStyles?: IconButtonStyles;
    checkIconStyles?: IconButtonStyles;
    listStyles?: IconButtonStyles;
    maxSelectedItemsAllowed?: number;

    constructor(params: SpIconButtonsGroupParams<T>) {
        super(params);
        this.component = 'IconButtonsGroupComponent';
        this.items = params.items;
        this.allowToUnSelect = params.allowToUnSelect;
        this.allowToExpand = params.allowToExpand;
        this.allowToExpandSm = params.allowToExpandSm;
        this.labelAlignStart = params.labelAlignStart;
        this.showVertical = params.showVertical;
        this.isMultiselect = params.isMultiselect;
        this.styles = params.styles;
        this.iconStyles = params.iconStyles;
        this.checkIconStyles = params.checkIconStyles;
        this.listStyles = params.listStyles;
        this.maxSelectedItemsAllowed = params.maxSelectedItemsAllowed;
    }
}
