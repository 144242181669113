import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import type { StepperStep } from '../../models/stepper/stepper-step';
import { isDefined } from '../../utils';

@Injectable({
    providedIn: 'root',
})
export class StepperService<T> {
    backButton$ = new BehaviorSubject<boolean>(false);
    nextButton$ = new Subject<void>();

    currentStepIndex = 0;
    currentHeaderStepIndex = 0;
    highlightedStepIndex = 0;

    isPendingActionFooterState = false;

    private _steps: StepperStep<T>[] = [];
    private _headerSteps: StepperStep<T>[] = [];
    private _hideSteps = false;

    get steps() {
        return this._steps;
    }

    get headerSteps() {
        return this._headerSteps;
    }

    get hideSteps() {
        return this._hideSteps;
    }

    get hasPreviousStep() {
        return this.currentStepIndex > 0;
    }

    get isLastStep() {
        return this.currentStepIndex + 1 >= this._steps.length;
    }

    get hasNextButton() {
        return this._steps.length > this.currentStepIndex
            ? !this._steps[this.currentStepIndex].hideNextButton
            : false;
    }

    get currentStep(): StepperStep<T> {
        return this.getStep(this.currentStepIndex);
    }

    initSteps(steps: StepperStep<T>[], hideSteps = false) {
        this._steps = [...steps];
        this._headerSteps = [...steps.filter((step) => !step.outOfStepper)];
        this._hideSteps = hideSteps;
    }

    getStep(index: number): StepperStep<T> {
        return this._steps[index];
    }

    getCurrentHeaderStep(): StepperStep<T> {
        return this._headerSteps[this.currentHeaderStepIndex];
    }

    getHeaderStep(index: number): StepperStep<T> {
        return this._headerSteps[index];
    }

    getNextStep(): StepperStep<T> {
        return this.getStep(this.currentStepIndex + 1);
    }

    getPrevStep(): StepperStep<T> {
        return this.getStep(this.currentStepIndex - 1);
    }

    isCurrentlyActiveStep(step: T): boolean {
        return this.getStep(this.currentStepIndex).type === step;
    }

    reset() {
        this._steps = [];
        this._headerSteps = [];
        this.currentStepIndex = 0;
        this.currentHeaderStepIndex = 0;
        this.highlightedStepIndex = 0;
        this.isPendingActionFooterState = false;
    }

    computeCurrentStepIndex(stepType: T) {
        const currentStepIndex = this._steps.findIndex(
            (step) => step.type === stepType,
        );
        this.currentStepIndex = currentStepIndex > -1 ? currentStepIndex : 0;

        const currentHeaderStepIndex = this._headerSteps.findIndex(
            (step) => step.type === stepType,
        );
        this.currentHeaderStepIndex =
            currentHeaderStepIndex > -1 ? currentHeaderStepIndex : 0;
        const currentHeaderStep = this.getCurrentHeaderStep();
        this.highlightedStepIndex = isDefined(currentHeaderStep.parentType)
            ? this._headerSteps.findIndex(
                  (step) => step.type === currentHeaderStep.parentType,
              )
            : this.currentHeaderStepIndex;
    }

    togglePendingActionFooterState(isPendingActionState: boolean) {
        this.isPendingActionFooterState = isPendingActionState;
    }
}
