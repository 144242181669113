import type { AfterViewInit, OnDestroy } from '@angular/core';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import {
    Ng2Dropdown,
    Ng2DropdownButton,
    Ng2DropdownMenu,
} from 'ng2-material-dropdown';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import type { NavigationExtras } from '@angular/router';
import { unsubscribeIfActive } from '../utils';
import { SpDropdownActionsService } from './sp-dropdown-actions.service';
import { DropdownData } from './sp-dropdown.constants';

export interface SpDropDownData {
    text: string;
    itemId?: number;
    icon?: string;
    secondaryIcon?: string;
    routerLink?: string;
    navigationUrl?: string;
    navigationExtras?: NavigationExtras;
    children?: SpDropDownData[];
    class?: string;
    data?: DropdownData;
    preventClose?: boolean;
    userName?: string;
    type?: SpDropDownDataType; // default is item
    tooltipDisabledState?: string;
}

export enum SpDropDownDataType {
    item = 0,
    separator = 1,
    header = 2,
}

@Component({
    selector: 'sp-dropdown',
    templateUrl: './sp-dropdown.component.html',
    styleUrls: ['./sp-dropdown.component.scss'],
})
export class SpDropdownComponent implements OnDestroy, AfterViewInit {
    SpDropDownDataType = SpDropDownDataType;

    currentlyActionedItem?: SpDropDownData;
    lastSelectedOption?: DropdownData;

    private dropDownMenuHeight = 0;
    private dropDownMenuWidth = 0;
    private subscription: Subscription;

    @Input() menuData: SpDropDownData;
    @Input() public appendToBody = false;
    @Input() public showCaret = true;
    @Input() public disabled = false;
    @Input() public offsetLeftAdjust = 0;
    @Input() public offsetTopAdjust = 0;
    @Input() public widthClass = 0;
    @Input() public dropdownMenuClass = '';
    @Input() public set currentOption(option: DropdownData) {
        this.lastSelectedOption = option;
    }

    @Output() menuItemClick: EventEmitter<DropdownData> =
        new EventEmitter<DropdownData>();

    // Required to set the offsets of ng2-dropdown component
    @ViewChild(Ng2Dropdown) dropdown: Ng2Dropdown;
    // The dropdown menu container to calculate offset to align caret and down arrow
    @ViewChild(Ng2DropdownMenu, { read: ElementRef })
    dropdownMenu: ElementRef<HTMLElement>;
    // to calculate the height of dropdown trigger content
    @ViewChild(Ng2DropdownButton, { read: ElementRef })
    btnContent: ElementRef<HTMLElement>;

    // This is shameful workaround. Please see comment to isVisible getter to understand
    // why it had to be added when we migrated from angular-8 -> angular-9
    private isViewInitialized = false;
    private btnClientRect: DOMRect;

    get maxHeightBelow(): number {
        return (
            document.documentElement.clientHeight -
            (this.btnClientRect?.bottom || 0) -
            this.offsetTopAdjust -
            this.btnClientRect?.height / 2
        );
    }

    get maxHeightAbove(): number {
        return (this.btnClientRect?.top || 0) - this.btnClientRect?.height / 2;
    }

    constructor(
        private element: ElementRef<HTMLElement>,
        private renderer: Renderer2,
        private spDropdownActionsService: SpDropdownActionsService,
        private router: Router,
    ) {
        this.subscription = new Subscription();
    }

    ngAfterViewInit(): void {
        // children[0] is the menu list with the options.
        const dne = this.dropdownMenu.nativeElement.children[0];

        // Timeout so the browser has time to compute sizes.
        setTimeout(() => {
            // dne hast opacity=0, display:none 'because of internal ng2 animations triggers
            // activating display, enables browser to compute the width well. FF fix.
            this.renderer.setStyle(dne, 'display', 'block');
            this.dropDownMenuWidth = dne.getBoundingClientRect().width;
            this.isViewInitialized = true;
        }, 300);

        this.watchSpinner();
    }

    onShow(): void {
        this.btnClientRect = this.element.nativeElement.getBoundingClientRect();
        // When dropdown is opened for the first time we can calculate menu height
        if (this.dropDownMenuHeight === 0) {
            setTimeout(() => {
                this.dropDownMenuHeight = (
                    this.dropdownMenu.nativeElement.children[0] as HTMLElement
                ).getBoundingClientRect().height;
            });
        }
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    get displayAbove(): boolean {
        return this.maxHeightBelow < this.maxHeightAbove;
    }

    private watchSpinner(): void {
        this.subscription =
            this.spDropdownActionsService.hideActiveSpinner$.subscribe(() => {
                this.currentlyActionedItem = undefined;
            });
    }

    private getLeftOffset(clientRec: DOMRect): number {
        // 5 : 'cause internal component ng2.. decrements by that value. Idk what for.
        // 10:  distance to match the dropdown arrow, in sync with css .ng2-dropdown-menu:after.right
        return (
            clientRec.left +
            this.offsetLeftAdjust +
            15 +
            clientRec.width -
            this.dropDownMenuWidth
        );
    }

    private getTopOffset(clientRec: DOMRect): number {
        const height = this.displayAbove
            ? this.btnClientRect.top -
              this.dropDownMenuHeight +
              this.offsetTopAdjust
            : clientRec.top +
              this.offsetTopAdjust +
              this.btnClientRect?.height / 2;

        return Math.max(height, 0);
    }

    itemClicked($event): void {
        if (this.currentlyActionedItem) {
            return; // disable clicks when spinner is active
        }

        let value;

        if ($event.value) {
            value = $event.value;
            this.currentlyActionedItem = this.getChildWIthAsyncAction(value);
        } else {
            value = $event;
        }

        this.menuItemClick.emit(value);
    }

    // IMPORTANT NOTE: this method is incorrect
    // This component handles clicks internally and does not fire them at the exact time of event, but
    // instead uses custom event to notify about selected item. Initial click events are being fired as well,
    // but timing is inconsistent. This sometimes leads to unwanted side effects.
    navigate(event, navigationUrl: string, extras: NavigationExtras): void {
        event.stopPropagation();
        if (!navigationUrl) {
            return;
        }

        this.router.navigate([navigationUrl], extras);
    }

    // This component handles clicks internally and does not fire them at the exact time of event, but
    // instead uses custom event to notify about selected item. Initial click events are being fired as well,
    // but timing is inconsistent. This sometimes leads to unwanted side effects.
    onDataActionSelected(event): void {
        event.stopPropagation();
    }

    public getChildWIthAsyncAction(data: DropdownData): SpDropDownData {
        return this.menuData.children.find(
            (m) => m.data === data && m.preventClose === true,
        );
    }

    // IMPORTANT NOTE: this method is INCORRECT
    // This method relies on the API of the ng2-material-dropdown component, in particular it invokes
    // Ng2DropdownMenu::updatePosition() which relies on the state of the Ng2DropdownMenu. In particular,
    // updatePosition() uses 'focusFirstElement' input binding. However, this getter is called BEFORE
    // Ng2DropdownMenu is initialized (prior Ng2DropdownMenu::ngOnInit) and the false value of the
    // 'focusFirstElement' we crucially rely on IS NOT YET SET which, of course, throws terribly
    public get isVisible(): boolean {
        // This is a shameful workaround. Please see the comment to the method to understand why it is needed
        if (!this.isViewInitialized) {
            return false;
        }

        const clientRec = this.element.nativeElement.getBoundingClientRect();

        const height = this.displayAbove
            ? this.maxHeightAbove
            : this.maxHeightBelow;
        (
            this.dropdownMenu.nativeElement.children[0] as HTMLElement
        ).style.maxHeight = `${height}px`;

        this.dropdown.menu.updatePosition(
            {
                top: this.getTopOffset(clientRec),
                left: this.getLeftOffset(clientRec),
                bottom: clientRec.bottom,
                right: clientRec.right,
                width: clientRec.width,
                height: clientRec.height,
            } as ClientRect,
            true,
        );

        return this.dropdown.menu.dropdownState.menuState.isVisible;
    }
}
