import type { OnDestroy, OnInit } from '@angular/core';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SpComponentInput } from '../sp-form-controls.model';
import { getInputFieldIconCssClassForContext } from '../sp-form-control-utils';
import { spControlInputWrapperMarker } from '../sp-form-input.component';

@Component({
    selector: 'sp-single-value-autocomplete',
    templateUrl: './single-value-autocomplete.component.html',
    styleUrls: ['./single-value-autocomplete.component.scss'],
})
export class SingleValueAutocompleteComponent implements OnInit, OnDestroy {
    readonly spControlInputWrapperMarker = spControlInputWrapperMarker;

    @Input() control: SpComponentInput;

    @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>;

    ngOnInit(): void {
        this.watchValueChangeFromOutside();
    }

    watchValueChangeFromOutside(): void {
        if (!this.control.autocomplete.setValueFromOutside$) {
            return;
        }

        this.control.autocomplete.setValueFromOutside$.subscribe((value) => {
            this.control.formControl.setValue(value);
            this.inputField.nativeElement.value =
                value[this.control.autocomplete.displayPropertyName];
        });
    }

    get domainLogoSize(): number {
        // if icon is clearbit url image then 24 else if fallback then 32
        return this.control.fallbackLogo !== this.control.icon ? 24 : 32;
    }

    get inputFieldIconCssClasses() {
        const cssClasses = {};

        if (this.control.formControl.valid && this.control.formControl.value) {
            Object.assign(
                cssClasses,
                getInputFieldIconCssClassForContext('check'),
            );
        } else if (this.control.icon) {
            let context = '';
            if (
                this.control.formControl.invalid &&
                (this.control.formControl.dirty ||
                    this.control.formControl.touched)
            ) {
                context = 'danger';
            }
            Object.assign(
                cssClasses,
                getInputFieldIconCssClassForContext(this.control.icon, context),
            );
        }

        return cssClasses;
    }

    get v2AutocompleteParams() {
        return Object.assign(this.control.autocomplete, {
            formControl: this.control.formControl,
            valueChangedHandler: (value) => {
                this.control.formControl.setValue(value);
                this.control.valueChange.emit(value);
            },
        });
    }

    ngOnDestroy() {
        this.control?.onDestroyFn();
    }
}
