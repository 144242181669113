import type { PopupTooltip } from '../steps/shared/popup-tooltip/popup-tooltip.model';

export const leadershipsTitle = 'Leadership';
export const leadershipsLogo = '/images/icons/forms/leadership.svg';
export const clubsTitle = 'Clubs / Associations';
export const clubsLogo = '/images/icons/forms/shield_award.svg';
export const volunteeringTitle = 'Volunteering';
export const volunteeringLogo = '/images/icons/forms/volunteering.svg';
export const publicationsTitle = 'Publications';
export const publicationsLogo = '/images/icons/forms/book.svg';
export const awardsTitle = 'Awards';
export const awardsLogo = '/images/icons/forms/trophy.svg';
export const coursesTitle = 'Courses';
export const coursesLogo = '/images/icons/forms/education.svg';
export const othersTitle = 'Other';
export const othersLogo = '/images/icons/forms/chess.svg';

// Changing value of enum is not recommended.
// In case it get changed please update appropriate class name in places where it is used.
export enum CandidateOnboardingControls {
    // profile info
    resumeUpload = 'resume-upload',
    candidateEmail = 'candidate-email',
    candidatePhoneNumber = 'candidate-phone-number',
    candidateLocation = 'candidate-location',
    candidateRelocation = 'candidate-relocation',
    candidateRemoteOnly = 'candidate-remote-only',
    candidateVisaSponsorship = 'candidate-visa-sponsorship',
    candidateWebsite = 'candidate-website',
    candidateLinkedin = 'candidate-linkedin',
    candidateSummary = 'candidate-summary',
    // work experience
    workLogo = 'work-logo',
    workTitle = 'work-title',
    workDepartment = 'work-department',
    workIndustry = 'work-industry',
    workResponsibilities = 'work-responsibilities',
    // education
    educationLogo = 'education-logo',
    educationDate = 'education-date',
    educationFieldOfStudy = 'education-field-of-study',
    // accomplishments
    certificateName = 'certificate-name',
    certificateDate = 'certificate-date',
    leadership = 'leadership',
    language = 'language',
    clubs = 'clubs',
    volunteering = 'volunteering',
    publication = 'publication',
    award = 'award',
    course = 'course',
    other = 'other',
    // skills
    topSkills = 'skills',
    familiarSkills = 'familiarSkills',
    // demographic
    demographicGender = 'demographic-gender',
    demographicRace = 'demographic-race',
    // preferences
    preferenceCompensation = 'preference-compensation',
    preferencePriorities = 'preference-priorities',
    preferredLocation = 'preferred-location',
}

// TODO update key to be of type CandidateOnboardingControls
export const CandidateOnboardingControlsTooltip: {
    [key in string]: PopupTooltip;
} = {
    // Personal Info Step
    [CandidateOnboardingControls.candidateSummary]: {
        id: CandidateOnboardingControls.candidateSummary,
        title: 'Summary',
        description:
            `<p>Please summarize your work experience in 2-3 sentences.</p>` +
            `This is shown to all employers on SquarePeg - so don’t mention any specific job or employer.`,
    },
    // WorkExperience Step
    [CandidateOnboardingControls.workIndustry]: {
        id: CandidateOnboardingControls.workIndustry,
        title: 'Industry',
        description:
            `<p>Only include industries relevant to this specific job.</p>` +
            `SquarePeg uses your industry experience (along with other data) to match you with jobs.`,
    },
    // Skills Step
    [CandidateOnboardingControls.topSkills]: {
        id: CandidateOnboardingControls.topSkills,
        title: 'Skills',
        description:
            `<p>Enter your five strongest skills as primary and all remaining skills as secondary.` +
            ` Be specific - these should be a defined tool, process, or coding language.</p>`,
    },
    // Preferences Step
    [CandidateOnboardingControls.preferenceCompensation]: {
        id: CandidateOnboardingControls.preferenceCompensation,
        title: 'Desired Salary',
        description:
            `<p>Enter a range between your lowest acceptable salary and your target salary. ` +
            `This range should not include bonuses or commissions.</p>` +
            `SquarePeg uses your desired salary (along with other data) to match you with jobs.`,
    },
    [CandidateOnboardingControls.preferredLocation]: {
        id: CandidateOnboardingControls.preferredLocation,
        title: 'Preferred Location',
        description:
            `<p>On-site jobs require you to work from an office. Hybrid jobs allow split time` +
            ` between office and home. Remote jobs never require you to work from an office.</p>` +
            `SquarePeg uses your preferred location (along with other data) to match you with jobs.`,
    },
};
