import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { TokenType } from '../shared/models/token-type.enum';
import type { TokenVerificationResponse } from '../auth/model/token-verification-response.model';
import type { TokenRequest } from '../auth/model/token-request.model';

@Injectable()
export class TokenService {
    constructor(private http: HttpClient) {}

    sendTokenToEmail(tokenType: TokenType, request: TokenRequest) {
        return this.http.post(`/auth/tokens/${tokenType}`, request);
    }

    verifyToken(token: string): Observable<TokenVerificationResponse> {
        return this.http.patch<TokenVerificationResponse>(
            `/auth/tokens/verify/${token}`,
            token,
        );
    }
}
