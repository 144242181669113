import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'sp-attachment-in-textarea',
    templateUrl: './attachment-in-textarea.component.html',
    styleUrls: ['./attachment-in-textarea.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentInTextareaComponent {
    @Input() selectedFileName: string;
    @Input() fileSizeLabel: string;
    @Input() removable = true;

    @Output() resetAttachment = new EventEmitter<void>();

    removeAttachment() {
        this.resetAttachment.emit();
    }
}
