<img src="../../../../images/modals/pause-sourcing/wash-right.svg" class="wash top-right" />
<a href="javascript:" class="close-button" (click)="onClose()"></a>
<img src="images/modals/pause-sourcing/pause-sourcing.png" class="pause-sourcing-graphics" />
<h1 class="header">{{ header }}</h1>
<p class="informational-text">{{ infoText }}</p>
<sp-stateful-button
    class="primary-button"
    [label]="buttonLabel"
    [isLoading]="isActionInProgress"
    (buttonClick)="onPauseSourcing()"
>
</sp-stateful-button>
<a href="javascript:" class="secondary-button" (click)="onClose()">Cancel</a>
<img src="../../../../images/modals/pause-sourcing/wash-left.svg" class="wash bottom-left" />
