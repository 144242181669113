import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import ComponentRestrictions = google.maps.places.ComponentRestrictions;

export type GeoLocation = {
    dbId?: number;
    id: string; // placeId
    value: string;
    isCountry?: boolean;
};

export enum GoogleApiLocationType {
    country = 'country',
    regions = '(regions)',
    cities = '(cities)',
}

@Injectable()
export class GoogleApiService {
    private autocompleteService: google.maps.places.AutocompleteService;

    constructor() {
        this.autocompleteService = new google.maps.places.AutocompleteService();
    }

    getPlaces(
        keyword: any,
        locationTypes: GoogleApiLocationType[],
        displayCountriesOnly: boolean,
        componentRestrictions: ComponentRestrictions = null,
    ): Observable<GeoLocation[]> {
        return new Observable((observer) => {
            const options = {
                input: keyword,
                types: locationTypes,
                componentRestrictions,
            };

            this.autocompleteService.getPlacePredictions(
                options,
                (predictions, status) => {
                    if (status === 'OK') {
                        const locations = [];
                        if (displayCountriesOnly) {
                            predictions.forEach((prediction) => {
                                if (
                                    prediction.types.indexOf(
                                        GoogleApiLocationType.country,
                                    ) > -1
                                ) {
                                    locations.push({
                                        id: prediction.place_id,
                                        value: prediction.description,
                                        isCountry: true,
                                        // value object is set to FormControl value by autocomplete lib.
                                        // this field should be kept as a determination for location
                                        data: {
                                            googlePlaceId: prediction.place_id,
                                        },
                                    });
                                }
                            });
                        } else {
                            predictions.forEach((prediction) => {
                                locations.push({
                                    id: prediction.place_id,
                                    value: prediction.description,
                                    // value object is set to FormControl value by autocomplete lib.
                                    // this field should be kept as a determination for location
                                    data: {
                                        googlePlaceId: prediction.place_id,
                                    },
                                });
                            });
                        }
                        observer.next(locations);
                    } else if (status === 'ZERO_RESULTS') {
                        observer.next([]);
                    } else {
                        observer.error(status);
                    }
                    observer.complete();
                },
            );
        });
    }
}
