import { Injectable } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DocumentEventsProxyService {
    documentClick$ = new Subject<MouseEvent>();

    constructor() {
        fromEvent<MouseEvent>(document, 'click').subscribe((event) =>
            this.documentClick$.next(event),
        );
    }
}
