<quill-editor
    class="wysiwyg-editor"
    [styles]="{ height: control.height + 'px' }"
    [class.with-top-border-radius]="true"
    [placeholder]="control.placeholder"
    [formControl]="formControl"
    [readOnly]="control.disabled"
    [bounds]="'self'"
    (onEditorCreated)="onEditorCreated($event)"
    (onContentChanged)="onContentChanged($event)"
    theme="snow"
>
    <div quill-editor-toolbar class="sp-quill-placeholders-toolbar">
        <ng-container *ngIf="control.templates$">
            <a
                *ngIf="!isLoadingTemplates; else toolbarButtonSpinner"
                href="javascript:"
                class="ql-select-template-toolbar-button sp-ql-toolbar-button"
                [class.is-disabled]="!!templatesLoadingError"
                spTooltip
                [tooltipText]="templatesLoadingError"
                (click)="onSelectTemplateButtonClick($event)"
            >
            </a>
        </ng-container>
        <a
                *ngIf="control.allowAddingPlaceholders"
                href="javascript:"
                class="ql-add-placeholder-toolbar-button sp-ql-toolbar-button"
                (click)="onInsertFieldButtonClick($event)"
        >
            {{ '{ }' }}
        </a>
        <div *ngIf="control.templates$ || control.allowAddingPlaceholders" class="separator"></div>
        <a href="javascript:"
           spTooltip
           tooltipText="{{linkButtonTooltip}}"
           class=" sp-ql-toolbar-button add-link"
           (click)="onInsertLinkButtonClick($event)">
        </a>
        <div class="separator"></div>
    </div>
</quill-editor>

<ng-template #toolbarButtonSpinner>
    <img src="/images/spinner.svg" class="sp-dots-spinner sp-ql-toolbar-button-spinner" />
</ng-template>
