import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AutosaveProfileService {
    constructor(private http: HttpClient) {}

    autosaveProfile(userId: number, profile: any): Observable<void> {
        return this.http.patch<any>(
            `/api/users/${userId}/profile/autosave`,
            profile,
        );
    }
}
