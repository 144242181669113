<sp-form-input
    [control]="chipsControlTop"
    (mouseenter)="onMouseEnter($event, chipsControlTop.name)"
    class="top-control {{ chipsControlTop.name }}"
>
</sp-form-input>

<sp-form-input
    [control]="chipsControlBottom"
    (mouseenter)="onMouseEnter($event, chipsControlBottom.name)"
    class="{{ chipsControlBottom.name }}"
>
</sp-form-input>
