import type { OnDestroy, OnInit } from '@angular/core';
import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { TooltipDirective } from '../../tooltip/tooltip.directive';
import { invalidFormFieldCssClass } from '../../../core/config.service';
import type { ControlFocusEventData } from '../../models/control-focus-event-data';
import { TooltipWidthType } from '../../tooltip/tooltip.component';
import {
    SpFormControl,
    SpFormControlType,
    SpPasswordInput,
} from './sp-form-controls.model';
import { getInputFieldIconCssClassForContext } from './sp-form-control-utils';
import { SpChipsInputControl } from './chips/model';

export const spControlInputWrapperMarker = 'input-wrapper-marker';

@Component({
    selector: 'sp-form-input',
    templateUrl: './sp-form-input.component.html',
    styleUrls: ['./sp-form-input.component.scss'],
})
export class SpFormInputComponent implements OnInit, OnDestroy {
    readonly SpFormControlType = SpFormControlType;
    readonly spControlInputWrapperMarker = spControlInputWrapperMarker;
    readonly TooltipWidthType = TooltipWidthType;

    // used to store original placeholder value of the auto-complete input when input gets
    // focused and needs to be cleared
    private placeholder: string;
    aiButtonInProgress: boolean;

    @ViewChild(TooltipDirective) fieldTooltipDirective: TooltipDirective;

    @Input()
    public control: SpFormControl;

    @Input()
    public spAddBodyClassOnFocus = 'input-focus';

    @Output()
    public autocompleteNoMatchFoundAction = new EventEmitter();

    @Output()
    public chipsRemoved = new EventEmitter();

    @Output()
    public mouseEnter = new EventEmitter<ControlFocusEventData>();

    @Output()
    public closed = new EventEmitter();

    /**
     *  Keep in mind that #spFormInputChildComponent template reference must
     *  be added on each child component that needs to be referenced
     *  via sp-form-input
     */
    @ViewChild('spFormInputChildComponent') spFormInputChildComponent;

    @HostBinding(`class.${invalidFormFieldCssClass}`)
    get isFieldValueInvalid() {
        return (
            this.control &&
            this.control.formControl &&
            this.control.formControl.invalid
        );
    }

    get inputFieldHasValue() {
        return this.control.value?.toString().length > 0;
    }

    get inputFieldIconCssClasses() {
        const cssClasses = {};

        if (this.control.formControl.valid && this.control.formControl.value) {
            cssClasses['sp-input-icon__check'] = true;
        } else if (this.control.icon) {
            Object.assign(
                cssClasses,
                this.getContextColoredIconCssClass(this.control.icon),
            );
        }

        return cssClasses;
    }

    get isInputControl() {
        return (
            this.control.controlType === SpFormControlType.input ||
            this.control.controlType ===
                SpFormControlType.validatedPasswordInput
        );
    }

    get isAllowPasswordUnmask() {
        return (
            this.control instanceof SpPasswordInput &&
            this.control.allowPasswordUnmask
        );
    }

    get showErrorAbove(): boolean {
        return (
            this.control.error && !this.control.showValidationErrorBelowField
        );
    }

    @HostListener('focusin', ['$event'])
    onFocusin() {
        if (this.control.autocomplete) {
            this.placeholder = this.control.placeholder;
            this.control.placeholder = '';
        }

        return (this.control.focused = true);
    }

    @HostListener('focusout', ['$event'])
    onFocusOut() {
        this.control.formControl.markAsDirty();
        if (this.control.autocomplete) {
            this.control.placeholder = this.placeholder;
        }

        return (this.control.focused = false);
    }

    ngOnInit(): void {
        // needed for cases when we trying to reinit control that uses autocomplete
        // fixes autocomplete lib attaching event listeners to an old input
        if (this.control.autocomplete) {
            this.control.autocomplete.inputElement = null;
        }
    }

    ngOnDestroy(): void {
        this.control?.onDestroyFn();
    }

    onChipsRemoved(event) {
        this.chipsRemoved.emit(event);
    }

    getContextColoredIconCssClass(icon: string): { [name: string]: boolean } {
        const context =
            this.control.formControl.invalid && this.control.formControl.touched
                ? 'danger'
                : '';

        return getInputFieldIconCssClassForContext(icon, context);
    }

    // remove tooltip on sm devices on scroll
    onScroll() {
        if (this.fieldTooltipDirective) {
            this.fieldTooltipDirective.hide(true);
        }
    }

    onShowHidePasswordClick() {
        (this.control as SpPasswordInput).showHidePassword();
    }

    onMouseEnter(event: ControlFocusEventData) {
        this.mouseEnter.emit(event);
    }

    onClose() {
        this.closed.emit();
    }

    aiButtonAction() {
        if (this.aiButtonInProgress) {
            return;
        }

        this.aiButtonInProgress = true;
        this.control.disabled = true;
        this.control.spellGenerateButton
            .func(this.control.value)
            .pipe(
                finalize(() => {
                    this.aiButtonInProgress = false;
                    this.control.disabled = false;
                }),
            )
            .subscribe(
                (values) => {
                    this.aiButtonInProgress = false;
                    if (this.control instanceof SpChipsInputControl) {
                        this.control.setValue$.next([
                            ...this.control.value,
                            ...values,
                        ]);
                    }
                },
                () => (this.aiButtonInProgress = false),
            );
    }
}
