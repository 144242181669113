import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { spDialogData } from '../dialog/dialog.service';

@Component({
    selector: 'sp-full-screen-loading-indicator',
    templateUrl: './full-screen-loading-indicator.component.html',
    styleUrls: ['./full-screen-loading-indicator.component.scss'],
})
export class FullScreenLoadingIndicatorComponent implements OnInit {
    currentMessageIndex = 0;
    delayInMs = 6000;
    currentMessage: string;
    rotatingMessages: string[] = [];

    constructor(@Inject(spDialogData) dialogData) {
        this.rotatingMessages =
            dialogData.contentComponentData.rotatingMessages;
        this.delayInMs = dialogData.contentComponentData.delayInMs;
    }

    ngOnInit() {
        if (this.rotatingMessages.length) {
            this.currentMessage = this.rotatingMessages[0];
            this.startMessagesRotation();
        }
    }

    private startMessagesRotation(): void {
        setInterval(() => {
            this.currentMessageIndex =
                (this.currentMessageIndex + 1) % this.rotatingMessages.length;
            this.currentMessage =
                this.rotatingMessages[this.currentMessageIndex];
        }, this.delayInMs);
    }
}
