import type { OverlayRef } from '@angular/cdk/overlay';
import { GlobalPositionStrategy, Overlay } from '@angular/cdk/overlay';
import type { ComponentType } from '@angular/cdk/portal';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { DialogRef } from '../dialog-ref';
import type { IHeaderButton } from '../panel/model/header-button.interface';
import { addCdkOverlayClass, isDefined } from '../utils';
import { dialogOverlayCssClass } from '../constants';
import type { DialogFooterButton } from './dialog-footer-button';
import type { DialogTitle } from './model/dialog-title.model';

export const spDialogData = new InjectionToken<any>('SpDialog');

const defaultDialogConfig = {
    width: '600px',
    hasBackdrop: true,
    backdropClass: 'sp-dialog-backdrop',
    panelClass: 'sp-dialog-panel',
    maxHeight: '90vh',
    maxWidth: '95vw',
};

const smScreenDialogOverrides = {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    panelClasses: ['sp-dialog-panel-marginless'],
};

export interface DialogParams<T> {
    title?: string | DialogTitle;
    titleAlign?: 'left' | 'center' | 'right';
    headerless?: boolean;
    hideHeaderBottomBorder?: boolean;
    hideFooterBottomBorder?: boolean;
    colorContext?: string;
    component?: ComponentType<any>;
    contentComponentData?: { [key: string]: any };
    footer?: string;

    dialogRef?: DialogRef<T>;
    width?: string;
    height?: string;
    top?: string;
    bottom?: string;
    right?: string;
    hasBackdrop?: boolean;
    backdropClass?: string;
    panelClass?: string;
    // additional panel classes to co-exist with panelClass classes; if you need to override
    // default panel class you should specify panelClass property instead
    panelClasses?: string[];
    maxHeight?: string;
    maxWidth?: string;
    usePlain?: boolean;
    contentView?: boolean;
    headerButtons?: IHeaderButton[];
    headerImage?: string;
    dontShowAgainLabel?: string;
    infoMessage?: string;
    headerLogo?: string;
    headerClass?: string;
    visibleContentOutsideModal?: boolean;

    primaryButton?: DialogFooterButton;
    secondaryButton?: DialogFooterButton;
    alternativeButton?: DialogFooterButton;
    allowWithinModalClickEventPropagation?: boolean;
    stopPropagationOnOutsideClick?: boolean;

    // to get rid of verbose smScreenDialogConfigOverrides props. In progress.
    supportsSmallScreen?: boolean;
    isSmallScreen?: boolean;
    // There is a css trick that allows 'position: fixed' used within modal to be relative to modal, not body.
    // This allows to bypass it.
    containFixedPosition?: boolean;
}

@Injectable()
export class DialogService {
    constructor(
        private overlay: Overlay,
        private injector: Injector,
    ) {}

    open<T>(
        componentType: ComponentType<T>,
        data?: DialogParams<T>,
    ): DialogRef<T> {
        data = data || {};
        data = { ...defaultDialogConfig, ...data };
        if (data.supportsSmallScreen && data.isSmallScreen) {
            data = { ...data, ...smScreenDialogOverrides };
        }

        let positionStrategy =
            new GlobalPositionStrategy().centerHorizontally();

        if (data.top || data.bottom) {
            if (data.top) {
                positionStrategy = positionStrategy.top(data.top);
            }

            if (data.bottom) {
                positionStrategy = positionStrategy.bottom(data.bottom);
            }
        } else {
            positionStrategy = positionStrategy.centerVertically();

            if (data.right) {
                positionStrategy = positionStrategy.right(data.right);
            }
        }

        const overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            hasBackdrop: isDefined(data.hasBackdrop)
                ? data.hasBackdrop
                : defaultDialogConfig.hasBackdrop,
            backdropClass: data.backdropClass,
            panelClass: [
                data.panelClass ?? '',
                ...(data.panelClasses ? data.panelClasses : []),
            ],
            maxHeight: data.maxHeight,
            maxWidth: data.maxWidth,
            width: data.width,
            height: data.height,
        });

        addCdkOverlayClass(dialogOverlayCssClass);

        return this.attachDialogComponent(componentType, overlayRef, data);
    }

    private attachDialogComponent<T>(
        componentType: ComponentType<T>,
        overlayRef: OverlayRef,
        data?: DialogParams<T>,
    ): DialogRef<T> {
        const dialogRef = new DialogRef<T>(overlayRef);
        const injector = this.createInjector({
            ...data,
            ...{ dialogRef },
        });
        const modalPortal = new ComponentPortal<T>(
            componentType,
            undefined,
            injector,
        );
        overlayRef.attach(modalPortal);

        return dialogRef;
    }

    private createInjector(data: any): Injector {
        data = data || {};

        const tokens = new WeakMap();
        tokens.set(spDialogData, data);

        return new PortalInjector(this.injector, tokens);
    }
}
