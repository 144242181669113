import type { OnInit } from '@angular/core';
import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { LabelType } from '@angular-slider/ngx-slider';
import { getInputFieldIconCssClassForContext } from '../sp-form-control-utils';
import { getMaskInlineCss, isDefined } from '../../../utils';
import { TooltipWidthType } from '../../../tooltip/tooltip.component';
import { SpSliderBulletSize, SpSliderControl } from './model';

@Component({
    selector: 'sp-slider-input',
    templateUrl: './sp-slider.component.html',
    styleUrls: ['./sp-slider.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SpSliderComponent implements OnInit {
    readonly TooltipWidthType = TooltipWidthType;

    @Input()
    public control: SpSliderControl;

    @Output()
    public closed = new EventEmitter();

    @HostBinding('class.v2-slider') v2Design = true;

    private _lowValue: number;
    private _highValue: number;
    showResetButton: boolean;

    get lowValue() {
        return this._lowValue !== 0 &&
            this.control.sliderOptions &&
            this.control.sliderOptions.translate
            ? this.control.sliderOptions.translate(
                  this._lowValue,
                  LabelType.Low,
              )
            : this._lowValue;
    }

    get highValue() {
        return this.control.sliderOptions &&
            this.control.sliderOptions.translate
            ? this.control.sliderOptions.translate(
                  this._highValue,
                  LabelType.High,
              )
            : this._highValue;
    }

    get value() {
        return this._lowValue !== undefined && this._highValue !== undefined
            ? this.lowValue + ' - ' + this.highValue
            : this.lowValue;
    }

    @HostBinding('class.color-scheme-light')
    get colorSchemeLight() {
        return this.control.colorScheme === 'light';
    }

    @HostBinding('class.color-scheme-gradient')
    get colorSchemeGradient() {
        return this.control.colorScheme === 'gradient';
    }

    @HostBinding('class.invalid')
    get isInvalid() {
        return (
            this.control.formControl.touched &&
            !!this.control.formControl.errors
        );
    }

    get percentClass(): { [name: string]: boolean } {
        // note: we have to map classes like color-scheme-gradient-62.5 to color-scheme-gradient-62_5
        return {
            [`color-scheme-gradient-${mapStepValueToString(
                this.control.lowValue,
            )}`]: true,
        };
    }

    get valueColor(): { [name: string]: boolean } {
        return {
            [`value-color-scheme-gradient-${this.control.lowValue}`]:
                this.control.colorScheme === 'gradient' ||
                this.control.colorScheme === 'default',
        };
    }

    get iconCssClass() {
        return this.control.icon
            ? getInputFieldIconCssClassForContext(
                  this.control.icon,
                  this.isInvalid ? 'danger' : '',
              )
            : {};
    }

    get maskIconCss() {
        return this.control.iconUrl && this.control.iconColor
            ? getMaskInlineCss(
                  this.control.iconColor,
                  this.control.iconUrl,
                  'auto 16px',
              )
            : {};
    }

    get isSmallBullet() {
        return (
            this.control && this.control.bulletSize === SpSliderBulletSize.small
        );
    }

    get gradientStepColor(): string {
        return this.control.gradientStepColors[
            mapStepValueToString(this.control.lowValue)
        ];
    }

    ngOnInit(): void {
        this._lowValue = this.control.lowValue as number;
        if (this.control.highValue !== undefined) {
            this._highValue = this.control.highValue as number;
        }

        this.showResetButton =
            isDefined(this.control.defaultValue) &&
            this.control.defaultValue !== this.control.value.value;

        this.watchValueChangeFromOutside();
    }

    onValueChange(value: number) {
        this._lowValue = value;

        // backward compatibility
        this.control.onValueChange(value);

        this.showResetButton =
            isDefined(this.control.defaultValue) &&
            this.control.defaultValue !== this.control.value.value;
    }

    onHighValueChange(value: number) {
        this._highValue = value;

        // backward compatibility
        this.control.onHighValueChange(value);
    }

    onResetValueClicked() {
        this.onValueChange(this.control.defaultValue);
    }

    onClose() {
        this.closed.emit();
    }

    private watchValueChangeFromOutside() {
        if (!this.control.setValue$) {
            return;
        }

        this.control.setValue$.subscribe((value) => {
            this.onValueChange(value.lowValue);
            this.onHighValueChange(value.highValue);
        });
    }
}

function mapStepValueToString(stepValue: number | string | object): string {
    return `${stepValue}`.replace('.', '_');
}
