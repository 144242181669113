import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
    public hasEntry(key: string) {
        return !!window.localStorage.getItem(key);
    }

    public getEntry(key: string) {
        return this.hasEntry(key)
            ? JSON.parse(window.localStorage.getItem(key))
            : null;
    }

    public getItem(key: string) {
        return window.localStorage.getItem(key);
    }

    public getEntryByType<T>(key: string): T {
        return this.hasEntry(key)
            ? (JSON.parse(window.localStorage.getItem(key)) as T)
            : null;
    }

    public storeEntry(key: string, value: any) {
        const entry = this.getEntry(key) || {};
        Object.assign(entry, value);
        window.localStorage.setItem(key, JSON.stringify(entry));
    }

    public storeItem(key: string, value: any) {
        window.localStorage.setItem(key, value);
    }

    public removeEntry(key: string) {
        window.localStorage.removeItem(key);
    }

    public storeChildEntry(rootKey: string, childKey: string, value: any) {
        const root = this.getEntry(rootKey) || {};
        root[childKey] = value;
        this.storeEntry(rootKey, root);
    }

    public removeChildEntry(key: string, childKey: string) {
        const root = this.getEntry(key) || {};
        if (root && childKey in root) {
            delete root[childKey];
            this.removeEntry(key);
            this.storeEntry(key, root);
        }
    }

    public getSessionEntry(key: string) {
        return window.sessionStorage.getItem(key);
    }

    public storeSessionEntry(key: string, value: any) {
        window.sessionStorage.setItem(key, value);
    }

    public removeSessionEntry(key) {
        window.sessionStorage.removeItem(key);
    }

    public hasSessionEntry(key: string) {
        return !!window.sessionStorage.getItem(key);
    }
}
