<ng-container [ngSwitch]="menuItem.type">
    <li *ngSwitchCase="'dropdown'" routerLinkActive="nav-item-active" class="nav-item nav-dropdown">
        <sp-status-light
            *ngIf="menuItem.withStatusLight"
            class="status-light"
            [color]="menuItem.statusLightActive ? '#32c6ba' : '#ffc600'"
            [tooltip]="menuItem.statusLightTooltip"
        ></sp-status-light>
        <sp-dropdown
            [widthClass]="20"
            [offsetTopAdjust]="20"
            [offsetLeftAdjust]="getOffsetLeft(menuItem)"
            [menuData]="dropDownData"
            [showCaret]="!menuItem.hideCaret"
            (menuItemClick)="onMenuItemClicked($event)"
        >
            <span
                *ngIf="menuItem.icon && !(iconAsPicture || menuItem.userName)"
                class="nav-icon nav-icon-{{ menuItem.icon }}"
            ></span>
            <sp-avatar
                *ngIf="iconAsPicture || menuItem.userName"
                class="image-container"
                [className]="'size-32'"
                [avatar]="menuItem.icon"
                [userName]="menuItem.userName"
            ></sp-avatar>
            <span class="label">{{ menuItem.label }}</span>
            <ng-container *ngTemplateOutlet="newIndicator"></ng-container>
        </sp-dropdown>
    </li>

    <li *ngSwitchCase="'separator'" class="nav-item separator"><span></span></li>

    <li *ngSwitchCase="MenuItemType.dropdownHeader" class="nav-item header">
        <sp-avatar
            *ngIf="iconAsPicture || menuItem.userName"
            class="image-container"
            [className]="'size-32 middle'"
            [avatar]="menuItem.icon"
            [userName]="menuItem.userName"
        ></sp-avatar>
        <span class="label">{{ menuItem.label }}</span>
    </li>
    <li
        *ngSwitchDefault
        class="nav-item"
        [routerLinkActive]="[routerLinkActive]"
        [class.nav-item-active]="isActive"
        [class.nav-item-disabled]="menuItem.disabled"
        [class.with-attention-indicator]="menuItem.withAttentionIndicator"
    >
        <a
            href="javascript:"
            (click)="onMenuItemClicked($event)"
            [routerLink]="itemRoute"
            title="{{ menuItem.hoverText }}"
            class="nav-link"
        >
            <span class="nav-icon nav-icon-{{ menuItem.icon }}"></span>
            <span class="label">{{ menuItem.label }}</span>
            <ng-container *ngTemplateOutlet="newIndicator"></ng-container>
            <span [class.with-notification-indicator]="menuItem.hasNotificationIcon"></span>
        </a>
    </li>
</ng-container>

<ng-template #newIndicator>
    <span *ngIf="menuItem.isNewElement" class="new-status-banner">NEW!</span>
</ng-template>
