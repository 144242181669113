import type { OnInit } from '@angular/core';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import type {
    UploadFile,
    UploadInput,
    UploadOutput,
    UploaderOptions,
} from 'ngx-uploader';
import { keyCodeCtrlEnter, keyCodeEnter } from 'app/core/config.service';
import { fileSizeWithUnits, isDefined } from '../../utils';
import { WysiwygEditorWithMentionsComponent } from '../wysiwyg-editor-with-mentions/wysiwyg-editor-with-mentions.component';
import {
    emptyFileWarning,
    fileSizeTooBigWarning,
    maxFileSizeInBytes,
    oneAttachmentWarning,
} from '../../constants';
import { MessageWithMedia } from '../message-with-media.model';
import type { DropdownItem, User } from '../../models';
import { ChatType } from '../../messaging-chat/model';
import { CommentTextareaService } from './comment-textarea.service';

const defaultOptions: UploaderOptions = {
    concurrency: 0,
};

export const commentAttachmentButtonId = 'commentAttachmentButtonId';

@Component({
    selector: 'sp-comment-textarea[entityId]',
    templateUrl: './comment-textarea.component.html',
    styleUrls: ['./comment-textarea.component.scss'],
    providers: [CommentTextareaService],
})
export class CommentTextareaComponent implements OnInit {
    private _enabled = false;
    readonly attachmentWarning = oneAttachmentWarning;
    readonly commentAttachmentButtonId = commentAttachmentButtonId;
    readonly placeholder = `You can tag someone using '@'`;

    get fileIsAttached() {
        return isDefined(this.selectedFile) || this.isUploadedSelectedFile;
    }

    get enabled() {
        return this._enabled;
    }

    @Input()
    set enabled(value) {
        this._enabled = value;
        if (this.form) {
            value ? this.form.enable() : this.form.disable();
        }
    }

    @Input()
    set availableMentions(items: User[]) {
        this.availableDropdownItems = items
            ? this.commentTextareaService.mapUsersToDropdownItems(items)
            : [];
    }

    @Input() isLoading = false;
    @Input() chatType = ChatType.commentsMatch;
    @Input() entityId!: number;

    @Output() submitMessage = new EventEmitter<MessageWithMedia>();
    @Output() errorMessage = new EventEmitter<string>();

    @ViewChild('editorWithMentions', { static: true })
    editorWithMentions!: WysiwygEditorWithMentionsComponent;

    form: FormGroup;

    options: UploaderOptions;
    uploadInput: EventEmitter<UploadInput>;

    selectedFile?: UploadFile;
    selectedFileName?: string;
    fileSizeLabel?: string;

    isUploadedSelectedFile = false;
    availableDropdownItems: DropdownItem[] = [];

    constructor(private commentTextareaService: CommentTextareaService) {
        this.uploadInput = new EventEmitter<UploadInput>();

        this.form = new FormGroup({
            message: new FormControl(''),
            attachment: new FormControl(''),
        });
        this.options = { ...defaultOptions };
    }

    ngOnInit() {
        if (!this._enabled) {
            this.form.disable();
        }
    }

    submitComment() {
        let attachmentData: FormData | undefined;
        if (this.selectedFile?.nativeFile) {
            attachmentData = new FormData();
            attachmentData.append(
                this.selectedFile.type,
                this.selectedFile.nativeFile,
            );
        }
        const attributes = {
            chatType: this.chatType,
            entityId: this.entityId,
        };
        const content = this.editorWithMentions.getHtmlContent();
        const mentionsData = this.commentTextareaService.getMentions(
            this.availableDropdownItems,
            content,
        );
        if (mentionsData.ids.size > 0 || mentionsData.keywords.size > 0) {
            Object.assign(attributes, {
                mentionedUserIds: Array.from(mentionsData.ids),
                mentionedKeywords: Array.from(mentionsData.keywords),
            });
        }
        this.submitMessage.emit(
            new MessageWithMedia(content, attachmentData, attributes),
        );
    }

    resetMessageForm() {
        this.selectedFile = undefined;
        this.isUploadedSelectedFile = false;
        this.form.reset();
        this.editorWithMentions.setValue('');
    }

    resetAttachment() {
        this.selectedFile = undefined;
        this.isUploadedSelectedFile = false;
        this.form.get('attachment')?.reset();
    }

    editMessage(message: string) {
        this.resetMessageForm();
        this.editorWithMentions.editMessage(message);
    }

    setMessage(message: string) {
        this.resetMessageForm();
        this.editorWithMentions.setValue(message);
    }

    setUploadedAttachment(fileName: string, fileSize: number) {
        this.isUploadedSelectedFile = true;
        this.selectedFileName = fileName;
        this.fileSizeLabel = fileSizeWithUnits(fileSize);
    }

    onMessageInputKeyup(event: KeyboardEvent) {
        if (
            (event.ctrlKey || event.metaKey) &&
            (event.keyCode === keyCodeEnter ||
                event.keyCode === keyCodeCtrlEnter)
        ) {
            event.stopPropagation();
            this.submitComment();
        }
    }

    onUploadOutput(output: UploadOutput) {
        if (!this.selectedFile) {
            switch (output.type) {
                case 'addedToQueue':
                    if (output.file !== undefined) {
                        if (
                            output.file.size > maxFileSizeInBytes ||
                            output.file.size === 0
                        ) {
                            this.errorMessage.emit(
                                output.file.size === 0
                                    ? emptyFileWarning
                                    : fileSizeTooBigWarning,
                            );
                            this.resetAttachment();
                        } else {
                            this.selectedFile = output.file;
                            this.selectedFileName = output.file.name;
                            this.fileSizeLabel = fileSizeWithUnits(
                                output.file.size,
                            );
                        }
                    }

                    break;
                case 'cancelled':
                case 'rejected':
                    this.resetMessageForm();

                    break;
            }
        }
    }

    openFileDialog(event: MouseEvent) {
        if (this.fileIsAttached) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
