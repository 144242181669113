import { EmployerDashboardMatchType } from '../../../../matches/employer/model/employer-dashboard-match-type';
import type { Match } from '../../match.model';
import type { Employer } from '../../employer.model';
import { DecisionType } from '../../enumeration/match-decision-type.model';
import type { User } from '../../user.model';
import type { BaseJobInfo } from '../../job-info.model';

export enum SegmentEventActorType {
    user = 'User',
}

export enum SegmentEventType {
    employer_job_comment = 'Employer Job Comment',
    employer_create_comment = 'Employer Create Comment',
    employer_invite_apply = 'Employer Invite Apply',
    employer_remove_invite_apply = 'Employer Remove Invite Apply',
    employer_required_action = 'Employer Required Action',
    employer_send_message = 'Employer Send Message',
    candidate_send_message = 'Candidate Send Message',
    employer_update_team = 'Employer Update Team',
    employer_create_team = 'Employer Create Team',
    employer_candidate_approval = 'Employer Candidate Approval',
    employer_candidate_archive = 'Employer Candidate Archive',
    employer_candidate_unarchive = 'Employer Candidate Unarchive',
    candidate_restore_application = 'Candidate Restore Application',
    candidate_withdraw_application = 'Candidate Withdraw Application',
    employer_tag_candidate = 'Employer Tag Candidate',
    employer_favorite_candidate = 'Employer Favorite Candidate',
    resume_parsed = 'Resume Parsed',
    linked_in_company_id_needed = 'Employer LinkedIn Company ID Needed',
}

export enum EventLocation {
    jobInvitedTab = 'jobInvitedTab',
    jobApplicationsTab = 'jobApplicationsTab',
    jobInProgressTab = 'jobInProgressTab',
    jobArchiveTab = 'jobArchiveTab',
    inviteToApply = 'inviteToApply',
    candidateDetailPage = 'candidateDetailPage',
    allMessages = 'allMessages',
    allJobs = 'allJobs',
    automatedMessageModal = 'automatedMessageModal',
    jobDetail = 'jobDetail',
    jobInProgressTabDragAndDrop = 'jobInProgressTabDragAndDrop',
    jobInProgressTabBulk = 'jobInProgressTabBulk',
    jobInProgressTabPopUpMenu = 'jobInProgressTabPopUpMenu',
}

export const employerDashboardMatchTypeToEventLocationMap: {
    [key in Exclude<
        EmployerDashboardMatchType,
        EmployerDashboardMatchType.allApproved
    >]: EventLocation;
} = {
    [EmployerDashboardMatchType.invited]: EventLocation.jobInvitedTab,
    [EmployerDashboardMatchType.applications]: EventLocation.jobApplicationsTab,
    [EmployerDashboardMatchType.inProgress]: EventLocation.jobInProgressTab,
    [EmployerDashboardMatchType.archived]: EventLocation.jobArchiveTab,
    [EmployerDashboardMatchType.invite]: EventLocation.inviteToApply,
};

export const eventLocationToLabelMap = {
    [EventLocation.jobInvitedTab]: 'Job Invited Tab',
    [EventLocation.jobApplicationsTab]: 'Job Applications Tab',
    [EventLocation.jobInProgressTab]: 'Job In-Progress Tab',
    [EventLocation.jobArchiveTab]: 'Job Archive Tab',
    [EventLocation.inviteToApply]: 'Invite to Apply',
    [EventLocation.candidateDetailPage]: 'Candidate Detail Page',
    [EventLocation.allMessages]: 'All Messages',
    [EventLocation.allJobs]: 'All Jobs',
    [EventLocation.automatedMessageModal]: 'Automated Message Modal',
    [EventLocation.jobDetail]: 'Job Detail',
    [EventLocation.jobInProgressTabDragAndDrop]:
        'Job In-Progress Tab Drag and Drop',
    [EventLocation.jobInProgressTabBulk]: 'Job In-Progress Tab Bulk',
    [EventLocation.jobInProgressTabPopUpMenu]:
        'Job In-Progress Tab Pop Up Menu',
};

export const employerActionOnMatchToAnalyticsEventMap = {
    [DecisionType.invite]: SegmentEventType.employer_invite_apply,
    [DecisionType.remove]: SegmentEventType.employer_remove_invite_apply,
    [DecisionType.connect]: SegmentEventType.employer_candidate_approval,
    [DecisionType.pass]: SegmentEventType.employer_candidate_archive,
    [DecisionType.withdraw]: SegmentEventType.employer_candidate_archive,
    [DecisionType.undo]: SegmentEventType.employer_candidate_unarchive,
};

export class SegmentEventDataSources {
    job?: BaseJobInfo;
    user?: User;
    employer?: Employer;
    match?: Match;
    eventLocation?: EventLocation;
    eventActor?: SegmentEventActorType;
    additionalProperties?: { [key: string]: any };
}

export class SegmentEventModel {
    eventType: SegmentEventType;
    eventDataSources: SegmentEventDataSources;

    constructor(
        eventType: SegmentEventType,
        eventDataSources: SegmentEventDataSources,
    ) {
        this.eventType = eventType;
        this.eventDataSources = eventDataSources;
    }
}
