import type { IdentifiableVersionedModel } from './identifiable-versioned-model.interface';

export class AbstractIdentifiableVersionedModel
    implements IdentifiableVersionedModel
{
    id: number;
    version: number;

    constructor(model: IdentifiableVersionedModel) {
        this.id = model.id;
        this.version = model.version;
    }
}
