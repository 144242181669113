<ng-container *ngIf="!errorState; else errorStateTemplate">
    <pdf-viewer
        [src]="url"
        [render-text]="true"
        [original-size]="false"
        [fit-to-page]="false"
        [(page)]="currentPage"
        (after-load-complete)="onLoadCompleted($event)"
        (error)="onError()"
        class="pdf-viewer"
        [style.width]="width"
        [style.height]="height"
    >
    </pdf-viewer>

    <div class="action-buttons">
        <div class="navigator">
            <img
                src="/images/icons/arrow-backward.svg"
                class="left-arrow"
                alt=""
                (click)="onPrevClick()"
            />
            {{ currentPage }}/{{ totalNumberOfPages }}
            <img
                src="/images/icons/arrow-forward.svg"
                class="right-arrow"
                alt=""
                (click)="onNextClick()"
            />
        </div>
        <div *ngIf="allowToEnlarge" class="enlarge-button" (click)="enlarge()"></div>
    </div>
</ng-container>

<ng-template #errorStateTemplate>
    <div class="error-state-content" [style.width]="width">
        <img src="/images/icons/file_warning.svg" alt="" />
        <div>
            The resume provided is not compatible with this viewer.<br />Please click to download
            it.
        </div>
        <a href="{{ url }}" target="_blank" class="download-resume-button">Download Resume</a>
    </div>
</ng-template>
