import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';
import type { IHeaderButton } from './model/header-button.interface';
import { PanelContext } from './model/panel-context.enum';

@Component({
    selector: 'sp-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {
    @Input() title: string;
    @Input() titleAlign: 'left' | 'center' = 'center';
    @Input() headerTextColor: 'secondary'; // if not specified, default value for a given colorContext is used
    @Input() colorContext: PanelContext = PanelContext.primary_ghost;
    @Input() headerSize: 'regular' | 'bigger' = 'regular';
    @Input() headerFontSize: 'regular' | 'bigger' = 'regular';
    @Input() headerButtons: IHeaderButton[] = [];

    @Output() ctaClick = new EventEmitter<string>();

    @HostBinding('class.context-primary')
    get primaryContext() {
        return this.colorContext === PanelContext.primary;
    }

    @HostBinding('class.context-plain')
    get plainContext() {
        return this.colorContext === PanelContext.plain;
    }

    @HostBinding('class.context-primary_ghost')
    get primaryGhostContext() {
        return this.colorContext === PanelContext.primary_ghost;
    }

    @HostBinding('class.context-info_ghost')
    get infoGhostContext() {
        return this.colorContext === PanelContext.info_ghost;
    }

    @HostBinding('class.context-danger_ghost')
    get dangerGhostContext() {
        return this.colorContext === PanelContext.danger_ghost;
    }

    onButtonClick(identifier: string) {
        this.ctaClick.emit(identifier);
    }
}
