import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { TextMaskConfig } from 'angular2-text-mask';
import { createAutoCorrectedDatePipe } from 'text-mask-addons/dist/textMaskAddons';
import { asyncScheduler } from 'rxjs';
import type { IDateValue } from '../sp-form-controls.model';
import {
    MaskedDateInputFormat,
    SpMaskedDateInput,
} from '../sp-form-controls.model';
import { getInputFieldIconCssClassForContext } from '../sp-form-control-utils';
import { padWithZeros } from '../../../utils';

const separator = '/';

const fullDateModeMask = [
    /\d/,
    /\d/,
    separator,
    /\d/,
    /\d/,
    separator,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];
const monthYearModeMask = [/\d/, /\d/, separator, /\d/, /\d/, /\d/, /\d/];
const yearModeMask = [/\d/, /\d/, /\d/, /\d/];

// wrapper of the original createAutoCorrectedDatePipe which modifies passed 'conformedValue'
// if conformed value contains 'Feb. 29' date for non-leap year. I.e. Feb. 29 -> Feb. 28
function createLeapYearAwareAutoCorrectedDatePipe(
    dateFormat = '',
    handleLeapYear = false,
    { minYear = 1, maxYear = 9999 } = {},
) {
    // as of version 3.8.0 of text-mask-addons lib a second optional parameter has been added to a
    // createAutoCorrectedDatePipe function; however typings were not updated to reflect that change
    // which causes compilation error
    const pipeFunc = createAutoCorrectedDatePipe(dateFormat, {
        minYear,
        maxYear,
    });
    if (!handleLeapYear) {
        return pipeFunc;
    }

    return function (conformedValue) {
        if (conformedValue && /02\/29\/\d\d\d\d/g.test(conformedValue)) {
            const mdy = conformedValue.split('/');
            const year = parseInt(mdy[2], 10);
            // credits for leap-year check algorithm: https://stackoverflow.com/a/16353241/1524551
            const isLeapYear =
                (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
            if (!isLeapYear) {
                mdy[1] = 28;
                conformedValue = mdy.join('/');
            }
        }

        return pipeFunc(conformedValue);
    };
}

@Component({
    selector: 'sp-masked-date-input',
    templateUrl: './masked-date-input.component.html',
    styleUrls: ['./masked-date-input.component.scss'],
})
export class MaskedDateInputComponent implements OnInit {
    @Input() control: SpMaskedDateInput;

    placeholder: string;
    dateFormat: string;

    model = '';
    textMaskConfig: TextMaskConfig;
    isCurrentDate: boolean;

    // stored original field label copy if we toggle label based on isCurrent checkbox state
    private defaultLabelCopy: string;

    private value: Date | string;

    private unmaskRegexp: RegExp;

    get iconCssClass(): { [name: string]: boolean } {
        let context;
        if (
            this.control.formControl.invalid &&
            this.control.formControl.touched
        ) {
            context = 'danger';
        }

        return getInputFieldIconCssClassForContext(this.control.icon, context);
    }

    get hasValidationError() {
        return (
            this.control.formControl.dirty && this.control.formControl.invalid
        );
    }

    ngOnInit() {
        if (this.control.format === MaskedDateInputFormat.fullDate) {
            this.dateFormat = `mm${separator}dd${separator}yyyy`;
            this.unmaskRegexp = new RegExp(
                `\\d\\d${separator}\\d\\d${separator}\\d\\d\\d\\d`,
                'g',
            );
        } else if (this.control.format === MaskedDateInputFormat.monthYear) {
            this.dateFormat = `mm${separator}yyyy`;
            this.unmaskRegexp = new RegExp(
                `\\d\\d${separator}\\d\\d\\d\\d`,
                'g',
            );
        } else {
            // year only
            this.dateFormat = `yyyy`;
            this.unmaskRegexp = new RegExp(`\\d\\d\\d\\d`, 'g');
        }

        this.value = this.control._value.value;
        this.model = this.dateToMaskedValue(this.control._value);
        this.isCurrentDate =
            this.control._value && this.control._value.isCurrent;
        const placeholderText = this.isCurrentDate
            ? this.control.placeholderForIsCurrentTo
            : null;
        this.setPlaceholder(placeholderText);

        const minMaxYear = {
            ...(this.control.minYear ? { minYear: this.control.minYear } : {}),
            ...(this.control.maxYear ? { maxYear: this.control.maxYear } : {}),
        };

        let mask;
        if (this.control.format === MaskedDateInputFormat.fullDate) {
            mask = fullDateModeMask;
        } else if (this.control.format === MaskedDateInputFormat.monthYear) {
            mask = monthYearModeMask;
        } else {
            mask = yearModeMask;
        }

        this.textMaskConfig = {
            mask,
            pipe: createLeapYearAwareAutoCorrectedDatePipe(
                this.dateFormat,
                this.control.format === MaskedDateInputFormat.fullDate,
                minMaxYear,
            ),
            // must be set to true as per requirement in the library's repository:
            // https://github.com/text-mask/text-mask/tree/master/addons/#pipes
            keepCharPositions: true,
        };

        // initial masked date input label depending on the state of the isCurrent checkbox
        if (this.control.labelForIsCurrentToggled) {
            this.defaultLabelCopy = this.control.label;
            if (this.isCurrentDate) {
                asyncScheduler.schedule(
                    () =>
                        (this.control.label =
                            this.control.labelForIsCurrentToggled),
                );
            }
        }
    }

    onValueChange(event) {
        this.value = this.toUTCDate(event);
        this.emitDate();
    }

    public setPlaceholder(value?: string) {
        this.control.placeholder = value || this.dateFormat.toUpperCase();
    }

    private emitDate() {
        this.control.onValueChange({
            value: this.value,
            isCurrent: this.isCurrentDate,
        });
    }

    private toUTCDate(maskedValue): Date {
        // Due to 'global' flag in RegExp, if a match was found in a previous test(), lastIndex will be set to the position
        // of the end of the matched string in the input. If lastIndex is equal to or less than the length of the input,
        // on the next value test() will attempt to match the input starting from lastIndex and will fail.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/lastIndex#description.
        // So for proper string testing on each value passed lastIndex needs to be reset.
        this.unmaskRegexp.lastIndex = 0;
        if (!this.unmaskRegexp.test(maskedValue)) {
            // invalid input (i.e. incomplete value entered)
            return this.control.emitIncompleteMaskedValue ? maskedValue : null;
        }

        const chunks = maskedValue.split(separator);
        const monthYearDay = { year: null, month: null, date: 1 };
        if (this.control.format === MaskedDateInputFormat.fullDate) {
            monthYearDay.month = parseInt(chunks[0], 10) - 1;
            monthYearDay.date = parseInt(chunks[1], 10);
            monthYearDay.year = parseInt(chunks[2], 10);
        } else if (this.control.format === MaskedDateInputFormat.monthYear) {
            monthYearDay.month = parseInt(chunks[0], 10) - 1;
            monthYearDay.year = parseInt(chunks[1], 10);
        } else {
            // year only
            monthYearDay.year = parseInt(chunks[0], 10);
            monthYearDay.month = 0;
        }

        const date = new Date(
            Date.UTC(monthYearDay.year, monthYearDay.month, monthYearDay.date),
        );
        // Date.UTC converts years between 0 and 99 to a year in the 20th century (1900 + year).
        // For example, 95 is converted to the year 1995.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC#description
        // In this case we set full year explicitly after date object has been created.
        // https://stackoverflow.com/questions/5863327/tips-for-working-with-pre-1000-a-d-dates-in-javascript
        if (monthYearDay.year < 100) {
            date.setFullYear(monthYearDay.year);
        }

        return date;
    }

    private dateToMaskedValue(date: IDateValue): string {
        if (!date || !date.value) {
            return '';
        }

        let chunks: string[];
        if (this.control.format === MaskedDateInputFormat.fullDate) {
            chunks = [
                padWithZeros(date.value.getUTCMonth() + 1, 2),
                padWithZeros(date.value.getUTCDate(), 2),
                padWithZeros(date.value.getUTCFullYear(), 4),
            ];
        } else if (this.control.format === MaskedDateInputFormat.monthYear) {
            chunks = [
                padWithZeros(date.value.getUTCMonth() + 1, 2),
                padWithZeros(date.value.getUTCFullYear(), 4),
            ];
        } else {
            // year only
            chunks = [padWithZeros(date.value.getUTCFullYear(), 4)];
        }

        return chunks.join(separator);
    }
}
