<div class="sp-input-with-icon">
    <!--
    note: v2 css class HAS to be set on the input UNTIL corresponding changes added previously to
    sp-web-styles project to allow v1/v2 styles to coexist are removed
  -->
    <input
        type="text"
        class="input-field v2"
        [class.error]="hasValidationError"
        [class.without-icon]="!this.control.icon"
        [textMask]="textMaskConfig"
        [(ngModel)]="model"
        [placeholder]="control.placeholder"
        [disabled]="
            control.disabled ||
            (control.hasIsCurrentOption && !control.allowFutureDates && isCurrentDate)
        "
        (ngModelChange)="onValueChange($event)"
    />
    <span *ngIf="this.control.icon" [ngClass]="iconCssClass" class="sp-field-icon"> </span>
</div>
