import { EmployerDashboardMatchType } from '../../../../matches/employer/model/employer-dashboard-match-type';
import { EmployerConnectedMatchStatus } from '../../enumeration/employer-connected-match-status.model';
import type { Match } from '../../match.model';
import { CandidateMatchSubStatus } from '../../../../matches/candidate/model/candidate-match-sub-status.model';
import type { Employer } from '../../employer.model';
import type { BaseJobInfo } from '../../job-info.model';
import { isDefined } from '../../../utils';
import type { User } from '../../user.model';
import { eventLocationToLabelMap } from './segment-event.model';
import type {
    EventLocation,
    SegmentEventActorType,
    SegmentEventDataSources,
} from './segment-event.model';
import { SegmentUserTrait } from './segment-user-traits.model';

export enum SegmentEventProperty {
    // Employer Job properties
    jobId = 'Job ID',
    jobTitle = 'Job Title',
    jobSourcingEnabled = 'Job Sourcing Enabled',
    // Employer properties
    employerName = 'Employer Name',
    employerId = 'Employer ID',
    // Match properties
    matchId = 'Match ID',
    matchScore = 'Match Score',
    matchCandidateId = 'Match Candidate ID',
    matchCandidateEmail = 'Match Candidate Email',
    matchCandidateName = 'Match Candidate Name',
    matchCandidateResumeUpload = 'Match Candidate Resume Uploaded',
    matchCandidateProfileCompleted = 'Match Candidate Profile Complete',
    matchCandidateAssessmentComplete = 'Match Candidate Assessment Complete',
    matchEmployerStatus = 'Match Employer Status',
    matchEmployerSubStatus = 'Match Employer Sub Status',
    matchCandidateStatus = 'Match Candidate Status',
    matchCandidateSubStatus = 'Match Candidate Sub Status',
    // Other properties
    eventLocation = 'Event Location',
    eventActor = 'Event Actor',
}

enum MatchEmployerStatus {
    invited = 'Invited',
    applications = 'Applications',
    inProgress = 'In-Progress',
    archived = 'Archived',
}

enum MatchEmployerSubStatus {
    applicationsNew = 'Applications - New',
    applicationsViewed = 'Applications - Viewed',
    reviewedApproved = 'Reviewed - Approved',
    reviewedWithdrew = 'Reviewed - Withdrew',
    contactedPending = 'Contacted - Pending',
    contactedResponded = 'Contacted - Responded',
    contactedNoResponse = 'Contacted - No Response',
    contactedWithdrew = 'Contacted - Withdrew',
    interviewingScheduled = 'Interviewing - Scheduled',
    interviewingPhoneScreen = 'Interviewing - Phone Screen',
    interviewingInterview = 'Interviewing - Interview',
    interviewingDisqualified = 'Interviewing - Disqualified',
    interviewingWithdrew = 'Interviewing - Withdrew',
    offersPending = 'Offers - Pending',
    offersAccepted = 'Offers - Accepted',
    offersDeclined = 'Offers - Declined',
}

enum MatchCandidateStatus {
    review = 'Review',
    applied = 'Applied',
    archived = 'Archived',
}

enum MatchCandidateSubStatus {
    appliedIncomplete = 'Applied - Incomplete',
    appliedApplied = 'Applied - Applied',
    inProgressPending = 'In-Progress - Pending',
    inProgressInterviewing = 'In-Progress - Interviewing',
    inProgressPhoneScreen = 'In-Progress - Phone Screen',
    offersPending = 'Offers - Pending',
    offersAccepted = 'Offers - Accepted',
    OffersDeclined = 'Offers - Declined',
    archivedDisqualified = 'Archived - Disqualified',
    archivedClosed = 'Archived - Closed',
    archivedWithdrew = 'Archived - Withdrew',
}

const employerDashboardMatchTypeToMatchEmployerStatusMap = {
    [EmployerDashboardMatchType.invited]: MatchEmployerStatus.invited,
    [EmployerDashboardMatchType.applications]: MatchEmployerStatus.applications,
    [EmployerDashboardMatchType.inProgress]: MatchEmployerStatus.inProgress,
    [EmployerDashboardMatchType.archived]: MatchEmployerStatus.archived,
};

const employerConnectedMatchStatusToMatchEmployerSubStatusMap = {
    [EmployerConnectedMatchStatus.approved]:
        MatchEmployerSubStatus.reviewedApproved,
    [EmployerConnectedMatchStatus.withdrawnReviewed]:
        MatchEmployerSubStatus.reviewedWithdrew,
    [EmployerConnectedMatchStatus.pendingContacted]:
        MatchEmployerSubStatus.contactedPending,
    [EmployerConnectedMatchStatus.responded]:
        MatchEmployerSubStatus.contactedResponded,
    [EmployerConnectedMatchStatus.noResponse]:
        MatchEmployerSubStatus.contactedNoResponse,
    [EmployerConnectedMatchStatus.withdrawnContacted]:
        MatchEmployerSubStatus.contactedWithdrew,
    [EmployerConnectedMatchStatus.scheduled]:
        MatchEmployerSubStatus.interviewingScheduled,
    [EmployerConnectedMatchStatus.phoneScreen]:
        MatchEmployerSubStatus.interviewingPhoneScreen,
    [EmployerConnectedMatchStatus.interview]:
        MatchEmployerSubStatus.interviewingInterview,
    [EmployerConnectedMatchStatus.disqualified]:
        MatchEmployerSubStatus.interviewingDisqualified,
    [EmployerConnectedMatchStatus.withdrawnInterviewing]:
        MatchEmployerSubStatus.interviewingWithdrew,
    [EmployerConnectedMatchStatus.pending]:
        MatchEmployerSubStatus.offersPending,
    [EmployerConnectedMatchStatus.accepted]:
        MatchEmployerSubStatus.offersAccepted,
    [EmployerConnectedMatchStatus.declined]:
        MatchEmployerSubStatus.offersDeclined,
};

export class SegmentEventProperties {
    [SegmentEventProperty.jobId]?: number;
    [SegmentEventProperty.jobTitle]?: string;
    [SegmentEventProperty.jobSourcingEnabled]?: boolean;
    [SegmentEventProperty.employerName]?: string;
    [SegmentEventProperty.employerId]?: number;
    [SegmentEventProperty.matchId]?: number;
    [SegmentEventProperty.matchScore]?: number;
    [SegmentEventProperty.matchCandidateId]?: number;
    [SegmentEventProperty.matchCandidateEmail]?: string;
    [SegmentEventProperty.matchCandidateName]?: string;
    [SegmentEventProperty.matchCandidateResumeUpload]?: boolean;
    [SegmentEventProperty.matchCandidateProfileCompleted]?: boolean;
    [SegmentEventProperty.matchCandidateAssessmentComplete]?: boolean;
    [SegmentEventProperty.matchEmployerStatus]?: MatchEmployerStatus;
    [SegmentEventProperty.matchEmployerSubStatus]?: MatchEmployerSubStatus;
    [SegmentEventProperty.matchCandidateStatus]?: MatchCandidateStatus;
    [SegmentEventProperty.matchCandidateSubStatus]?: MatchCandidateSubStatus;
    [SegmentEventProperty.eventLocation]?: EventLocation;
    [SegmentEventProperty.eventActor]?: SegmentEventActorType;
    [SegmentUserTrait.employerUserId]?: number;
    [SegmentUserTrait.employerUserEmail]?: string;
    [SegmentUserTrait.employerUserName]?: string;

    public static mapDataSourcesToEventProperties(
        propsDataSources: SegmentEventDataSources,
    ): SegmentEventProperties {
        let eventProperties = Object.assign(
            new SegmentEventProperties(),
            this.getStandardJobProperties(propsDataSources.job),
            this.getStandardEmployerProperties(propsDataSources.employer),
            this.getStandardMatchProperties(propsDataSources.match),
            this.getEmployerUserProperties(propsDataSources.user),
            {
                [SegmentEventProperty.eventLocation]:
                    eventLocationToLabelMap[propsDataSources.eventLocation],
                [SegmentEventProperty.eventActor]: propsDataSources.eventActor,
            },
        );

        if (isDefined(propsDataSources.additionalProperties)) {
            eventProperties = Object.assign(
                eventProperties,
                propsDataSources.additionalProperties,
            );
        }

        return eventProperties;
    }

    private static getStandardJobProperties(
        job: BaseJobInfo,
    ): SegmentEventProperties {
        const props = !isDefined(job)
            ? {}
            : {
                  [SegmentEventProperty.jobId]: job.id,
                  [SegmentEventProperty.jobTitle]: job.title,
                  [SegmentEventProperty.jobSourcingEnabled]:
                      job.isJobSourcingActive,
              };

        return props as SegmentEventProperties;
    }

    private static getStandardEmployerProperties(
        employer: Employer,
    ): SegmentEventProperties {
        const props = !isDefined(employer)
            ? {}
            : {
                  [SegmentEventProperty.employerName]: employer.employerName,
                  [SegmentEventProperty.employerId]: employer.id,
              };

        return props as SegmentEventProperties;
    }

    private static getStandardMatchProperties(
        match: Match,
    ): SegmentEventProperties {
        const props = !isDefined(match)
            ? {}
            : {
                  [SegmentEventProperty.matchId]: match.id,
                  [SegmentEventProperty.matchScore]: match.totalMatchScore,
                  [SegmentEventProperty.matchCandidateId]: match.candidateId,
                  [SegmentEventProperty.matchCandidateEmail]:
                      match.candidateEmail,
                  [SegmentEventProperty.matchCandidateName]:
                      match.candidateName,
                  [SegmentEventProperty.matchCandidateResumeUpload]:
                      !!match.hasCandidateUploadedResume,
                  [SegmentEventProperty.matchCandidateProfileCompleted]:
                      !!match.isCandidateProfileComplete,
                  [SegmentEventProperty.matchCandidateAssessmentComplete]:
                      !match.candidateWithIncompleteAssessments,
                  [SegmentEventProperty.matchEmployerStatus]:
                      employerDashboardMatchTypeToMatchEmployerStatusMap[
                          match.employerDashboardMatchType
                      ],
                  [SegmentEventProperty.matchEmployerSubStatus]:
                      this.getEmployerMatchSubStatus(match),
                  [SegmentEventProperty.matchCandidateStatus]:
                      this.getCandidateStatusFromCandidateMatchSubStatus(
                          match.candidateMatchSubStatus,
                      ),
                  [SegmentEventProperty.matchCandidateSubStatus]:
                      this.getCandidateSubStatusFromCandidateMatchSubStatus(
                          match.candidateMatchSubStatus,
                      ),
              };

        return props as SegmentEventProperties;
    }

    private static getEmployerUserProperties(
        user: User,
    ): SegmentEventProperties {
        const props = !isDefined(user)
            ? {}
            : {
                  [SegmentUserTrait.employerUserId]: user.id,
                  [SegmentUserTrait.employerUserEmail]: user.email,
                  [SegmentUserTrait.employerUserName]: user.fullName,
              };

        return props as SegmentEventProperties;
    }

    private static getCandidateStatusFromCandidateMatchSubStatus(
        status: CandidateMatchSubStatus,
    ): MatchCandidateStatus {
        switch (status) {
            case CandidateMatchSubStatus.ca_applied:
            case CandidateMatchSubStatus.cb_pendingInProgress:
            case CandidateMatchSubStatus.cc_interviewing:
            case CandidateMatchSubStatus.cd_phoneScreen:
            case CandidateMatchSubStatus.ce_pendingOffers:
            case CandidateMatchSubStatus.cf_accepted:
            case CandidateMatchSubStatus.cg_declined:
                return MatchCandidateStatus.applied;
            case CandidateMatchSubStatus.aa_disqualified:
            case CandidateMatchSubStatus.da_withdrew:
            case CandidateMatchSubStatus.db_archived:
                return MatchCandidateStatus.archived;
            default:
                return MatchCandidateStatus.review;
        }
    }

    private static getCandidateSubStatusFromCandidateMatchSubStatus(
        status: CandidateMatchSubStatus,
    ): MatchCandidateSubStatus {
        switch (status) {
            case CandidateMatchSubStatus.ch_incomplete:
                return MatchCandidateSubStatus.appliedIncomplete;
            case CandidateMatchSubStatus.ca_applied:
                return MatchCandidateSubStatus.appliedApplied;
            case CandidateMatchSubStatus.cb_pendingInProgress:
                return MatchCandidateSubStatus.inProgressPending;
            case CandidateMatchSubStatus.cc_interviewing:
                return MatchCandidateSubStatus.inProgressInterviewing;
            case CandidateMatchSubStatus.cd_phoneScreen:
                return MatchCandidateSubStatus.inProgressPhoneScreen;
            case CandidateMatchSubStatus.ce_pendingOffers:
                return MatchCandidateSubStatus.offersPending;
            case CandidateMatchSubStatus.cf_accepted:
                return MatchCandidateSubStatus.offersAccepted;
            case CandidateMatchSubStatus.cg_declined:
                return MatchCandidateSubStatus.OffersDeclined;
            case CandidateMatchSubStatus.aa_disqualified:
                return MatchCandidateSubStatus.archivedDisqualified;
            case CandidateMatchSubStatus.da_withdrew:
                return MatchCandidateSubStatus.archivedWithdrew;
            default:
                return null;
        }
    }

    private static getEmployerMatchSubStatus(
        match: Match,
    ): MatchEmployerSubStatus {
        let status = null;
        if (
            match.employerDashboardMatchType ===
            EmployerDashboardMatchType.applications
        ) {
            status = match.isViewedByEmployer
                ? MatchEmployerSubStatus.applicationsViewed
                : MatchEmployerSubStatus.applicationsNew;
        } else if (
            match.employerDashboardMatchType ===
            EmployerDashboardMatchType.inProgress
        ) {
            status =
                employerConnectedMatchStatusToMatchEmployerSubStatusMap[
                    match.connectedMatchStatus
                ];
        }

        return status;
    }
}
