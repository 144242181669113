import { Component, Inject } from '@angular/core';
import type { DialogRef } from '../../../../../shared/dialog-ref';
import { spDialogData } from '../../../../../shared/dialog/dialog.service';
import type { WelcomeModalData } from './profile-prefill-result-modal.utils';
import {
    getProfileFullyPrefilledFromResumeData,
    getProfilePartiallyPrefilledFromResumeData,
} from './profile-prefill-result-modal.utils';

@Component({
    selector: 'sp-profile-prefill-result-modal',
    templateUrl: './profile-prefill-result-modal.component.html',
    styleUrls: ['./profile-prefill-result-modal.component.scss'],
})
export class ProfilePrefillResultModalComponent {
    private dialogRef: DialogRef<ProfilePrefillResultModalComponent>;
    userName: string;
    isProfileFullyPrefilled: boolean;

    constructor(@Inject(spDialogData) dialogData) {
        this.dialogRef = dialogData.dialogRef;
        this.userName = dialogData.contentComponentData.userName;
        this.isProfileFullyPrefilled =
            dialogData.contentComponentData.isProfileFullyPrefilled;
    }

    get modalData(): WelcomeModalData {
        return this.isProfileFullyPrefilled
            ? getProfileFullyPrefilledFromResumeData(this.userName)
            : getProfilePartiallyPrefilledFromResumeData(this.userName);
    }

    closeModal() {
        this.dialogRef.close();
    }
}
