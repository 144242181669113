import type { OnInit } from '@angular/core';
import { Component, HostBinding } from '@angular/core';
import { Angulartics2Segment } from 'angulartics2/segment';
import { asyncScheduler } from 'rxjs';
import { UserService } from './core/user.service';
import { MessagingChatListenerService } from './core/messaging-chat-listener.service';
import { NotificationsListenerService } from './core/background-listeners/notifications-listener.service';
import { UserType } from './shared/models/user.model';
import { ActivityFeedService } from './core/activity-feed.service';
import type { NavbarState } from './shared/navbar/model';

@Component({
    selector: 'sp-web-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [ActivityFeedService],
})
export class AppComponent implements OnInit {
    private navbarState?: NavbarState;

    @HostBinding('class.has-navbar')
    get showNavbar() {
        return !!this.userService.user && !this.routeWithoutNavbar;
    }

    @HostBinding('class.has-stepper')
    get hasStepper(): boolean {
        return !!this.navbarState?.hasStepper;
    }

    @HostBinding('class.has-progress-bar')
    get hasProgressBar(): boolean {
        return !!this.navbarState?.hasProgressBar;
    }

    @HostBinding('class.has-stepper-with-progress-bar')
    get hasStepperWithProgressBar(): boolean {
        return !!this.navbarState?.hasStepperWithBottomProgressBar;
    }

    constructor(
        private userService: UserService,
        angulartics2Segment: Angulartics2Segment,
        private messagingChatListener: MessagingChatListenerService,
        private notificationsListener: NotificationsListenerService,
    ) {
        angulartics2Segment.startTracking();
    }

    ngOnInit() {
        this.userService.user$.subscribe((user) => {
            if (user) {
                this.messagingChatListener.startListening();
                if (user.type === UserType.employer) {
                    this.notificationsListener.startListening();
                }
            } else {
                this.messagingChatListener.stopListening();
                this.notificationsListener.stopListening();
            }
        });
    }

    onNavStateChange(navbarState: NavbarState) {
        asyncScheduler.schedule(() => {
            this.navbarState = navbarState;
        });
    }

    get routeWithoutNavbar(): boolean {
        const href = window.location.href;

        return (
            (href.includes('assessments/') &&
                !href.includes('assessments/jobs') &&
                !href.endsWith('results')) ||
            href.includes('/login') ||
            href.includes('/apply') ||
            href.includes('add/success') ||
            href.includes('edit/success') ||
            href.includes('account/profile/create/select-role') ||
            href.includes('account/profile/create/welcome')
        );
    }
}
