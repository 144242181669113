import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SpCompositeFilterChipsControl } from 'app/shared/forms/custom-controls/sp-form-controls.model';

@Injectable()
export class EmployerMatchesEditTagsService {
    initSpComponentData(data: any) {
        return new SpCompositeFilterChipsControl({
            form: new FormGroup({}),
            name: 'filter-tags',
            isOptional: true,
            allChips: data.contentComponentData.allTags,
            selectedChips: data.contentComponentData.selectedTags,
        });
    }
}
