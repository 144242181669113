import type { OnDestroy } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ISignupForm } from '../../model/signup-form.model';
import { getDomainFromEmail, unsubscribeIfActive } from '../../../shared/utils';

@Component({
    selector: 'sp-join-employer-form',
    templateUrl: './join-employer-form.component.html',
    styleUrls: ['./join-employer-form.component.scss'],
})
export class JoinEmployerFormComponent implements OnDestroy {
    private subscription: Subscription;

    @Input() prefillData: ISignupForm;
    @Input() requestToJoinCallback: () => Observable<any>;

    isSubmitting: boolean;
    error: string;
    get description() {
        return this.prefillData.allowAutoJoin
            ? `Your team allows anyone with a <span class="domain">${getDomainFromEmail(
                  this.prefillData.email,
              )}</span> ` +
                  `email address to join your workspace. Click the Join button below to get started.`
            : `Click the Get Access button below to get started.`;
    }

    get buttonLabel() {
        return this.prefillData.allowAutoJoin ? 'Join' : 'Get Access';
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    submitRequestToJoin() {
        this.isSubmitting = true;
        this.subscription = this.requestToJoinCallback()
            .pipe(finalize(() => (this.isSubmitting = false)))
            .subscribe(
                () => {
                    /*handled on parent component*/
                },
                () => {
                    this.error =
                        'We were unable to process your request. Please try again later or contact your account manager.';
                },
            );
    }
}
