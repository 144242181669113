<div
    class="popup-content"
    [class.dark]="config.theme === 'dark'"
    [class.hide-pointer]="config.hidePointer"
    [style.borderRadius.px]="config.borderRadius"
    [style.padding.px]="config.padding"
    [style.width.px]="config.width"
>
    <ng-container #popupContentContainer></ng-container>
</div>
