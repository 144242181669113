import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { JobInfo } from '../../../shared/models';
import type { JobLocationType } from '../../../shared/models/job-info.model';

export interface JobItem {
    jobTitle: string;
    employerName: string;
    employerLogoUrl?: string;
    jobType: string;
    officeLocations?: string[];
    candidateLocations?: string[];
    jobLocationType?: JobLocationType;
    slug?: string;
}

@Component({
    selector: 'sp-job-item',
    templateUrl: './job-item.component.html',
    styleUrls: ['./job-item.component.scss'],
})
export class JobItemComponent implements OnInit {
    @Input() job: JobItem;

    jobInfo: JobInfo;

    ngOnInit(): void {
        this.jobInfo = Object.assign(new JobInfo(), {
            jobLocationType: this.job.jobLocationType,
            officeLocations: this.job.officeLocations?.map((item) => ({
                value: item,
            })),
            candidateLocations: this.job.candidateLocations?.map((item) => ({
                value: item,
            })),
        });
    }
}
