import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import type { UploadOutput } from 'ngx-uploader';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { WindowService } from '../../core/window.service';
import { ANALYZING_RESUME_MSG } from '../../core/messages';
import {
    uploaderMessageLgScreen,
    uploaderMessageSmScreen,
} from '../../shared/constants';
import { UserService } from '../../core/user.service';
import { unsubscribeIfActive } from '../../shared/utils';
import { JobsService } from '../../core/jobs.service';
import { CandidateProfileService } from '../../account/profile/candidate-profile/candidate-profile.service';
import { AutosaveProfileService } from '../../account/profile/service/autosave-profile.service';
import {
    getEasySignupJobHashIdKeyPrefix,
    getLastJobHashIdCandidateAppliedToSessionKey,
    passThroughSignupQueryParams,
} from '../../core/config.service';
import { LocalStorageService } from '../../core/local-storage.service';
import { ButtonContext } from '../../shared/stateful-button/stateful-button.component';
import { PostResumeUploadActionsService } from '../../account/profile/candidate-profile/post-resume-upload-actions.service';
import type { JobItem } from './job-item/job-item.component';

export interface UserData {
    email: string;
    firstName: string;
    lastName: string;
}

@Component({
    selector: 'sp-easy-signup',
    templateUrl: './easy-signup.component.html',
    styleUrls: ['./easy-signup.component.scss'],
    providers: [
        PostResumeUploadActionsService,
        CandidateProfileService,
        AutosaveProfileService,
    ],
})
export class EasySignupComponent implements OnInit, OnDestroy {
    readonly ButtonContext = ButtonContext;

    uploadUrl: string;
    showSpinner = false;
    errorMsg?: string;
    jobData: JobItem;
    userData: UserData;
    uploadDropAreaMessage: string;
    uploadProcessingMessage: string;
    jobHashId: string;
    passThroughParams;

    private subscription: Subscription;

    private static mapJobData(queryParams: any): JobItem {
        let officeLocations = queryParams.officeLocations;
        if (officeLocations) {
            if (!Array.isArray(officeLocations)) {
                officeLocations = [officeLocations];
            } else if (officeLocations.length === 0) {
                officeLocations = null;
            }
        }
        let candidateLocations = queryParams.candidateLocations;
        if (candidateLocations) {
            if (!Array.isArray(candidateLocations)) {
                candidateLocations = [candidateLocations];
            } else if (candidateLocations.length === 0) {
                candidateLocations = null;
            }
        }

        return {
            jobType: queryParams.jobType,
            jobTitle: queryParams.jobTitle,
            jobLocationType: queryParams.jobLocationType,
            officeLocations,
            candidateLocations,
            employerName: queryParams.employerName,
            employerLogoUrl: queryParams.employerLogoUrl,
            slug: queryParams.slug,
        };
    }

    private static mapUserData(queryParams: any): UserData {
        return {
            email: queryParams.email,
            firstName: queryParams.firstName,
            lastName: queryParams.lastName,
        };
    }

    private static mapPassThroughParams(queryParams: any) {
        const params: Record<string, string> = {};
        const queryParamsKeys = Object.keys(queryParams);

        queryParamsKeys.forEach((paramKey) => {
            if (
                !!queryParams[paramKey] &&
                passThroughSignupQueryParams.includes(paramKey)
            ) {
                params[paramKey] = queryParams[paramKey].trim();
            }
        });

        return params;
    }

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private jobsService: JobsService,
        private windowService: WindowService,
        private profileService: CandidateProfileService,
        private autosaveProfileService: AutosaveProfileService,
        private localStorageService: LocalStorageService,
        private postResumeUploadActionsService: PostResumeUploadActionsService,
    ) {
        this.subscription = new Subscription();
        this.uploadUrl = `${environment.config.API_URL}/api/upload`;
        this.uploadDropAreaMessage = this.windowService.isLtLarger()
            ? uploaderMessageSmScreen
            : uploaderMessageLgScreen;
        this.uploadProcessingMessage = ANALYZING_RESUME_MSG;
    }

    ngOnInit(): void {
        this.jobHashId = this.route.snapshot.params.jobHashId;
        this.jobData = EasySignupComponent.mapJobData(
            this.route.snapshot.queryParams,
        );
        this.userData = EasySignupComponent.mapUserData(
            this.route.snapshot.queryParams,
        );
        this.passThroughParams = EasySignupComponent.mapPassThroughParams(
            this.route.snapshot.queryParams,
        );

        if (!this.userService.isAuthenticated()) {
            this.showSpinner = true;
            const observable$ = this.userService
                .easySignup(this.userData, this.jobHashId, true)
                .pipe(finalize(() => (this.showSpinner = false)))
                .subscribe(
                    () => this.onSignupSuccess(),
                    (error) => {
                        if (error.status === 409) {
                            this.localStorageService.storeSessionEntry(
                                getEasySignupJobHashIdKeyPrefix(
                                    this.userData.email,
                                ),
                                this.jobHashId,
                            );
                            this.errorMsg = `A SquarePeg account already exists for the provided email address.
              <br />Please <a href='/login/candidate'>sign in</a> to apply for this role.`;
                        } else {
                            this.errorMsg = `An error occurred. Please try again later or contact us
              at <a href='mailto:jobs@squarepeghires.com'>jobs@squarepeghires.com</a>`;
                        }
                    },
                );
            this.subscription.add(observable$);
        }
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    onSignupSuccess() {
        const user = this.userService.user;
        // store jobHashId of last job that candidate applied to, so we can open match,
        // once he/she navigate to list of matches on applied tab
        this.localStorageService.storeSessionEntry(
            getLastJobHashIdCandidateAppliedToSessionKey(user.id),
            this.jobHashId,
        );
    }

    onUploadOutput(uploadOutput: UploadOutput) {
        if (uploadOutput.type !== 'done') {
            return;
        }
        this.postResumeUploadActionsService.onResumeUploadResult(
            uploadOutput?.file?.response?.error,
        );
    }

    onCloseClick() {
        let queryString = '';
        if (this.passThroughParams) {
            queryString =
                '?' +
                Object.keys(this.passThroughParams)
                    .map(
                        (key) =>
                            encodeURIComponent(key) +
                            '=' +
                            encodeURIComponent(this.passThroughParams[key]),
                    )
                    .join('&');
        }
        window.location.href = `${environment.config.SITE_URL}/jobs/${this.jobHashId}/${this.jobData.slug}${queryString}`;
    }
}
