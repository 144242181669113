import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IHeaderLink } from 'app/core/navbar.service';
import type { SpDropDownData } from 'app/shared/sp-dropdown/sp-dropdown.component';
import { SpDropDownDataType } from 'app/shared/sp-dropdown/sp-dropdown.component';
import { MenuItemType } from 'app/core/navbar.utils';

const activeMenuItemCssClass = 'nav-item-active';

@Component({
    selector: 'sp-nav-item',
    templateUrl: './nav-item.component.html',
    styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent implements OnInit, OnChanges {
    @Input() menuItem: IHeaderLink;
    @Input() activeRoute: string;

    @Output() selectMenuItem: EventEmitter<IHeaderLink> = new EventEmitter();

    iconAsPicture = false;
    dropDownData: SpDropDownData;
    isActive: boolean;

    MenuItemType = MenuItemType;

    get routerLinkActive() {
        const menuItem = this.menuItem;

        const hasRouterLinkActiveAttr =
            (!menuItem.activeRouteRegexp ||
                !menuItem.activeRouteRegexp.length) &&
            !menuItem.disabled &&
            menuItem.routerLink;

        return hasRouterLinkActiveAttr ? activeMenuItemCssClass : '';
    }

    get itemRoute(): string[] | null {
        return !this.menuItem.routerLink || this.menuItem.disabled
            ? null
            : [this.menuItem.routerLink];
    }

    ngOnInit() {
        if (this.menuItem.type === MenuItemType.dropdown) {
            this.dropDownData = {
                text: this.menuItem.label,
                type: SpDropDownDataType.item,
            };

            if (this.menuItem.icon && this.menuItem.icon.indexOf('://') > 0) {
                this.iconAsPicture = true;
            }

            if (this.menuItem.children) {
                this.dropDownData.children = [];

                for (const sublink of this.menuItem.children) {
                    this.dropDownData.children.push(
                        this.menuItem2DropDownDataItem(sublink),
                    );
                }
            }
        } else {
            this.isActive = this.computeIsActiveRouteState(this.activeRoute);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeRoute) {
            this.isActive = this.computeIsActiveRouteState(
                changes.activeRoute.currentValue,
            );
        }
    }

    getOffsetLeft(menuItem: IHeaderLink): number {
        return menuItem.isNewElement ? -15 : 0;
    }

    onMenuItemClicked($event) {
        // Event comes from dropdown submenu with eventName
        // Ignore DropMenuItems click $event type Object, the ones not configured with eventName
        let headerLink: IHeaderLink;
        if (typeof $event === 'string' && $event.length > 0) {
            headerLink = this.getSubmenu($event);
        } else {
            headerLink = this.menuItem;
            headerLink.el = $event.target;
        }

        this.selectMenuItem.emit(headerLink);
    }

    private menuItem2DropDownDataItem(menuItem: IHeaderLink): SpDropDownData {
        switch (menuItem.type) {
            case MenuItemType.separator:
                return { text: '', type: SpDropDownDataType.separator };
            case MenuItemType.dropdownHeader:
                return {
                    text: menuItem.label,
                    icon: menuItem.icon,
                    type: SpDropDownDataType.header,
                    class: menuItem.class,
                    userName: menuItem.userName,
                };
            default:
                return {
                    text: menuItem.label,
                    routerLink: menuItem.routerLink,
                    icon: menuItem.icon,
                    data: menuItem.selectedEventName,
                    class: menuItem.class,
                };
        }
    }

    private getSubmenu(eventName: string): IHeaderLink {
        for (const sublink of this.menuItem.children) {
            if (eventName === sublink.selectedEventName) {
                return sublink;
            }
        }
    }

    private computeIsActiveRouteState(url: string): boolean {
        return (
            this.menuItem.activeRouteRegexp &&
            !!this.menuItem.activeRouteRegexp.find((regexp) => {
                regexp.lastIndex = 0;

                return regexp.test(url);
            })
        );
    }
}
