import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { of } from 'rxjs';
import { UserService } from '../../../core/user.service';

@Directive({
    selector: '[spHasAnyAuthority]',
})
export class HasAnyAuthorityDirective {
    private permissions: string[];

    @Input() set spHasAnyAuthority(value: string | string[]) {
        this.permissions = value
            ? typeof value === 'string'
                ? [value]
                : value
            : null;

        of(this.userService.hasAnyPermission(this.permissions)).subscribe(
            (hasAnyPermission) => {
                this.viewContainerRef.clear();
                if (hasAnyPermission) {
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                }
            },
        );
    }

    constructor(
        private userService: UserService,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
    ) {}
}
