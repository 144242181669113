import { Injectable } from '@angular/core';
import type {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../../../core/user.service';
import { isDefined } from '../../../shared/utils';
import { LocalStorageService } from '../../../core/local-storage.service';
import { CandidateMatchesApiService } from '../../../core/candidate-matches-api.service';

@Injectable()
export class EasySignupGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private storageService: LocalStorageService,
        private candidateMatchesApiService: CandidateMatchesApiService,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | boolean {
        const jobHashId = route.params.jobHashId;
        const email = route.queryParams.email;

        if (this.userService.isAuthenticated() && isDefined(jobHashId)) {
            const user = this.userService.user;
            if (user.email === email && user.isProfileComplete) {
                return this.candidateMatchesApiService
                    .applyForMatchOrGenerateNewIfDoesNotExistAndNavigateTo(
                        jobHashId,
                        user,
                    )
                    .pipe(map(() => false));
            }
        } else {
            // if user is not authenticated, do store params as we would need them
            this.userService.storePassThroughQueryParams(route.queryParams);
        }

        return true;
    }
}
