import { toDate } from '../../../shared/date-utils';

export class CandidateWorkExperience {
    startDate: Date;
    completionDate?: Date;
    isCurrentlyApplicable?: boolean;

    companyName: string;
    jobTitle: string;
    domain?: string;
    industries: string[];
    department?: string;
    responsibilities: string;

    static of(workExperience: any) {
        if (!workExperience) {
            return null;
        }

        return Object.assign(new CandidateWorkExperience(), workExperience, {
            startDate: toDate(workExperience.startDate),
            completionDate: toDate(workExperience.completionDate),
        });
    }
}
