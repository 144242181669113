import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { SpInputWithSelector } from '../sp-form-controls.model';
import type { GenericLabelValue } from '../../../models';
import { getInputFieldIconCssClassForContext } from '../sp-form-control-utils';

@Component({
    selector: 'sp-input-with-selector',
    templateUrl: './input-with-selector.component.html',
    styleUrls: ['./input-with-selector.component.scss'],
})
export class InputWithSelectorComponent implements OnInit {
    @Input() control: SpInputWithSelector;

    inputValue;
    selectedValue: GenericLabelValue<string>;
    expanded: boolean;
    isInvalidControl: any;

    get iconClass() {
        const cssClasses = {};

        if (
            this.control.formControl.valid &&
            this.control.formControl.value?.inputValue
        ) {
            cssClasses['sp-input-icon__check'] = true;
        } else if (this.control.icon) {
            const context =
                this.control.formControl.invalid &&
                this.control.formControl.touched
                    ? 'danger'
                    : '';
            Object.assign(
                cssClasses,
                getInputFieldIconCssClassForContext(this.control.icon, context),
            );
        }

        return cssClasses;
    }

    ngOnInit() {
        this.inputValue = this.control.value.inputValue;
        this.selectedValue = this.control.items.find(
            (item) => item.value === this.control.value.selectedValue,
        );
    }

    initValidation() {
        this.control.formControl.setValue(
            this.inputValue
                ? {
                      inputValue: this.inputValue,
                      selectedValue: this.selectedValue,
                  }
                : null,
        );
        this.control.formControl.markAsTouched();
        this.isInvalidControl =
            this.control.formControl.touched &&
            this.control.formControl.invalid;
    }

    onOptionItemClick(option: any) {
        this.selectedValue = option;
        this.control.value.selectedValue = this.selectedValue.value;
        this.expanded = false;
    }

    onSelectorClick() {
        this.expanded = !this.expanded;
    }
}
