import type { IPageInfo } from './models/page.model';

/**
 * Provided current collection length and page size computes the current page and next page
 * to be loaded. If current page is incomplete currentPage will be equal to next page to
 * re-load current page attempting to completely fill it
 * @param localCollectionSize
 * @param pageSize
 */
export function computePageOffset(
    localCollectionSize: number,
    pageSize: number,
): IPageInfo {
    let currentPage: number;
    let nextPage: number;

    if (localCollectionSize % pageSize === 0) {
        currentPage = localCollectionSize / pageSize;
        if (currentPage > 0) {
            currentPage--;
        }
        nextPage = localCollectionSize > 0 ? currentPage + 1 : currentPage;
    } else {
        currentPage = Math.floor(localCollectionSize / pageSize);
        // page is incomplete, thus we have to re-load (and merge) current page
        nextPage = currentPage;
    }

    return {
        currentPage,
        nextPage,
    };
}
