import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import type { AutoCompleteEvent } from './sp-autocomplete.model';
import { AutoCompleteEventType } from './sp-autocomplete.model';

@Injectable()
export class AutoCompleteService implements OnDestroy {
    notifier$: Subject<AutoCompleteEvent>;

    constructor() {
        this.notifier$ = new Subject<AutoCompleteEvent>();
    }

    ngOnDestroy(): void {
        this.notifier$.unsubscribe();
    }

    public removeFromExclusion(element: any) {
        this.notifier$.next({
            type: AutoCompleteEventType.removeFromExclusions,
            element,
        });
    }

    public reset() {
        this.notifier$.next({ type: AutoCompleteEventType.reset });
    }
}
