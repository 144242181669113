import type { NavigationExtras } from '@angular/router';
import { toDate } from '../../date-utils';
import { isDefined } from '../../utils';
import { SidebarTabIds } from '../../shared-candidate-profile/messaging-sidebar.constants';
import { routerDataKeyActiveSidebarTab } from '../../../core/config.service';
import {
    ApplicationViewedStatus,
    applicantNotificationsLabels,
} from '../../status-notification-manager/status-notification-manager.constants';
import type { GetstreamAddedAsJobReviewerEmployerActivity } from './getstream/getstream-added-as-job-reviewer-employer-activity.model';
import type { GetstreamNotificationFeed } from './getstream/getstream-notification-feed.model';
import { EmployerFeedAction } from './employer-feed-action.model';
import type { GetstreamCandidateStageNotificationActivity } from './getstream/getstream-candidate-stage-notification.model';
import type { GetstreamEmployerActivity } from './getstream/getstream-employer-activity.model';
import type { GetstramUserAcceptedInviteEmployerActivity } from './getstream/getstream-user-accepted-invite-employer-activity.model';
import type { GetstreamReviewerMentionedInCommentActivity } from './getstream/getstream-reviewer-mentioned-in-comment-activity.model';

const notificationTypeToRouterLink = {
    userAssignedAsJobReviewer: '/jobs/published',
    userAcceptedInvite: '/account/teams-and-users',
    matchWaitingInStage: '/jobs/{jobId}/matches/{tab}/{matchId}',
    employerUserAccessRequest: '/account/teams-and-users',
};

export type EmployerFeedActivityType =
    | GetstreamEmployerActivity
    | GetstreamAddedAsJobReviewerEmployerActivity
    | GetstramUserAcceptedInviteEmployerActivity
    | GetstreamCandidateStageNotificationActivity
    | GetstreamReviewerMentionedInCommentActivity;

export class NotificationActor {
    firstName: string;
    lastName: string;
    picture?: string;
}

export class AppNotification {
    id: string;
    activityGroupId?: string;
    message: string;
    date: Date;
    actor: NotificationActor;
    isSeen: boolean;
    type?: string;
    route?: string;
    routeState?: NavigationExtras;

    static fromGetstreamActivity(
        activityGroup: GetstreamNotificationFeed<EmployerFeedActivityType>,
    ): AppNotification {
        const [activity] = activityGroup.activities;
        switch (activity.verb) {
            case EmployerFeedAction.userAssignedAsJobReviewer:
                return AppNotification.fromAddedAsJobReviewerEmployerActivity(
                    activityGroup,
                    activity as GetstreamAddedAsJobReviewerEmployerActivity,
                );
            case EmployerFeedAction.userAcceptedInvite:
                return AppNotification.fromUserAcceptedInviteEmployerActivity(
                    activityGroup,
                    activity as GetstramUserAcceptedInviteEmployerActivity,
                );
            case EmployerFeedAction.reviewerMentionedInComment:
                return AppNotification.fromReviewerMentionedInCommentEmployerActivity(
                    activityGroup,
                    activity as GetstreamReviewerMentionedInCommentActivity,
                );
            case EmployerFeedAction.matchWaitingInStage:
                return AppNotification.fromCandidateStageNotificationActivity(
                    activityGroup,
                    activity as GetstreamCandidateStageNotificationActivity,
                );
            case EmployerFeedAction.employerUserAccessRequest:
                return AppNotification.fromEmployerUserRequestAccess(
                    activityGroup,
                    activity,
                );
            default:
                throw new Error(
                    `Activity type ${activity.verb} is not supported`,
                );
        }
    }

    static fromAddedAsJobReviewerEmployerActivity(
        activityGroup: GetstreamNotificationFeed<GetstreamEmployerActivity>,
        activity: GetstreamAddedAsJobReviewerEmployerActivity,
    ): AppNotification {
        const notification = this.fromBaseEmployerActivity(
            activityGroup,
            activity,
        );

        notification.message = `You have been assigned to <strong>${activity.employer_job}</strong> by
        <strong>${activity.actor_user.firstname} ${activity.actor_user.lastname}</strong>`;

        return notification;
    }

    private static fromUserAcceptedInviteEmployerActivity(
        activityGroup: GetstreamNotificationFeed<GetstreamEmployerActivity>,
        activity: GetstramUserAcceptedInviteEmployerActivity,
    ) {
        const notification = this.fromBaseEmployerActivity(
            activityGroup,
            activity,
        );

        notification.message =
            `<strong>${activity.actor_user.firstname} ${activity.actor_user.lastname}</strong> ` +
            `has accepted your invitation and joined <strong>${activity.employer_name}</strong>`;

        return notification;
    }

    private static fromReviewerMentionedInCommentEmployerActivity(
        activityGroup: GetstreamNotificationFeed<GetstreamEmployerActivity>,
        activity: GetstreamReviewerMentionedInCommentActivity,
    ) {
        const notification = this.fromBaseEmployerActivity(
            activityGroup,
            activity,
        );

        notification.route =
            this.getRouteForMentionInCommentNotifications(activity);
        notification.routeState = {
            state: {
                data: {
                    [routerDataKeyActiveSidebarTab]:
                        SidebarTabIds.tabCommentsChat,
                },
            },
        };
        notification.message =
            `<strong>${activity.actor_user.firstname} ${activity.actor_user.lastname}</strong> ` +
            `mentioned you in a comment in <strong>${activity.employer_job}:${activity.comment_content}</strong>`;

        return notification;
    }

    private static fromCandidateStageNotificationActivity(
        activityGroup: GetstreamNotificationFeed<GetstreamEmployerActivity>,
        activity: GetstreamCandidateStageNotificationActivity,
    ): AppNotification {
        const notification = this.fromBaseEmployerActivity(
            activityGroup,
            activity,
        );
        const stage = applicantNotificationsLabels[activity.match_status];

        notification.message = `<strong>${this.getFirstNameWithLastNameInitial(
            notification.actor,
        )}</strong> has been
      waiting in the <strong>${stage}</strong> stage for <strong>${
          activity.match_status_days
      } days</strong>.
      Click to view.`;
        notification.route = this.getCandidateStageNotificationActivityRoute(
            activity,
            notification.route,
        );

        return notification;
    }

    private static fromEmployerUserRequestAccess(
        activityGroup: GetstreamNotificationFeed<GetstreamEmployerActivity>,
        activity: GetstreamEmployerActivity,
    ): AppNotification {
        const notification = this.fromBaseEmployerActivity(
            activityGroup,
            activity,
        );

        notification.message =
            `<strong>${activity.actor_user.firstname} ${activity.actor_user.lastname}</strong> ` +
            `has requested account access. Click here to approve or reject.`;

        return notification;
    }

    static fromBaseEmployerActivity(
        activityGroup: GetstreamNotificationFeed<GetstreamEmployerActivity>,
        activity: GetstreamEmployerActivity,
    ): AppNotification {
        const notification = new AppNotification();
        notification.id = activity.id;
        notification.activityGroupId = activityGroup.id;
        notification.date = toDate(activityGroup.created_at);
        notification.actor = {
            picture: activity.actor_user.profile_picture,
            firstName: activity.actor_user.firstname,
            lastName: activity.actor_user.lastname,
        };
        notification.isSeen = activityGroup.is_seen;
        notification.route = notificationTypeToRouterLink[activity.verb];
        notification.type = activity.verb;

        return notification;
    }

    private static getRouteForMentionInCommentNotifications(
        activity: GetstreamReviewerMentionedInCommentActivity,
    ) {
        let route = `jobs/${activity.employer_job_id}/matches`;
        if (isDefined(activity.match_id)) {
            if (!isDefined(activity.match_status)) {
                route = route.concat(`/applications/${activity.match_id}`);
            } else {
                route = route.concat(
                    activity.match_status === 'connect'
                        ? '/in-progress'
                        : '/archived',
                );
                route = route.concat(`/${activity.match_id}`);
            }
        } else {
            route = route.concat(`/applications`);
        }

        return route;
    }

    private static getCandidateStageNotificationActivityRoute(
        activity: GetstreamCandidateStageNotificationActivity,
        baseRoute: string,
    ): string {
        const tab = Object.keys(ApplicationViewedStatus).includes(
            activity.match_status,
        )
            ? 'applications'
            : 'in-progress';

        return baseRoute
            .replace('{jobId}', '' + activity.employer_job_id)
            .replace('{tab}', '' + tab)
            .replace('{matchId}', '' + activity.match_id);
    }

    private static getFirstNameWithLastNameInitial(actor: NotificationActor) {
        let name = '';
        if (isDefined(actor.firstName) && actor.firstName.length > 0) {
            name += actor.firstName;
        }

        if (isDefined(actor.lastName) && actor.lastName.length > 0) {
            name += ' ' + actor.lastName.charAt(0) + '.';
        }

        return name;
    }
}
