<h1 *ngIf="title" class="title">{{ title }}</h1>

<p
    *ngIf="topMessage"
    [innerHTML]="topMessage"
    [class.text-justified]="justifyText"
    class="top-message"
></p>

<p
    *ngFor="let item of contentMessage"
    [innerHTML]="item"
    [class.text-justified]="justifyText"
    [class.is-small]="withSmallContentMessageSize"
    class="content-message"
></p>

<p *ngIf="bottomNotes" class="bottom-notes" [innerHTML]="bottomNotes"></p>

<ng-container *ngIf="!!dontShowAgainLabel">
    <label class="sp-checkbox field-checkbox confirmation-checkbox">
        <input type="checkbox" [(ngModel)]="dontShowAgain" />
        <span class="checkbox-square"></span>
        {{ data.dontShowAgainLabel }}
    </label>
</ng-container>
