import type { ProfileInstitution } from '.';

export class CertificateItem {
    name: string;
    authority?: ProfileInstitution;
    authorityAndTitle?: string;
    domain?: string;

    constructor(data) {
        this.name = data.name;
        this.authority = data.authority;
        this.authorityAndTitle = `${this.authority.name} - ${this.name}`;
        this.domain = data.authority.domain;
    }
}
