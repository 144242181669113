<div *ngIf="control.icon" [ngClass]="iconCssClass" class="field-icon"></div>

<div class="selections-wrapper" *ngIf="selectedValues?.length > 0">
    <div class="avatar-stack" *ngFor="let item of selectedValues">
        <sp-avatars-stack [label]="item.label" [size]="24" [items]="item.avatars">
        </sp-avatars-stack>
        <a href="javascript:" class="remove-button" (click)="onRemoveStack(item.id)"></a>
    </div>
</div>

<input
    #autocompleteInputField
    [name]="control.name"
    [placeholder]="control.placeholder"
    [ngClass]="{ focused: control.focused }"
    [class.autocomplete-input-hidden]="!isAutocompleteInputVisible"
    (blur)="onInputBlur()"
    (focus)="onInputFocus()"
    class="input-field v2"
/>

<sp-autocomplete
    *ngIf="control.autocomplete && autocompleteInput?.nativeElement"
    [autocomplete]="autocompleteParams"
    class="autocomplete"
>
</sp-autocomplete>
