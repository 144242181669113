import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import type { BroadcastEvent } from '../shared/models';
import { BroadcastEventType } from '../shared/models';

@Injectable({
    providedIn: 'root',
})
export class BroadcastService {
    public broadcast$ = new Subject<BroadcastEvent>();

    broadcastChatMessageAddedEvent(hasUnconsumed: boolean): void {
        this.broadcast$.next({
            event: BroadcastEventType.unconsumedChatMessage,
            data: hasUnconsumed,
        });
    }

    broadcastEmployerFeedNotification(hasUnread: boolean): void {
        this.broadcast$.next({
            event: BroadcastEventType.employerFeedNotification,
            data: hasUnread,
        });
    }
}
