import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { distinctUntilChanged, map, pluck } from 'rxjs/operators';
import { appScrollingContainerCssClass } from './config.service';

const navbarComponentTagName = 'sp-navbar';
export const scrollDifferenceThreshold = 1; // 1px threshold for possible differences while calculation scrolling

@Injectable()
export class WindowService {
    public height$: Observable<number>;
    public width$: Observable<number>;
    public breakPoints = {
        small: 480,
        medium: 768,
        large: 992,
        larger: 1200,
        largest: 1440,
    };

    static vScrollRelativeToContainer(nativeElement: Element, y: number) {
        try {
            // attempt to scroll smoothly but ... if we cannot (browser limitation), just jump to an anchor
            nativeElement.scrollTo({ top: y, left: 0, behavior: 'smooth' });
        } catch (e) {
            nativeElement.scrollTo(0, y);
        }
    }

    static hScrollRelativeToContainer(nativeElement: Element, x: number) {
        try {
            // attempt to scroll smoothly but ... if we cannot (browser limitation), just jump to an anchor
            nativeElement.scrollTo({ left: x, behavior: 'smooth' });
        } catch (e) {
            nativeElement.scrollTo(x, 0);
        }
    }

    static vScrollContainerToBottom(container: Element) {
        const containerHeight = container.scrollHeight;
        WindowService.vScrollRelativeToContainer(container, containerHeight);
    }

    static getElementOffsetRelativeToWindow(
        element: HTMLElement,
        accountForNavbarHeight = true,
    ) {
        let e: HTMLElement | null = element;
        const offset = { x: 0, y: 0 };

        while (e) {
            offset.x += e.offsetLeft;
            offset.y += e.offsetTop;
            e = e.offsetParent as HTMLElement;
        }

        if (
            document.documentElement &&
            (document.documentElement.scrollTop ||
                document.documentElement.scrollLeft)
        ) {
            offset.x -= document.documentElement.scrollLeft;
            offset.y -= document.documentElement.scrollTop;
        } else if (
            document.body &&
            (document.body.scrollTop || document.body.scrollLeft)
        ) {
            offset.x -= document.body.scrollLeft;
            offset.y -= document.body.scrollTop;
        } else if (window.pageXOffset || window.pageYOffset) {
            offset.x -= window.pageXOffset;
            offset.y -= window.pageYOffset;
        }

        // On the views where we have a sticky navbar we need to calculate offsets as if
        // the bottom of the fixed navbar is the boundary of the viewport. Otherwise, if e.g.
        // we calculate top / bottom position of the field popover we may end up rendering
        // tooltip above the sticky navbar but the field itself is hidden under navbar
        let navbarHeight = 0;
        if (accountForNavbarHeight) {
            const navbar = document.getElementsByTagName(
                navbarComponentTagName,
            ) as HTMLCollectionOf<HTMLElement>;
            if (navbar?.length) {
                navbarHeight = navbar[0].offsetHeight;
            }
        }

        const scrollTop =
            document.getElementsByClassName('app-content')[0].scrollTop;
        offset.y = offset.y - scrollTop - navbarHeight;

        return offset;
    }

    static vScrolledToBottom(element: HTMLElement) {
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight
        // #determine_if_an_element_has_been_totally_scrolled
        return (
            Math.abs(
                element.clientHeight + element.scrollTop - element.scrollHeight,
            ) < scrollDifferenceThreshold
        );
    }

    constructor() {
        const windowSize$ = new BehaviorSubject(this.getWindowSize());

        this.height$ = windowSize$.pipe(
            pluck('height'),
            distinctUntilChanged(),
        );
        this.width$ = windowSize$.pipe(pluck('width'), distinctUntilChanged());

        fromEvent(window, 'resize')
            .pipe(map(() => this.getWindowSize()))
            .subscribe(windowSize$);
    }

    getWindowSize() {
        return {
            height: window.innerHeight,
            width: window.innerWidth,
        };
    }

    isSmall(): boolean {
        return this.breakPoints.medium > this.getWindowSize().width;
    }

    isLtLarge(): boolean {
        return this.breakPoints.large > this.getWindowSize().width;
    }

    isLtLarger(): boolean {
        return this.breakPoints.larger > this.getWindowSize().width;
    }

    isLtLargest(): boolean {
        return this.breakPoints.largest > this.getWindowSize().width;
    }

    vScrollToRelativeToScrollingContainer(y: number) {
        WindowService.vScrollRelativeToContainer(
            this.getAppParentContainerElement(appScrollingContainerCssClass),
            y,
        );
    }

    getAppParentContainerElement(cssClass: string): Element {
        // Note: please note that the name of the class being queried below should be
        // in sync with css class set on the application root wrapper tag or application
        // scrolling container
        return document.getElementsByClassName(cssClass)[0];
    }
}

// https://stackoverflow.com/a/4819886/1524551
export function isTouchDevice(): boolean {
    return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
}
