import type { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import type { OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UserType } from '../../../shared/models/user.model';
import type { SpFormControl } from '../../../shared/forms/custom-controls/sp-form-controls.model';
import { UserService } from '../../../core/user.service';
import { getEasySignupJobHashIdKeyPrefix } from '../../../core/config.service';
import { LocalStorageService } from '../../../core/local-storage.service';
import { LogInFormService } from './login-form.service';

enum LoginFormState {
    initial,
    enterPassword,
    linkSent,
    linkResent,
    error,
}

@Component({
    selector: 'sp-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
    providers: [LogInFormService],
})
export class LogInFormComponent implements OnInit, OnChanges {
    @Input() userType: UserType;
    @Input() redirectTo: string;
    @Input() prefilledEmail: string;
    @Input() isSubmittingOutside = false;

    @Output() logIn: EventEmitter<void> = new EventEmitter();
    @Output() forgotPassword: EventEmitter<void> = new EventEmitter();

    public emailNotRegistered = 'Email not registered yet.';

    public form: FormGroup;
    public controls: { [key: string]: SpFormControl };

    public isSubmitting = false;
    error: string;

    public state: LoginFormState;
    readonly LoginFormState = LoginFormState;

    constructor(
        private userService: UserService,
        private storageService: LocalStorageService,
        private logInFormService: LogInFormService,
    ) {}

    get buttonLabel() {
        return this.state === LoginFormState.initial ||
            this.state === LoginFormState.enterPassword ||
            this.state === LoginFormState.error
            ? 'Next'
            : 'Don’t see it? Resend';
    }

    get buttonDisabled(): boolean {
        return this.form.invalid || this.isSubmittingOutside;
    }

    get submissionInProgress(): boolean {
        return this.isSubmitting || this.isSubmittingOutside;
    }

    ngOnInit(): void {
        this.controls = this.logInFormService.createControls(
            this.userType,
            this.prefilledEmail,
        );
        this.form = this.logInFormService.form;
        this.state = LoginFormState.initial;
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (
            this.controls &&
            changes.userType?.currentValue !== changes.userType?.previousValue
        ) {
            this.controls['email'].label =
                changes.userType.currentValue === UserType.candidate
                    ? 'Email Address'
                    : 'Work Email Address';
        }
    }

    submitForm() {
        if (this.isSubmitting) {
            return;
        }

        if (
            this.state === LoginFormState.initial ||
            this.state === LoginFormState.error
        ) {
            this.initialLoginRequest();
        } else if (this.state === LoginFormState.enterPassword) {
            this.loginWithPassword();
        } else if (
            this.state === LoginFormState.linkSent ||
            this.state === LoginFormState.linkResent
        ) {
            this.sendLoginLink();
        }
    }

    initialLoginRequest() {
        this.error = null;
        this.isSubmitting = true;
        this.userService
            .loginRequest(this.form.value.email, {
                jobHashId: this.getGetJobHashIdFromStorage(),
                redirectTo: this.redirectTo,
            })
            .pipe(finalize(() => (this.isSubmitting = false)))
            .subscribe(
                (result) =>
                    result.hasPassword
                        ? this.setPasswordFieldAvailableState()
                        : this.setLinkSentState(),
                () => (this.state = LoginFormState.error),
            );
    }

    loginWithPassword() {
        this.isSubmitting = true;
        this.error = null;
        this.userService
            .login(this.form.value.email, this.form.value.password)
            .subscribe(
                () => this.logIn.emit(),
                (error) => {
                    this.isSubmitting = false;
                    if (error === this.emailNotRegistered) {
                        this.form.controls['email'].setErrors({
                            emailNotRegistered: true,
                        });
                    } else {
                        this.error = error;
                    }
                },
            );
    }

    sendLoginLink() {
        this.isSubmitting = true;
        this.userService
            .requestAuthLink(this.form.value.email, {
                jobHashId: this.getGetJobHashIdFromStorage(),
                redirectTo: this.redirectTo,
            })
            .pipe(finalize(() => (this.isSubmitting = false)))
            .subscribe(() =>
                this.state !== LoginFormState.linkSent &&
                this.state !== LoginFormState.linkResent
                    ? this.setLinkSentState()
                    : (this.state = LoginFormState.linkResent),
            );
    }

    setPasswordFieldAvailableState() {
        this.state = LoginFormState.enterPassword;
        this.form.controls.password.setValidators(Validators.required);
        this.form.controls.password.setValue(null);
        this.form.controls.password.markAsTouched();
    }

    setLinkSentState() {
        if (this.state === LoginFormState.enterPassword) {
            this.setPasswordFieldOptional();
        }
        this.state = LoginFormState.linkSent;
    }

    setPasswordFieldOptional() {
        this.form.controls.password.clearValidators();
        this.form.controls.password.setErrors(null);
    }

    forgotPasswordClick() {
        this.forgotPassword.emit();
    }

    private getGetJobHashIdFromStorage() {
        // check if jobHashId exists in session storage so we can create match (if not exist)
        // and navigate to it once login via emailed link
        return this.storageService.getSessionEntry(
            getEasySignupJobHashIdKeyPrefix(this.form.value.email),
        );
    }
}
