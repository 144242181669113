<form [formGroup]="form" class="login__form" (submit)="submitForm()" novalidate>
    <sp-form-input
        [control]="controls['email']"
        class="login-form__{{ controls['email'].name }}"
    ></sp-form-input>
    <sp-form-input
        [control]="controls['password']"
        [style.display]="state === LoginFormState.enterPassword ? 'block' : 'none'"
        class="login-form__{{ controls['password'].name }}"
    ></sp-form-input>
    <div *ngIf="state !== LoginFormState.initial" class="info-message">
        <span class="info-icon"></span>
        <span class="info-text">
            <span *ngIf="state === LoginFormState.enterPassword"
                >Please enter your password or
                <a class="click-here-link" href="javascript:" (click)="sendLoginLink()"
                    >click here</a
                >
                to be emailed a sign-in link. Alternatively, you can use the forgot password link
                below to change it.
            </span>
            <span *ngIf="state === LoginFormState.linkSent"
                >We just emailed you a password-free login link. It will expire shortly, so please
                check your inbox and use the provided link to sign in.
            </span>
            <span *ngIf="state === LoginFormState.linkResent"
                >We resent your password-free login link. It will expire shortly, so please check
                your inbox and use the provided link to sign in.
            </span>
            <span *ngIf="state === LoginFormState.error">
                We aren’t able to find an account for the provided email address. Please double
                check that it was entered correctly.
            </span>
        </span>
    </div>

    <label class="form-error-message" *ngIf="error">
        {{ error }}
    </label>
    <sp-stateful-button
        class="submit-btn"
        type="submit"
        name="submit"
        [label]="buttonLabel"
        [context]="'primary'"
        [disabledFromOutside]="buttonDisabled"
        [isLoading]="submissionInProgress"
    >
    </sp-stateful-button>

    <a
        *ngIf="state === LoginFormState.enterPassword"
        href="javascript:"
        class="forgot-password-link"
        (click)="forgotPasswordClick()"
    >
        Forgot password?
    </a>
</form>
