<ng-container [formGroup]="control.form">
    <ng-container *ngIf="!control.hideHeader">
        <label
            [class.has-error]="control.error"
            [class.field-label__empty]="!control.label && !showErrorAbove"
            [ngStyle]="control.labelStyle"
            class="field-label"
        >
            <span class="field-label-text">
                <span
                    *ngIf="control.labelIcon"
                    [ngClass]="getContextColoredIconCssClass(control.labelIcon)"
                    class="field-label-icon"
                >
                </span>
                <span [class.with-icon]="control.labelIcon" class="label-and-hint">
                    <span
                        *ngIf="!control.isOptional && !control.hideRequiredTooltip"
                        spTooltip
                        tooltipText="Required"
                        spWindowScroll
                        class="label"
                        (scrollEvent)="onScroll()"
                    >
                        {{ control.label }}
                        <span *ngIf="control.label">*</span>
                    </span>

                    <span *ngIf="control.isOptional" class="label">{{ control.label }}</span>
                    <span
                        *ngIf="control.infoTooltip"
                        class="tooltip-info-icon"
                        spTooltip
                        [tooltipText]="control.infoTooltip"
                        [textAlign]="'left'"
                        [widthType]="TooltipWidthType.normal"
                    >
                    </span>
                    <span *ngIf="control.hint" class="hint">{{ control.hint }}</span>
                    <a *ngIf="control.isClosable" class="close" (click)="onClose()"></a>
                </span>
            </span>

            <div class="field-label-extras" *ngIf="showErrorAbove">
                <span class="field-error">
                    {{ control.error }}
                </span>
            </div>
            <a
                *ngIf="control.spellGenerateButton?.isVisible(control.value)"
                class="ai-button"
                role="button"
                (click)="aiButtonAction()">
                <img alt="" *ngIf="aiButtonInProgress" src="/images/spinner-purple.svg" class="sp-dots-spinner spinner" />
                <span *ngIf="!aiButtonInProgress" class="sparkle"></span>
                {{control.spellGenerateButton.label}}
            </a>
        </label>
    </ng-container>

    <!-- Chips -->

    <sp-chips
        #spFormInputChildComponent
        *ngIf="control.extComponent === 'ChipsComponent'"
        [control]="control"
    >
    </sp-chips>

    <!-- Avatar Stacks Selector Component -->

    <sp-avatars-stacks-selector
        *ngIf="control.extComponent === 'AvatarStacksSelectorComponent'"
        [control]="control"
    >
    </sp-avatars-stacks-selector>

    <!-- Tag Chips -->

    <sp-tag-chips
        *ngIf="control['component'] === 'SpTagChipsInputComponent'"
        [control]="control"
        (chipsDelete)="onChipsRemoved($event)"
    >
    </sp-tag-chips>

    <!-- Composite Chips -->

    <sp-composite-chips
        *ngIf="control.extComponent === 'CompositeChipsComponent'"
        (mouseEnter)="onMouseEnter($event)"
        [control]="control"
    >
    </sp-composite-chips>

    <!-- Composite filtering Chips -->

    <sp-composite-filtering-chips
        *ngIf="control['component'] === 'CompositeFilteringChipsComponent'"
        [control]="control"
    >
    </sp-composite-filtering-chips>

    <!-- Select a.k.a. Dropdown -->

    <sp-select *ngIf="control['component'] === 'SpSelect'" [control]="control"> </sp-select>

    <!-- Input field with image selector -->

    <sp-input-with-image-selector
        *ngIf="control['component'] === 'InputWithImageSelector'"
        [control]="control"
        [iconClass]="inputFieldIconCssClasses"
    >
    </sp-input-with-image-selector>

    <!--Input with selector-->

    <sp-input-with-selector
        *ngIf="control['component'] === 'InputWithSelector'"
        [control]="control"
    >
    </sp-input-with-selector>

    <!-- Slider -->

    <sp-slider-input
        *ngIf="control['component'] === 'SpSliderComponent'"
        [control]="control"
        (closed)="onClose()"
    >
        <ng-content></ng-content>
    </sp-slider-input>

    <!-- Multi-value Slider -->

    <sp-multi-value-slider-input
        *ngIf="control['component'] === 'SpMultiValueSliderComponent'"
        [control]="control"
    >
    </sp-multi-value-slider-input>

    <!-- Single Value Autocomplete (e.g. single value location, company etc.) -->

    <sp-single-value-autocomplete
        *ngIf="control.extComponent === 'SpSingleValueAutocomplete'"
        [control]="control"
    >
    </sp-single-value-autocomplete>

    <!-- Masked Date Input -->

    <sp-masked-date-input
        #spFormInputChildComponent
        *ngIf="control['component'] === 'MaskedDateInputComponent'"
        [control]="control"
    >
    </sp-masked-date-input>

    <!-- Masked Date Range Input -->

    <sp-masked-date-range-input
        #spFormInputChildComponent
        *ngIf="control['component'] === 'MaskedDateRangeInputComponent'"
        [control]="control"
    >
    </sp-masked-date-range-input>

    <!-- Textarea -->

    <sp-textarea
        *ngIf="control.controlType === SpFormControlType.textarea"
        [control]="control"
    ></sp-textarea>

    <!-- WYSIWYG component -->

    <sp-wysiwyg-editor
        *ngIf="control['component'] === 'SpWysiwygEditorComponent'"
        [control]="control"
    >
    </sp-wysiwyg-editor>

    <!-- WYSIWYG-based Template Editor -->

    <sp-template-editor-wysiwyg
        #spFormInputChildComponent
        *ngIf="control['component'] === 'TemplateEditorWysiwygComponent'"
        [control]="control"
    >
    </sp-template-editor-wysiwyg>

    <sp-icon-buttons-group-control *ngIf="control['component'] === 'IconButtonsGroupComponent'"
                                   [control]="control"></sp-icon-buttons-group-control>

    <!-- Input -->

    <!--
    note: v2 css class HAS to be set on the input UNTIL corresponding changes added previously to
    sp-web-styles project to allow v1/v2 styles to coexist are removed
  -->
    <div
        *ngIf="isInputControl"
        [class.small]="control.size === 'small'"
        class="input-wrapper {{ spControlInputWrapperMarker }}"
    >
        <input
            [name]="control.name"
            [autocomplete]="control.browserAutocomplete"
            [type]="control['inputType']"
            [placeholder]="control.placeholder"
            [formControlName]="control.name"
            [class.not-empty]="inputFieldHasValue"
            [class.input-field-with-toggle]="isAllowPasswordUnmask"
            [attr.disabled]="control.disabled ? 'disabled' : null"
            class="v2 input-field"
            #spFormInput
        />

        <span [ngClass]="inputFieldIconCssClasses" class="field-icon"></span>

        <a
            *ngIf="isAllowPasswordUnmask"
            class="show-hide-button"
            role="button"
            (click)="onShowHidePasswordClick()"
        >
            <img class="unmask-password-icon" src="/images/icons/forms/eyeball.svg" alt="show" />
        </a>

        <sp-password-validation-details
            *ngIf="control.controlType === SpFormControlType.validatedPasswordInput"
            [control]="control"
            class="password-validation-messages"
        >
        </sp-password-validation-details>
    </div>

    <!-- sp-form-input error -->

    <div
        *ngIf="control.showValidationErrorBelowField && !control.showChildErrorMessages"
        class="field-label-extras field-label-extras-bottom v2"
    >
        <span *ngIf="control.error" class="field-error v2">{{ control.error }}</span>
    </div>
</ng-container>
