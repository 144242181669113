<div class="template-body" [innerHTML]="body"></div>

<ng-container *ngIf="bottomButtons">
    <span
        class="icon edit-button"
        spTooltip
        tooltipText="Edit"
        (click)="execTemplateAction(actionType.edit)"
    ></span>
    <span
        class="icon duplicate-button"
        spTooltip
        tooltipText="Duplicate"
        (click)="execTemplateAction(actionType.duplicate)"
    ></span>
    <span
        class="icon delete-button"
        spTooltip
        tooltipText="Delete"
        (click)="execTemplateAction(actionType.delete)"
    ></span>
</ng-container>
