export enum MatchFeedAction {
    view = 'view',
    message = 'message',
    comment = 'comment',
    approved = 'approved',
    matchArchived = 'matchArchived',
    unarchived = 'unarchived',
    invited = 'invited',
    moveToAnotherJob = 'moveToAnotherJob',

    // in-progress employer matches
    reviewedWithdrew = 'reviewedWithdrew',
    reviewedApproved = 'reviewedApproved',
    contactedPending = 'contactedPending',
    contactedResponded = 'contactedResponded',
    contactedNoResponse = 'contactedNoResponse',
    contactedWithdrew = 'contactedWithdrew',
    interviewingScheduled = 'interviewingScheduled',
    interviewingPhoneScreen = 'interviewingPhoneScreen',
    interviewingInterview = 'interviewingInterview',
    interviewingDiscarded = 'interviewingDiscarded',
    interviewingWithdrew = 'interviewingWithdrew',
    offersAccepted = 'offersAccepted',
    offersPending = 'offersPending',
    offersDeclined = 'offersDeclined',

    atsCandidateExport = 'atsCandidateExport',
    atsCandidateStatusChange = 'atsCandidateStatusChange',
}
