export enum CandidateMatchSubStatus {
    // Prefixes are added to sub statuses for proper sorting. If match is on candidate's Archived dashboard
    // tab due to job being archived by employer we do not change its sub status to be able to return it to the same
    // state if job is unarchived. The first letter in prefix is for sorting matches on archived tab and second letter is
    // for sorting matches on their respective tabs. This allows us to put all closed matches (job archived) on Archived
    // tab between disqualified and withdrew as per requirements: disqualified(a) -> closed(b, c) -> withdrew (da) ->
    // archived (db). There is no sorting required on Applied tab and second letter in prefix is only added for
    // consistency.

    ba_invited = 'ba_invited',
    bb_nonViewed = 'bb_nonViewed', // considered as new - default - not viewed by candidate
    bc_inReview = 'bc_inReview',

    // sub statuses on ca_applied tab
    ca_applied = 'ca_applied',

    cb_pendingInProgress = 'cb_pendingInProgress',
    cc_interviewing = 'cc_interviewing',
    cd_phoneScreen = 'cd_phoneScreen',

    ce_pendingOffers = 'ce_pendingOffers',
    cf_accepted = 'cf_accepted',
    cg_declined = 'cg_declined',

    // This is a status only for UI (not supported on backend).
    // It will going to be used only on applied tab in cases when there are not answered screening questions
    // or not filled in required cover letter
    ch_incomplete = 'ch_incomplete',

    // sub statuses on archived tab
    // NOTE: prefix 'c_', 'b_' and 'a_' are required for proper sorting on ca_applied tab
    aa_disqualified = 'aa_disqualified',
    da_withdrew = 'da_withdrew',
    db_archived = 'db_archived',
}
