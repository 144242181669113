<header class="header">
    <div class="header-left"></div>
    <h1 class="header-center">
        <ng-content></ng-content>
    </h1>
    <a class="header-right" (click)="onClose()">
        <span>Close</span>
        <img class="header-close-button" />
    </a>
</header>
