import type { ComponentRef, Renderer2 } from '@angular/core';
import {
    Injectable,
    InjectionToken,
    Injector,
    RendererFactory2,
} from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import type {
    ComponentType,
    ConnectedPosition,
    FlexibleConnectedPositionStrategyOrigin,
    OverlayRef,
    PositionStrategy,
} from '@angular/cdk/overlay';
import type { Observable, Subscription } from 'rxjs';

export const SP_POPOVER_DATA = new InjectionToken<any>('SpPopover');

const defaultConnectedPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
};

@Injectable({
    providedIn: 'root',
})
export class PopoverService<T> {
    private overlayRef: OverlayRef;
    private contentComponentRef: ComponentRef<T>;

    private origin: FlexibleConnectedPositionStrategyOrigin;
    private mouseEnterUnlistenFn: () => void;
    private mouseLeaveUnlistenFn: () => void;

    private renderer: Renderer2;
    private componentAction$: Observable<any>;
    private isMouseOverPopupComponent = false;
    private subscriptionAction: Subscription;

    constructor(
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private injector: Injector,
        private rendererFactory2: RendererFactory2,
    ) {}

    show(
        origin: FlexibleConnectedPositionStrategyOrigin,
        component: ComponentType<T>,
        popoverData?: any,
    ) {
        this.origin = origin;
        if (this.overlayRef) {
            if (this.overlayRef.hasAttached()) {
                this.overlayRef.detach();
            }

            const positionStrategy: PositionStrategy =
                this.overlayPositionBuilder
                    .flexibleConnectedTo(this.origin)
                    .withPositions([defaultConnectedPosition]);
            this.overlayRef.updatePositionStrategy(positionStrategy);
        } else {
            this.createOverlay();
        }

        const portal = new ComponentPortal(
            component,
            null,
            this.createInjector(popoverData),
        );
        this.contentComponentRef = this.overlayRef.attach(portal);
    }

    showAfterTimeout(
        origin: FlexibleConnectedPositionStrategyOrigin,
        component: ComponentType<T>,
        popoverData?: any,
    ) {
        setTimeout(() => {
            this.show(origin, component, popoverData);
            this.addActionsAndListeners(popoverData);
        }, 10);
    }

    hide() {
        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
        this.origin = null;
    }

    hideAfterTimeout() {
        setTimeout(() => {
            if (!this.isMouseOverPopupComponent) {
                if (this.subscriptionAction) {
                    this.subscriptionAction.unsubscribe();
                }
                if (this.mouseEnterUnlistenFn) {
                    this.mouseEnterUnlistenFn();
                }
                if (this.mouseLeaveUnlistenFn) {
                    this.mouseLeaveUnlistenFn();
                }

                this.hide();
            }
        }, 10);
    }

    private createOverlay() {
        const defaultPositionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.origin)
            .withPositions([defaultConnectedPosition]);

        this.overlayRef = this.overlay.create({
            positionStrategy: defaultPositionStrategy,
        });
    }

    private createInjector(data: any): Injector {
        data = data || {};

        const tokens = new WeakMap();
        tokens.set(SP_POPOVER_DATA, data);

        return new PortalInjector(this.injector, tokens);
    }

    private addActionsAndListeners(popoverData?: any) {
        // componentAction is EventEmitter on the component which will be shown in the popup service
        // Component itself is not obligated to have componentAction.
        this.componentAction$ =
            // @ts-expect-error TODO: Find a solid way to do this
            this.contentComponentRef.instance.componentAction;
        if (this.componentAction$) {
            this.subscriptionAction = this.componentAction$.subscribe(
                (action) => popoverData.executeActionCallback(action),
            );
        }

        this.renderer = this.rendererFactory2.createRenderer(null, null);
        this.mouseEnterUnlistenFn = this.renderer.listen(
            this.contentComponentRef.location.nativeElement,
            'mouseenter',
            (event: MouseEvent) => {
                event.stopPropagation();
                this.isMouseOverPopupComponent = true;
            },
        );
        this.mouseLeaveUnlistenFn = this.renderer.listen(
            this.contentComponentRef.location.nativeElement,
            'mouseleave',
            (event: MouseEvent) => {
                event.stopPropagation();
                this.isMouseOverPopupComponent = false;
                this.hideAfterTimeout();
            },
        );
    }
}
