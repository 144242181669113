import type { AbstractControl, ValidatorFn } from '@angular/forms';

const minLength = 10;
const min1Number = new RegExp('^(?=.*[0-9])\\S+$');
const min1UppercaseOrSymbol = new RegExp(
    '^(?=.*[A-Z])\\S+$|^(?=.*[^A-Za-z0-9])\\S+$',
);
const min1Lowercase = new RegExp('^(?=.*[a-z])\\S+$');

export const validationMessages = {
    minlength: `${minLength}+ characters`,
    min1Lowercase: '1 lowercase character',
    min1Number: '1 number',
    min1SymbolOrUppercase: '1 symbol or uppercase character',
};

export function passwordValidator(): ValidatorFn {
    return (
        control: AbstractControl,
    ): Record<string, Record<string, string>> | null =>
        validatePassword(control.value);
}

export function validatePassword(
    password: string,
): Record<string, Record<string, string>> | null {
    const validationErrors: Record<string, Record<string, string>> = {};

    if (!password || password.length < minLength) {
        validationErrors['minlength'] = {
            value: password,
            message: validationMessages.minlength,
        };
    }

    if (!min1Number.test(password)) {
        validationErrors['min1Number'] = {
            value: password,
            message: validationMessages.min1Number,
        };
    }

    if (!min1Lowercase.test(password)) {
        validationErrors['min1Lowercase'] = {
            value: password,
            message: validationMessages.min1Lowercase,
        };
    }

    if (!min1UppercaseOrSymbol.test(password)) {
        validationErrors['min1SymbolOrUppercase'] = {
            value: password,
            message: validationMessages.min1SymbolOrUppercase,
        };
    }

    return Object.keys(validationErrors).length > 0 ? validationErrors : null;
}
