import type { CountryCode } from 'libphonenumber-js';
import { isValidNumberForRegion } from 'libphonenumber-js';
import { isDefined, isValidEmail, prefixUrl } from '../../../../shared/utils';
import type { GeoLocation } from '../../../../core/google-api.service';
import { getCountryCodeFromPhoneNumber } from '../../../../shared/country-utils';
import { COMMON_FORM_VALIDATORS } from '../../../../core/config.service';
import type { IPersonalInfo } from './personal-info.interface';

export class PersonalInfo {
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    email: string;
    website?: string;
    publicProfileUrl?: string;
    summary?: string;
    location: GeoLocation;
    openToRelocation?: boolean;
    requiresSponsorship?: boolean;

    constructor(personalInfo: IPersonalInfo) {
        if (personalInfo) {
            this.firstName = personalInfo.firstName;
            this.lastName = personalInfo.lastName;
            this.phoneNumber = personalInfo.phoneNumber;
            this.email = personalInfo.email;
            this.website = prefixUrl(personalInfo.website);
            this.publicProfileUrl = prefixUrl(
                personalInfo.publicProfileUrl,
                'https://',
            );
            this.summary = personalInfo.summary;
            this.openToRelocation = personalInfo.openToRelocation;
            this.requiresSponsorship = personalInfo.requiresSponsorship;
            this.location = personalInfo.location || null;
        }
    }

    isValid() {
        let isValidPhoneNumber = true;
        if (isDefined(this.phoneNumber)) {
            const countryCode = getCountryCodeFromPhoneNumber(
                this.phoneNumber,
            ).toUpperCase();
            isValidPhoneNumber = isValidNumberForRegion(
                this.phoneNumber,
                countryCode as CountryCode,
            );
        }

        return (
            isDefined(this.firstName, this.lastName) &&
            this.firstName.trim().length >= 2 &&
            this.firstName.trim().length <=
                COMMON_FORM_VALIDATORS.firstNameMaxLength &&
            this.lastName.trim().length >= 2 &&
            this.lastName.trim().length <=
                COMMON_FORM_VALIDATORS.lastNameMaxLength &&
            isValidPhoneNumber &&
            isValidEmail(this.email) &&
            !!this.location?.id &&
            !!this.location?.value &&
            isDefined(this.requiresSponsorship)
        );
    }
}
