import { v4 as uuidv4 } from 'uuid';

export interface GenericIdName<T = number> {
    id: T;
    name: string;
}

export function toGenericUidName(name: string): GenericIdName<string> {
    return { id: uuidv4(), name };
}

export function toGenericIdName(name: string): GenericIdName<string> {
    return { id: name, name };
}
