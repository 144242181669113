<header class="modal-header">
    <a href="javascript:" class="close-button" (click)="closeModal()"></a>
</header>

<img class="success-image" src="../../../../../../images/modals/welcome/success_icon.svg" />
<h1 class="modal-title">{{ modalData.title }}</h1>
<h3 class="modal-subtitle">{{ modalData.subtitle }}</h3>
<p class="modal-info-text">{{ modalData.infoText }}</p>

<img class="glow-right" src="../../../../../../images/glows/purple_glow_right.svg" />
<img class="glow-left" src="../../../../../../images/glows/purple_glow_left.svg" />

<a class="review-button" (click)="closeModal()">{{ modalData.buttonLabel }}</a>
