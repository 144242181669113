<tag-input
    class="tag-input"
    [(ngModel)]="control.selectedChips"
    [theme]="control.theme"
    [hideForm]="true"
    [modelAsStrings]="true"
>
    <ng-template let-item="item">
        <span class="close-icon" (click)="removeFromSelected(item)"></span>
        <span class="ng2-tag__text sp__draggable" (click)="onChipClicked()">
            {{ item }}
        </span>
    </ng-template>
</tag-input>
