import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { SpCompositeFilterChipsControl } from '../sp-form-controls.model';
import type { SpTagChipsInputControl } from '../sp-form-controls.model';
import { CompositeFilteringChipsService } from './composite-filtering-chips.service';

@Component({
    selector: 'sp-composite-filtering-chips',
    templateUrl: './composite-filtering-chips.component.html',
    styleUrls: ['./composite-filtering-chips.component.scss'],
    providers: [CompositeFilteringChipsService],
})
export class CompositeFilteringChipsComponent implements OnInit {
    @Input()
    public control: SpCompositeFilterChipsControl;

    spTagChipsControl: SpTagChipsInputControl;
    selectedChips: string[];
    allChips: string[];
    theme: string;
    filteredChips: string[];
    newTag = '';

    get canAdd() {
        if (
            this.filteredChips === undefined ||
            this.newTag.trim().length === 0
        ) {
            return false;
        }

        return this.filteredChips.length === 0;
    }

    constructor(
        private compositeFilteringChipsService: CompositeFilteringChipsService,
    ) {}

    ngOnInit() {
        this.allChips = this.control.allChips;
        this.selectedChips = this.control.selectedChips;
        this.theme = this.control.theme;
        this.filteredChips = this.allChips;
        this.spTagChipsControl =
            this.compositeFilteringChipsService.initSpComponentData(
                this.control,
            );
    }

    removeFromSelected(item: string) {
        const index = this.selectedChips.indexOf(item);
        if (index !== -1) {
            this.selectedChips.splice(index, 1);
        }
        this.filterChips();
    }

    addToSelected(item: string) {
        if (this.selectedChips.indexOf(item) > -1) {
            return;
        }

        this.selectedChips.push(item);
        this.filterChips();
    }

    createTag() {
        this.selectedChips.push(this.newTag);
        this.newTag = '';
        this.filteredChips = this.allChips;
    }

    filterChips() {
        this.filteredChips = this.allChips.filter((item) =>
            item.includes(this.newTag),
        );
    }
}
