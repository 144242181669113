import { EmployerConnectedMatchStatus } from './employer-connected-match-status.model';

export enum TemplateStage {
    incomplete = 'incomplete',
    moveToAnotherJob = 'moveToAnotherJob',

    approved = 'approved',
    archive = 'archive',
    invited = 'invited',
    withdrew = 'withdrew',

    pendingContacted = 'pendingContacted',
    responded = 'responded',
    noResponse = 'noResponse',

    scheduled = 'scheduled',
    phoneScreen = 'phoneScreen',
    interview = 'interview',
    disqualified = 'disqualified',

    accepted = 'accepted',
    pending = 'pending',
    declined = 'declined',
}

export function fromConnectedMatchStatus(status: string) {
    switch (status) {
        case EmployerConnectedMatchStatus.approved:
            return TemplateStage.approved;
        case EmployerConnectedMatchStatus.invited:
            return TemplateStage.invited;
        case EmployerConnectedMatchStatus.withdrawnContacted:
        case EmployerConnectedMatchStatus.withdrawnInterviewing:
        case EmployerConnectedMatchStatus.withdrawnReviewed:
            return TemplateStage.withdrew;
        case EmployerConnectedMatchStatus.pendingContacted:
            return TemplateStage.pendingContacted;
        case EmployerConnectedMatchStatus.responded:
            return TemplateStage.responded;
        case EmployerConnectedMatchStatus.noResponse:
            return TemplateStage.noResponse;
        case EmployerConnectedMatchStatus.scheduled:
            return TemplateStage.scheduled;
        case EmployerConnectedMatchStatus.phoneScreen:
            return TemplateStage.phoneScreen;
        case EmployerConnectedMatchStatus.interview:
            return TemplateStage.interview;
        case EmployerConnectedMatchStatus.disqualified:
            return TemplateStage.disqualified;
        case EmployerConnectedMatchStatus.accepted:
            return TemplateStage.accepted;
        case EmployerConnectedMatchStatus.pending:
            return TemplateStage.pending;
        case EmployerConnectedMatchStatus.declined:
            return TemplateStage.declined;
        default:
            return null;
    }
}
