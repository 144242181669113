<div
    class="upload-file-area"
    ngFileDrop
    [class.upload-in-progress]="inProgress"
    [options]="options"
    [uploadInput]="uploadInput"
    (uploadOutput)="onUploadOutput($event)"
    (click)="onDropAreaClick()"
>
    <span *ngIf="isErrorState && errorMessage" class="error-message">
        <span class="error-message-icon"></span>
        <span>{{ errorMessage }}</span>
    </span>

    <sp-box-loading-indicator
        *ngIf="inProgress && displayBoxLoadingIndicator"
        class="in-progress-indicator"
        [size]="40"
    >
    </sp-box-loading-indicator>

    <ng-container *ngIf="!inProgress">
        <img class="uploader-icon" src="/images/icons/upload_secondary.svg" />
        <p class="upload-file-area-desc" [innerHTML]="dropAreaMessage"></p>
        <p class="upload-data-type-info">{{ dropAreaFileTypeMessage }}</p>
    </ng-container>

    <ng-container *ngIf="inProgress">
        <div class="file-upload-percentage-step">
            Parsing Your Resume<br />
            {{ percentsCompletedWithSteps }}% Completed...
        </div>
        <sp-step-progress-animation
            class="progress-animation"
            [width]="percentsCompletedWithSteps"
            [backgroundColor]="progressBackgroundColor"
        >
        </sp-step-progress-animation>
    </ng-container>
</div>

<label for="sp-file-upload-v2" class="browse-button" *ngIf="!inProgress" #browseButton> </label>
<input
    #uploader
    id="sp-file-upload-v2"
    class="file-input"
    type="file"
    ngFileSelect
    [options]="options"
    (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput"
/>
