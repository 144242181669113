import { isDefined } from './utils';

enum ChunkType {
    text = 'text',
    placeholder = 'placeholder',
    link = 'link',
}

// Describes single 'chunk' found in the raw text
// those 'chunks' will be extracted from raw text for further
// conversion into Deltas
interface ChunkPosition {
    start: number;
    length: number;
    value: string;
    url?: string;
    type: ChunkType;
}

// Represents a smallest piece extracted from text (text or placeholder)
// for further conversion into a set of Delta's insert operations to build
// Delta tree to init Quill with
interface InsertChunk {
    value: string;
    type: ChunkType;
    url?: string;
}

const linkRegexp = new RegExp(`<a href="([^"]+)">([^<]+)<\\/a>`, 'g');

export function createChunksFromString(
    value: string,
    placeholderRegexps: RegExp[],
): InsertChunk[] {
    const chunkPositions: ChunkPosition[] = [];

    // find occurrences of supported placeholders in the raw template tag
    placeholderRegexps.forEach((re) => {
        re.lastIndex = 0;

        let execArray;
        while ((execArray = re.exec(value)) !== null) {
            chunkPositions.push({
                start: execArray.index,
                length: execArray[0].length,
                value: execArray[0],
                type: ChunkType.placeholder,
            });
        }
    });

    // Introducing validator bits, so we can mechanism to check whether chunk is already created for part of the string
    // one possible example is when we have an placeholder inside url
    let validatorBits: boolean[] = Array(value.length).fill(false);

    // find occurrences of urls
    let urlArray;
    while ((urlArray = linkRegexp.exec(value)) !== null) {
        validatorBits = validatorBits.fill(
            true,
            urlArray.index,
            urlArray.index + urlArray[0].length,
        );
        chunkPositions.push({
            start: urlArray.index,
            length: urlArray[0].length,
            value: urlArray[2],
            url: urlArray[1],
            type: ChunkType.link,
        });
    }

    chunkPositions.sort((p1, p2) => p1.start - p2.start);

    // convert raw text into a set of chunks to be later mapped into Delta's nodes
    const chunks: InsertChunk[] = [];
    let charIndex = 0;
    chunkPositions.forEach((chunkPos) => {
        const endPos = chunkPos.start;
        if (charIndex < endPos) {
            chunks.push({
                value: value.substr(charIndex, endPos - charIndex),
                type: ChunkType.text,
            });
        }

        chunks.push({
            value: chunkPos.value,
            type: chunkPos.type,
            url: chunkPos.url,
        });

        charIndex = chunkPos.start + chunkPos.length;
    });

    if (charIndex < value.length) {
        chunks.push({ value: value.substr(charIndex), type: ChunkType.text });
    }

    return chunks;
}

// If the url provided does not start with any of whitelisted protocols it will be
// treated as a relative one. This prevents default behavior.
export function customizeLinkBlot(Link: any) {
    class CustomLink extends Link {
        static sanitize(url: string) {
            const value = super.sanitize(url);
            const startsWithWhitelistedPrefix =
                isDefined(value) &&
                this.PROTOCOL_WHITELIST.some((prefix: string) =>
                    value.startsWith(prefix),
                );

            return startsWithWhitelistedPrefix ? value : `https://${value}`;
        }
    }

    return CustomLink;
}
