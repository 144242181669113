import type { Media } from '@twilio/conversations';
import { isDefined } from '../../utils';
import { computeUnconsumedState } from '../chat-utils';

export class ThreadMessageConsumptionStatus {
    lastMessageIndex: number;
    lastConsumedMessageIndex: number;
    lastMessageAuthor?: string;
    lastMessageTimestamp?: Date;
    lastMessageBody?: string;
    isLastMessageContainingAttachment?: boolean;
    lastMessageAttachmentFilename?: string;
    lastMessageAttachmentFilesize?: number;

    constructor(lastMessageIndex: number, lastConsumedMessageIndex: number) {
        this.lastMessageIndex = lastMessageIndex;
        this.lastConsumedMessageIndex = lastConsumedMessageIndex;
    }

    setLastMessageInformation(
        author: string,
        messageTimestamp: Date,
        body: string,
        media: Media,
    ) {
        this.lastMessageAuthor = author;
        this.lastMessageTimestamp = messageTimestamp;
        this.lastMessageBody = body;

        if (media) {
            this.isLastMessageContainingAttachment = true;
            this.lastMessageAttachmentFilename = media.filename;
            this.lastMessageAttachmentFilesize = media.size;
        }
    }

    hasUnconsumed(): boolean {
        return computeUnconsumedState(
            this.lastMessageIndex,
            this.lastConsumedMessageIndex,
        );
    }

    containsLastMessageInformation(): boolean {
        return (
            isDefined(this.lastMessageAuthor) &&
            isDefined(this.lastMessageTimestamp)
        );
    }
}
