import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TemplateStage } from '../shared/models/enumeration/template-stage.model';
import type { MessageTemplate } from '../shared/messaging-chat/model/message-template';
import { toDate } from '../shared/date-utils';

@Injectable()
export class MessageTemplateService {
    constructor(private http: HttpClient) {}

    getMessageTemplateForStage(
        stage: TemplateStage,
    ): Observable<MessageTemplate> {
        return this.http.get<MessageTemplate>(`/api/chat/templates/${stage}`);
    }

    getAllTemplates(): Observable<MessageTemplate[]> {
        return this.http
            .get<MessageTemplate[]>(`/api/chat/templates`)
            .pipe(
                map((templates) =>
                    templates.map((template) =>
                        this.mapToMessageTemplateItem(template),
                    ),
                ),
            );
    }

    deleteTemplate(templateId: number, version: number): Observable<void> {
        return this.http.delete<void>(`/api/chat/templates/${templateId}`, {
            params: { version: `${version}` },
        });
    }

    initTemplateDefaultsMap(): { [key in TemplateStage]: boolean } {
        return {
            [TemplateStage.incomplete]: false,
            [TemplateStage.moveToAnotherJob]: false,

            [TemplateStage.approved]: false,
            [TemplateStage.archive]: false,
            [TemplateStage.invited]: false,
            [TemplateStage.withdrew]: false,

            [TemplateStage.pendingContacted]: false,
            [TemplateStage.responded]: false,
            [TemplateStage.noResponse]: false,

            [TemplateStage.scheduled]: false,
            [TemplateStage.phoneScreen]: false,
            [TemplateStage.interview]: false,
            [TemplateStage.disqualified]: false,

            [TemplateStage.accepted]: false,
            [TemplateStage.pending]: false,
            [TemplateStage.declined]: false,
        };
    }

    private mapToMessageTemplateItem(template: any) {
        return {
            ...template,
            recentlyUsedDate: toDate(template.recentlyUsedDate),
        };
    }
}
