import { Subject, Subscription } from 'rxjs';
import { EventEmitter } from '@angular/core';
import type { TagModelClass } from 'ngx-chips/core/accessor';
import { unsubscribeIfActive } from '../../../../utils';
import { SpFormControl, SpFormControlType } from '../../sp-form-controls.model';
import type { CompositeChipsContainer } from './composite-chips-container.model';
import type { SpChipsDynamicPropertyMap } from './chips-dynamic-property-map.model';
import type { SpChipsControlParams } from './chips-form-input-control-params.model';

export class SpChipsInputControl extends SpFormControl {
    controlType = SpFormControlType.component;
    extComponent = 'ChipsComponent';

    allowExcessItems: boolean;
    showCounter: boolean;
    compositeModePosition?: CompositeChipsContainer;
    showCaret: boolean;
    // Adds bottom counter
    withBottomCounter?: boolean;

    onBeforeAddFunc?: (newItem) => SpChipsDynamicPropertyMap;
    orderingFunc?: (a, b) => number;

    // outputs
    chipsInputFocus: EventEmitter<void>;
    setValue$: Subject<TagModelClass[]>;

    private subscription: Subscription;

    constructor(params: SpChipsControlParams) {
        super(params);
        this.subscription = new Subscription();

        this.showCounter = !!params.showCounter;
        this.withBottomCounter = !!params.withBottomCounter;
        this.allowExcessItems =
            params.allowExcessItems === undefined
                ? false
                : params.allowExcessItems;
        this.compositeModePosition = params.compositeModePosition;
        this.showCaret = !!params.showCaret;
        this.onBeforeAddFunc = params.onBeforeAddFunc;
        this.orderingFunc = params.orderingFunc;
        this.chipsInputFocus = new EventEmitter<void>();
        this.setValue$ = new Subject();
        this.spellGenerateButton = params.spellGenerateButton;
    }

    onDestroyFn() {
        unsubscribeIfActive(this.subscription);
    }

    emitFocusEvent() {
        this.chipsInputFocus.next();
    }
}
