import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isIE } from '../utils';

@Component({
    selector: 'sp-box-loading-indicator',
    templateUrl: './box-loading-indicator.component.html',
    styleUrls: ['./box-loading-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxLoadingIndicatorComponent implements OnInit {
    @Input() size: number;

    isIe: boolean;

    ngOnInit() {
        this.isIe = isIE();
    }
}
