import type { ChatChannelAttributes } from './chat-channel-attributes.model';

export class ThreadAttributeUpdatedEvent {
    channelSid: string;
    attributes: ChatChannelAttributes;

    constructor(channelSid: string, attributes: ChatChannelAttributes) {
        this.channelSid = channelSid;
        this.attributes = attributes;
    }
}
