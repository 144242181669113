import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { from, of } from 'rxjs';
import { UserService } from '../../core/user.service';
import { isDefined } from '../utils';

@Injectable({
    providedIn: 'root',
})
export class FeatureService {
    private flags: Record<string, boolean> = {};

    constructor(
        private http: HttpClient,
        userService: UserService,
    ) {
        userService.user$.subscribe((user) => {
            this.clearCache();
        });
    }

    getFeature(id: string): Observable<boolean> {
        const cached = this.flags[id];

        return isDefined(cached)
            ? of(cached)
            : this.http.get<boolean>(`/public/feature/${id}`).pipe(
                  tap((flag) => {
                      this.flags[id] = flag;
                  }),
              );
    }

    getAccessibleItemsFromList<T extends { feature?: string }>(
        items: T[],
    ): Observable<T[]> {
        return from(items).pipe(
            mergeMap((item, index) =>
                isDefined(item.feature)
                    ? this.isFeaturedItemEnabled(item, index)
                    : of({ index, item, isOn: true }),
            ),
            filter((obj) => obj.isOn),
            toArray(),
            map((results) =>
                results
                    .sort((a, b) => a.index - b.index)
                    .map((result) => result.item),
            ),
        );
    }

    private isFeaturedItemEnabled<T extends { feature?: string }>(
        item: T,
        index: number,
    ): Observable<{ index: number; item: T; isOn: boolean }> {
        return this.getFeature(item.feature).pipe(
            map((isOn) => ({ index, item, isOn })),
        );
    }

    private clearCache() {
        this.flags = {};
    }
}
