import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { AuthRoutingModule, routedComponents } from './auth.routing';
import { SocialAuthComponent } from './social-auth/social-auth.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AuthComponent } from './auth.component';
import { SignUpFormComponent } from './forms/signup-form/signup-form.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { EasySignupComponent } from './easy-signup/easy-signup.component';
import { JobItemComponent } from './easy-signup/job-item/job-item.component';
import { RequestAccessFormComponent } from './forms/request-access-form/request-access-form.component';
import { InformationFormComponent } from './information-form/information-form.component';
import { JoinEmployerFormComponent } from './forms/join-employer-form/join-employer-form.component';

@NgModule({
    imports: [SharedModule, AuthRoutingModule],
    declarations: [
        routedComponents,
        SocialAuthComponent,
        PasswordResetComponent,
        AuthComponent,
        SignUpFormComponent,
        EmailVerificationComponent,
        EasySignupComponent,
        JobItemComponent,
        RequestAccessFormComponent,
        InformationFormComponent,
        JoinEmployerFormComponent,
    ],
})
export class AuthModule {}
