import type { ActivatedRouteSnapshot } from '@angular/router';
import { BaseRouteReuseStrategy } from '@angular/router';

const ROUTES_TO_REUSE_COMPONENT = ['signup/:userType', 'login/:userType'];

export class AuthRouteReuseStrategy extends BaseRouteReuseStrategy {
    shouldReuseRoute(
        future: ActivatedRouteSnapshot,
        curr: ActivatedRouteSnapshot,
    ): boolean {
        return (
            future.routeConfig === curr.routeConfig ||
            this.isSwitchingBetweenLoginAndSignup(
                future.routeConfig?.path,
                curr.routeConfig?.path,
            )
        );
    }

    private isSwitchingBetweenLoginAndSignup(
        futurePath?: string,
        currPath?: string,
    ): boolean {
        return (
            !!futurePath &&
            !!currPath &&
            ROUTES_TO_REUSE_COMPONENT.includes(futurePath) &&
            ROUTES_TO_REUSE_COMPONENT.includes(currPath)
        );
    }
}
