import * as uuid from 'uuid';
import type { ProfileInstitution } from '../../../../shared/models';
import type { IEducation } from './education.interface';

export class Education implements IEducation {
    _uuid?: string;
    _isNew?: boolean;

    school: ProfileInstitution;
    degree?: { [key: string]: any };
    startDate?: Date;
    endDate?: Date;
    isCurrent?: boolean;
    fieldOfStudy: { [key: string]: any };

    static createBlank() {
        return new Education({
            _uuid: uuid(),
            _isNew: true,

            school: null,
            degree: null,
            startDate: null,
            endDate: null,
            isCurrent: false,
            fieldOfStudy: null,
        });
    }

    constructor(education: IEducation) {
        this._uuid = education._uuid || uuid();
        this._isNew = education._isNew || false;

        this.school = education.school;
        this.degree = education.degree;
        this.startDate = education.startDate
            ? new Date(education.startDate)
            : null;
        this.endDate = education.endDate ? new Date(education.endDate) : null;
        this.isCurrent = education.isCurrent;
        this.fieldOfStudy = education.fieldOfStudy;
    }

    validate(): string[] {
        const errors = [];

        if (!(this.school && this.school.name)) {
            errors.push('School');
        }

        if (
            this.startDate &&
            this.endDate &&
            this.startDate.getTime() > this.endDate.getTime()
        ) {
            errors.push('Start - End Date range');
        }

        if (!this.fieldOfStudy) {
            errors.push('Field of Study');
        }

        return errors.length ? errors : null;
    }

    clone(): Education {
        return new Education({
            _uuid: this._uuid || uuid(),
            _isNew: this._isNew,

            school: this.school ? { ...this.school } : this.school,
            degree: this.degree ? { ...this.degree } : this.degree,
            startDate: this.startDate ? new Date(this.startDate) : null,
            endDate: this.endDate ? new Date(this.endDate) : null,
            isCurrent: this.isCurrent,
            fieldOfStudy: this.fieldOfStudy ? { ...this.fieldOfStudy } : null,
        });
    }

    isValid() {
        return this.validate() === null;
    }
}
