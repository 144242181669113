import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, HostBinding, Input } from '@angular/core';
import { JobInfo } from '../models';
import {
    JobLocationType,
    jobLocationOptionNames,
} from '../models/job-info.model';

@Component({
    selector: 'sp-composite-location-view',
    templateUrl: './composite-location-view.component.html',
    styleUrls: ['./composite-location-view.component.scss'],
})
export class CompositeLocationViewComponent implements OnChanges {
    readonly JobLocationType = JobLocationType;
    @Input() job: JobInfo;
    @Input() groupedOfficeLocations: boolean;
    @Input() viewType: 'inline' | 'block' | 'standard' = 'standard';
    @Input() colorfulChip = true;
    @Input() showAdditionalInfo = false;

    officeLocations: string[] | undefined;
    officeLocationsPreviewLocation: string;
    officeLocationsPlusNMore: string | null;
    officeLocationsTooltipOpened: boolean | null;
    tooltipOfficeLocations: string;

    candidateLocations: string[];
    candidateLocationsPreviewLocation: string | null;
    candidateLocationsPlusNMore: string | null;
    candidateLocationsTooltipOpened: boolean;
    tooltipCandidateLocations: string | null;
    locationTypeChip: string | null;

    @HostBinding('class.inline')
    get inlineStyle() {
        return this.viewType === 'inline';
    }

    @HostBinding('class.standard')
    get standardStyle() {
        return this.viewType === 'standard';
    }

    @HostBinding('class.block')
    get blockStyle() {
        return this.viewType === 'block';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes &&
            changes.job &&
            changes.job.previousValue !== changes.job.currentValue
        ) {
            this.prepareData();
        }
    }

    private prepareData() {
        this.resetValues();

        this.locationTypeChip = this.job.jobLocationType
            ? jobLocationOptionNames[this.job.jobLocationType]
            : null;
        this.officeLocations = this.job.officeLocations?.map(
            (loc) => loc.value,
        );

        this.mapGroupedCandidateLocations();

        if (this.groupedOfficeLocations && this.job.officeLocations?.length) {
            this.mapGroupedOfficeLocations();
        }
    }

    private mapGroupedCandidateLocations() {
        if (!this.job.isRemote() || !this.job.candidateLocations?.length) {
            return;
        }
        this.candidateLocationsPreviewLocation = `Only in ${this.job.candidateLocations[0].value}`;
        this.tooltipCandidateLocations = this.job.candidateLocations
            ?.map((l) => `<div>${l.value}</div>`)
            .join('');
        if (this.job.candidateLocations.length > 1) {
            this.candidateLocationsPreviewLocation += `,`;
            this.candidateLocationsPlusNMore = ` +${
                this.job.candidateLocations.length - 1
            }`;
        }
    }

    private mapGroupedOfficeLocations() {
        this.officeLocationsPreviewLocation = this.job.officeLocations[0].value;
        this.tooltipOfficeLocations = this.job.officeLocations
            .map((l) => `<div>${l.value}</div>`)
            .join('');
        if (this.job.officeLocations.length > 1) {
            this.officeLocationsPreviewLocation += `,`;
            this.officeLocationsPlusNMore = ` +${
                this.job.officeLocations.length - 1
            }`;
        }
    }

    private resetValues() {
        // In some cases we do not destroy&create this component but we simply change input bindings.
        // For those cases we need to reset props in order to properly calculate new values
        this.candidateLocationsPreviewLocation = null;
        this.tooltipCandidateLocations = null;
        this.candidateLocationsPreviewLocation = null;
        this.candidateLocationsPlusNMore = null;
        this.officeLocationsPreviewLocation = null;
        this.tooltipOfficeLocations = null;
        this.officeLocationsPlusNMore = null;
    }
}
