import * as uuid from 'uuid';
import { isValidDate } from '../../../../shared/forms/validators/date.validator';
import type { ProfileInstitution } from '../../../../shared/models';
import type { CertificateItem } from '../../../../shared/models/certificate-item.model';
import type { ICertification } from './certification.interface';

export class Certification implements ICertification {
    _uuid?: string;
    _isNew?: boolean;

    title: CertificateItem;
    authority: ProfileInstitution;
    year: Date;

    static createBlank() {
        return new Certification({
            _uuid: uuid(),
            _isNew: true,

            title: null,
            authority: null,
            year: null,
        });
    }

    static of(certificate: any) {
        return new Certification({
            _uuid: uuid(),
            _isNew: true,

            title: certificate.title,
            authority: certificate.authority,
            year: certificate.year ? new Date(certificate.year) : null,
        });
    }

    constructor(certification: ICertification) {
        this._uuid = certification._uuid || uuid();
        this._isNew = certification._isNew || false;

        this.title = certification.title;
        this.authority = certification.authority?.name
            ? certification.authority
            : null;
        this.year = certification.year ? new Date(certification.year) : null;
    }

    validate(): string[] {
        const errors = [];

        if (!(this.title && this.title.name)) {
            errors.push('Title');
        }

        if (!(this.authority && this.authority.name)) {
            errors.push('Authority');
        }

        if (!this.year || isValidDate({ value: this.year }, true, true)) {
            errors.push('Year');
        }

        return errors.length ? errors : null;
    }

    public isValid(): boolean {
        return this.validate() === null;
    }

    clone(): Certification {
        return new Certification({
            _uuid: this._uuid || uuid(),
            _isNew: this._isNew,

            title: this.title ? { ...this.title } : this.title,
            authority: this.authority ? { ...this.authority } : this.authority,
            year: this.year ? new Date(this.year) : this.year,
        });
    }
}
