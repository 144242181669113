import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DecisionType, MatchCandidate } from '../shared/models';
import type { Page, User } from '../shared/models';
import { isDefined } from '../shared/utils';
import { DashboardMatchType } from '../matches/candidate/model';
import type { InviteToApplyResponseType } from '../auth/auth.component';
import { CandidateApplySource } from '../shared/models/enumeration/candidate-apply-source.enum';
import { UserService } from './user.service';

@Injectable()
export class CandidateMatchesApiService {
    constructor(
        private userService: UserService,
        private router: Router,
        private http: HttpClient,
    ) {}

    // check if match exists for given jobHashId and userId
    // - if not - generate one and navigate to that match
    // - if yes - just navigate to it
    applyForMatchOrGenerateNewIfDoesNotExistAndNavigateTo(
        jobHashId: any,
        user: User,
    ): Observable<MatchCandidate> {
        let matchCandidate: Observable<MatchCandidate> =
            this.applyForJobByHashId(jobHashId, user.id);
        matchCandidate = matchCandidate.pipe(
            switchMap((existingMatch) => {
                if (!isDefined(existingMatch)) {
                    // no match found - generate
                    return this.generateEmptyMatch(
                        jobHashId,
                        CandidateApplySource.jobLandingPage,
                        DecisionType.connect,
                    );
                }
                // match found - navigate
                this.routeToAppropriateLocation(existingMatch);

                return EMPTY;
            }),
            tap((match) => {
                if (
                    !user.optionalTraitsAssessments &&
                    MatchCandidate.of(match).job.getOptionalTraitsAssessments()
                ) {
                    user.optionalTraitsAssessments = true;
                    this.userService.user = user;
                }
            }),
            catchError(() => {
                this.router.navigate(['/matches']);

                return EMPTY;
            }),
        );

        return matchCandidate.pipe(
            tap(
                (match) =>
                    this.routeToAppropriateLocation(MatchCandidate.of(match)),
                () => this.router.navigate(['/matches']),
            ),
        );
    }

    private routeToAppropriateLocation(match: MatchCandidate) {
        const user = this.userService.user;
        const tab = match.candidateDashboardMatchType || 'applied';
        const shouldFinishAssessment =
            !user.completedAllAssessments &&
            !user.optionalTraitsAssessments &&
            !match.job?.getOptionalTraitsAssessments();
        const showRequiredAssessmentsDialogue =
            tab === DashboardMatchType.review &&
            !match.job?.getOptionalTraitsAssessments() &&
            !user.completedAllAssessments;
        shouldFinishAssessment
            ? this.router.navigate(['/assessments'])
            : this.router.navigate([
                  '/matches',
                  tab,
                  match.id,
                  { showRequiredAssessmentsDialogue },
              ]);
    }

    applyForJobByHashId(
        jobHashId: string,
        userId: number,
    ): Observable<MatchCandidate> {
        return this.http
            .get<MatchCandidate>(
                `/api/jobs/${jobHashId}/candidates/${userId}/match`,
            )
            .pipe(map((match) => (match ? MatchCandidate.of(match) : null)));
    }

    generateEmptyMatch(
        jobHashId: string,
        applySource: CandidateApplySource,
        candidateAction: DecisionType,
    ): Observable<MatchCandidate> {
        const candidateId = this.userService.user.id;
        const request = { applySource, candidateAction };

        return this.http
            .post<MatchCandidate>(
                `/api/jobs/${jobHashId}/candidates/${candidateId}/matches/empty-match`,
                request,
            )
            .pipe(map((match) => (match ? MatchCandidate.of(match) : null)));
    }

    processCandidateResponseOnSpInviteToApply(
        matchId: number,
        response: InviteToApplyResponseType,
        spInviteToApplyToken: string,
    ): Observable<void> {
        return this.http.patch<void>(
            `/auth/candidate/matches/${matchId}/${response}/${spInviteToApplyToken}`,
            null,
        );
    }

    fetchMatches(params: any): Observable<Page<MatchCandidate>> {
        return this.http
            .get<Page<MatchCandidate>>('/api/matches', { params })
            .pipe(
                map((page) => {
                    page.content = page.content.map((match) =>
                        MatchCandidate.of(match),
                    );

                    return page;
                }),
            );
    }

    getNonMatchedJobDetails(jobId: number): Observable<MatchCandidate> {
        return this.http
            .get<MatchCandidate>(`/api/matches/job/${jobId}`)
            .pipe(map((match) => MatchCandidate.of(match)));
    }
}
