export class MessageWithMedia {
    messageText: string;
    media: FormData;
    attributes?: any;

    constructor(message?: string, attachment?: FormData, attributes?: any) {
        this.messageText = message;
        this.media = attachment;
        this.attributes = attributes;
    }

    public containsProperMessageData() {
        return this.media || (this.messageText && !!this.messageText.trim());
    }
}
