import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import invariant from 'tiny-invariant';
import type { JobInfo } from '../models';
import { ColorContext } from '../models';
import { isDefined, unsubscribeIfActive } from '../utils';
import { JobsService } from '../../core/jobs.service';
import { NotificationService } from '../notification/notification.service';
import type { BaseJobInfo, SourcingStatus } from '../models/job-info.model';
import { PlainComponentDialogContainerComponent } from '../dialog/plain-component-dialog-container/plain-component-dialog-container.component';
import { ChangeSourcingStatusModalComponent } from '../../jobs/shared/change-sourcing-status-modal/change-sourcing-status-modal.comonent';
import { DialogService } from '../dialog/dialog.service';

@Injectable()
export class SpDropdownActionsService implements OnDestroy {
    hideActiveSpinner$ = new BehaviorSubject<void>(void 0);

    subscription: Subscription;

    get jobsCollectionByType(): JobInfo[] {
        return this.jobsService.getJobCollectionBasedOnType();
    }

    constructor(
        private jobsService: JobsService,
        private notificationService: NotificationService,
        private dialogService: DialogService,
        private router: Router,
    ) {
        this.subscription = new Subscription();
    }

    ngOnDestroy() {
        unsubscribeIfActive(this.subscription);
    }

    hideSpinner() {
        this.hideActiveSpinner$.next();
    }

    archiveJob(job: BaseJobInfo, navigationUrl?: string): void {
        this.subscription.add(
            this.jobsService
                .archiveJob(job.id)
                .pipe(
                    finalize(() => {
                        this.hideSpinner();
                    }),
                )
                .subscribe(
                    (jobInfo) => {
                        this.jobsService.archiveLocally(jobInfo);
                        this.showNotification('Your job has been archived.');

                        if (navigationUrl) {
                            void this.router.navigate([navigationUrl]);
                        }
                    },
                    () => {
                        this.showNotification(
                            'An error occurred archiving your job.',
                            ColorContext.WARNING,
                        );
                    },
                ),
        );
    }

    duplicateJob(job: BaseJobInfo): void {
        const duplicateErrorMessage =
            `We were unable to duplicate the “${job.title}” job.` +
            `Please try again later or contact your account manager.`;
        this.subscription.add(
            this.jobsService
                .fetchOneJob(job.id)
                .pipe(
                    finalize(() => {
                        this.hideSpinner();
                    }),
                )
                .subscribe(
                    (updatedJob) => {
                        this.router
                            .navigate(['/jobs/add/description'], {
                                state: { data: { publishedJob: updatedJob } },
                            })
                            .catch(() =>
                                this.showNotification(
                                    duplicateErrorMessage,
                                    ColorContext.WARNING,
                                ),
                            );
                    },
                    () =>
                        this.showNotification(
                            duplicateErrorMessage,
                            ColorContext.WARNING,
                        ),
                ),
        );
    }

    openChangeSourcingStatusModal(
        job: BaseJobInfo,
        targetStatus: SourcingStatus,
    ): Observable<JobInfo> {
        return this.dialogService
            .open(PlainComponentDialogContainerComponent, {
                component: ChangeSourcingStatusModalComponent,
                width: '58rem',
                height: '64rem',
                contentComponentData: {
                    job,
                    targetStatus,
                },
            })
            .afterClosed()
            .pipe(
                tap((updatedJob) => {
                    if (isDefined(updatedJob)) {
                        this.updateJobSourcingStatusAndVersionInCollectionByType(
                            updatedJob,
                        );
                        this.updateLocalJobSourcingStatusAndVersion(updatedJob);
                    }
                }),
            );
    }

    private updateJobSourcingStatusAndVersionInCollectionByType(
        updatedJob: JobInfo,
    ): void {
        const localJob = this.jobsCollectionByType.find(
            (job) => job.id === updatedJob.id,
        );
        invariant(localJob);
        localJob.sourcingStatus = updatedJob.sourcingStatus;
        localJob.version = updatedJob.version;
        this.jobsService.setJobCollectionBasedOnType([
            ...this.jobsCollectionByType,
        ]);
    }

    private updateLocalJobSourcingStatusAndVersion(updatedJob: JobInfo): void {
        const localJob = this.jobsService.getBaseJobInfo(updatedJob.id);
        invariant(localJob);
        localJob.sourcingStatus = updatedJob.sourcingStatus;
        localJob.version = updatedJob.version;
        this.jobsService.syncBaseJobInfo(localJob);
    }

    private showNotification(
        message: string,
        colorContext = ColorContext.PRIMARY,
    ) {
        this.notificationService.show({
            title: null,
            message,
            colorContext,
        });
    }
}
