import { UserType } from 'app/shared/models/user.model';
import { EmployerConnectedMatchStatus } from '../../../models/enumeration/employer-connected-match-status.model';
import {
    AbstractIdentifiableVersionedModel,
    DecisionType,
} from '../../../models';
import { isDefined } from '../../../utils';
import { ChatChannelAttributes } from '../../model/chat-channel-attributes.model';
import { toDate } from '../../../date-utils';
import { DashboardJobType } from '../../../models/enumeration/dashboard-job-type.enum';
import { eolSubStatuses } from '../../../models/match-employer.model';
import { ChatMessagingThreadCandidateStatus } from './chat-messaging-thread-candidate-status.model';

const unscreened = 'unscreened';
const archived = 'archived';

export class ChatMessagingThread extends AbstractIdentifiableVersionedModel {
    chatChannelId: string;
    name: string;
    candidateConnectedStatus?: EmployerConnectedMatchStatus;
    candidateStatus?: ChatMessagingThreadCandidateStatus;
    unscreenedCandidate?: boolean;
    matchEmployerAction?: DecisionType;
    participantIds: number[];
    companyName?: string;
    thumbnailLogoUrl?: string;
    archived: boolean;
    jobId?: number;
    jobStatus?: DashboardJobType;
    isStealthJob: boolean;
    matchId?: number;
    activityFeedUUID?: string;
    commentsChatChannelId?: string;
    lastTimeFeedView?: Date;
    lastMessageTimestamp?: Date;
    createdDate?: Date;

    // properties computed on front-end
    lastMessageAuthor?: string;
    lastMessageAuthorId?: number;
    lastMessageBody?: string;
    isLastMessageSentByCandidate?: boolean;
    isLastMessageContainingAttachment?: boolean;
    lastMessageIndex: number;
    lastConsumedMessageIndex: number;
    attributes: ChatChannelAttributes;
    lastMessageAttachmentFilename?: string;
    lastMessageAttachmentFilesize?: number;
    initialConversationComplete: boolean;

    get threadCandidateStatus() {
        if (this.unscreenedCandidate && !this.matchEmployerAction) {
            return unscreened;
        } else if (
            this.archived ||
            this.matchEmployerAction === DecisionType.pass ||
            this.jobStatus === DashboardJobType.archived
        ) {
            return archived;
        } else if (this.candidateConnectedStatus) {
            return this.candidateConnectedStatus;
        }

        return ChatMessagingThreadCandidateStatus.inReview;
    }

    constructor(thread: any) {
        super(thread);
        this.chatChannelId = thread.chatChannelId;
        this.name = thread.name;
        this.candidateStatus = this.fromEmployerConnectedStatus(
            thread.candidateConnectedStatus,
        );
        this.candidateConnectedStatus = thread.candidateConnectedStatus;
        this.unscreenedCandidate = thread.unscreenedCandidate;
        this.matchEmployerAction = thread.matchEmployerAction;
        this.participantIds = thread.participantIds;
        this.companyName = thread.companyName;
        this.archived = thread.archived;
        this.initialConversationComplete = thread.initialConversationComplete;
        this.thumbnailLogoUrl = thread.thumbnailLogoUrl;
        this.jobId = thread.jobId;
        this.jobStatus = thread.jobStatus;
        this.matchId = thread.matchId;
        this.activityFeedUUID = thread.activityFeedUUID;
        this.lastTimeFeedView = new Date(thread.lastTimeFeedView);
        this.commentsChatChannelId = thread.commentsChatChannelId;
        this.lastMessageTimestamp = toDate(thread.lastMessageTimestamp);
        this.lastMessageBody = thread.lastMessageBody;
        this.isLastMessageSentByCandidate = thread.isLastMessageSentByCandidate;
        this.isLastMessageContainingAttachment =
            thread.isLastMessageContainingAttachment;
        this.createdDate = toDate(thread.createdDate);
        this.attributes = ChatChannelAttributes.of(thread.attributes);
        this.isStealthJob = thread.stealthJob;
    }

    isParticipant(userId: number): boolean {
        return this.participantIds.indexOf(userId) > -1;
    }

    requiresAttention(userId: number, consumerUserType: UserType): boolean {
        if (
            this.hasAttributes() &&
            this.attributes.requiredActionDismissed(userId)
        ) {
            return false;
        }

        // No initial message from employer
        if (!this.lastMessageTimestamp) {
            return consumerUserType === UserType.employer;
        }

        // true if the message exchange is not completed
        return !this.initialConversationComplete;
    }

    setCandidateStatus(status: EmployerConnectedMatchStatus) {
        this.candidateStatus = this.fromEmployerConnectedStatus(status);
        this.candidateConnectedStatus = status;
    }

    // returns the initial firstDate reference
    // According to the state of the conversation flow
    getFirstTimestamp(consumerUserType: UserType): Date {
        if (!this.hasAttributes() || !this.lastMessageTimestamp) {
            return this.createdDate;
        }

        if (consumerUserType === UserType.employer) {
            // No initial message to candidate
            if (!this.attributes.employerFirstMessageDate) {
                return this.attributes.candidateFirstMessageDate
                    ? this.attributes.candidateFirstMessageDate
                    : this.createdDate;
            }

            // Message from employer but no response from candidate
            if (!this.attributes.candidateFirstMessageDate) {
                return this.attributes.employerFirstMessageDate;
            }

            // Message exchange is complete
            return this.lastMessageTimestamp;
        }

        // candidate
        // - if no initial message, candidate doesn't see any required actions
        // - only when he sends message or received a single one
        if (
            this.attributes.candidateFirstMessageDate &&
            !this.initialConversationComplete
        ) {
            return this.attributes.candidateFirstMessageDate;
        }

        // employer may send an initial message, candidate haven't responded yet.
        // value is independent of how many initial messages the employer sends
        return this.attributes.employerFirstMessageDate
            ? this.attributes.employerFirstMessageDate
            : this.createdDate;
    }

    hasAttributes(): boolean {
        return this.attributes && Object.keys(this.attributes).length > 0;
    }

    hasUnconsumedMessages(): boolean {
        return (
            isDefined(this.lastMessageIndex) &&
            (!isDefined(this.lastConsumedMessageIndex) ||
                this.lastConsumedMessageIndex < this.lastMessageIndex)
        );
    }

    needsResponse(isEmployer: boolean): boolean {
        return (
            isDefined(this.isLastMessageSentByCandidate) &&
            ((isEmployer && this.isLastMessageSentByCandidate) ||
                (!isEmployer && !this.isLastMessageSentByCandidate))
        );
    }

    getLastActivityDate(): Date {
        return this.lastMessageTimestamp
            ? this.lastMessageTimestamp
            : this.createdDate;
    }

    isEol(): boolean {
        return (
            eolSubStatuses.has(this.candidateConnectedStatus) ||
            this.threadCandidateStatus === archived
        );
    }

    private fromEmployerConnectedStatus(
        candidateConnectedStatus: EmployerConnectedMatchStatus,
    ) {
        let threadStatus = ChatMessagingThreadCandidateStatus.inReview;
        if (candidateConnectedStatus) {
            switch (candidateConnectedStatus) {
                case EmployerConnectedMatchStatus.approved:
                    threadStatus = ChatMessagingThreadCandidateStatus.approved;

                    break;
                case EmployerConnectedMatchStatus.pendingContacted:
                case EmployerConnectedMatchStatus.responded:
                case EmployerConnectedMatchStatus.noResponse:
                    threadStatus = ChatMessagingThreadCandidateStatus.contacted;

                    break;
                case EmployerConnectedMatchStatus.scheduled:
                case EmployerConnectedMatchStatus.phoneScreen:
                case EmployerConnectedMatchStatus.interview:
                    threadStatus =
                        ChatMessagingThreadCandidateStatus.interviewing;

                    break;
                case EmployerConnectedMatchStatus.pending:
                    threadStatus = ChatMessagingThreadCandidateStatus.offers;

                    break;
                case EmployerConnectedMatchStatus.accepted:
                    threadStatus = ChatMessagingThreadCandidateStatus.hired;

                    break;
                case EmployerConnectedMatchStatus.withdrawnReviewed:
                case EmployerConnectedMatchStatus.withdrawnContacted:
                case EmployerConnectedMatchStatus.withdrawnInterviewing:
                case EmployerConnectedMatchStatus.declined:
                    threadStatus =
                        ChatMessagingThreadCandidateStatus.withdrewDeclined;

                    break;
                case EmployerConnectedMatchStatus.disqualified:
                    threadStatus =
                        ChatMessagingThreadCandidateStatus.disqualified;

                    break;
            }
        }

        return threadStatus;
    }
}
