<header
    class="header"
    [ngClass]="{
        'title-align-left': titleAlign === 'left',
        header__bigger: headerSize === 'bigger',
        'header-text__secondary': headerTextColor === 'secondary'
    }"
>
    <div class="title" [class.header-font__bigger]="headerFontSize === 'bigger'">{{ title }}</div>
    <div class="buttons">
        <a
            *ngFor="let button of headerButtons"
            class="header-button"
            href="javascript:"
            (click)="onButtonClick(button.identifier)"
        >
            <img src="/images/icons/{{ button.icon }}.svg" alt="{{ button.alt }}" />
        </a>
    </div>
</header>
<div class="content">
    <ng-content></ng-content>
</div>
