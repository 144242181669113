import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import type { Industry } from '../shared/models';
import type { CachedResponseData } from '../shared/models/cached-response-data.interface';
import { isCacheValid } from '../shared/models/cached-response-data.interface';
import { markUsedItemsInAutocompleteSourceByName } from '../shared/utils';

const cacheRefreshIntervalMs = 15 * 60 * 1000; // 15 minutes

export const maxNumberOfIndustriesAllowed = 6;

@Injectable()
export class IndustryService {
    private _cachedIndustries: CachedResponseData<Industry[]>;
    private _industriesObservable$: Observable<Industry[]>;

    constructor(private http: HttpClient) {}

    getIndustries(
        keyword = '',
        excludeIndustries?: Industry[][],
    ): Observable<Industry[]> {
        if (isCacheValid(this._cachedIndustries, cacheRefreshIntervalMs)) {
            this._industriesObservable$ = of(this._cachedIndustries.data);
        } else {
            this._industriesObservable$ =
                this.getIndustriesObservable().pipe(share());
        }

        return this._industriesObservable$.pipe(
            tap((industries: Industry[]) => {
                // simple industries caching
                if (
                    !isCacheValid(
                        this._cachedIndustries,
                        cacheRefreshIntervalMs,
                    )
                ) {
                    this._cachedIndustries = {
                        lastFetchedAt: new Date(),
                        data: industries,
                    };
                }
            }),
            map((industries: Industry[]) =>
                this.filterAndOrderIndustries(
                    industries,
                    keyword,
                    excludeIndustries,
                ),
            ),
        );
    }

    searchByDomains(domains: string[]): Observable<Record<string, Industry[]>> {
        return this.http.get<Record<string, Industry[]>>(
            '/api/industries/search',
            { params: { domains: domains.join(',') } },
        );
    }

    private getIndustriesObservable() {
        return this.http.get<Industry[]>('/api/industries');
    }

    private filterAndOrderIndustries(
        industries: Industry[],
        keyword = '',
        excludeIndustries: Industry[][] = [[]],
    ) {
        return markUsedItemsInAutocompleteSourceByName(
            industries,
            excludeIndustries,
        )
            .filter(
                (industry) =>
                    industry.name
                        .toLowerCase()
                        .indexOf(keyword.toString().toLowerCase()) !== -1,
            )
            .sort((a, b) => a.order - b.order);
    }
}
