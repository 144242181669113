import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Angulartics2Module } from 'angulartics2';
import { Ng2UiAuthModule } from 'ng2-ui-auth';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AUTH_CONFIG, ConfigService } from './config.service';
import { GoogleApiService } from './google-api.service';
import { JobsService } from './jobs.service';
import { UserModule } from './user/user.module';
import { WindowRef } from './window-ref';
import { WindowService } from './window.service';
import { UserService } from './user.service';
import { HttpInterceptor } from './http-interceptor.service';
import { EmployerJobGuard } from './employer-job.guard';
import { IndustryService } from './industry.service';
import { ClearbitApiService } from './clearbit-api.service';
import { InstitutionService } from './institution.service';
import { ProfileAnswerOptionService } from './profile-answer-option.service';
import { NavbarService } from './navbar.service';
import { LocalStorageService } from './local-storage.service';
import { JobsResolver } from './resolver/jobs-resolver.service';
import { SkillService } from './skill.service';
import { CandidateOnboardingCompleteGuard } from './guards/candidate-onboarding-complete.guard';
import { EmployerMatchesApiService } from './employer-matches-api.service';
import { MessageTemplateService } from './message-template.service';
import { ActivityFeedService } from './activity-feed.service';
import { AssessmentListService } from './assessment-list.service';
import { CandidateMatchesApiService } from './candidate-matches-api.service';
import { RequiredActionsService } from './required-actions.service';
import { TokenService } from './token.service';
import { AnalyticsService } from './analytics.service';
import { CandidateAssessmentsCompleteGuard } from './guards/candidate-assessments-complete.guard';
import { GoogleMapsModule } from './google/google-maps.module';
import { UserflowService } from './userflow.service';
import { EnvironmentProviderService } from './environment-provider.service';

@NgModule({
    imports: [
        RouterModule,
        HttpClientModule,
        Angulartics2Module.forRoot(),
        UserModule,
        GoogleMapsModule,
        Ng2UiAuthModule.forRoot(AUTH_CONFIG),
    ],
    providers: [
        ConfigService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true },
        WindowService,
        UserService,
        JobsService,
        EmployerMatchesApiService,
        GoogleApiService,
        WindowRef,
        EmployerJobGuard,
        CandidateOnboardingCompleteGuard,
        IndustryService,
        SkillService,
        ClearbitApiService,
        InstitutionService,
        ProfileAnswerOptionService,
        NavbarService,
        LocalStorageService,
        JobsResolver,
        MessageTemplateService,
        ActivityFeedService,
        AssessmentListService,
        CandidateMatchesApiService,
        RequiredActionsService,
        TokenService,
        AnalyticsService,
        CandidateAssessmentsCompleteGuard,
        UserflowService,
        EnvironmentProviderService,
    ],
})
export class CoreModule {}
