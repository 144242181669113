import { OverlayModule } from '@angular/cdk/overlay';
import { Ng2DropdownModule } from 'ng2-material-dropdown';
import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TagInputModule } from 'ngx-chips';
import { QuillModule } from 'ngx-quill';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TextMaskModule } from 'angular2-text-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxUploaderModule } from 'ngx-uploader';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObserversModule } from '@angular/cdk/observers';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { LogInFormComponent } from '../auth/forms/login-form/login-form.component';
import { ChangeSourcingStatusModalComponent } from '../jobs/shared/change-sourcing-status-modal/change-sourcing-status-modal.comonent';
import { EmployerMatchesService } from '../matches/employer/employer-matches.service';
import { SplitByPipe } from './pipe/split-by.pipe';
import { UnavailableContentComponent } from './unavailable-content/unavailable-content.component';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { DialogContainerComponent } from './dialog/dialog-container/dialog-container.component';
import { DialogService } from './dialog/dialog.service';
import { UploadComponent } from './upload/upload.component';
import { SpFormInputComponent } from './forms/custom-controls/sp-form-input.component';
import { AutosizeDirective } from './autosize.directive';
import {
    ChipsComponent,
    MaskedDateInputComponent,
    MaskedDateRangeInputComponent,
    SelectComponent,
    SpSliderComponent,
    SpWysiwygEditorComponent,
} from './forms/custom-controls';
import { SpAutocompleteComponent } from './forms/custom-controls/autocomplete/sp-autocomplete.component';
import { AnalyticsDirective } from './analytics.directive';
import { TabSwitcherComponent } from './tab-switcher/tab-switcher.component';
import { PanelComponent } from './panel/panel.component';
import { MessageTooltipComponent } from './message-tooltip/message-tooltip.component';
import { FullScreenDialogContainerComponent } from './dialog/full-screen-dialog-container/full-screen-dialog-container.component';
import { SingleValueAutocompleteComponent } from './forms/custom-controls/single-value-autocomplete/single-value-autocomplete.component';
import { CompositeChipsComponent } from './forms/custom-controls/composite-chips/composite-chips.component';
import { FormFieldComponent } from './forms/form-field/form-field.component';
import { AttributeWithIconComponent } from './attribute-with-icon/attribute-with-icon.component';
import { AttributionLinkComponent } from './attribution-link/attribution-link.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TimeAgoPipe } from './pipe/time-ago/time-ago.pipe';
import { NotificationComponent } from './notification/notification.component';
import { NotificationContainerComponent } from './notification/notification-container.component';
import { NotificationService } from './notification/notification.service';
import { StatefulButtonComponent } from './stateful-button/stateful-button.component';
import { JoinWithPipe } from './pipe/join-with/join-with.pipe';
import { PlainComponentDialogContainerComponent } from './dialog/plain-component-dialog-container/plain-component-dialog-container.component';
import { WindowScrollDirective } from './directive/window-scroll.directive';
import { DoughnutChartComponent } from './charts/doughnut-chart/doughnut-chart.component';
import { HorizontalBarChartComponent } from './charts/horizontal-bar-chart/horizontal-bar-chart.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { DoubleVerticalBarChartComponent } from './charts/double-vertical-bar-chart/double-vertical-bar-chart.component';
import { ChartLegendComponent } from './charts/chart-legend/chart-legend.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { PasswordValidationDetailsComponent } from './forms/custom-controls/password-validation-details/password-validation-details.component';
import { StepperHeaderComponent } from './stepper/stepper-header/stepper-header.component';
import { StepperFooterComponent } from './stepper/stepper-footer/stepper-footer.component';
import { ImageUploaderComponent } from './upload/image-uploader/image-uploader.component';
import { IconButtonsGroupComponent } from './icon-buttons-group/icon-buttons-group.component';
import { TextareaComponent } from './forms/custom-controls/textarea/textarea.component';
import { IconDropdownComponent } from './icon-dropdown/icon-dropdown.component';
import { ChipsLineComponent } from './chips-line/chips-line.component';
import { ResolvableLogoComponent } from './resolvable-logo/resolvable-logo.component';
import { NavItemComponent } from './navbar/nav-item/nav-item.component';
import { SpDropdownComponent } from './sp-dropdown/sp-dropdown.component';
import { CompositeFilteringChipsComponent } from './forms/custom-controls/composite-filtering-chips/composite-filtering-chips.component';
import { TagChipsComponent } from './forms/custom-controls/tag-chips/tag-chips.component';
import { ExpandablePillsListComponent } from './expandable-pills-list/expandable-pills-list.component';
import { FileSizePipe } from './pipe/file-size/file-size.pipe';
import { UserDateInfoComponent } from './user-date-info/user-date-info.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ConfirmationModalComponent } from './modal/confirmation-modal/confirmation-modal.component';
import { TabsComponent } from './tabs/tabs.component';
import { TabContentComponent } from './tabs/tab-content.component';
import { SearchableTableComponent } from './searchable-table/searchable-table.component';
import { AvatarsStackComponent } from './avatars-stack/avatars-stack.component';
import { ChipsListComponent } from './chips-list/chips-list.component';
import { Date180DaysBased } from './pipe/date-180-days-based/date-180-days-based.pipe';
import { ReadonlyTraitComponent } from './readonly-trait/readonly-trait.component';
import { PaddedDoughnutChartComponent } from './charts/padded-doughnut-chart/padded-doughnut-chart.component';
import { FunnelBarChartComponent } from './charts/funnel-bar-chart/funnel-bar-chart.component';
import { CommentTextareaComponent } from './custom-textarea/comment-textarea/comment-textarea.component';
import { ReviewersPopupComponent } from './reviewers-popup/reviewers-popup.component';
import { EmployerMatchesEditTagsComponent } from './dialog/employer-matches-edit-tags/employer-matches-edit-tags.component';
import { EmployerMatchesEditTagsService } from './dialog/employer-matches-edit-tags/employer-matches-edit-tags.service';
import { StatefulIconButtonComponent } from './stateful-icon-button/stateful-icon-button.component';
import { TemplateMenuPopupComponent } from './template-menu-popup/template-menu-popup.component';
import { TemplateItemComponent } from './template-item/template-item.component';
import { TemplateLegendComponent } from './template-legend/template-legend.component';
import { TemplateEditorWysiwygComponent } from './forms/custom-controls/template-editor/template-editor-wysiwyg.component';
import { PopupComponent } from './popup/popup.component';
import { PopupDropdownComponent } from './popup/popup-dropdown/popup-dropdown.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { CreateTemplateDialogComponent } from './create-template-dialog/create-template-dialog.component';
import { TemplateMenuPopupButtonComponent } from './template-menu-popup/template-menu-popup-button/template-menu-popup-button.component';
import { StatusLabelComponent } from './status-label/status-label.component';
import { AppendableItemsListComponent } from './appendable-items-list/appendable-items-list.component';
import { NotificationsDropdownComponent } from './notifications-dropdown/notifications-dropdown.component';
import { WysiwygEditorWithMentionsComponent } from './custom-textarea/wysiwyg-editor-with-mentions/wysiwyg-editor-with-mentions.component';
import { MentionDropdownListComponent } from './custom-textarea/wysiwyg-editor-with-mentions/menton-dropdown-list/mention-dropdown-list.component';
import { MessagingTextareaComponent } from './custom-textarea/messaging-textarea/messaging-textarea.component';
import { AttachmentInTextareaComponent } from './custom-textarea/attachment-in-textarea/attachment-in-textarea.component';
import { InputWithImageSelectorComponent } from './forms/custom-controls/input-with-image-selector/input-with-image-selector.component';
import { IconCheckboxComponent } from './icon-checkbox/icon-checkbox.component';
import { ProgressAnimationComponent } from './progress-animation/progress-animation.component';
import { BoxLoadingIndicatorComponent } from './box-loading-indicator/box-loading-indicator.component';
import { ScrollableContentHeaderComponent } from './scrollable-content-header/scrollable-content-header.component';
import { PopupTemplateComponent } from './popup/popup-template/popup-template.component';
import { IconSectionListItemsComponent } from './icon-section-list-items/icon-section-list-items.component';
import { FullScreenSearchableDropdownModalComponent } from './modal/full-screen-searchable-dropdown-modal/full-screen-searchable-dropdown-modal.component';
import { ToolbarTabsComponent } from './toolbar-tabs/toolbar-tabs.component';
import { SortableColumnHeaderComponent } from './sortable-column-header/sortable-column-header.component';
import { LaneCardsGroupComponent } from './lanes-container/lane-cards-group/lane-cards-group.component';
import { LaneCardComponent } from './lanes-container/lane-card/lane-card.component';
import { LaneComponent } from './lanes-container/lane/lane.component';
import { InviteToApplyDialogComponent } from './modal/invite-to-apply-dialog/invite-to-apply-dialog.component';
import { LanesComponent } from './lanes-container/lanes/lanes.component';
import { OAuth2RedirectPageComponent } from './oauth2-redirect-page/oauth2-redirect-page.component';
import { ExpandableContentComponent } from './expandable-content/expandable-content.component';
import { HasAnyAuthorityDirective } from './directive/has-any-authority/has-any-authority.directive';
import { ShortenToThresholdPipe } from './pipe/shorten-to-threshold/shorten-to-threshold.pipe';
import { StepProgressAnimationComponent } from './step-progress-animation/step-progress-animation.component';
import { BaseHeaderComponent } from './base-header/base-header.component';
import { ObfuscatedContentComponent } from './obfuscated-content/obfuscated-content.component';
import { EmailPopupComponent } from './email-popup/email-popup.component';
import { StatusLightComponent } from './status-light/status-light.component';
import { EmployerBenefitsComponent } from './employer-benefits/employer-benefits.component';
import { RequiredActionInfoComponent } from './required-action-info/required-action-info.component';
import { RequiredActionInfoSectionComponent } from './required-action-info-section/required-action-info-section.component';
import { IncompleteProfileBannerComponent } from './incomplete-profile-banner/incomplete-profile-banner.component';
import { SearchForMoreComponent } from './search-for-more/search-for-more.component';
import { CompensationUnitSelectorComponent } from './forms/custom-controls/unit-selector/compensation-unit-selector/compensation-unit-selector.component';
import { SpMultiValueSliderComponent } from './forms/custom-controls/slider-multi-value/sp-multi-value-slider.component';
import { OrderSuffixPipe } from './pipe/date-suffix/order-suffix.pipe';
import { InputWithSelectorComponent } from './forms/custom-controls/input-with-selector/input-with-selector.component';
import { StatusNotificationManagerComponent } from './status-notification-manager/status-notification-manager.component';
import { AvatarsStacksSelectorComponent } from './forms/custom-controls/avatars-stacks-selector/avatars-stacks-selector.component';
import { MoveToPopupComponent } from './lanes-container/move-to-popup/move-to-popup.component';
import { CompositeLocationViewComponent } from './composite-location-view/composite-location-view.component';
import { LinkWithActionsComponent } from './llink-with-actions/link-with-actions.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { FullScreenLoadingIndicatorComponent } from './full-screen-loading-indicator/full-screen-loading-indicator.component';
import { FullScreenLoadingIndicatorService } from './full-screen-loading-indicator/full-screen-loading-indicator.service';
import { InvitedLabelComponent } from './invited-label/invited-label.component';
import { ConfettiComponent } from './confetti/confetti.component';
import { JobSalaryViewComponent } from './shared-job-components/job-salary-view/job-salary-view.component';
import { ReviewersListComponent } from './reviewers-list/reviewers-list.component';
import { JobTargetingViewComponent } from './shared-job-components/job-targeting-view/job-targeting-view.component';
import { JobDetailsViewComponent } from './shared-job-components/job-details-view/job-details-view.component';
import { JobSettingsViewComponent } from './shared-job-components/job-settings-view/job-settings-view.component';
import { ReviewersDropdownComponent } from './shared-job-components/reviewers-dropdown/reviewers-dropdown.component';
import { SpDropdownActionsService } from './sp-dropdown/sp-dropdown-actions.service';
import { EmailCampaignSectionComponent } from './email-campaign-section/email-campaign-section.component';
import { ShineDotComponent } from './shine-dot/shine-dot.component';
import { SourcingInfoModalComponent } from './sourcing-info-modal/sourcing-info-modal.component';
import { PdfViewerModalComponent } from './modal/pdf-viewer-modal/pdf-viewer-modal.component';
import { InsertLinkTemplateComponent } from './forms/custom-controls/template-editor/insert-link-template/insert-link-template.component';
import { ToggleControlComponent } from './forms/custom-controls/toggle-control/toggle-control.component';
import { DropdownControlComponent } from './forms/custom-controls/dropdown-control/dropdown-control.component';
import { SplitScreenViewComponent } from './split-screen-view/split-screen-view.component';
import { NotificationModalComponent } from './modal/notification-modal/notification-modal.component';
import { IconButtonsGroupControlComponent } from './icon-buttons-group-control/icon-buttons-group-control.component';
import { ChipWithConstraintComponent } from './forms/custom-controls/chips/chip-with-constraint/chip-with-constraint.component';
import { MultilineNplusChipsComponent } from './multiline-nplus-chips/multiline-nplus-chips.component';
import { ResolvableCompanyLogoComponent } from './resolvable-company-logo/resolvable-company-logo.component';
import { JobEnhancedTargetingViewComponent } from './shared-job-components/job-enhanced-targeting-view/job-enhanced-targeting-view.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NguiAutoCompleteModule,
        ReactiveFormsModule,
        RouterModule,
        OverlayModule,
        Ng2DropdownModule,
        NgxUploaderModule,
        QuillModule,
        NgxSliderModule,
        TagInputModule,
        TextMaskModule,
        NgSelectModule,
        DragDropModule,
        ObserversModule,
        PdfViewerModule,
        NguiAutoCompleteModule,
    ],
    declarations: [
        UnavailableContentComponent,
        SectionHeaderComponent,
        PanelComponent,
        PanelComponent,
        DialogContainerComponent,
        UploadComponent,
        LogInFormComponent,
        SpFormInputComponent,
        SpWysiwygEditorComponent,
        SpAutocompleteComponent,
        SpSliderComponent,
        IconDropdownComponent,
        TabSwitcherComponent,
        MaskedDateInputComponent,
        MaskedDateRangeInputComponent,
        ChipsComponent,
        SelectComponent,
        MessageTooltipComponent,
        FullScreenDialogContainerComponent,
        CompositeChipsComponent,
        FormFieldComponent,
        SingleValueAutocompleteComponent,
        AttributeWithIconComponent,
        AttributionLinkComponent,
        ProgressBarComponent,
        NavbarComponent,
        NotificationComponent,
        NotificationContainerComponent,
        StatefulButtonComponent,
        PlainComponentDialogContainerComponent,
        DoughnutChartComponent,
        HorizontalBarChartComponent,
        LineChartComponent,
        DoubleVerticalBarChartComponent,
        PaddedDoughnutChartComponent,
        FunnelBarChartComponent,
        ChartLegendComponent,
        TooltipComponent,
        AutosizeDirective,
        AnalyticsDirective,
        WindowScrollDirective,
        TooltipDirective,
        PasswordValidationDetailsComponent,
        StepperHeaderComponent,
        StepperFooterComponent,
        ImageUploaderComponent,
        IconButtonsGroupComponent,
        TextareaComponent,
        ChipsLineComponent,
        ResolvableLogoComponent,
        NavItemComponent,
        SpDropdownComponent,
        CompositeFilteringChipsComponent,
        TagChipsComponent,
        ExpandablePillsListComponent,
        UserDateInfoComponent,
        EmployerBenefitsComponent,
        SpMultiValueSliderComponent,

        SplitByPipe,
        TimeAgoPipe,
        Date180DaysBased,
        JoinWithPipe,
        FileSizePipe,
        ShortenToThresholdPipe,
        OrderSuffixPipe,
        AvatarComponent,
        ConfirmationModalComponent,
        TabsComponent,
        TabContentComponent,
        SearchableTableComponent,
        AvatarsStackComponent,
        ChipsListComponent,
        ReadonlyTraitComponent,
        CommentTextareaComponent,
        ReviewersPopupComponent,
        EmployerMatchesEditTagsComponent,
        StatefulIconButtonComponent,
        PopupDropdownComponent,
        TemplateMenuPopupComponent,
        TemplateItemComponent,
        TemplateLegendComponent,
        TemplateEditorWysiwygComponent,
        PopupComponent,
        ToggleSwitchComponent,
        CreateTemplateDialogComponent,
        TemplateMenuPopupButtonComponent,
        StatusLabelComponent,
        AppendableItemsListComponent,
        NotificationsDropdownComponent,
        MentionDropdownListComponent,
        WysiwygEditorWithMentionsComponent,
        MessagingTextareaComponent,
        AttachmentInTextareaComponent,
        InputWithImageSelectorComponent,
        ProgressAnimationComponent,
        BoxLoadingIndicatorComponent,
        IconCheckboxComponent,
        ScrollableContentHeaderComponent,
        PopupTemplateComponent,
        IconSectionListItemsComponent,
        FullScreenSearchableDropdownModalComponent,
        ToolbarTabsComponent,
        SortableColumnHeaderComponent,
        LaneComponent,
        LaneCardComponent,
        LaneCardsGroupComponent,
        InviteToApplyDialogComponent,
        LanesComponent,
        OAuth2RedirectPageComponent,
        ExpandableContentComponent,
        HasAnyAuthorityDirective,
        StepProgressAnimationComponent,
        BaseHeaderComponent,
        ObfuscatedContentComponent,
        EmailPopupComponent,
        StatusLightComponent,
        RequiredActionInfoComponent,
        RequiredActionInfoSectionComponent,
        IncompleteProfileBannerComponent,
        SearchForMoreComponent,
        CompensationUnitSelectorComponent,
        InputWithSelectorComponent,
        AvatarsStacksSelectorComponent,
        MoveToPopupComponent,
        StatusNotificationManagerComponent,
        CompositeLocationViewComponent,
        LinkWithActionsComponent,
        PdfViewerComponent,
        FullScreenLoadingIndicatorComponent,
        InvitedLabelComponent,
        ConfettiComponent,
        JobSalaryViewComponent,
        ReviewersListComponent,
        JobTargetingViewComponent,
        JobDetailsViewComponent,
        JobSettingsViewComponent,
        ReviewersDropdownComponent,
        EmailCampaignSectionComponent,
        ReviewersDropdownComponent,
        ShineDotComponent,
        SourcingInfoModalComponent,
        ChangeSourcingStatusModalComponent,
        PdfViewerModalComponent,
        InsertLinkTemplateComponent,
        ToggleControlComponent,
        DropdownControlComponent,
        SplitScreenViewComponent,
        NotificationModalComponent,
        IconButtonsGroupControlComponent,
        ChipWithConstraintComponent,
        MultilineNplusChipsComponent,
        ResolvableCompanyLogoComponent,
        JobEnhancedTargetingViewComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        NguiAutoCompleteModule,
        ReactiveFormsModule,
        UnavailableContentComponent,
        SectionHeaderComponent,
        PanelComponent,
        DialogContainerComponent,
        UploadComponent,
        SpFormInputComponent,
        LogInFormComponent,
        SpWysiwygEditorComponent,
        SpAutocompleteComponent,
        SpSliderComponent,
        TagInputModule,
        ChipsComponent,
        IconDropdownComponent,
        TabSwitcherComponent,
        SelectComponent,
        MessageTooltipComponent,
        SingleValueAutocompleteComponent,
        AttributeWithIconComponent,
        AttributionLinkComponent,
        ProgressBarComponent,
        NavbarComponent,
        NotificationContainerComponent,
        StatefulButtonComponent,
        FormFieldComponent,
        DoughnutChartComponent,
        HorizontalBarChartComponent,
        LineChartComponent,
        DoubleVerticalBarChartComponent,
        ChartLegendComponent,
        PaddedDoughnutChartComponent,
        FunnelBarChartComponent,
        AutosizeDirective,
        AnalyticsDirective,
        WindowScrollDirective,
        TooltipDirective,
        PasswordValidationDetailsComponent,
        StepperHeaderComponent,
        StepperFooterComponent,
        ImageUploaderComponent,
        IconButtonsGroupComponent,
        ChipsLineComponent,
        ExpandablePillsListComponent,
        ResolvableLogoComponent,
        CompositeFilteringChipsComponent,
        SpDropdownComponent,
        UserDateInfoComponent,
        AvatarComponent,

        SplitByPipe,
        TimeAgoPipe,
        Date180DaysBased,
        JoinWithPipe,
        FileSizePipe,
        AvatarComponent,
        TabsComponent,
        TabContentComponent,
        SearchableTableComponent,
        AvatarsStackComponent,
        ChipsListComponent,
        ReadonlyTraitComponent,
        TooltipComponent,
        CommentTextareaComponent,
        ReviewersPopupComponent,
        StatefulIconButtonComponent,
        ToggleSwitchComponent,
        TemplateMenuPopupButtonComponent,
        StatusLabelComponent,
        AppendableItemsListComponent,
        MessagingTextareaComponent,
        IconCheckboxComponent,
        ScrollableContentHeaderComponent,
        IconSectionListItemsComponent,
        ToolbarTabsComponent,
        SortableColumnHeaderComponent,
        LaneComponent,
        InviteToApplyDialogComponent,
        LanesComponent,
        BoxLoadingIndicatorComponent,
        OAuth2RedirectPageComponent,
        ExpandableContentComponent,
        HasAnyAuthorityDirective,
        BaseHeaderComponent,
        ObfuscatedContentComponent,
        EmailPopupComponent,
        StatusLightComponent,
        EmployerBenefitsComponent,
        RequiredActionInfoComponent,
        RequiredActionInfoSectionComponent,
        IncompleteProfileBannerComponent,
        SearchForMoreComponent,
        CompensationUnitSelectorComponent,
        OrderSuffixPipe,
        StatusNotificationManagerComponent,
        CompositeLocationViewComponent,
        LinkWithActionsComponent,
        PdfViewerComponent,
        AvatarsStacksSelectorComponent,
        FullScreenLoadingIndicatorComponent,
        InvitedLabelComponent,
        ConfettiComponent,
        JobSalaryViewComponent,
        ReviewersListComponent,
        JobTargetingViewComponent,
        JobDetailsViewComponent,
        JobSettingsViewComponent,
        ReviewersDropdownComponent,
        EmailCampaignSectionComponent,
        ReviewersDropdownComponent,
        ShineDotComponent,
        SourcingInfoModalComponent,
        ToggleControlComponent,
        DropdownControlComponent,
        SplitScreenViewComponent,
        IconButtonsGroupControlComponent,
        MultilineNplusChipsComponent,
        ResolvableCompanyLogoComponent,
        JobEnhancedTargetingViewComponent,
    ],
    providers: [
        DialogService,
        SpDropdownActionsService,
        EmployerMatchesEditTagsService,
        FullScreenLoadingIndicatorService,
        EmployerMatchesService,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                { provide: NotificationService, useClass: NotificationService },
            ],
        };
    }
}
