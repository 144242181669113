import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';
import type { TagModelClass } from 'ngx-chips/core/accessor';
import type { DropdownData } from '../../../../sp-dropdown/sp-dropdown.constants';
import { DropdownDataAction } from '../../../../sp-dropdown/sp-dropdown.constants';
import type { Constraint } from '../../../../models/enumeration/constraint.enum';
import { SpDropDownData } from '../../../../sp-dropdown/sp-dropdown.component';

export interface ChipWithConstraint extends TagModelClass {
    constraint: Constraint;
}

@Component({
    selector: 'sp-chip-with-constraint[model]',
    templateUrl: './chip-with-constraint.component.html',
    styleUrls: ['./chip-with-constraint.component.scss'],
})
export class ChipWithConstraintComponent {
    @HostBinding('class.preview')
    @Input()
    previewOnly = false;

    @Input() model!: ChipWithConstraint;
    @Input() dropdownItems?: SpDropDownData;
    @Input() disabled = false;

    @Output() removeItem = new EventEmitter<ChipWithConstraint>();
    @Output() constraintChange = new EventEmitter();

    @HostBinding('class') get className() {
        return this.model.constraint;
    }

    get allowConstraintChange() {
        return !this.previewOnly && this.dropdownItems;
    }

    get offsetLeftAdjust() {
        return this.elRef.nativeElement.left;
    }

    get offsetTopAdjust() {
        // 5 is to give some space between the chip and the dropdown
        return this.elRef.nativeElement.offsetHeight + 5;
    }

    constructor(private elRef: ElementRef) {}

    onActionSelected(action: DropdownData) {
        if (action === this.model.constraint) {
            return;
        }

        if (action === DropdownDataAction.remove) {
            this.removeItem.emit(this.model);

            return;
        }

        this.model.constraint = action as Constraint;
        this.constraintChange.emit();
    }
}
