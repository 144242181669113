import { Injectable } from '@angular/core';
import { concatMap, finalize, map, share, tap } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../broadcast.service';
import { ActivityFeedService } from '../activity-feed.service';
import type { FeedResult } from '../activity-feed.service';
import { UserService } from '../user.service';
import { UserType } from '../../shared/models/user.model';
import { AppNotification } from '../../shared/models';
import type { GetstreamNotificationFeed } from '../../shared/models/notification/getstream/getstream-notification-feed.model';
import type { GetstreamAddedAsJobReviewerEmployerActivity } from '../../shared/models/notification/getstream/getstream-added-as-job-reviewer-employer-activity.model';
import { isDefined, unsubscribeIfActive } from '../../shared/utils';
import type { EmployerFeedActivityType } from '../../shared/models/notification/app-notification.model';

const feedGroupName = 'employer_user_notifications';
const notificationsPageSize = 10;

@Injectable({
    providedIn: 'root',
})
export class NotificationsListenerService {
    private notificationsFeedUUID: string;

    private initEmployerNotificationsFeed$: Observable<any>;
    private isInitializingEmployerNotificationsFeed: boolean;

    private subscription: Subscription;

    constructor(
        private broadcastService: BroadcastService,
        private activityFeedService: ActivityFeedService,
        private userService: UserService,
    ) {}

    startListening(): void {
        const user = this.userService.user;
        if (user.type !== UserType.employer) {
            return;
        }

        this.subscription = new Subscription();
        this.notificationsFeedUUID = user.feedUUID;
        this.isInitializingEmployerNotificationsFeed = true;

        this.initEmployerNotificationsFeed$ = this.activityFeedService
            .init(`/api/feed/token/notifications/users/current-user`)
            .pipe(
                // get notifications feed to get the 'new items' state; also retrieves initial page with notifications
                concatMap(() =>
                    this.activityFeedService.getFeed<
                        FeedResult<
                            GetstreamNotificationFeed<GetstreamAddedAsJobReviewerEmployerActivity>
                        >
                    >(feedGroupName, this.notificationsFeedUUID, {
                        pageSize: notificationsPageSize,
                        latestPage: true,
                    }),
                ),
                tap((feedResult) =>
                    this.emitHasUnseenEmployerNotificationsState(
                        feedResult.unseen > 0,
                    ),
                ),
                finalize(
                    () =>
                        (this.isInitializingEmployerNotificationsFeed = false),
                ),
                concatMap(() =>
                    this.activityFeedService.listenForNew(
                        feedGroupName,
                        this.notificationsFeedUUID,
                    ),
                ),
            )
            .pipe(share());

        const subscription = this.initEmployerNotificationsFeed$.subscribe(
            () => {
                this.emitHasUnseenEmployerNotificationsState(true);
            },
        );
        this.subscription.add(subscription);
    }

    stopListening(): void {
        this.notificationsFeedUUID = null;
        unsubscribeIfActive(this.subscription);
    }

    getNotifications(fetchLatest = false): Observable<AppNotification[]> {
        let getFeed$ = this.activityFeedService.getFeed<
            GetstreamNotificationFeed<EmployerFeedActivityType>
        >(feedGroupName, this.notificationsFeedUUID, {
            pageSize: notificationsPageSize,
            latestPage: fetchLatest,
        });

        if (this.isInitializingEmployerNotificationsFeed) {
            getFeed$ = this.initEmployerNotificationsFeed$.pipe(
                concatMap(() => getFeed$),
            );
        }

        return getFeed$.pipe(
            map((feedResult) =>
                feedResult.results.map((group) =>
                    AppNotification.fromGetstreamActivity(group),
                ),
            ),
        );
    }

    markAsSeen(activityGroupIds: string[]): Observable<object> {
        return this.activityFeedService
            .markAsSeen(
                feedGroupName,
                this.notificationsFeedUUID,
                activityGroupIds,
            )
            .pipe(
                tap((response) => {
                    const hasUnseen =
                        isDefined(response['unseen']) && response['unseen'] > 0;
                    this.emitHasUnseenEmployerNotificationsState(hasUnseen);
                }),
            );
    }

    hasNextPage(): boolean {
        return this.activityFeedService.hasNextPage();
    }

    private emitHasUnseenEmployerNotificationsState(hasUnseen: boolean) {
        this.broadcastService.broadcastEmployerFeedNotification(hasUnseen);
    }
}
