import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import type { Observable } from 'rxjs';
import { JobsService } from '../jobs.service';
import type { BaseJobInfo } from '../../shared/models/job-info.model';

@Injectable()
export class JobsResolver implements Resolve<BaseJobInfo[]> {
    constructor(private jobsService: JobsService) {}

    resolve(
        route: ActivatedRouteSnapshot,
    ): BaseJobInfo[] | Observable<BaseJobInfo[]> {
        return this.jobsService.fetchJobsBaseInfo();
    }
}
