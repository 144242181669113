import { Injectable } from '@angular/core';
import type { TagModelClass } from 'ngx-chips/core/accessor';
import { CompositeChipsContainer } from '../chips/model';

/**
 * Simple component scoped service to share:
 *  - drag-and-drop state data between sp-chips subcomponents in a sp-composite-chips component
 *  - selected values in sp-chips subcomponents to e.g. avoid for duplicate items being added
 */
@Injectable()
export class CompositeChipsService {
    isDragInProgress = false;
    enterContainer?: CompositeChipsContainer;

    private topControlValues: TagModelClass[] = [];
    private bottomControlValues: TagModelClass[] = [];

    addValue(dstContainer: CompositeChipsContainer, tag: TagModelClass) {
        const dst =
            dstContainer === CompositeChipsContainer.top
                ? this.topControlValues
                : this.bottomControlValues;
        dst.push(tag);
    }

    removeValue(dstContainer: CompositeChipsContainer, tag: TagModelClass) {
        const dst =
            dstContainer === CompositeChipsContainer.top
                ? this.topControlValues
                : this.bottomControlValues;
        const index = dst.findIndex((tagModel) => tagModel.id === tag.id);
        if (index > -1) {
            dst.splice(index, 1);
        }
    }

    isAlreadySelected(tag: TagModelClass, onlyIdCheck = false): boolean {
        const predicate = onlyIdCheck
            ? (alreadySelectedTag: TagModelClass) =>
                  alreadySelectedTag.id === tag.id
            : (alreadySelectedTag: TagModelClass) =>
                  alreadySelectedTag.id === tag.id ||
                  alreadySelectedTag.value === tag.value;

        return !![...this.topControlValues, ...this.bottomControlValues].find(
            predicate,
        );
    }
}
