import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { AnimationEvent } from '@angular/animations';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import type { Subscription } from 'rxjs';
import { WindowService } from '../../core/window.service';
import { unsubscribeIfActive } from '../utils';
import type { NotificationMessage } from './notification-message.interface';
import { NotificationService } from './notification.service';

@Component({
    selector: 'sp-notification-container',
    templateUrl: './notification-container.component.html',
    styleUrls: ['./notification-container.component.scss'],
    // defining two separate states to support different types of animation on different screen sizes
    // (i.e. slide right -> left on medium+ and bottom -> top on small)
    // (as of angular 7.x state style callbacks are not yet supported)

    // note that the code below uses position: absolute / position: relative to make
    // instead of former display: none due to the issue with angular-7 animations in
    // edge https://github.com/angular/angular/issues/29371
    // Setting absolute positioning on notification-content does not cause the parent
    // container to stretch in height to accommodate a child component
    animations: [
        trigger('smInOutState', [
            state(
                'in',
                style({
                    transform: 'translateY(0%)',
                    position: 'relative',
                }),
            ),
            state(
                'out',
                style({
                    transform: 'translateY(calc(100% + 10px))',
                    position: 'absolute',
                }),
            ),
            state(
                'outImmediate',
                style({
                    transform: 'translateY(calc(100% + 10px))',
                    position: 'absolute',
                }),
            ),
            transition('in => out', animate('300ms 5s ease-in')),
            transition('* => outImmediate', animate('300ms ease-in')),
            transition('* => in', animate('300ms ease-out')),
        ]),
        trigger('mdInOutState', [
            state(
                'in',
                style({
                    transform: 'translateX(0%)',
                    position: 'relative',
                }),
            ),
            state(
                'out',
                style({
                    transform: 'translateX(110%)',
                    position: 'absolute',
                }),
            ),
            state(
                'outImmediate',
                style({
                    transform: 'translateX(110%)',
                    position: 'absolute',
                }),
            ),
            transition('in => out', animate('300ms 5s ease-in')),
            transition('* => outImmediate', animate('300ms ease-in')),
            transition('* => in', animate('300ms ease-out')),
        ]),
    ],
})
export class NotificationContainerComponent implements OnInit, OnDestroy {
    inOutState: 'in' | 'out' | 'outImmediate' = 'out';
    notification: NotificationMessage;
    isSmallScreen: boolean;

    private subscription: Subscription;

    constructor(
        private notificationService: NotificationService,
        private windowService: WindowService,
    ) {}

    ngOnInit() {
        this.isSmallScreen = this.windowService.isSmall();

        this.subscription = this.notificationService.notification$.subscribe(
            (notification) => {
                this.notification = notification;
                setTimeout(
                    () =>
                        (this.inOutState =
                            this.notification === null ? 'out' : 'in'),
                    1,
                );
            },
        );

        this.subscription.add(
            this.windowService.width$.subscribe(
                () => (this.isSmallScreen = this.windowService.isSmall()),
            ),
        );
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    onNotificationAnimationDone(event: AnimationEvent) {
        if (event.toState === 'in' && !this.notification.disableAutoDismiss) {
            this.inOutState = 'out';
        }
    }

    onDismiss() {
        this.inOutState = 'outImmediate';
    }
}
