import { toDate } from 'app/shared/date-utils';
import { isDefined } from 'app/shared/utils';

export enum ChannelAttributeKeys {
    employerFirstMsg = 'employerFirstMsg',
    candidateFirstMsg = 'candidateFirstMsg',
    employerFirstMsgDate = 'employerFirstMsgDate',
    candidateFirstMsgDate = 'candidateFirstMsgDate',
    dismissedUserList = 'dismissedUserIds',
}

export class ChatChannelAttributes {
    isCandidateFirstMessage?: boolean;
    isEmployerFirstMessage?: boolean;
    candidateFirstMessageDate?: Date;
    employerFirstMessageDate?: Date;
    dismissedUserList?: number[];

    static of(attributes) {
        const channelAttributes = new ChatChannelAttributes();
        if (isDefined(attributes)) {
            channelAttributes.isCandidateFirstMessage =
                attributes[ChannelAttributeKeys.candidateFirstMsg];
            channelAttributes.isEmployerFirstMessage =
                attributes[ChannelAttributeKeys.employerFirstMsg];
            channelAttributes.candidateFirstMessageDate = toDate(
                attributes[ChannelAttributeKeys.candidateFirstMsgDate],
            );
            channelAttributes.employerFirstMessageDate = toDate(
                attributes[ChannelAttributeKeys.employerFirstMsgDate],
            );
            channelAttributes.dismissedUserList =
                attributes[ChannelAttributeKeys.dismissedUserList] || [];
        }

        return channelAttributes;
    }

    requiredActionDismissed(userId: number) {
        return (
            this.dismissedUserList &&
            this.dismissedUserList.indexOf(userId) >= 0
        );
    }
}
