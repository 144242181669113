<!-- Regular button -->

<button
    *ngIf="buttonType === 'button'"
    [class.context-primary]="context === 'primary'"
    [class.context-danger]="context === 'danger'"
    [class.context-info]="context === 'info'"
    [class.inverted]="inverted"
    [class.employer-bottom-bar]="employerBottomBarButton"
    [class.dark-background-fix]="isBackgroundDark"
    [class.with-space-between]="withSpaceBetweenLabelAndIcon"
    [class.with-ai-suffix]="withAiSuffix"
    [style.height.px]="heightPx"
    [disabled]="isLoading || disabledFromOutside"
    (click)="onClick($event)"
    class="button"
>
    <span
        *ngIf="!isLoading && leftIconUrl"
        [ngStyle]="leftButtonIconMaskCss"
        class="left-button-icon"
    >
    </span>
    <span *ngIf="!isLoading" class="button-label">{{ label }}</span>
    <ng-container *ngIf="isLoading">
        <span class="spinner-border"></span>
        <span *ngIf="loadingLabel" class="button-label">{{ loadingLabel }}</span>
    </ng-container>
    <span
        *ngIf="!isLoading && iconUrl"
        [ngStyle]="buttonIconMaskCss"
        [style.height.px]="iconSizeOverridePx"
        [style.width.px]="iconSizeOverridePx"
        class="button-icon"
    >
    </span>
    <span class="ai-suffix">
        <span class="ai-icon"></span>
        <span>AI</span>
    </span>
</button>

<!-- Link-style button -->

<a
    *ngIf="buttonType === 'link'"
    [class.context-danger]="context === 'danger'"
    [class.context-info]="context === 'info'"
    [class.icon-link]="this.iconUrl"
    [class.link-button-primary]="this.iconUrl || context === 'primary'"
    (click)="onClick($event)"
    href="javascript:"
    class="link-button"
>
    <img
        *ngIf="!this.isLoading && this.iconUrl"
        src="{{ iconUrl }}"
        class="icon"
        [style.height.px]="iconSizeOverridePx"
        [style.width.px]="iconSizeOverridePx"
    />
    <span *ngIf="isLoading" class="spinner-border"></span>
    {{ this.isLoading && !this.iconUrl ? '' : label }}
</a>
