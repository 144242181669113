import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import type { ɵa as IPartialConfigOptions } from 'ng2-ui-auth';
import { StorageType } from 'ng2-ui-auth';

export const AUTH_CONFIG: IPartialConfigOptions = {
    baseUrl: environment.config.API_URL,
    loginUrl: 'auth/signin',
    tokenPrefix: 'SquarePeg',
    authHeader: 'X-AUTH-TOKEN',
    authToken: '',
    storageType: StorageType.SESSION_STORAGE,
    cordova: false,
    providers: {
        linkedin: {
            clientId: environment.config.LINKEDIN_CLIENT_ID,
            redirectUri: `${environment.config.APP_URL}/login/candidate/success`,
            authorizationEndpoint:
                'https://www.linkedin.com/oauth/v2/authorization',
            scope: ['openid', 'profile', 'email'],
        },
        google: {
            clientId: environment.config.GOOGLE_CLIENT_ID,
            redirectUri: `${environment.config.APP_URL}/login/candidate/success`,
            scope: ['profile', 'email'],
        },
        lever: {
            clientId: environment.integrations.lever.clientId,
            redirectUri: `${environment.config.APP_URL}/account/integrations/success`,
            authorizationEndpoint:
                environment.integrations.lever.authorizationEndpoint,
            responseType: 'code',
            state: environment.integrations.lever.state,
            scope: environment.integrations.lever.scope,
            scopeDelimiter: ' ',
            additionalUrlParams: {
                prompt: 'consent',
                audience: environment.integrations.lever.audience,
            },
        },
        slack: {
            clientId: environment.integrations.slack.clientId,
            redirectUri: `${environment.config.APP_URL}/account/integrations/success`,
            authorizationEndpoint:
                environment.integrations.slack.authorizationEndpoint,
            state: environment.integrations.slack.state,
            scope: environment.integrations.slack.scope,
            scopeDelimiter: ' ',
        },
    },
};

// CSS class set on the container of the root angular app
// Referred to by e.g. infiniteScroll configurations in different
// parts of the application
export const appScrollingContainerCssClass = 'app-scrolling-container';

// CSS class set on the invalid sp-form-input field. Mostly used for auto-scrolling
// to first invalid field
export const invalidFormFieldCssClass = 'sp-invalid-field';

// CSS class set for of controls for highlight purpose
export const smInfoIconWrapperMarker = 'sm-info-icon-wrapper-marker';
export const smInfoIconMarker = 'sm-info-icon-marker';

export const spInfoEmailAddress = 'info@squarepeghires.com';

export const httpConflictStatus = 409;
export const httpPreconditionFailedStatus = 412;

export const YEARS_OF_EXPERIENCE_RANGE = {
    min: 0,
    max: 20,
    defaultMin: 0,
    defaultMax: 0,
};

export const SALARY_RANGE = {
    min: 0,
    max: 500000,
    defaultMin: 0,
    defaultMax: 0,
};

export const CANDIDATE_SALARY_RANGE = {
    min: 0,
    max: 500000,
    defaultMin: 0,
    defaultMax: 0,
    validMin: 5000,
};

export const COMMON_FORM_VALIDATORS = {
    firstNameMaxLength: 50,
    lastNameMaxLength: 50,
    max255Length: 255,
    employer: {
        companyNameMaxLength: 128,
        contactPosition: 128,
    },
};

// This email regexp is used in angular forms so we can stay consistent with validation in all the forms we have
export const EMAIL_REGEXP =
    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const AUTOSAVE_PROFILE_DEBOUNCE_TIME_MS = 3000;

export const userAssessmentStorageKey = 'userAssessmentAnswers_';
export const legacyUserAssessmentStorageKey = 'userAssessment_';
const userAssessmentProgressStorageKeySuffix = '_progress';
const candidateStatusChangeMessagingInboxDialogStateKeyPrefix =
    'candidateStatusChangeMessagingInboxDialogState_';
const candidateScreeningQuestionAnswersKeyPrefix =
    'candidateScreeningQuestionAnswers_';
const candidateCoverLetterKeyPrefix = 'candidateCoverLetter_';
const easySignupJobHashIdKeyPrefix = 'easySignupJobHashIdKeyPrefix_';
const candidateDeletionReasonsKeyPrefix = 'candidateDeletionReasons_';
const requiredActionsInfoSessionKeyPrefix = 'requiredActionsInfoSession_';
const lastJobHashIdCandidateAppliedToKeyPrefix =
    'lastJobHashIdCandidateAppliedTo_';
const candidateOnboardingTooltip = 'candidateOnboardingTooltip_';
const hideEmployerSendMessageDialogKeyPrefix =
    'hideEmployerSendMessageDialogKeyPrefix_';

// height of the fixed non-scrolling two-level (navbar + subnavbar) header on medium+ screen size devices
export const mdFixedTwoLevelHeaderHeight = 125;

export const getAssessmentStorageKey = (userId: number, jobId?: number) => {
    const baseKey = userAssessmentStorageKey + userId;

    return jobId ? baseKey + `_${jobId}` : baseKey;
};

export const getEasySignupJobHashIdKeyPrefix = (email: string) =>
    `${easySignupJobHashIdKeyPrefix}${email}`;

export const getLegacyAssessmentStorageKey = (
    userId: number,
    jobId?: number,
) => {
    const baseKey = legacyUserAssessmentStorageKey + userId;

    return jobId ? baseKey + `_${jobId}` : baseKey;
};

export const getAssessmentProgressStorageKey = (
    userId: number,
    jobId?: number,
) =>
    getAssessmentStorageKey(userId, jobId) +
    userAssessmentProgressStorageKeySuffix;

export const getLegacyAssessmentProgressStorageKey = (
    userId: number,
    jobId?: number,
) =>
    getLegacyAssessmentStorageKey(userId, jobId) +
    userAssessmentProgressStorageKeySuffix;

export const getCandidateScreeningQuestionAnswersKey = (
    jobId: number,
    candidateId: number,
    questionId: number,
) =>
    `${candidateScreeningQuestionAnswersKeyPrefix}${jobId}_${candidateId}_${questionId}`;

export const getCandidateScreeningQuestionAnswersFallbackKey = (
    matchId: number,
    questionId: number,
) => `${candidateScreeningQuestionAnswersKeyPrefix}${matchId}_${questionId}`;

export const getCandidateCoverLetterKey = (
    jobId: number,
    candidateId: number,
) => `${candidateCoverLetterKeyPrefix}${jobId}_${candidateId}`;

export const getCandidateCoverLetterFallbackKey = (matchId: number) =>
    `${candidateCoverLetterKeyPrefix}${matchId}`;

export function getCandidateStatusChangeMessagingInboxDialogStateKey(
    userId: number,
) {
    return `${candidateStatusChangeMessagingInboxDialogStateKeyPrefix}${userId}`;
}

export const getCandidateDeletionReasonsKey = (userId: number) =>
    `${candidateDeletionReasonsKeyPrefix}${userId}`;

export const getHideEmployerSendMessageDialogKey = (userId: number) =>
    `${hideEmployerSendMessageDialogKeyPrefix}${userId}`;

export function getRequiredActionsInfoSessionKey(userId: number) {
    return `${requiredActionsInfoSessionKeyPrefix}${userId}`;
}

export function getLastJobHashIdCandidateAppliedToSessionKey(userId: number) {
    return `${lastJobHashIdCandidateAppliedToKeyPrefix}${userId}`;
}

export const getCandidateOnboardingTooltip = (
    userId: number,
    controlId: string,
) => `${candidateOnboardingTooltip}${userId}_${controlId}`;

export const forbiddenEmployerEmailDomains = [
    'gmail.com',
    'yahoo.com',
    'outlook.com',
    'aol.com',
    'zoho.com',
    'mail.com',
    'icloud.com',
];

export const passThroughSignupQueryParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'referrer',
    'employerJobId',
    'group',
];

export const spLogoPath = '/images/icons/sp-logo-18.svg';

// key under which sidebar-tab-to-activate information is passed on routing
export const routerDataKeyActiveSidebarTab = 'sidebar';

// key codes
export const keyCodeBackspace = 8;
export const keyCodeTab = 9;
export const keyCodeEnter = 13;
export const keyCodeCtrl = 17;
export const keyCodeCtrlEnter = 10; // https://bugs.chromium.org/p/chromium/issues/detail?id=79407
export const keyCodeEsc = 27;
export const keyCodeArrowLeft = 37;
export const keyCodeArrowUp = 38;
export const keyCodeArrowRight = 39;
export const keyCodeArrowDown = 40;
export const keyCodeSemicolon = 186;
export const keyCodeComma = 188;
export const keyCodeAt = 229;

@Injectable()
export class ConfigService {
    public apiUrl: string = environment.config.API_URL;
    public siteUrl: string = environment.config.SITE_URL;
    public linkedinClientId: string = environment.config.LINKEDIN_CLIENT_ID;
    public googleClientId: string = environment.config.GOOGLE_CLIENT_ID;
}
