<form [formGroup]="form" class="comment-form">
    <span class="comment-buttons-wrapper" id="{{ commentAttachmentButtonId }}">
        <label
            class="icon-attach"
            [class.disabled]="!enabled || fileIsAttached"
            for="file-upload-comments"
            spTooltip
            tooltipText="{{ fileIsAttached ? attachmentWarning : '' }}"
        >
        </label>
        <input
            formControlName="attachment"
            id="file-upload-comments"
            class="file-input"
            type="file"
            ngFileSelect
            [options]="options"
            (click)="openFileDialog($event)"
            (uploadOutput)="onUploadOutput($event)"
            [uploadInput]="uploadInput"
        />
    </span>
    <sp-wysiwyg-editor-with-mentions
        #editorWithMentions
        [enabled]="enabled"
        [placeholder]="placeholder"
        (keyup)="onMessageInputKeyup($event)"
        [availableMentions]="availableDropdownItems"
        class="editor"
    >
    </sp-wysiwyg-editor-with-mentions>
    <a
        *ngIf="!isLoading"
        [class.disabled]="!enabled"
        (click)="submitComment()"
        class="textarea-button send-message-button"
    >
    </a>
    <div *ngIf="isLoading" class="textarea-button">
        <img src="/images/spinner.svg" class="sp-dots-spinner spinner" />
    </div>
</form>

<sp-attachment-in-textarea
    *ngIf="fileIsAttached"
    class="attachment"
    [selectedFileName]="selectedFileName"
    [fileSizeLabel]="fileSizeLabel"
    [removable]="!isUploadedSelectedFile"
    (resetAttachment)="resetAttachment()"
>
</sp-attachment-in-textarea>
