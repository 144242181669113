<a
    *ngFor="let tab of tabs"
    [ngClass]="{ selected: tab.active }"
    [routerLink]="tab.route"
    [queryParams]="tab.queryParams"
    href="javascript:"
    class="tab"
>
    {{ tab.title }}
</a>
