import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlainComponentDialogContainerComponent } from '../dialog/plain-component-dialog-container/plain-component-dialog-container.component';
import { DialogService } from '../dialog/dialog.service';
import { PdfViewerModalComponent } from '../modal/pdf-viewer-modal/pdf-viewer-modal.component';

@Component({
    selector: 'sp-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
    @Input() url: string;
    @Input() height: string;
    @Input() width: string;
    @Input() allowToEnlarge: boolean;

    errorState: boolean;
    currentPage: number;
    totalNumberOfPages: number;

    readonly subscription: Subscription;

    constructor(private dialogService: DialogService) {
        this.subscription = new Subscription();
    }

    ngOnInit(): void {
        this.currentPage = 1;
    }

    onError() {
        this.errorState = true;
    }

    onLoadCompleted(event) {
        this.totalNumberOfPages = event.numPages;
    }

    onPrevClick() {
        if (this.currentPage > 1) {
            this.currentPage -= 1;
        }
    }

    onNextClick() {
        if (this.currentPage < this.totalNumberOfPages) {
            this.currentPage += 1;
        }
    }

    enlarge(): void {
        this.subscription.add(
            this.dialogService
                .open(PlainComponentDialogContainerComponent, {
                    component: PdfViewerModalComponent,
                    width: '118rem',
                    height: '100%',
                    contentComponentData: {
                        documentUrl: this.url,
                    },
                })
                .afterClosed()
                .subscribe(),
        );
    }
}
