import { Injectable } from '@angular/core';
import * as stream from 'getstream';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { Observable, from, of } from 'rxjs';
import type { GetfeedOptions } from '../shared/models/notification/getfeed-options.model';
import type { MatchFeedAction } from '../shared/models';
import type { JobFeedAction } from '../shared/models/activity-feed/job-feed-action.enum';

const activityFeedWebTokenDurationHours = 48;
const activityFeedResultPerPage = 50;

export interface FeedResult<T> {
    next: string;
    results: T[];
    unseen?: number;
}

interface RealTimeResult<T> {
    new: T[];
}

export interface FeedActivity {
    actor: string;
    actorLogo?: string;
    candidate: {
        firstname: string;
        lastname: string;
        profile_picture: string;
    };
    employer: {
        firstname: string;
        lastname: string;
        profile_picture: string;
    };
    object: string;
    time: string;
    verb: MatchFeedAction | JobFeedAction;
    // getstream docs say that any extra data we want to attach to an activity should be
    // placed in the nodes of the FeedActivity object but we'll use just one 'extras' node
    extras?: any;
}

@Injectable()
export class ActivityFeedService {
    private client: stream.Client;

    private userAuthToken: string;
    private tokenUrl: string;
    private userAuthTokenTimeExpiration: Date;

    private nextActivityMarkerId: string = null;

    constructor(private http: HttpClient) {}

    public init(tokenGenerationUrl: string): Observable<boolean> {
        this.tokenUrl = tokenGenerationUrl;
        this.userAuthToken = null;
        this.client = null;

        return this.http
            .get(this.tokenUrl, {
                headers: { 'Content-Type': 'text/plain' },
                responseType: 'text',
            })
            .pipe(
                catchError(() => of(null)),
                tap((token) => {
                    if (token) {
                        this.userAuthToken = token;
                        const validUntil =
                            new Date().getTime() +
                            1000 * 60 * 60 * activityFeedWebTokenDurationHours;
                        this.userAuthTokenTimeExpiration = new Date(validUntil);
                    }
                }),
                map((token) => !!token),
            );
    }

    private setupClient(): Observable<stream.Client> {
        this.client = stream.connect(
            environment.config.getStreamApi.API_KEY,
            this.userAuthToken,
            environment.config.getStreamApi.APP_ID,
        );

        return of(this.client);
    }

    private getStreamClient(): Observable<stream.Client> {
        if (this.client && this.userAuthTokenTimeExpiration > new Date()) {
            return of(this.client);
        }

        return this.setupClient();
    }

    getFeed<T>(
        feedGroup,
        userId,
        options?: GetfeedOptions,
    ): Observable<FeedResult<T>> {
        return this.getStreamClient().pipe(
            concatMap((c) =>
                from(
                    c.feed(feedGroup, userId, this.userAuthToken).get({
                        limit:
                            (options && options.pageSize) ||
                            activityFeedResultPerPage,
                        enrich: true,
                        // from docs: filter the feed on ids smaller than the given value
                        id_lt:
                            (options && options.latestPage) ||
                            !this.nextActivityMarkerId
                                ? ''
                                : this.nextActivityMarkerId,
                    }),
                ),
            ),
            tap((getStreamResult: FeedResult<T>) => {
                if (getStreamResult.next) {
                    const params = new URLSearchParams(getStreamResult.next);
                    this.nextActivityMarkerId = params.get('id_lt');
                } else {
                    this.nextActivityMarkerId = '';
                }
            }),
        );
    }

    hasNextPage() {
        return this.nextActivityMarkerId !== '';
    }

    listenForNew<T>(feedGroup, userId): Observable<T[]> {
        return this.getStreamClient().pipe(
            concatMap(
                (client) =>
                    new Observable<T[]>((subscriber) => {
                        // subs is type FayeSubscription but has been wrong typed in the getstream sdk.
                        const subs: any = client
                            .feed(feedGroup, userId)
                            .subscribe(function (data: RealTimeResult<T>) {
                                subscriber.next(data.new);
                            });

                        return function unsubscribe() {
                            subs.cancel();
                        };
                    }),
            ),
        );
    }

    markAsSeen(
        feedGroupName: string,
        userId: string,
        ids: string[],
    ): Observable<object> {
        return this.getStreamClient().pipe(
            map((client) => client.feed(feedGroupName, userId)),
            concatMap((feed) =>
                from(feed.get({ limit: 1, mark_seen: ids })).pipe(
                    concatMap((obj) => of(feed)),
                ),
            ),
            concatMap((feed) => from(feed.get({ limit: 1, mark_seen: ids }))),
        );
    }
}
