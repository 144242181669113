export interface WelcomeModalData {
    title: string;
    subtitle: string;
    infoText: string;
    buttonLabel: string;
}

export function getProfileFullyPrefilledFromResumeData(
    userName: string,
): WelcomeModalData {
    return {
        title: userName ? `Welcome, ${userName}!` : 'Welcome!',
        infoText:
            'We were able to pre-fill everything from your resume. Please submit your preferences and confirm that ' +
            'your profile is correct on the following screens.',
        subtitle: 'Your smart profile has been created.',
        buttonLabel: 'Confirm your preferences',
    };
}

export function getProfilePartiallyPrefilledFromResumeData(
    userName: string,
): WelcomeModalData {
    return {
        title: userName ? `Welcome, ${userName}!` : 'Welcome!',
        infoText:
            'We’ve done our best to pre-fill everything from your resume - please take a few minutes to ' +
            'confirm this information on the following screens.',
        subtitle: 'Your smart profile has been created.',
        buttonLabel: 'Confirm your profile',
    };
}
