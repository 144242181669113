import { Injectable } from '@angular/core';
import type { DropdownItem, User } from '../../models';
import type { MentionData } from '../../models/mention-data';

const teamKey = 'team';
const teamLabel = 'Team';
export const mentionPrefix = '@';
export const squarepegKey = 'squarepeg';

@Injectable()
export class CommentTextareaService {
    private readonly teamMention: DropdownItem = {
        key: teamKey,
        label: teamLabel,
        icon: '/images/icons/at_icon.svg',
    };

    private readonly squarepegMention: DropdownItem = {
        key: squarepegKey,
        label: squarepegKey,
        icon: '/images/icons/at_icon.svg',
    };

    mapUsersToDropdownItems(reviewers: User[]): DropdownItem[] {
        let availableMentions = reviewers?.length
            ? [this.teamMention, this.squarepegMention]
            : [this.squarepegMention];
        availableMentions = availableMentions.concat(
            reviewers
                .sort((a1, a2) =>
                    a1.fullName < a2.fullName
                        ? -1
                        : a1.fullName === a2.fullName
                          ? 0
                          : 1,
                )
                .map((item) => ({
                    key: `${item.id}`,
                    label: item.fullName,
                    avatar: {
                        imageUrl: item.profilePictureUrl,
                        fullName: item.fullName,
                    },
                })),
        );

        return availableMentions;
    }

    getMentions(availableMentions: DropdownItem[], text: string): MentionData {
        let ids = new Set<string>();
        const keywords = new Set<string>();
        // we must go through available mentions so we can be aware if user deleted some mention
        availableMentions.forEach((mention) => {
            if (text.indexOf(this.getMentionWithPrefix(mention.label)) > -1) {
                switch (mention.key) {
                    case teamKey: {
                        keywords.add(this.getMentionWithPrefix(teamLabel));
                        const availableIds =
                            this.getListOfAvailableIds(availableMentions);
                        ids = new Set(availableIds);

                        break;
                    }
                    case squarepegKey:
                        keywords.add(this.getMentionWithPrefix(squarepegKey));

                        break;
                    default:
                        ids.add(mention.key);
                        keywords.add(this.getMentionWithPrefix(mention.label));
                }
            }
        });

        return { keywords, ids };
    }

    private getListOfAvailableIds(availableMentions: DropdownItem[]) {
        const ids = new Set<string>();
        availableMentions.forEach((item) => {
            if (item.key !== teamKey && item.key !== squarepegKey) {
                ids.add(item.key);
            }
        });

        return ids;
    }

    private getMentionWithPrefix(
        label: string,
        prefix: string = mentionPrefix,
    ): string {
        return `${prefix}${label}`;
    }
}
