import type { AfterViewInit } from '@angular/core';
import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';

export enum TooltipWidthType {
    normal = 'normal',
    wide = 'wide',
}

@Component({
    selector: 'sp-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements AfterViewInit {
    @Input() tooltipText: string;
    @Input() textAlign = 'center';
    @Input() horizontalPosition: 'left' | 'center' | 'right' = 'right';
    @Input() verticalPosition: 'top' | 'bottom' = 'top';
    @Input() customCaretOffsetLeft: number;
    @Input() widthType: TooltipWidthType = TooltipWidthType.wide;
    @Input() cssOverrides: { [key: string]: string };

    @Output() rendered = new EventEmitter();

    @HostBinding('class.normal-width')
    get isNormalWidth() {
        return this.widthType === TooltipWidthType.normal;
    }

    get caretHorizontalOffset() {
        return this.customCaretOffsetLeft
            ? `caret-offset-left-${this.customCaretOffsetLeft}`
            : `popover-${this.horizontalPosition}`;
    }

    ngAfterViewInit(): void {
        this.rendered.emit();
    }
}
