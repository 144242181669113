import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import {
    BehaviorSubject,
    Subject,
    Subscription,
    interval,
    of,
    throwError,
} from 'rxjs';
import { AuthService } from 'ng2-ui-auth';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import type { NavigationExtras, Params } from '@angular/router';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';
import invariant from 'tiny-invariant';
import type { ICandidateProfile } from '../account/profile/candidate-profile/model/candidate-profile.model';
import { UserType } from '../shared/models/user.model';
import type { UserInteractionEventType } from '../shared/models/user.model';
import { Subscription as SpSubscription, User } from '../shared/models';
import type { UserInvitation } from '../shared/models';
import type { ISignupForm } from '../auth/model/signup-form.model';
import {
    containsAnyFromArray,
    isDefined,
    unsubscribeIfActive,
} from '../shared/utils';
import type { IAccessRequest } from '../auth/model/request-access-form.model';
import type { UserData } from '../auth/easy-signup/easy-signup.component';
import { UserInvitationStatus } from '../shared/models/user/user-invitation.interface';
import type { UserInvitationPayload } from '../account/profile/employer-profile/profile-edit/edit-employer-profile.service';
import { msInHour } from '../shared/date-utils';
import { passThroughSignupQueryParams } from './config.service';
import { LocalStorageService } from './local-storage.service';
import { JwtService } from './jwt.service';

const oauthInvitationEmailMismatchError =
    'Please use a Google account associated with the email where you received' +
    ' the invitation.';

type OAuthProvider = 'linkedin' | 'google';

export interface LoginRequestResponse {
    hasPassword: boolean;
}

export enum EmployerUserProfileUpdateType {
    accessInfo,
    summaryUserInfo,
}

export enum CandidateUserProfileUpdateType {
    accountSettings,
    suspensionSettings,
}

export interface OAuthRequest {
    provider: OAuthProvider;
    invitationToken?: string;
    isSignUp: boolean;
}

@Injectable()
export class UserService {
    public user$: BehaviorSubject<User | null> =
        new BehaviorSubject<User | null>(null);
    public userLogout$: Subject<void> = new Subject<void>();

    private _candidateProfile: ICandidateProfile | null = null;
    private _user: User;
    private permissions: string[] = [];

    private hourlyUserUpdateSubscription = new Subscription();

    get user(): User {
        return this._user;
    }

    set user(user: User | null) {
        this._user = User.from(user);
        this.notifyUserUpdated();
        this.resetHourlyUserUpdate();
    }

    constructor(
        private http: HttpClient,
        private ng2UiAuthService: AuthService,
        private storageService: LocalStorageService,
        private jwtService: JwtService,
        private router: Router,
    ) {}

    get candidateProfile(): ICandidateProfile | null {
        return this._candidateProfile;
    }

    set candidateProfile(candidateProfile: ICandidateProfile | null) {
        this._candidateProfile = candidateProfile;
    }

    notifyUserUpdated() {
        this.user$.next(this.user);
    }

    /**
     * Authenticate using the passed provider
     *
     * @param oauthOptions
     * @param userType
     */
    authenticate(
        oauthOptions: OAuthRequest,
        userType?: UserType,
    ): Observable<User> {
        const errorOverrides: Record<number, string> = {};
        const userData: Record<string, any> = {};
        if (userType) {
            userData['userType'] = userType;
        }

        if (oauthOptions.invitationToken) {
            userData['invitationToken'] = oauthOptions.invitationToken;
            errorOverrides[400] = oauthInvitationEmailMismatchError;
        }

        const passThroughSignupParams = this.getPassThroughSignupParamsObject();
        if (passThroughSignupParams) {
            userData['passThroughSignupParams'] = passThroughSignupParams;
        }
        userData['isSignUp'] = oauthOptions.isSignUp;

        return this.ng2UiAuthService
            .authenticate(oauthOptions.provider, userData)
            .pipe(
                tap((user) => {
                    this.removePassThroughSignupParams();
                    if (isDefined(user.id)) {
                        this.authSuccessHandler(user);
                    }
                }),
                catchError((error) =>
                    this.authErrorHandler(error, errorOverrides),
                ),
            );
    }

    /**
     * Log In using the passed credentials
     *
     * @param {String} email
     * @param {String} password
     */
    login(email: string, password: string): Observable<User | string> {
        const credentials = { email, password };

        return this.ng2UiAuthService.login(credentials).pipe(
            tap((user) => this.authSuccessHandler(user)),
            catchError((error) => this.authErrorHandler(error)),
        );
    }

    initUser(): Promise<User | null> {
        const userObservable$ = this.ng2UiAuthService.getToken()
            ? this.fetchCurrentUser()
            : of(null);

        return userObservable$.toPromise();
    }

    getUserObservable(): Observable<User> {
        return this.user ? of(this.user) : this.fetchCurrentUser();
    }

    refreshCurrentUser() {
        this.fetchCurrentUser().subscribe();
    }

    loginWithToken(token: string): Observable<User> {
        this.ng2UiAuthService.setToken(token);

        return this.fetchCurrentUser().pipe(
            catchError((error) => {
                this.ng2UiAuthService.removeToken();

                return this.authErrorHandler(error);
            }),
        );
    }

    loginWithAuthToken(token: string): Observable<User> {
        return this.http.get<User>(`/auth/link/${token}`).pipe(
            tap((user) => {
                invariant(user.token);
                this.ng2UiAuthService.setToken(user.token);
                this.authSuccessHandler(user);
            }),
            catchError((error) => this.authErrorHandler(error)),
        );
    }

    /**
     * Sign Up using the passed credentials
     */
    signup(signupData: ISignupForm): Observable<User> {
        const passThroughSignupParams = this.getPassThroughSignupParamsObject();
        if (passThroughSignupParams) {
            signupData.passThroughSignupParams = passThroughSignupParams;
        }

        return this.http.post<User>('/auth/register', signupData).pipe(
            tap((user) => this.postSignupAction(user)),
            catchError((error) => this.authErrorHandler(error)),
        );
    }

    /**
     * Easy Sign Up with email, firstName and lastName and generate empty match
     */
    easySignup(
        userData: UserData,
        jobHashId: string,
        createEmptyMatch: boolean,
    ): Observable<User | string> {
        const passThroughSignupParams = {
            ...this.getPassThroughSignupParamsObject(),
            employerJobId: jobHashId,
            createEmptyMatch,
        };
        const body = { ...userData, passThroughSignupParams };

        return this.http.post<User>('/auth/register-and-apply', body).pipe(
            tap((user) => {
                user.generatedMatchesCount = createEmptyMatch ? 1 : 0;
                this.postSignupAction(user);
            }),
            catchError((error) => this.authErrorHandler(error)),
        );
    }

    /**
     * Request demo using the passed credentials
     *
     * @param name
     * @param title
     * @param email
     * @param companyName
     * @param phoneNumber (optional)
     */
    requestDemo(
        name: string,
        title: string,
        email: string,
        companyName: string,
        phoneNumber: number,
    ): Observable<any> {
        const demoRequestData = {
            type: 'employerDemoRequest',
            title,
            email,
            name,
            companyName,
            phoneNumber,
        };

        return this.http.post(`/public/contact`, demoRequestData);
    }

    changePassword(
        newPassword: string,
        currentPassword?: string,
    ): Observable<User> {
        return this.http
            .post<User>('/api/me/passwordReset', {
                currentPassword,
                newPassword,
            })
            .pipe(tap(() => (this.user.hasPassword = true)));
    }

    requestPasswordReset(email: string): Observable<void> {
        return this.http.post<void>('/auth/passwordReset/link', email, {
            headers: { 'Content-Type': 'text/plain' },
        });
    }

    loginRequest(
        email: string,
        requestParams: Record<string, string>,
    ): Observable<LoginRequestResponse> {
        const params = this.getQueryParams(requestParams);

        return this.http.post<LoginRequestResponse>(
            `/auth/login-request`,
            email,
            { headers: { 'Content-Type': 'text/plain' }, params },
        );
    }

    requestAuthLink(
        email: string,
        requestParams: Record<string, string>,
    ): Observable<void> {
        const params = this.getQueryParams(requestParams);

        return this.http.post<void>(`/auth/link`, email, {
            headers: { 'Content-Type': 'text/plain' },
            params,
        });
    }

    resetPassword(password: string, token: string): Observable<User> {
        return this.http
            .post<User>('/auth/passwordReset', { newPassword: password, token })
            .pipe(
                tap((user) => {
                    invariant(user.token);
                    this.ng2UiAuthService.setToken(user.token);
                    this.authSuccessHandler(user);
                }),
            );
    }

    requestAccess(requestData: IAccessRequest): Observable<void> {
        return this.http.post<void>('/auth/request-access', requestData);
    }

    refreshUserPersonalInfo(
        firstName: string,
        lastName: string,
        email: string,
        displayName?: string,
        phoneNumber?: string,
    ) {
        this.user = {
            ...this._user,
            ...{ firstName, lastName, email, displayName, phoneNumber },
        } as User;
    }

    /**
     * Set new user data
     */
    setUserAndToken(user: any) {
        this.ng2UiAuthService.setToken(user.token);
        this.user = user;
    }

    isAuthenticated(): boolean {
        return this.ng2UiAuthService.isAuthenticated();
    }

    isSubscriptionActive(): boolean {
        return (
            !!this.user?.subscription &&
            Object.assign(new SpSubscription(), this.user.subscription).isActive
        );
    }

    isTrialActive(): boolean {
        return (
            !!this.user?.subscription &&
            Object.assign(new SpSubscription(), this.user.subscription)
                .isTrialActive
        );
    }

    isSubscriptionCancelled(): boolean {
        return (
            !!this.user?.subscription &&
            Object.assign(new SpSubscription(), this.user.subscription)
                .isCanceled
        );
    }

    getEmployerUsers(employerId?: number): Observable<User[]> {
        return this.http
            .get<User[]>(`/api/employers/${employerId}/users`)
            .pipe(map((users) => users.map((user) => User.from(user))));
    }

    signOut() {
        this.ng2UiAuthService
            .logout()
            .subscribe({ complete: () => this.signOutSuccessHandler() });
    }

    getPrefillEmployerFormData(
        email: string,
        timeoutMs = 5000,
    ): Observable<ISignupForm> {
        return this.http
            .post<ISignupForm>('/auth/register/prefill', email, {
                headers: { 'Content-Type': 'text/plain' },
            })
            .pipe(timeout(timeoutMs));
    }

    // true - if candidate did not completed assessments and they are not optional
    // false - candidate completed assessments or candidate with optional assessments or both
    shouldCandidateAssessmentsBeCompleted() {
        const user = this.user;

        return (
            user &&
            user.type === UserType.candidate &&
            !(user.completedAllAssessments || user.optionalTraitsAssessments) &&
            !user.hasUploadedResume
        );
    }

    isAccountManagerUser(): boolean {
        return !!this.user.userImpersonating;
    }

    getCandidateProfile(
        forceReload = true,
        skipAutosavedData = false,
    ): Observable<ICandidateProfile> {
        if (!forceReload && this._candidateProfile) {
            return of(this._candidateProfile);
        }

        return this.http
            .get<ICandidateProfile>('/api/me/profile', {
                params: { skipAutosavedData: `${skipAutosavedData}` },
            })
            .pipe(tap((profile) => (this._candidateProfile = profile)));
    }

    hasAnyPermission(permissions?: string[]): boolean {
        if (!permissions?.length) {
            return true;
        }

        if (!this.permissions?.length) {
            this.permissions = this.jwtService.getPermissions(
                this.getCurrentUserJwtToken(),
            );
        }

        return containsAnyFromArray(this.permissions || [], permissions);
    }

    /**
     * Called when the user is successfully logged out
     */
    private signOutSuccessHandler(extras?: NavigationExtras) {
        // fix exception when open any link in a new tab
        const userType = this.user ? this.user.type : UserType.candidate;

        sessionStorage.removeItem('selectedJob');
        unsubscribeIfActive(this.hourlyUserUpdateSubscription);
        this.user = null;
        this._candidateProfile = null;
        this.userLogout$.next();
        this.permissions = [];

        this.navigateToLogin(userType, extras);
    }

    // note: wrapping into a separate method due to issues with testing window.location.href
    public navigateToLogin(userType: UserType, extras?: NavigationExtras) {
        let queryParams = '';
        if (extras?.queryParams) {
            queryParams = '?';
            queryParams += Object.keys(extras.queryParams)
                .filter((key) => extras.queryParams?.[key])
                .map((key) => `${key}=${extras.queryParams?.[key]}`)
                .join('&');
        }

        this.router.navigateByUrl(`/login/${userType}${queryParams}`);
    }

    private authSuccessHandler(user: any) {
        this.permissions = this.jwtService.getPermissions(
            this.getCurrentUserJwtToken(),
        );
        this.user = user;
    }

    private authErrorHandler(
        response: any,
        errorMessageOverrides: { [key: number]: string } = {},
    ) {
        let responseError = response;

        if (
            response.status === 400 ||
            response.status === 401 ||
            response.status === 403
        ) {
            responseError =
                errorMessageOverrides && errorMessageOverrides[response.status]
                    ? errorMessageOverrides[response.status]
                    : this.parseResponseError(response);
        }

        return throwError(responseError);
    }

    inviteUser(
        employerId: number,
        payload: UserInvitationPayload,
        status: UserInvitationStatus = UserInvitationStatus.invitedByEmployer,
    ): Observable<UserInvitation> {
        const invitationOwnerUser = this.user ? this.user.id : null;

        return this.http.post<UserInvitation>(
            `/auth/employers/${employerId}/invite`,
            { ...payload, status, invitationOwnerUser },
        );
    }

    private parseResponseError(response: any): any {
        let responseError;

        if (response.error && response.error.error) {
            responseError = response.error.error;
        } else {
            try {
                responseError = JSON.parse(response._body).error;
            } catch (e) {
                responseError = response;
            }
        }

        return Array.isArray(responseError) ? responseError[0] : responseError;
    }

    updateCurrentEmployerUserProfileData(
        userPayload: { [key: string]: any },
        updateType: EmployerUserProfileUpdateType,
    ): Observable<User> {
        return this.updateEmployerUserProfileData(
            this.user.id,
            userPayload,
            updateType,
        );
    }

    updateEmployerUserProfileData(
        userId: number,
        userPayload: Record<string, any>,
        updateType: EmployerUserProfileUpdateType,
    ): Observable<User> {
        return this.http.patch<User>(
            `/api/users/${userId}`,
            this.getProfileDataFromPayload(userPayload, updateType),
        );
    }

    updateOtherEmployerUser(
        userId: number,
        payload: Record<string, any>,
    ): Observable<User> {
        return this.http.patch<User>(`/api/employers/users/${userId}`, payload);
    }
    getProfileDataFromPayload(
        userPayload: Record<string, any>,
        updateType: EmployerUserProfileUpdateType,
    ): FormData {
        let payloadData = {};

        if (updateType === EmployerUserProfileUpdateType.accessInfo) {
            payloadData = Object.assign(payloadData, {
                position: userPayload.position,
                userEmployerUpdateType:
                    EmployerUserProfileUpdateType.accessInfo,
            });
        } else {
            payloadData = Object.assign(payloadData, {
                personalInfo: {
                    firstName: userPayload.firstName,
                    lastName: userPayload.lastName,
                    phoneNumber: userPayload.phoneNumber,
                },
                userEmployerUpdateType:
                    EmployerUserProfileUpdateType.summaryUserInfo,
            });
        }

        // FormData as payload automatically sets headers content-type as
        // multipart for file submission.
        const payload = new FormData();
        // Json payload with correct content-type for correct dto deserialization in backend
        const blob = new Blob([JSON.stringify(payloadData)], {
            type: 'application/json',
        });
        payload.append('data', blob);
        if (userPayload['file']) {
            payload.append('avatar', userPayload['file']);
        }

        return payload;
    }

    updateUserInteractions(
        userInteractionEvent: UserInteractionEventType,
    ): Observable<void> {
        return this.http
            .patch<void>(
                `/api/users/interaction-events/${userInteractionEvent}`,
                {},
            )
            .pipe(
                tap(() => {
                    const user = this.user;
                    if (!user.interactionEvents) {
                        user.interactionEvents = [userInteractionEvent];
                    } else if (
                        !user.interactionEvents.includes(userInteractionEvent)
                    ) {
                        user.interactionEvents.push(userInteractionEvent);
                    }
                    this.user = user;
                }),
            );
    }

    deleteAccount(
        currentPassword?: string,
        reasons?: string[],
    ): Observable<void> {
        return this.http.post<void>('/api/users/me/delete', {
            password: currentPassword,
            reasons,
        });
    }

    getEmployerUserInvitation(token: string): Observable<UserInvitation> {
        return this.http.get<UserInvitation>(
            `/auth/register/invitation/${token}`,
        );
    }

    storePassThroughQueryParams(queryParams: Params) {
        Object.keys(queryParams)
            .filter(
                (paramKey) =>
                    !!queryParams[paramKey] &&
                    passThroughSignupQueryParams.includes(paramKey),
            )
            .forEach((paramKey) =>
                this.storageService.storeSessionEntry(
                    paramKey,
                    queryParams[paramKey].trim(),
                ),
            );
    }

    getStringifiedPassThroughSignupParamsObject() {
        const params = this.getPassThroughSignupParamsObject();

        return !!params && Object.keys(params).length
            ? JSON.stringify(params)
            : null;
    }

    private fetchCurrentUser() {
        return this.http.get<User>('/api/me').pipe(
            map((user) => User.from(user)),
            tap((user) => this.authSuccessHandler(user)),
        );
    }

    private getPassThroughSignupParamsObject(): Record<string, any> | null {
        const params: Record<string, any> = {};
        passThroughSignupQueryParams.forEach((param) => {
            const value = this.storageService.getSessionEntry(param);
            if (value) {
                params[param] = value;
            }
        });

        return Object.keys(params).length ? params : null;
    }

    private removePassThroughSignupParams() {
        passThroughSignupQueryParams.forEach((param) =>
            this.storageService.removeSessionEntry(param),
        );
    }

    private getCurrentUserJwtToken(): string | null {
        return this.ng2UiAuthService.getToken();
    }

    private postSignupAction(user: User) {
        this.removePassThroughSignupParams();

        invariant(user.token);
        this.ng2UiAuthService.setToken(user.token);
        this.authSuccessHandler(user);
    }

    private getQueryParams(requestParams: Record<string, string>) {
        let queryParams = new HttpParams();
        Object.keys(requestParams).forEach((key) => {
            if (requestParams[key]) {
                queryParams = queryParams.append(key, requestParams[key]);
            }
        });

        return queryParams;
    }

    private resetHourlyUserUpdate() {
        unsubscribeIfActive(this.hourlyUserUpdateSubscription);

        this.hourlyUserUpdateSubscription = interval(msInHour)
            .pipe(switchMap(() => this.fetchCurrentUser()))
            .subscribe();
    }
}
