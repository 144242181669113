import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './core/user/auth.guard';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: 'account',
                    loadChildren: () =>
                        import('app/account/account.module').then(
                            (m) => m.AccountModule,
                        ),
                },
                {
                    path: 'assessments',
                    loadChildren: () =>
                        import('app/assessments/assessments.module').then(
                            (m) => m.AssessmentsModule,
                        ),
                },
                {
                    path: 'matches',
                    loadChildren: () =>
                        import(
                            'app/matches/candidate/candidate-matches.module'
                        ).then((m) => m.CandidateMatchesModule),
                },
                {
                    path: 'jobs/analytics',
                    loadChildren: () =>
                        import('app/analytics/analytics.module').then(
                            (m) => m.AnalyticsModule,
                        ),
                },
                {
                    path: 'jobs/:jobId/matches',
                    canActivate: [AuthGuard],
                    loadChildren: () =>
                        import(
                            'app/matches/employer/employer-matches.module'
                        ).then((m) => m.EmployerMatchesModule),
                },
                {
                    path: 'jobs/:jobId/analytics',
                    loadChildren: () =>
                        import('app/analytics/analytics.module').then(
                            (m) => m.AnalyticsModule,
                        ),
                },
                {
                    path: 'jobs',
                    loadChildren: () =>
                        import('app/jobs/jobs.module').then(
                            (m) => m.JobsModule,
                        ),
                },
                {
                    path: 'actions',
                    loadChildren: () =>
                        import('app/actions/actions.module').then(
                            (m) => m.ActionsModule,
                        ),
                },
                {
                    path: 'messages',
                    loadChildren: () =>
                        import('app/messaging/messaging.module').then(
                            (m) => m.MessagingModule,
                        ),
                },
                {
                    path: 'campaigns',
                    loadChildren: () =>
                        import('app/campaigns/campaigns.module').then(
                            (m) => m.CampaignsModule,
                        ),
                },
            ],
            { relativeLinkResolution: 'legacy' },
        ),
    ],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
