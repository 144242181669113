import type { GenericFileInfo } from '../../../../shared/models';
import { TraitsResult } from '../traits-results/model/traits-result.model';
import { PersonalInfo } from './personal-info.model';
import { WorkExperience } from './work-experience.model';
import { Education } from './education.model';
import { Certification } from './certification.model';
import { CandidateSkills } from './candidate-skills.model';
import { CandidatePreferences } from './candidate-preferences.model';
import { CandidateDemographics } from './candidate-demographics.model';
import type { IPersonalInfo } from './personal-info.interface';
import type { IWorkExperience } from './work-experience.interface';
import type { IEducation } from './education.interface';
import type { ICertification } from './certification.interface';
import type { Language } from './index';

export interface ICandidateProfile {
    isProfileReadyForReview?: boolean;
    displayName?: string;
    suspendedUntil?: Date;
    suspensionTime?: number; // hours

    personalInfo?: IPersonalInfo;
    workExperience?: IWorkExperience[];
    schools: IEducation[];
    certifications: ICertification[];
    skills?: CandidateSkills;
    preferences?: CandidatePreferences;
    demographics: CandidateDemographics;
    languages?: Language[];
    leaderships?: string[];
    clubs?: string[];
    volunteering?: string[];
    publications?: string[];
    awards?: string[];
    courses?: string[];
    others?: string[];
    resume?: GenericFileInfo;
    traits: TraitsResult[];
    priorities?: number[];
}

export class CandidateProfile {
    isProfileReadyForReview?: boolean;
    displayName?: string;
    suspendedUntil?: Date;

    personalInfo?: PersonalInfo;
    workExperience?: WorkExperience[];
    schools: Education[];
    certifications: Certification[];
    skills?: CandidateSkills;
    preferences?: CandidatePreferences;
    priorities?: number[];
    demographics: CandidateDemographics;
    languages?: Language[];
    leaderships?: string[];
    clubs?: string[];
    volunteering?: string[];
    publications?: string[];
    awards?: string[];
    courses?: string[];
    others?: string[];
    resume?: GenericFileInfo;
    traits: TraitsResult[];

    static fromStorage(candidateProfile: ICandidateProfile): CandidateProfile {
        const profile = new CandidateProfile();
        profile.isProfileReadyForReview =
            candidateProfile.isProfileReadyForReview;
        profile.displayName = candidateProfile.displayName;

        profile.personalInfo = new PersonalInfo(candidateProfile.personalInfo);
        profile.workExperience = candidateProfile.workExperience.map(
            (item) => new WorkExperience(item),
        );
        profile.schools = candidateProfile.schools.map(
            (item) => new Education(item),
        );
        profile.certifications = candidateProfile.certifications.map(
            (item) => new Certification(item),
        );
        profile.languages = candidateProfile.languages || [];
        profile.leaderships = candidateProfile.leaderships || [];
        profile.clubs = candidateProfile.clubs || [];
        profile.volunteering = candidateProfile.volunteering || [];
        profile.publications = candidateProfile.publications || [];
        profile.awards = candidateProfile.awards || [];
        profile.courses = candidateProfile.courses || [];
        profile.others = candidateProfile.others || [];
        profile.priorities = candidateProfile.priorities || [];

        const profileSkills: any = candidateProfile.skills || {};
        const skills = new CandidateSkills(
            profileSkills.topSkills || [],
            profileSkills.additionalSkills || [],
            true,
        );
        profile.skills = skills;

        const profilePreferences: any = candidateProfile.preferences || {};
        const preferences = new CandidatePreferences(
            profilePreferences.minSalary || 0,
            profilePreferences.maxSalary || 0,
            profilePreferences.preferredLocation,
            profilePreferences.willingToRelocate,
            profilePreferences.communication,
            profilePreferences.distanceToCommute,
        );
        profile.preferences = preferences;

        profile.demographics = candidateProfile.demographics
            ? new CandidateDemographics(candidateProfile.demographics)
            : new CandidateDemographics({});
        profile.resume = candidateProfile.resume;
        profile.traits = (candidateProfile.traits || []).map((traitResults) =>
            Object.assign(new TraitsResult(), {
                label: traitResults.label,
                score: traitResults.score,
                averageScore: traitResults.averageScore,
            }),
        );

        return profile;
    }
}
