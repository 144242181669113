import type { SingleInputFieldContentComponentParams } from '../account/profile/candidate-profile/steps/step-accomplishments/shared/single-input-field-content/single-input-field-content.component';
import {
    CandidateOnboardingControls,
    awardsLogo,
    awardsTitle,
    clubsLogo,
    clubsTitle,
    coursesLogo,
    coursesTitle,
    leadershipsLogo,
    leadershipsTitle,
    othersLogo,
    othersTitle,
    publicationsLogo,
    publicationsTitle,
    volunteeringLogo,
    volunteeringTitle,
} from '../account/profile/candidate-profile/profile-stepper/candidate-profile-stepper.constants';

export enum AccomplishmentSectionType {
    leaderships = 'leaderships',
    clubs = 'clubs',
    volunteering = 'volunteering',
    publications = 'publications',
    awards = 'awards',
    courses = 'courses',
    others = 'others',
}

export const accomplishmentSectionParams: {
    [key in AccomplishmentSectionType]: SingleInputFieldContentComponentParams;
} = {
    [AccomplishmentSectionType.leaderships]: {
        label: 'Leadership Description *',
        placeholder: 'Add leadership',
        logo: leadershipsLogo,
        title: leadershipsTitle,
        tooltipMessage: 'Required',
        controlId: CandidateOnboardingControls.leadership,
    } as SingleInputFieldContentComponentParams,

    [AccomplishmentSectionType.clubs]: {
        label: 'Club / Association Name *',
        placeholder: 'Add club / association',
        logo: clubsLogo,
        title: clubsTitle,
        tooltipMessage: 'Required',
        controlId: CandidateOnboardingControls.clubs,
    } as SingleInputFieldContentComponentParams,

    [AccomplishmentSectionType.volunteering]: {
        label: 'Organization Name *',
        placeholder: 'Add organization',
        logo: volunteeringLogo,
        title: volunteeringTitle,
        tooltipMessage: 'Required',
        controlId: CandidateOnboardingControls.volunteering,
    } as SingleInputFieldContentComponentParams,

    [AccomplishmentSectionType.publications]: {
        label: 'Publication Title *',
        placeholder: 'Add publication',
        logo: publicationsLogo,
        title: publicationsTitle,
        tooltipMessage: 'Required',
        controlId: CandidateOnboardingControls.publication,
    } as SingleInputFieldContentComponentParams,

    [AccomplishmentSectionType.awards]: {
        label: 'Award Title *',
        placeholder: 'Add award',
        logo: awardsLogo,
        title: awardsTitle,
        tooltipMessage: 'Required',
        controlId: CandidateOnboardingControls.award,
    } as SingleInputFieldContentComponentParams,

    [AccomplishmentSectionType.courses]: {
        label: 'Course Name *',
        placeholder: 'Add course',
        logo: coursesLogo,
        title: coursesTitle,
        tooltipMessage: 'Required',
        controlId: CandidateOnboardingControls.course,
    } as SingleInputFieldContentComponentParams,

    [AccomplishmentSectionType.others]: {
        label: 'Is there anything else you would like employers to know about you? (e.g. hobby) *',
        placeholder: 'Add item',
        logo: othersLogo,
        title: othersTitle,
        tooltipMessage: 'Required',
        controlId: CandidateOnboardingControls.other,
    } as SingleInputFieldContentComponentParams,
};
