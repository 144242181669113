import { formatDate } from '@angular/common';
import type { User } from '../shared/models';
import { BroadcastEventType } from '../shared/models';
import { teamAndUserManagementLabel } from '../shared/constants';
import type { IHeaderLink, INavigationTree } from './navbar.service';

export const traitsAssessmentRoute = '/assessments/6';

export enum BasicMenuItemEvent {
    signOut = 'signout',
    confirmSignOut = 'signout-confirm',
    showChildrenMobile = 'mobile-children',
    openNotificationsDropdown = 'openNotificationsDropdown',
}

export enum MenuItemType {
    menuItem = 'menuItem', // default
    separator = 'separator',
    dropdown = 'dropdown',
    dropdownHeader = 'dropdown-header',
}

const separatorItem: IHeaderLink = {
    label: '',
    type: MenuItemType.separator,
    routerLink: '',
};

const activeCandidateTooltip = 'Your account is currently active.';

export const basicUserMenu: INavigationTree = {
    showStepper: false,
    mainMenu: [
        { ...separatorItem, smScreenOnly: true },
        {
            label: 'Sign Out',
            routerLink: '',
            icon: 'signout_light',
            selectedEventName: BasicMenuItemEvent.confirmSignOut,
            smScreenOnly: true,
        },
    ],
    secondaryMenu: [
        separatorItem,
        {
            label: 'Sign Out',
            routerLink: '',
            icon: 'signout_light',
            selectedEventName: BasicMenuItemEvent.confirmSignOut,
            class: 'ignore-styling-md',
        },
    ],
};

export const createCandidateProfilePrefix = '/account/profile/create';
export function getFullCandidateMenu(
    candidate: User,
    isSmScreen: boolean,
): INavigationTree {
    return {
        showStepper: false,
        mainMenu: [
            {
                label: isSmScreen ? 'Jobs' : 'Job Matches',
                routerLink: '/matches',
                icon: 'jobmatches',
            },
            {
                label: isSmScreen ? 'Profile' : 'Your Profile',
                icon: 'user',
                routerLink: candidate.isProfileComplete
                    ? '/account/profile/view'
                    : '/account/profile/create/review',
                withAttentionIndicator: !candidate.isProfileComplete,
            },
            {
                label: isSmScreen ? 'Traits' : 'Traits Assessment',
                routerLink: '/account/profile/results/traits',
                icon: 'chart-graph',
                withAttentionIndicator: !candidate.completedAllAssessments,
            },
            {
                label: isSmScreen ? 'Log out' : 'Sign Out',
                icon: 'signout_light',
                routerLink: '',
                smScreenOnly: true,
                selectedEventName: BasicMenuItemEvent.signOut,
            },
            { ...separatorItem, smScreenOnly: true },
            {
                label: 'Settings',
                icon: 'cog',
                routerLink: '/account/settings',
                smScreenOnly: true,
            },
            {
                label: 'Messages',
                routerLink: '/messages',
                icon: 'email',
                smScreenOnly: true,
                notificationKeyToListen:
                    BroadcastEventType.unconsumedChatMessage,
            },
        ],
        secondaryMenu: [],
    };
}

const jobActiveRouteRegexps = [
    /\/jobs$/gi,
    /\/jobs\/\d+\/matches/gi,
    /\/jobs\/(published|archived|drafts)/gi,
];

const analyticsActiveRouteRegexps = [
    /\/jobs\/analytics$/gi,
    /\/jobs\/\d+\/analytics/gi,
];

const campaignsActiveRouteRegexps = [
    /\/campaigns$/gi,
    /\/campaigns\/(published|archived)/gi,
];

export const basicEmployerMenu: INavigationTree = {
    showStepper: false,
    isEmployer: true,
    mainMenu: [
        {
            label: 'Jobs',
            routerLink: '/jobs',
            icon: 'jobmatches',
            activeRouteRegexp: [...jobActiveRouteRegexps],
        },
        {
            label: 'Sourcing Campaigns',
            routerLink: '/campaigns',
            icon: 'megaphone_solid',
            activeRouteRegexp: [...campaignsActiveRouteRegexps],
            feature: 'sourcing-campaigns',
        },
        {
            label: 'Analytics',
            routerLink: '/jobs/analytics',
            icon: 'analytics',
            activeRouteRegexp: [...analyticsActiveRouteRegexps],
        },
        {
            label: 'Sign Out',
            routerLink: '',
            icon: 'signout_light',
            selectedEventName: BasicMenuItemEvent.signOut,
            smScreenOnly: true,
        },
        { ...separatorItem, smScreenOnly: true },
        {
            label: 'Settings',
            routerLink: null,
            icon: 'cog',
            selectedEventName: BasicMenuItemEvent.showChildrenMobile,
            smScreenOnly: true,
        },
        {
            label: 'Messages',
            routerLink: '/messages',
            icon: 'email',
            smScreenOnly: true,
            notificationKeyToListen: BroadcastEventType.unconsumedChatMessage,
        },
        {
            label: 'Notifications',
            routerLink: null,
            icon: 'notification',
            selectedEventName: BasicMenuItemEvent.openNotificationsDropdown,
            notificationKeyToListen:
                BroadcastEventType.employerFeedNotification,
            smScreenOnly: true,
        },
    ],
    secondaryMenu: [],
};

export const fullEmployerMenu: INavigationTree = {
    isEmployer: true,
    mainMenu: [
        {
            label: 'Jobs',
            routerLink: '/jobs',
            icon: 'jobmatches',
            activeRouteRegexp: [...jobActiveRouteRegexps],
        },
        {
            label: 'Sourcing Campaigns',
            routerLink: '/campaigns',
            icon: 'megaphone_solid',
            activeRouteRegexp: [...campaignsActiveRouteRegexps],
            feature: 'sourcing-campaigns',
        },
        {
            label: 'Analytics',
            routerLink: '/jobs/analytics',
            icon: 'analytics',
            activeRouteRegexp: [...analyticsActiveRouteRegexps],
        },
        {
            label: 'Sign Out',
            routerLink: '',
            icon: 'signout_light',
            selectedEventName: BasicMenuItemEvent.signOut,
            smScreenOnly: true,
        },
        { ...separatorItem, smScreenOnly: true },
        {
            label: 'Settings',
            routerLink: null,
            icon: 'cog',
            selectedEventName: BasicMenuItemEvent.showChildrenMobile,
            smScreenOnly: true,
        },
        {
            label: 'Messages',
            routerLink: '/messages',
            icon: 'email',
            smScreenOnly: true,
            notificationKeyToListen: BroadcastEventType.unconsumedChatMessage,
        },
        {
            label: 'Notifications',
            routerLink: null,
            hoverText: 'Notifications',
            icon: 'notification',
            selectedEventName: BasicMenuItemEvent.openNotificationsDropdown,
            notificationKeyToListen:
                BroadcastEventType.employerFeedNotification,
            smScreenOnly: true,
        },
    ],
    secondaryMenu: [],
};

export function buildCandidateMenu(
    candidate: User,
    isSmScreen: boolean,
): INavigationTree {
    const menu = { ...getFullCandidateMenu(candidate, isSmScreen) };
    let candidateTooltip = activeCandidateTooltip;
    if (candidate.suspendedUntil) {
        candidateTooltip =
            `Your account is currently suspended until ` +
            `${formatDate(candidate.suspendedUntil, 'MMM dd, YYYY', 'en-US')}`;
    }

    menu.secondaryMenu = [
        {
            label: 'Messages',
            hoverText: 'Messages',
            routerLink: '/messages',
            icon: 'email',
            notificationKeyToListen: BroadcastEventType.unconsumedChatMessage,
        },
        separatorItem,
        {
            label: candidate.displayName,
            routerLink: '/account',
            icon: '',
            type: MenuItemType.dropdown,
            withStatusLight: true,
            statusLightActive: !candidate.suspendedUntil,
            statusLightTooltip: candidateTooltip,
            children: [
                {
                    label: 'Settings',
                    routerLink: '/account/settings',
                },
                {
                    label: '',
                    type: MenuItemType.separator,
                    routerLink: '',
                },
                {
                    label: 'Sign Out',
                    routerLink: '',
                    icon: 'signout_light',
                    class: 'icon-align-right',
                    selectedEventName: BasicMenuItemEvent.signOut,
                },
            ],
        },
    ];

    return menu;
}

export function buildEmployerMenu(
    user: User,
    baseMenu: INavigationTree,
): INavigationTree {
    const menu = { ...baseMenu };

    let logo: string = null;
    if (user.employer && user.employer.thumbnailLogoUrl) {
        logo = user.employer.thumbnailLogoUrl;
    }
    const dropDownMenuOptions = [
        {
            label: user.fullName,
            icon: user.profilePictureUrl,
            routerLink: '',
            userName: user.fullName,
            type: MenuItemType.dropdownHeader,
        },
        {
            label: 'My Profile',
            routerLink: '/account/my-profile',
            icon: 'user-default',
        },
        {
            label: 'Notifications',
            routerLink: '/account/notifications',
            icon: 'notification',
        },
        {
            label: 'Messaging',
            routerLink: '/account/messaging',
            icon: 'email',
        },
        separatorItem,
        {
            label: user.employer.employerName,
            icon: logo,
            userName: user.employer.employerName,
            routerLink: '',
            type: MenuItemType.dropdownHeader,
        },
        {
            label: 'Company Profile',
            routerLink: '/account/company-profile',
            icon: 'company_solid',
        },
        {
            label: 'Account Settings',
            routerLink: '/account/account-settings',
            icon: 'cog',
        },
        {
            label: teamAndUserManagementLabel,
            routerLink: '/account/teams-and-users',
            icon: 'public-filled',
        },
        {
            label: 'Integrations',
            routerLink: '/account/integrations',
            icon: 'synchronize',
            permissions: ['PERM_ATS_OPERATIONS'],
        },
        {
            label: 'Referral Program',
            routerLink: '/account/referral-program',
            icon: 'gift',
        },
        separatorItem,
        {
            label: 'Sign Out',
            routerLink: '',
            selectedEventName: BasicMenuItemEvent.signOut,
            icon: 'signout_light',
            class: 'lighter',
        },
    ];

    menu.secondaryMenu = [
        {
            label: 'Notifications',
            routerLink: null,
            hoverText: 'Notifications',
            selectedEventName: BasicMenuItemEvent.openNotificationsDropdown,
            notificationKeyToListen:
                BroadcastEventType.employerFeedNotification,
            icon: 'notification',
        },
        {
            label: 'Messages',
            hoverText: 'Messages',
            routerLink: '/messages',
            icon: 'email',
            notificationKeyToListen: BroadcastEventType.unconsumedChatMessage,
        },
        separatorItem,
        {
            label: user.fullName,
            routerLink: '/account',
            type: MenuItemType.dropdown,
            children: dropDownMenuOptions,
        },
    ];

    // Sets the dropdown menu also to the settings menu for mobile right menu bar
    const settingsMenu = menu.mainMenu.find(
        (menuItem) => menuItem.label === 'Settings',
    );
    if (settingsMenu) {
        settingsMenu.children = dropDownMenuOptions;
    }

    return menu;
}
