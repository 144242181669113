<ul
    class="locations-content"
    [class.both-locations-included]="
        candidateLocationsPreviewLocation && officeLocations?.length > 0
    "
    [class.without-color]="!colorfulChip"
>
    <li class="location-type candidate-locations {{ job.jobLocationType }}">
        {{ locationTypeChip }}
        <span
            *ngIf="candidateLocationsPreviewLocation"
            class="with-ellipsis with-dot"
            spTooltip
            [tooltipText]="!candidateLocationsPlusNMore ? tooltipCandidateLocations : null"
        >
            {{ candidateLocationsPreviewLocation }}
        </span>
        <span
            *ngIf="candidateLocationsPlusNMore"
            spTooltip
            [tooltipText]="tooltipCandidateLocations"
            (visibilityChange)="candidateLocationsTooltipOpened = $event"
            [textAlign]="'left'"
        >
            {{ candidateLocationsPlusNMore }}
            <img
                alt=""
                class="tooltip-arrow-candidate-locations"
                [class.opened]="candidateLocationsTooltipOpened"
            />
        </span>
    </li>

    <!-- If office locations should be grouped-->
    <li *ngIf="groupedOfficeLocations" class="office-locations">
        <span
            *ngIf="officeLocationsPreviewLocation"
            class="with-ellipsis with-dot"
            spTooltip
            [tooltipText]="!officeLocationsPlusNMore ? tooltipOfficeLocations : null"
        >
            {{ officeLocationsPreviewLocation }}
        </span>
        <span
            class="plus-more-office-locations"
            *ngIf="officeLocationsPlusNMore"
            spTooltip
            [tooltipText]="tooltipOfficeLocations"
            (visibilityChange)="officeLocationsTooltipOpened = $event"
            [textAlign]="'left'"
        >
            {{ officeLocationsPlusNMore }}
            <img
                alt=""
                class="tooltip-arrow-office-locations"
                [class.opened]="officeLocationsTooltipOpened"
            />
        </span>
    </li>

    <!-- If office locations should be shown as chips-->
    <ng-container *ngIf="!groupedOfficeLocations">
        <li *ngFor="let location of officeLocations" class="office-locations chip">
            {{ location }}
        </li>
    </ng-container>
</ul>
