import { Component, Input } from '@angular/core';

export interface ITabSwitch {
    title: string;
    route: string;
    active: boolean;
    queryParams?: Record<string, string>;
}

@Component({
    selector: 'sp-tab-switcher[tabs]',
    templateUrl: './tab-switcher.component.html',
    styleUrls: ['./tab-switcher.component.scss'],
})
export class TabSwitcherComponent {
    @Input() public tabs!: ITabSwitch[];
}
