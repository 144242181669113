import type {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

export function objectWithPropertiesValidator(...keys: string[]): ValidatorFn {
    function hasAllProperties(obj, properties: string[]): boolean {
        return !properties.find((property) => !obj[property]);
    }

    return (control: AbstractControl): ValidationErrors => {
        const value = control.value;

        if (!value) {
            // rely on 'required' validator
            return null;
        }

        if (typeof value !== 'object' || !hasAllProperties(value, keys)) {
            return {
                missingOrEmptyProperties: true,
            };
        }

        return null;
    };
}
