import type { OnDestroy } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import type { DialogRef } from '../../../shared/dialog-ref';
import { spDialogData } from '../../../shared/dialog/dialog.service';
import { ColorContext } from '../../../shared/models';
import { JobsService } from '../../../core/jobs.service';
import { NotificationService } from '../../../shared/notification/notification.service';
import { unsubscribeIfActive } from '../../../shared/utils';
import type { BaseJobInfo } from '../../../shared/models/job-info.model';
import { SourcingStatus } from '../../../shared/models/job-info.model';

const sourcingStatusToActionMapping: {
    [key in SourcingStatus.started | SourcingStatus.stopped]: string;
} = {
    [SourcingStatus.started]: 'start',
    [SourcingStatus.stopped]: 'stop',
};

@Component({
    selector: 'sp-change-sourcing-status-modal',
    templateUrl: './change-sourcing-status-modal.component.html',
    styleUrls: ['./change-sourcing-status-modal.component.scss'],
})
export class ChangeSourcingStatusModalComponent implements OnDestroy {
    private dialogRef: DialogRef<ChangeSourcingStatusModalComponent>;
    job: BaseJobInfo;
    subscription: Subscription;
    isActionInProgress = false;
    targetStatus: SourcingStatus.started | SourcingStatus.stopped;

    readonly errorMessage: string;

    get header(): string {
        return `${sourcingStatusToActionMapping[this.targetStatus]} sourcing?`;
    }

    get infoText(): string {
        return this.targetStatus === SourcingStatus.started
            ? 'SquarePeg will start sourcing passive candidates and matching new applicants. This job will also be ' +
                  'published on LinkedIn if applicable. If you change your mind, sourcing can be stopped again at any time.'
            : 'SquarePeg will stop sourcing passive candidates and matching new applicants. This job will also be ' +
                  'unpublished from LinkedIn if applicable. If you change your mind, sourcing can be started again at any time.';
    }

    get buttonLabel(): string {
        return `${
            sourcingStatusToActionMapping[this.targetStatus]
        } sourcing for this job`;
    }

    constructor(
        @Inject(spDialogData) dialogData,
        private jobsService: JobsService,
        private notificationService: NotificationService,
    ) {
        this.subscription = new Subscription();
        this.dialogRef = dialogData.dialogRef;
        this.job = dialogData.contentComponentData.job;
        this.targetStatus = dialogData.contentComponentData.targetStatus;
        this.errorMessage =
            `An error occurred while trying to ${
                sourcingStatusToActionMapping[this.targetStatus]
            } sourcing ` +
            'for this role. Please try again later or contact your account manager.';
    }

    onPauseSourcing() {
        this.isActionInProgress = true;
        this.subscription.add(
            this.jobsService
                .updateJobSourcingStatus(
                    this.job.id,
                    this.targetStatus,
                    this.job.version,
                )
                .pipe(finalize(() => (this.isActionInProgress = false)))
                .subscribe(
                    (job) => this.dialogRef.close(job),
                    () =>
                        this.notificationService.show({
                            message: this.errorMessage,
                            colorContext: ColorContext.WARNING,
                        }),
                ),
        );
    }

    onClose() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        unsubscribeIfActive(this.subscription);
    }
}
