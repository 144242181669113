<h1 class="dropdown-title">Notifications</h1>

<ul
    *ngIf="notifications?.length; else emptyState"
    #notificationsScrollableContainer
    class="notifications-list {{ scrollableContainerCssClass }}"
    cdkScrollable
>
    <ng-container *ngFor="let notification of notifications">
        <li
            *ngIf="notification.route"
            [routerLink]="notification.route"
            (click)="onNotificationClick($event, notification)"
            class="notification-item"
            [class.is-seen]="notification.isSeen"
        >
            <ng-container
                *ngTemplateOutlet="listItemContent; context: { notification: notification }"
            ></ng-container>
        </li>

        <li
            *ngIf="!notification.route"
            class="notification-item"
            [class.is-seen]="notification.isSeen"
            (click)="closePopup()"
        >
            <ng-container
                *ngTemplateOutlet="listItemContent; context: { notification: notification }"
            ></ng-container>
        </li>
    </ng-container>
</ul>

<div *ngIf="isLoadingMore" class="loading-more-spinner-wrapper">
    <img
        src="/images/spinner.svg"
        alt="Loading notifications..."
        class="sp-dots-spinner spinner-small"
    />
</div>

<!-- notification list item template -->
<ng-template let-notification="notification" #listItemContent>
    <div class="left-column">
        <span class="bullet"></span>
        <sp-avatar
            [className]="'size-32 actor-avatar'"
            [avatar]="notification.actor.picture"
            [firstName]="notification.actor.firstName"
            [lastName]="notification.actor.lastName"
        >
        </sp-avatar>
        <span class="activity-icon activity-icon-{{ notification.type }}">
            <span class="activity-icon-image"></span>
        </span>
    </div>
    <div class="content-column">
        <p class="notification-message" [innerHTML]="notification.message"></p>
        <div class="notification-date">
            {{ notification.date | date : "MMM dd yyyy 'at' h:mm aa" }}
        </div>
    </div>
</ng-template>

<ng-template #emptyState>
    <div class="empty-state-container">
        <img
            *ngIf="isLoading"
            src="/images/spinner.svg"
            alt="Loading notifications..."
            class="sp-dots-spinner spinner-medium"
        />

        <ng-container *ngIf="!isLoading">
            <div class="empty-state-logo-container">
                <span class="sp-vertical-align-helper"></span>
                <img
                    src="/images/icons/empty-notifications.svg"
                    alt="Empty notifications state image"
                />
            </div>
            <h2 class="empty-state-header">No notifications available</h2>
            <p class="empty-state-message">
                You&apos;ll be notified when certain events take place, such as being assigned to a
                job or someone accepting your invitation.
            </p>
        </ng-container>
    </div>
</ng-template>
