import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import type { GenericIdName } from '../shared/models';
import { CertificateItem } from '../shared/models/certificate-item.model';
import type { Language } from '../shared/models/language.model';
import { byIdComparator } from '../shared/utils';

enum ProfileAnswerOptionType {
    department = 'department',
    degree = 'degree',
    fieldOfStudy = 'fieldOfStudy',
    companyType = 'companyType',
    priority = 'priority',
    workExperienceType = 'workExperienceType',
    companyFundingType = 'companyFundingType',
}

@Injectable()
export class ProfileAnswerOptionService {
    private departments: GenericIdName[];
    private degrees: GenericIdName[];
    private fieldsOfStudy: GenericIdName[];
    private companyTypes: GenericIdName[];
    private priorities: GenericIdName[];
    private workTypes: GenericIdName[];
    private companyFundings: GenericIdName[];

    constructor(private http: HttpClient) {}

    getDepartments(forceFetch = false): Observable<GenericIdName[]> {
        if (this.departments && this.departments.length && !forceFetch) {
            return of(this.departments);
        }

        return this.getAttributeOptionsByType(
            ProfileAnswerOptionType.department,
        ).pipe(tap((departments) => (this.departments = departments)));
    }

    getCompanyFundings(forceFetch = false): Observable<GenericIdName[]> {
        if (
            this.companyFundings &&
            this.companyFundings.length &&
            !forceFetch
        ) {
            return of(this.companyFundings);
        }

        return this.getAttributeOptionsByType(
            ProfileAnswerOptionType.companyFundingType,
        ).pipe(
            tap(
                (companyFundings) =>
                    (this.companyFundings =
                        companyFundings.sort(byIdComparator)),
            ),
        );
    }

    getDegrees(forceFetch = false): Observable<GenericIdName[]> {
        if (this.degrees && this.degrees.length && !forceFetch) {
            return of(this.degrees);
        }

        return this.getAttributeOptionsByType(
            ProfileAnswerOptionType.degree,
        ).pipe(tap((degrees) => (this.degrees = degrees)));
    }

    searchCertificates(keyword): Observable<CertificateItem[]> {
        return this.search(keyword, 'certificates').pipe(
            map((items) => items.map((data) => new CertificateItem(data))),
        );
    }

    getFieldsOfStudy(forceFetch = false): Observable<GenericIdName[]> {
        if (this.fieldsOfStudy && this.fieldsOfStudy.length && !forceFetch) {
            return of(this.fieldsOfStudy);
        }

        return this.getAttributeOptionsByType(
            ProfileAnswerOptionType.fieldOfStudy,
        ).pipe(tap((fieldsOfStudy) => (this.fieldsOfStudy = fieldsOfStudy)));
    }

    getCompanyTypes(forceFetch = false): Observable<GenericIdName[]> {
        if (this.companyTypes && this.companyTypes.length && !forceFetch) {
            return of(this.companyTypes);
        }

        return this.getAttributeOptionsByType(
            ProfileAnswerOptionType.companyType,
        ).pipe(tap((companyTypes) => (this.companyTypes = companyTypes)));
    }

    getPriorities(forceFetch = false): Observable<GenericIdName[]> {
        if (this.priorities && this.priorities.length && !forceFetch) {
            return of(this.priorities);
        }

        return this.getAttributeOptionsByType(
            ProfileAnswerOptionType.priority,
        ).pipe(tap((priorities) => (this.priorities = priorities)));
    }

    getWorkTypes(forceFetch = false): Observable<GenericIdName[]> {
        if (this.workTypes && this.workTypes.length && !forceFetch) {
            return of(this.workTypes);
        }

        return this.getAttributeOptionsByType(
            ProfileAnswerOptionType.workExperienceType,
        ).pipe(tap((workTypes) => (this.workTypes = workTypes)));
    }

    searchLanguages(keyword: string): Observable<Language[]> {
        return this.search(keyword, 'languages');
    }

    search<T>(
        keyword: string,
        type: 'languages' | 'certificates',
    ): Observable<T[]> {
        return keyword && keyword.trim().length
            ? this.http.get<T[]>(`/api/profile-answer-options/${type}`, {
                  params: { name: keyword },
              })
            : of([]);
    }

    private getAttributeOptionsByType(
        type: ProfileAnswerOptionType,
    ): Observable<GenericIdName[]> {
        return this.http.get<GenericIdName[]>('/api/profile-answer-options', {
            params: { type },
        });
    }
}
