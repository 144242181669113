export const environment = {
    production: true,
    env: 'prod',
    config: {
        API_URL: 'https://app.squarepeghires.com',
        APP_URL: 'https://app.squarepeghires.com',
        SITE_URL: 'https://www.squarepeghires.com',
        LINKEDIN_CLIENT_ID: '78p7tfj8kb6x2g',
        GOOGLE_CLIENT_ID:
            '222313411756-o71pm5islbgg7h3paj4vff6f6sd8si1b.apps.googleusercontent.com',
        STRIPE_PUBLISHABLE_KEY: 'pk_live_CZDRDO5xv8PK1QqiN4e0qHd9',
        USERFLOW_API_KEY: 'ct_cs2wrr5ltfh4hob47mwgxcuriy',
        getStreamApi: {
            API_KEY: 'bqemduf4at8x',
            APP_ID: '79259',
        },
        googleMapsApi: {
            language: 'en_US',
            API_KEY: 'AIzaSyDqDnuJvqY32NjI7qOneGMcYczvxIyqHsM',
        },
        pk:
            '-----BEGIN PUBLIC KEY-----\n' +
            'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjAckH/d5lZLFxGEdskCo\n' +
            'pQdFGqNz/JVNlsGwJJ0UxgvnsQRzSa/8dbrCLXL5/RR9w9Ko5lO6743Ti71gP4DK\n' +
            'GZ2LAHY4dJOcX2kFDuGFLpUswO+GwjRjLpQGmg3CALaEq+y79AE1aYQ+APdg8alQ\n' +
            'eAYpi/HXJZIz/HAXULazmW+R3CNlkWIGjNTabPIerwBreuf913641se7K/gEuyhR\n' +
            '5GnBx7PC0rN3iqRFa9d25YecHVQIPGyfodo3kOL6+nmHUXbJIVH8Jhcce43jlyYJ\n' +
            '4szm36dgbuJL2Gp2PouVLQZZmIEmC3zrHfRU0yGBGK8Ya3HvsQmwz9Xa21ScEjTv\n' +
            'swIDAQAB\n' +
            '-----END PUBLIC KEY-----',
    },
    emails: {
        INFO: 'info@squarepeghires.com',
        OPERATIONS: 'operations@squarepeghires.com',
        SALES: 'sales@squarepeghires.com',
    },
    analytics: {
        segment: {
            writeKey: 'b7d9Xb3i2BUxiawkqmbjD3JtrSouUCNb',
        },
    },
    employerConfig: {
        customCandidateContactMessage: {
            '146': getCustomCandidateMessageForAB,
        },
    },
    integrations: {
        lever: {
            clientId: 'YQupPvR50vc5Io4ndK6yiZ78gCCbs66x',
            authorizationEndpoint: 'https://auth.lever.co/authorize',
            state: '31dae0d9', // should be in sync with a value in sp-backend-api's properties
            audience: 'https://api.lever.co/v1/',
            scope: [
                'offline_access',
                'applications:read:admin',
                'applications:read:admin',
                'archive_reasons:read:admin',
                'notes:write:admin',
                'offers:read:admin',
                'opportunities:write:admin',
                'postings:read:admin',
                'resumes:read:admin',
                'stages:read:admin',
                'users:read:admin',
                'webhooks:write:admin',
            ],
            prompt: 'consent',
        },
        slack: {
            clientId: '43238091591.4095447301845',
            authorizationEndpoint: 'https://slack.com/oauth/v2/authorize',
            state: 'qbjqi8w3', // this should be in sync with corresponding value on sp-backend-api's props
            scope: ['incoming-webhook'],
        },
    },
};

export function getCustomCandidateMessageForAB(
    candidateName: string,
    userFirstName: string,
) {
    return [
        `Hello ${candidateName},\n`,
        `Thank you for your interest in Bernstein's Private Wealth Associate role. Our initial interview process is comprised of a phone interview and a behavioral assessment. I am reaching out to schedule the interview - please let me know what days/times work well for you in the next two weeks. We need about 30 minutes of your time.\n`,
        `You should expect to receive a separate email with a link to complete the 5 minute PI Behavioral Assessment. Please complete the assessment by the close of business on the day following receipt. Let me know if you have any questions.\n`,
        `Thank you, ${userFirstName}`,
    ];
}
