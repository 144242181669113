import { Component, Inject } from '@angular/core';
import type { PopupRef } from '../../../../popup/model';
import {
    PopupContentComponentData,
    spPopupData,
} from '../../../../popup/model';

@Component({
    selector: 'sp-insert-link-template',
    templateUrl: './insert-link-template.component.html',
    styleUrls: ['./insert-link-template.component.scss'],
})
export class InsertLinkTemplateComponent {
    popupRef: PopupRef;
    value: string;

    constructor(@Inject(spPopupData) popupData: PopupContentComponentData) {
        this.popupRef = popupData.popupRef;
    }
}
