import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type {
    DecisionType,
    IdentifiableVersionedModel,
    Match,
} from '../shared/models';
import { MatchEmployer } from '../shared/models';
import type { EmployerConnectedMatchStatus } from '../shared/models/enumeration/employer-connected-match-status.model';
import type { EventLocation } from '../shared/models/analytics/segment/segment-event.model';

@Injectable()
export class EmployerMatchesApiService {
    constructor(private http: HttpClient) {}

    getMatch(matchId: number): Observable<MatchEmployer> {
        return this.http
            .get<MatchEmployer>(`/api/matches/${matchId}`)
            .pipe(map((match) => MatchEmployer.of(match)));
    }

    // when update match tags, we return distinct job tags
    updateMatchTags(
        matchId: number,
        matchTags: string[] = [],
    ): Observable<string[]> {
        return this.http.patch<string[]>(
            `/api/matches/${matchId}/match-tags`,
            matchTags,
        );
    }

    favoriteMatch(
        match: IdentifiableVersionedModel,
    ): Observable<MatchEmployer> {
        return this.http.put<MatchEmployer>(
            `/api/matches/${match.id}/favorite`,
            match.version,
        );
    }

    updateMatchConnectedStatus(
        jobId: number,
        match: MatchEmployer,
        prevStatus: EmployerConnectedMatchStatus,
        nextStatus: EmployerConnectedMatchStatus,
        eventLocation: EventLocation,
    ): Observable<IdentifiableVersionedModel[]> {
        const requestBody = {
            targetStatus: nextStatus,
            eventLocation,
            matches: [
                {
                    id: match.id,
                    order: match.order,
                    status: nextStatus,
                    version: match.version,
                },
            ],
            missingPrevMatchId: true,
        };

        return this.updateStatusOfInProgressMatches(jobId, requestBody);
    }

    updateStatusOfInProgressMatches(
        jobId: number,
        requestBody: any,
    ): Observable<Match[]> {
        return this.http.patch<Match[]>(
            `/api/jobs/${jobId}/matches/employer-connected-match-status`,
            requestBody,
        );
    }

    saveConnectedRelinkedMatches(
        jobId: number,
        request: any,
    ): Observable<IdentifiableVersionedModel[]> {
        return this.http.patch<IdentifiableVersionedModel[]>(
            `/api/jobs/${jobId}/matches/relink-connected`,
            request,
        );
    }

    executeMatchAction(
        matchesIds: number[],
        action: DecisionType,
    ): Observable<MatchEmployer[]> {
        return this.http.patch<any>(`/api/matches/${action}`, matchesIds);
    }
}
