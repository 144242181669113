import type { Skill } from '../../../../shared/models';

export class CandidateSkills {
    topSkills: Skill[];
    additionalSkills?: Skill[];

    constructor(
        topSkills: Skill[],
        additionalSkills: Skill[],
        removeOverlappingSkills = false,
    ) {
        this.topSkills = topSkills;

        if (removeOverlappingSkills) {
            // make sure there are not duplicates between top / additional skills
            const topSkillsIds = this.topSkills.map((skill) => skill.id);
            this.additionalSkills = additionalSkills.filter(
                (skill) => !topSkillsIds.includes(skill.id),
            );
        } else {
            this.additionalSkills = additionalSkills;
        }
    }

    isValid() {
        return (
            this.topSkills &&
            this.topSkills.length > 0 &&
            this.topSkills.length <= 5 &&
            this.additionalSkills.length <= 15
        );
    }

    clone() {
        return new CandidateSkills(
            this.topSkills.map((skill) => ({ ...skill })),
            this.additionalSkills.map((skill) => ({ ...skill })),
        );
    }
}
