import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { SpInputWithImageSelector } from '../sp-form-controls.model';

@Component({
    selector: 'sp-input-with-image-selector',
    templateUrl: './input-with-image-selector.component.html',
    styleUrls: ['./input-with-image-selector.component.scss'],
})
export class InputWithImageSelectorComponent implements OnInit {
    @Input() control: SpInputWithImageSelector;
    @Input() iconClass: any;

    invalidInputClass: boolean;
    selectedCode: string;
    value;

    ngOnInit() {
        this.value = this.control.value.value;
        this.calculateStyle();
        this.selectedCode = this.control.defaultItemCode;
    }

    initValidation() {
        this.control.formControl.setValue(
            this.value ? { value: this.value, code: this.selectedCode } : null,
        );
        this.control.formControl.markAsTouched();
        this.calculateStyle();
    }

    onChangeEvent(newSelectedItem: any) {
        this.selectedCode = newSelectedItem.code;
        this.initValidation();
    }

    private calculateStyle() {
        this.invalidInputClass =
            this.control.formControl.touched &&
            this.control.formControl.invalid;
    }
}
