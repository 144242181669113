import type { GenericIdName } from '../../../shared/models/generic-id-name.interface';
import { toDate } from '../../../shared/date-utils';

export class CandidateSchool {
    startDate: Date;
    endDate?: Date;

    school: {
        name: string;
        domain: string;
    };
    degree?: GenericIdName;
    fieldOfStudy?: GenericIdName;
    isCurrent?: boolean;

    static of(school: any) {
        if (!school) {
            return null;
        }

        return Object.assign(new CandidateSchool(), school, {
            startDate: toDate(school.startDate),
            endDate: toDate(school.endDate),
        });
    }
}
