import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { CanActivate } from '@angular/router';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../user.service';
import { UserType } from '../../shared/models/user.model';
import { traitsAssessmentRoute } from '../navbar.utils';

@Injectable()
export class CandidateAssessmentsCompleteGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private router: Router,
    ) {}

    canActivate(): Observable<boolean> {
        return this.userService.getUserObservable().pipe(
            map((user) => {
                const canActivate =
                    user.type === UserType.candidate &&
                    !!user.completedAllAssessments;

                if (!canActivate) {
                    this.router.navigate([traitsAssessmentRoute]);

                    return false;
                }

                return true;
            }),
        );
    }
}
