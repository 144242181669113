import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import type {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { isDefined, unsubscribeIfActive } from '../../../utils';
import { CompositeChipsContainer, SpChipsInputControl } from '../chips/model';
import type { ControlFocusEventData } from '../../../models/control-focus-event-data';
import { SpCompositeChipsInputControl } from './model';
import { CompositeChipsService } from './composite-chips.service';

@Component({
    selector: 'sp-composite-chips',
    templateUrl: './composite-chips.component.html',
    styleUrls: ['./composite-chips.component.scss'],
    providers: [CompositeChipsService],
})
export class CompositeChipsComponent implements OnInit, OnDestroy {
    @Input() control: SpCompositeChipsInputControl;

    @Output() mouseEnter = new EventEmitter<ControlFocusEventData>();

    chipsControlTop: SpChipsInputControl;
    chipsControlBottom: SpChipsInputControl;

    topControlForm: FormGroup;
    bottomControlForm: FormGroup;

    private topValue: { [key: string]: any[] };
    private bottomValue: { [key: string]: any[] };

    private subscription: Subscription = new Subscription();

    static isEmptyInputValue(value: any): boolean {
        // we don't check for string here so it also works with arrays
        return !isDefined(value) || value.length === 0;
    }

    private static inFieldMaxLength(
        maxLength: number,
        fieldName: string,
    ): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const length: number = control.value[fieldName]
                ? control.value[fieldName].length
                : 0;

            return length > maxLength
                ? {
                      maxlength: {
                          requiredLength: maxLength,
                          actualLength: length,
                      },
                  }
                : null;
        };
    }

    private static requiredInField(fieldName: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null =>
            CompositeChipsComponent.isEmptyInputValue(control.value[fieldName])
                ? { required: true }
                : null;
    }

    ngOnInit() {
        this.topValue = {
            [this.control.name]: this.control.value
                ? this.control.value[this.control.name]
                : [],
        };
        this.bottomValue = {
            [this.control.secondaryName]: this.control.value
                ? this.control.value[this.control.secondaryName]
                : [],
        };

        this.topControlForm = new FormGroup({});
        this.bottomControlForm = new FormGroup({});

        const commonSettings = {
            chipsDisplay: this.control.chipsDisplay,
            isMultiSelect: true,
            autocomplete: { ...this.control.autocomplete },
            showCounter: this.control.showCounter,
            allowExcessItems: this.control.allowExcessItems,
            allowDuplicates: this.control.allowDuplicates,
            dragZone: this.control.dragZone,
            icon: this.control.icon,
        };

        const topControlErrorMessages = this.control.errorMessages.maxlength
            ? {
                  maxlength: `${this.control.errorMessages.maxlength} ${this.control.chipsMaxItems}`,
              }
            : {};
        this.chipsControlTop = new SpChipsInputControl({
            ...commonSettings,
            form: this.topControlForm,
            name: this.control.name,
            label: this.control.label,
            hint: this.control.hint,
            value: this.topValue[this.control.name],
            compositeModePosition: CompositeChipsContainer.top,
            placeholder: this.control.placeholder,
            chipsMaxItems: this.control.chipsMaxItems,
            isOptional: this.control.isOptional,
            validators: this.control.validators
                ? [
                      ...this.control.validators,
                      Validators.maxLength(this.control.chipsMaxItems),
                  ]
                : [Validators.maxLength(this.control.chipsMaxItems)],
            errorMessages: topControlErrorMessages,
        });

        const additionalValidators = [];
        if (!this.control.isOptional) {
            additionalValidators.push(
                CompositeChipsComponent.requiredInField(this.control.name),
            );
        }

        if (this.control.chipsMaxItems) {
            additionalValidators.push(
                CompositeChipsComponent.inFieldMaxLength(
                    this.control.chipsMaxItems,
                    this.control.name,
                ),
            );
        }

        const bottomControlErrorMessages = this.control.errorMessages.maxlength
            ? {
                  maxlength: `${this.control.errorMessages.maxlength} ${this.control.secondaryChipsMaxItems}`,
              }
            : {};
        this.chipsControlBottom = new SpChipsInputControl({
            ...commonSettings,
            form: this.bottomControlForm,
            name: this.control.secondaryName,
            label: this.control.secondaryLabel,
            hint: this.control.secondaryHint,
            theme: this.control.secondaryControlTheme,
            value: this.bottomValue[this.control.secondaryName],
            compositeModePosition: CompositeChipsContainer.bottom,
            placeholder: this.control.secondaryPlaceholder,
            chipsMaxItems: this.control.secondaryChipsMaxItems,
            isOptional: this.control.secondaryIsOptional,
            validators: this.control.validators
                ? [
                      ...this.control.validators,
                      Validators.maxLength(this.control.secondaryChipsMaxItems),
                  ]
                : [Validators.maxLength(this.control.secondaryChipsMaxItems)],
            errorMessages: bottomControlErrorMessages,
        });

        if (!this.control.secondaryIsOptional) {
            additionalValidators.push(
                CompositeChipsComponent.requiredInField(
                    this.control.secondaryName,
                ),
            );
        }
        if (this.control.secondaryChipsMaxItems) {
            additionalValidators.push(
                CompositeChipsComponent.inFieldMaxLength(
                    this.control.secondaryChipsMaxItems,
                    this.control.secondaryName,
                ),
            );
        }
        this.control.formControl.setValidators([
            ...this.control.validators,
            ...additionalValidators,
        ]);

        this.subscription.add(
            this.topControlForm.valueChanges.subscribe((value) => {
                this.topValue = value;
                this.setFormValue();
            }),
        );

        this.subscription.add(
            this.bottomControlForm.valueChanges.subscribe((value) => {
                this.bottomValue = value;
                this.setFormValue();
            }),
        );

        this.watchSourceUpdates();

        this.control.validationCallback = () => this.triggerValidation();
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    setFormValue() {
        const value = { ...this.topValue, ...this.bottomValue };

        this.control.formControl.setValue(value);
        this.control.valueChange.emit(value);
    }

    private watchSourceUpdates() {
        this.subscription.add(
            this.control.sourceUpdated$.subscribe((source) => {
                this.chipsControlTop.source = source;
                this.chipsControlBottom.source = source;
            }),
        );
    }

    private triggerValidation(): boolean {
        this.markSubControlAsDirty(this.topControlForm);
        this.markSubControlAsDirty(this.bottomControlForm);

        return this.topControlForm.valid && this.bottomControlForm.valid;
    }

    private markSubControlAsDirty(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((formControlKey) =>
            formGroup.controls[formControlKey].markAsDirty(),
        );
    }

    onMouseEnter(event: MouseEvent, controlName: string) {
        this.mouseEnter.emit({
            controlId: controlName,
            control: event.target as HTMLElement,
        });
    }
}
