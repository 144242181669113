<ng2-dropdown
    class="sp-dropdown"
    (onItemClicked)="itemClicked($event)"
    (onShow)="onShow()"
    [class.isVisible]="isVisible"
>
    <ng2-dropdown-button class="label" [class.disabled-button]="disabled" [showCaret]="false" >
        <ng-content></ng-content>
        <svg
            *ngIf="showCaret"
            class="caret"
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <g stroke="none" stroke-width="1" fill-rule="evenodd">
                <g fill-rule="nonzero">
                    <path
                        d="M6,4.17406308 L6,5.50270631 L8.04,7.64905642 C8.22945455,7.8483529 8.22945455,8.15164529 8.04,8.35094177 L6,10.4972919 L6,11.8259351 C6,11.9845375 6.20436364,12.0603606 6.31672727,11.9428001 L9.95309091,8.11686405 C10.0156364,8.05112751 10.0156364,7.94921849 9.95309091,7.88313414 L6.31672727,4.05719813 C6.20436364,3.93928973 6,4.01615627 6,4.17406308 Z"
                        transform="translate(8.000000, 8.000000) rotate(90.000000) translate(-8.000000, -8.000000) "
                    ></path>
                </g>
            </g>
        </svg>
    </ng2-dropdown-button>
    <ng2-dropdown-menu
        [@.disabled]="true"
        [focusFirstElement]="false"
        [appendToBody]="appendToBody"
        [class.dropdown-oposite-arrow]="displayAbove"
        [width]="widthClass"
        [ngClass]="dropdownMenuClass"
    >
        <ng-container *ngFor="let subMenu of menuData.children">
            <ng-container [ngSwitch]="subMenu.type">
                <ng-container *ngSwitchDefault>
                    <!--
            note: having two ng2-menu-item rendered conditionally below is a workaround
            Basically, if routerLink is not defined for this ng2-menu-item but we do
            instead call router.navigate() while handling this ng2-menu-item click we are gonna have
            issues with cancelled http request in case we router.navigate to a route with a guard that
            returns an http Observable
          -->
                    <ng2-menu-item
                        *ngIf="(subMenu.routerLink || subMenu.navigationUrl) && !subMenu.data"
                        class="{{ subMenu.class }}"
                        routerLinkActive="menu-item-active"
                        [routerLink]="[subMenu.routerLink]"
                        [class.fix-route-active]="!subMenu.routerLink"
                        [value]="subMenu.data"
                        [class.disabled]="!!currentlyActionedItem"
                        [preventClose]="subMenu.preventClose || !!currentlyActionedItem"
                        (click)="navigate($event, subMenu.navigationUrl, subMenu.navigationExtras)"
                    >
                        <span
                            *ngIf="currentlyActionedItem !== subMenu"
                            class="dd-icon dd-icon-{{ subMenu.icon }} {{ subMenu.class }}"
                        >
                        </span>
                        <img
                            *ngIf="currentlyActionedItem === subMenu"
                            src="/images/spinner.svg"
                            class="tab-spinner sp-dots-spinner"
                        />
                        {{ subMenu.text }}
                        <span
                            *ngIf="subMenu.secondaryIcon"
                            class="dd-icon dd-icon-{{ subMenu.secondaryIcon }} secondary"
                        >
                        </span>
                    </ng2-menu-item>

                    <ng2-menu-item
                        *ngIf="!subMenu.routerLink && !subMenu.navigationUrl"
                        class="{{ subMenu.class }}"
                        [class.fix-route-active]="!subMenu.routerLink"
                        [value]="subMenu.data"
                        [class.disabled]="!!currentlyActionedItem || subMenu.tooltipDisabledState"
                        [preventClose]="subMenu.preventClose || !!currentlyActionedItem"
                        spTooltip
                        [tooltipText]="subMenu.tooltipDisabledState"
                        (click)="onDataActionSelected($event)"
                    >
                        <span
                            *ngIf="currentlyActionedItem !== subMenu"
                            class="dd-icon dd-icon-{{ subMenu.icon }} {{ subMenu.class }}"
                        >
                        </span>
                        <img
                            *ngIf="currentlyActionedItem === subMenu"
                            src="/images/spinner.svg"
                            class="tab-spinner sp-dots-spinner"
                        />
                        <span class="prefix"></span>
                        {{ subMenu.text }}
                        <span *ngIf="subMenu.data === lastSelectedOption" class="checkmark"></span>
                        <span
                            *ngIf="subMenu.secondaryIcon"
                            class="dd-icon dd-icon-{{ subMenu.secondaryIcon }} secondary"
                        >
                        </span>
                    </ng2-menu-item>
                </ng-container>

                <div *ngSwitchCase="SpDropDownDataType.separator" class="ng2-menu-divider"></div>

                <div *ngSwitchCase="SpDropDownDataType.header" class="header">
                    <sp-avatar
                        *ngIf="subMenu.icon || subMenu.userName"
                        class="image-container"
                        [avatar]="subMenu.icon"
                        [userName]="subMenu.userName"
                        [className]="'size-32 middle'"
                    ></sp-avatar>
                    <span>{{ subMenu.text }}</span>
                </div>
            </ng-container>
        </ng-container>
    </ng2-dropdown-menu>
</ng2-dropdown>
