export class TraitsResult {
    label: string;
    score: number;
    averageScore: number;

    description: string;
    points: string[];

    percentile?: string;
}
