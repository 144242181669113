import type { Team } from '../team.model';
import type { JobInfo } from '../job-info.model';

export interface UserInvitation {
    id?: number;
    email: string;
    firstName: string;
    lastName: string;
    alreadyUsed: boolean;
    status: UserInvitationStatus;
    role: string;
    teams?: Team[];
    jobs?: JobInfo[];
}

export enum UserInvitationStatus {
    invitedByEmployer = 'invitedByEmployer',
    requestToJoin = 'requestToJoin',
    requestAccess = 'requestAccess',
    requestRejected = 'requestRejected',
    requestAccepted = 'requestAccepted',
    completed = 'completed',
}
