import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import type { SpFormControlsGroup } from '../../../shared/forms/custom-controls/sp-form-controls-group.interface';
import { UserService } from '../../../core/user.service';
import { environment } from '../../../../environments/environment';
import { IAccessRequest } from '../../model/request-access-form.model';
import { RequestAccessFormService } from './request-access-form.service';

enum ResponseStatus {
    success,
    error,
}

@Component({
    selector: 'sp-request-access-form',
    templateUrl: './request-access-form.component.html',
    styleUrls: ['./request-access-form.component.scss'],
    providers: [RequestAccessFormService],
})
export class RequestAccessFormComponent implements OnInit {
    spInfoEmailAddress = environment.emails.INFO;

    form: FormGroup;
    controls: SpFormControlsGroup;
    isSubmitting: boolean;
    responseStatus: ResponseStatus;

    @Input() requestData: IAccessRequest;

    readonly ResponseStatus = ResponseStatus;

    constructor(
        private requestAccessFormService: RequestAccessFormService,
        private userService: UserService,
    ) {}

    get isDisabled() {
        return (
            this.form.invalid ||
            this.isSubmitting ||
            this.responseStatus === ResponseStatus.success
        );
    }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.controls = this.requestAccessFormService.initFormControls(
            this.requestData.email,
            this.requestData.employerName,
            this.requestData.firstName,
            this.requestData.lastName,
        );
        this.form = this.requestAccessFormService.form;
    }

    onSubmit() {
        this.isSubmitting = true;
        this.responseStatus = null;
        this.userService
            .requestAccess({
                employerId: this.requestData.employerId,
                employerName: this.requestData.employerName,
                firstName: this.form.value.firstName,
                lastName: this.form.value.lastName,
                email: this.form.value.email,
            })
            .pipe(finalize(() => (this.isSubmitting = false)))
            .subscribe(
                () => (this.responseStatus = ResponseStatus.success),
                () => (this.responseStatus = ResponseStatus.error),
            );
    }
}
