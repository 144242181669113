import type { AfterViewInit, OnDestroy } from '@angular/core';
import {
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    Renderer2,
} from '@angular/core';

/**
 * @deprecated
 */
@Directive({
    selector: '[spAutosize]',
})
export class AutosizeDirective implements AfterViewInit, OnDestroy {
    private minHeight: number;
    private textAreaEl: HTMLElement;
    private quillEditorElement: HTMLElement;

    private unlistenFunctions: Function[] = [];

    @Input('spAutosize') spAutosizeEnabled: boolean;

    @HostBinding('class.autoresizable') true;

    @HostListener('input', ['$event'])
    onChange() {
        if (this.spAutosizeEnabled) {
            this.adjustHeight();
        }
    }

    constructor(
        private element: ElementRef<HTMLElement>,
        private renderer: Renderer2,
    ) {}

    ngAfterViewInit(): void {
        if (this.spAutosizeEnabled) {
            if (this.element.nativeElement.tagName === 'TEXTAREA') {
                this.textAreaEl = this.element.nativeElement;
                this.prepareForAdjustHeight();
            } else if (this.element.nativeElement.tagName === 'sp-form-input') {
                this.textAreaEl =
                    this.element.nativeElement.querySelector('textarea');
                this.prepareForAdjustHeight();
            } else if (this.element.nativeElement.tagName === 'QUILL-EDITOR') {
                // Note: after migration to angular-9 previous synchronous implementation stopped working in
                // a way that this.element.nativeElement.querySelector started to return null. The quick and dirty
                // workaround was to do querying asynchronously in setTimeout
                // However, this is not a long term solution. The proper way to fix it is to update implementation
                // of a SINGLE consumer of this directive to not rely on the directive which will unlock us for
                // directive removal
                setTimeout(() => {
                    this.textAreaEl =
                        this.element.nativeElement.querySelector(
                            '.ql-container',
                        );
                    this.quillEditorElement =
                        this.element.nativeElement.querySelector('.ql-editor');
                    this.quillEditorElement.style.overflow = 'hidden';

                    const unlistenFuncs = [
                        this.renderer.listen(
                            this.quillEditorElement,
                            'blur',
                            () => this.adjustHeight(),
                        ),
                        this.renderer.listen(
                            this.quillEditorElement,
                            'keydown',
                            () => this.adjustHeight(),
                        ),
                        this.renderer.listen(
                            this.quillEditorElement,
                            'paste',
                            () => {
                                // wait angular to render value inside element with contenteditable=true
                                setTimeout(() => this.adjustHeight(), 10);
                            },
                        ),
                    ];
                    this.unlistenFunctions.push(...unlistenFuncs);
                    this.prepareForAdjustHeight();
                }, 10);
            }
        }
    }

    ngOnDestroy(): void {
        this.unlistenFunctions.forEach((fn) => {
            if (fn && fn instanceof Function) {
                fn();
            }
        });
    }

    adjustHeight(): void {
        this.textAreaEl.style.overflow = 'hidden';
        this.textAreaEl.style.height = 'auto';
        this.textAreaEl.style.height = `${
            this.textAreaEl.scrollHeight <= this.minHeight
                ? this.minHeight
                : this.textAreaEl.scrollHeight
        }px`;
    }

    private prepareForAdjustHeight() {
        // this code is originally owned by 'dkirillov' and has been extracted into
        // a func to be able to reuse it with setTimeout function
        this.minHeight = this.textAreaEl.offsetHeight;
        this.textAreaEl.style.boxSizing = 'border-box';
        setTimeout(() => this.adjustHeight(), 10);
    }
}
