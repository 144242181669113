<header
    class="header"
    [ngClass]="{
        'sm-menu-visible': smMenuExpanded,
        employer: navigationPath.isEmployer,
        'stepper-header': navigationPath.showStepper,
        'stepper-with-bottom-progress-bar': navigationPath.showStepperWithBottomProgressBar,
        'progress-bar-header': navigationPath.showProgressBar
    }"
>
    <div class="main-header">
        <div class="logo-container"
             [class.non-hyperlink]="navigationPath.preventLogoNavigation"
             *ngIf="!navbarService.smLeftHeaderLink">
            <img (click)="onHeaderLogoClick()" class="logo" src="/images/logo.svg" />
            <div class="separator logo-separator left" *ngIf="!withProgressBar"></div>
        </div>

        <!-- Regular Header -->
        <ng-container
            *ngIf="
                !navigationPath.showStepper &&
                !navigationPath.showProgressBar &&
                !navigationPath.showStepperWithBottomProgressBar
            "
        >
            <!--when we have left header link and do have label, show label-->
            <a
                *ngIf="navbarService.smLeftHeaderLink"
                class="sm-left-header-button main"
                href="javascript:"
                [routerLink]="[navbarService.smLeftHeaderLink.routerLink]"
            >
                {{ navbarService.smLeftHeaderLink.label }}
            </a>

            <!--when we have left header link but do not have label, show back arrow-->
            <ng-container
                *ngIf="navbarService.smLeftHeaderLink && !navbarService.smLeftHeaderLink.label"
            >
                <a
                    [routerLink]="[navbarService.smLeftHeaderLink.routerLink]"
                    class="sm-header-back-button-icon"
                ></a>
                <div class="separator logo-separator left"></div>
            </ng-container>

            <!-- sm screens, header title -->
            <div *ngIf="navbarService.navbarTitle || activeItem" class="header-center">
                {{ navbarService.navbarTitle ? navbarService.navbarTitle : activeItem?.label }}
            </div>

            <a (click)="onSmMenuToggleClick()" href="javascript:" class="sm-expand-action">
                <div class="expand-icon">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                <div class="collapse-icon">
                    <a class="sm-collapse-menu-icon"></a>
                </div>
            </a>
            <div class="menu-sm-overlay" (click)="onSmMenuToggleClick()"></div>

            <!-- primary md navigation items (left side) -->
            <ul class="nav nav-main">
                <sp-nav-item
                    *ngFor="let link of mainMenu"
                    class="sp-nav-item"
                    [ngClass]="[link?.class || '']"
                    [menuItem]="link"
                    [activeRoute]="activeRoutePath"
                    (selectMenuItem)="onItemSelected($event)"
                >
                </sp-nav-item>
            </ul>

            <!-- secondary md navigation items (right side) -->
            <ul class="nav nav-right">
                <sp-nav-item
                    *ngFor="let link of navigationPath.secondaryMenu"
                    class="sp-nav-item"
                    [ngClass]="[link?.class || '']"
                    [menuItem]="link"
                    (selectMenuItem)="onItemSelected($event)"
                >
                </sp-nav-item>
            </ul>
            <div class="clearfix"></div>
        </ng-container>

        <!-- Stepper Header -->
        <ng-container *ngIf="navigationPath.showStepper">
            <ng-container *ngTemplateOutlet="leftHeaderButton"></ng-container>

            <sp-stepper-header class="stepper"></sp-stepper-header>

            <ng-container *ngTemplateOutlet="headerRight"></ng-container>
        </ng-container>

        <!-- Progress Bar Header -->
        <ng-container *ngIf="navigationPath.showProgressBar">
            <div class="progress-bar-header-center">
                <div class="progress-bar-outer" *ngIf="navigationPath.progressBarPercentage">
                    <div
                        class="progress-bar-inner"
                        [style.width.%]="100 - navigationPath.progressBarPercentage"
                    ></div>
                </div>
            </div>

            <ng-container *ngTemplateOutlet="headerRight"></ng-container>
        </ng-container>

        <!-- Stepper with bottom progress bar header -->
        <ng-container *ngIf="navigationPath.showStepperWithBottomProgressBar">
            <ng-container *ngTemplateOutlet="leftHeaderButton"></ng-container>

            <sp-stepper-header class="stepper" [direction]="'row'"> </sp-stepper-header>

            <ng-container *ngTemplateOutlet="headerRight"></ng-container>
        </ng-container>
    </div>
    <div
        class="progress-bar-outer"
        *ngIf="
            navigationPath.showStepperWithBottomProgressBar && navigationPath.progressBarPercentage
        "
    >
        <div
            class="progress-bar-inner"
            [style.width.%]="100 - navigationPath.progressBarPercentage"
        ></div>
    </div>
</header>

<ul class="nav nav-main-sm-children" [ngClass]="{ 'sm-visible-submenu': showMobileSubMenu }">
    <ng-container *ngFor="let link of mobileSubMenu">
        <sp-nav-item
            *spHasAnyAuthority="link.permissions"
            class="sp-nav-item dark-items"
            [ngClass]="[link?.class || '']"
            [menuItem]="link"
            (selectMenuItem)="onItemSelected($event)"
        >
        </sp-nav-item>
    </ng-container>
</ul>

<ul class="nav nav-main-sm" [ngClass]="{ 'sm-visible': smMenuExpanded }">
    <sp-nav-item
        *ngFor="let link of mainMenu"
        class="sp-nav-item"
        [ngClass]="[link?.class || '']"
        [menuItem]="link"
        [activeRoute]="activeRoutePath"
        (selectMenuItem)="onItemSelected($event)"
    ></sp-nav-item>

    <div class="wrapper-smlinks">
        <sp-nav-item
            *ngFor="let link of mainMenuSm"
            class="sp-nav-item"
            [ngClass]="[link?.class || '']"
            [class.only-sm]="link.smScreenOnly"
            [menuItem]="link"
            (selectMenuItem)="onItemSelected($event)"
        ></sp-nav-item>
    </div>
</ul>

<div
    *ngIf="isImpersonating"
    class="impersonated-user-info"
    [class.stepper]="navigationPath.showStepper"
>
    <span>Impersonating</span>
    <span>{{ impersonatedUserInfo }}</span>
</div>

<ng-template #leftHeaderButton>
    <a
        *ngIf="navbarService.smLeftHeaderLink"
        class="sm-left-header-button"
        href="javascript:"
        [routerLink]="[navbarService.smLeftHeaderLink.routerLink]"
    >
        {{ navbarService.smLeftHeaderLink.label }}
    </a>
</ng-template>

<ng-template #headerRight>
    <div class="header-right">
      <ng-container *ngIf="!navigationPath.preventClose">
        <a href="javascript:" (click)="onRightButtonClick()" class="header-right-button">
          <span class="sp-vertical-align-helper"></span>

          <span class="header-right-text">Close</span>
          <img class="header-button-icon icon-right" src="/images/icons/close.svg" />
        </a>
      </ng-container>
    </div>
</ng-template>
