import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, Subject, Subscription, forkJoin, of } from 'rxjs';
import {
    concatMap,
    debounceTime,
    filter,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
    CandidateUserProfileUpdateType,
    UserService,
} from '../../../core/user.service';
import { LocalStorageService } from '../../../core/local-storage.service';
import {
    byOptionalEndDateDescComparator,
    deleteObjectProperties,
} from '../../../shared/utils';
import { AUTOSAVE_PROFILE_DEBOUNCE_TIME_MS } from '../../../core/config.service';
import type {
    GenericFileInfo,
    IPill,
    Industry,
    ProfileCompany,
    User,
} from '../../../shared/models';
import { ClearbitApiService } from '../../../core/clearbit-api.service';
import { AutosaveProfileService } from '../service/autosave-profile.service';
import {
    AccomplishmentSectionType,
    accomplishmentSectionParams,
} from '../../../shared/accomplishments.constants';
import type { SectionListItem } from '../../../shared/icon-section-list-items/icon-section-list-items.component';
import {
    IndustryService,
    maxNumberOfIndustriesAllowed,
} from '../../../core/industry.service';
import type { CandidateListItem } from '../../../shared/models/user/candidate/candidate-list-item.model';
import {
    calculateDateRange,
    calculateTotalExperienceInfo,
} from '../../../shared/date-utils';
import {
    CandidateProfileStepType,
    candidateProfileSteps,
} from './steps/candidate-profile-steps';
import { StepContentListItemType } from './steps/model/step-content-list-item-type.model';
import type { IStepContentListItem } from './steps/model/step-content-list-item.interface';
import { CandidateProfile } from './model/candidate-profile.model';
import type { ICandidateProfile } from './model/candidate-profile.model';
import type {
    CandidateDemographics,
    CandidatePreferences,
    CandidateSkills,
    IWorkExperience,
    Language,
    PersonalInfo,
} from './model';
import { preferNotToRespondKey } from './model/candidate-demographics.model';

@Injectable()
export class CandidateProfileService {
    private readonly baseUrl = `/account/profile/${
        this.user?.isProfileComplete ? 'edit' : 'create'
    }/`;

    private _candidateProfile: CandidateProfile;
    private autosaveQueue = new Subject<Observable<void>>();

    localProfileUpdate$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    private autosaveProfileSubscription = new Subscription();

    validateStepRequest$: Subject<string>;
    private _currentStep: CandidateProfileStepType;

    private _isEditMode = false;

    readonly stepValidators: Record<
        string,
        ((profile: CandidateProfile) => boolean)[]
    > = {
        'work-experience': [isProfileStepComplete],
        skills: [isProfileStepComplete, isWorkExperienceStepComplete],
        preferences: [
            isProfileStepComplete,
            isWorkExperienceStepComplete,
            isSkillsStepComplete,
        ],
        demographics: [
            isProfileStepComplete,
            isWorkExperienceStepComplete,
            isSkillsStepComplete,
            isPreferencesStepComplete,
        ],
        review: [
            isProfileStepComplete,
            isWorkExperienceStepComplete,
            isSkillsStepComplete,
            isPreferencesStepComplete,
        ],
    };

    set currentStep(stepType: CandidateProfileStepType) {
        this._currentStep = stepType;
    }

    get currentStepType(): CandidateProfileStepType {
        return this._currentStep;
    }

    get candidateProfile() {
        return this._candidateProfile;
    }

    get isEditMode(): boolean {
        return this._isEditMode;
    }

    get user(): User {
        return this.userService.user;
    }

    constructor(
        private userService: UserService,
        private storageService: LocalStorageService,
        private clearbitService: ClearbitApiService,
        private autosaveProfileService: AutosaveProfileService,
        private industryService: IndustryService,
        private http: HttpClient,
    ) {
        this.validateStepRequest$ = new Subject<string>();

        this.autosaveQueue.pipe(
            filter((request) => !!request),
            concatMap((request) => request),
            tap(() => this.autosaveQueue.next()),
        );
    }

    setProfile(
        candidateProfile: CandidateProfile,
        notifyProfileUpdate = false,
    ) {
        this._candidateProfile = candidateProfile;
        if (notifyProfileUpdate) {
            this.notifyLocalProfileUpdate();
        }
    }

    triggerStepValidation() {
        this.validateStepRequest$.next(this._currentStep);
    }

    isNextStepEnabled(): boolean {
        let enabled = true;
        if (this._currentStep === CandidateProfileStepType.workExperience) {
            enabled = !!this._candidateProfile.workExperience?.length;
        }

        return enabled;
    }

    toggleEditMode(isEditMode: boolean) {
        this._isEditMode = isEditMode;
    }

    onAddStepListItem(
        type: StepContentListItemType,
        listItem: IStepContentListItem,
        index: number,
    ) {
        // new item is added, should have truthy _isNew flag
        const targetCollection = this.getTargetCollectionByItemType(type);
        targetCollection.splice(index, 0, listItem);
    }

    onSaveStepListItem(
        type: StepContentListItemType,
        listItem: IStepContentListItem,
        skipSync = false,
    ) {
        const targetCollection = this.getTargetCollectionByItemType(type);
        const index = targetCollection.findIndex(
            (item) => item._uuid === listItem._uuid,
        );
        targetCollection.splice(index, 1, listItem);

        if (!skipSync) {
            this.notifyLocalProfileUpdate();
        }
    }

    onRemoveStepListItem(
        type: StepContentListItemType,
        uuid: string,
        skipSync = false,
    ) {
        const targetCollection = this.getTargetCollectionByItemType(type);
        const index = targetCollection.findIndex((item) => item._uuid === uuid);
        targetCollection.splice(index, 1);

        if (!skipSync) {
            this.notifyLocalProfileUpdate();
        }
    }

    setResume(resume: GenericFileInfo) {
        this._candidateProfile.resume = resume;
        this.notifyLocalProfileUpdate();
    }

    setPersonalInfo(profileInfo: PersonalInfo, skipSync = false) {
        this._candidateProfile.personalInfo = profileInfo;

        if (!skipSync) {
            this.notifyLocalProfileUpdate();
        }
    }

    setSkills(candidateSkills: CandidateSkills, skipSync = false) {
        this._candidateProfile.skills = candidateSkills;

        if (!skipSync) {
            this.notifyLocalProfileUpdate();
        }
    }

    setWorkplacePriorities(priorities: number[], skipSync = false) {
        this._candidateProfile.priorities = priorities;

        if (!skipSync) {
            this.notifyLocalProfileUpdate();
        }
    }

    setPreferences(
        candidatePreferences: CandidatePreferences,
        skipSync = false,
    ) {
        this._candidateProfile.preferences = candidatePreferences;
        if (!skipSync) {
            this.notifyLocalProfileUpdate();
        }
    }

    setDemographics(candidateDemographics: CandidateDemographics) {
        this._candidateProfile.demographics = candidateDemographics;

        this.notifyLocalProfileUpdate();
    }

    setLanguages(languages: Language[], skipSync = false) {
        this._candidateProfile.languages = languages;

        if (skipSync) {
            this.notifyLocalProfileUpdate();
        }
    }

    updateAccomplishments(
        type: AccomplishmentSectionType,
        data: string[],
        skipSync = false,
    ) {
        this._candidateProfile[type] = data;

        if (!skipSync) {
            this.notifyLocalProfileUpdate();
        }
    }

    submitProfile(): Observable<CandidateProfile> {
        const candidateProfile = { ...this.candidateProfile };
        candidateProfile.demographics = {
            racialGroups: candidateProfile.demographics.racialGroups?.length
                ? candidateProfile.demographics.racialGroups
                : [preferNotToRespondKey],
            gender:
                candidateProfile.demographics.gender || preferNotToRespondKey,
            birthday: candidateProfile.demographics.birthday,
        } as CandidateDemographics;

        return this.submitPayload(candidateProfile).pipe(
            tap((profile) =>
                this.setProfile(CandidateProfile.fromStorage(profile), true),
            ),
        );
    }

    updateProfileSettings(
        updatedData: Record<string, string | number | boolean>,
        updateType: CandidateUserProfileUpdateType,
    ) {
        const candidateProfile: any = {
            ...this.candidateProfile,
            userCandidateUpdateType: updateType,
        };

        if (updateType === CandidateUserProfileUpdateType.accountSettings) {
            candidateProfile.personalInfo.firstName = updatedData.firstName;
            candidateProfile.personalInfo.lastName = updatedData.lastName;
            candidateProfile.personalInfo.phoneNumber = updatedData.phoneNumber;
            candidateProfile.personalInfo.email = updatedData.email;
        } else if (
            updateType === CandidateUserProfileUpdateType.suspensionSettings
        ) {
            candidateProfile.suspendProfile = updatedData.suspendProfile;
        }

        return this.submitPayload(candidateProfile);
    }

    initAutosaveProfile(): Observable<void> {
        // remove service properties before storing autosave profile json data
        const listableItemServiceProperties = ['_uuid', '_isNew'];
        const candidateProfile = { ...this._candidateProfile };
        delete candidateProfile['isProfileReadyForReview'];

        candidateProfile.workExperience = candidateProfile.workExperience?.map(
            (item) =>
                deleteObjectProperties(item, ...listableItemServiceProperties),
        );
        candidateProfile.schools = candidateProfile.schools.map((item) =>
            deleteObjectProperties(item, ...listableItemServiceProperties),
        );
        candidateProfile.certifications = candidateProfile.certifications.map(
            (item) =>
                deleteObjectProperties(item, ...listableItemServiceProperties),
        );

        const request = this.autosaveProfileService.autosaveProfile(
            this.userService.user.id,
            candidateProfile,
        );
        this.autosaveQueue.next(request);

        return request;
    }

    watchAndSyncCandidateProfile() {
        this.stopWatchAndSyncCandidateProfile();
        this.autosaveProfileSubscription.add(
            this.localProfileUpdate$
                .pipe(
                    filter((autosave) => autosave),
                    debounceTime(AUTOSAVE_PROFILE_DEBOUNCE_TIME_MS),
                    concatMap(() => this.initAutosaveProfile()),
                )
                .subscribe(),
        );
    }

    stopWatchAndSyncCandidateProfile() {
        this.localProfileUpdate$ = new BehaviorSubject<boolean>(false);
    }

    resolveCompanyInfoWithClearbit(
        candidateProfile: Observable<ICandidateProfile>,
    ): Observable<ICandidateProfile> {
        return candidateProfile.pipe(
            switchMap(this.resolveDomainsAndLogos()),
            switchMap(this.resolveIndustries()),
        );
    }

    canAccessStep(stepUrlSegment: string): boolean {
        let canAccess = true;

        const canAccessStepPredicateFunctions: Function[] | undefined =
            this.stepValidators[stepUrlSegment];
        if (canAccessStepPredicateFunctions) {
            canAccess = canAccessStepPredicateFunctions.every((fn) =>
                fn(this._candidateProfile),
            );
        }

        return canAccess;
    }

    getSkillsAsChips(): IPill[] {
        const topSkillsIds =
            this._candidateProfile.skills?.topSkills.map((skill) => skill.id) ||
            [];

        return (this._candidateProfile.skills?.topSkills || [])
            .concat(
                (this._candidateProfile.skills?.additionalSkills || []).filter(
                    (skill) => !topSkillsIds.includes(skill.id),
                ),
            )
            .map((skill) => this.toChip(skill));
    }

    getWorkExperienceIndustriesAsChips(): IPill[] {
        const industries: IPill[] = [];
        const industryIds = new Set<number>();

        this._candidateProfile.workExperience?.forEach((workExperience) => {
            workExperience.industries.forEach((industry) => {
                if (!industryIds.has(industry.id)) {
                    industryIds.add(industry.id);
                    industries.push(this.toChip(industry));
                }
            });
        });

        return industries;
    }

    getResume(): GenericFileInfo | undefined {
        return this._candidateProfile.resume;
    }

    getPersonalInfo() {
        return this._candidateProfile.personalInfo;
    }

    getWorkExperience() {
        return this._candidateProfile.workExperience;
    }

    getPreferences(): CandidatePreferences | undefined {
        return this._candidateProfile.preferences;
    }

    getDemographics(): CandidateDemographics {
        return this._candidateProfile.demographics;
    }

    getWorkplacePriorities(): number[] | undefined {
        return this._candidateProfile.priorities;
    }

    getWorkExperienceAsReviewListItems(): CandidateListItem[] {
        const data = this._candidateProfile.workExperience?.length
            ? [...this._candidateProfile.workExperience]
            : [];

        return data.sort(byOptionalEndDateDescComparator).map((we) => ({
            title: we.company.name,
            subTitle: we.jobTitle,
            domain: we.company.domain,
            additionalInfo: [
                calculateDateRange(
                    we.startDate,
                    we.endDate,
                    we.isCurrentPosition,
                ),
                calculateTotalExperienceInfo(we, 'startDate', 'endDate'),
            ],
            description: we.achievements,
            chips: we.industries.length
                ? we.industries.map((industry) => industry.name)
                : [],
        }));
    }

    getEducationAsReviewListItems(): CandidateListItem[] {
        const data =
            this._candidateProfile.schools?.length > 0
                ? [...this._candidateProfile.schools]
                : [];

        return data.sort(byOptionalEndDateDescComparator).map((school) => ({
            title: school.school?.name,
            subTitle: `${school.fieldOfStudy?.name} | ${school.degree?.name}`,
            domain: school.school?.domain,
            additionalInfo: [
                calculateDateRange(
                    school.startDate,
                    school.endDate,
                    school.isCurrent,
                ),
            ],
        }));
    }

    getCertificatesAsReviewListItems(): CandidateListItem[] {
        const data =
            this._candidateProfile.certifications?.length > 0
                ? [...this._candidateProfile.certifications]
                : [];

        return data
            .sort(
                (a, b) =>
                    new Date(b.year).getTime() - new Date(a.year).getTime(),
            )
            .map((certification) => ({
                title: certification.authority?.name,
                subTitle: certification.title?.name,
                domain:
                    certification.authority?.domain ||
                    certification.title?.domain,
                additionalInfo: certification.year
                    ? [`${new Date(certification.year).getFullYear()}`]
                    : [],
            }));
    }

    getAccomplishmentsAsSectionListItems(
        isSmScreen: boolean,
    ): SectionListItem[] {
        const accomplishments: SectionListItem[] = [];
        const keys: AccomplishmentSectionType[] = (
            Object.keys(
                accomplishmentSectionParams,
            ) as AccomplishmentSectionType[]
        ).filter(
            (key) =>
                key !== AccomplishmentSectionType.others &&
                !!this._candidateProfile[key]?.length,
        );
        for (const key of keys) {
            const values = this._candidateProfile[key] || [];
            const icon = accomplishmentSectionParams[key].logo;
            const label = `${accomplishmentSectionParams[key].title}:`;
            if (isSmScreen) {
                // for small screens we do not want to group items,
                // so adding sectionListItem for any value of accomplishment type
                for (const value of values) {
                    accomplishments.push({
                        icon,
                        iconColor: '#32C6BA',
                        label,
                        values: [value],
                    });
                }
            } else {
                accomplishments.push({
                    icon,
                    iconColor: '#32C6BA',
                    label,
                    values,
                });
            }
        }

        return accomplishments;
    }

    getLanguages() {
        return this._candidateProfile.languages;
    }

    getOthers() {
        return this._candidateProfile.others?.join(', ');
    }

    getStepLink(urlSuffix: string) {
        return this.baseUrl + urlSuffix;
    }

    getNavigationUrlOfLastValidStep(defaultUrl: string): string {
        let stepType = this.firstInvalidStep();
        if (
            CandidateProfileStepType.resumeReview === stepType &&
            !this.user.hasUploadedResume &&
            !this.user.isProfileComplete
        ) {
            stepType = CandidateProfileStepType.resume;
        }
        const stepUrl = candidateProfileSteps.find(
            (item) => item.type === stepType,
        )?.url;

        return stepUrl ? this.getStepLink(stepUrl) : defaultUrl;
    }

    firstInvalidStep(): CandidateProfileStepType | null {
        if (!isProfileStepComplete(this.candidateProfile)) {
            return CandidateProfileStepType.resumeReview;
        } else if (!isWorkExperienceStepComplete(this.candidateProfile)) {
            return CandidateProfileStepType.workExperience;
        } else if (!isSkillsStepComplete(this.candidateProfile)) {
            return CandidateProfileStepType.skills;
        } else if (!isPreferencesStepComplete(this.candidateProfile)) {
            return CandidateProfileStepType.preferences;
        }

        return null;
    }

    private submitPayload(candidateProfile: CandidateProfile) {
        // FormData as payload automatically sets headers content-type as
        // multipart for file submission.
        const payload = new FormData();
        // Json payload with correct content-type for correct dto deserialization in backend
        const blob = new Blob([JSON.stringify(candidateProfile)], {
            type: 'application/json',
        });
        payload.append('data', blob);

        return this.http
            .patch<CandidateProfile>(
                `/api/users/${this.userService.user.id}`,
                payload,
            )
            .pipe(
                tap((profile: CandidateProfile) => {
                    if (profile.personalInfo) {
                        this.userService.refreshUserPersonalInfo(
                            profile.personalInfo.firstName,
                            profile.personalInfo.lastName,
                            profile.personalInfo.email,
                            profile.displayName,
                            profile.personalInfo.phoneNumber,
                        );
                    }
                }),
            );
    }

    private resolveDomainsAndLogos(): (
        profile: ICandidateProfile,
    ) => Observable<ICandidateProfile> {
        return (profile: ICandidateProfile) => {
            if (profile.workExperience?.length) {
                const workExperienceIndexToCompany = new Map<
                    number,
                    ProfileCompany
                >();
                profile.workExperience.forEach((workExperience, index) => {
                    if (
                        (workExperience.company.name ||
                            workExperience.company.normalizedName) &&
                        !workExperience.company.domain
                    ) {
                        workExperienceIndexToCompany.set(
                            index,
                            workExperience.company,
                        );
                    }
                });
                const companiesToResolveObservables = Array.from(
                    workExperienceIndexToCompany.values(),
                ).map((company) => {
                    const companyName = company.normalizedName
                        ? company.normalizedName
                        : company.name;

                    return this.clearbitService.searchCompany(companyName);
                });

                if (companiesToResolveObservables.length > 0) {
                    return forkJoin(companiesToResolveObservables).pipe(
                        map((companies) => {
                            Array.from(
                                workExperienceIndexToCompany.entries(),
                            ).forEach((entry, index) => {
                                if (companies[index].length > 0) {
                                    entry[1].domain =
                                        companies[index][0].domain;
                                    entry[1].logo = companies[index][0].logo;
                                }
                            });

                            profile.workExperience?.forEach((item, index) => {
                                if (workExperienceIndexToCompany.has(index)) {
                                    item.company = Object.assign(
                                        item.company,
                                        workExperienceIndexToCompany.get(index),
                                    );
                                }
                            });

                            return profile;
                        }),
                    );
                }

                return of(profile);
            }

            return of(profile);
        };
    }

    private resolveIndustries(): (
        profile: ICandidateProfile,
    ) => Observable<ICandidateProfile> {
        return (profile) => {
            if (profile.workExperience?.length) {
                const domains: string[] = [];
                profile.workExperience.forEach((we) => {
                    if (we.company?.domain) {
                        domains.push(we.company.domain);
                    }
                });
                if (domains?.length) {
                    return this.industryService.searchByDomains(domains).pipe(
                        map((industriesMap: Record<string, Industry[]>) => {
                            if (Object.values(industriesMap).length > 0) {
                                profile.workExperience?.forEach(
                                    (we: IWorkExperience) => {
                                        if (!we.company.domain) {
                                            return;
                                        }

                                        const industries =
                                            industriesMap[we.company.domain];

                                        if (!industries) {
                                            return;
                                        }

                                        we.industries = industries.slice(
                                            0,
                                            maxNumberOfIndustriesAllowed,
                                        );
                                    },
                                );
                            }

                            return profile;
                        }),
                    );
                }

                return of(profile);
            }

            return of(profile);
        };
    }

    private getTargetCollectionByItemType(
        type: StepContentListItemType,
    ): IStepContentListItem[] {
        if (type === StepContentListItemType.workExperience) {
            return this.candidateProfile.workExperience || [];
        } else if (type === StepContentListItemType.school) {
            return this.candidateProfile.schools;
        } else if (type === StepContentListItemType.certification) {
            return this.candidateProfile.certifications;
        }

        throw new Error(
            'Attempting to get target collection for unsupported type',
        );
    }

    private notifyLocalProfileUpdate() {
        this.userService.candidateProfile = this._candidateProfile;
        this.localProfileUpdate$.next(true);
    }

    private toChip(item: { name: string }): IPill {
        return {
            name: item.name,
            color: '#32c6ba',
            textColor: '#fff',
        };
    }
}

function isProfileStepComplete(candidateProfile: CandidateProfile): boolean {
    return candidateProfile.personalInfo?.isValid() || false;
}

function isWorkExperienceStepComplete(
    candidateProfile: CandidateProfile,
): boolean {
    return (
        !!candidateProfile.workExperience?.length &&
        candidateProfile.workExperience.findIndex(
            (workExperience) => !workExperience.isValid(),
        ) < 0
    );
}

function isSkillsStepComplete(candidateProfile: CandidateProfile): boolean {
    return candidateProfile.skills?.isValid() || false;
}

function isPreferencesStepComplete(
    candidateProfile: CandidateProfile,
): boolean {
    return candidateProfile.preferences?.isValid() || false;
}
