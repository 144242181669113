<h1 class="success-message" *ngIf="showSuccessMessage">Successfully Logged In.</h1>

<div class="backgrounds-layer">
    <div class="background-layer candidate"></div>
    <div class="background-layer employer"></div>
</div>

<div
    class="form-pane-container"
    [ngClass]="userType === UserType.candidate ? 'candidate' : 'employer'"
    [class.sign-in]="isSignIn"
>
    <ng-container
        *ngTemplateOutlet="formPaneTemplate; context: { userPanelType: UserType.candidate }"
    >
    </ng-container>
    <ng-container
        *ngTemplateOutlet="formPaneTemplate; context: { userPanelType: UserType.employer }"
    >
    </ng-container>
</div>

<ng-template #formPaneTemplate let-userPanelType="userPanelType">
    <section
        class="form-pane"
        *ngIf="!showSuccessMessage"
        [class.active]="userPanelType === userType"
    >
        <div class="content">
            <a href="{{ siteUrl }}">
                <img src="/images/logo.svg" class="logo" alt="SquarePeg Logo" />
            </a>

            <ng-container *ngIf="showAuthScreen">
                <div class="auth-form-header">
                    {{ authFormLabels?.header }}
                </div>

                <ng-container *ngIf="isInitialAuthScreen">
                  <sp-tab-switcher [tabs]="tabs"></sp-tab-switcher>

                  <sp-social-auth
                    class="social-auth"
                    [isSignUp]="isSignUp"
                    [isAuthenticating]="isAuthenticating"
                    [userType]="userPanelType"
                    [invitationToken]="
                        userPanelType === UserType.employer ? employerInvitationToken : null
                    "
                    (authenticate)="authenticate($event)"
                  >
                  </sp-social-auth>

                  <div class="separator">
                    <div class="separator-line"></div>
                    <span class="separator-line-text">or</span>
                  </div>
                </ng-container>

                <p class="error-message" *ngIf="authenticationError">
                    {{ authenticationError }}
                </p>

                <!-- Sign Up -->
                <sp-signup-form
                    *ngIf="isSignUp"
                    class="signup-form"
                    [userType]="userPanelType"
                    [error]="error"
                    [employerInvitationToken]="employerInvitationToken"
                    [employerVerificationToken]="emailVerificationToken"
                    (signUp)="redirectToFirstActivity()"
                    (verificationEmailSent)="onVerificationEmailSent($event)"
                    (prefillDataFetched)="onPrefillDataFetched($event)"
                >
                </sp-signup-form>

                <!-- Sign In -->
                <sp-login-form
                    *ngIf="isSignIn"
                    class="login-form"
                    [userType]="userType"
                    [redirectTo]="redirectTo"
                    [prefilledEmail]="prefilledEmail"
                    [isSubmittingOutside]="isAuthenticating"
                    (logIn)="redirectToFirstActivity()"
                    (forgotPassword)="onForgotPassword(userPanelType)"
                >
                </sp-login-form>

                <ng-container *ngIf="isInitialAuthScreen">
                  <div class="auth-type-switcher">
                    <span class="info">{{ authFormLabels?.switcherInfo }}</span>
                    <span class="button" (click)="switchFormType()">
                        <u>{{ authFormLabels?.switcherButton }}</u>
                    </span>
                  </div>
                </ng-container>
            </ng-container>

            <!-- Password Reset -->
            <sp-password-reset *ngIf="showForgotPasswordForm" (passwordChange)="onPasswordChange()">
            </sp-password-reset>

            <!-- Email Verification -->
            <sp-email-verification
                *ngIf="!!emailVerificationToken && userType === UserType.candidate"
                (verificationComplete)="onEmailVerificationComplete($event)"
            >
            </sp-email-verification>

            <!-- New Employer User Access Request -->
            <sp-request-access-form *ngIf="showRequestAccessForm" [requestData]="requestAccessData">
            </sp-request-access-form>

            <!-- Show Join Employer Form -->
            <sp-join-employer-form
                *ngIf="showJoinEmployerForm"
                [prefillData]="prefillData"
                [requestToJoinCallback]="sendRequestToJoinEmail"
            >
            </sp-join-employer-form>

            <!-- Request To Join Employer Email Sent -->
            <sp-information-form
                *ngIf="showRequestEmailSentForm"
                [image]="'/images/auth/request_to_join_success.svg'"
                [title]="'Almost there!'"
                [description]="requestToJoinEmailSentDescription"
            >
            </sp-information-form>

            <!-- Email Verification Email Sent -->
            <sp-information-form
                class="employer-email-verification"
                *ngIf="showVerificationEmailSentForm"
                [image]="'/images/auth/verification_email_sent.svg'"
                [title]="'Please verify your email address'"
                [description]="emailSentToDescription"
                [buttonCallback]="resendEmailVerification"
                [buttonContext]="ButtonContext.info"
                [buttonInverted]="true"
                [buttonSuccessMessage]="
                    'Email sent with new verification link. Please check your inbox. '
                "
                [buttonErrorMessage]="
                    'We were unable to re-send your verification email. Please try again later.'
                "
                [buttonLabel]="'Resend verification email'"
            >
            </sp-information-form>

            <!-- SP Invite to Apply response result -->
            <div *ngIf="showInviteToApplyResponseResult">
                <sp-information-form
                    [title]="getInviteToApplyFormData().title"
                    [description]="getInviteToApplyFormData().description"
                    [buttonLabel]="'Sign In'"
                    [queryParams]="{ redirectTo: inviteToApplyRedirectTo }"
                    [buttonNavigationLink]="'/login/candidate'"
                >
                </sp-information-form>
            </div>

            <div
                class="terms-of-service"
                *ngIf="!showVerificationEmailSentForm && !showRequestEmailSentForm"
            >
                By using SquarePeg you agree to its<br />
                <a href="{{ siteUrl }}/terms-of-service/" title="Terms of Service"
                    >Terms of Service</a
                >
                and
                <a href="{{ siteUrl }}/privacy-policy/" title="Privacy Policy">Privacy Policy</a>
            </div>
        </div>

        <footer class="footer">
            <span class="copyright">© 2016 - {{ currentYear }} SquarePeg.</span>
            <span>
                <a href="{{ siteUrl }}" class="about-link" tabindex="-1">About SquarePeg</a>
            </span>
        </footer>
    </section>
</ng-template>
