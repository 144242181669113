<ng-select
    [items]="control.items"
    [ngModel]="''"
    [clearable]="false"
    [editableSearchTerm]="false"
    [searchable]="false"
    [dropdownPosition]="'auto'"
    [closeOnSelect]="true"
    (change)="onChangeEvent($event)"
    class="image-selector"
>
    <ng-template ng-option-tmp let-item="item">
        <span
            class="item-image {{ control.genericImageClassName }} {{
                control.specificImageClassPrefix
            }}{{ item.code }}"
        ></span>
        <span class="item-name">{{ item.name }}</span>
        <span class="item-value">{{ item.value }}</span>
    </ng-template>
</ng-select>

<div class="image-selection-wrapper">
    <span
        class="selected-image
  {{ control.genericImageClassName }}
  {{ control.specificImageClassPrefix }}{{ selectedCode }}"
    >
    </span>
</div>
<span [ngClass]="iconClass" class="field-icon"></span>

<input
    [name]="control.name"
    [type]="control.inputType"
    [placeholder]="control.placeholder"
    [attr.disabled]="control.disabled ? 'disabled' : null"
    [class.invalid]="invalidInputClass"
    [(ngModel)]="value"
    (keyup)="initValidation()"
    class="input-field v2"
/>
