import { Injectable } from '@angular/core';
import type { Observer } from 'rxjs';
import { Observable } from 'rxjs';
import { ColorContext } from '../models';
import type { NotificationMessage } from './notification-message.interface';

@Injectable()
export class NotificationService {
    private _notification: Observer<NotificationMessage>;
    public notification$: Observable<NotificationMessage>;

    private _notificationAction: Observer<any>;
    public notificationAction$: Observable<any>;

    constructor() {
        this.notification$ = new Observable(
            (observer) => (this._notification = observer),
        );
        this.notificationAction$ = new Observable(
            (observer) => (this._notificationAction = observer),
        );
    }

    show(notification: NotificationMessage) {
        notification = {
            colorContext: ColorContext.PRIMARY,
            ...notification,
        };
        this._notification.next(notification);
    }

    hide() {
        this._notification.next(null);
    }
}
