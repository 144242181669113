import { User } from './user.model';
import type { IdentifiableModel } from './identifiable-model.interface';
import type { AvatarItem } from './avatar-item.model';

export class Team {
    id: number;
    name: string;
    manager?: IdentifiableModel;
    members?: User[];

    static from(teamData) {
        return Object.assign(new Team(), teamData, {
            manager: teamData.manager ? User.from(teamData.manager) : null,
            members: teamData.members
                ? teamData.members.map((user) => User.from(user))
                : null,
        });
    }

    static getTeamManager(team: Team): User {
        if (!team.manager || !team.members.length) {
            return null;
        }

        return team.members.find((member) => member.id === team.manager.id);
    }

    static getNonManagerMembers(team: Team) {
        if (!team.manager) {
            return team.members;
        }

        return team.members.filter((member) => member.id !== team.manager.id);
    }

    get teamMemberAvatars(): AvatarItem[] {
        return this.members?.length > 0
            ? this.members.map((item) => ({
                  firstName: item.firstName,
                  fullName: item.fullName,
                  lastName: item.lastName,
                  image: item.profilePictureUrl,
              }))
            : [];
    }
}
