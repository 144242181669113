<a href="javascript:"
    [class.is-loading]="isLoading"
    class="toggle-wrapper size-{{ size }} sm-size-{{ smSize }} orientation-{{ labelPosition }}">

  <span *ngIf="!isLoading || stateLabelPosition"
        class="toggle-with-state state-label-{{ stateLabelPosition}}">
    <span *ngIf="!isLoading" class="toggle" [class.on]="isOn" (click)="toggle($event)">
      <span class="handle"></span>
    </span>
    <span *ngIf="stateLabelPosition" class="state-label">{{ switchStateLabel }}</span>
  </span>

  <img *ngIf="isLoading" src="/images/spinner.svg" class="sp-dots-spinner spinner" />
  <span *ngIf="label" class="label">{{ label }}</span>

  <ng-content *ngIf="!label"></ng-content>
</a>
