import type { AbstractControl, ValidationErrors } from '@angular/forms';
import { isDefined } from '../../utils';
import { isValidDateObject } from '../../date-utils';

export function dateValidator(allowFutureDates, isRequired = false) {
    return (control: AbstractControl): ValidationErrors | null =>
        isValidDate(control.value, allowFutureDates, isRequired);
}

export function isValidDate(
    value,
    allowFutureDates,
    required = false,
): ValidationErrors {
    if (!value || !value.value) {
        return required ? { required: { value } } : null;
    }

    if (typeof value.value.getMonth !== 'function') {
        return { invalidDate: { value } };
    } else if (
        !allowFutureDates &&
        value.value &&
        isDateAfterToday(value.value)
    ) {
        return { dateIsAfterCurrentDate: { value } };
    }

    return null;
}

export function minYearValidator(minYear: number) {
    return (control: AbstractControl): ValidationErrors | null =>
        isDefined(control.value)
            ? isDateAfter(control.value.value, minYear)
            : null;
}

export function isBeforeNow(value: Date): boolean {
    return value.getTime() < new Date().getTime();
}

export function isDateAfter(value, minYear: number): ValidationErrors {
    if (isValidDateObject(value) && value.getFullYear() < minYear) {
        return { dateIsBeforeMinDate: { value } };
    }

    return null;
}

export function isDateAfterToday(date: Date) {
    const currentDate = new Date();
    const localCurrentEpochTime =
        currentDate.getTime() - currentDate.getTimezoneOffset() * 60000;

    return date.getTime() > localCurrentEpochTime;
}
