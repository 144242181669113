import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { BehaviorSubject, Subject, Subscription, of } from 'rxjs';
import type { GenericIdVersion, Page } from '../shared/models';
import { JobInfo } from '../shared/models';
import { DashboardJobType } from '../shared/models/enumeration/dashboard-job-type.enum';
import { computePageOffset } from '../shared/page-utils';
import { mergeCollectionsByField, unsubscribeIfActive } from '../shared/utils';
import type { JobReviewers } from '../shared/models/job-info.model';
import { BaseJobInfo, SourcingStatus } from '../shared/models/job-info.model';
import { CachedItemsMap } from '../shared/models/cached-response-data.interface';
import type { AppendableItemsListItem } from '../shared/appendable-items-list/model';
import type { AtsJobInfo } from '../shared/models/ats-job-info.model';
import { PatchJobRequestType } from '../shared/models/enumeration/patch-job-request-type.model';
import type { JobRequirements } from '../shared/models/job-requirements.model';
import { UserService } from './user.service';
import { JobRequirementsApiService } from './job-requirements-api.service';

const defaultPageSize = 50;
const reviewersRefreshIntervalMs = 3600 * 1000; // 1 hour

@Injectable()
export class JobsService implements OnDestroy {
    private _selectedJobId?: number;
    private _selectedJobRequirements?: JobRequirements;
    private selectedJobIdSubject$: BehaviorSubject<number | undefined> =
        new BehaviorSubject<number | undefined>(undefined);

    private _activeTab = DashboardJobType.published;
    private _allJobsBaseInfo: BaseJobInfo[] = [];

    private jobReviewersMap: CachedItemsMap<JobReviewers> =
        new CachedItemsMap<JobReviewers>(reviewersRefreshIntervalMs);

    public jobVersionUpdated$ = new Subject<GenericIdVersion>();
    public selectedJobId$: Observable<number> = this.selectedJobIdSubject$
        .asObservable()
        .pipe(
            distinctUntilChanged(),
            filter((jobId): jobId is number => !!jobId),
        );

    publishedJobs: JobInfo[] = [];
    draftJobs: JobInfo[] = [];
    archivedJobs: JobInfo[] = [];

    jobTabCounters: Map<string, number> = new Map<string, number>();
    lastPageOfSelectedTabIsLoaded = false;

    sidebarSelectedJob: JobInfo | null = null;
    // used to keep job context between page job/{jobId}/applicants/...
    // and analytics section, only that way

    subscription: Subscription;

    get selectedJobId() {
        return this._selectedJobId;
    }
    set selectedJobId(id: number | undefined) {
        if (id === this._selectedJobId) {
            return;
        }

        this._selectedJobId = id;
        this.selectedJobIdSubject$.next(id);
    }

    get selectedJobRequirements(): JobRequirements | undefined {
        return this._selectedJobRequirements;
    }

    get publishedJobsBaseInfo(): BaseJobInfo[] {
        return this._allJobsBaseInfo.filter(
            (job) => job.status === DashboardJobType.published,
        );
    }

    set activeTab(tab: DashboardJobType) {
        this.lastPageOfSelectedTabIsLoaded = false;
        this._activeTab = tab;
    }

    get activeTab(): DashboardJobType {
        return this._activeTab;
    }

    constructor(
        private userService: UserService,
        private reqsService: JobRequirementsApiService,
        private http: HttpClient,
    ) {
        this.subscription = new Subscription();
        this.watchUserLoggingOut();
        this.watchSelectedJobIdChange();
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    // TODO move it to get-or-fetch in future, so undefined is not an option for return
    getBaseJobInfo(id: number | string): BaseJobInfo | undefined {
        return this._allJobsBaseInfo.find((job) => `${job.id}` === `${id}`);
    }

    getSelectedJobBaseInfo(): BaseJobInfo | undefined {
        if (!this._selectedJobId) {
            return undefined;
        }

        return this.getBaseJobInfo(this._selectedJobId);
    }

    updateCounters() {
        this.jobTabCounters = new Map([
            [
                DashboardJobType.published,
                this._allJobsBaseInfo.filter(
                    (job) => job.status === DashboardJobType.published,
                ).length,
            ],
            [
                DashboardJobType.drafts,
                this._allJobsBaseInfo.filter(
                    (job) => job.status === DashboardJobType.drafts,
                ).length,
            ],
            [
                DashboardJobType.archived,
                this._allJobsBaseInfo.filter(
                    (job) => job.status === DashboardJobType.archived,
                ).length,
            ],
        ]);
    }

    getCompleteJobById(id: number | string): JobInfo | undefined {
        const allJobs = [
            ...(this.publishedJobs || []),
            ...(this.draftJobs || []),
            ...(this.archivedJobs || []),
        ];

        return allJobs.find((job) => `${job.id}` === `${id}`);
    }

    addBaseJobInfo(job: BaseJobInfo): void {
        this._allJobsBaseInfo.push(BaseJobInfo.of(job));
        this.updateCounters();
    }

    syncBaseJobInfo(job: BaseJobInfo) {
        const oldJobIndex = this._allJobsBaseInfo.findIndex(
            (existingJob) => existingJob.id === job.id,
        );
        this._allJobsBaseInfo[oldJobIndex] = Object.assign(
            this._allJobsBaseInfo[oldJobIndex],
            job,
        );
    }

    getJobCollectionBasedOnType(): JobInfo[] {
        if (this.activeTab === DashboardJobType.published) {
            return this.publishedJobs;
        } else if (this.activeTab === DashboardJobType.drafts) {
            return this.draftJobs;
        } else if (this.activeTab === DashboardJobType.archived) {
            return this.archivedJobs;
        }

        return [];
    }

    getJobStatusBasedOnTab(): DashboardJobType {
        if (this.activeTab === DashboardJobType.archived) {
            return DashboardJobType.archived;
        } else if (this.activeTab === DashboardJobType.drafts) {
            return DashboardJobType.drafts;
        }

        return DashboardJobType.published;
    }

    archiveJob(jobId: number): Observable<JobInfo> {
        const requestBody = {
            published: false,
            patchJobRequestType: PatchJobRequestType.statusUpdate,
        };

        return this.patchJob(jobId, requestBody);
    }

    updateJobSourcingStatus(
        jobId: number,
        sourcingStatus: SourcingStatus,
        version: number,
    ): Observable<JobInfo> {
        const requestBody = {
            sourcingStatus,
            version,
            patchJobRequestType: PatchJobRequestType.sourcingStatusUpdate,
        };

        return this.patchJob(jobId, requestBody);
    }

    patchJob(
        jobId: number,
        patchJobRequest: Record<string, any>,
    ): Observable<JobInfo> {
        return this.http.patch<JobInfo>(`/api/jobs/${jobId}`, patchJobRequest, {
            headers: { 'Content-Type': 'application/merge-patch+json' },
        });
    }

    archiveLocally(jobInfo: JobInfo) {
        const jobIndex = this.getJobCollectionBasedOnType().findIndex(
            (job) => job.id === jobInfo.id,
        );
        if (jobIndex > -1) {
            this.getJobCollectionBasedOnType().splice(jobIndex, 1);
        }
        const jobToBeArchived = this._allJobsBaseInfo.find(
            (job) => job.id === jobInfo.id,
        );
        if (jobToBeArchived) {
            jobToBeArchived.status = DashboardJobType.archived;
            jobToBeArchived.sourcingStatus = SourcingStatus.archived;
            this.updateCounters();
        }
    }

    saveJob(job: JobInfo): Observable<JobInfo> {
        return job.id ? this.updateJob(job) : this.createJob(job);
    }

    updateJobReviewers(
        jobId: number,
        reviewers: JobReviewers,
    ): Observable<void> {
        return this.http.patch<void>(`/api/jobs/${jobId}/reviewers`, reviewers);
    }

    private createJob(job: JobInfo): Observable<JobInfo> {
        return this.http.post<JobInfo>('/api/jobs', job).pipe(
            tap((jobResponse) => {
                this.addBaseJobInfo(jobResponse);
            }),
        );
    }

    private updateJob(job: JobInfo): Observable<JobInfo> {
        return this.http.put<JobInfo>(`/api/jobs/${job.id}`, job);
    }

    fetchJobsBaseInfo(forceReload = false): Observable<BaseJobInfo[]> {
        if (!this._allJobsBaseInfo.length || forceReload) {
            return this.executeGetJobsRequest().pipe(
                tap((jobs) => {
                    this._allJobsBaseInfo = jobs.map((job) =>
                        BaseJobInfo.of(job),
                    );
                    this.updateCounters();
                }),
            );
        }

        return of(this._allJobsBaseInfo);
    }

    moveFromDraftToPublished(job: JobInfo) {
        this.draftJobs = this.draftJobs.filter((j) => j.id !== job.id);
        this.publishedJobs.push(job);
        this.updateCounters();
    }

    fetchEverPublishedJobs(): Observable<BaseJobInfo[]> {
        return this.executeGetJobsRequest(true);
    }

    getJobs(sort?: string): Observable<JobInfo[]> {
        const params = this.getJobsParams(0, sort);

        return this.fetchJobsWithParams(params).pipe(
            tap((jobs) => this.setJobCollectionBasedOnType(jobs)),
        );
    }

    fetchNextJobsChunk(sort?: string): Observable<JobInfo[]> {
        return this.loadNextChunkOfJobs(
            this.getJobCollectionBasedOnType(),
            sort,
        );
    }

    fetchJobsWithParams(params: Record<string, any>): Observable<JobInfo[]> {
        return this.http
            .get<Page<JobInfo>>('/api/employers/jobs', { params })
            .pipe(
                map((page) => {
                    this.lastPageOfSelectedTabIsLoaded = page.last;

                    return page.content.map((job) => JobInfo.of(job));
                }),
            );
    }

    fetchOneJob(jobId: number): Observable<JobInfo> {
        return this.http
            .get<JobInfo>(`/api/jobs/${jobId}`)
            .pipe(map((response) => JobInfo.of(response)));
    }

    fetchAvailableAtsJobs(params: {
        atsJobIdToBeIncluded?: string;
    }): Observable<AtsJobInfo[]> {
        return this.http.get<AtsJobInfo[]>(`/api/jobs/available-ats-jobs`, {
            params,
        });
    }

    getJobReviewers(jobId: number): Observable<JobReviewers> {
        const reviewers = this.jobReviewersMap.getItemIfValid(jobId);
        let reviewers$: Observable<JobReviewers>;
        if (reviewers) {
            reviewers$ = of(reviewers);
        } else {
            reviewers$ = this.http
                .get<JobReviewers>(`/api/jobs/${jobId}/reviewers`)
                .pipe(
                    tap((data) => this.jobReviewersMap.updateItem(jobId, data)),
                );
        }

        return reviewers$;
    }

    getJobScreeningQuestions(
        jobId: number,
    ): Observable<AppendableItemsListItem[]> {
        return this.http.get<AppendableItemsListItem[]>(
            `/api/jobs/${jobId}/screening-questions`,
        );
    }

    clearJobReviewersCache() {
        this.jobReviewersMap.clear();
    }

    getMissingDataForJob(job: JobInfo): Observable<JobInfo> {
        return this.getJobScreeningQuestions(job.id).pipe(
            map((questions) => {
                job.screeningQuestions = questions;

                return job;
            }),
        );
    }

    private getJobsParams(page: number, activeSort?: string) {
        return {
            status: this.getJobStatusBasedOnTab(),
            size: defaultPageSize,
            page,
            sort: activeSort || '',
        };
    }

    setJobCollectionBasedOnType(jobs: JobInfo[]) {
        if (this.activeTab === DashboardJobType.published) {
            this.publishedJobs = jobs;
        } else if (this.activeTab === DashboardJobType.drafts) {
            this.draftJobs = jobs;
        } else if (this.activeTab === DashboardJobType.archived) {
            this.archivedJobs = jobs;
        }
    }

    private loadNextChunkOfJobs(collection: JobInfo[], sort?: string) {
        const pageInfo = computePageOffset(collection.length, defaultPageSize);
        const params = this.getJobsParams(pageInfo.nextPage, sort);

        return this.fetchJobsWithParams(params).pipe(
            tap((jobs) => {
                if (pageInfo.nextPage === pageInfo.currentPage) {
                    mergeCollectionsByField(collection, jobs);
                } else {
                    collection.splice(collection.length, 0, ...jobs);
                }
            }),
        );
    }

    private executeGetJobsRequest(
        onlyEverPublished = false,
    ): Observable<BaseJobInfo[]> {
        const params = new HttpParams({ fromObject: { onlyEverPublished } });

        return this.http.get<BaseJobInfo[]>('/api/jobs', { params });
    }

    private watchUserLoggingOut() {
        this.subscription.add(
            this.userService.userLogout$.subscribe(() => {
                this._activeTab = DashboardJobType.published;
                this._allJobsBaseInfo = [];
                this._selectedJobRequirements = undefined;
            }),
        );
    }

    private watchSelectedJobIdChange(): void {
        this.subscription.add(
            this.selectedJobId$
                .pipe(
                    switchMap((jobId) =>
                        this.reqsService.getJobReqsIfFeatureEnabled(jobId),
                    ),
                )
                .subscribe((reqs) => {
                    this._selectedJobRequirements = reqs;
                }),
        );
    }
}
