<sp-icon-buttons-group
    [items]="control.items"
    [control]="control.formControl"
    [allowToUnSelect]="control.allowToUnSelect"
    [allowToExpand]="control.allowToExpand"
    [allowToExpandSm]="control.allowToExpandSm"
    [labelAlignStart]="control.labelAlignStart"
    [showVertical]="control.showVertical"
    [invalid]="invalid"
    [isMultiselect]="control.isMultiselect"
    [styles]="control.styles"
    [iconStyles]="control.iconStyles"
    [checkIconStyles]="control.checkIconStyles"
    [listStyles]="control.listStyles"
    [maxSelectedItemsAllowed]="control.maxSelectedItemsAllowed"
></sp-icon-buttons-group>
