import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractChatClient } from './abstract-chat-client.service';
import { ChatType } from './model';

@Injectable({
    providedIn: 'root',
})
export class BaseMessagingChatClient extends AbstractChatClient {
    constructor(http: HttpClient) {
        super(http, ChatType.messaging);
    }
}
