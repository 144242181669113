import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import type { SpCompositeFilterChipsControl } from '../sp-form-controls.model';
import { SpTagChipsInputControl } from '../sp-form-controls.model';

@Injectable({
    providedIn: 'root',
})
export class CompositeFilteringChipsService {
    public initSpComponentData(control: SpCompositeFilterChipsControl) {
        return new SpTagChipsInputControl({
            form: new FormGroup({}),
            name: 'filter-tags',
            isOptional: true,
            theme: control.theme,
            selectedChips: control.selectedChips,
        });
    }
}
