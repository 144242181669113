<div
    [formGroup]="control.form"
    [class.with-chars-counter]="hasCharsCounter"
    [class.error]="!!control.error"
    [class.touched]="!!control.isDirtyOrTouched && !control.error"
    [style.padding-bottom.px]="control.bottomPadding"
    [ngStyle]="control.style"
    class="textarea-wrapper"
>
    <textarea
        [name]="control.name"
        [placeholder]="control.placeholder"
        [rows]="control.textareaAsInput ? '1' : control.rows"
        [formControlName]="control.name"
        [attr.disabled]="control.disabled ? 'disabled' : null"
        class="inner-input"
    >
    </textarea>
    <span *ngIf="control.maxChars" class="remaining-chars-counter"
        >{{ charsLeft }} characters left</span
    >
</div>
