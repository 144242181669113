<!-- from -->
<sp-form-input [control]="fromDateControl" class="from-date-field"></sp-form-input>

<!-- to -->
<sp-form-input
    [control]="toDateControl"
    class="to-date-field {{ maskedDateRangeDateToMarker }}"
    #toDateFormInput
></sp-form-input>

<sp-toggle-switch
    *ngIf="control.hasIsCurrentOption"
    [isOn]="toDateIsCurrentDate"
    [size]="'medium'"
    [labelPosition]="'left'"
    (toggleChange)="onCurrentDateToggle($event)"
    class="current-date-toggle-switch"
>
    <span class="current-date-toggle-switch-label">{{ control.labelIsCurrentOption }}</span>
</sp-toggle-switch>
