import { InjectionToken } from '@angular/core';
import type { PopupService } from '../popup.service';

export { PopupDropdownStyles } from './popup-dropdown-styles.model';

export const spPopupData = new InjectionToken<PopupContentComponentData>(
    'SpPopupData',
);

export interface PopupContentComponentData {
    popupData: PopupData;
    popupRef: PopupRef;
}

export class PopupRef {
    constructor(private popupService: PopupService) {}

    close(data?: any) {
        this.popupService.hide(data);
    }

    emitData(data: any) {
        this.popupService.emitData(data);
    }
}

export type OverlayPositionX = 'start' | 'center' | 'end';
export type OverlayPositionY = 'top' | 'center' | 'bottom'; // mirrors angular's ConnectedPosition.overlayY
export type PopupBackgroundColor = 'purple' | 'white';

export interface PopupConfig {
    overlayX?: OverlayPositionX;
    overlayY?: OverlayPositionY;
    originX?: OverlayPositionX;
    originY?: OverlayPositionY;
    offsetX?: number;
    offsetY?: number;

    // Dismiss popup on scroll. Please note that container being scrolled needs to have
    // cdkScrollable directive for CDK to be able to detect scrolling and act accordingly
    dismissOnScroll?: boolean;

    // padding in popup container component. Defined as string to be able to provide value
    // for top/bottom/etc
    padding?: string;

    // border radius on popup container component
    borderRadius?: number;

    // hide popup container component's pointer
    hidePointer?: boolean;

    // Enable/Disabled event bubbling up when click inside the popup
    stopPropagationForClickInside?: boolean;

    // make background overlay darker
    hasBackdrop?: boolean;

    // color of the tooltip
    backgroundColor?: PopupBackgroundColor;

    // container width
    width?: number;

    // Used for properly styling ::before arrow
    theme?: 'dark' | 'light';
}

export interface PopupData {
    config: PopupConfig;
    popupData: any;
}
