import type { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import {
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import * as Delta from 'quill-delta/lib/delta';
import type { BoundsStatic, RangeStatic } from 'quill';
import type Quill from 'quill';
import { Subscription } from 'rxjs';
import type { DropdownItem } from '../../models';
import {
    keyCodeAt,
    keyCodeBackspace,
    keyCodeEnter,
} from '../../../core/config.service';
import {
    escapeRegExp,
    isAlphanumericKey,
    unsubscribeIfActive,
} from '../../utils';
import { PopupService } from '../../popup/popup.service';
import { commentAttachmentButtonId } from '../comment-textarea/comment-textarea.component';
import { MentionDropdownListComponent } from './menton-dropdown-list/mention-dropdown-list.component';
import { MentionMarker } from './mention-marker';

@Component({
    selector: 'sp-wysiwyg-editor-with-mentions',
    templateUrl: './wysiwyg-editor-with-mentions.component.html',
    styleUrls: ['./wysiwyg-editor-with-mentions.component.scss'],
})
export class WysiwygEditorWithMentionsComponent
    implements OnChanges, OnDestroy
{
    @Input() enabled: boolean;
    @Input() availableMentions: DropdownItem[];
    @Input() placeholder: string;

    @ViewChild(QuillEditorComponent) editorComponent: QuillEditorComponent;

    private readonly subscription: Subscription;
    private readonly mentionPrefix = '@';
    private readonly commentAttachmentButtonId = commentAttachmentButtonId;
    mentionsFilterText = '';
    mentionsDropdownOpened = false;
    isFocused = false;
    formControl = new FormControl();

    private editor: Quill;
    private placeholderRegexps: RegExp[];
    private editorWidth = 300;
    private dropdownHeight = 220;
    private dropdownWidth = 240;
    private dropdownOffset = 10;

    @HostBinding('class.is-focused')
    get isExpanded() {
        return this.isFocused && this.enabled;
    }

    constructor(
        private popupService: PopupService,
        private elRef: ElementRef<HTMLElement>,
    ) {
        this.subscription = new Subscription();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.availableMentions) {
            this.initPossiblePlaceholderRegexps();
        }
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    @HostListener('window:mouseup', ['$event'])
    clickout(event) {
        const attachmentButton: HTMLElement = document.getElementById(
            this.commentAttachmentButtonId,
        );
        this.isFocused =
            attachmentButton?.contains(event.target) ||
            this.elRef.nativeElement.contains(event.target);
    }

    onEditorCreated(editor: Quill) {
        this.editor = editor;
    }

    onMentionDropdownClose() {
        const caretRange = this.editor.getSelection(true);
        if (caretRange) {
            caretRange.index = caretRange.index + 1;
            this.editor.setSelection(caretRange);
        }
    }

    onMentionItemSelected(mentionKey: string) {
        const baseLineRange = this.editor.getSelection(true);
        const value = `${this.mentionPrefix}${
            this.availableMentions.find((item) => item.key === mentionKey).label
        }`;
        const numOfCharsToBeDeleted = this.mentionsFilterText.length + 1; // including @
        const insertionIndex = baseLineRange.index - numOfCharsToBeDeleted;
        this.replaceTextWithBlot(insertionIndex, numOfCharsToBeDeleted, value);
        // append one empty space after mentioned item
        this.editor.insertText(insertionIndex + 1, ' ');
        // move a caret after the inserted embed
        const setCursorAt: RangeStatic = {
            index: insertionIndex + 2,
            length: baseLineRange.length,
        };
        this.editor.setSelection(setCursorAt);
        this.isFocused = true;
    }

    onKeyDown(event: KeyboardEvent) {
        if (this.mentionsDropdownOpened) {
            if (event.keyCode === keyCodeBackspace) {
                this.mentionsFilterText = this.mentionsFilterText.slice(0, -1);
            } else if (event.keyCode === keyCodeEnter) {
                // onContentChanged is triggered before DOM onKey events so using history module to undo action is the only
                // way to remove extra new line added on selecting match with enter
                this.editor.getModule('history').undo();
            } else if (isAlphanumericKey(event.key)) {
                this.mentionsFilterText = this.mentionsFilterText.concat(
                    event.key,
                );
            } else {
                return false;
            }
        } else if (
            event.key === this.mentionPrefix ||
            event.keyCode === keyCodeAt
        ) {
            this.openMentionsDropdown(event.target);
        }

        // to avoid "jumping" view to the top when pressing enter or backspace,
        // we set selection to the very end
        if (
            event.keyCode === keyCodeEnter ||
            event.keyCode === keyCodeBackspace
        ) {
            this.editor.setSelection(this.editor.getSelection(true));
        }
    }

    editMessage(value: string) {
        this.setValue(value);
        this.isFocused = true;
    }

    setValue(value: string) {
        this.editor.clipboard.dangerouslyPasteHTML(value);
        this.placeholderRegexps.forEach((regexp) => {
            regexp.lastIndex = 0;

            let res;
            while ((res = regexp.exec(this.editor.getText())) !== null) {
                this.replaceTextWithBlot(res.index, res[0].length, res[0]);
            }
        });
    }

    replaceTextWithBlot(startIndex: number, length: number, value: string) {
        // Delete the range of text
        this.editor.deleteText(startIndex, length);
        // Insert the custom blot
        this.editor.insertEmbed(startIndex, MentionMarker.blotName, {
            value,
        });
    }

    getHtmlContent(): string {
        if (!this.editor.getContents().ops) {
            return '';
        }

        // Since we no longer need mention to have special class for styling, special delta operation is
        // replaced with a delta operation with regular insert. This allows us to use inner HTML without breaking
        // further processing.
        const ops = this.editor
            .getContents()
            .ops.map((operation) =>
                operation.insert && operation.insert[MentionMarker.blotName]
                    ? { insert: operation.insert[MentionMarker.blotName].value }
                    : operation,
            );

        this.editor.setContents(new Delta({ ops }));

        return this.editor.root.innerHTML;
    }

    private initPossiblePlaceholderRegexps() {
        this.placeholderRegexps = this.availableMentions.map(
            (placeholder) =>
                new RegExp(
                    escapeRegExp(`${this.mentionPrefix}${placeholder.label}`),
                    'g',
                ),
        );
    }

    private openMentionsDropdown(target) {
        const bounds: BoundsStatic = this.editor.getBounds(
            this.editor.getSelection(true).index,
        );
        this.mentionsDropdownOpened = true;

        const offsetLeft = this.calculateLeftOffset(target, bounds);
        const offsetTop = this.calculateTopOffset(bounds);

        this.popupService
            .show(target, MentionDropdownListComponent, {
                config: {
                    overlayX: 'end',
                    overlayY: 'bottom',
                    hidePointer: true,
                },
                popupData: {
                    dropdownData: this.availableMentions,
                    offsetLeft,
                    offsetTop,
                },
            })
            .subscribe((action) => this.onPopupServiceAction(action));
    }

    private onPopupServiceAction(action) {
        if (action) {
            this.onMentionItemSelected(action);
        } else {
            this.onMentionDropdownClose();
        }
        this.mentionsDropdownOpened = false;
        this.mentionsFilterText = '';
    }

    private calculateLeftOffset(target, bounds: BoundsStatic) {
        const controlLeftOffset = target.getBoundingClientRect().left;
        // isTopLeft is true when we want popup to show on the topLeft side of the cursor
        const isTopLeft =
            bounds.left + this.dropdownWidth + controlLeftOffset >
            window.innerWidth;
        // leftFromOverlay is horizontal distance between overlay element to the start of the control element
        const leftFromOverlay = bounds.left - this.editorWidth / 2;

        // offsetLeft is distance by which we move popup to right to position correctly relative to overlay element
        let offsetLeft = leftFromOverlay - this.dropdownOffset;
        if (isTopLeft) {
            if (leftFromOverlay > this.dropdownOffset) {
                // if we have enough space on the left to show popup properly
                offsetLeft =
                    leftFromOverlay - this.dropdownWidth + this.dropdownOffset;
            } else {
                // if we have do not have enough space on the left to show popup properly -> on small screens
                // position popup to control left offset (so they are aligned)
                offsetLeft = -bounds.left;
            }
        }

        return offsetLeft;
    }

    private calculateTopOffset(bounds: BoundsStatic) {
        // iOS (small screens) gives wrong number in window.innerHeight compared to all other, when keyboard is open.
        // Since the correct value should be taken from document.documentElement.client for further calculation.
        // as fix we can decrease top offset by difference of window.innerHeight and document.documentElement.clientHeight
        // https://github.com/angular/components/issues/18890
        // Note that on other place (except iOS) heightDiff will be 0, so no changes will be applied.
        // TODO revisit this calculation
        const heightDiff =
            window.innerHeight - document.documentElement.clientHeight;
        const editorHeight = Math.max(
            this.editorComponent.editorElem.offsetHeight,
            this.editorComponent.editorElem.scrollHeight,
        );
        const offsetTop =
            bounds.top - editorHeight - this.dropdownHeight - heightDiff;

        return offsetTop;
    }
}
