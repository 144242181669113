import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { JobsService } from './jobs.service';

@Injectable()
export class EmployerJobGuard implements CanActivate {
    constructor(private jobsService: JobsService) {}

    /**
     * The guard to protect accessing non-existent or archived job
     * @param next
     */
    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
        if (next.params.id) {
            const jobId = parseInt(next.params.id, 10);

            return this.isJobActive(jobId);
        }

        return of(false);
    }

    private isJobActive(jobId: number): Observable<boolean> {
        if (this.jobsService.getBaseJobInfo(jobId)) {
            return of(true);
        }

        return this.jobsService
            .fetchOneJob(jobId)
            .pipe(map((job) => job !== undefined));
    }
}
