import type {
    CandidateTraitResult,
    IMatchSectionResult,
} from '../../matches/candidate/model';
import { CandidateWorkExperience } from '../../matches/employer/model/candidate-work-experience.model';
import { CandidateSchool } from '../../matches/employer/model/candidate-school.model';
import { getMostRecentDateRangeEntry, toDate } from '../date-utils';
import type { Language } from '../../account/profile/candidate-profile/model';
import { Certification } from '../../account/profile/candidate-profile/model';
import { Match } from './match.model';
import type { GenericNameValue } from './generic-name-value.interface';
import type { GenericIdName } from './generic-id-name.interface';
import type { CandidateInfoFile } from './user/candidate/candidate-info-file.interface';
import { EmployerConnectedMatchStatus } from './enumeration/employer-connected-match-status.model';
import type { LocationType } from './location-type.model';

export const eolSubStatuses = new Set<EmployerConnectedMatchStatus>([
    EmployerConnectedMatchStatus.withdrawnContacted,
    EmployerConnectedMatchStatus.withdrawnInterviewing,
    EmployerConnectedMatchStatus.withdrawnReviewed,
    EmployerConnectedMatchStatus.disqualified,
    EmployerConnectedMatchStatus.declined,
    EmployerConnectedMatchStatus.accepted,
]);

export class MatchEmployer extends Match {
    candidateLocation: string;
    candidateWebsite?: string;
    candidateLinkedinProfileUrl?: string;
    candidateInfoFile: CandidateInfoFile;
    candidateSummary?: string;
    candidateSpecificSkills: GenericNameValue[];
    candidateSpecificAdditionalSkills?: GenericNameValue[];
    candidatePriorities?: number[];
    isUnscreened?: boolean;
    requiresVisaSponsorship?: boolean;
    candidateWillingToRelocate?: boolean;
    preferredLocation?: LocationType;

    candidateExperience?: CandidateWorkExperience[];
    candidateEducation?: CandidateSchool[];
    candidateCertifications?: Certification[];
    candidateIndustries: GenericIdName[];
    candidateTotalWorkExperienceMonths?: number;

    tags?: string[];
    skillScore?: number;
    workExperienceScore?: number;
    traitScore?: number;
    matchResults?: {
        skillResults?: IMatchSectionResult[];
        industryResults?: IMatchSectionResult[];
        traitResults?: CandidateTraitResult[];
    };

    employerConnectedStatusLastModifiedDate: Date;
    order?: number;
    employerFavorite?: boolean;

    // chat channel id associated with this match
    commentsChatChannelId?: string;

    feedUUID?: string;
    lastTimeFeedView?: Date;

    // accomplishments
    languages?: Language[];
    leaderships?: string[];
    clubs?: string[];
    volunteering?: string[];
    publications?: string[];
    awards?: string[];
    courses?: string[];
    others?: string[];

    static of(match) {
        const m = Object.assign(new MatchEmployer(), match, {
            createdDate: toDate(match.createdDate),
            modifiedDate: toDate(match.modifiedDate),
            candidateActionDate: toDate(match.candidateActionDate),
            employerActionDate: toDate(match.employerActionDate),
            approvalDate: toDate(match.approvalDate),
            lastTimeFeedView: toDate(match.lastTimeFeedView),
            employerConnectedStatusLastModifiedDate: toDate(
                match.employerConnectedStatusLastModifiedDate,
            ),
        });

        if (match.candidateExperience) {
            m.candidateExperience = match.candidateExperience.map((item) =>
                CandidateWorkExperience.of(item),
            );
        }

        if (match.candidateEducation) {
            m.candidateEducation = match.candidateEducation.map((item) =>
                CandidateSchool.of(item),
            );
        }

        if (match.candidateCertifications) {
            m.candidateCertifications = match.candidateCertifications.map(
                (item) => Certification.of(item),
            );
        }

        if (match.employerConnectedMatchStatus) {
            m.connectedMatchStatus =
                EmployerConnectedMatchStatus[
                    match.employerConnectedMatchStatus
                ];
        }

        return m;
    }

    getMostRecentPosition() {
        const workExperience = this.candidateExperience;
        if (!workExperience || !workExperience.length) {
            return null;
        }

        const mostRecentJob = getMostRecentDateRangeEntry(
            workExperience,
            'isCurrentlyApplicable',
            'completionDate',
        );

        return {
            position: mostRecentJob.jobTitle,
            employerName: mostRecentJob.companyName,
            industries: mostRecentJob.industries,
        };
    }

    calculateTotalWorkExperienceMonths() {
        const monthsOfExperience = this.candidateTotalWorkExperienceMonths || 0;

        return Math.ceil(monthsOfExperience / 12);
    }
}
