import { Injectable } from '@angular/core';
import { KJUR, b64utoutf8 } from 'jsrsasign/lib/jsrsasign';
import { environment } from '../../environments/environment';
import { isDefined } from '../shared/utils';

@Injectable({
    providedIn: 'root',
})
export class JwtService {
    getPermissions(jwt): string[] {
        const parsedJwt = this.parseJwt(jwt);
        const permissions = parsedJwt ? parsedJwt.permissions || [] : [];

        return permissions.map((permission) => permission.name);
    }

    private parseJwt(jwt: string): any {
        let parsedJwt = null;
        if (this.verifyJwt(jwt)) {
            parsedJwt = KJUR.jws.JWS.readSafeJSONString(
                b64utoutf8(jwt.split('.')[1]),
            );
        }

        return parsedJwt;
    }

    private verifyJwt(jwt: string): boolean {
        return (
            isDefined(jwt) &&
            KJUR.jws.JWS.verifyJWT(jwt, environment.config.pk, {
                alg: ['RS256'],
            })
        );
    }
}
