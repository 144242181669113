<div class="input-wrapper">
    <span [ngClass]="iconClass" class="field-icon"></span>
    <input
        [name]="control.name"
        [type]="control.inputType"
        [placeholder]="control.placeholder"
        [attr.disabled]="control.disabled ? 'disabled' : null"
        [class.invalid]="isInvalidControl"
        [(ngModel)]="inputValue"
        (keyup)="initValidation()"
        class="input-field v2"
    />
    <div class="selector">
        <div (click)="onSelectorClick()">
            <span class="selector-label">{{ selectedValue?.label }}</span>
            <img
                alt=""
                class="caret"
                src="/images/icons/single-caret.svg"
                [class.expanded]="expanded"
            />
        </div>
        <div class="dropdown-container" *ngIf="expanded">
            <ul *ngFor="let opt of control.items; let idx = index" class="dropdown-options">
                <li
                    class="dropdown-option"
                    [class.selected-option]="opt.value === selectedValue?.value"
                >
                    <a
                        href="javascript:"
                        (click)="onOptionItemClick(opt)"
                        class="dropdown-option-link"
                    >
                        {{ opt.label }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
