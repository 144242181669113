import { Component, Inject } from '@angular/core';
import type { MessageTemplate } from '../messaging-chat/model/message-template';
import { PopoverService } from '../popover/popover.service';
import { TemplateLegendComponent } from '../template-legend/template-legend.component';
import { spPopupContentComponentData } from '../popup/popup.component';

export interface TemplateTopListItem {
    icon: string;
    text: string;
    actionType: TemplateMenuActionType;
    disabledTooltipText?: string;
}

export enum TemplateMenuActionType {
    templateManager,
    addNew,
}

@Component({
    selector: 'sp-template-menu-popup',
    templateUrl: './template-menu-popup.component.html',
    styleUrls: ['./template-menu-popup.component.scss'],
})
export class TemplateMenuPopupComponent {
    readonly noFilteredTemplatesFound = {
        title: 'No templates available.',
        body: 'Please modify your search criteria.',
    };
    readonly noActiveTemplatesFound = {
        title: 'No templates available.',
        body: 'Please create one.',
    };

    topListItems: TemplateTopListItem[];
    allTemplatesSectionData: MessageTemplate[];
    filteredAllTemplatesSectionData: MessageTemplate[];
    recentlyUsedTemplatesSectionData: MessageTemplate[];
    contentData: any;

    constructor(
        @Inject(spPopupContentComponentData) data: any,
        private popoverService: PopoverService<TemplateLegendComponent>,
    ) {
        this.contentData = data.contentComponentData;
        this.topListItems = data.contentComponentData.topListItems;
        this.allTemplatesSectionData =
            data.contentComponentData.allTemplatesSectionData.sort((a1, a2) =>
                a1.title.toLowerCase() < a2.title.toLowerCase() ? -1 : 1,
            );
        this.filteredAllTemplatesSectionData =
            data.contentComponentData.allTemplatesSectionData;
        this.recentlyUsedTemplatesSectionData =
            data.contentComponentData.recentlyUsedTemplatesSectionData;
    }

    onTemplateClick(event, template) {
        event.stopPropagation();
        this.contentData.selectTemplate(template);
        this.popoverService.hideAfterTimeout();
    }

    onTopListItemClicked(action: TemplateMenuActionType) {
        this.contentData.topListItemClick(action);
    }

    onSearchValueChange(event) {
        this.filteredAllTemplatesSectionData =
            this.allTemplatesSectionData.filter((item) =>
                item.title
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase()),
            );
    }

    onRowHovered(hoveredRow, body: string) {
        hoveredRow.stopPropagation();
        const legendComponentHeight = 190;
        const legendComponentWidth = 320;

        const showToTheLeft =
            window.innerHeight - hoveredRow.clientX < legendComponentWidth + 48;
        const showAbove =
            window.innerHeight - hoveredRow.clientY <
            legendComponentHeight + 48;

        // translate to -170 vertically, so we can centrally align caret of the popup
        // based on legendComponentHeight which is 190;
        // translate horizontally is calculated based on template menu width (23rem) and legend component width
        // including inline padding (32rem + 4.8rem).
        let translate;
        if (showAbove || showToTheLeft) {
            translate = `translate(${showToTheLeft ? '-598px' : 0}, ${
                showAbove ? '-170px' : 0
            })`;
        }

        this.popoverService.showAfterTimeout(
            hoveredRow.target,
            TemplateLegendComponent,
            {
                body,
                bottomButtons: false,
                height: legendComponentHeight,
                width: legendComponentWidth,
                transform: translate,
                orientationVertical: !showAbove || 'bottom',
                orientationHorizontal: !showToTheLeft || 'left',
            },
        );
    }

    onRowLeave() {
        this.popoverService.hideAfterTimeout();
    }

    onSearchInputClick(event: MouseEvent) {
        event.stopPropagation();
    }
}
