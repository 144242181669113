import type { FormControl } from '@angular/forms';
import {
    inputIconCssClassPrefix,
    validFilledFieldInputIconNameSuffix,
} from '../../constants';

export function getInputFieldIconCssClassForContext(
    icon?: string,
    context = '',
): Record<string, boolean> {
    const cssClasses: Record<string, boolean> = {};

    if (!icon) {
        return cssClasses;
    }

    if (context.length > 0) {
        icon = `${icon}_${context}`;
    }

    cssClasses[`${inputIconCssClassPrefix}${icon}`] = true;

    return cssClasses;
}

export function getInputFieldIconCssClass(
    formControl: FormControl,
    markValid: boolean,
    controlIcon?: string,
): Record<string, boolean> {
    // to NOT render a checkmark instead of a field icon on an empty array value
    const isEmptyArrayValue =
        Array.isArray(formControl.value) && formControl.value.length === 0;
    const icon =
        markValid &&
        formControl.valid &&
        formControl.value &&
        !isEmptyArrayValue
            ? validFilledFieldInputIconNameSuffix
            : controlIcon;
    const context =
        (formControl.touched || formControl.dirty) && formControl.invalid
            ? 'danger'
            : '';

    return getInputFieldIconCssClassForContext(icon, context);
}
