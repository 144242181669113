import { Subscription } from 'rxjs';
import type { OnDestroy } from '@angular/core';
import { Directive } from '@angular/core';
import { unsubscribeIfActive } from '../utils';

@Directive()
export abstract class AbstractDialogContentComponent implements OnDestroy {
    protected subscription: Subscription;

    constructor() {
        this.subscription = new Subscription();
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }
}
