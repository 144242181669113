import type { Observable } from 'rxjs';
import { from, of } from 'rxjs';
import { Client } from '@twilio/conversations';
import { map, switchMap, tap } from 'rxjs/operators';
import type { HttpClient } from '@angular/common/http';
import type { ChatType } from './model';
import { ChatChannelMember } from './model';

export abstract class AbstractChatClient {
    protected client: Client;
    protected chatType: ChatType;

    get userIdentity(): string {
        return this.client.user.identity;
    }

    protected constructor(
        protected http: HttpClient,
        chatType: ChatType,
    ) {
        this.chatType = chatType;
    }

    setChatType(chatType: ChatType): void {
        this.chatType = chatType;
    }

    getClientAsync(updateToken = false): Observable<Client> {
        let client$: Observable<Client>;
        if (this.client) {
            if (updateToken) {
                client$ = this.getAuthToken().pipe(
                    switchMap((token) => from(this.client.updateToken(token))),
                );
            } else {
                client$ = of(this.client);
            }
        } else {
            client$ = this.getAuthToken().pipe(
                switchMap((token) => of(new Client(token))),
                tap((client) => (this.client = client)),
            );
        }

        return client$;
    }

    equalsCurrentUser(identity: string | null): boolean {
        return !!identity && identity === this.userIdentity;
    }

    async shutdown() {
        await this.client?.shutdown();
        this.client = null;
    }

    removeAllClientListeners(): void {
        if (this.client) {
            this.client.removeAllListeners();
        }
    }

    fetchChannelMembers(
        userIds: number[],
        entityId: number,
    ): Observable<ChatChannelMember[]> {
        const params: { [key: string]: string } = {
            chatType: this.chatType,
            userIds: userIds.join(','),
            entityId: `${entityId}`,
        };

        return this.http
            .get<ChatChannelMember[]>(`/api/chat/members`, { params })
            .pipe(
                map((members) => members.map((m) => new ChatChannelMember(m))),
            );
    }

    fetchEmployerChannelMember(
        employerUserId: number,
    ): Observable<ChatChannelMember> {
        return this.http
            .get<ChatChannelMember>(`/api/chat/members/${employerUserId}`)
            .pipe(map((member) => new ChatChannelMember(member)));
    }

    obtainMembership(channelId: string, entityId: number): Observable<string> {
        return this.http.post(
            '/api/chat/members',
            {
                chatType: this.chatType,
                entityId,
                channelId,
            },
            { responseType: 'text' },
        );
    }

    createChatChannel(entityId: number): Observable<string> {
        return this.http.post(
            '/api/chat/channels',
            {
                chatType: this.chatType,
                entityId,
            },
            { responseType: 'text' },
        );
    }

    protected getAuthToken(): Observable<string> {
        return this.http.get('/api/chat/token', {
            headers: { 'Content-Type': 'text/plain' },
            params: { chatType: this.chatType },
            responseType: 'text',
        });
    }
}
