<img
    *ngIf="isPending"
    src="/images/image-preloader.gif"
    alt="Processing password reset request..."
    class="submitting-animation"
/>

<!-- Request a Link -->

<ng-container *ngIf="state === ResetPasswordState.requestLink">
    <h1 class="title">Reset Your Password</h1>
    <p class="message">
        Enter the email address associated with your SquarePeg<br />
        account and we&apos;ll send you a reset link.
    </p>

    <form novalidate [formGroup]="form" (submit)="submit()" class="request-password-reset-form">
        <sp-form-input [control]="control" class="big-b-margin"></sp-form-input>

        <p class="error" *ngIf="error?.genericError">
            We&apos;re unable to reset your password. Please try again later.
        </p>

        <button
            type="submit"
            class="submit-button"
            [disabled]="form.pristine || form.invalid || isPending"
        >
            Send Reset Link
        </button>
    </form>
    <a [routerLink]="['/login', userType]" class="link">Wait, I remember. Take me back!</a>
</ng-container>

<!-- Link Requested Confirmation Message -->

<ng-container *ngIf="state === ResetPasswordState.linkRequested">
    <h1 class="title">Reset Link Sent</h1>

    <p class="message">
        A password reset link has been sent to the email address <br />
        you provided.
    </p>

    <p class="message big-b-margin">
        If you don&apos;t see it in a couple of minutes, check your spam <br />
        folder. It was sent from
        <span class="email">{{ fromEmail }}</span>
    </p>

    <div class="button-wrapper">
        <button type="button" class="submit-button" [routerLink]="['/login', userType]">
            Return to Login
        </button>
    </div>
</ng-container>

<!-- Edit Password -->

<ng-container *ngIf="state === ResetPasswordState.editPassword && token">
    <h1 class="title">Choose New Password</h1>

    <p class="message">
        Your password should include upper and lower case letters, at least one number and be at
        least 10 characters long.
    </p>

    <form novalidate [formGroup]="form" (submit)="submit()" class="edit-password-form">
        <sp-form-input [control]="control" class="big-b-margin"></sp-form-input>

        <p class="error" *ngIf="error?.genericError">
            We&apos;re unable to reset your password. Please try again later.
        </p>

        <ng-container *ngIf="error?.resetTokenValidationError">
            <p class="error">The provided token is invalid.</p>
            <a routerLink="/password/reset" class="link">Click here to request a new one</a>
        </ng-container>

        <button
            type="submit"
            class="submit-button"
            [disabled]="form.pristine || form.invalid || isPending"
        >
            Change Password
        </button>
    </form>
</ng-container>
