import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Quill from 'quill';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import './analytics';
import { TemplateMarker } from './app/shared/forms/custom-controls/template-editor/template-fields';
import { MentionMarker } from './app/shared/custom-textarea/wysiwyg-editor-with-mentions/mention-marker';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

// patch for iOS 11.x DnD scroll issue
// (see this thread for more info https://github.com/metafizzy/flickity/issues/740#issuecomment-380467084)
window.addEventListener(
    'touchmove',
    function (e) {
        const touchTargetClassName = (e.target as HTMLElement).className;
        if (touchTargetClassName.includes('sp__draggable')) {
            e.preventDefault();
        }
    },
    { passive: false },
);

// Register custom Quill Formats
TemplateMarker.blotName = 'TemplateMarker';
TemplateMarker.tagName = 'marker-span';
Quill.register(TemplateMarker);

MentionMarker.blotName = 'MentionMarker';
MentionMarker.tagName = 'mention-marker-span';
Quill.register(MentionMarker);
