export enum MatchBannerColor {
    invited = '#FFC600',
    notViewedReview = '#748CFB',
    incomplete = '#EE2B6F',
    applied = '#32C6BA',
    sample = '#32C6BA',
    withdrew = '#BCCCD4',
    archived = '#BCCCD4',
    disqualified = '#7993A0',
    pendingInProgress = '#f4e516',
    interviewing = '#b7d5ce',
    phoneScreen = '#748cfb',
    pendingOffers = '#ffee00',
    accepted = '#7329f4',
    declined = '#253545',
    closed = '#DBE6EB',
}
