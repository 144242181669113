import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import userflow from 'userflow.js';
import { Subscription } from 'rxjs';
import invariant from 'tiny-invariant';
import type { User } from '../shared/models';
import { UserType } from '../shared/models/user.model';
import { SegmentUserTraits } from '../shared/models/analytics/segment/segment-user-traits.model';
import { unsubscribeIfActive } from '../shared/utils';
import { UserService } from './user.service';
import { EnvironmentProviderService } from './environment-provider.service';

@Injectable({
    providedIn: 'root',
})
export class UserflowService implements OnDestroy {
    isInitialized = false;

    readonly subscription: Subscription = new Subscription();

    private get apiKey(): string | null {
        return this.environmentProvider.environment.config.USERFLOW_API_KEY;
    }

    constructor(
        private userService: UserService,
        private environmentProvider: EnvironmentProviderService,
    ) {
        this.watchUserUpdated();
    }

    ngOnDestroy() {
        unsubscribeIfActive(this.subscription);
    }

    openResourceCenter(): void {
        userflow.openResourceCenter();
    }

    private init(): void {
        invariant(this.apiKey);

        userflow.init(this.apiKey);
        this.isInitialized = true;
    }

    private identify(user: User): void {
        const userTraits: Record<string, any> =
            SegmentUserTraits.forIdentifyEmployer(user);
        const userflowAttributes: Record<string, any> = {};

        Object.keys(userTraits).map((segmentKey) => {
            const userflowKey = segmentKey.replace(/[^0-9a-zA-Z_\-\s]/gi, '');
            userflowAttributes[userflowKey] = userTraits[segmentKey];
        });

        userflow.identify(user.id.toString(), userflowAttributes);
    }

    private reset(): void {
        userflow.reset();
    }

    private watchUserUpdated() {
        this.subscription.add(
            this.userService.user$.subscribe((user) =>
                this.processUserUpdate(user),
            ),
        );
    }

    private processUserUpdate(user: User | null) {
        // This is to prevent failed initializations on envs where integration is not enabled.
        if (!this.apiKey) {
            return;
        }

        if (!user) {
            this.reset();

            return;
        }

        // Currently we only track employer user properties on userflow
        if (user.type !== UserType.employer || !!user?.userImpersonating) {
            return;
        }

        if (!this.isInitialized) {
            this.init();
        }

        this.identify(user);
    }
}
