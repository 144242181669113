<quill-editor
    class="wysiwyg-editor"
    [spAutosize]="!!control.maxHeight"
    [ngClass]="computedCssClasses"
    [class.with-top-border-radius]="true"
    [class.without-label]="!control.label"
    [placeholder]="control.placeholder"
    [formControl]="control.formControl"
    [modules]="control.editorOptions"
    [readOnly]="control.disabled"
    [formats]="supportedFormats"
    [bounds]="'self'"
    theme="snow"
    (onFocus)="onFocus()"
    (onBlur)="onBlur()"
    (keydown)="onKeyDown($event)"
    (onContentChanged)="onContentChanged($event)"
    (onEditorCreated)="onEditorCreated($event)"
>
</quill-editor>
