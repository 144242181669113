import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type {
    ActivatedRouteSnapshot,
    CanActivate,
    Params,
    RouterStateSnapshot,
} from '@angular/router';
import { UserService } from '../core/user.service';
import { LocalStorageService } from '../core/local-storage.service';
import { passThroughSignupQueryParams } from '../core/config.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private router: Router,
        private storageService: LocalStorageService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const unsetQueryParams = this.storePassThroughQueryParams(
            route.queryParams,
        );
        if (Object.keys(unsetQueryParams).length) {
            // unset stored pass-through query parameters on a router to clean up url
            const queryParamsStartIndex = state.url.indexOf('?');
            const url =
                queryParamsStartIndex >= 0
                    ? state.url.substr(0, queryParamsStartIndex)
                    : state.url;
            const queryParams = {
                ...route.queryParams,
                ...unsetQueryParams,
            };
            this.router.navigate([url], {
                queryParams,
                queryParamsHandling: 'merge',
            });
        }

        if (
            this.isEmailVerificationUrl(state) &&
            !route.params.emailVerificationToken
        ) {
            // email verification url must have a verificationToken
            this.router.navigate(['/login/candidate']);

            return false;
        }

        return !this.userService.isAuthenticated();
    }

    private isEmailVerificationUrl(state: RouterStateSnapshot): boolean {
        return (
            state.url.indexOf('signup/verify') > -1 ||
            state.url.indexOf('signup/employer/verify') > -1 ||
            state.url.indexOf('signup/candidate/verify') > -1
        );
    }

    private storePassThroughQueryParams(queryParams: Params) {
        const storedKeys = {};
        const queryParamsKeys = Object.keys(queryParams);

        queryParamsKeys.forEach((paramKey) => {
            if (
                !!queryParams[paramKey] &&
                passThroughSignupQueryParams.includes(paramKey)
            ) {
                this.storageService.storeSessionEntry(
                    paramKey,
                    queryParams[paramKey].trim(),
                );
                storedKeys[paramKey] = null;
            }
        });

        return storedKeys;
    }
}
