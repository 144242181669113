import {
    EmployerConnectedMatchStatus,
    connectedMatchStatusColor,
} from 'app/shared/models/enumeration/employer-connected-match-status.model';
import type { SearchableTableColumn } from '../../../../../../shared/searchable-table/model/table-column.interface';
import { SearchableTableColumnType } from '../../../../../../shared/searchable-table/model/searchable-table-column-type.model';
import type { GenericLabelValue } from '../../../../../../shared/models';

export interface TemplatePlaceholder extends GenericLabelValue<string> {
    needsEscaping?: boolean;
}

export const activeTemplatesTableColumns: SearchableTableColumn[] = [
    {
        header: 'Title',
        width: '420px',
        isSearchable: true,
        isSortable: true,
        type: SearchableTableColumnType.template,
    },
    {
        header: 'Created',
        width: '150px',
        isSortable: true,
        type: SearchableTableColumnType.template,
    },
    {
        header: 'Author',
        width: '100px',
        isSortable: true,
        type: SearchableTableColumnType.template,
    },
    {
        header: 'Last Used',
        width: '150px',
        isSortable: true,
        type: SearchableTableColumnType.template,
    },
];

export const templatePlaceholders: TemplatePlaceholder[] = [
    {
        value: 'candidateFirstName',
        label: 'Candidate First Name',
    },
    {
        value: 'candidateFullName',
        label: 'Candidate Full Name',
    },
    {
        value: 'candidateEmailAddress',
        label: 'Candidate Email Address',
    },
    {
        value: 'jobTitle',
        label: 'Job Title',
    },
    {
        value: 'companyName',
        label: 'Company Name',
    },
    {
        value: 'companyUserFirstName',
        label: 'Company User First Name',
    },
    {
        value: 'companyUserFullName',
        label: 'Company User Full Name',
    },
    {
        value: 'companyUserPosition',
        label: 'Company User Position',
    },
    {
        value: 'jobApplyLink',
        label: 'Job Apply Link',
        needsEscaping: true,
    },
];

export const templatesAdditionalMatchStatusColorsMap = {
    unscreened: '#cce8ff',
    moveToAnotherJob: '#bcccd4',
    archive: '#bcccd4',
    withdrew: connectedMatchStatusColor[EmployerConnectedMatchStatus.declined],
};
