<sp-dropdown
    *ngIf="allowConstraintChange; else preview"
    [appendToBody]="true"
    [menuData]="dropdownItems"
    [disabled]="disabled"
    [dropdownMenuClass]="'chip-with-constraint'"
    [offsetLeftAdjust]="offsetLeftAdjust"
    [offsetTopAdjust]="offsetTopAdjust"
    [currentOption]="model.constraint"
    (menuItemClick)="onActionSelected($event)"
>
    {{ model.value }}
</sp-dropdown>

<ng-template #preview>
    <span class="marker"></span><span [title]="model.value">{{ model.value }}</span>
</ng-template>
