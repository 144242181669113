import type { User } from '../../user.model';
import { IntegrationVendor } from '../../integrations/integration.model';

enum UserType {
    candidate = 'Candidate',
    employer = 'Employer',
}

export enum FreeTrialStatus {
    active = 'Active',
    expired = 'Expired',
}

export enum SegmentUserTrait {
    // Candidate traits
    candidateId = 'Candidate ID',
    candidateEmail = 'Candidate Email',
    candidateName = 'Candidate Name',
    candidateResumeUploaded = 'Candidate Resume Uploaded',
    candidateProfileComplete = 'Candidate Profile Complete',
    candidateAssessmentComplete = 'Candidate Assessment Complete',
    matchesGenerated = 'Matches Generated',
    inviteToApplyOptIn = 'Invite to Apply Opt-In',
    // Employer traits
    employerUserId = 'Employer User ID',
    employerUserEmail = 'Employer User Email',
    employerUserName = 'Employer User Name',
    employerName = 'Employer Name',
    employerId = 'Employer ID',
    employerSlackConnected = 'Slack Connected',
    employerJobPublished = 'Job Published',
    employerActionedCandidate = 'Candidate Actioned',
    employerDaysRemainingInTrial = 'Days Remaining in Trial',
    employerFreeTrialStatus = 'Free Trial Status',
    employerLinkedInConnected = 'LinkedIn Connected',
    // Shared user traits
    userType = 'User Type',
    impersonation = 'Impersonation',
    // User traits that are being updated via Search app and may be stored in the browser, so need resetting.
    adminUserId = 'Admin User ID',
    adminUserEmail = 'Admin User Email',
    adminUserName = 'Admin User Name',
}

export class SegmentUserTraits {
    userId: number;
    $first_name: string;
    $last_name: string;
    [SegmentUserTrait.candidateId]?: number;
    [SegmentUserTrait.candidateEmail]?: string;
    [SegmentUserTrait.candidateName]?: string;
    [SegmentUserTrait.candidateResumeUploaded]?: boolean;
    [SegmentUserTrait.candidateProfileComplete]?: boolean;
    [SegmentUserTrait.candidateAssessmentComplete]?: boolean;
    [SegmentUserTrait.matchesGenerated]?: boolean;
    [SegmentUserTrait.inviteToApplyOptIn]?: boolean;
    [SegmentUserTrait.employerUserId]?: number;
    [SegmentUserTrait.employerUserEmail]?: string;
    [SegmentUserTrait.employerUserName]?: string;
    [SegmentUserTrait.employerName]?: string;
    [SegmentUserTrait.employerId]?: number;
    [SegmentUserTrait.employerSlackConnected]?: boolean;
    [SegmentUserTrait.employerJobPublished]?: boolean;
    [SegmentUserTrait.employerActionedCandidate]?: boolean;
    [SegmentUserTrait.employerDaysRemainingInTrial]?: number;
    [SegmentUserTrait.employerFreeTrialStatus]?: FreeTrialStatus;
    [SegmentUserTrait.employerLinkedInConnected]?: boolean;
    [SegmentUserTrait.userType]?: UserType;
    [SegmentUserTrait.impersonation]?: boolean;
    [SegmentUserTrait.adminUserId]: undefined;
    [SegmentUserTrait.adminUserEmail]: undefined;
    [SegmentUserTrait.adminUserName]: undefined;

    public static forIdentifyCandidate(user: User): SegmentUserTraits {
        return {
            userId: user.id,
            $first_name: user.firstName,
            $last_name: user.lastName,
            [SegmentUserTrait.candidateId]: user.id,
            [SegmentUserTrait.candidateEmail]: user.email,
            [SegmentUserTrait.candidateName]: user.fullName,
            [SegmentUserTrait.candidateResumeUploaded]:
                !!user.hasUploadedResume,
            [SegmentUserTrait.candidateProfileComplete]:
                !!user.isProfileComplete,
            [SegmentUserTrait.candidateAssessmentComplete]:
                !!user.completedAllAssessments,
            [SegmentUserTrait.matchesGenerated]: user.generatedMatchesCount > 1,
            [SegmentUserTrait.inviteToApplyOptIn]: !!user.inviteToApplyOptIn,
            [SegmentUserTrait.userType]: UserType.candidate,
            [SegmentUserTrait.impersonation]: !!user.userImpersonating,
            // Unset Employer user properties
            [SegmentUserTrait.employerUserId]: undefined,
            [SegmentUserTrait.employerUserEmail]: undefined,
            [SegmentUserTrait.employerUserName]: undefined,
            [SegmentUserTrait.employerName]: undefined,
            [SegmentUserTrait.employerId]: undefined,
            [SegmentUserTrait.employerSlackConnected]: undefined,
            [SegmentUserTrait.employerJobPublished]: undefined,
            [SegmentUserTrait.employerActionedCandidate]: undefined,
            [SegmentUserTrait.employerDaysRemainingInTrial]: undefined,
            [SegmentUserTrait.employerFreeTrialStatus]: undefined,
            [SegmentUserTrait.employerLinkedInConnected]: undefined,
            // Unset Admin user properties
            [SegmentUserTrait.adminUserId]: undefined,
            [SegmentUserTrait.adminUserEmail]: undefined,
            [SegmentUserTrait.adminUserName]: undefined,
        };
    }

    public static forIdentifyEmployer(user: User): SegmentUserTraits {
        return {
            userId: user.id,
            $first_name: user.firstName,
            $last_name: user.lastName,
            [SegmentUserTrait.employerUserId]: user.id,
            [SegmentUserTrait.employerUserEmail]: user.email,
            [SegmentUserTrait.employerUserName]: user.fullName,
            [SegmentUserTrait.employerName]: user.employer.employerName,
            [SegmentUserTrait.employerId]: user.employer.id,
            [SegmentUserTrait.employerSlackConnected]:
                user.employer.integrations?.some(
                    (integration) =>
                        integration.vendor === IntegrationVendor.slack &&
                        integration.connected,
                ),
            [SegmentUserTrait.employerJobPublished]:
                !!user.employer.hasEverPublishedAJob,
            [SegmentUserTrait.employerActionedCandidate]:
                user.employer.hasActionedCandidates,
            [SegmentUserTrait.employerDaysRemainingInTrial]:
                user.employer.daysRemainingInTrial,
            [SegmentUserTrait.employerFreeTrialStatus]:
                user.employer.daysRemainingInTrial > 0
                    ? FreeTrialStatus.active
                    : FreeTrialStatus.expired,
            [SegmentUserTrait.employerLinkedInConnected]:
                !!user.employer.linkedInCompanyId,
            [SegmentUserTrait.userType]: UserType.employer,
            [SegmentUserTrait.impersonation]: !!user.userImpersonating,
            // Unset Candidate user properties
            [SegmentUserTrait.candidateId]: undefined,
            [SegmentUserTrait.candidateEmail]: undefined,
            [SegmentUserTrait.candidateName]: undefined,
            [SegmentUserTrait.matchesGenerated]: undefined,
            [SegmentUserTrait.inviteToApplyOptIn]: undefined,
            [SegmentUserTrait.candidateResumeUploaded]: undefined,
            [SegmentUserTrait.candidateProfileComplete]: undefined,
            [SegmentUserTrait.candidateAssessmentComplete]: undefined,
            // Unset Admin user properties
            [SegmentUserTrait.adminUserId]: undefined,
            [SegmentUserTrait.adminUserEmail]: undefined,
            [SegmentUserTrait.adminUserName]: undefined,
        };
    }
}
