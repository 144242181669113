import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import type {
    UploadFile,
    UploadInput,
    UploadOutput,
    UploaderOptions,
} from 'ngx-uploader';
import { UploadStatus } from 'ngx-uploader';
import { UploadType } from './upload-file.type';

@Component({
    selector: 'sp-file-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss'],
})
export class UploadComponent {
    @Input() uploadType: UploadType;
    @Input() uploadTypeLabel: string;
    @Input() uploadUrl: string;
    @Input() uploadRequestParams: { [key: string]: string };
    @Input() isDisabled: boolean;
    @Input() displayBoxLoadingIndicator: boolean;

    @Input() dropAreaMessage: string;
    @Input() dropAreaFileTypeMessage: string;
    @Input() processingMessage: string;
    @Input() errorMessage: string;
    @Input() type: 'normal' | 'small' = 'normal';

    @Output() uploadOutput: EventEmitter<UploadOutput> =
        new EventEmitter<UploadOutput>();

    @ViewChild('browseButton', { read: ElementRef })
    browseButtonRef: ElementRef<HTMLElement>;

    @ViewChild('uploader', { read: ElementRef, static: true })
    uploader: ElementRef<HTMLInputElement>;

    readonly progressBackgroundColor = 'rgba(116,140,251,0.08)';

    options: UploaderOptions;
    file: UploadFile;
    uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
    error: boolean;
    isUploaded = false;
    inProgress: boolean;
    isErrorState: boolean;

    percentsCompletedWithSteps = 0;
    secondStepIntervalMs = 50; // 10% per half a second = 50ms
    lastStepIntervalMs = 20; // 25% per half a second = 20ms

    @HostBinding('class.drag-over')
    dragOver: boolean;

    @HostBinding('class.small')
    get isSmall() {
        return this.type === 'small';
    }

    sleep = (ms) => new Promise((res) => setTimeout(res, ms));
    secondStepFunction = (val: number): boolean => val >= 50 && val < 90;
    lastStepFunction = (val: number): boolean => val < 100;

    get success() {
        return (
            this.file &&
            this.file.progress.status === UploadStatus.Done &&
            !('error' in this.file.response && this.file.response.error)
        );
    }

    async onUploadOutput(output: UploadOutput) {
        if (output.type === 'allAddedToQueue') {
            this.isErrorState = false;
            const event: UploadInput = {
                headers: {
                    'X-AUTH-TOKEN': sessionStorage.getItem('SquarePeg_token'),
                },
                type: 'uploadFile',
                url: this.uploadUrl,
                method: 'POST',
                file: this.file,
                data: {
                    type: this.uploadType,
                    ...this.uploadRequestParams,
                },
            };

            this.uploadInput.emit(event);
        } else if (
            output.type === 'addedToQueue' &&
            typeof output.file !== 'undefined'
        ) {
            this.dragOver = false;
            this.file = output.file;
        } else if (
            output.type === 'done' &&
            typeof output.file !== 'undefined'
        ) {
            // we need to smoothly finish progress bar
            await this.processNextStep(
                this.lastStepIntervalMs,
                this.lastStepFunction,
            );
            this.inProgress = false;

            this.file = output.file;
            if (this.success) {
                this.isUploaded = true;
            } else {
                this.isErrorState = true;
                this.file = null;
                this.uploader.nativeElement.value = null;
            }
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'cancelled') {
            this.isUploaded = false;
            this.uploader.nativeElement.value = null;
        } else if (output.type === 'uploading') {
            this.inProgress = !!this.file;
            // we need to calculate progress bar percentage with dividing by 2
            // so first half of the progress bar (0 - 50%) is actual upload, while rest of progress bar (50% - 100%)
            // is time for processing the file
            this.percentsCompletedWithSteps = Math.floor(
                this.file.progress.data.percentage / 2,
            );
            if (this.file.progress.data.percentage === 100) {
                // once file upload finish, we increase progress bar at a steady pace until third step threshold,
                // where progress bar is going to stop and wait or file to be processed
                await this.processNextStep(
                    this.secondStepIntervalMs,
                    this.secondStepFunction,
                );
            }
        }

        this.uploadOutput.emit(output);
    }

    // workaround to force opening 'Browse File' dialog upon click on drop-area (in addition to Browse button)
    onDropAreaClick() {
        if (!this.browseButtonRef || this.inProgress || this.isDisabled) {
            return;
        }

        this.browseButtonRef.nativeElement.click();
    }

    private async processNextStep(
        intervalMs: number,
        shouldIncrementFn: (number) => boolean,
    ) {
        while (shouldIncrementFn(this.percentsCompletedWithSteps)) {
            this.percentsCompletedWithSteps++;
            await this.sleep(intervalMs);
        }
    }
}
