import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import type {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpInterceptor as Ng2HttpInterceptor,
} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NotificationService } from '../shared/notification/notification.service';
import { ColorContext } from '../shared/models';
import { isDefined, isEntityVersionCheckError } from '../shared/utils';
import { UserService } from './user.service';
import { entityVersionCheckError } from './messages';

@Injectable()
export class HttpInterceptor implements Ng2HttpInterceptor {
    constructor(
        private userService: UserService,
        private notificationService: NotificationService,
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const url = this.updateUrl(req.url);

        // The auth lib used in the app setups HttpInterceptor that adds a custom auth header
        // to each request. With CORS all the custom requests are going to be sent to a
        // remote host causing an error if the response header 'access-control-allow-headers'
        // does not contain a custom header which is indeed the case with third-party API
        // services which are unaware of your custom headers
        if (this.shouldSkipCustomHeaders(url)) {
            const headerlessReq = req.clone({
                headers: new HttpHeaders({}),
            });

            return next.handle(headerlessReq);
        }

        const authReq = req.clone({ url });

        return next.handle(authReq).pipe(
            tap(
                () => {},
                (event: HttpEvent<any>) => {
                    if (event['status'] && !this.skipSignOut(event)) {
                        if (event['status'] === 401) {
                            if (isDefined(event['error'])) {
                                const error = this.getErrorFromEvent(
                                    event['error'],
                                );
                                this.showNotification(error.message);
                            }

                            this.userService.signOut();
                        }

                        if (isEntityVersionCheckError(event)) {
                            this.showNotification(entityVersionCheckError);
                        }
                    }
                },
            ),
        );
    }

    private updateUrl(url: string) {
        return url.startsWith('http') ? url : environment.config.API_URL + url;
    }

    private skipSignOut(event) {
        if (event['url']) {
            const url = event['url'] as string;

            return (
                url.includes('/auth/') ||
                url.includes('/employer/connect-pass/')
            );
        }
    }

    private shouldSkipCustomHeaders(url): boolean {
        return url.indexOf(environment.config.API_URL) < 0;
    }

    private getErrorFromEvent(error: any) {
        return typeof error === 'string' ? JSON.parse(error) : error;
    }

    private showNotification(
        message: string,
        colorContext: ColorContext = ColorContext.WARNING,
    ) {
        this.notificationService.show({ message, colorContext });
    }
}
