export enum EmployerConnectedMatchStatus {
    approved = 'approved',
    invited = 'invited',
    withdrawnReviewed = 'withdrawnReviewed',

    pendingContacted = 'pendingContacted',
    responded = 'responded',
    noResponse = 'noResponse',
    withdrawnContacted = 'withdrawnContacted',

    scheduled = 'scheduled',
    phoneScreen = 'phoneScreen',
    interview = 'interview',
    disqualified = 'disqualified',
    withdrawnInterviewing = 'withdrawnInterviewing',

    accepted = 'accepted',
    pending = 'pending',
    declined = 'declined',
}

export const employerConnectedMatchStatusLabels = {
    approved: 'Approved',
    invited: 'Invited',
    withdrawnReviewed: 'Withdrew',
    pendingContacted: 'Pending',
    responded: 'Responded',
    noResponse: 'No Response',
    withdrawnContacted: 'Withdrew',
    scheduled: 'Scheduled',
    phoneScreen: 'Phone Screen',
    interview: 'Interview',
    disqualified: 'Disqualified',
    withdrawnInterviewing: 'Withdrew',
    accepted: 'Accepted',
    pending: 'Pending',
    declined: 'Declined',
};

export const employerConnectedMatchStatusForThreadsLabels = {
    approved: 'Reviewed: Approved',
    unscreened: 'In Review',
    invited: 'Reviewed: Invited',
    withdrawnReviewed: 'Reviewed: Withdrew',
    pendingContacted: 'Contacted: Pending',
    responded: 'Contacted: Responded',
    noResponse: 'Contacted: No Response',
    withdrawnContacted: 'Contacted: Withdrew',
    scheduled: 'Interviewing: Scheduled',
    phoneScreen: 'Interviewing: Phone Screen',
    interview: 'Interviewing: Interview',
    disqualified: 'Interviewing: Disqualified',
    withdrawnInterviewing: 'Interviewing: Withdrew',
    accepted: 'Offers: Accepted',
    pending: 'Offers: Pending',
    declined: 'Offers: Declined',
};

export const connectedMatchStatusColor = {
    approved: '#32c6ba',
    invited: '#ffc600',
    withdrawnReviewed: '#e0eaee',
    pendingContacted: '#ffee00',
    responded: '#73befe',
    noResponse: '#f8aac4',
    withdrawnContacted: '#e0eaee',
    scheduled: '#a9b8fc',
    phoneScreen: '#748cfb',
    interview: '#b7d5ce',
    disqualified: '#7993a0',
    withdrawnInterviewing: '#e0eaee',
    accepted: '#7329f4',
    pending: '#ffee00',
    declined: '#7A8691',
};

export const connectedMatchStatusOrder = [
    EmployerConnectedMatchStatus.approved,
    EmployerConnectedMatchStatus.invited,
    EmployerConnectedMatchStatus.withdrawnReviewed,

    EmployerConnectedMatchStatus.pendingContacted,
    EmployerConnectedMatchStatus.responded,
    EmployerConnectedMatchStatus.noResponse,
    EmployerConnectedMatchStatus.withdrawnContacted,

    EmployerConnectedMatchStatus.scheduled,
    EmployerConnectedMatchStatus.phoneScreen,
    EmployerConnectedMatchStatus.interview,
    EmployerConnectedMatchStatus.disqualified,
    EmployerConnectedMatchStatus.withdrawnInterviewing,

    EmployerConnectedMatchStatus.pending,
    EmployerConnectedMatchStatus.accepted,
    EmployerConnectedMatchStatus.declined,
];

export function byEmployerMatchConnectedStatusComparator(
    a: { connectedMatchStatus: EmployerConnectedMatchStatus },
    b: { connectedMatchStatus: EmployerConnectedMatchStatus },
) {
    return (
        connectedMatchStatusOrder.indexOf(a.connectedMatchStatus) -
        connectedMatchStatusOrder.indexOf(b.connectedMatchStatus)
    );
}
