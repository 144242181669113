import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, Subject, Subscription, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import type { RequiredActionInfoItem } from '../shared/required-action-info/required-action-info.component';
import type { CachedResponseData } from '../shared/models/cached-response-data.interface';
import { isCacheValid } from '../shared/models/cached-response-data.interface';
import type { RequiredActionInfo } from '../shared/models/required-action-info.model';
import { mapToRequiredActionItem } from '../shared/required-action-info/required-action-info.utils';
import { unsubscribeIfActive } from '../shared/utils';
import { RequiredActionInfoType } from '../shared/models/required-action-info-type.model';
import type { MatchEmployer } from '../shared/models';
import {
    daysBetweenDates,
    isWithinHoursBeforeNow,
    toDate,
} from '../shared/date-utils';
import {
    EmployerConnectedMatchStatus,
    employerConnectedMatchStatusForThreadsLabels,
} from '../shared/models/enumeration/employer-connected-match-status.model';
import { getRequiredActionsInfoSessionKey } from './config.service';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';

export const minDaysPendingReview = 2;

const maxHoursConnectedStatusNotChanged = 48;
const connectedStatusesNotTakenIntoAccount =
    new Set<EmployerConnectedMatchStatus>([
        EmployerConnectedMatchStatus.declined,
        EmployerConnectedMatchStatus.disqualified,
        EmployerConnectedMatchStatus.noResponse,
        EmployerConnectedMatchStatus.accepted,
        EmployerConnectedMatchStatus.withdrawnContacted,
        EmployerConnectedMatchStatus.withdrawnReviewed,
        EmployerConnectedMatchStatus.withdrawnInterviewing,
    ]);
const requiredActionsRefreshIntervalMs = 30 * 60 * 1000; // 30 minutes

export enum RequiredActionEventType {
    responseFromEmployer = 'responseFromEmployer',
    connectPass = 'connectPass',
    firstContact = 'firstContact',
}

export interface RequiredActionEvent {
    type: RequiredActionEventType;
    matchId: number;
    jobId: number;
}

@Injectable()
export class RequiredActionsService implements OnDestroy {
    private readonly subscription: Subscription;
    private requiredActionSubscription: Subscription;
    private userId: number;
    private invalidateRequiredAction$ = new Subject<RequiredActionEvent>();
    private cachedRequiredActions: CachedResponseData<RequiredActionInfo[]>;

    requiredActionsInfo$ = new BehaviorSubject<RequiredActionInfoItem[]>(null);
    loadingRequiredActionsInfo = true;

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private userService: UserService,
    ) {
        this.subscription = new Subscription();
        this.watchInvalidateRequiredActions();
        this.watchUserLoggingOut();
        this.userId = this.userService.user.id;
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription, this.requiredActionSubscription);
    }

    refreshRequiredActions(): void {
        this.requiredActionSubscription = this.getRequiredActions().subscribe(
            (data) => {
                this.requiredActionsInfo$.next(
                    data.map(mapToRequiredActionItem),
                );
            },
        );
    }

    resetRequiredActions() {
        if (
            !isCacheValid(
                this.cachedRequiredActions,
                requiredActionsRefreshIntervalMs,
            )
        ) {
            this.requiredActionsInfo$.next(null);
            this.loadingRequiredActionsInfo = true;
        }
    }

    invalidateRequiredActionsAsync(
        matchId: number,
        jobId: number,
        type: RequiredActionEventType,
    ) {
        this.invalidateRequiredAction$.next({ type, matchId, jobId });
    }

    getRequiredActions(): Observable<RequiredActionInfo[]> {
        // if cached required actions are valid, simply return them instead of making backend call
        if (
            !isCacheValid(
                this.cachedRequiredActions,
                requiredActionsRefreshIntervalMs,
            )
        ) {
            this.resetRequiredActions();

            return this.http
                .get<RequiredActionInfo[]>(`/api/jobs/required-actions`)
                .pipe(
                    tap((requiredActionsInfo) => {
                        this.cachedRequiredActions = {
                            lastFetchedAt: new Date(),
                            data: requiredActionsInfo,
                        };
                    }),
                    finalize(() => (this.loadingRequiredActionsInfo = false)),
                );
        }

        return of(this.cachedRequiredActions.data);
    }

    getRequiredActionForStatusNotChanged(match: MatchEmployer): string {
        let requiredAction = null;

        if (match.connectedMatchStatus) {
            // In-progress tab
            if (
                !isWithinHoursBeforeNow(
                    match.employerConnectedStatusLastModifiedDate,
                    maxHoursConnectedStatusNotChanged,
                ) &&
                this.isValidConnectedStatus(match.connectedMatchStatus)
            ) {
                const numOfDays = daysBetweenDates(
                    match.employerConnectedStatusLastModifiedDate,
                    toDate(new Date()),
                );
                requiredAction =
                    `${match.candidateName} has been waiting in ` +
                    `${
                        employerConnectedMatchStatusForThreadsLabels[
                            match.connectedMatchStatus
                        ]
                    } for ${numOfDays} days.`;
            }
        } else if (
            !match.employerAction &&
            !match.isUnscreened &&
            match.approvalDate
        ) {
            // Applications tab
            if (
                !isWithinHoursBeforeNow(
                    match.approvalDate,
                    maxHoursConnectedStatusNotChanged,
                )
            ) {
                const numOfDays = daysBetweenDates(
                    match.approvalDate,
                    toDate(new Date()),
                );
                const status = match.isViewedByEmployer
                    ? 'Applications: In Review'
                    : 'Applications: New';
                requiredAction = `${match.candidateName} has been waiting in ${status} for ${numOfDays} days.`;
            }
        }

        return requiredAction;
    }

    getDismissedRequiredActionIds(): string[] {
        const key = getRequiredActionsInfoSessionKey(this.userId);

        return this.localStorageService.hasSessionEntry(key)
            ? JSON.parse(this.localStorageService.getSessionEntry(key))
            : [];
    }

    filterOutDismissedActions(
        requiredActionInfoItems: RequiredActionInfoItem[],
    ): RequiredActionInfoItem[] {
        const dismissedIds = new Set(this.getDismissedRequiredActionIds());

        return requiredActionInfoItems?.filter(
            (item) => !dismissedIds?.has(item.id),
        );
    }

    private watchInvalidateRequiredActions() {
        this.subscription.add(
            this.invalidateRequiredAction$.subscribe((action) =>
                this.invalidateRequiredAction(action),
            ),
        );
    }

    private invalidateRequiredAction(action: RequiredActionEvent) {
        this.subscription.add(
            // be sure we update cached required actions if not valid anymore before invalidation
            this.getRequiredActions().subscribe(() => {
                switch (action.type) {
                    case RequiredActionEventType.connectPass:
                        this.invalidateConnectPassEvent(action);

                        break;
                    case RequiredActionEventType.firstContact:
                        this.invalidateFirstContactEvent(action);

                        break;
                    case RequiredActionEventType.responseFromEmployer:
                        this.invalidateResponseFromEmployer(action);

                        break;
                    default:
                        break;
                }
            }),
        );
    }

    // On connect/pass action we need to invalidate pendingReviewCandidate and pendingReviewPerJob required actions
    private invalidateConnectPassEvent(action: RequiredActionEvent) {
        const requiredActions = this.cachedRequiredActions.data;

        // if cached requiredActions contains pendingReviewCandidate required action we need to:
        // 1. decrement count from pendingReviewPerJob
        // 2. remove pendingReviewCandidate action if exists
        // 3. update cached required actions

        // decrement count from pendingReviewPerJob
        const requiredActionIndex = requiredActions.findIndex(
            (item) =>
                item.type === RequiredActionInfoType.pendingReviewPerJob &&
                item.employerJobId === action.jobId,
        );
        if (requiredActionIndex > -1) {
            this.decrementRequiredActionCount(
                requiredActions,
                requiredActionIndex,
            );
        }

        // remove pendingReviewCandidate action if exists
        const pendingReviewCandidateIndex = requiredActions.findIndex(
            (item) =>
                item.type === RequiredActionInfoType.pendingReviewCandidate &&
                item.matchId === action.matchId,
        );
        if (pendingReviewCandidateIndex > -1) {
            requiredActions.splice(pendingReviewCandidateIndex, 1);
        }

        this.updateCachedRequiredActions(requiredActions);
    }

    // On contactFirst action we need to invalidate only notContactedCandidatesPerJob required action
    private invalidateFirstContactEvent(action: RequiredActionEvent) {
        const requiredActions = this.cachedRequiredActions.data;
        const notContactedCandidatesIndex = requiredActions.findIndex(
            (item) =>
                item.type ===
                    RequiredActionInfoType.notContactedCandidatesPerJob &&
                item.employerJobId === action.jobId,
        );

        // if cached requiredActions contains notContactedCandidatesPerJob required action we need to:
        // 1. update count of notContactedCandidatesPerJob action
        // 2. update cached required actions
        this.decrementRequiredActionCount(
            requiredActions,
            notContactedCandidatesIndex,
        );
        this.updateCachedRequiredActions(requiredActions);
    }

    // On responseFromEmployer action we need to invalidate
    // requireResponseCandidateTotal and requireResponseCandidatePerJob required actions
    private invalidateResponseFromEmployer(action: RequiredActionEvent) {
        const requiredActions = this.cachedRequiredActions.data;
        const requireResponseCandidatePerJobIndex = requiredActions.findIndex(
            (item) =>
                item.type ===
                    RequiredActionInfoType.requireResponseCandidatePerJob &&
                item.employerJobId === action.jobId,
        );

        // if cached requiredActions contains requireResponseCandidatePerJob required action we need to:
        // 1. reduce count from requireResponseCandidatePerJob
        // 2. reduce count from requireResponseCandidateTotal
        // 3. update cached required actions
        if (requireResponseCandidatePerJobIndex > -1) {
            this.decrementRequiredActionCount(
                requiredActions,
                requireResponseCandidatePerJobIndex,
            );

            const requiredActionIndex = requiredActions.findIndex(
                (item) =>
                    item.type ===
                    RequiredActionInfoType.requireResponseCandidateTotal,
            );
            this.decrementRequiredActionCount(
                requiredActions,
                requiredActionIndex,
            );
            this.updateCachedRequiredActions(requiredActions);
        }
    }

    private decrementRequiredActionCount(
        data: RequiredActionInfo[],
        index: number,
    ): void {
        if (index > -1) {
            const requiredAction = data[index];
            if (requiredAction) {
                if (requiredAction.count === 1) {
                    data.splice(index, 1);
                } else {
                    requiredAction.count -= 1;
                    data[index] = requiredAction;
                }
            }
        }
    }

    private updateCachedRequiredActions(requiredActions: RequiredActionInfo[]) {
        this.cachedRequiredActions = {
            lastFetchedAt: this.cachedRequiredActions.lastFetchedAt,
            data: requiredActions,
        };
        this.requiredActionsInfo$.next(
            this.cachedRequiredActions.data.map(mapToRequiredActionItem),
        );
    }

    private isValidConnectedStatus(
        connectedMatchStatus: EmployerConnectedMatchStatus,
    ) {
        return !connectedStatusesNotTakenIntoAccount.has(connectedMatchStatus);
    }

    private watchUserLoggingOut() {
        this.subscription.add(
            this.userService.userLogout$.subscribe(
                () => (this.cachedRequiredActions = null),
            ),
        );
    }
}
