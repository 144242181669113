import type { EmployerConnectedMatchStatus } from '../models/enumeration/employer-connected-match-status.model';
import { employerConnectedMatchStatusForThreadsLabels } from '../models/enumeration/employer-connected-match-status.model';

export enum ApplicationViewedStatus {
    applicationsNew = 'applicationsNew',
    applicationsInReview = 'applicationsInReview',
}

export type ApplicantNotificationsType =
    | EmployerConnectedMatchStatus
    | ApplicationViewedStatus;
export const applicationViewedStatusLabels = {
    applicationsNew: 'Applications: New',
    applicationsInReview: 'Applications: In Review',
};
export const applicantNotificationsLabels = {
    ...employerConnectedMatchStatusForThreadsLabels,
    ...applicationViewedStatusLabels,
};
