import { DropdownDataAction } from '../sp-dropdown/sp-dropdown.constants';
import type { SideBarItem } from './tabbed-sidebar/tabbed-sidebar-item.interface';

export enum SidebarTabIds {
    tabChat = 'tabChat',
    tabCommentsChat = 'tabCommentsChat',
    tabActivity = 'tabActivity',
    tabJobInfo = 'tabJobInfo',
    tabMessagesChat = 'tabMessagesChat',
    tabProfile = 'tabProfile',
    requiredActions = 'requiredActions',
    tabCampaign = 'tabCampaign',
}

export const dropdownActionToSidebarTabId = {
    [DropdownDataAction.viewInfo]: SidebarTabIds.tabJobInfo,
    [DropdownDataAction.viewDiscussion]: SidebarTabIds.tabCommentsChat,
    [DropdownDataAction.viewRequiredActions]: SidebarTabIds.requiredActions,
    [DropdownDataAction.viewActivity]: SidebarTabIds.tabActivity,
    [DropdownDataAction.viewMessages]: SidebarTabIds.tabMessagesChat,
};

const messageTab = (title: string) => ({
    id: SidebarTabIds.tabMessagesChat,
    title,
    icon: 'chat',
    enabled: true,
});

const commentsTab = (title: string) => ({
    id: SidebarTabIds.tabCommentsChat,
    title,
    icon: 'message',
    enabled: true,
});

const activityTab = (title: string) => ({
    id: SidebarTabIds.tabActivity,
    title,
    icon: 'activity',
    enabled: true,
});

const jobInfoTab = (title: string, headerLabel?: string) => ({
    id: SidebarTabIds.tabJobInfo,
    title,
    icon: 'job-info',
    headerLabel,
    showDivider: true,
    enabled: true,
});

const jobCampaignTab = (title: string) => ({
    id: SidebarTabIds.tabCampaign,
    title,
    icon: 'megaphone',
    enabled: true,
    feature: 'sourcing-campaigns',
});

const tabRequiredActions: SideBarItem = {
    id: SidebarTabIds.requiredActions,
    title: 'Required Actions',
    icon: 'flag',
    enabled: true,
};

const tabProfile = (title: string, headerLabel?: string) => ({
    id: SidebarTabIds.tabProfile,
    title,
    icon: 'profile',
    headerLabel,
    showDivider: false,
    enabled: true,
});

export const sidebarJobTabItems: SideBarItem[] = [
    jobInfoTab('Job information', 'Edit this job'),
    commentsTab('Job Discussion'),
    jobCampaignTab('Sourcing Campaign'),
    tabRequiredActions,
    activityTab('Job Activity'),
];

export const sidebarArchivedJobTabItems: SideBarItem[] = [
    jobInfoTab('Job information'),
    commentsTab('Job Discussion'),
    jobCampaignTab('Sourcing Campaign'),
    activityTab('Job Activity'),
];

export const sidebarMatchDetailsTabItems: SideBarItem[] = [
    jobInfoTab('Job information', 'Edit this job'),
    messageTab('Candidate Messages'),
    commentsTab('Candidate Discussion'),
    activityTab('Candidate Activity'),
];

export function getJobTabItems(includeRequiredActions: boolean): SideBarItem[] {
    return includeRequiredActions
        ? sidebarJobTabItems
        : sidebarArchivedJobTabItems;
}

export function getSidebarTabItemsMessaging(withResume: boolean) {
    return [
        tabProfile('Candidate Profile', withResume ? 'Download resume' : null),
        commentsTab('Candidate Discussion'),
        activityTab('Candidate Activity'),
        jobInfoTab('Job information', 'Edit this job'),
    ];
}
