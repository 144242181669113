<div *ngIf="avatar && !showTooltip" class="avatar">
    <img [src]="avatar" (error)="imageNotLoaded()" />
</div>

<div *ngIf="avatar && showTooltip" class="avatar" spTooltip [tooltipText]="userName">
    <img [src]="avatar" (error)="imageNotLoaded()" />
</div>

<div *ngIf="showInitials && !showTooltip" class="empty-avatar" [class.borderless]="borderless">
    {{ initials }}
</div>
<div
    *ngIf="showInitials && showTooltip"
    class="empty-avatar"
    [class.borderless]="borderless"
    spTooltip
    [tooltipText]="tooltipText"
>
    {{ initials }}
</div>
