export type SubscriptionStatus = 'trialing' | 'active' | 'canceled';

export class Subscription {
    id?: number;
    email?: string;
    productName: string;
    promotionalCode: string;
    priceCharged: number;
    productLabel: string;
    nextBillingDate: Date;
    trialEndDate?: Date;
    status: SubscriptionStatus;

    public get isActive(): boolean {
        return this.status === 'active';
    }

    public get isCanceled(): boolean {
        return this.status === 'canceled';
    }

    public get isTrial(): boolean {
        return this.trialEndDate && this.status === 'trialing';
    }

    public get isTrialActive(): boolean {
        return (
            this.isTrial &&
            new Date(this.trialEndDate).getTime() > new Date().getTime()
        );
    }

    public get isTrialExpired(): boolean {
        return (
            this.isTrial &&
            new Date(this.trialEndDate).getTime() < new Date().getTime()
        );
    }
}
