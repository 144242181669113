import { Component, Input } from '@angular/core';
import { SpIconButtonsGroupControls } from '../forms/custom-controls/sp-form-controls.model';

@Component({
    selector: 'sp-icon-buttons-group-control[control]',
    templateUrl: './icon-buttons-group-control.component.html',
    styleUrls: ['./icon-buttons-group-control.component.scss'],
})
export class IconButtonsGroupControlComponent<T> {
    @Input() control!: SpIconButtonsGroupControls<T>;

    get invalid() {
        return (
            this.control.formControl.touched && this.control.formControl.invalid
        );
    }
}
