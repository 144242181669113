import type { OnInit } from '@angular/core';
import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import type { Options } from '@angular-slider/ngx-slider';
import { CompensationUnit } from '../unit-selector/compensation-unit-selector/compensation-unit.model';
import { SpMultiValueSliderControl, SpSliderBulletSize } from './model';

@Component({
    selector: 'sp-multi-value-slider-input[control]',
    templateUrl: './sp-multi-value-slider.component.html',
    styleUrls: [
        './sp-multi-value-slider.component.scss',
        './../slider/sp-slider.component.scss',
    ],
    encapsulation: ViewEncapsulation.None,
})
export class SpMultiValueSliderComponent implements OnInit {
    @Input()
    public control!: SpMultiValueSliderControl;

    @HostBinding('class.v2-slider') v2Design = true;

    @ViewChild('inputElement', { static: false })
    inputElement!: ElementRef<HTMLElement>;

    public sliderValue: number;
    private _value: number;

    public sliderOptions: Options;
    public inputEnabled = false;
    public sliderUnit: CompensationUnit;

    get inputValue() {
        return this._value;
    }

    get inputMax() {
        return this.sliderUnit === CompensationUnit.relative
            ? 100
            : this.control.inputCeilAbsolute;
    }

    @HostBinding('class.color-scheme-light')
    get colorSchemeLight() {
        return this.control.colorScheme === 'light';
    }

    @HostBinding('class.color-scheme-gradient')
    get colorSchemeGradient() {
        return this.control.colorScheme === 'gradient';
    }

    get percentClass(): { [name: string]: boolean } {
        // note: we have to map classes like color-scheme-gradient-62.5 to color-scheme-gradient-62_5
        return {
            [`color-scheme-gradient-${mapStepValueToString(
                this.control.value.value,
            )}`]: true,
        };
    }

    get isSmallBullet() {
        return (
            this.control && this.control.bulletSize === SpSliderBulletSize.small
        );
    }

    ngOnInit(): void {
        this.sliderUnit = this.control.value.unit;
        // Ensure slider's ceiling is in sync with unit type after re-initialization;
        this.sliderOptions = {
            ...this.control.sliderOptions,
            ceil:
                this.sliderUnit === CompensationUnit.relative
                    ? 100
                    : this.control.sliderCeilAbsolute,
        };

        this.sliderValue = this.adjustSliderValue(this.control.value.value);
        this._value = this.adjustInputValue(this.control.value.value);
    }

    onValueChange(value: number) {
        this.sliderValue = this.adjustSliderValue(value);
        this._value = this.adjustInputValue(value);

        // backward compatibility
        this.control.onValueChange(this._value);
    }

    adjustSliderValue(value: number) {
        const compareValue =
            this.sliderUnit === CompensationUnit.relative
                ? 100
                : this.control.sliderCeilAbsolute;

        return value > compareValue ? compareValue : value;
    }

    adjustInputValue(value: number) {
        const compareValue =
            this.sliderUnit === CompensationUnit.relative
                ? 100
                : this.control.inputCeilAbsolute;

        return value > compareValue ? compareValue : value;
    }

    toggleInput(isEnabled: boolean): void {
        this.inputEnabled = isEnabled;

        if (isEnabled) {
            setTimeout(() => {
                this.inputElement.nativeElement.focus();
            });
        }
    }

    editableLabelValue(value: number): string {
        return this.control.editableValuesFormatter
            ? this.control.editableValuesFormatter(value, this.sliderUnit)
            : value.toString();
    }

    onUnitChange(unit: CompensationUnit): void {
        this.sliderUnit = unit;
        this.control.onUnitChange(unit);
        if (this.sliderUnit === CompensationUnit.relative) {
            this.toggleToPercentageUnits();
        } else if (this.sliderUnit === CompensationUnit.absolute) {
            this.toggleToAbsoluteUnits();
        }
    }

    onPressEnter(event: any): void {
        event.target.blur();
    }

    private toggleToPercentageUnits(): void {
        const percentageLowValue = this.convertToPercentageValue(this._value);
        this.sliderOptions = { ...this.sliderOptions, ceil: 100 };
        this.onValueChange(percentageLowValue);
    }

    private toggleToAbsoluteUnits(): void {
        const absoluteLowValue = this.convertToAbsoluteValue(this._value);
        this.sliderOptions = {
            ...this.sliderOptions,
            ceil: this.control.sliderCeilAbsolute,
        };
        this.onValueChange(absoluteLowValue);
    }

    private convertToPercentageValue(absoluteValue: number): number {
        return Math.round(
            (absoluteValue / this.control.sliderCeilAbsolute) * 100,
        );
    }

    private convertToAbsoluteValue(percentageValue: number): number {
        return Math.round(
            (percentageValue * this.control.sliderCeilAbsolute) / 100,
        );
    }
}

function mapStepValueToString(stepValue: number | string | object): string {
    return `${stepValue}`.replace('.', '_');
}
