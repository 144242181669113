import type { AfterViewInit, ComponentRef } from '@angular/core';
import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    InjectionToken,
    Injector,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import type { ComponentType } from '@angular/cdk/portal';
import { DialogContentComponentInjector } from '../dialog/dialog-container/dialog-content-component-injector';
import type { PopupConfig, PopupRef } from './model';
import { PopupContentComponentData, spPopupData } from './model';

export const spPopupContentComponentData = new InjectionToken<any>(
    'SpPopupContentComponentData',
);

@Component({
    selector: 'sp-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements AfterViewInit {
    @Output() rendered = new EventEmitter();
    @Output() outsideClick = new EventEmitter<any>();

    @ViewChild('popupContentContainer', {
        read: ViewContainerRef,
        static: true,
    })
    private popupContentContainerRef: ViewContainerRef;
    private popupContentComponentRef: ComponentRef<any>;

    config: PopupConfig;
    private popupHandle: PopupRef;

    constructor(
        @Inject(spPopupData) popupData: PopupContentComponentData,
        private componentFactoryResolver: ComponentFactoryResolver,
        private elRef: ElementRef<HTMLElement>,
        private injector: Injector,
    ) {
        this.popupHandle = popupData.popupRef;
        this.config = popupData.popupData.config;
    }

    ngAfterViewInit(): void {
        this.rendered.emit();
    }

    renderContent<T>(componentType: ComponentType<T>, popupData: any) {
        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
                componentType,
            );
        this.popupContentContainerRef.clear();
        this.popupContentComponentRef =
            this.popupContentContainerRef.createComponent(
                componentFactory,
                undefined,
                this.createInjector(popupData),
            );
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.elRef.nativeElement.contains(event.target)) {
            event.stopPropagation();
            this.outsideClick.emit(event.target);
        }
    }

    @HostListener('click', ['$event'])
    withinHostClick(event) {
        // Stop propagation of the 'click' event when click happens inside a popup container
        // to avoid closing popup on 'self-click'. This happens in certain cases where
        // handle (i.e. button, anchor) that used to open popup on click is a ancestor of the
        // DOM node where popup is being rendered
        if (this.config.stopPropagationForClickInside) {
            event.stopPropagation();
        }
    }

    private createInjector(
        popupComponentData: any,
    ): DialogContentComponentInjector {
        const tokens = new WeakMap<InjectionToken<any>, any>();
        tokens.set(spPopupContentComponentData, {
            popupHandle: this.popupHandle,
            contentComponentData: popupComponentData,
        });

        return new DialogContentComponentInjector(this.injector, tokens);
    }
}
