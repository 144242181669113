<header *ngIf="!hideSteps" class="header">
    <!-- gte larger devices -->

    <div *ngIf="!isSmallScreen" class="header-items">
        <div class="items-row">
            <ng-container *ngFor="let step of steps; let idx = index">
                <ng-container *ngIf="!step.parentType && !step.hideInNavbar">
                    <div
                        *ngIf="idx !== 0"
                        [class.highlighted]="idx <= currentStepIndex"
                        class="divider"
                    ></div>
                    <div class="step-wrapper">
                        <div
                            class="step-icon-container"
                            [class.highlighted]="idx <= currentStepIndex"
                            [class.active]="idx === currentStepIndex"
                        >
                            <span class="step-icon-mask" [ngStyle]="getStepIconMask(idx)"></span>
                        </div>
                        <div
                            class="step-title"
                            [class.highlighted]="idx <= currentStepIndex"
                            [class.active]="idx === currentStepIndex"
                        >
                            {{ step.title }}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <!-- lt larger devices -->

    <ng-container *ngIf="isSmallScreen && steps.length > 0">
        <div class="header-left" [class.first-step]="currentStepIndex === 0">
            <a href="javascript:" (click)="onLeftButtonClick()">
                <img class="sm-header-button-icon" src="/images/icons/back.svg" />
            </a>
        </div>
        <div class="header-center">
            <span class="step-icon-mask" [ngStyle]="getStepIconMask(currentStepIndex)"></span>
            <span>{{ currentStep.title }}</span>
        </div>
    </ng-container>
</header>
