<div class="wrapper size-{{ size }}">
    <ng-container *ngFor="let item of items; let i = index">
        <img
            *ngIf="item.image"
            (error)="onImageLoadError(i)"
            src="{{ item.image }}"
            alt="avatar-{{ i }}"
            class="avatar-image avatar-item-{{ i }}"
            [class.hidden]="showFallbackAvatar(i)"
        />
        <span class="avatar-initials avatar-item-{{ i }}" [class.hidden]="!showFallbackAvatar(i)">
            {{ item.initials }}
        </span>
    </ng-container>
    <span *ngIf="!hidePlusMore && plusMoreItems" class="plus-more-label">+{{ plusMoreItems }}</span>
    <span *ngIf="!!label" class="plus-more-label">{{ label }}</span>
</div>
