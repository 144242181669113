import { Component, HostBinding, Input } from '@angular/core';
import { TooltipWidthType } from '../tooltip/tooltip.component';

export enum ShineDotType {
    active = 'active',
    pending = 'pending',
    inactive = 'inactive',
}

@Component({
    selector: 'sp-shine-dot',
    templateUrl: './shine-dot.component.html',
    styleUrls: ['./shine-dot.component.scss'],
})
export class ShineDotComponent {
    readonly TooltipWidthType = TooltipWidthType;
    @Input() status: ShineDotType;
    @Input() tooltip: string;

    @HostBinding('class.active')
    get isActive() {
        return this.status === ShineDotType.active;
    }

    @HostBinding('class.pending')
    get isPending() {
        return this.status === ShineDotType.pending;
    }

    @HostBinding('class.inactive')
    get isInactive() {
        return this.status === ShineDotType.inactive;
    }
}
