import * as uuid from 'uuid';
import type { GenericIdName, ProfileCompany } from '../../../../shared/models';
import { isValidMinLength } from '../../../../shared/utils';
import type { IWorkExperience } from './work-experience.interface';

export class WorkExperience implements IWorkExperience {
    _uuid?: string;
    _isNew?: boolean;

    company: ProfileCompany;
    jobTitle: string;
    startDate: Date;
    endDate?: Date;
    isCurrentPosition: boolean;
    industries: GenericIdName[];
    achievements: string;

    static createBlank() {
        return new WorkExperience({
            _uuid: uuid(),
            _isNew: true,

            company: null,
            jobTitle: '',
            startDate: null,
            endDate: null,
            isCurrentPosition: false,
            industries: [],
            achievements: '',
        });
    }

    constructor(workExperience: IWorkExperience) {
        this._uuid = workExperience._uuid || uuid();
        this._isNew = workExperience._isNew || false;

        this.company = workExperience.company;
        this.jobTitle = workExperience.jobTitle;
        this.startDate = workExperience.startDate
            ? new Date(workExperience.startDate)
            : null;
        this.endDate = workExperience.endDate
            ? new Date(workExperience.endDate)
            : null;
        this.isCurrentPosition = !!workExperience.isCurrentPosition;
        this.industries = workExperience.industries;
        this.achievements = workExperience.achievements;
    }

    validate(): string[] {
        const errors = [];

        if (!(this.company && this.company.name)) {
            errors.push('Company');
        }

        if (!isValidMinLength(this.jobTitle, 2)) {
            errors.push('Job Title');
        }

        if (!this.startDate) {
            errors.push('Start Date');
        }

        if (!(this.endDate || this.isCurrentPosition)) {
            errors.push('End Date');
        }

        if (
            !this.industries ||
            this.industries.length < 1 ||
            this.industries.length > 6
        ) {
            errors.push('Industry');
        }

        return errors.length ? errors : null;
    }

    clone() {
        return new WorkExperience({
            _uuid: this._uuid,
            _isNew: this._isNew,

            company: this.company ? { ...this.company } : null,
            jobTitle: this.jobTitle,
            startDate: this.startDate ? new Date(this.startDate) : null,
            endDate: this.endDate ? new Date(this.endDate) : null,
            isCurrentPosition: this.isCurrentPosition,
            industries: this.industries.map((industry) => ({ ...industry })),
            achievements: this.achievements,
        });
    }

    isValid() {
        return this.validate() === null;
    }
}
