import type {
    ComponentRef,
    EmbeddedViewRef,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    Output,
} from '@angular/core';
import { Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import type {
    ConnectedPosition,
    OverlayRef,
    PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { delay, take } from 'rxjs/operators';
import type { Subscription } from 'rxjs';
import invariant from 'tiny-invariant';
import { DocumentEventsProxyService } from '../../core/document-events-proxy.service';
import { WindowService } from '../../core/window.service';
import { addCdkOverlayClass, removeCdkOverlayClass } from '../utils';
import { TooltipComponent, TooltipWidthType } from './tooltip.component';

const tooltipOverlayCssClass = 'sp-tooltip';
const tooltipOverNavbarCssClass = 'tooltip-above-navbar';

const defaultConnectedPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetX: -20, // make tooltip popover pointer be positioned over '*'
};

@Directive({
    selector: '[spTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
    @Input() tooltipText: string;
    @Input() showOnClickOnly = false;
    @Input() disableOnTouchScreens: boolean;
    @Input() aboveNavbar = false;
    @Input() isDisabled = false;
    @Input() textAlign: 'center' | 'left' = 'center';
    @Input() widthType: TooltipWidthType;
    @Input() cssOverrides: { [key: string]: string };

    @Output() visibilityChange = new EventEmitter<boolean>();

    private overlayRef: OverlayRef;
    private tooltipRef: ComponentRef<TooltipComponent> | null;

    private viewportWidth: number;
    private subscription: Subscription;

    constructor(
        private elRef: ElementRef<HTMLElement>,
        private documentEventsProxyService: DocumentEventsProxyService,
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private ngZone: NgZone,
    ) {}

    ngOnInit(): void {
        this.viewportWidth = document.documentElement.clientWidth;
        this.subscription =
            this.documentEventsProxyService.documentClick$.subscribe((event) =>
                this.clickout(event),
            );
    }

    ngOnDestroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }

        this.subscription.unsubscribe();
    }

    @HostListener('mouseenter')
    @HostListener('click', ['$event']) // target touch screen devices
    show(event: MouseEvent) {
        const skipTooltip =
            this.disableOnTouchScreens ||
            this.isDisabled ||
            !this.tooltipText ||
            (this.showOnClickOnly && !this.isClickEvent(event));
        if (skipTooltip) {
            return;
        } else if (this.showOnClickOnly && this.isClickEvent(event)) {
            event.stopPropagation();
        }

        if (!this.overlayRef) {
            this.createOverlay();
        }

        if (this.aboveNavbar) {
            addCdkOverlayClass(tooltipOverNavbarCssClass);
        }

        if (!this.tooltipRef) {
            const tooltipPortal = new ComponentPortal(TooltipComponent);
            this.tooltipRef = this.overlayRef.attach(tooltipPortal);
        }

        this.tooltipRef.instance.tooltipText = this.tooltipText;
        this.tooltipRef.instance.textAlign = this.textAlign;
        this.tooltipRef.instance.widthType = this.widthType;
        this.tooltipRef.instance.cssOverrides = this.cssOverrides;
        this.tooltipRef.instance.rendered
            .pipe(
                take(1),
                // using delay(n) to capture more accurate dimensions of the rendered popover.
                delay(10),
            )
            .subscribe(() => {
                if (!this.tooltipRef) {
                    return;
                }

                const tooltipComponentHostElement = (
                    this.tooltipRef.hostView as EmbeddedViewRef<HTMLElement>
                ).rootNodes[0] as HTMLElement;
                this.positionTooltip(
                    tooltipComponentHostElement.offsetWidth,
                    tooltipComponentHostElement.offsetHeight,
                );
                this.visibilityChange.emit(true);
            });
    }

    @HostListener('mouseleave')
    hide(runExplicitlyInZone = false) {
        if (this.overlayRef && !!this.tooltipRef) {
            if (runExplicitlyInZone) {
                this.ngZone.run(() => this.hidePopover());
            } else {
                this.hidePopover();
            }
        }
    }

    // the method was supposed to use @HostListener('document:click', ['$event'])
    // to dismiss tooltip upon click outside of the tooltip itself. Due to performance
    // issues (reproduceable with multiple tooltip instances) we use document:click
    // proxied by DocumentEventsService
    clickout(event: MouseEvent) {
        if (!this.elRef.nativeElement.contains(event.target as Node)) {
            this.hide();
        }
    }

    private hidePopover() {
        this.overlayRef.detach();
        this.tooltipRef = null;
        this.visibilityChange.emit(false);
        if (this.aboveNavbar) {
            removeCdkOverlayClass(tooltipOverNavbarCssClass);
        }
    }

    private createOverlay() {
        const defaultPositionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elRef)
            .withPositions([defaultConnectedPosition]);

        this.overlayRef = this.overlay.create({
            positionStrategy: defaultPositionStrategy,
            panelClass: tooltipOverlayCssClass,
        });
    }

    // re-position tooltip if there is not enough room for default positioning
    private positionTooltip(width: number, height: number) {
        invariant(this.tooltipRef);

        const parentWidth = this.elRef.nativeElement.offsetWidth;
        const parentOffset = WindowService.getElementOffsetRelativeToWindow(
            this.elRef.nativeElement,
            true,
        );

        let connectedPosition: ConnectedPosition = defaultConnectedPosition;
        const availableRight =
            this.viewportWidth - (parentWidth + parentOffset.x);

        if (availableRight < width) {
            connectedPosition = {
                ...connectedPosition,
                overlayX: 'end',
                offsetX: 0,
            };
            this.tooltipRef.instance.horizontalPosition = 'left';

            // In case if we move tooltip to the left and it spans from the left border of the browser window beyond the
            // parent element due to its width, we need to readjust the placement of caret.
            if (width > parentOffset.x) {
                this.tooltipRef.instance.customCaretOffsetLeft = parentOffset.x;
            }
        }

        if (parentOffset.y < height) {
            connectedPosition = {
                ...connectedPosition,
                originY: 'bottom',
                overlayY: 'top',
            };
            this.tooltipRef.instance.verticalPosition = 'bottom';
        }

        this.tooltipRef.changeDetectorRef.markForCheck();
        const positionStrategy: PositionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elRef)
            .withPositions([connectedPosition]);
        this.overlayRef.updatePositionStrategy(positionStrategy);
    }

    private isClickEvent(event: MouseEvent): boolean {
        return event?.type === 'click';
    }
}
