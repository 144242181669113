<a href="javascript:" class="close-button" (click)="close($event)"></a>
<div class="viewer-wrapper">
    <sp-pdf-viewer [url]="documentUrl" [width]="'99.6rem'" class="pdf-viewer"></sp-pdf-viewer>
</div>
<div class="bottom-section">
    <sp-stateful-button
        [label]="'Close'"
        [context]="'info'"
        [inverted]="true"
        (buttonClick)="close($event)"
    >
    </sp-stateful-button>
    <sp-stateful-button [label]="'Download Resume'" (buttonClick)="downloadResume()">
    </sp-stateful-button>
</div>
