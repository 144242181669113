export const candidateTraitsResultsInterpretationMessage = [
    'As you read your results, please keep in mind that we use a multidimensional approach to understanding your ' +
        'personality - meaning each quality and trait is observed individually. Unlike other assessments which may ' +
        'group you into a "type", we have thousands of different possible combinations for our custom results.',

    'Personality research shows that people should not be grouped into a few standardized types when making employment ' +
        'related decisions. The nature of our personalities is complex and we often exhibit inconsistent and conflicting ' +
        'behaviors - so, attempting to understand you on more than one dimension, the report may at times seem to present ' +
        'conflicting results. In those cases, please think of both options and see which situations may prompt you ' +
        'to behave in one way or the other.',

    "The purpose of this report is to stimulate self-exploration and self-awareness, so even if something doesn't " +
        'seem right, it only contributes to the development of a more complete image of yourself at work.',
];

// labels
export const missingDataLabel = 'N / A';

// profile
export const teamAndUserManagementLabel = 'Teams & User Management';
export const defaultCountryCode = 'us';
export const genericFlagClassName = 'sp-flag';
export const specificFlagClassPrefix = 'flag-';

// templates
export const templatesNotLoaded =
    'Unable to load message templates. Please reload the page if needed.';
export const numberOfRecentlyUsedTemplates = 3;

// messaging
export const minContentHeight = 78; // px
export const fileSizeTooBigWarning =
    'Please select files with a size below 140 Mb.';
export const emptyFileWarning =
    'It looks like the file you attached is empty. Please select a different one.';
export const oneAttachmentWarning = 'Only one attachment allowed';
export const maxFileSizeInBytes: number = 140 * 1000 * 1024; // 140MB

// error messages
export const minYearDateAllowed = 1900;
export const minDateAllowed = `Only dates after ${minYearDateAllowed} allowed`;

// globally used css classes / prefixes / suffixes
export const chipsMaxItemsCssClassPrefix = 'sp-chips-max-';
export const inputIconCssClassPrefix = 'sp-input-icon__';
export const validFilledFieldInputIconNameSuffix = 'check';

// candidate onboarding
export const responsibilitiesAndAchievementsPlaceholder =
    'Example: Delivered P&L and innovation for priority brands; owned ' +
    'business plan development and execution, introduced 6 new products and repositioned 2 priority brands, ' +
    'led and developed a team of 5. \r\n\r\n' +
    '• Developed intervention plan for 2nd biggest brand after 3 year sales decline. Delivered profitability ' +
    'at a CAGR of 6% 2009-2011; gained 3.2% market share.\r\n' +
    '• Led brand restage innovation in  liqueur; introduced new segment, resulting in 33% volume growth vs ' +
    'budget in 2009 and 8.5% market share.';

// candidate match details

export const screeningQuestionsCompletedMessage = `You’ve already answered all screening questions`;
export const screeningQuestionsNotCompletedMessage =
    `You’ll need to answer a few screening questions ` +
    `before you can apply for this role.`;

export const coverLetterCompletedMessage = `You’ve already filled in your cover letter.`;
export const coverLetterNotCompletedMessage =
    `You’ll need to provide a cover letter ` +
    `before you can apply for this role.`;

export const traitAssessmentsCompletedMessage = `You’ve already completed your traits assessment.`;
export const traitAssessmentsNotCompletedMessage =
    'This role requires that you complete a traits assessment.';

export const sampleMessageDisableActionMsg =
    'This is just a sample. Check back later for ' +
    'jobs that are a good match for you.';

export const uploaderMessageSmScreen = 'Click to Select a File';
export const uploaderMessageLgScreen =
    'Drag & Drop Your Resume<br/>or Click to Select a File';

export const obfuscatedEntityTooltip = `We’re working on computing your match data with this role.
   Please try again in a few minutes.`;

export const baseJobStepperStepIndexToPercentageArray = [25, 50, 75, 100];
export const jobStepperWithImportStepIndexToPercentageArray = [
    20, 40, 60, 80, 100,
];

export const cdkOverlayContainerCssClass = 'cdk-overlay-container';
export const cdkOverlayContainerBackdropShowingCssClass =
    'cdk-overlay-backdrop-showing';
export const dialogOverlayCssClass = 'dialog-overlay';
export const linkButtonTooltip =
    'Please first select the text you would like to make a hyperlink.';

// Google API
export const usCountry = 'us';
export const usAndCanada = ['us', 'ca'];
