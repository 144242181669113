import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';
import { isDefined } from '../utils';

export enum ButtonContext {
    primary = 'primary',
    danger = 'danger',
    info = 'info',
}

export enum ButtonSize { // inline with $sp-components-sizes from web-styles project
    small = 'small', // 32px
    normal = 'normal', // 40px
}

export type StatefulButtonType = 'button' | 'link';

@Component({
    selector: 'sp-stateful-button',
    templateUrl: './stateful-button.component.html',
    styleUrls: ['./stateful-button.component.scss'],
})
export class StatefulButtonComponent implements OnInit, OnChanges {
    buttonIconMaskCss: any;
    leftButtonIconMaskCss: any;

    @Input() label;
    @Input() loadingLabel;
    @Input() context: ButtonContext;
    @Input() inverted = false;
    @Input() buttonType: StatefulButtonType = 'button';
    @Input() buttonSize = ButtonSize.normal;
    @Input() heightPx: number = null;
    @Input() iconUrl: string;
    @Input() leftIconUrl: string;
    @Input() isLoading: boolean;
    @Input() disabledFromOutside: boolean;
    @Input() isBackgroundDark = false;
    @Input() employerBottomBarButton = false;
    @Input() iconMaskSize = '1rem';
    @Input() iconSizeOverridePx: number;
    @Input() withSpaceBetweenLabelAndIcon = false;
    @Input() withAiSuffix: boolean;

    @Output() buttonClick = new EventEmitter<any>();

    @HostBinding('class.small')
    get isSmallButton() {
        return this.buttonSize === ButtonSize.small;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && (changes.iconUrl || changes.iconMaskSize)) {
            this.buttonIconMaskCss = this.iconUrl
                ? {
                      '-webkit-mask-image': `url(${this.iconUrl})`,
                      'mask-image': `url(${this.iconUrl})`,
                      'mask-size': `${this.iconMaskSize}`,
                      '-webkit-mask-size': `${this.iconMaskSize}`,
                  }
                : null;
        }
    }

    ngOnInit() {
        if (!isDefined(this.context)) {
            this.context = ButtonContext.primary;
        }

        this.leftButtonIconMaskCss = this.leftIconUrl
            ? {
                  '-webkit-mask-image': `url(${this.leftIconUrl})`,
                  'mask-image': `url(${this.leftIconUrl})`,
              }
            : null;
    }

    onClick(event) {
        if (!this.disabledFromOutside) {
            this.buttonClick.emit(event);
        }
    }
}
