<!--
  Note: be careful changing class name of the wrapper container because:
    1. wrapper is queried using native DOM API for programmatic scrolling purposes
    2. wrapper element is referenced by ngx-infinite-scroll plugin used on the
       candidate-matches screen for infinite scrolling purposes
-->
<div class="app-content" cdkScrollable>
    <sp-navbar *ngIf="showNavbar" (navbarStateChange)="onNavStateChange($event)"> </sp-navbar>

    <div class="app-scrolling-container" cdkScrollable>
        <router-outlet></router-outlet>
    </div>
</div>
<sp-notification-container></sp-notification-container>
