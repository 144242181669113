import { JobsService } from 'app/core/jobs.service';
import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../core/user.service';
import { UserType } from '../shared/models/user.model';

@Injectable()
export class DashboardGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private jobsService: JobsService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.userService.getUserObservable().pipe(
            map((user) => {
                const isStartSubscription =
                    user.type === UserType.employer &&
                    route.queryParams &&
                    'startSubscription' in route.queryParams;

                const canActivate =
                    isStartSubscription &&
                    !this.userService.isSubscriptionActive();

                if (!canActivate) {
                    if (user.type === UserType.employer) {
                        if (isStartSubscription) {
                            this.router.navigate(['/account/subscription']);
                        } else {
                            this.router.navigate(['/jobs']);
                        }
                    } else {
                        if (!user.isProfileComplete) {
                            this.router.navigate(['/account/profile']);

                            return false;
                        }

                        this.router.navigate(['/matches']);
                    }
                }

                return canActivate;
            }),
        );
    }
}
