import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Subscription } from 'rxjs';
import { SpTextArea } from '../sp-form-controls.model';
import { unsubscribeIfActive } from '../../../utils';

@Component({
    selector: 'sp-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit, OnDestroy {
    @Input() control: SpTextArea;

    charsLeft: number;

    private subscription: Subscription;

    get hasCharsCounter() {
        return this.control.maxChars;
    }

    ngOnInit() {
        if (this.hasCharsCounter) {
            const valueLength = this.control.value
                ? this.control.value.length
                : 0;
            this.charsLeft = this.control.maxChars - valueLength;

            this.subscription = this.control.formControl.valueChanges.subscribe(
                (value) =>
                    (this.charsLeft =
                        value.length > this.control.maxChars
                            ? 0
                            : this.control.maxChars - value.length),
            );
        }
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }
}
