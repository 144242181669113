import type { OnInit } from '@angular/core';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { isDefined } from '../../../utils';

@Directive()
export abstract class AbstractUnitSelectorComponent<T> implements OnInit {
    public selectedUnit: T;

    @Input() units: T[] = [];
    @Input() defaultUnit: T;

    @Output() unitSelected: EventEmitter<T> = new EventEmitter<T>();

    ngOnInit() {
        if (
            isDefined(this.defaultUnit) &&
            this.units.indexOf(this.defaultUnit) >= 0
        ) {
            this.selectedUnit = this.defaultUnit;
        } else {
            this.selectedUnit = this.units[0];
            this.unitSelected.emit(this.selectedUnit);
        }
    }

    onItemSelection(selectedUnit: T) {
        if (selectedUnit === this.selectedUnit) {
            return;
        }
        this.selectedUnit = selectedUnit;
        this.unitSelected.emit(this.selectedUnit);
    }

    getLabel(value: T) {}
}
