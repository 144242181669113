import { Component } from '@angular/core';
import { AbstractUnitSelectorComponent } from '../abstract-unit-selector';
import type { CompensationUnit } from './compensation-unit.model';
import { compensationUnitLabels } from './compensation-unit.model';

@Component({
    selector: 'sp-compensation-unit-selector',
    templateUrl: './compensation-unit-selector.component.html',
    styleUrls: ['./compensation-unit-selector.component.scss'],
})
export class CompensationUnitSelectorComponent extends AbstractUnitSelectorComponent<CompensationUnit> {
    getLabel(unit: CompensationUnit) {
        return compensationUnitLabels[unit];
    }
}
