<ng-container *ngIf="notification && isSmallScreen">
    <sp-notification
        [message]="notification.message"
        [colorContext]="notification.colorContext"
        (dismissNotification)="onDismiss()"
        [@smInOutState]="inOutState"
        (@smInOutState.done)="onNotificationAnimationDone($event)"
        class="notification"
    >
    </sp-notification>
</ng-container>

<ng-container *ngIf="notification && !isSmallScreen">
    <sp-notification
        [message]="notification.message"
        [colorContext]="notification.colorContext"
        (dismissNotification)="onDismiss()"
        [@mdInOutState]="inOutState"
        (@mdInOutState.done)="onNotificationAnimationDone($event)"
        class="notification"
    >
    </sp-notification>
</ng-container>
