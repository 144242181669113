import type { FormGroup } from '@angular/forms';
import type { OnChanges, OnDestroy, SimpleChange } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { finalize, map, tap } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import invariant from 'tiny-invariant';
import { UserType } from '../../../shared/models/user.model';
import type { SpFormControlsGroup } from '../../../shared/forms/custom-controls/sp-form-controls-group.interface';
import type { ISignupForm } from '../../model/signup-form.model';
import { TokenService } from '../../../core/token.service';
import { TokenType } from '../../../shared/models/token-type.enum';
import type { TokenVerificationResponse } from '../../model/token-verification-response.model';
import type { TokenRequest } from '../../model/token-request.model';
import { UserService } from '../../../core/user.service';
import { unsubscribeIfActive } from '../../../shared/utils';
import { SignUpFormService } from './signup-form.service';

export enum SignupErrorType {
    invitationExpiredError = 'invitationExpiredError',
    invitationUsedError = 'invitationUsedError',
    invitationNotFoundError = 'invitationNotFoundError',
    genericError = 'genericError',
    forbiddenEmailDomainError = 'forbiddenEmailDomainError',
    newUserForExistingEmployerError = 'newUserForExistingEmployerError',
    incorrectVerificationLink = 'incorrectVerificationLink',
    emailAlreadyUsedError = 'emailAlreadyUsedError',
}

@Component({
    selector: 'sp-signup-form[userType]',
    templateUrl: './signup-form.component.html',
    styleUrls: ['./signup-form.component.scss'],
    providers: [SignUpFormService],
})
export class SignUpFormComponent implements OnChanges, OnDestroy {
    @Input() userType!: UserType;
    @Input() employerInvitationToken?: string;
    @Input() employerVerificationToken?: string;
    @Input() error?: SignupErrorType;

    @Output() signUp: EventEmitter<void> = new EventEmitter();
    @Output() verificationEmailSent: EventEmitter<string> = new EventEmitter();
    @Output() prefillDataFetched: EventEmitter<ISignupForm> =
        new EventEmitter();

    public form: FormGroup;
    public controls: SpFormControlsGroup;

    public UserType = UserType;
    public isSubmitting = false;
    public emailAlreadyUsedMessage =
        'The email address has already been used to register an account';

    public prefillEmployerDataRequestComplete = false;
    public prefillData?: ISignupForm;

    public readonly SignupErrorType = SignupErrorType;

    private subscription: Subscription;

    get isSignUpRequest() {
        return (
            this.userType === UserType.candidate ||
            this.prefillEmployerDataRequestComplete
        );
    }

    get isEmployerInvitationSignUp() {
        return !!this.employerInvitationToken;
    }

    get isEmployerVerifiedEmailSignUp() {
        return !!this.employerVerificationToken;
    }

    get showFullEmployerSignupForm() {
        return (
            this.prefillEmployerDataRequestComplete ||
            this.isEmployerInvitationSignUp
        );
    }

    get showPasswordField() {
        return (
            this.userType === UserType.candidate ||
            this.showFullEmployerSignupForm
        );
    }

    get isInvitationError() {
        return (
            this.error === SignupErrorType.invitationExpiredError ||
            this.error === SignupErrorType.invitationUsedError ||
            this.error === SignupErrorType.invitationNotFoundError
        );
    }

    constructor(
        private userService: UserService,
        private tokenService: TokenService,
        private signUpFormService: SignUpFormService,
        private router: Router,
    ) {
        this.subscription = new Subscription();
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (
            !!changes.userType &&
            changes.userType.currentValue !== changes.userType.previousValue
        ) {
            this.error = undefined;
            this.signUpFormService.saveFormState();
            this.initForm();
        }

        if (
            !!changes.employerVerificationToken &&
            this.userType === UserType.employer &&
            changes.employerVerificationToken.currentValue !==
                changes.employerVerificationToken.previousValue
        ) {
            this.verifyEmployerEmail();
        }
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    initForm() {
        if (!this.form) {
            const disableEmailField =
                this.isEmployerInvitationSignUp ||
                this.isEmployerVerifiedEmailSignUp;
            this.controls = this.signUpFormService.createControls(
                this.userType,
                disableEmailField,
            );
            this.form = this.signUpFormService.form;
        }

        this.signUpFormService.updateControls(
            this.controls,
            this.userType,
            {
                showFullEmployerSignupForm: this.showFullEmployerSignupForm,
            },
            this.signUpFormService.formData,
        );

        if (
            this.isEmployerInvitationSignUp &&
            !this.prefillEmployerDataRequestComplete
        ) {
            this.prefillEmployerFormFromInvite();
        }
    }

    submitForm() {
        this.error = undefined;

        if (
            this.signUpFormService.isEmailDomainForbidden(
                this.form.value.email,
                this.userType,
            )
        ) {
            this.error = SignupErrorType.forbiddenEmailDomainError;

            return;
        }

        this.isSubmitting = true;
        if (this.isSignUpRequest) {
            this.userService
                .signup({
                    email: this.form.value.email,
                    password: this.form.value.password,
                    firstName: this.form.value.firstName,
                    lastName: this.form.value.lastName,
                    userType: this.userType,
                    invitationToken:
                        this.userType === UserType.candidate ||
                        !this.employerInvitationToken
                            ? undefined
                            : this.employerInvitationToken,
                })
                .pipe(finalize(() => (this.isSubmitting = false)))
                .subscribe(
                    () => this.signUp.emit(),
                    (error) => this.onSignupOrPrefillRequestError(error),
                );
        } else {
            this.fetchPrefillFormData(
                this.form.value.email,
                this.form.value.firstName,
                this.form.value.lastName,
            )
                .pipe(finalize(() => (this.isSubmitting = false)))
                .subscribe(
                    () => {
                        if (!this.prefillData?.employerRegistered) {
                            this.sendEmployerVerificationEmail();
                        } else {
                            this.prefillDataFetched.emit(this.prefillData);
                        }
                    },
                    (error) => this.onSignupOrPrefillRequestError(error),
                );
        }
    }

    redirectToSignIn() {
        this.router.navigate(['/login', this.userType], {
            state: { prefilledEmail: this.form.value.email },
        });
    }

    private onSignupOrPrefillRequestError(error: any) {
        if (
            error.error &&
            error.error.error[0] === this.emailAlreadyUsedMessage
        ) {
            this.error = SignupErrorType.emailAlreadyUsedError;
            this.form.controls['email'].setErrors({ alreadyRegistered: true });

            return;
        }

        this.error = SignupErrorType.genericError;
    }

    private fetchPrefillFormData(
        email: string,
        firstName: string,
        lastName: string,
    ): Observable<ISignupForm> {
        return this.userService.getPrefillEmployerFormData(email).pipe(
            tap(
                (prefillData) =>
                    (this.prefillData = {
                        ...prefillData,
                        firstName,
                        lastName,
                    }),
            ),
            finalize(() => (this.isSubmitting = false)),
        );
    }

    private prefillEmployerFormFromInvite() {
        invariant(this.employerInvitationToken);
        this.isSubmitting = true;
        const subscription = this.userService
            .getEmployerUserInvitation(this.employerInvitationToken)
            .pipe(
                finalize(() => this.onFetchPrefillFormDataComplete()),
                map((invitation) => {
                    if (invitation.alreadyUsed) {
                        throw SignupErrorType.invitationUsedError;
                    }

                    return {
                        email: invitation.email,
                        firstName: invitation.firstName,
                        lastName: invitation.lastName,
                    };
                }),
                tap((invitationPrefillData) => {
                    this.signUpFormService.updateControls(
                        this.controls,
                        UserType.employer,
                        {
                            showFullEmployerSignupForm:
                                this.showFullEmployerSignupForm,
                        },
                        invitationPrefillData,
                    );
                }),
            )
            .subscribe(
                (prefillData) =>
                    this.onEmployerFormPrefillRequestSuccess(prefillData),
                (error) => {
                    if (error.status) {
                        this.error =
                            error.status && error.status === 410
                                ? SignupErrorType.invitationExpiredError
                                : SignupErrorType.invitationNotFoundError;
                    } else {
                        this.error = error;
                    }
                },
            );

        this.subscription.add(subscription);
    }

    private onEmployerFormPrefillRequestSuccess(prefillData: ISignupForm) {
        this.signUpFormService.formData = {
            email: this.form.value.email,
            ...prefillData,
        };
    }

    private onFetchPrefillFormDataComplete() {
        // regardless of result, refresh form controls and validation.
        this.prefillEmployerDataRequestComplete = true;
        this.signUpFormService.updateControls(
            this.controls,
            this.userType,
            { showFullEmployerSignupForm: this.showFullEmployerSignupForm },
            this.signUpFormService.formData,
        );

        this.isSubmitting = false;
    }

    private sendEmployerVerificationEmail() {
        const tokenType: TokenType =
            this.userType === UserType.employer
                ? TokenType.employerEmailVerification
                : TokenType.candidateEmailVerification;
        // when we send token to employer email, we need to send passThroughQueryParams as contextData
        // so they do not get lost in process of verification
        const tokenRequest: TokenRequest = {
            email: this.form.value.email,
            firstName: this.form.value.firstName,
            lastName: this.form.value.lastName,
            contextData:
                this.userService.getStringifiedPassThroughSignupParamsObject(),
        };
        this.tokenService.sendTokenToEmail(tokenType, tokenRequest).subscribe(
            () => this.verificationEmailSent.emit(this.form.value.email),
            (error) => (this.error = SignupErrorType.genericError),
        );
    }

    private verifyEmployerEmail() {
        invariant(this.employerVerificationToken);
        this.isSubmitting = true;
        this.subscription.add(
            this.tokenService
                .verifyToken(this.employerVerificationToken)
                .pipe(finalize(() => (this.isSubmitting = false)))
                .subscribe(
                    (response: TokenVerificationResponse) => {
                        this.onEmployerFormPrefillRequestSuccess(
                            response.prefillData,
                        );
                        this.onFetchPrefillFormDataComplete();
                        if (response.contextData) {
                            // after verifying employer email, if response contextData (passThroughParams in this case) exists
                            // that means that before verification user had passThroughQueryParams so we need to re-store them
                            this.userService.storePassThroughQueryParams(
                                JSON.parse(response.contextData),
                            );
                        }
                    },
                    (error) => {
                        this.form.controls['email'].enable();
                        this.error = SignupErrorType.incorrectVerificationLink;
                    },
                ),
        );
    }
}
