import { SidebarTabIds } from '../shared-candidate-profile/messaging-sidebar.constants';
import { routerDataKeyActiveSidebarTab } from '../../core/config.service';
import { DashboardJobType } from '../models/enumeration/dashboard-job-type.enum';
import type { BasicMenuItemEvent } from '../../core/navbar.utils';
import type { Currency } from '../models/enumeration/currency.enum';
import {
    Constraint,
    constraintLabels,
    constraintOptionClasses,
} from '../models/enumeration/constraint.enum';
import { SpDropDownDataType } from './sp-dropdown.component';
import type { SpDropDownData } from './sp-dropdown.component';

export type DropdownData =
    | DropdownDataAction
    | BasicMenuItemEvent
    | Currency
    | Constraint
    | number;

export enum DropdownDataAction {
    edit = 'edit',
    duplicate = 'duplicate',
    archive = 'archive',
    startSourcing = 'startSourcing',
    stopSourcing = 'stopSourcing',
    share = 'share',
    openApplications = 'openApplications',
    viewInfo = 'viewInfo',
    viewDiscussion = 'viewDiscussion',
    viewMessages = 'viewMessages',
    viewRequiredActions = 'viewRequiredActions',
    viewActivity = 'viewActivity',
    download = 'download',
    moveJob = 'moveJob',
    invite = 'invite',
    restore = 'restore',
    suspend = 'suspend',
    reinstate = 'reinstate',
    remove = 'remove',
}

// Job/Match actions shared between multiple dropdowns

const openApplications: SpDropDownData = {
    icon: 'web',
    secondaryIcon: 'external_default',
    text: 'Open Application Page',
    data: DropdownDataAction.openApplications,
};

const viewJobInfo = (
    isJobListItem: boolean,
    jobId?: number,
): SpDropDownData => ({
    icon: 'info_default',
    data: !isJobListItem ? DropdownDataAction.viewInfo : undefined,
    text: 'View Job Information',
    navigationUrl: isJobListItem
        ? `/jobs/${jobId}/matches/applications`
        : undefined,
    navigationExtras: {
        state: {
            data: { [routerDataKeyActiveSidebarTab]: SidebarTabIds.tabJobInfo },
        },
    },
});

const viewDiscussion = (
    isJobListItem: boolean,
    title: string,
    jobId?: number,
): SpDropDownData => ({
    icon: 'discussion_default',
    data: !isJobListItem ? DropdownDataAction.viewDiscussion : undefined,
    text: title,
    navigationUrl: isJobListItem
        ? `/jobs/${jobId}/matches/applications`
        : undefined,
    navigationExtras: {
        state: {
            data: {
                [routerDataKeyActiveSidebarTab]: SidebarTabIds.tabCommentsChat,
            },
        },
    },
});

const viewJobRequiredActions = (
    jobId: number,
    isJobListItem: boolean,
): SpDropDownData => ({
    icon: 'flag_default',
    data: !isJobListItem ? DropdownDataAction.viewRequiredActions : undefined,
    text: 'View Required Actions',
    navigationUrl: isJobListItem
        ? `/jobs/${jobId}/matches/applications`
        : undefined,
    navigationExtras: {
        state: {
            data: {
                [routerDataKeyActiveSidebarTab]: SidebarTabIds.requiredActions,
            },
        },
    },
});

const viewActivity = (
    isJobListItem: boolean,
    title: string,
    jobId?: number,
): SpDropDownData => ({
    icon: 'clock_default',
    data: !isJobListItem ? DropdownDataAction.viewActivity : undefined,
    text: title,
    navigationUrl: isJobListItem
        ? `/jobs/${jobId}/matches/applications`
        : undefined,
    navigationExtras: {
        state: {
            data: {
                [routerDataKeyActiveSidebarTab]: SidebarTabIds.tabActivity,
            },
        },
    },
});

const edit = {
    icon: 'pencil_basic',
    text: 'Edit Job',
    data: DropdownDataAction.edit,
};

const duplicate = {
    icon: 'duplicate_basic',
    text: 'Duplicate Job',
    data: DropdownDataAction.duplicate,
    preventClose: true,
};

const archive = {
    icon: 'archive_default_3',
    text: 'Archive Job',
    data: DropdownDataAction.archive,
    preventClose: true,
};

const startSourcing = {
    icon: 'control_play_basic',
    text: 'Start Sourcing',
    data: DropdownDataAction.startSourcing,
};

const stopSourcing = {
    icon: 'control_stop',
    text: 'Stop Sourcing',
    data: DropdownDataAction.stopSourcing,
};

const downloadResume = {
    icon: 'download_default',
    data: DropdownDataAction.download,
    text: 'Download Resume',
};

const viewCandidateMessages = {
    icon: 'email_default',
    data: DropdownDataAction.viewMessages,
    text: 'View Candidate Messages',
};

const moveToAnotherJob = {
    icon: 'cross_solid',
    data: DropdownDataAction.moveJob,
    text: 'Move to Different Job',
};

const separator = { text: '', type: SpDropDownDataType.separator };
const remove = {
    text: 'Remove',
    class: 'remove',
    data: DropdownDataAction.remove,
};

export const activeItemDropDownData = (
    jobId: number,
    isSourced: boolean,
    isEmployerAccountEnabled: boolean,
    isJobListItem: boolean,
): SpDropDownData => {
    const activeJobDropdownData = {
        text: '',
        children: [
            viewJobInfo(isJobListItem, jobId),
            viewDiscussion(isJobListItem, 'View Job Discussion', jobId),
            viewJobRequiredActions(jobId, isJobListItem),
            viewActivity(isJobListItem, 'View Job Activity', jobId),
            separator,
            isSourced ? stopSourcing : startSourcing,
            separator,
            edit,
            duplicate,
            archive,
        ],
    };

    if (isEmployerAccountEnabled) {
        activeJobDropdownData.children = [
            openApplications,
            separator,
            ...activeJobDropdownData.children,
        ];
    }

    return activeJobDropdownData;
};

export const draftItemDropDownData: SpDropDownData = {
    text: '',
    children: [archive, duplicate],
};

export const archivedItemDropDownData: SpDropDownData = {
    text: '',
    children: [duplicate],
};

export const jobActionsDropDownData = (
    status: DashboardJobType,
    jobId: number,
    isSourced: boolean,
    isEmployerAccountEnabled: boolean,
    isJobListItem: boolean,
): SpDropDownData => {
    switch (status) {
        case DashboardJobType.drafts:
            return draftItemDropDownData;
        case DashboardJobType.archived:
            return archivedItemDropDownData;
        default:
            return activeItemDropDownData(
                jobId,
                isSourced,
                isEmployerAccountEnabled,
                isJobListItem,
            );
    }
};

export const employerMatchDropdownActionsData = (
    hasResume: boolean,
): SpDropDownData => {
    const baseItems = [
        viewJobInfo(false, undefined),
        viewCandidateMessages,
        viewDiscussion(false, 'View Candidate Discussion', undefined),
        viewActivity(false, 'View Candidate Activity', undefined),
        separator,
        moveToAnotherJob,
    ];

    return {
        text: '',
        children: hasResume
            ? [downloadResume, separator, ...baseItems]
            : baseItems,
    };
};

export const defaultConstraintDropdownOptions = () => {
    const options = Object.values(Constraint).map(
        (constraint: Constraint): SpDropDownData => ({
            text: constraintLabels[constraint],
            data: constraint,
            class: constraintOptionClasses[constraint],
        }),
    );

    options.unshift({ text: 'Change to:', type: SpDropDownDataType.header });
    options.push(separator);
    options.push(remove);

    return options;
};

export const defaultConstraintDropdownData = (): SpDropDownData => ({
    text: '',
    children: defaultConstraintDropdownOptions(),
});

export const locationConstraintDropdownData = {
    text: '',
    children: [
        {
            text: 'Location priority cannot be changed.',
            type: SpDropDownDataType.header,
        },
        separator,
        remove,
    ],
};
