import type {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { Validators } from '@angular/forms';

/**
 *  Code copied from https://github.com/angular/angular/blob/7.2.x/packages/forms/src/validators.ts
 *
 *  trims the values to ignore blankspaces for the minLength validator
 * @param value
 */
function isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

export class OverridenValidators {
    static minLength(minLength: number, countWhitespaces = false): ValidatorFn {
        if (countWhitespaces) {
            return Validators.minLength(minLength);
        }

        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null; // don't validate empty values to allow optional controls
            }
            const length: number = control.value
                ? control.value.trim
                    ? control.value.trim().length
                    : control.value.length
                : 0;

            return length < minLength
                ? {
                      minlength: {
                          requiredLength: minLength,
                          actualLength: length,
                      },
                  }
                : null;
        };
    }
}
