import type {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { Validators } from '@angular/forms';
import { CustomValidators } from '@narik/custom-validators';

export function url(isLenientMode = false): ValidatorFn {
    if (!isLenientMode) {
        return CustomValidators.url;
    }

    return (control: AbstractControl): ValidationErrors => {
        const v: string = control.value;

        const requiredValidatorErrors = Validators.required(control);
        if (
            requiredValidatorErrors !== null &&
            requiredValidatorErrors !== undefined
        ) {
            return null;
        }

        // modified version of the URL regexp
        // (https://github.com/rsaenen/ngx-custom-validators/blob/master/src/app/url/validator.ts)
        return /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
            v,
        )
            ? null
            : { url: true };
    };
}
