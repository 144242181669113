import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    ViewChild,
} from '@angular/core';
import type { AvatarItem } from '../../../models';
import { getInputFieldIconCssClassForContext } from '../sp-form-control-utils';
import { SpAvatarStacksSelectorControl } from './model/avatar-stacks-selector-control.model';

export interface AvatarStacksItem {
    id: number;
    label: string;
    avatars: AvatarItem[];
}

@Component({
    selector: 'sp-avatars-stacks-selector',
    templateUrl: './avatars-stacks-selector.component.html',
    styleUrls: ['./avatars-stacks-selector.component.scss'],
})
export class AvatarsStacksSelectorComponent {
    @Input() control: SpAvatarStacksSelectorControl;

    @ViewChild('autocompleteInputField', { static: true })
    autocompleteInput: ElementRef<HTMLInputElement>;

    selectedValues: AvatarStacksItem[] = [];
    private isInputFocused: boolean;

    @HostBinding('class.invalid')
    get isInvalid() {
        const formControl = this.control.formControl;

        return (
            (formControl.dirty || formControl.touched) && formControl.invalid
        );
    }

    get isAutocompleteInputVisible(): boolean {
        return this.selectedValues.length === 0 || this.isInputFocused;
    }

    get autocompleteParams() {
        return {
            ...this.control.autocomplete,
            inputElement: this.autocompleteInput.nativeElement,
            valueChangedHandler: (value) => this.addValue(value),
        };
    }

    @HostBinding('class.tag-container-empty')
    get isTagContainerEmpty() {
        return !this.selectedValues.length;
    }

    get iconCssClass() {
        const context = this.isInvalid ? 'danger' : '';

        return getInputFieldIconCssClassForContext(this.control.icon, context);
    }

    onInputBlur() {
        this.clearInput();
    }

    onInputFocus() {
        this.isInputFocused = true;
    }

    onRemoveStack(id: number) {
        const index = this.selectedValues.findIndex((item) => item.id === id);
        if (index > -1) {
            this.selectedValues.splice(index, 1);
            this.setFormValue();
        }
    }

    private addValue(stackItem: AvatarStacksItem) {
        if (!this.selectedValues.find((item) => item.id === stackItem.id)) {
            this.selectedValues.push(stackItem);
            this.setFormValue();
        }
    }

    private clearInput() {
        this.autocompleteInput.nativeElement.value = null;
    }

    private setFormValue() {
        this.control.formControl.setValue(this.selectedValues);
        this.control.formControl.markAsDirty();
    }
}
