import type { OnInit } from '@angular/core';
import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Optional,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { SpAutoCompleteParams } from '../sp-form-controls.model';
import { AutoCompleteEventType } from './sp-autocomplete.model';
import { AutoCompleteService } from './sp-autocomplete.service';

@Component({
    selector: 'sp-autocomplete',
    templateUrl: './sp-autocomplete.component.html',
    styleUrls: ['./sp-autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SpAutocompleteComponent implements OnInit {
    @Input()
    public autocomplete: SpAutoCompleteParams;

    // templateRef to an external input to init autocomplete on top of
    @Input()
    public externalInput: any;

    @Output()
    public noMatchFoundAction = new EventEmitter();

    filteredSourceArray: any[];

    @HostBinding('class.limit-height')
    get limitHeight() {
        return this.autocomplete.limitDropdownHeight;
    }

    get filteredSource() {
        if (this.autocomplete.excludeSelected && this.filteredSourceArray) {
            return this.filteredSourceArray;
        }

        return this.autocomplete.source;
    }

    constructor(@Optional() private autoCompleteService: AutoCompleteService) {}

    ngOnInit(): void {
        if (this.autocomplete.zIndex === undefined) {
            this.autocomplete.zIndex = 1;
        }

        if (this.autocomplete.icon) {
            this.autocomplete.listFormatter = (item) => {
                let imgSrc = this.autocomplete.icon.defaultSrc;
                if (this.autocomplete.icon.srcMapping) {
                    imgSrc =
                        typeof this.autocomplete.icon.srcMapping === 'function'
                            ? this.autocomplete.icon.srcMapping(item)
                            : item[this.autocomplete.icon.srcMapping];
                }

                const text = item[this.autocomplete.icon.labelMapping];

                return `
          <div class="item-with-icon">
            <img class="icon" src="${imgSrc}"/>
            ${text}
          </div>
        `;
            };
        }

        this.autocomplete.sourceChangedHandler = this.sourceChanged;

        this.initIgnoreEventHandler();
    }

    onAutocompleteValueChanged(value: any) {
        if (
            this.autocomplete.displayPropertyName &&
            this.autocomplete.formControl?.value &&
            this.autocomplete.formControl.value[
                this.autocomplete.displayPropertyName
            ] === value
        ) {
            // On initial focus -> blur, value change event is triggered and the value is set to string
            // by ngui-auto-complete, so we are breaking the value (ex. {id, name})
            return;
        }

        if (this.autocomplete.valueChangedHandler) {
            this.autocomplete.valueChangedHandler(
                this.autocomplete.listFormatter &&
                    this.autocomplete.listFormatter instanceof String
                    ? value[this.autocomplete.listFormatter as string]
                    : value,
            );
        }

        if (
            this.autocomplete.excludeSelected &&
            this.autocomplete.source.indexOf
        ) {
            if (!this.filteredSourceArray) {
                this.resetFilteredSource();
            }
            const idx = this.filteredSourceArray.indexOf(value);
            this.filteredSourceArray.splice(idx, 1);
        }

        if (this.autocomplete.clearOnSelect) {
            this.autocomplete.inputElement.value = '';
        }
    }

    onAutocompleteCustomValueSelected(value) {
        if (this.autocomplete.customValueSelectedHandler) {
            this.autocomplete.customValueSelectedHandler(value);
        }

        if (this.autocomplete.clearOnSelect) {
            this.autocomplete.inputElement.value = '';
        }
    }

    onNoMatchFoundAction(event) {
        this.noMatchFoundAction.emit(event);
    }

    resetFilteredSource() {
        this.filteredSourceArray = [...this.autocomplete.source];
    }

    sourceChanged() {
        if (this.autocomplete.excludeSelected) {
            this.resetFilteredSource();
        }
    }

    private initIgnoreEventHandler() {
        if (this.autocomplete.excludeSelected && this.autoCompleteService) {
            this.autoCompleteService.notifier$.subscribe((event) => {
                switch (event.type) {
                    case AutoCompleteEventType.removeFromExclusions: {
                        const prevIdx = this.autocomplete.source.indexOf(
                            event.element,
                        );
                        this.filteredSourceArray.splice(
                            prevIdx,
                            0,
                            event.element,
                        );

                        break;
                    }
                    case AutoCompleteEventType.reset:
                        this.resetFilteredSource();
                }
            });
        }
    }
}
