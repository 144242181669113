import { Injectable } from '@angular/core';
import {
    debounceTime,
    delay,
    filter,
    map,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { BehaviorSubject, EMPTY, Subject, of } from 'rxjs';
import type { User } from 'app/shared/models';
import type { UserInteractionEventType } from 'app/shared/models/user.model';
import { UserType } from 'app/shared/models/user.model';
import { DialogService } from 'app/shared/dialog/dialog.service';
import { DialogContainerComponent } from 'app/shared/dialog/dialog-container/dialog-container.component';
import type { FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import { ConfirmationModalComponent } from '../shared/modal/confirmation-modal/confirmation-modal.component';
import { PopupService } from '../shared/popup/popup.service';
import { NotificationsDropdownComponent } from '../shared/notifications-dropdown/notifications-dropdown.component';
import type { OverlayPositionX, OverlayPositionY } from '../shared/popup/model';
import { FeatureService } from '../shared/feature/feature.service';
import {
    BasicMenuItemEvent,
    basicEmployerMenu,
    basicUserMenu,
    buildCandidateMenu,
    buildEmployerMenu,
    fullEmployerMenu,
} from './navbar.utils';
import type { MenuItemType } from './navbar.utils';
import { WindowService } from './window.service';
import { UserService } from './user.service';

export interface IHeaderLink {
    label: string;
    hoverText?: string;
    notificationHoverText?: string;
    routerLink: string;
    routerLinkParams?: any;
    icon?: string;
    type?: MenuItemType;
    userName?: string;
    children?: IHeaderLink[];
    activeOnChildren?: boolean;
    class?: string;
    selectedEventName?: BasicMenuItemEvent;
    disabled?: boolean;
    withStatusLight?: boolean;
    statusLightActive?: boolean;
    statusLightTooltip?: string;
    hideCaret?: boolean;
    isNewElement?: boolean;
    userInteractionEvent?: UserInteractionEventType;
    smScreenOnly?: boolean;
    externalUrl?: string;
    withAttentionIndicator?: boolean;

    permissions?: string[];
    feature?: string;

    // An array of the RegExps to match current route against to determine whether route is active.
    // When non-empty has priority over routerLinkActive directive used in the nav-item's implementation.
    // Used for advanced cases where we have the following example configuration:
    //  - Analytics: '/jobs/analytics'
    //  - Jobs: '/jobs' | '/jobs/:id/(published|drafts|pending)' | '/jobs/:id/matches' etc.
    // In the scenario described we do not want Jobs route to be marked as active when '/jobs/analytics' route
    // is active since '/jobs/analytics' is not child of the '/jobs'
    activeRouteRegexp?: RegExp[];

    // a unique string key to match broadcast event names against to compute notification
    // indicator status
    notificationKeyToListen?: string | any;

    // computed property set on the HeaderLink item to show / hide notification icon
    // on the menu item
    hasNotificationIcon?: boolean; // computed property

    // HTML element which triggered event (e.g. click). Could be used to e.g. position dropdown
    // relative to an anchor that triggered dropdown opening
    el?: HTMLElement;
}

export interface INavigationTree {
    showStepper?: boolean;
    showProgressBar?: boolean;
    showStepperWithBottomProgressBar?: boolean;
    progressBarPercentage?: number;
    backgroundColorType?: string;
    // main menu is menu in the left hand side of the navigation bar
    mainMenu: IHeaderLink[];
    // secondary menu is a menu in the right hand side of the navigation bar
    secondaryMenu: IHeaderLink[];
    isEmployer?: boolean;
    preventLogoNavigation?: boolean;
    preventClose?: boolean;
}

export interface NavbarState {
    showStepper?: boolean;
    showProgressBar?: boolean;
    showStepperWithBottomProgressBar?: boolean;
    percent?: number;
    backgroundColorType?: string;
    preventLogoNavigation?: boolean;
    preventClose?: boolean;
}

/**
 *   custom menu items styles:
 *
 *   SecondaryMenu is different from level2 Menu, which renders
 *   the children of any MenuItem. Secondary is a supporting menu location
 *   that renders items with medium screen size
 *
 *   secondaryMenu items only are displayed when screen > md for design
 *
 *    classNames:
 *
 *      only-sm:             mainMenu items -> shows only in right bar when on mobile screen
 *      ignore-styling:      secondaryMenu items (>= larger) -> Keeps the size of icons like main nav
 *      ignore-styling-md:   secondaryMenu items (>= medium) -> Keeps the size of icons + label like main nav
 *      keep-on-sm           secondaryMenu items :  force display menu items on mobile too. By default items are hidden
 *
 *
 *    ICONS:
 *    Icons used must be included in file src/app/shared/navbar/nav-item/nav-item.component.scss
 *     svg must be located at /images/icons/forms/      #{$icon}_light.svg (normal)    and   #{$icon}.svg (active)
 *
 */

@Injectable()
export class NavbarService {
    private currentNavMenu: INavigationTree;
    private _smScreenNavbarTitle: string;
    private _smLeftHeaderLink: IHeaderLink;
    private _asyncHeaderAction: boolean;

    public headerLeftButtonClick$ = new Subject<boolean>();
    public headerRightButtonClick$ = new Subject<boolean>();
    public spNavigationTree$: BehaviorSubject<INavigationTree>;
    public disableMenusByRoute$ = new Subject<string[]>();

    stateChange$ = new Subject<NavbarState>();

    set withAsyncHeaderActions(value: boolean) {
        this._asyncHeaderAction = value;
    }

    get withAsyncHeaderActions(): boolean {
        return this._asyncHeaderAction;
    }

    constructor(
        private userService: UserService,
        private dialogService: DialogService,
        private popupService: PopupService,
        private windowService: WindowService,
        private featureService: FeatureService,
    ) {
        this.currentNavMenu = { ...basicUserMenu };
        this.spNavigationTree$ = new BehaviorSubject(this.currentNavMenu);

        this.watchStateChange();
        this.watchUserChange();
    }

    public handleMenuEvents(headerLink: IHeaderLink) {
        switch (headerLink.selectedEventName) {
            case BasicMenuItemEvent.signOut:
                this.userService.signOut();

                break;
            case BasicMenuItemEvent.confirmSignOut:
                if (
                    this.windowService.getWindowSize().width >=
                    this.windowService.breakPoints.larger
                ) {
                    // no sign-out confirmation on screens >large
                    this.userService.signOut();

                    return;
                }

                this.openConfirmationModal()
                    .afterClosed()
                    .pipe(take(1))
                    .subscribe((proceed) => {
                        if (proceed) {
                            this.userService.signOut();
                        }
                    });

                break;
            case BasicMenuItemEvent.openNotificationsDropdown:
                this.toggleNotificationsDropdown(headerLink);

                break;
            default:
                // when a stepper is visible also a rigth button is visible
                this.emitRightButtonClick();
        }
    }

    private openConfirmationModal() {
        return this.dialogService.open(DialogContainerComponent, {
            title: 'Sign Out',
            titleAlign: 'left',
            primaryButton: { label: 'Yes', isStateful: false },
            secondaryButton: { label: 'No', isStateful: false },
            usePlain: true,
            component: ConfirmationModalComponent,
            contentComponentData: {
                topMessage: 'Are you sure you want to sign out?',
            },
        });
    }

    private watchStateChange() {
        this.stateChange$.pipe(debounceTime(200)).subscribe((state) => {
            this.currentNavMenu.showStepper = state.showStepper;
            this.currentNavMenu.showProgressBar = state.showProgressBar;
            this.currentNavMenu.showStepperWithBottomProgressBar =
                state.showStepperWithBottomProgressBar;
            this.currentNavMenu.progressBarPercentage = state.percent;
            this.currentNavMenu.backgroundColorType = state.backgroundColorType;
            this.currentNavMenu.preventLogoNavigation =
                state.preventLogoNavigation;
            this.currentNavMenu.preventClose = state.preventClose;
        });
    }

    private watchUserChange() {
        this.userService.user$
            .pipe(
                filter((user): user is User => !!user),
                switchMap((user) => this.calculateMenuPath(user)),
            )
            .subscribe(() => this.spNavigationTree$.next(this.currentNavMenu));
    }

    private calculateMenuPath(user: User): Observable<INavigationTree> {
        let currentNavMenu;
        if (!user) {
            this.currentNavMenu = { ...basicUserMenu };

            return EMPTY;
        } else if (user.type === UserType.candidate) {
            currentNavMenu = this.getCandidateMenuTree(user);
        } else {
            currentNavMenu = this.getEmployerMenuTree(user);
        }

        return this.filterOutInaccessibleItems(currentNavMenu).pipe(
            tap((navigationTree) => (this.currentNavMenu = navigationTree)),
        );
    }

    private filterOutInaccessibleItems(currentNavMenu: INavigationTree) {
        currentNavMenu =
            this.removeMenuItemsWithInsufficientPermissions(currentNavMenu);

        return this.featureService
            .getAccessibleItemsFromList(currentNavMenu.mainMenu)
            .pipe(
                tap(
                    (filteredMainMenu) =>
                        (currentNavMenu.mainMenu = filteredMainMenu),
                ),
                map(() => currentNavMenu),
            );
    }

    private getCandidateMenuTree(user: User): INavigationTree {
        if (!this.userService.user.allowedToAccessMatches) {
            // If a stepper is currently visible, we keep it that way.
            // when needed any service will use the setter stepperHeaderDisplay = true to show it.
            return {
                ...basicUserMenu,
                showStepper: this.currentNavMenu.showStepper,
                showProgressBar: this.currentNavMenu.showProgressBar,
                progressBarPercentage:
                    this.currentNavMenu.progressBarPercentage,
            };
        }

        // Complete menu options
        return {
            ...buildCandidateMenu(user, this.windowService.isSmall()),
            showStepper: this.currentNavMenu.showStepper,
            showProgressBar: this.currentNavMenu.showProgressBar,
            progressBarPercentage: this.currentNavMenu.progressBarPercentage,
        };
    }

    private getEmployerMenuTree(user: User): INavigationTree {
        if (user.employer && user.employer.isProfileComplete) {
            return buildEmployerMenu(user, basicEmployerMenu);
        }

        return buildEmployerMenu(user, fullEmployerMenu);
    }

    set navbarTitle(navbarTitle: string) {
        this._smScreenNavbarTitle = navbarTitle;
    }

    get navbarTitle() {
        return this._smScreenNavbarTitle;
    }

    get smLeftHeaderLink() {
        return this._smLeftHeaderLink ? this._smLeftHeaderLink : null;
    }

    setSmLeftHeaderButton(link: IHeaderLink) {
        this.setAsync(link, (value) => (this._smLeftHeaderLink = value));
    }

    setNavbarTitle(title: string) {
        this.setAsync(title, (value) => (this.navbarTitle = value));
    }

    // Stepper right button click to execute logout, close or back actions
    emitRightButtonClick() {
        this.headerRightButtonClick$.next(true);
    }

    private setAsync(value: any, setterFunc: (value: any) => void) {
        of(value)
            .pipe(delay(1))
            .subscribe((val) => setterFunc(val));
    }

    private removeMenuItemsWithInsufficientPermissions(
        navigationTree: INavigationTree,
    ): INavigationTree {
        const menu = { ...navigationTree };
        menu.mainMenu = this.omitItemsWithInsufficientPermissions(
            menu.mainMenu,
        );
        menu.secondaryMenu = this.omitItemsWithInsufficientPermissions(
            menu.secondaryMenu,
        );
        if (menu.secondaryMenu) {
            menu.secondaryMenu.forEach(
                (item) =>
                    (item.children = this.omitItemsWithInsufficientPermissions(
                        item.children,
                    )),
            );
        }

        return menu;
    }

    private omitItemsWithInsufficientPermissions<
        T extends { permissions?: string[] },
    >(items?: T[]): T[] {
        return items
            ? items.filter((item) =>
                  this.userService.hasAnyPermission(item.permissions),
              )
            : [];
    }

    public disableMenusByRoutes(routes: string[]) {
        this.disableMenusByRoute$.next(routes);
    }

    public resetNavbarState() {
        this.stateChange$.next({});
    }

    public setNavbarState(newState: NavbarState) {
        this.stateChange$.next(newState);
    }

    private toggleNotificationsDropdown(headerLink: IHeaderLink): void {
        const positionConfig = this.windowService.isSmall()
            ? {
                  overlayX: 'end' as OverlayPositionX,
                  overlayY: 'center' as OverlayPositionY,
                  offsetX: -10,
                  offsetY: -10,
              }
            : {
                  offsetX: 5,
              };
        this.popupService.show(
            headerLink.el as FlexibleConnectedPositionStrategyOrigin,
            NotificationsDropdownComponent,
            {
                config: { borderRadius: 8, ...positionConfig },
                popupData: null,
            },
            true,
        );
    }
}
