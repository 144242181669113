import type { Observable } from 'rxjs';
import { EMPTY, concat, defer, from } from 'rxjs';
import { mergeMap, reduce } from 'rxjs/operators';
import type { Conversation, Paginator } from '@twilio/conversations';
import { isDefined } from '../utils';
import type { ChatChannelMember } from './model';

export function fetchConversationDescriptorPages(
    page$: Observable<Paginator<Conversation>>,
): Observable<any> {
    return defer(() =>
        page$.pipe(
            mergeMap((page) => {
                const items$: Observable<any> = from(page.items);
                const next$: Observable<any> = page.hasNextPage
                    ? fetchConversationDescriptorPages(from(page.nextPage()))
                    : EMPTY;

                return concat(items$, next$);
            }),
        ),
    );
}

export function fetchConversationDescriptors(
    startingPage$: Observable<Paginator<Conversation>>,
): Observable<any[]> {
    return fetchConversationDescriptorPages(startingPage$).pipe(
        reduce<any, any[]>((acc, val) => {
            acc.push(val);

            return acc;
        }, []),
    );
}

export function computeUnconsumedState(
    lastMessageIndex: number,
    lastConsumedMessageIndex: number,
): boolean {
    return (
        isDefined(lastMessageIndex) &&
        (!isDefined(lastConsumedMessageIndex) ||
            lastConsumedMessageIndex < lastMessageIndex)
    );
}

export function findMemberByEmail(
    channelMembers: Map<number | string, ChatChannelMember>,
    email: string,
): ChatChannelMember {
    return Array.from(channelMembers.values()).find(
        (member) => member.email === email,
    );
}
