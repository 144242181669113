import Quill from 'quill';

const Embed = Quill.import('blots/embed');

export class MentionMarker extends Embed {
    static create(value) {
        const node = super.create(value);

        node.setAttribute('class', 'sp-quill-mention-placeholder-node');
        node.setAttribute('data-value', value.value);
        node.innerHTML = value.value;

        return node;
    }

    static value(node) {
        return {
            value: node.getAttribute('data-value'),
        };
    }
}
