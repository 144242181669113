import type { ComponentRef, OnDestroy, OnInit } from '@angular/core';
import {
    Component,
    ComponentFactoryResolver,
    HostListener,
    Inject,
    InjectionToken,
    Injector,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subscription, asyncScheduler } from 'rxjs';
import type { ComponentType } from '@angular/cdk/overlay';
import type { DialogRef } from '../../dialog-ref';
import { ColorContext } from '../../models';
import { DialogParams, spDialogData } from '../dialog.service';
import { unsubscribeIfActive } from '../../utils';
import { PanelContext } from '../../panel/model/panel-context.enum';
import type { DialogTitle } from '../model/dialog-title.model';
import { DialogContentComponentInjector } from './dialog-content-component-injector';

export const SP_DIALOG_CONTENT_COMPONENT_DATA = new InjectionToken<any>(
    'SpNotificationDialog',
);

@Component({
    selector: 'sp-dialog-container',
    templateUrl: './dialog-container.component.html',
    styleUrls: ['./dialog-container.component.scss'],
})
export class DialogContainerComponent<T> implements OnInit, OnDestroy {
    dialogRef: DialogRef<T>;
    title: DialogTitle | string;
    infoMessage: string;

    private component: ComponentType<T>;
    private readonly subscription: Subscription;
    private _dialogContentContainerRef: ViewContainerRef;
    dialogContentComponentRef: ComponentRef<T>;

    v2PanelContext: PanelContext;

    // note: using setter on purpose - if there is an ngIf structural directive in template,
    // the 'dialogContent' viewChild query returns undefined
    // details on solutions:
    // - https://stackoverflow.com/questions/39366981/viewchild-in-ngif
    // - https://stackoverflow.com/questions/34947154/angular-2-viewchild-annotation-returns-undefined
    @ViewChild('dialogContent', { read: ViewContainerRef })
    set dialogContentContainerRef(viewContainerRef: ViewContainerRef) {
        if (
            viewContainerRef &&
            viewContainerRef !== this._dialogContentContainerRef
        ) {
            this._dialogContentContainerRef = viewContainerRef;
            this.renderDialogContent();
        }
    }

    get hasFooter() {
        return (
            this.data.primaryButton ||
            this.data.secondaryButton ||
            this.data.alternativeButton
        );
    }

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        @Inject(spDialogData) public data: DialogParams<T>,
        private injector: Injector,
    ) {
        this.component = data.component as ComponentType<T>;
        this.dialogRef = data.dialogRef;
        this.title = data.title;
        this.infoMessage = data.infoMessage;
        this.subscription = new Subscription();

        if (data.colorContext === ColorContext.PRIMARY) {
            this.v2PanelContext = PanelContext.primary;
        }
    }

    ngOnInit() {
        this.dialogRef.backdropClick().subscribe((event) => {
            if (this.data.stopPropagationOnOutsideClick) {
                event.stopPropagation();
            }
            this.closeDialog();
        });
        this.dialogRef.title$.subscribe((title) => {
            asyncScheduler.schedule(() => (this.title = title));
        });
    }

    ngOnDestroy() {
        this.dialogContentComponentRef.destroy();
        unsubscribeIfActive(this.subscription);
    }

    @HostListener('click', ['$event'])
    onDialogHostComponentClick(event) {
        this.handleEventPropagation(event);
    }

    onPrimaryButtonClick(event) {
        this.handleEventPropagation(event);
        if (!this.dialogRef.primaryButtonDisabled) {
            this.dialogRef.primaryButtonClick$.next();
        }
    }

    onSecondaryButtonClick(event) {
        this.handleEventPropagation(event);
        if (!this.dialogRef.secondaryButtonDisabled) {
            this.dialogRef.secondaryButtonClick$.next();
        }
    }

    onAlternativeButtonClick() {
        this.dialogRef.alternativeButtonClick$.next();
    }

    onCloseDialog(event) {
        this.handleEventPropagation(event);
        this.closeDialog();
    }

    private renderDialogContent() {
        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
                this.component,
            );
        this._dialogContentContainerRef.clear();
        this.dialogContentComponentRef =
            this._dialogContentContainerRef.createComponent(
                componentFactory,
                undefined,
                this.createInjector(),
            );
        this.dialogContentComponentRef.changeDetectorRef.detectChanges();
    }

    private closeDialog() {
        this.dialogRef.close();
    }

    private createInjector(): DialogContentComponentInjector {
        const tokens = new WeakMap();
        tokens.set(SP_DIALOG_CONTENT_COMPONENT_DATA, { ...this.data });

        return new DialogContentComponentInjector(this.injector, tokens);
    }

    onContentViewHeaderButtonClick(data: string) {
        if (data === 'close') {
            this.closeDialog();
        } else {
            this.dialogRef.sendData(data);
        }
    }

    private handleEventPropagation(event) {
        if (!this.data.allowWithinModalClickEventPropagation) {
            event.stopPropagation();
        }
    }
}
