import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import type { SpFormControlsGroup } from '../../../shared/forms/custom-controls/sp-form-controls-group.interface';
import {
    SpEmailInput,
    SpTextInput,
} from '../../../shared/forms/custom-controls/sp-form-controls.model';
import { COMMON_FORM_VALIDATORS } from '../../../core/config.service';

@Injectable()
export class RequestAccessFormService {
    form: FormGroup;
    controls: SpFormControlsGroup;

    initFormControls(
        email: string,
        employerName: string,
        firstName: string,
        lastName: string,
    ) {
        this.form = new FormGroup({});

        this.controls = {
            companyName: new SpTextInput({
                form: this.form,
                name: 'companyName',
                value: employerName,
                disabled: true,
                icon: 'city',
            }),

            firstName: new SpTextInput({
                form: this.form,
                name: 'firstName',
                label: 'First Name',
                value: firstName,
                validators: [
                    Validators.maxLength(
                        COMMON_FORM_VALIDATORS.firstNameMaxLength,
                    ),
                ],
                errorMessages: {
                    maxlength: `Max ${COMMON_FORM_VALIDATORS.firstNameMaxLength} characters`,
                },
                placeholder: 'enter your first name',
                icon: 'user',
            }),

            lastName: new SpTextInput({
                form: this.form,
                name: 'lastName',
                label: 'Last Name',
                value: lastName,
                validators: [
                    Validators.maxLength(
                        COMMON_FORM_VALIDATORS.lastNameMaxLength,
                    ),
                ],
                errorMessages: {
                    maxlength: `Max ${COMMON_FORM_VALIDATORS.lastNameMaxLength} characters`,
                },
                placeholder: 'enter your last name',
                icon: 'user',
            }),

            email: new SpEmailInput({
                form: this.form,
                name: 'email',
                label: 'Work Email',
                value: email,
                placeholder: 'enter your work email',
                icon: 'email',
            }),
        };

        return this.controls;
    }
}
