<a
    *ngIf="userType === 'employer'"
    class="provider-icon__big"
    href="javascript:"
    [ngClass]="{ disabled: isAuthenticating }"
    (click)="authenticateWithProvider(AuthProvider.google)"
>
    <img class="icon" src="/images/buttons/social-google.svg" />
    <span class="signup-button-label"> {{ isSignUp ? 'Sign up' : 'Sign in' }} with Google </span>
</a>

<ng-container *ngIf="userType === 'candidate'">
    <a
        class="provider-icon__small"
        href="javascript:"
        [ngClass]="{ disabled: isAuthenticating }"
        (click)="authenticateWithProvider(AuthProvider.google)"
    >
        <img class="icon" src="/images/buttons/social-google.svg" />
    </a>
    <a
        class="provider-icon__small"
        href="javascript:"
        [ngClass]="{ disabled: isAuthenticating }"
        (click)="authenticateWithProvider(AuthProvider.linkedin)"
    >
        <img class="icon linkedin" />
    </a>
</ng-container>
