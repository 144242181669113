import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../../../core/user.service';
import {
    SegmentEventModel,
    SegmentEventType,
} from '../../../shared/models/analytics/segment/segment-event.model';
import { PlainComponentDialogContainerComponent } from '../../../shared/dialog/plain-component-dialog-container/plain-component-dialog-container.component';
import { DialogService } from '../../../shared/dialog/dialog.service';
import type { DialogParams } from '../../../shared/dialog/dialog.service';
import { AnalyticsService } from '../../../core/analytics.service';
import { WindowService } from '../../../core/window.service';
import { unsubscribeIfActive } from '../../../shared/utils';
import { ProfilePrefillResultModalComponent } from './modal/profile-prefill-result-modal/profile-prefill-result-modal.component';
import { CandidateProfileService } from './candidate-profile.service';
import { CandidateProfileStepType } from './steps/candidate-profile-steps';
import { CandidateProfile } from './model';
import type { ICandidateProfile } from './model/candidate-profile.model';

const profileFullyPrefilledUrl = '/account/profile/create/preferences/jackpot';

@Injectable()
export class PostResumeUploadActionsService implements OnDestroy {
    private _isProfileFullyPrefilled = false;
    private _latestStepAccessibleUrl: string;

    readonly subscription: Subscription;

    constructor(
        private userService: UserService,
        private profileService: CandidateProfileService,
        private analyticsService: AnalyticsService,
        private windowService: WindowService,
        private dialogService: DialogService,
        private router: Router,
    ) {
        this.subscription = new Subscription();
    }

    ngOnDestroy() {
        unsubscribeIfActive(this.subscription);
    }

    onResumeUploadResult(error: string) {
        !error
            ? this.onResumeUploadSuccess()
            : this.onResumeUploadFailure(error);
    }

    onResumeUploadSuccess() {
        this.userService.user.hasUploadedResume = true;
        this.userService.notifyUserUpdated();
        const candidateProfileObservable$ =
            this.userService.getCandidateProfile(true, true);
        this.profileService
            .resolveCompanyInfoWithClearbit(candidateProfileObservable$)
            .subscribe((candidateProfile) =>
                this.onProfileDataResolved(candidateProfile),
            );
    }

    onResumeUploadFailure(error: string) {
        this.trackResumeParsedAnalyticsEvent(`Failure - ${error}`);
    }

    private onProfileDataResolved(candidateProfile: ICandidateProfile) {
        const profile = CandidateProfile.fromStorage(candidateProfile);
        this.profileService.setProfile(
            CandidateProfile.fromStorage(profile),
            true,
        );
        this._isProfileFullyPrefilled =
            this.profileService.firstInvalidStep() ===
            CandidateProfileStepType.preferences;
        this._latestStepAccessibleUrl = this._isProfileFullyPrefilled
            ? profileFullyPrefilledUrl
            : this.profileService.getNavigationUrlOfLastValidStep(
                  profileFullyPrefilledUrl,
              );
        this.trackResumeParsedAnalyticsEvent(
            `Success - ${
                this._isProfileFullyPrefilled ? 'Complete' : 'Incomplete'
            } Profile`,
        );
        this._isProfileFullyPrefilled
            ? this.navigateToLatestStepAccessible()
            : this.openPartialSuccessDialog(
                  candidateProfile.personalInfo?.firstName,
              );
    }

    private trackResumeParsedAnalyticsEvent(outcome: string): void {
        this.analyticsService.trackEvent(
            new SegmentEventModel(SegmentEventType.resume_parsed, {
                additionalProperties: {
                    Outcome: outcome,
                },
            }),
        );
    }

    private navigateToLatestStepAccessible() {
        this.router.navigate([this._latestStepAccessibleUrl], {
            state: {
                data: { prefillFromResume: this._isProfileFullyPrefilled },
            },
        });
    }

    private openPartialSuccessDialog(firstName?: string) {
        const smScreenDialogConfigOverrides = this.windowService.isSmall()
            ? {
                  width: '100%',
                  height: '100%',
                  maxHeight: '100%',
                  maxWidth: '100%',
                  panelClasses: ['sp-dialog-panel-marginless'],
              }
            : {};

        this.subscription.add(
            this.dialogService
                .open(PlainComponentDialogContainerComponent, {
                    component: ProfilePrefillResultModalComponent,
                    width: '67rem',
                    height: '56rem',
                    contentComponentData: {
                        userName: firstName,
                    },
                    ...smScreenDialogConfigOverrides,
                } as DialogParams<
                    PlainComponentDialogContainerComponent<ProfilePrefillResultModalComponent>
                >)
                .afterClosed()
                .subscribe(() => this.navigateToLatestStepAccessible()),
        );
    }
}
