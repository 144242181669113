<ul
    class="items-list"
    [ngStyle]="listStyles"
    [ngClass]="{
        expand: allowToExpand,
        'expand-sm': allowToExpandSm,
        vertical: showVertical,
        invalid: invalid
    }"
>
    <li
        *ngFor="let item of items"
        [class.selected]="isSelected(item.id)"
        [class.disabled]="disabledItem(item.id)"
        [ngStyle]="isSelected(item.id) ? combinedSelectedButtonStyle : inlineStyles"
        (click)="onItemClick(item.id)"
        class="item"
    >
        <span
            *ngIf="isSelected(item.id)"
            class="check-icon"
            [ngStyle]="checkIconStyles"
        ></span>
        <ng-container *ngIf="item.icon"
                      [ngTemplateOutlet]="icon"
                      [ngTemplateOutletContext]="invalid ? { icon: item.invalidStateIcon ?? item.icon, alt: item.name } : { icon: item.icon, alt: item.name }">
        </ng-container>
        <p class="item-label sp-noselect" [class.label-align-start]="labelAlignStart" [innerHTML]="item.name">
        </p>
    </li>
</ul>

<ng-template #icon let-icon="icon" let-alt="alt">
  <img
    src="{{ icon }}"
    alt="{{ alt }}"
    [ngStyle]="iconStyles"
    class="item-icon"
  />
</ng-template>
