import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { User } from '../../shared/models';
import { TokenService } from '../../core/token.service';

@Component({
    selector: 'sp-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent implements OnInit {
    @Output()
    verificationComplete = new EventEmitter<User>();

    error: any;

    private user: User;

    get emailVerificationResult() {
        if (this.error) {
            return this.error.errorMessage;
        }

        return 'Email verified.';
    }

    get afterVerificationCta() {
        return this.error ? 'Login' : 'Get Started';
    }

    constructor(
        private tokenService: TokenService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.verifyToken(this.route.snapshot.params.emailVerificationToken);
    }

    onAfterVerificationCtaClick() {
        this.verificationComplete.emit(this.user);
    }

    private verifyToken(token) {
        this.tokenService.verifyToken(token).subscribe(
            (verificationResponse) => {
                this.user = verificationResponse.user;
                this.error = null;
            },
            (error) => (this.error = error),
        );
    }
}
