import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';
import { BasicMenuItemEvent, MenuItemType } from 'app/core/navbar.utils';
import { WindowService } from '../../core/window.service';
import { NavbarService } from '../../core/navbar.service';
import type { IHeaderLink, INavigationTree } from '../../core/navbar.service';
import { UserService } from '../../core/user.service';
import { BroadcastService } from '../../core/broadcast.service';
import type { BroadcastEvent, User } from '../models';
import { isDefined, unsubscribeIfActive } from '../utils';
import { UserType } from '../models/user.model';
import type { NavbarState } from './model';

@Component({
    selector: 'sp-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    @Output() navbarStateChange = new EventEmitter<NavbarState>();

    selectMenuItem = new EventEmitter<IHeaderLink>();
    navigationPath: INavigationTree;

    mainMenu: IHeaderLink[] = new Array(0);
    mainMenuSm: IHeaderLink[] = new Array(0);

    activeItem: IHeaderLink;

    smMenuExpanded = false;
    // Submenus of mobile right menu options
    showMobileSubMenu = false;
    smScreen: boolean;

    // currently active route path, updated on every NavigationEnd
    activeRoutePath: string;

    currentUser: User;

    mobileSubMenu: IHeaderLink[];
    private subscription: Subscription;

    private lastBroadcastEvent: BroadcastEvent;
    private broadcastServiceSubscription: Subscription;

    @HostBinding('class.impersonating')
    get isImpersonating(): boolean {
        return isDefined(this.currentUser.userImpersonating);
    }

    @HostBinding('class.with-progress-bar')
    get withProgressBar(): boolean {
        return this.navigationPath.showProgressBar;
    }

    @HostBinding('class.with-stepper-and-bottom-progress-bar')
    get withStepperAndBottomProgressBar(): boolean {
        return this.navigationPath.showStepperWithBottomProgressBar;
    }

    @HostBinding('class.grey')
    get greyBackground(): boolean {
        return this.navigationPath.backgroundColorType === 'grey';
    }

    constructor(
        private userService: UserService,
        public navbarService: NavbarService,
        private windowService: WindowService,
        private broadcastService: BroadcastService,
        private router: Router,
    ) {
        this.subscription = new Subscription();
        this.currentUser = this.userService.user;
    }

    get impersonatedUserInfo(): string {
        const userName = this.currentUser.fullName;
        const employerName = this.currentUser.employer
            ? ` - ${this.currentUser.employer.employerName}`
            : '';
        const userId = ` (${this.currentUser.id})`;

        return userName.concat(employerName, userId);
    }

    ngOnInit() {
        this.smScreen = this.windowService.isSmall();

        this.watchNavigationTreeChange();
        this.watchCurrentRouteChange();
        this.watchWindowWidthChange();
        this.watchItemSelected();
        this.watchNavbarStateChange();
    }

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
        this.navbarStateChange.emit({ hasStepper: false });
    }

    private setNavigationPath(newMenu: INavigationTree) {
        this.navigationPath = newMenu;
        this.mainMenuSm = new Array(0);
        this.mainMenu = new Array(0);
        this.emitNavbarStateChange({ hasStepper: newMenu.showStepper });

        this.navigationPath.mainMenu.forEach((link) => {
            this.completeMenuType(link);
            // Separates some menus only for mobile to the corresponding right menu bar
            if (link.smScreenOnly) {
                this.mainMenuSm.push(link);
            } else {
                this.mainMenu.push(link);
            }
        });

        this.navigationPath.secondaryMenu.forEach((link) => {
            this.completeMenuType(link);
        });

        // If already in an url, check the associated
        // menu item and its children to set as active
        if (this.router.url) {
            this.activateMenuByRoute();
        }

        this.reComputeNavItemNotificationIndicator(this.lastBroadcastEvent);
        this.watchAppNotifications();
    }

    private completeMenuType(menuItem) {
        if (!menuItem.type) {
            // no type then by default MenuItemType.menuItem
            menuItem.type = MenuItemType.menuItem;
        }
    }

    /**
     * Activates an item. if it has a custom event name we emmit
     * To avoid some visual issues (menus rendered faster than routing)
     * the routing final event is responsible to activate menus and lvl2
     * @param activeItem
     */
    onItemSelected(activeItem: IHeaderLink) {
        if (
            activeItem.selectedEventName ===
            BasicMenuItemEvent.showChildrenMobile
        ) {
            this.mobileSubMenu = activeItem.children;
            this.showMobileSubMenu = !this.showMobileSubMenu;
            this.activeItem = this.showMobileSubMenu ? activeItem : null;

            return;
        } else if (activeItem.selectedEventName) {
            // Only triggers when the menu has it's selectedEventName property
            this.selectMenuItem.emit(activeItem);
        }

        if (this.showMobileSubMenu) {
            this.showMobileSubMenu = false;
            this.activeItem = null;
        }

        this.smMenuExpanded = false;
    }

    /**
     * Iterates on every first level menu to get if any is active
     * matches the first one
     */
    private activateMenuByRoute() {
        let link = this.getActiveFromMenu(this.mainMenu);
        if (!link) {
            link = this.getActiveFromMenu(this.mainMenuSm);
        }

        this.activeItem = link;
    }

    private getActiveFromMenu(
        menuList: IHeaderLink[],
        exact = false,
    ): IHeaderLink {
        for (const sublink of menuList) {
            const isExactCheck = sublink.activeOnChildren ? false : exact;
            if (
                sublink.routerLink !== '' &&
                this.router.isActive(sublink.routerLink, isExactCheck)
            ) {
                return sublink;
            }
        }

        return null;
    }

    onSmMenuToggleClick() {
        if (this.navigationPath.showStepper === true) {
            // Emmit the close action or whatever is associated
            this.onRightButtonClick();

            return;
        }

        this.smMenuExpanded = !this.smMenuExpanded;
        if (this.showMobileSubMenu) {
            this.showMobileSubMenu = false;
        }
    }

    onRightButtonClick() {
        this.navbarService.emitRightButtonClick();
    }

    async onHeaderLogoClick() {
        if (this.navbarService.withAsyncHeaderActions) {
            this.navbarService.headerLeftButtonClick$.next();

            return;
        }

        const user = this.userService.user;
        if (
            (user &&
                user.type === UserType.candidate &&
                !user.isProfileComplete) ||
            this.withProgressBar
        ) {
            return;
        }

        await this.router.navigate(['/']);
    }

    private watchNavigationTreeChange() {
        this.subscription.add(
            this.navbarService.spNavigationTree$.subscribe((menu) =>
                this.setNavigationPath(menu),
            ),
        );
    }

    // Used to activate an item from routing.
    // the active item has children they will be rendered
    private watchCurrentRouteChange() {
        this.activeRoutePath = this.router.url;
        const subscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.activeRoutePath = event.url;
                this.activateMenuByRoute();
            });
        this.subscription.add(subscription);
    }

    private watchWindowWidthChange() {
        const subscription = this.windowService.width$
            .pipe(skip(1))
            .subscribe(() => {
                this.smScreen = this.windowService.isSmall();
            });
        this.subscription.add(subscription);
    }

    private watchAppNotifications() {
        if (this.broadcastServiceSubscription) {
            unsubscribeIfActive(this.broadcastServiceSubscription);
            this.subscription.remove(this.broadcastServiceSubscription);
        }

        this.broadcastServiceSubscription = this.broadcastService.broadcast$
            .pipe(filter((event) => !!event))
            .subscribe((event) => {
                this.lastBroadcastEvent = event;
                this.reComputeNavItemNotificationIndicator(event);
            });

        this.subscription.add(this.broadcastServiceSubscription);
    }

    private watchItemSelected() {
        const itemSelectedSubscription = this.selectMenuItem.subscribe(
            (headerLink) => {
                this.navbarService.handleMenuEvents(headerLink);
            },
        );
        this.subscription.add(itemSelectedSubscription);
    }

    private reComputeNavItemNotificationIndicator(event) {
        if (!event) {
            // first render or no notifications were previously received
            return;
        }

        const broadcastEvent = event.event;
        const eventData = event.data;
        if (this.navigationPath?.secondaryMenu) {
            this.setNotificationIndicatorOnMenuItem(
                this.navigationPath.secondaryMenu,
                broadcastEvent,
                eventData,
            );
            this.setNotificationIndicatorOnMenuItem(
                this.mainMenuSm,
                broadcastEvent,
                eventData,
            );
        }
    }

    private setNotificationIndicatorOnMenuItem(
        menuItems: IHeaderLink[],
        broadcastEvent: string,
        eventData: any,
    ) {
        const menuItemSm = menuItems.find(
            (link) => link.notificationKeyToListen === broadcastEvent,
        );
        if (menuItemSm) {
            menuItemSm.hasNotificationIcon = !!eventData;
        }
    }

    private emitNavbarStateChange(navbarState: NavbarState) {
        this.navbarStateChange.emit(navbarState);
    }

    private watchNavbarStateChange() {
        const subscription = this.navbarService.stateChange$
            .pipe(distinctUntilChanged())
            .subscribe((navbarState) => {
                this.emitNavbarStateChange({
                    hasProgressBar: navbarState.showProgressBar,
                    hasStepper: navbarState.showStepper,
                    hasStepperWithBottomProgressBar:
                        navbarState.showStepperWithBottomProgressBar,
                });
            });
        this.subscription.add(subscription);
    }
}
