import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { buildClearbitLogoUrl, getMaskInlineCss } from '../utils';

@Component({
    selector: 'sp-resolvable-logo',
    templateUrl: './resolvable-logo.component.html',
    styleUrls: ['./resolvable-logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResolvableLogoComponent implements OnInit, OnChanges {
    @Input() domain: string;
    @Input() fallbackLogo: string;
    @Input() isFallbackLogoIcon = true;
    @Input() isFallbackLogoMasked = false;
    @Input() iconSize = 16;

    @Input() width = 64;
    @Input() height = 64;
    @Input() isCircle: boolean;
    @Input() roundedBorders = true;
    @Input() whiteBackground: boolean;

    @Input() logoUrl: string;
    @Input() isMasked = false;
    @Input() maskColor = '#5a6d80';

    get showMaskedIcon() {
        return !this.logoUrl && (this.isMasked || this.isFallbackLogoMasked);
    }

    get isFallbackLogo() {
        return this.logoUrl === this.fallbackLogo || this.showMaskedIcon;
    }

    get maskInlineCss() {
        return getMaskInlineCss(
            this.maskColor,
            this.fallbackLogo,
            `${this.iconSize}px`,
        );
    }

    ngOnInit() {
        if (!this.fallbackLogo) {
            throw new Error(
                'fallbackLogo path is mandatory binding and cannot be empty',
            );
        }

        this.resolveLogo();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.domain && !changes.domain.firstChange) {
            this.resolveLogo();
        }
    }

    onLogoLoadingError(imgElement) {
        if (this.isMasked || this.isFallbackLogoMasked) {
            this.logoUrl = null; // so it can show mask
        } else if (imgElement.src.indexOf(this.fallbackLogo) === -1) {
            imgElement.src = this.fallbackLogo;
        }
    }

    private resolveLogo() {
        if (this.domain) {
            this.logoUrl = buildClearbitLogoUrl(this.domain);
        } else if (!this.logoUrl) {
            if (this.isMasked) {
                this.logoUrl = null;
            } else if (!this.logoUrl) {
                this.logoUrl = this.fallbackLogo;
            }
        }
    }
}
