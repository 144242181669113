import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { JobRequirements } from '../shared/models/job-requirements.model';
import { FeatureService } from '../shared/feature/feature.service';

@Injectable()
export class JobRequirementsApiService {
    constructor(
        private http: HttpClient,
        private featureService: FeatureService,
    ) {}

    getJobReqsIfFeatureEnabled(
        jobId: number,
    ): Observable<JobRequirements | undefined> {
        return this.featureService
            .getFeature('enhanced-targeting')
            .pipe(
                switchMap((isEnhancedTargetingOn) =>
                    isEnhancedTargetingOn
                        ? this.getJobRequirements(jobId)
                        : of(undefined),
                ),
            );
    }

    getJobRequirements(jobId: number): Observable<JobRequirements> {
        return this.http
            .get<JobRequirements>(`/api/jobreqs/${jobId}`)
            .pipe(map((reqs) => JobRequirements.of(reqs)));
    }

    updateJobRequirements(
        jobId: number,
        jobRequirements: JobRequirements,
    ): Observable<void> {
        return this.http.put<void>(`/api/jobreqs/${jobId}`, jobRequirements);
    }
}
