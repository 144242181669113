export enum ChatChannelEventType {
    messageAdded = 'messageAdded',
    messageUpdated = 'messageUpdated',
    messageRemoved = 'messageRemoved',
    participantUpdated = 'participantUpdated',
    conversationUpdated = 'conversationUpdated',

    // custom enum constants, not available in chat client lib
    messageConsumed = 'messageConsumed',
}
