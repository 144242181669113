import type { OnDestroy } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import type { Params } from '@angular/router';
import { unsubscribeIfActive } from '../../shared/utils';
import { ButtonContext } from '../../shared/stateful-button/stateful-button.component';

@Component({
    selector: 'sp-information-form',
    templateUrl: './information-form.component.html',
    styleUrls: ['./information-form.component.scss'],
})
export class InformationFormComponent implements OnDestroy {
    @Input() image: string;
    @Input() title: string;
    @Input() description: string;
    @Input() buttonLabel: string;
    @Input() buttonCallback: () => Observable<any>;
    @Input() buttonSuccessMessage: string;
    @Input() buttonErrorMessage: string;
    @Input() buttonNavigationLink: string;
    @Input() queryParams: Params | null;
    @Input() buttonContext?: ButtonContext;
    @Input() buttonInverted = false;

    isLoading: boolean;
    buttonStatusMessage: string;

    private subscription: Subscription;

    ngOnDestroy(): void {
        unsubscribeIfActive(this.subscription);
    }

    onButtonClick() {
        if (this.buttonNavigationLink) {
            return;
        }

        this.isLoading = true;
        this.subscription = this.buttonCallback()
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                () => (this.buttonStatusMessage = this.buttonSuccessMessage),
                () => (this.buttonStatusMessage = this.buttonErrorMessage),
            );
    }
}
