import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';

export enum ToggleSize {
    small = 'small',
    medium = 'medium',
    big = 'big',
}

export enum ToggleLabelPosition {
    left = 'left',
    right = 'right',
}

@Component({
    selector: 'sp-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleSwitchComponent {
    @Input() label?: string;
    @Input() isOn = false;
    @Input() isLoading = false;
    @Input() size = ToggleSize.small;
    @Input() fontSize = ToggleSize.small;
    // separate size configuration for sm screen size devices
    @Input() smSize = ToggleSize.small;
    @Input() labelPosition = ToggleLabelPosition.right;
    @Input() stateLabelPosition?: ToggleLabelPosition;

    @Output() toggleChange = new EventEmitter<boolean>();

    @HostBinding('class.medium-font-size')
    get withMediumFontSize() {
        return this.fontSize === 'medium';
    }

    get switchStateLabel(): string {
        return this.isOn ? 'Yes' : 'No';
    }

    toggle(event: MouseEvent) {
        if (!this.isLoading) {
            event.stopPropagation();
            this.isOn = !this.isOn;

            this.toggleChange.emit(this.isOn);
        }
    }
}
