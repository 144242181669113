import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import type { Skill } from '../shared/models';
import { markUsedItemsInAutocompleteSourceByName } from '../shared/utils';

@Injectable()
export class SkillService {
    constructor(private http: HttpClient) {}

    searchSkills(
        keyword: string,
        excludeSkills?: Skill[][],
    ): Observable<Skill[]> {
        return keyword?.trim().length
            ? this.http
                  .get<Skill[]>(
                      `/api/skills?name=${encodeURIComponent(keyword.trim())}`,
                  )
                  .pipe(
                      map((skills) =>
                          markUsedItemsInAutocompleteSourceByName(
                              skills,
                              excludeSkills,
                          ),
                      ),
                  )
            : EMPTY;
    }
}
