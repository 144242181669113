import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import type { SpFormControl } from '../../../shared/forms/custom-controls/sp-form-controls.model';
import {
    SpEmailInput,
    SpPasswordInput,
} from '../../../shared/forms/custom-controls/sp-form-controls.model';
import { UserType } from '../../../shared/models/user.model';

@Injectable()
export class LogInFormService {
    form: FormGroup;

    createControls(
        userType: UserType,
        prefilledEmail: string,
    ): { [key: string]: SpFormControl } {
        this.form = new FormGroup({});

        return {
            email: new SpEmailInput({
                form: this.form,
                name: 'email',
                value: prefilledEmail,
                label:
                    userType === UserType.candidate
                        ? 'Email Address'
                        : 'Work Email Address',
                errorMessages: { emailNotRegistered: 'not registered yet' },
                placeholder: 'Enter e-mail',
                icon: 'email',
            }),

            password: new SpPasswordInput({
                form: this.form,
                isOptional: true,
                name: 'password',
                label: 'Password',
                placeholder: 'enter your password',
                icon: 'lock',
            }),
        };
    }
}
