import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { CanActivate } from '@angular/router';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../user.service';

@Injectable()
export class CandidateOnboardingCompleteGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private router: Router,
    ) {}

    canActivate(): Observable<boolean> {
        return this.userService.getUserObservable().pipe(
            map((user) => {
                if (!user.allowedToAccessMatches) {
                    this.router.navigate(['/account']);

                    return false;
                }

                return true;
            }),
        );
    }
}
