import {
    EmployerConnectedMatchStatus,
    connectedMatchStatusColor,
} from 'app/shared/models/enumeration/employer-connected-match-status.model';

export enum ChatMessagingThreadCandidateStatus {
    inReview = 'inReview',
    approved = 'approved',
    contacted = 'contacted',
    interviewing = 'interviewing',
    offers = 'offers',
    hired = 'hired',
    withdrewDeclined = 'withdrewDeclined',
    disqualified = 'disqualified',
}

export const chatMessagingThreadCandidateStatusColor = {
    unscreened: '#CCE8FF',
    inReview:
        connectedMatchStatusColor[EmployerConnectedMatchStatus.phoneScreen],
    approved: connectedMatchStatusColor[EmployerConnectedMatchStatus.approved],
    contacted: [
        `linear-gradient(135deg, ` +
            `${
                connectedMatchStatusColor[
                    EmployerConnectedMatchStatus.pendingContacted
                ]
            } 33%, ` +
            `${
                connectedMatchStatusColor[
                    EmployerConnectedMatchStatus.responded
                ]
            } 34%, ` +
            `${
                connectedMatchStatusColor[
                    EmployerConnectedMatchStatus.responded
                ]
            } 67%, ` +
            `${
                connectedMatchStatusColor[
                    EmployerConnectedMatchStatus.noResponse
                ]
            } 34%)`,
        connectedMatchStatusColor[EmployerConnectedMatchStatus.noResponse],
    ],
    interviewing: [
        `linear-gradient(135deg, ` +
            `${
                connectedMatchStatusColor[
                    EmployerConnectedMatchStatus.scheduled
                ]
            } 34%, ` +
            `${
                connectedMatchStatusColor[
                    EmployerConnectedMatchStatus.phoneScreen
                ]
            } 34%, ` +
            `${
                connectedMatchStatusColor[
                    EmployerConnectedMatchStatus.phoneScreen
                ]
            } 67%, ` +
            `${
                connectedMatchStatusColor[
                    EmployerConnectedMatchStatus.interview
                ]
            } 67%)`,
        connectedMatchStatusColor[EmployerConnectedMatchStatus.interview],
    ],
    offers: connectedMatchStatusColor[EmployerConnectedMatchStatus.pending],
    hired: connectedMatchStatusColor[EmployerConnectedMatchStatus.accepted],
    withdrewDeclined: [
        `linear-gradient(135deg, ` +
            `${
                connectedMatchStatusColor[
                    EmployerConnectedMatchStatus.withdrawnInterviewing
                ]
            } 50%, ` +
            `${
                connectedMatchStatusColor[EmployerConnectedMatchStatus.declined]
            } 50%)`,
        connectedMatchStatusColor[EmployerConnectedMatchStatus.declined],
    ],
    disqualified:
        connectedMatchStatusColor[EmployerConnectedMatchStatus.disqualified],
};
