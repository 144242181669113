import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { CandidateMatchesApiService } from '../core/candidate-matches-api.service';
import { InviteToApplyResponseType } from './auth.component';

@Injectable()
export class InviteToApplyGuard implements CanActivate {
    constructor(
        private matchApiService: CandidateMatchesApiService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const matchId = parseInt(route.params.matchId, 10);
        const inviteToApplyResponse = route.params.inviteToApplyResponse;

        if (inviteToApplyResponse === InviteToApplyResponseType.expired) {
            return true;
        }

        const spInviteToApplyToken = route.queryParams['spInviteToApplyToken'];

        return this.matchApiService
            .processCandidateResponseOnSpInviteToApply(
                matchId,
                inviteToApplyResponse,
                spInviteToApplyToken,
            )
            .pipe(
                map(() => true),
                catchError((error) => {
                    switch (error.status) {
                        // Invitation link has expired
                        case 410:
                            this.router.navigate([
                                `/apply/candidate/${matchId}/${InviteToApplyResponseType.expired}`,
                            ]);

                            return of(false);
                        // TODO: this is a temporary solution. Actions to be performed on the match as a result of subsequent
                        // decision (either same or different) are still to be determined.
                        case 409:
                            return of(true);
                        default:
                            this.router.navigate(['/login/candidate']);

                            return of(false);
                    }
                }),
            );
    }
}
